import * as React from "react";
import { Typography, Container } from "@mui/material";


import { useTranslation } from 'react-i18next';

import Group1 from "../landing/image/Highlight/Group1.png";
import Group2 from "../landing/image/Highlight/Group2.png";
import Group3 from "../landing/image/Highlight/Group3.png";

export default function Highlight() {
  const { t, i18n } = useTranslation();


  const item = {
    "workToExit": {
      "title": t("workToExitTitle"),
      "subtitle": t("workToExitSubtitle"),
      "description": t("workToExitDescription")
    },
    "modernTechnology": {
      "title": t("modernTechnologyTitle"),
      "subtitle": t("modernTechnologySubtitle"),
      "description": t("modernTechnologyDescription")
    },
    "connectedHRProcess": {
      "title": t("connectedHRProcessTitle"),
      "subtitle": t("connectedHRProcessSubtitle"),
      "description": t("connectedHRProcessDescription")
    }
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h2" align="center" sx={{ fontSize: "48px", fontWeight: 400, marginTop: "60px", marginBottom: "0px" }}>
        {t("universalHRHighlight")}
      </Typography>

      <div className="grid-container grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* First Item */}
        <div className="grid-item">
          <div className="circle-container">
            <div className="circle background-1"></div>
            <img src={Group1} alt="Group1" className="image-group" style={{ marginTop: "30px", marginBottom: "20px" }} />
          </div>
          <Typography align="center" variant="h5" className="highlight-text">
            {item.workToExit.title}<br /><span style={{ color: "rgba(83, 24, 129, 0.7)"}}>{item.workToExit.subtitle}</span>
          </Typography>
          <Typography 
            variant="body2" 
            color="textSecondary" 
            align="center" 
            className="subtext"
            margin="0px 20px"
            dangerouslySetInnerHTML={{ __html: item.workToExit.description }} 
          />
        </div>

        {/* Second Item */}
        <div className="grid-item">
          <div className="circle-container">
            <div className="circle background-2"></div>
            <img src={Group2} alt="Group2" className="image-group" style={{ margin: "50px 20px" }} />
          </div>
          <Typography align="center" variant="h5" className="highlight-text">
            {item.modernTechnology.title}<br /><span style={{ color: "#EB94C0"}}>{item.modernTechnology.subtitle}</span>
          </Typography>
          <Typography 
            variant="body2" 
            color="textSecondary" 
            align="center" 
            className="subtext" 
            margin="0px 20px"
            dangerouslySetInnerHTML={{ __html: item.modernTechnology.description }} 
          />
        </div>

        {/* Third Item */}
        <div className="grid-item">
          <div className="circle-container">
            <div className="circle background-3"></div>
            <img src={Group3} alt="Group3" className="image-group" style={{ margin: "70px 0px" }} />
          </div>
          <Typography align="center" variant="h5" className="highlight-text">
            {item.connectedHRProcess.title}<br /><span style={{ color: "#6FD0E2"}}>{item.connectedHRProcess.subtitle}</span>
          </Typography>
          <Typography 
            variant="body2" 
            color="textSecondary" 
            align="center" 
            className="subtext" 
            margin="0px 20px"
            sx={{
              "@media (max-width: 800px)": {
                paddingTop: "30px",
              },
            }}
            dangerouslySetInnerHTML={{ __html: item.connectedHRProcess.description }} 
          />
        </div>
      </div>
    </Container>
  );
}
