import React from "react";
import {
  Avatar,
  Grid,
  Typography,
  CardActionArea,
  Button,
  Divider,
  Chip,
} from "@mui/material";
import CardStyle from "../../../shared/general/Card";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import {
  getUserFullName,
  getUserPosition,
} from "../../../../../utils/userData";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
const typeStyles = {
  boss: {
    backgroundColor: "#EC5B6A",
  },
  peer: {
    backgroundColor: "#ff8d00",
  },
  crossfunction: {
    backgroundColor: "#7d55c2",
  },
  customer: {
    backgroundColor: "#008dd1",
  },
  subordinate: {
    backgroundColor: "#00bdb1",
  },
  project: {
    backgroundColor: "#ffc700",
  },
  outsider: {
    backgroundColor: "#820747",
  },
  oneself: {
    backgroundColor: "#b46c6c",
  },
  default: {
    backgroundColor: "#b46c6c",
  },
};
const ChipSampleSize = styled(Chip)(({ type }) => ({
  ...(typeStyles[type] || typeStyles.default),
}));
const WrapButtonSuccess = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  ["@media only screen and (max-width:600px)"]: {
    justifyContent: "center",
  },
});
const WrapButton = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
});

const CardPotentailAssessment = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const { potentialAssessment } = props;
  return (
    <CardStyle
      style={{
        borderLeft: "10px solid #EC5B6A",
        height: "100%",
      }}
    >
      <CardActionArea
        onClick={() => {
          if (potentialAssessment.isComplete != 1) {
            history.push("/user-potential/question-page", {
              potentialAssessment: potentialAssessment,
            });
          }
        }}
      >
        <Grid container spacing={2} padding={"24px"}>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar
              sx={{
                marginRight: "8px",
                width: 120,
                height: 120,
                marginBottom: "15px",
                "& img": { objectFit: "contain" },
              }}
              src={potentialAssessment.imageURL}
            />
            <Typography
              sx={{ fontWeight: "500", fontSize: "18px" }}
              gutterBottom
            >
              {getUserFullName(potentialAssessment)}
            </Typography>
            <Typography color="text.secondary">
              {getUserPosition(potentialAssessment)}
            </Typography>
          </Grid>
          <Grid item xs={8} container>
            <Grid item xs={12}>
              <Typography variant="h5" fontWeight={"600"} gutterBottom>
                {potentialAssessment.questionSetName}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography variant="body2" gutterBottom color="text.secondary">
                {potentialAssessment.description}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
              }}
            >
              <Typography color="text.secondary" sx={{ marginRight: "15px" }}>
                {t("Duration")}:{" "}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {dayjs(potentialAssessment.startDate).format("DD/MM/YYYY")} -{" "}
                {dayjs(potentialAssessment.endDate).format("DD/MM/YYYY")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
              }}
            >
              <Typography color="text.secondary" sx={{ marginRight: "15px" }}>
                จำนวนคำถาม:
              </Typography>
              <Typography variant="body1" gutterBottom>
                {potentialAssessment.questionLength}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems:"center"
              }}
            >
              <Typography color="text.secondary" sx={{ marginRight: "15px" }}>
                Ratio:
              </Typography>
              <Chip
                label={`${potentialAssessment.ratio}%`}
                sx={{
                  backgroundColor: "#EC5B6A",
                  ml: "10px",
                  fontSize: "16px",
                  color:"white"
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems:"center"
              }}
            >
              <Typography color="text.secondary" sx={{ marginRight: "15px" }}>
                ประเมินในฐานนะ:
              </Typography>
              <ChipSampleSize
                label={potentialAssessment.role}
                type={potentialAssessment.role}
                sx={{
                  fontSize: "16px",
                  ml: "10px",
                  color:"white"

                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {potentialAssessment.isComplete === 1 ? (
              <WrapButtonSuccess>
                <LibraryAddCheckIcon
                  style={{ marginRight: 4, color: "#00b74a" }}
                />
                <Typography fontSize={16} style={{ color: "#00b74a" }}>
                  ตอบแบบประเมินเรียบร้อย
                </Typography>
              </WrapButtonSuccess>
            ) : (
              <WrapButton>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    history.push("/user-potential/question-page", {
                      potentialAssessment: potentialAssessment,
                    });
                  }}
                >
                  ตอบแบบประเมิน
                </Button>
              </WrapButton>
            )}
          </Grid>
        </Grid>
      </CardActionArea>
    </CardStyle>
  );
};

export default CardPotentailAssessment;
