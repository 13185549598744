import React, { useEffect } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ButtonBlue from "../../shared/general/ButtonBlue";
import UploadFile from "../announcement/uploadFile";
import { useDispatch, useSelector } from "react-redux";
import {
  addWarningLetter,
  getWarningLetter,
  updateWarningLetter,
} from "../../../../actions/warningLetter";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../utils/userData";

const DialogCreate = ({ open, handleClose, data, warningLetterTemplate, companySelected }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: employees } = useSelector((state) => state.employees);
  const { result: warningLevel } = useSelector((state) => state.warningLevel);

  const dataAnnouncement = {
    title: "",
    detail: "",
    file: null,
    employee: null,
    idWarningLevel: "",
    idWarningLetterTemplateHtml: ""
  };

  const validationSchema = yup.object().shape({
    title: yup.string().max(100).required(t("titleIsRequired")),
    detail: yup.string().max(450),
    file: yup.mixed().nullable(),
    employee: yup.object().required(t("employeeNameIsRequired")).nullable(),
    idWarningLevel: yup.string().required(t("warningLevelIsRequired")),
    idWarningLetterTemplateHtml: yup.string().required(t("warningLetterTemplateIsRequired")),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: dataAnnouncement,
    resolver: yupResolver(validationSchema),
  });

  const handleAddWarningLetter = async (values) => {
    values.idEmployees = values.employee.idEmployees;

    const formData = new FormData();
    for (const key in values) {
      formData.append(key, values[key]);
    }

    let res = null;
    if (data) {
      res = await dispatch(updateWarningLetter(formData));
    } else {
      res = await dispatch(addWarningLetter(formData));
    }

    if (res) {
      reset();
      handleClose();
      dispatch(getWarningLetter(companySelected.idCompany));
    }
  };

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        employee: { idEmployees: data.idEmployees },
      });
    }
  }, []);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth={"sm"}>
      <DialogTitle>
        {data ? t("EditWarningLetter") : t("CreateWarningLetter")}
      </DialogTitle>
      <form onSubmit={handleSubmit(handleAddWarningLetter)}>
        <DialogContent>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={12}>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={`${t("WarningLetterName")}`}
                    error={Boolean(errors.title)}
                    helperText={errors.title && errors.title.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="detail"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    label={`${t("ReasonWaringMessage")}`}
                    error={Boolean(errors.detail)}
                    helperText={errors.detail && errors.detail.message}
                  />
                )}
              />
            </Grid>
            {data ? (
              <>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth error={errors.idWarningLevel}>
                    <InputLabel>{t("WarningLevel")}</InputLabel>
                    <Controller
                      name="idWarningLevel"
                      control={control}
                      render={({ field }) => (
                        <Select {...field} label={`${t("WarningLevel")}`}>
                          {warningLevel
                            .filter((element) => element.active)
                            .map((item) => (
                              <MenuItem
                                key={item.idWarningLevel}
                                value={item.idWarningLevel}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    />
                    {errors.idWarningLevel && (
                      <FormHelperText error={errors.idWarningLevel}>
                        {errors.idWarningLevel.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={12}>
                  <Controller
                    name="employee"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={employees}
                        isOptionEqualToValue={(option, value) =>
                          option.idEmployees === value.idEmployees
                        }
                        getOptionLabel={(option) =>
                          `${getUserFullName(option)}`
                        }
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.idEmployees}>
                              {`${getUserFullName(option)}`}
                            </li>
                          );
                        }}
                        onChange={(e, value) => field.onChange(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${t("EmployeeList")}`}
                            error={Boolean(errors.employee)}
                            helperText={
                              errors.employee && errors.employee.message
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth error={errors.idWarningLevel}>
                    <InputLabel>{t("WarningLevel")}</InputLabel>
                    <Controller
                      name="idWarningLevel"
                      control={control}
                      render={({ field }) => (
                        <Select {...field} label={`${t("WarningLevel")}`}>
                          {warningLevel
                            .filter((element) => element.active)
                            .map((item) => (
                              <MenuItem
                                key={item.idWarningLevel}
                                value={item.idWarningLevel}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    />
                    {errors.idWarningLevel && (
                      <FormHelperText error={errors.idWarningLevel}>
                        {errors.idWarningLevel.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={errors.idWarningLevel}>
                <InputLabel>{t("DownloadTemplate")}</InputLabel>
                <Controller
                  name="idWarningLetterTemplateHtml"
                  control={control}
                  render={({ field }) => (
                    <Select {...field} label={`${t("DownloadTemplate")}`}>
                      {warningLetterTemplate
                        // .filter((element) => element.active)
                        .map((item) => (
                          <MenuItem
                            key={item.idWarningLetterTemplateHtml}
                            value={item.idWarningLetterTemplateHtml}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
                {errors.idWarningLevel && (
                  <FormHelperText error={errors.idWarningLevel}>
                    {errors.idWarningLevel.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {/* {
              data && <Grid item xs={12} sm={5}>
                {data.file && (
                  <Button
                    variant="outlined"
                    component={Link}
                    href={data.file}
                    target="_blank"
                    startIcon={<AttachFileIcon />}
                  >
                    {t("AttachFile")}
                  </Button>
                )}
              </Grid>
            } */}
            <Grid item xs={12}>
              <Controller
                name="file"
                control={control}
                render={({ field }) => (
                  <UploadFile
                    {...field}
                    onChange={field.onChange}
                    acceptFileType={"image/jpeg, image/png, application/pdf"}
                    acceptFileTypeLabel={t("JpgPngPdf")}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="error" type="button" onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <ButtonBlue variant="contained" type="submit">
            {t("Confirm")}
          </ButtonBlue>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogCreate;
