import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Chip, createFilterOptions, Grid, IconButton, InputAdornment, MenuItem, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CardStyle from "../../../shared/general/Card";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from "react-redux";
import { getLevelByidCompany } from "../../../../../actions/level";
import { getCompanyRetirementSetting, updateCompanyRetirementSetting } from "../../../../../actions/company";

import Swal from "sweetalert2";
import AddIcon from '@mui/icons-material/Add'; 
import calculateService from "../../../../../services/calculate.service";
import DialogConfirm from "../../../shared/general/DialogConfirm";

const StyledRoot = styled(Box)({
  "& .MuiTypography-h6": {
    fontSize: "1.5rem",
  },
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const RetirementSetting = (props) => {
  const { selectedCompany, isReadOnly } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: groupLevelList } = useSelector((state) => state.level);
  const { result: retirementSetting, isFetching: isFetchingRetirementSetting } = useSelector((state) => state.retirementSetting);

  const [dialogCalculateRetirement, setDialogCalculateRetirement] = useState({
    isOpen: false,
    data: {},
  })

  const validateYupSchema = yup.object({
    retirementAge: yup.string()
      .required(`${t("ThisFieldIsRequired")}`)
      .test("isValid", "Retirement age must be between 50 - 70", (value) => (value >= 50 && value <= 70)),
    retirementGroupList: yup.array().of(
      yup.object().shape({
        retirementAge: yup.string()
          .required(`${t("ThisFieldIsRequired")}`)
          .test("isValid", "Retirement age must be between 50 - 70", (value) => (value >= 50 && value <= 70)),
        levelGroupList: yup.array().min(1, "ต้องมีอย่างน้อย 1 ตัวเลือก").required(`${t("ThisFieldIsRequired")}`),
      })
    ),
  });

  const useHookForm = useForm({
    defaultValues: {
      retirementAge: "",
      retirementGroupList: [],
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  })

  const useFieldArrayRetirementGroupList = useFieldArray({
    control: useHookForm.control,
    name: "retirementGroupList",
  });

  const onAddRetirementGroupList = () => {
    useFieldArrayRetirementGroupList.append({
      levelGroupList: [],
      retirementAge: "",
    })
  }

  const onDeleteRetirementGroupList = (index) => {
    useFieldArrayRetirementGroupList.remove(index)
  }

  const onResetData = () => {
    const map = {}

    groupLevelList.map(item => {
      map[item.idGroupLevel] = item;
    })
    
    useHookForm.reset({
      retirementAge: retirementSetting.retirementAge,
      retirementGroupList: retirementSetting.retirementGroupList.map(item => ({
        retirementAge: item.retirementAge,
        levelGroupList: item.levelGroupList.map(x => map[x]).filter(x => x),
      })),
    })
  }

  useEffect(() => {
    if(selectedCompany){
      dispatch(getLevelByidCompany({ idCompany: selectedCompany.idCompany }));
      dispatch(getCompanyRetirementSetting({ idCompany: selectedCompany.idCompany }));
    }
  }, [selectedCompany])

  useEffect(() => {
    if(retirementSetting && groupLevelList){

      const map = {}

      groupLevelList.map(item => {
        map[item.idGroupLevel] = item;
      })
      
      useHookForm.reset({
        retirementAge: retirementSetting.retirementAge,
        retirementGroupList: retirementSetting.retirementGroupList.map(item => ({
          retirementAge: item.retirementAge,
          levelGroupList: item.levelGroupList.map(x => map[x]).filter(x => x),
        })),
      })

    } else {

      useHookForm.reset({
        retirementAge: "",
        retirementGroupList: [],
      })

    }
  }, [retirementSetting, groupLevelList])

  const onSubmit = async (data) => {
    // console.log(data)

    const formData = {
      retirementAge: data.retirementAge,
      retirementGroupList: data.retirementGroupList.map(item => ({
        retirementAge: item.retirementAge,
        levelGroupList: item.levelGroupList.map(x => x.idGroupLevel)
      }))
    }

    const result = await dispatch(updateCompanyRetirementSetting(selectedCompany.idCompany, formData))

    if (result && result.status === 200) {
      Swal.fire({
        icon: 'success',
        title: t("DataSaveSuccessful"),
        showConfirmButton: false,
        timer: 1500,
      });

      dispatch(getCompanyRetirementSetting({ idCompany: selectedCompany.idCompany }));
    } else {
      Swal.fire({
        icon: 'error',
        title: t("AnErrorOccurred"),
        confirmButtonText: 'OK',
      });
    }
  }

  const handleOpenDialogCalculateRetirement = () => {
    setDialogCalculateRetirement(prev => ({
      ...prev,
      isOpen: true,
    }))
  }

  const handleCloseDialogCalculateRetirement = () => {
    setDialogCalculateRetirement(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const onCalculateRetirementEmployees = async () => {

    if(selectedCompany){
      const result = await calculateService.calculateRetirementEmployees(selectedCompany.idCompany);

      if (result && result.status === 200) {

        setDialogCalculateRetirement(prev => ({
          ...prev,
          isOpen: false,
        }))

        Swal.fire({
          icon: 'success',
          title: t("DataSaveSuccessful"),
          showConfirmButton: false,
          timer: 1500,
        });

      } else {
        Swal.fire({
          icon: 'error',
          title: t("AnErrorOccurred"),
          confirmButtonText: 'OK',
        });
      }

    }

  }

  const retirementGroupListWatch = useHookForm.watch("retirementGroupList");

  const filterSelectedOptions = () => {

    const map = {};

    retirementGroupListWatch.map(group => {
      group.levelGroupList.map(item => {
        map[item.idGroupLevel] = true;
      })
    })

    return groupLevelList.filter(item => !map[item.idGroupLevel]);

  }

  return (
    <StyledRoot>
      <Box marginTop="32px">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-between"
            >
              <Typography fontSize="24px" fontWeight="500">
                {t("retirement")}
              </Typography>
              <Box>
                <ButtonBlue variant="contained" onClick={handleOpenDialogCalculateRetirement}>{t("calculateEmployeeRetirementDate")}</ButtonBlue>
              </Box>
            </Box>
          </Grid>

          {isFetchingRetirementSetting && <Grid item xs={12} container justifyContent="center">
            <Typography fontSize="20px">{t("LoadingData")}...</Typography>
          </Grid>}

          {!isFetchingRetirementSetting && retirementSetting && <Grid item xs={12}>
            <CardStyle>
              <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
                <Box padding="16px">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box paddingTop="13.5px">
                        <Typography>{t("retirementAgeOfEmployeeInCompany")} (50 - 70 {t("Year")})</Typography>
                        <Typography paddingTop="4px" fontSize="14px" color="text.secondary">{t("descriptionOfRetirementAge")}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="retirementAge"
                        control={useHookForm.control}
                        render={({field, fieldState}) => (
                          <TextFieldTheme
                            {...field}
                            placeholder={t("retirementAge")}
                            InputProps={{
                              inputComponent: NumberFormatTheme,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Typography color="text.secondary">{t("Year")}</Typography>
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{
                              allowNegative: false,
                              allowLeadingZeros: false,
                              value: field.value,
                              decimalScale: 0,
                              onValueChange: (values) => {
                                const { value } = values;
                                if(value > 70){
                                  field.onChange(70);
                                } else {
                                  field.onChange(value);
                                }
                              },
                            }}
                            onChange={() => {}}
                            error={fieldState.error? true: false}
                            helperText={fieldState.error? fieldState.error.message: null}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box marginTop="8px" marginBottom="8px" display="flex" justifyContent="space-between" alignItems="center">
                        <Typography fontSize="18px" fontWeight="600">{t("setRetirementAgeBasedOnEmployeeGroupLevel")}</Typography>
                        <ButtonBlue 
                          variant="outlined"
                          startIcon={<AddIcon />}
                          onClick={onAddRetirementGroupList}
                        >
                          {t("AddItem")}
                        </ButtonBlue>
                      </Box>
                    </Grid>

                    {useFieldArrayRetirementGroupList.fields.length === 0 && <Grid item xs={12}>
                      <Typography align="center" fontStyle="oblique" color="text.secondary">{t("NoItems")}</Typography>
                    </Grid>}

                    {useFieldArrayRetirementGroupList.fields.length > 0 && useFieldArrayRetirementGroupList.fields.map((item, index) => (
                      <Grid key={item.id} item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Box display="flex" alignItems="center" gap="8px">
                              <Typography fontSize="18px">{t("group")} {index + 1}</Typography>
                              <IconButton onClick={() => {onDeleteRetirementGroupList(index)}}>
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box paddingTop="13.5px">
                              <Typography>{t("EmployeeGroupLevel")}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Controller
                              name={`retirementGroupList.${index}.levelGroupList`}
                              control={useHookForm.control}
                              render={({ field, fieldState }) => (
                                <Autocomplete
                                  {...field}
                                  options={filterSelectedOptions()}
                                  getOptionLabel={(option) => `${option.groupLevelName}`}
                                  isOptionEqualToValue={(option, value) => option.idGroupLevel === value.idGroupLevel}
                                  renderOption={(props, option) => (
                                    <MenuItem {...props} key={option.idGroupLevel}>
                                      <Box>
                                        <Typography>{option.groupLevelName}</Typography>
                                      </Box>
                                    </MenuItem>
                                  )}
                                  renderInput={(params) => (
                                    <TextFieldTheme
                                      {...params}
                                      placeholder={t("SelectData")}
                                      onBlur={field.onBlur}
                                      error={fieldState.error? true: false}
                                      helperText={fieldState.error? fieldState.error.message: null}
                                    />
                                  )}
                                  value={field.value}
                                  onChange={(_, value) => {
                                    field.onChange(value)
                                  }}
                                  noOptionsText={t("NoData")}
                                  disableClearable
                                  multiple
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box paddingTop="13.5px">
                              <Typography>{t("retirementAgeOfEmployeeInGroup")} (50 - 70 {t("Year")})</Typography>
                              <Typography paddingTop="4px" fontSize="14px" color="text.secondary">{t("descriptionOfRetirementAge")}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Controller
                              name={`retirementGroupList.${index}.retirementAge`}
                              control={useHookForm.control}
                              render={({field, fieldState}) => (
                                <TextFieldTheme
                                  {...field}
                                  placeholder={t("retirementAge")}
                                  InputProps={{
                                    inputComponent: NumberFormatTheme,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Typography color="text.secondary">{t("Year")}</Typography>
                                      </InputAdornment>
                                    ),
                                  }}
                                  inputProps={{
                                    allowNegative: false,
                                    allowLeadingZeros: false,
                                    value: field.value,
                                    decimalScale: 0,
                                    onValueChange: (values) => {
                                      const { value } = values;
                                      if(value > 70){
                                        field.onChange(70);
                                      } else {
                                        field.onChange(value);
                                      }
                                    },
                                  }}
                                  onChange={() => {}}
                                  error={fieldState.error? true: false}
                                  helperText={fieldState.error? fieldState.error.message: null}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}

                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center" justifyContent="flex-end" gap="16px">
                        <ButtonBlue variant="text" onClick={onResetData}>{t("RestoreDefault")}</ButtonBlue>
                        <ButtonBlue variant="contained" type="submit">{t("Save")}</ButtonBlue>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </CardStyle>
          </Grid>}
        </Grid>
      </Box>
      <DialogConfirm
        open={dialogCalculateRetirement.isOpen}
        handleClose={handleCloseDialogCalculateRetirement}
        text={""}
        content1={t("calculateEmployeeRetirementDate")}
        // content2={t("systemWillOverwriteThisMonthData")}
        handleConfirm={onCalculateRetirementEmployees}
      />
    </StyledRoot>
  )
}

export default RetirementSetting;