import React, { useEffect, useState } from "react";
import { Typography, Box, CardMedia, Avatar, Container } from "@mui/material";
import { makeStyles } from '@mui/styles';

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

import Web from "../landing/image/OurTech/Web.png";
import Mobile from "../landing/image/OurTech/Mobile.png";
import AI from "../landing/image/OurTech/AI.png";

import { useTranslation } from 'react-i18next';

import "./carousel.css";

const useStyles = makeStyles({
  boxBg: {
    height: 500,
    width: '70%',
    display: 'flex', 
    alignItems: 'center',
    justifyContent: 'center', 
    position: 'relative',
    zIndex: 0,
    borderRadius: 30, 
    margin: '0 auto', 
    background: 'linear-gradient(90deg, rgba(235, 148, 192, 0.2) -0.51%, rgba(194, 176, 230, 0.2) 50.01%, rgba(165, 221, 231, 0.2) 99.49%)',
    backgroundRepeat: 'no-repeat',
    "@media only screen and (max-width: 1200px)": {
      height: 700,
    },
    "@media only screen and (max-width: 800px)": {
      height: '500px',
      width: '90%',
    },
    "@media only screen and (max-width: 600px)": {
      height: 350,
    }
  },
  
});


export default function Carousel() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const images = [
    {
      src: Web,
      alt: "Web Application",
      head: "Web Application",
      title: (
        <>
          UniHR<br />Web Application
        </>
      ),
      
      description: t("webAppDescription")
    },
    {
      src: Mobile,
      alt: "Mobile Application",
      head: "Mobile Application",
      title: (
        <>
          UniHR<br />Mobile Application
        </>
      ),
      
      description: t("mobileAppDescription"),
      
    },
   
    {
      src: AI,
      alt: "AI Chatbot",
      head: "AI Chatbot",
      title: "Jarvis Chatbot",
      description: t("aiChatbotDescription"),
    }
  ];
  
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 2000); 

    return () => clearInterval(interval); 
  }, []);

  return (

  <Container maxWidth="lg" className={`${classes.boxBg}`}
      sx={{
        "@media (max-width: 800px)": { width: "100%", height: "400px", padding: "0 10px" }
      }}>

    <div className="carousel-container">
      <div className="carousel">
        <div className="carousel-item" style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
          
          {/* ซ้าย: รูปภาพ */}
          <Box sx={{ width: '50%', height: '100%', marginTop: '50px',
              "@media (max-width: 800px)": {
                  width: '100%', 
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  height: '50px',
                  marginLeft: '10px',
                  marginTop: '100px'

                }
           }}>
            <CardMedia
              component="img"
              src={images[currentIndex].src}
              alt={images[currentIndex].alt}
              sx={{ width: 'auto', height: '280px', marginLeft: '50px',
                "@media (max-width: 800px)": {
                  marginLeft: 0,
                  height: '180px', 
                }
              }}
            /> 

          </Box>
          
          {/* ขวา: ข้อความ */}
          <Box sx={{ 
            width: '35%', 
            padding: '10px',  
            marginLeft: '50px',
            marginRight: '30px',
            marginTop: '50px',
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'flex-start',
            height: 'auto',
            "@media (max-width: 800px)": {
              width: '100%', 
              textAlign: 'left',
              display: 'flex',
              justifyContent: 'center',
              height: '50px',
              marginTop: '80px',
              marginLeft: '10px',
              marginRight: '5px'
            }
          }}>
            <Typography variant="h6" align="left" sx={{ fontSize: 24, fontWeight: 400, "@media (max-width: 800px)": { fontSize: 18 } }}>
              {images[currentIndex].head}
            </Typography>
            <Typography variant="h4" align="left" sx={{ fontSize: 36, fontWeight: 400, color: "rgba(83, 24, 129, 0.7) !important", "@media (max-width: 800px)": { fontSize: 25 },  whiteSpace: 'nowrap', lineHeight: 1 }}>
              {images[currentIndex].title}
            </Typography>
            <Typography variant="overline" align="left" sx={{ fontSize: 14, fontWeight: 200, lineHeight: 1.5, marginTop: 2, "@media (max-width: 800px)": { fontSize: 12 }}}>
              {images[currentIndex].description}
            </Typography>
          </Box>
        </div>
        
        {/* สัญลักษณ์การนำทาง */}
        <Box sx={{ position: 'absolute', top: '40%', left: '-110px', transform: 'translateY(-50%)', zIndex: 1000,     
        "@media (max-width: 800px)": {
            left: '185px', 
            top: '100%', 
            marginTop: '65px',
    } }}>
          <Avatar onClick={prevSlide} sx={{ 
            width: 71, 
            height: 71,
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            background: "#ffffff",
            "@media (max-width: 800px)": { width: 50, height: 50, backgroundImage: 'none' } 
          }}>
            <ArrowBackIosRoundedIcon sx={{ width: 34, height: 34, color: "#000000" , "@media (max-width: 800px)": { width: 24, height: 24 } }} />
          </Avatar>
        </Box>

        <Box sx={{ position: 'absolute', top: '40%', right: '-110px', transform: 'translateY(-50%)', zIndex: 1000,     
        "@media (max-width: 800px)": {
            right: '185px', 
            top: '100%', 
            marginTop: '65px',
          } }}>
          <Avatar onClick={prevSlide} sx={{ 
            width: 71, 
            height: 71, 
            color: "#000000",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            background: "#ffffff",
            "@media (max-width: 800px)": { width: 50, height: 50, backgroundImage: 'none' } 
          }}>
            <ArrowForwardIosRoundedIcon sx={{ width: 34, height: 34, "@media (max-width: 800px)": { width: 24, height: 24 } }} />
          </Avatar>
        </Box>
      </div>
    </div>
</Container>
  );
}

