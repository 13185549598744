import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getWarningLetterTemplateHtml, upsertWarningLetterTemplate, upsertWarningLetterTemplateHtml } from "../../../../../../actions/warningLetter";
import QuillEditor from "./component/CreatePdf/QuillEditor";
import Swal from "sweetalert2";
import LoadingFullPage from '../../../../shared/loadingFullPage/index.js';
import { useHistory } from 'react-router-dom';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import UsagePopup from "./component/dialog/UsagePopup.js";

const StyledRoot = styled("div")({
    backgroundColor: "#FFFFFF !important",
    position: 'relative'
});

const CreateHtml = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { result: warningLetterTemplate } = useSelector((state) => state.warningLetterTemplate);

    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [dontShowAgain, setDontShowAgain] = useState(false);
    const [isShowOption, setIsShowOption] = useState(true);

    const history = useHistory();
    const { id } = useParams();

    useEffect(async () => {
        if (id) {
            await dispatch(getWarningLetterTemplateHtml(id));
        }
    }, [id]);

    useEffect(() => {
        if (warningLetterTemplate) {
            setContent(warningLetterTemplate.content)
        }
    }, [warningLetterTemplate])

    useEffect(() => {
        const showNotification = localStorage.getItem("showNotification");
        if (showNotification === null) {
            localStorage.setItem("showNotification", "true");
            setShowPopup(true);
        } else if (showNotification === "false") {
            setShowPopup(false);
        } else {
            setShowPopup(true);
        }
    }, []);

    const handleClosePopup = () => {
        if (dontShowAgain) {
            localStorage.setItem("showNotification", "false");
        }
        setShowPopup(false);
    };

    const handleOnSubmit = async () => {
        try {

            const data = {
                "idWarningLetterTemplateHtml": warningLetterTemplate ? warningLetterTemplate.idWarningLetterTemplateHtml : null,
                "content": content.replace('<img', `<img class="image-class" `)
            }

            const result = await dispatch(upsertWarningLetterTemplateHtml(data));

            if (result) {
                const text = result.status === 200 ? `${t('Update')} ${t('Succeed')} ` : t('Document_created_successfully')
                Swal.fire({
                    title: `${t("Succeed")}!!`,
                    text: `${text}!!`,
                    icon: 'success',
                    confirmButtonText: `${t("Ok")}`,
                });
            }
        } catch (error) {
            console.error('Error submitting data:', error);

            Swal.fire({
                title: `${t("AnErrorOccurred")}`,
                text: `${t("Unable_to_create_document")}!!`,
                icon: 'error',
                confirmButtonText: `${t("Ok")}`,
            });
        } finally {
            setLoading(false);
        }

    };

    const handleDownloadPdfPreview = async () => {
        try {
            const contentHtml = content
            const filename = `preview.pdf`;

            const pdfContent = document.createElement('div');
            pdfContent.id = 'pdfContent';
            pdfContent.className = 'pdfContent';
            pdfContent.innerHTML = contentHtml;

            const styleElement = document.createElement('style');
            styleElement.innerHTML = `
            @import url('https://fonts.googleapis.com/css2?family=THSarabunNew&family=Noto+Sans+Thai&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:wght@400;700&display=swap');
            /* Quill editor styles */
            @import url('https://cdn.quilljs.com/1.3.6/quill.snow.css');
            /* Custom styles if any */
             .pdfContent {
                padding:45px 56px;
             }   
            #pdfContent {
                padding:45px 56px;
            }
             .ql-align-right {
                text-align: right;
            }
             .ql-align-left {
                text-align: left;
            }
             .ql-align-center {
                text-align: center;
            }

            .image-class {
                width: 120px;
                max-width: 100%;
                height: auto;
            }

            .ql-font-THSarabunNew {
                font-family: 'THSarabunNew', sans-serif;
            }

            .ql-font-serif {
                font-family: serif;
            }

            .ql-font-NotoSansThai {
                font-family: 'Noto Sans Thai', sans-serif;
            }

        `;

            document.head.appendChild(styleElement);

            document.body.appendChild(pdfContent);

            // รอให้ฟอนต์และรูปภาพโหลดเสร็จ
            await new Promise(resolve => setTimeout(resolve, 100)); // หน่วงเวลา 1 วินาที

            const canvas = await html2canvas(pdfContent, {
                useCORS: true,        // การใช้ CORS เพื่อโหลดแหล่งที่มาผ่านภาพ
                logging: true,        // เปิดการบันทึกการทำงาน
                backgroundColor: '#ffffff', // สีพื้นหลังของ canvas
                imageTimeout: 0,      // ตั้งเวลารอภาพ
                allowTaint: true,     // อนุญาตให้โหลดภาพจากแหล่งที่มาต่างๆ
                scrollX: 0,           // ตั้งค่าการเลื่อนแนวนอน
                scrollY: 0,           // ตั้งค่าการเลื่อนแนวตั้ง
            });

            const imgData = canvas.toDataURL('image/png');

            const doc = new jsPDF('p', 'mm', 'a4');
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();

            const paddingLeft = 0; // in mm
            const paddingRight = 0; // in mm
            const contentWidth = pageWidth - paddingLeft - paddingRight;

            const paddingTop = 0; // in mm
            const paddingBottom = 0; // in mm
            const contentHeight = pageHeight - paddingTop - paddingBottom;

            const imgHeight = (canvas.height * contentWidth) / canvas.width;

            // Ensure the image height does not exceed the page height
            const finalImgHeight = Math.min(imgHeight, contentHeight);

            doc.addImage(imgData, 'PNG', paddingLeft, paddingTop, contentWidth, finalImgHeight);
            doc.save(filename);

            Swal.fire({
                title: `${t("Succeed")}!!`,
                text: `PDF ${t('Succeed')}!!`,
                icon: 'success',
                confirmButtonText: `${t("Ok")}`,
            });

            document.body.removeChild(pdfContent);
            document.head.removeChild(styleElement);

        } catch (error) {
            console.error('Error generating PDF:', error);
            Swal.fire({
                title: `${t("AnErrorOccurred")}`,
                text: `${t("Unable_to_Generate_pdf_document")}!!`,
                icon: 'error',
                confirmButtonText: `${t("Ok")}`,
            });
        }
    };

    return (
        <StyledRoot className={"page"}>
            {loading && <LoadingFullPage />}
            <Container maxWidth="lg"
                style={{ paddingTop: "24px", paddingBottom: "72px", minWidth: '900px', maxWidth: '900px', position: 'relative' }}
            >
                <QuillEditor value={content} setValue={setContent} handleOnSubmit={handleOnSubmit} status={content ? true : false} handleDownloadPdf={handleDownloadPdfPreview} setShowPopup={setShowPopup} warningLetterTemplate={warningLetterTemplate}
                    isDisable={warningLetterTemplate && warningLetterTemplate.idCompany === null ? true : false}
                />
            </Container>
            <UsagePopup open={showPopup} onClose={handleClosePopup} isShowOption={isShowOption} setDontShowAgain={setDontShowAgain} />
        </StyledRoot>
    );
};

export default CreateHtml;
