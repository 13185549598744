import { httpClient } from "./httpClient";

const getAllPotential = () => {
  return httpClient.get("potential/all");
};



const getPotentailAssessment = (filter) => {
  return httpClient.get("potential/getPotentialAssessments", { params: filter });
};



const getPotentailAssessmentAdmin = (filter) => {
  return httpClient.get("potential/getPotentialAssessments-admin", { params: filter });
};
const getQuestionListByIdPotentialAssessment = (idPotentialAssessmentQuestionSet) => {
  return httpClient.get(`potential/getPotentialAssessment/${idPotentialAssessmentQuestionSet}`);
};

const getUniQuestion = ()=>{
  return httpClient.get("potential/uniQuestion");
}
const getOverviewAssessmentByIdQuestionSet = (idPotentialAssessmentQuestionSet)=>{
  return httpClient.get(`potential/getOverviewAssessment/${idPotentialAssessmentQuestionSet}`);
}

const getDetailAnswerPotentialAssessment = (idAppraisee)=>{
  return httpClient.get(`potential/detailAnswerPotentialAssessment/${idAppraisee}`,);
}

const getAppraiserByIdLevelGroup = (query)=>{
  return httpClient.get(`potential/getAppraiser`,{ params: query })
}

const postPotentailAssessment = (formData) =>{
  return httpClient.post("potential/createPotentialAssessment",formData);
}
const postAnswerPotentialAssessment = (formData) =>{
  return httpClient.post("potential/answerPotentialAssessment",formData);
}



export default {
  getAllPotential,
  getPotentailAssessment,
  getPotentailAssessmentAdmin,
  getAppraiserByIdLevelGroup,
  getUniQuestion,
  getOverviewAssessmentByIdQuestionSet,
  getQuestionListByIdPotentialAssessment,
  postPotentailAssessment,
  postAnswerPotentialAssessment,
  getDetailAnswerPotentialAssessment
};
