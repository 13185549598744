import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import CardStyle from "../../../shared/general/Card";
import { getCostElement } from "../../../../../actions/costElement";
import Setting from "./Setting";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
    root: {
        marginTop: "30px",
        paddingBttom: "100px",
        "& .MuiTypography-h6": {

            fontSize: "1.5rem",
        },
        "& .MuiAccordionSummary-root": {
            paddingLeft: 8,
            paddingRight: 16,
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
    },
    headingPage: {
        marginBottom: 32,
    },
    wrapIcon: {
        display: "flex",
        alignItems: "center",
    },
    iconHeader: {
        marginRight: 16,
    },
    paper: {
        padding: 8,
        borderRadius: "20px",
        boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    },
}));

const StyledHeadingPage = styled("div")({
    marginBottom: 16,
});

const StyledEmpty = styled("div")({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 350,
});

const StyledBoxSearch = styled(Box)({
    marginTop: 22,
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});

function CostElement(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { result: costElement } = useSelector((state) => {
        return state.costElement ? state.costElement : [];
    });
    const [data, setData] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [typeDialog, setTypeDialog] = useState(null);
    const { selectedCompany } = props;

    useEffect(() => {
        if (selectedCompany && selectedCompany.idCompany) {
            dispatch(
                getCostElement({
                    idCompany: selectedCompany.idCompany,
                })
            );
        }
    }, [openDialog, selectedCompany, dispatch]);

    const handleClose = () => {
        setOpenDialog(false);
        setTypeDialog(null);
        setData(null);
    };

    return (
        <div className={classes.root}>
            <StyledHeadingPage>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6">{t("CostElementSetting")}</Typography>
                    </Grid>
                    {/* <Grid item>
                        <ButtonBlue
                            variant="contained"
                            onClick={() => CostElementXlsx(costElement ? costElement : null, selectedCompany && selectedCompany.companyName ,t)}
                            disabled={!costElement || (costElement && costElement.length < 1)}
                        >
                            {t("DownloadData")}
                        </ButtonBlue>
                    </Grid> */}
                </Grid>
            </StyledHeadingPage>
            <CardStyle style={{ padding: 16, marginBottom: 20 }}>
                {costElement && costElement.length > 0 ? (
                    <TableContainer component={Paper} sx={{ minWidth: 600, marginTop: 6 }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">{t("TitleName")}</TableCell>
                                    <TableCell align="center">{t("CostElement")}</TableCell>
                                    <TableCell align="center">{t("Setting")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {costElement.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center">
                                            {item.name_TH ? item.name_TH : item.name_EN ? item.name_EN : "-"}
                                        </TableCell>
                                        <TableCell align="center">
                                            {item.costElementName ? item.costElementName : "-"}
                                        </TableCell>
                                        <TableCell align="center">
                                            <EditIcon
                                                onClick={() => {
                                                    if (item.idCostElement) {
                                                        setOpenDialog(true);
                                                        setTypeDialog("Edit");
                                                        setData(item);
                                                    } else {
                                                        setOpenDialog(true);
                                                        setTypeDialog("Add");
                                                        setData({
                                                            idPayrollType: item.idPayrollType || null,
                                                            idCompany: selectedCompany && selectedCompany.idCompany,
                                                            costElementName: "",
                                                            name_EN: item.name_EN,
                                                            name_TH: item.name_TH,
                                                        });
                                                    }
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography color="text.secondary" align="center">
                        {t("NoData")}
                    </Typography>
                )}
            </CardStyle>

            {openDialog && (
                <Setting
                    typeDialog={typeDialog}
                    handleClose={handleClose}
                    open={openDialog}
                    data={data}
                    selectedCompany={selectedCompany && selectedCompany.idCompany}
                    costElementList={costElement}
                />
            )}
        </div>
    );
}

export default CostElement;
