import React, { useEffect } from "react";
import {
  MenuItem,
  Typography,
  Grid,
  TextField,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  styled,
  Autocomplete,
  Popper,
  autocompleteClasses,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SaveIcon from "@mui/icons-material/Save";
import dayjs from "dayjs";
import {
  addProject,
  getProjectByEmp,
  getEmployeeProject,
  updateProject,
  getAllEmployeeProject,
} from "../../../../actions/project";
import DatePickerCustom from "../../shared/date/datePicker";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { openNotificationAlert } from "../../../../actions/notificationAlert";
import ButtonBlue from "../../shared/general/ButtonBlue";
import SelectEmployees from "../../shared/general/selectEmployees";

const useStyles = makeStyles(() => ({
  formControl: {
    marginRight: 8,
  },
  buttonDelete: {
    backgroundColor: "red",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 42,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  icon: {
    fontSize: 19,
    color: "#0b3f86",
  },
  nameDemand: {
    fontSize: 24,
    fontWeight: "bold",
    marginLeft: 20,
  },
  breadcrumbs: {
    marginTop: 5,
    fontSize: 18,
  },
  dataText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  wrapCaptionImage: {
    marginTop: 16,
  },
  captionImage: {
    color: "#212b36",
  },
  firstSection: {
    padding: "60px 24px",
    margin: 10,
  },
  imageProfile: {
    marginBottom: 62,
    textAlign: "center",
  },
  placeholder: {
    width: "100%",
    height: "100%",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "rgb(99, 115, 129)",
    backgroundColor: "rgb(224, 246, 248)",
    transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  placeholderImageProfile: {
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgba(22, 28, 36, .72)",
  },
  placeholderLabel: {
    color: "rgb(255, 255, 255)",
  },
  captionImage: {
    color: "#212b36",
    fontSize: 14,
  },
  wrapCaptionImage: {
    textAlign: "center",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 7,
  },
  text: {
    fontSize: 18,
    marginBottom: 7,
    // color: ''
  },
  fieldDate: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  fieldInterDate: {
    "& .MuiFormControl-root": {
      width: "calc(100% - 16px)",
    },
  },
  nameHeader: {
    fontSize: 21,
    fontWeight: "bold",
    marginBottom: 10,
    marginLeft: 10,
  },
}));

const StyledPopper = styled(Popper)({
  width: "330px !important",
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const projectType = [
  "โรงงานใหม่ (New Plant)",
  "ผลิตภัณฑ์ใหม่ (New Product)",
  "ธุรกิจใหม่ (New Business)",
  "เครือข่ายใหม่ (Network&Stakholder)",
  "ขยายการผลิต (New Capacity)",
  "ขยายผลิตภัณฑ์ (Product Improvement)",
  "ขยายธุรกิจ (Business Improvement)",
  "ขยายเครือข่าย (Enhance Network&Stakholder)",
  "พัฒนากระบวนการ (Process Improvement)",
  "ดิจิตัลและเอไอ (Digital & AI)",
  "นวัตกรรมใหม่ (Innovation)",
  "การศึกษาและเรียนรู้ (Study)",
  "Research (วิจัย)",
];

const AddProject = ({ idEmployeeProject, open, handleClose, idCompany, selectedYear }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: managers } = useSelector((state) => state.manager);
  const classes = useStyles();

  const validationSchema = yup.object().shape({
    employee: yup.object().nullable().required(),
    projectName: yup.string().max(200).required(),
    type: yup.string().required(),
    reason: yup.string().max(200).required(),
    impact: yup.string().required(),
    directReturn: yup.number().oneOf([0, 1]).required(),
    returnType: yup.string().required(),
    return: yup.number().integer().positive().required(),
    size: yup.string().required(),
    dateStart: yup.date().required(),
    dateEnd: yup
      .date()
      .required()
      .when("dateStart", (dateStart) => {
        return yup
          .date()
          .min(dayjs(dateStart).add(1, "day"), t("InvalidStartandEndDates"));
      }),
    role: yup.string().required(),
    manager: yup.object().nullable().required(),
    note: yup.string().max(200),
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      employee: null,
      projectName: "",
      type: "",
      reason: "",
      impact: "",
      directReturn: "",
      returnType: "",
      return: "",
      size: "",
      dateStart: dayjs(),
      dateEnd: dayjs(),
      role: "",
      note: "",
      manager: null,
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (formData) => {
    try {
      const approveBy = formData.manager.idEmployees;
      formData.approveBy = approveBy;

      delete formData.manager;

      let response = null;
      if (idEmployeeProject) {
        response = await updateProject(idEmployeeProject, {
          projectName: formData.projectName,
          type: formData.type,
          reason: formData.reason,
          impact: formData.impact,
          directReturn: formData.directReturn,
          returnType: formData.returnType,
          return: formData.return ,
          size: formData.size,
          dateStart: formData.dateStart,
          dateEnd: formData.dateEnd,
          role: formData.role,
          note: formData.note,
          approveBy,
        });
      } else {
        const data = { 
          ...formData,
          idEmployees: formData.employee.idEmployees, 
        }
        delete data.employee;
        response = await addProject(data);
      }
      if (response && response.status === 200) {
        dispatch(
          openNotificationAlert({
            type: "success",
            message: t("DataSaveSuccessful"),
          })
        );
      } else {
        dispatch(
          openNotificationAlert({
            type: "error",
            message: t("AnErrorOccurred"),
          })
        );
      }

      dispatch(getAllEmployeeProject({ idCompany, year: selectedYear }));
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const fetchedData = async (id) => {
    try {
      const response = await getEmployeeProject(id);
      if (response && response.data) {
        const data = response.data
        const findManager = managers.find(item => item.idEmployees === data.approveBy)
        reset({
          manager: findManager,
          dateStart: dayjs(data.dateStart),
          dateEnd: dayjs(data.dateEnd),
          ...data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (idEmployeeProject) {
      fetchedData(idEmployeeProject);
    }
  }, [idEmployeeProject]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {idEmployeeProject ? t("updateProject") : t("AddNewProject")}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="employee"
                render={({ field, fieldState }) => (
                  <>
                    <SelectEmployees
                      {...field}
                      handleChange={field.onChange}
                    />
                    {Boolean(fieldState.error) && (
                      <FormHelperText sx={{ color: "#d32f2f"}}>{fieldState.error.message}</FormHelperText>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="projectName"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label={t("ProjectName")}
                    fullWidth
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error && fieldState.error.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="type"
                render={({ field, fieldState }) => (
                  <FormControl fullWidth error={Boolean(fieldState.error)}>
                    <InputLabel>{t("Type")}</InputLabel>
                    <Select {...field} label={t("Type")}>
                      {projectType.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {fieldState.error && fieldState.error.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="reason"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label={t("Reason")}
                    fullWidth
                    multiline
                    rows={3}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error && fieldState.error.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name="impact"
                render={({ field, fieldState }) => (
                  <FormControl fullWidth error={Boolean(fieldState.error)}>
                    <InputLabel>{t("Impact")}</InputLabel>
                    <Select {...field} label={t("selectImpact")}>
                      <MenuItem value="high">{t("High")}</MenuItem>
                      <MenuItem value="medium">{t("Medium")}</MenuItem>
                      <MenuItem value="low">{t("Low")}</MenuItem>
                    </Select>
                    <FormHelperText>
                      {fieldState.error && fieldState.error.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name="directReturn"
                render={({ field, fieldState }) => (
                  <FormControl fullWidth error={Boolean(fieldState.error)}>
                    <InputLabel>{t("DirectImpact")}</InputLabel>
                    <Select {...field} label={t("DirectImpact")}>
                      <MenuItem value={1}>{t("Yes")}</MenuItem>
                      <MenuItem value={0}>{t("No")}</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name="returnType"
                render={({ field, fieldState }) => (
                  <FormControl fullWidth error={Boolean(fieldState.error)}>
                    <InputLabel>{t("returnType")}</InputLabel>
                    <Select {...field} label={t("returnType")}>
                      <MenuItem value={"yearly"}>{"Yearly"}</MenuItem>
                      <MenuItem value={"OneTime"}>{"One time"}</MenuItem>
                    </Select>
                    <FormHelperText>
                      {fieldState.error && fieldState.error.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name="return"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label={t("Amount")}
                    fullWidth
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error && fieldState.error.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <div className={classes.fieldInterDate}>
                <Typography gutterBottom>{t("StartDate")}</Typography>
                <Controller
                  control={control}
                  name="dateStart"
                  render={({ field, fieldState }) => (
                    <DatePickerCustom
                      {...field}
                      views={["year", "month", "day"]}
                      minDate={dayjs().subtract(4, "year").startOf("year")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(fieldState.error)}
                          helperText={
                            fieldState.error && fieldState.error.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <div className={classes.fieldDate}>
                <Typography gutterBottom>{t("EndDate")}</Typography>
                <Controller
                  control={control}
                  name="dateEnd"
                  render={({ field, fieldState }) => (
                    <DatePickerCustom
                      {...field}
                      views={["year", "month", "day"]}
                      minDate={dayjs(watch("dateStart")).add(1, "day")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(fieldState.error)}
                          helperText={
                            fieldState.error && fieldState.error.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name="role"
                render={({ field, fieldState }) => (
                  <FormControl fullWidth error={Boolean(fieldState.error)}>
                    <InputLabel>{t("Role")}</InputLabel>
                    <Select {...field} label={t("selectRole")}>
                      <MenuItem value="Leader">{t("leader")}</MenuItem>
                      <MenuItem value="Employee">{t("Employee")}</MenuItem>
                      <MenuItem value="IT support">{t("itSupport")}</MenuItem>
                    </Select>
                    <FormHelperText>
                      {fieldState.error && fieldState.error.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name="size"
                render={({ field, fieldState }) => (
                  <FormControl fullWidth error={Boolean(fieldState.error)}>
                    <InputLabel id="demo-simple-select-label">
                      {t("Size")}
                    </InputLabel>
                    <Select {...field} label={t("Size")}>
                      <MenuItem value="small">{t("Small")}</MenuItem>
                      <MenuItem value="medium">{t("Medium")}</MenuItem>
                      <MenuItem value="big">{t("Big")}</MenuItem>
                    </Select>
                    <FormHelperText>
                      {fieldState.error && fieldState.error.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={500} color="text.third" gutterBottom>
                {t("SearchManager")}
              </Typography>
              <Controller
                control={control}
                name="manager"
                render={({ field, fieldState }) => (
                  <StyledAutocomplete
                    {...field}
                    options={
                      managers
                        ? managers.sort(
                            (a, b) =>
                              a.positionName &&
                              a.positionName.localeCompare(b.positionName)
                          )
                        : []
                    }
                    onChange={(_, newValue) => {
                      field.onChange(newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.idEmployees === value.idEmployees
                    }
                    getOptionLabel={(option) =>
                      i18n.resolvedLanguage === "th"
                        ? `${option.firstname_TH} ${option.lastname_TH}`
                        : `${option.firstname_EN} ${option.lastname_EN}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        error={Boolean(fieldState.error)}
                        helperText={
                          fieldState.error && fieldState.error.message
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: null,
                        }}
                      />
                    )}
                    popupIcon={<i className="fa-light fa-chevron-down"></i>}
                    PopperComponent={StyledPopper}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={500} color="text.third" gutterBottom>
                {t("Note")}
              </Typography>
              <Controller
                control={control}
                name="note"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={2}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error && fieldState.error.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonBlue type="button" onClick={handleClose}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue
            variant="contained"
            type="submit"
            startIcon={<SaveIcon />}
            disabled={isSubmitting}
          >
            {t("Submit")}
          </ButtonBlue>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddProject;
