import dayjs from 'dayjs';

export const PND1TxtFile = (selectYear, selectedMonth, employeeDetails, contributionRate) => {
    const presentDay = new Date();
    const months = [
        { value: "01", name: "มกราคม" },
        { value: "02", name: "กุมภาพันธ์" },
        { value: "03", name: "มีนาคม" },
        { value: "04", name: "เมษายน" },
        { value: "05", name: "พฤษภาคม" },
        { value: "06", name: "มิถุนายน" },
        { value: "07", name: "กรกฎาคม" },
        { value: "08", name: "สิงหาคม" },
        { value: "09", name: "กันยายน" },
        { value: "10", name: "ตุลาคม" },
        { value: "11", name: "พฤศจิกายน" },
        { value: "12", name: "ธันวาคม" },
    ];

    const selectedMonthObject = months.find(month => month.value == selectedMonth);
    const selectedMonthName = selectedMonthObject ? selectedMonthObject.name : "Unknown Month";

    if (employeeDetails && employeeDetails.employeeDetails.length > 0) {
        let content = '';

        employeeDetails.employeeDetails.forEach((item) => {
            const incomeUnderSection = `${item.incomeUnderSection? item.incomeUnderSection === "40(1)"? "1": "4": ""}`;
            content += `00|${item.companyTaxId ? item.companyTaxId : "-"}|0000000000|0000| ${item.personalID}|0000000000 | ${item.title_TH}| ${item.firstname_TH}| ${item.lastname_TH}| ${item.houseNo ? item.houseNo : "-"}, หมู่บ้าน${item.village ? item.village : "-"}, หมู่ที่ ${item.villageNo ? item.villageNo : "-"}, ตำบล${item.subDistrict ? item.subDistrict : "-"}, อำเภอ${item.district ? item.district : "-"}| จังหวัด${item.province ? item.province : "-"}| ${item.areaCode ? item.areaCode : "-"}| ${selectedMonthName}| ${selectYear+543}|${incomeUnderSection}| ${dayjs(presentDay).format("DDMM")}${selectYear}| ${contributionRate ? contributionRate : '-'} | ${item.valueActual.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}| ${item.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}| 1\n\n`;
        });

        // const SummaryMoney = employeeDetails.salarySummary.totalSalary;
        // const formattedSummaryMoney = SummaryMoney.toLocaleString();

        // content += `Summary: ${ formattedSummaryMoney } \n`;

        const blob = new Blob([content], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'ภงด-1.txt';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    } else {
        console.log("No data");
    }
};