import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  Container,
  Tab,
  Tabs,
  Badge,
  Box,
  CardContent,
  Chip,
  Autocomplete,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ButtonBlue from "../../shared/general/ButtonBlue";

import CardStyle from "../../shared/general/Card";
import HistoryIcon from "@mui/icons-material/History";

import { getAllExpenseByIdCompany } from "../../../../actions/expense";
import SubTableWithButton from "../../shared/expense/table/SubTableWithButton";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../actions/company";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 24,
  },
  "& .head": {
    paddingTop: 8,
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .head-2": {
    paddingTop: 8,
    marginBottom: 24,
    marginTop: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .wrap-sum-amount": {
    marginBottom: 16,
  },
});

const StyledDivTab = styled("div")(({ theme }) => ({
  width: "100%",
  padding: "0 8px",
  marginBottom: 16,
}));

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  marginBottom: "24px",
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  fontSize: 16,
  "& .MuiBadge-badge": {
    right: -16,
    top: 12,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#e46a76",
  },
}));

const StyledChipTab = styled(Chip)({
  display: "flex",
  flexDirection: "column",
  width: 20,
  height: 20,
  marginLeft: 5,
  backgroundColor: "orangered",
  color: "#fff",
  "& .MuiChip-label": {
    display: "block",
    whiteSpace: "normal",
    textAlign: "center",
  },
});

const StyledTabs = styled(Tabs)({
  "& .Mui-selected": {
    color: "#46cbe2 !important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#46cbe2 !important",
  },
  "& .fal": {
    marginRight: 8,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ExpensePage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [statusTab, setStatusTab] = React.useState(0);
  const [statusType, setStatusType] = useState("Processing");
  const [statusIndex, setStatusIndex] = useState(0);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: expense } = useSelector((state) => state.expense);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );
  const [selectedCompany, setSelectedCompany] = useState(null);
  const listStatusType = [
    { status: "Processing", label: t("InProgress"), index: 0 },
    { status: "Holding", label: t("Holding"), index: 1 },
    { status: "WaitingForDocument", label: t("WaitingForDocument"), index: 2 },
    {
      status: "WaitingForPaymentSlip",
      label: t("WaitingForPaymentSlip"),
      index: 3,
    },
  ];

  useEffect(() => {
    if (
      userProfile &&
      affiliateOrganizationList &&
      affiliateOrganizationList.length > 0
    ) {
      let ownCompany = affiliateOrganizationList.find(
        (x) => x.idCompany === userProfile.idCompany
      );
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany);
    }
  }, [userProfile, affiliateOrganizationList]);

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
  };

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getAllExpenseByIdCompany(selectedCompany.idCompany));
    }
  }, [userProfile, selectedCompany]);

  const handleApproveTabsChange = (_, newValue) => {
    setStatusTab(newValue);
  };

  const handleViewDetail = (idExpense, expenseType, idEmployees) => {
    console.log(
      "idExpense, expenseType, idEmployees",
      idExpense,
      expenseType,
      idEmployees
    );

    if (idExpense) {
      history.push(
        `/expense/detail/${expenseType}/${idExpense}/${idEmployees}/edit`
      );
    }
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div className="head">
          <Typography variant="h4">{t("AppMenu.Expense")}</Typography>
        </div>

        <Box marginBottom="24px">
          <SelectAffiliateCompany
            options={affiliateOrganizationList}
            value={selectedCompany}
            onChange={(_, value) => {
              onChangeCompany(value);
            }}
          />
        </Box>

        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            marginBottom: "16px",
          }}
        >
          <ButtonBlue
            variant="contained"
            startIcon={<HistoryIcon />}
            onClick={() => history.push("/expense/history")}
          >
            {t("ExpenseHistory")}
          </ButtonBlue>
        </div>

        <CardStyle>
          {expense && (
            <CardContent>
              <div fullWidth>
                <StyledHeadLabel
                  variant="body2"
                  color="text.third"
                  gutterBottom
                >
                  {t("SelectStatus")}
                </StyledHeadLabel>
                <StyledAutocomplete
                  name="statusType"
                  id="statusType"
                  value={t(statusType)}
                  options={listStatusType}
                  disableClearable
                  isOptionEqualToValue={(option, value) =>
                    option.status === value
                  }
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setStatusType(newValue.status);
                      setStatusIndex(newValue.index);
                    }
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      placeholder={t("SelectStatus")}
                    />
                  )}
                />
              </div>
              <StyledDivTab>
                {statusIndex != 3 ? (
                  <>
                    <StyledTabs
                      value={statusTab}
                      onChange={handleApproveTabsChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      centered
                    >
                      {"ServiceAndGoods" in expense && (
                        <Tab
                          style={{ minWidth: 180 }}
                          label={
                            <StyledBadge color="secondary">
                              {t("ProductService")}
                              {expense.ServiceAndGoods &&
                              expense.ServiceAndGoods[statusType] &&
                              expense.ServiceAndGoods[statusType].length &&
                              expense.ServiceAndGoods[statusType].length > 0 ? (
                                <StyledChipTab
                                  label={
                                    expense.ServiceAndGoods[statusType].length
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </StyledBadge>
                          }
                          {...a11yProps(0)}
                        />
                      )}

                      {"Allowance" in expense && (
                        <Tab
                          style={{ minWidth: 180 }}
                          label={
                            <StyledBadge color="secondary">
                              {t("Allowance")}
                              {expense.Allowance &&
                              expense.Allowance[statusType] &&
                              expense.Allowance[statusType].length &&
                              expense.Allowance[statusType].length > 0 ? (
                                <StyledChipTab
                                  label={expense.Allowance[statusType].length}
                                />
                              ) : (
                                ""
                              )}
                            </StyledBadge>
                          }
                          {...a11yProps(1)}
                        />
                      )}
                      {"Mileage" in expense && (
                        <Tab
                          style={{ minWidth: 180 }}
                          label={
                            <StyledBadge color="secondary">
                              Mileage
                              {expense.Mileage &&
                              expense.Mileage[statusType] &&
                              expense.Mileage[statusType].length &&
                              expense.Mileage[statusType].length > 0 ? (
                                <StyledChipTab
                                  label={expense.Mileage[statusType].length}
                                />
                              ) : (
                                ""
                              )}
                            </StyledBadge>
                          }
                          {...a11yProps(2)}
                        />
                      )}
                      {"Welfare" in expense && (
                        <Tab
                          style={{ minWidth: 180 }}
                          label={
                            <StyledBadge color="secondary">
                              {t("Welfare")}
                              {expense.Welfare &&
                              expense.Welfare[statusType] &&
                              expense.Welfare[statusType].length &&
                              expense.Welfare[statusType].length > 0 ? (
                                <StyledChipTab
                                  label={expense.Welfare[statusType].length}
                                />
                              ) : (
                                ""
                              )}
                            </StyledBadge>
                          }
                          {...a11yProps(3)}
                        />
                      )}
                      {"FlexCoin" in expense && (
                        <Tab
                          style={{ minWidth: 180 }}
                          label={
                            <StyledBadge color="secondary">
                              Flex Coin
                              {expense.FlexCoin &&
                              expense.FlexCoin[statusType] &&
                              expense.FlexCoin[statusType].length &&
                              expense.FlexCoin[statusType].length > 0 ? (
                                <StyledChipTab
                                  label={expense.FlexCoin[statusType].length}
                                />
                              ) : (
                                ""
                              )}
                            </StyledBadge>
                          }
                          {...a11yProps(4)}
                        />
                      )}
                      {"Advance" in expense && (
                        <Tab
                          style={{ minWidth: 180 }}
                          label={
                            <StyledBadge color="secondary">
                              {t("Advance")}
                              {expense.Advance &&
                              expense.Advance[statusType] &&
                              expense.Advance[statusType].length &&
                              expense.Advance[statusType].length > 0 ? (
                                <StyledChipTab
                                  label={expense.Advance[statusType].length}
                                />
                              ) : (
                                ""
                              )}
                            </StyledBadge>
                          }
                          {...a11yProps(5)}
                        />
                      )}
                      {"ClearAdvance" in expense && (
                        <Tab
                          style={{ minWidth: 180 }}
                          label={
                            <StyledBadge color="secondary">
                              {t("ClearAdvance")}
                              {expense.ClearAdvance &&
                              expense.ClearAdvance[statusType] &&
                              expense.ClearAdvance[statusType].length &&
                              expense.ClearAdvance[statusType].length > 0 ? (
                                <StyledChipTab
                                  label={
                                    expense.ClearAdvance[statusType].length
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </StyledBadge>
                          }
                          {...a11yProps(6)}
                        />
                      )}
                      {"Billing" in expense && (
                        <Tab
                          style={{ minWidth: 180 }}
                          label={
                            <StyledBadge color="secondary">
                              {t("Billing")}
                              {expense.Billing &&
                              expense.Billing[statusType] &&
                              expense.Billing[statusType].length &&
                              expense.Billing[statusType].length > 0 ? (
                                <StyledChipTab
                                  label={expense.Billing[statusType].length}
                                />
                              ) : (
                                ""
                              )}
                            </StyledBadge>
                          }
                          {...a11yProps(7)}
                        />
                      )}
                    </StyledTabs>
                  </>
                ) : (
                  <StyledTabs
                    value={statusTab}
                    onChange={handleApproveTabsChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    centered
                  >
                    {"ClearAdvance" in expense && (
                      <Tab
                        style={{ minWidth: 180 }}
                        label={
                          <StyledBadge color="secondary">
                            {t("ClearAdvance")}
                            {expense.ClearAdvance &&
                            expense.ClearAdvance[statusType] &&
                            expense.ClearAdvance[statusType].length &&
                            expense.ClearAdvance[statusType].length > 0 ? (
                              <StyledChipTab
                                label={expense.ClearAdvance[statusType].length}
                              />
                            ) : (
                              ""
                            )}
                          </StyledBadge>
                        }
                        {...a11yProps(0)}
                      />
                    )}
                  </StyledTabs>
                )}
              </StyledDivTab>

              <Box className="wrap-table">
                {statusIndex != 3 ? (
                  <>
                    <TabPanel value={statusTab} index={0}>
                      <SubTableWithButton
                        data={
                          expense.ServiceAndGoods &&
                          expense.ServiceAndGoods[statusType]
                            ? expense.ServiceAndGoods[statusType]
                            : []
                        }
                        handleClick={handleViewDetail}
                      ></SubTableWithButton>
                    </TabPanel>
                    <TabPanel value={statusTab} index={1}>
                      <SubTableWithButton
                        data={
                          expense.Allowance && expense.Allowance[statusType]
                            ? expense.Allowance[statusType]
                            : []
                        }
                        handleClick={handleViewDetail}
                      ></SubTableWithButton>
                    </TabPanel>
                    <TabPanel value={statusTab} index={2}>
                      <SubTableWithButton
                        data={
                          expense.Mileage && expense.Mileage[statusType]
                            ? expense.Mileage[statusType]
                            : []
                        }
                        handleClick={handleViewDetail}
                      ></SubTableWithButton>
                    </TabPanel>
                    <TabPanel value={statusTab} index={3}>
                      <SubTableWithButton
                        data={
                          expense.Welfare && expense.Welfare[statusType]
                            ? expense.Welfare[statusType]
                            : []
                        }
                        handleClick={handleViewDetail}
                      ></SubTableWithButton>
                    </TabPanel>
                    <TabPanel value={statusTab} index={4}>
                      <SubTableWithButton
                        data={
                          expense.FlexCoin && expense.FlexCoin[statusType]
                            ? expense.FlexCoin[statusType]
                            : []
                        }
                        handleClick={handleViewDetail}
                      ></SubTableWithButton>
                    </TabPanel>
                    <TabPanel value={statusTab} index={5}>
                      <SubTableWithButton
                        data={
                          expense.Advance && expense.Advance[statusType]
                            ? expense.Advance[statusType]
                            : []
                        }
                        handleClick={handleViewDetail}
                      ></SubTableWithButton>
                    </TabPanel>
                    <TabPanel value={statusTab} index={6}>
                      <SubTableWithButton
                        data={
                          expense.ClearAdvance &&
                          expense.ClearAdvance[statusType]
                            ? expense.ClearAdvance[statusType]
                            : []
                        }
                        handleClick={handleViewDetail}
                      ></SubTableWithButton>
                    </TabPanel>
                    <TabPanel value={statusTab} index={7}>
                      <SubTableWithButton
                        data={
                          expense.Billing && expense.Billing[statusType]
                            ? expense.Billing[statusType]
                            : []
                        }
                        handleClick={handleViewDetail}
                      ></SubTableWithButton>
                    </TabPanel>
                  </>
                ) : (
                  <TabPanel value={statusTab} index={0}>
                    <SubTableWithButton
                      data={
                        expense.ClearAdvance && expense.ClearAdvance[statusType]
                          ? expense.ClearAdvance[statusType]
                          : []
                      }
                      handleClick={handleViewDetail}
                    ></SubTableWithButton>
                  </TabPanel>
                )}
              </Box>
            </CardContent>
          )}
        </CardStyle>
      </Container>
    </StyledRoot>
  );
};

export default ExpensePage;
