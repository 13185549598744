import { httpClient } from "./httpClient";

const getAllDocuments = () => {
  return httpClient.get("documents");
};

const getAllDocumentsById = (idCompany) => {
  return httpClient.get("documents/by-company", {
    params: {
      idCompany: idCompany,
    }
  });
};

const getAllDocumentsByIdFilter = (query) => {
  return httpClient.get("documents/by-company-filter", { params: query });
};

const getallCompanyDocuments = () => {
  return httpClient.get(`allDocumentsCompany`)
};

const getallSmartDocumentsById = (idCompany) => {
  return httpClient.get("allSmartDocument/by-company", {
    params: {
      idCompany: idCompany,
    }
  });
};

const getDocument = (id) => {
  return httpClient.get("documents/" + id);
};

const getDowloadCompanyDocument = (idCompany) => {
  return httpClient.get("document/getDowloadCompanyDocument/" + idCompany);
};

const getDowloadEmployeeDocument = (idEmployees, idCompany) => {
  return httpClient.get(`employees/${idEmployees}/${idCompany}/document`);
}

const getisUploadEmployeeDocument = (idEmployees, idCompany) => {
  return httpClient.get(`employees/${idEmployees}/${idCompany}/document`);
}

const getDowloadSmartDocument = (idEmployees, idCompany) => {
  return httpClient.get(`document/${idEmployees}/${idCompany}/getDowloadSmartDocument`);
};

const getDocumentGroupName = () => {
  return httpClient.get("document/GroupName");
};

const getDocumentTypeName = async (idDocumentGroup, id, idDocumentType, documentTypeName, idCompany) => {
  try {
    const response = await httpClient.get("document/TypeName", {
      params: {
        idDocumentGroup: idDocumentGroup,
        idEmployees: id,
        idDocumentType: idDocumentType,
        documentTypeName: documentTypeName,
        idCompany: idCompany,
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching document type name:", error);
    throw error;
  }
};

const getAllDocumentTypesByCompanyId = (idCompany) => {
  return httpClient.get(`document/getAllDocumentTypesByCompanyId`, {
    params: {
      idCompany: idCompany,
    }
  });
};

const getAllCompanyDocumentType = () => {
  return httpClient.get(`document/AllCompanyDocumentType`)
};

const getAllCompanyDocumentGroupName = () => {
  return httpClient.get(`document/AllDocumentGroupName`)
};

const getUnionDocumentGroupAndTypeName = () => {
  return httpClient.get(`document/AllUnionDocumentGroupType`)
};

const addDocument = (formData) => {
  return httpClient.post(`addDocument`, formData);
};

const editDocument = (formData) => {
  return httpClient.put(`editDocument`, formData);
};

const editCompanyDocument = (formData) => {
  return httpClient.put(`editCompanyDocument`, formData);
};

const addDocumentType = (formData) => {
  return httpClient.post(`addDocumentType`, formData);
};

const addCompanyDocumentType = (formData) => {
  return httpClient.post(`addCompanyDocumentType`, formData);
};

const addAllDocumentTypeName = (formData) => {
  return httpClient.post(`addAllDocumentTypeName`, formData);
};
const addDocumentZip = (formData) => {
  return httpClient.post(`addDocumentZip`, formData);
};

const addAllCompanyDocumentType = (formData) => {
  return httpClient.post(`addAllCompanyDocumentType`, formData);
};

const addCompanyDocumentGroup = (formData) => {
  return httpClient.post(`addCompanyDocumentGroup`, formData);
};

const addCompanyDocument = (formData) => {
  return httpClient.post(`addCompanyDocument`, formData);
};

const createPdfDocument = (formData) => {
  return httpClient.post(`createPdfDocument`, formData);
};

const upsertPdfSmartDocument = (formData) => {
  return httpClient.post(`smart-document-html`, formData);
};

const getSmartDocumentHtmlById = (id) => {
  return httpClient.get(`smart-document-html/${id}`,);
};

const getSmartDocumentPDF = (id, idEmployees = null, idCompany = null, type,idWarningLetter=null) => {
  let query = '';
  if (idCompany) query += `?idCompany=${idCompany}`;
  if (idEmployees) query += query ? `&idEmployees=${idEmployees}` : `?idEmployees=${idEmployees}`;
  if (type) query += query ? `&type=${type}` : `?type=${type}`;
  if (idWarningLetter) query += query ? `&idWarningLetter=${idWarningLetter}` : `?idWarningLetter=${idWarningLetter}`;
  return httpClient.post(`smart-document-html/generate-pdf-html/${id}${query}`);
};

const editDocumentType = (formData) => {
  return httpClient.put(`editDocumentType`, formData);
};

const editCompanyDocumentTypeGroup = (formData) => {
  return httpClient.put(`editCompanyDocumentTypeGroup`, formData);
};


const deleteCompanyDocumentInfo = (formData) => {

  const url = `deleteCompanyDocumentInfo`;

  return httpClient.delete(url, {
    data: formData
  });
};


const deleteEmployeeDocumentInfo = async (formData) => {
  try {
    const response = await httpClient.delete("deleteEmployeeDocumentInfo", {
      params: {
        idDocumentGroup: formData.idDocumentGroup,
        idEmployees: formData.id,
        idDocumentType: formData.idDocumentType,
        documentTypeName: formData.documentTypeName,
        idCompany: formData.idCompany,
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching document type name:", error);
    throw error;
  }

};


const deleteCompanyDocumentGroup = (formData) => {

  const encodedDocumentGroupName = encodeURIComponent(formData.documentGroupName);

  const url = `deleteCompanyDocumentGroup/${encodedDocumentGroupName}`;

  return httpClient.delete(url);
};

const deleteCompanyDocument = (formData) => {

  const encodedDocumentGroupName = encodeURIComponent(formData.documentGroupName);
  const encodedDocumentTypeName = encodeURIComponent(formData.documentTypeName);
  const encodedFilename = encodeURIComponent(formData.filename);
  const encodedidCompanyDocumentType = encodeURIComponent(formData.idCompanyDocumentType);
  const encodedidCompany = encodeURIComponent(formData.idCompany);


  const url = `deleteCompanyDocument/${encodedDocumentGroupName}/${encodedDocumentTypeName}/${encodedidCompany}/${encodedFilename}/${encodedidCompanyDocumentType}`;
  return httpClient.delete(url);
};


const getSmartDocumentsTextInfoById = (smartDocumentId, UserProfile, companyProfile) => {
  return httpClient.get("smartDocumentsTextInfoById/by-company", {
    params: {
      smartDocumentId: smartDocumentId,
      UserProfile: UserProfile,
      companyProfile: companyProfile,
    }
  });
};

const addSmartDocument = (formData) => {
  return httpClient.post(`addSmartDocument`, formData);
};

const editSmartDocument = (formData) => {
  return httpClient.put(`editSmartDocument`, formData);
};

const deleteSmartDocumentInfo = (formData) => {
  const url = `deleteSmartDocumentInfo`;
  return httpClient.delete(url, {
    data: formData
  });
};

const getAllAdditionalSmartDocument = (idCompany = null, isShow) => {
  let query = '';
  if (idCompany) query += `?idCompany=${idCompany}`;
  if (isShow === 'all') query += query ? `&isShow=${isShow}` : `?isShow=${isShow}`;
  return httpClient.get(`additional-smart-document-html${query}`);
};

const upsertAdditionalSmartDocument = (data) => {
  return httpClient.post(`additional-smart-document-html`, data);
};

const deleteAdditionalSmartDocument = (id) => {
  return httpClient.delete(`additional-smart-document-html/${id}`);
};

export default {
  getAllDocuments,
  getAllDocumentsById,
  getAllDocumentsByIdFilter,
  getallCompanyDocuments,
  getDocument,
  getDowloadCompanyDocument,
  getisUploadEmployeeDocument,
  getDowloadEmployeeDocument,
  getDowloadSmartDocument,
  getallSmartDocumentsById,
  getDocumentGroupName,
  getDocumentTypeName,
  getAllDocumentTypesByCompanyId,
  getUnionDocumentGroupAndTypeName,
  getAllCompanyDocumentType,
  addAllCompanyDocumentType,
  getAllCompanyDocumentGroupName,
  addDocument,
  addDocumentType,
  addCompanyDocumentType,
  addCompanyDocument,
  addAllDocumentTypeName,
  addCompanyDocumentGroup,
  createPdfDocument,
  editDocument,
  editCompanyDocument,
  editCompanyDocumentTypeGroup,
  editDocumentType,
  deleteEmployeeDocumentInfo,
  deleteCompanyDocumentGroup,
  deleteCompanyDocument,
  deleteCompanyDocumentInfo,
  getSmartDocumentsTextInfoById,
  addSmartDocument,
  editSmartDocument,
  deleteSmartDocumentInfo,
  addDocumentZip,
  upsertPdfSmartDocument,
  getSmartDocumentHtmlById,
  getSmartDocumentPDF,
  getAllAdditionalSmartDocument,
  upsertAdditionalSmartDocument,
  deleteAdditionalSmartDocument
};
