import { httpClient } from "./httpClient";


const getAllJobDescription = (idPosition)=>{
    return httpClient.get(`jobDescription/${idPosition}`);
}


const addJobDescriptions = (formData)=>{
    return httpClient.post(`/addJobDescriptions`,formData);
}


const updateJobDescription = (formData)=>{
    return httpClient.put(`/updateJobDescription`,formData);
}


const deleteJobDescription = (formData)=> {
    return httpClient.delete('/deleteJobDescription',{ data: formData });
}


export default {
    getAllJobDescription,
    addJobDescriptions,
    updateJobDescription,
    deleteJobDescription
}