import {
  PROJECT_FETCHING,
  PROJECT_FAILED,
  PROJECT_SUCCESS,
  EMPLOYEE_REWARD_FETCHING,
  EMPLOYEE_REWARD_FAILED,
  EMPLOYEE_REWARD_SUCCESS,
} from "./types";

import ProjectService from "../services/project.service";

export const getAllEmployeeProject = (query) => async (dispatch) => {
  try {
    dispatch({ type: PROJECT_FETCHING });
    const res = await ProjectService.getAllEmployeeProject(query);
    if (res) {
      dispatch({
        type: PROJECT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: PROJECT_FAILED,
    });
  }
};

export const getEmployeeProject = async (idEmployeeProject) => {
  try {
    const res = await ProjectService.getEmployeeProject(idEmployeeProject);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getManagerProjectToApprove = async (start, end) => {
  try {
    const res = await ProjectService.getManagerProjectToApprove(start, end);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateStatusProject = async (query) => {
  try {
    const res = await ProjectService.updateStatusProject(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getProjectByEmp = (query) => async (dispatch) => {
  try {
    dispatch({ type: PROJECT_FETCHING });
    const res = await ProjectService.getProjectByEmp(query);
    if (res) {
      dispatch({
        type: PROJECT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: PROJECT_FAILED,
    });
  }
};

export const addProject = async (query) => {
  try {
    const res = await ProjectService.addProject(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteProject = async (query) => {
  try {
    const res = await ProjectService.deleteProject(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateProject = async (id, query) => {
  try {
    const res = await ProjectService.updateProject(id, query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAdminProjectApprove = (query) => async (dispatch) => {
  try {
    dispatch({ type: PROJECT_FETCHING });
    const res = await ProjectService.getAdminProjectApprove(query);
    if (res) {
      dispatch({
        type: PROJECT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: PROJECT_FAILED,
    });
  }
};

export const updateAdminProjectApprove = (query) => async (dispatch) => {
  try {
    dispatch({ type: PROJECT_FETCHING });
    const res = await ProjectService.updateAdminProjectApprove(query);
    if (res) {
      dispatch({
        type: PROJECT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: PROJECT_FAILED,
    });
  }
};

export const getAllEmployeeReward = (query) => async (dispatch) => {
  try {
    dispatch({ type: EMPLOYEE_REWARD_FETCHING });
    const res = await ProjectService.getAllEmployeeReward(query);
    if (res) {
      dispatch({
        type: EMPLOYEE_REWARD_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEE_REWARD_FAILED,
    });
  }
};

export const getEmployeeRewardByEmp = (query) => async (dispatch) => {
  try {
    dispatch({ type: EMPLOYEE_REWARD_FETCHING });
    const res = await ProjectService.getEmployeeRewardByEmp(query);
    if (res) {
      dispatch({
        type: EMPLOYEE_REWARD_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEE_REWARD_FAILED,
    });
  }
};

export const getEmployeeReward = async (id) => {
  try {
    const res = await ProjectService.getEmployeeReward(id);
    if (res) {
      return res
    }
  } catch (error) {
    console.error(error);
  }
};

export const addEmployeeReward = async (formData) => {
  try {
    const res = await ProjectService.addEmployeeReward(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateEmployeeReward = async (id, formData) => {
  try {
    const res = await ProjectService.updateEmployeeReward(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteEmployeeReward = async (id) => {
  try {
    const res = await ProjectService.deleteEmployeeReward(id);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getManagerRewardApprove = (query) => async (dispatch) => {
  try {
    dispatch({ type: EMPLOYEE_REWARD_FETCHING });
    const res = await ProjectService.getManagerRewardApprove(query);
    if (res) {
      dispatch({
        type: EMPLOYEE_REWARD_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEE_REWARD_FAILED,
    });
  }
};

export const updateManagerRewardApprove = async (formData) => {
  try {
    const res = await ProjectService.updateManagerRewardApprove(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAdminRewardApprove = (query) => async (dispatch) => {
  try {
    dispatch({ type: EMPLOYEE_REWARD_FETCHING });
    const res = await ProjectService.getAdminRewardApprove(query);
    if (res) {
      dispatch({
        type: EMPLOYEE_REWARD_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEE_REWARD_FAILED,
    });
  }
};

export const updateAdminRewardApprove = async (formData) => {
  try {
    const res = await ProjectService.updateAdminRewardApprove(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

