import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import ProfileTab from "./profile";
import Expertise from "./expertise";
import LocationTab from "./location";
import DialogSinatureImage from "./DialogSinatureImage"
import ChangePasswordTab from "./change-password";
import AlertResponse from "../../shared/general/AlertResponse";
import PF from "./pf";


//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledTabs = styled(Tabs)({
  "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
    borderColor: "#46cbe2",
    color: "#46cbe2!important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#46cbe2",
  },
  "& i": {
    marginRight: 8,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RightPanel = ({ state }) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(0);
  const [tabItem, setTabItem] = useState([
    {
      label: `${t("PersonalInfo")}`,
      icon: <i className="fal fa-address-card"></i>,
    },
    {
      label: `${t("mySignature")}`,
      icon: <i className="fa-regular fa-signature"></i>,
    },
    {
      label: `${t("Address")}`,
      icon: <i className="fal fa-map-marked-alt"></i>,
    },
    {
      label: `${t("Education")}`,
      icon: <i className="fal fa-user-graduate"></i>,
    },
    {
      label: `${t("ProvidentFund")}`,
      icon: <i className="fa-regular fa-sack-dollar"></i>,
    },
    {
      label: `${t("ChangePassword")}`,
      icon: <i className="fa-regular fa-unlock-keyhole"></i>,
    },
  ]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  useEffect(() => {
    if (state && state.tab && state.tab === "providentFund") {
      setValue(4);
    }
  }, [state]);

  useEffect(() => {
    if (location.hash === "#providentFund") {
      setValue(4);
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const { result: employeeProfile } = useSelector((state) => state.userProfile);

  return (
    <div style={{ width: "100%", marginTop: 16 }}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          "& .MuiTab-root.Mui-selected": {
            color: "#46cbe2",
          },
        }}
        TabIndicatorProps={{
          style: {
            backgroundColor: "#46cbe2",
          },
        }}
      >
        {tabItem.map((value, index) => (
          <Tab
            key={index}
            label={
              <Box display="flex">
                {value.icon} {value.label}
              </Box>
            }
            {...a11yProps(index)}
          />
        ))}
      </StyledTabs>
      <div style={{ padding: "24px" }}>
        {value === 0 &&
          <ProfileTab
            handleChangeAlertType={handleChangeAlertType}
            handleOpenAlert={handleOpenAlert}
          />
        }
        {value === 1 && employeeProfile && (
          //  <Mysignature employeeProfile={employeeProfile}/>
          <DialogSinatureImage employeeProfile={employeeProfile} />
        )}
        {value === 2 &&
          <LocationTab
            handleChangeAlertType={handleChangeAlertType}
            handleOpenAlert={handleOpenAlert}
          />
        }
        {value === 3 &&
          <Expertise />
        }
        {value === 4 &&
          <PF />
        }
        {value === 5 &&
          <ChangePasswordTab />
        }
      </div>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </div>
  );
};

export default RightPanel;