import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { Controller } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Typography, MenuItem, IconButton } from "@mui/material";
const FormQuestion = (props) => {
  const { control, question, index, remove } = props;
  const { t } = useTranslation();

  return (
    <Grid
      container
      spacing={2}
      sx={{ marginTop: "10px", marginBottom: "10px" }}
    >
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography fontWeight={500}>{`${t("Question")} ${
          index + 1
        }`}</Typography>
        {index != 0 && (
          <IconButton color="error" onClick={() => remove(index)}>
            <DeleteIcon />
          </IconButton>
        )}
      </Grid>
      <Grid item xs={6}>
        <Typography className="label" color="text.third">
          {t("QuestionType")}
        </Typography>
        <Controller
          control={control}
          name={`questionList.${index}.questionType`}
          render={({ field, fieldState }) => (
            <TextFieldTheme
              {...field}
              select
              error={Boolean(fieldState.error)}
              helperText={fieldState.error && fieldState.error.message}
            >
              <MenuItem value={"text"}>Text</MenuItem>
              <MenuItem value={"choice"}>Choice</MenuItem>
            </TextFieldTheme>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography className="label" color="text.third">
          {t("Question")}
        </Typography>
        <Controller
          control={control}
          name={`questionList.${index}.question`}
          render={({ field, fieldState }) => (
            <TextFieldTheme
              {...field}
              error={Boolean(fieldState.error)}
              helperText={fieldState.error && fieldState.error.message}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default FormQuestion;
