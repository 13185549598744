import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  TableCell,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Loading from "../../../../assets/social-media.gif";
import { useTranslation } from "react-i18next";
import CardStyle from "../../../../shared/general/Card";
import AddIcon from "@mui/icons-material/Add";
import { getUserFullName } from "../../../../../../utils/userData";
import CardAppraisee from "./CardAppraisee";
import AddAppraiserDialog from "./addAppraiserDialog";

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  alignItems: "center",
  textAlign: "center",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));

function TableAppraiser(props) {
  const { t } = useTranslation();
  const {SetRatioSample,ratioSampleSize} = props;
  const { isFetching: appraiseesLoading, result: appraisees } = useSelector(
    (state) => state.potentialAppraiser
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [openDialog, setOpenDialog] = useState({
    isOpen: false,
    data: null,
  });

  return (
    <div>
      {appraiseesLoading && (
        <Box
          style={{
            minHeight: 400,
            position: "sticky",
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img width="120" alt="loading" src={Loading} />
        </Box>
      )}
      {(appraisees === null || appraisees.length === 0) && (
        <Box
          style={{
            minHeight: 400,
            position: "sticky",
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>{t("NoData")}</Typography>
        </Box>
      )}
      {appraisees && appraisees.length >= 1 && (
        <CardStyle
          style={{
            padding:"24px"
          }}
        >
          <Grid
            container
            spacing={2}
          >
            {appraisees.map((e, index) => (
              <Grid key={index} item xs={12} sm={4}>
                <CardAppraisee
                  appraisee={e}
                  setOpenDialog={() => {
          
                    setOpenDialog((prev) => ({
                      ...prev,
                      isOpen: true,
                      data: e,
                    }));
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </CardStyle>
      )}
      {openDialog.isOpen && (
        <AddAppraiserDialog
          openDialog={openDialog}
          ratioSampleSize = {ratioSampleSize}
          onClose={() => {
            setOpenDialog((prev)=>({
              isOpen:false,
              data:null
            }));
          }}
        />
      )}
    </div>
  );
}

export default TableAppraiser;
