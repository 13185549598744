import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { useLocation } from "react-router-dom";
import CardStyle from "../../shared/general/Card";
import LoadingIcon from "../../assets/social-media.gif";
import Swal from "sweetalert2";
import { makeStyles } from "@mui/styles";
import ExcelJS from "exceljs";
import {
  Grid,
  Typography,
  Container,
  TableContainer,
  Paper,
  Box,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Chip,
  Button,
  Tooltip,
} from "@mui/material";
import SnackBarCustom from "../../shared/snackbarCustom";
import { useTranslation } from "react-i18next";
import AddJobDescription from "./AddJobDescription";
import {
  getAllJobDescription,
  deleteJobDescription,
} from "../../../../actions/jobDescription";

import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import handlerExportPDF from "./file_pdf";

const useStyles = makeStyles({
  root: {
    marginTop: "20px",
    minHeight: "calc(100vh - 20px)",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  textNumber: {
    fontSize: 30,
    fontWeight: "bold",
    marginLeft: 20,
    color: "#007AFC",
  },
  mainData: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "#007AFC",
  },
  data: {
    fontSize: "1rem",
  },
  headText: {
    fontSize: "1rem",
    color: "#9e9e9e",
  },
  iconButton: {
    transition: "transform 0.3s ease",
    color: "#000000",
    "&:hover": {
      transform: "scale(1.2)",
      color: "#3f51b5",
    },
  },
  pdfButton: {
    transition: "transform 0.3s ease",
    color: "#000000",
    "&:hover": {
      transform: "scale(1.2)",
      color: "#1E90FF",
    },
  },
  iconButton: {
    transition: "transform 0.3s ease",
    color: "#000000",
    "&:hover": {
      transform: "scale(1.2)",
      color: "#00CC00",
    },
  },
  iconButtonDelete: {
    transition: "transform 0.3s ease",
    color: "#000000",
    "&:hover": {
      transform: "scale(1.2)",
      color: "#FF0000",
    },
  },
  cancelButton: {
    color: "rgba(0, 0, 255, 0.7)",
    border: "1px solid blue",
    borderRadius: "5px",
    padding: "5px 10px",
    fontSize: "13px",
    fontWeight: "bold",
    backgroundColor: "transparent",
    cursor: "pointer",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: "rgba(173, 216, 230, 0.6)",
    },
  },
  confirmButton: {
    color: "white",
    borderRadius: "5px",
    padding: "5px 10px",
    fontSize: "13px",
    fontWeight: "bold",
    backgroundColor: "rgba(255, 0, 0, 0.6)",
    cursor: "pointer",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: "rgba(255, 0, 0, 0.5)",
    },
  },
  dialogBackground: {
    "& .MuiDialog-paper": {
      backgroundColor: "##BEBEBE",
      borderRadius: "7px",
    },
    "& .css-ut4szi": {
      fontSize: "24px",
      width: "90vw",
      height: "90vh",
    },
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "none",
        border: "0px solid black",
        background: "white",
      },
    },
    "& .MuiBackdrop-root": {
      backdropFilter: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: "40vw",
      maxHeight: "60vh",
    },
    "& .MuiBackdrop-root.MuiModal-backdrop": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: "0vw",
      maxHeight: "0vh",
    },
  },
  button: {
    backgroundColor: "#f0f0f0",
    color: "#333",
    borderRadius: "10px",
    padding: "10px 20px",
    border: "none",
    boxShadow: "none",
    backgroundColor: "#0000",
  },
});

const StyledHeadingPage = styled("div")({
  marginBottom: 16,
});

const StyledRoot = styled("div")({
  minHeight: "calc(100vh - 20px)",
  "& .TextWhite": {
    color: "white",
  },
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  alignItems: "center",
  textAlign: "center",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const JobDescription = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();

  const { t, i18n } = useTranslation();
  const { item } = location.state || {};
  const { result: jobDescriptions } = useSelector(
    (state) => state.jobDescription
  );
  const [snackBarConfig, setSnackBarConfig] = useState({
    open: false,
    message: "",
    type: "success",
  });
  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: null,
  });

  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleCloseDialog = async () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleClickOpen = (row, dialogType) => {
    setSelectedRow(row);
    if (dialogType === "edit") {
      setDrawerConfig((prev) => ({
        ...prev,
        isOpen: true,
        isEdit: true,
        data: row,
      }));
    } else if (dialogType === "delete") {
      setOpenDialogDelete(true);
    } else {
      setDrawerConfig((prev) => ({
        ...prev,
        isOpen: true,
        isEdit: false,
        data: null,
      }));
    }
  };

  const handleConfirmDelete = async (type, formData) => {
    console.log(formData);
    setOpenDialogDelete(false);
    try {
      const result = await dispatch(deleteJobDescription(formData));
      setLoading(true);
      if (result && result.status === 200) {
        dispatch(getAllJobDescription(item.idPosition));
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: `${t("DeletedSuccessfully")}!!`,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: `${t("AnErrorOccurred")}!!`,
          text: `${t("PleaseTryAgain")}!!`,
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error deleting document:", error);
      Swal.fire({
        icon: "error",
        title: `${t("AnErrorOccurred")}!!`,
        text: `${t("PleaseTryAgain")}!!`,
        confirmButtonText: "OK",
      });
    } finally {
      setLoading(false);
      setOpenDialogDelete(false);
    }
  };

  const columns = [
    {
      key: "Task",
      label: "Task",
      minWidth: "500px",
      width: "500px",
      maxWidth: "500px",
      cellRender: (row) => (
        <Box flexGrow={1} overflow="hidden">
          <Typography
            whiteSpace="normal"
            wordWrap="break-word"
            overflow="hidden"
          >
            {row.task}
          </Typography>
        </Box>
      ),
    },
    {
      key: "Core",
      label: "Core",
      minWidth: "160px",
      width: "160px",
      textAlign: "center",
      cellRender: (row) => {
        // กำหนดสีตามค่า coreName
        const getChipColor = (typeCore) => {
          switch (typeCore) {
            case 1:
              return { backgroundColor: "#ff1744", color: "#fff" }; // สีแดง
            case 2:
              return { backgroundColor: "#4caf50", color: "#fff" }; // สีเขียว
            default:
              return { backgroundColor: "#9e9e9e", color: "#fff" }; // สีเทา (ค่าเริ่มต้น)
          }
        };

        return (
          <Box flexGrow={1} overflow="hidden">
            <Chip
              label={row.coreName.toUpperCase()}
              sx={{
                fontWeight: 600,
              }}
              style={getChipColor(row.type_core)} // ใช้สีตามค่าที่ตรวจสอบ
            />
          </Box>
        );
      },
    },
    {
      key: "Weight",
      label: "Weight",
      minWidth: "150px",
      width: "150px",
      textAlign: "center",
      cellRender: (row) => (
        <Box flexGrow={1} overflow="hidden">
          <Typography
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {row.weight}
          </Typography>
        </Box>
      ),
    },
    {
      key: "Action",
      label: "Action",
      minWidth: "120px",
      width: "120px",
      textAlign: "center",
      cellRender: (row) => (
        <Box flexGrow={1} overflow="hidden">
          <IconButton
            style={{ color: "#ffea00" }}
            onClick={() => handleClickOpen(row, "edit")}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            style={{ color: "#ff1744" }}
            onClick={() => handleClickOpen(row, "delete")}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const handleExportExcel = async () => {
    const header = [
      t("Task"),
      t("core"),
      t("Weight"),
      t("createDate"),
      t("updateAt"),
    ];

    // สร้าง Workbook และ Worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(t("jobDescription"));

    // ตั้งค่าความกว้างของคอลัมน์
    worksheet.columns = [
      { width: 50 },
      { width: 15 },
      { width: 15 },
      { width: 20 },
      { width: 20 },
    ];

    // เพิ่ม Header
    const headerRow = worksheet.addRow(header);

    // สไตล์สำหรับ Header
    const headerStyle = {
      font: {
        bold: true,
        size: 18,
        name: "TH SarabunPSK",
        color: { argb: "FFFFFF" },
      },
      alignment: { horizontal: "center", vertical: "middle" },
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "081F5C" } },
      border: {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      },
    };

    // ตั้งค่ารูปแบบ Header
    headerRow.eachCell((cell) => {
      cell.font = headerStyle.font;
      cell.alignment = headerStyle.alignment;
      cell.fill = headerStyle.fill;
      cell.border = headerStyle.border;
    });

    worksheet.getRow(1).height = 50;

    // สไตล์สำหรับข้อมูลในแต่ละเซลล์
    const bodyStyle = {
      font: { size: 18, name: "TH SarabunPSK" },
      border: {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      },
    };

    // เพิ่มข้อมูลในตาราง
    jobDescriptions.forEach((item) => {
      const row = [
        item.task || t("N/A"),
        item.coreName || t("N/A"),
        item.weight || t("N/A"),
        item.createDate
          ? new Date(item.createDate).toLocaleDateString()
          : t("N/A"),
        item.updateAt ? new Date(item.updateAt).toLocaleDateString() : t("N/A"),
      ];
      const excelRow = worksheet.addRow(row);

      // ตั้งค่ารูปแบบข้อมูล
      excelRow.eachCell((cell) => {
        cell.font = bodyStyle.font;
        cell.border = bodyStyle.border;
      });
    });

    // เขียนไฟล์ Excel และดาวน์โหลด
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.download = `JoBDescription_IdPosition_${item.idPosition}.xlsx`;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
  };
  const getLastedUpdate = () => {
    const dates = jobDescriptions.map((e) => new Date(e.updateAt));
    return new Date(Math.max(...dates)).toLocaleDateString("th-TH", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };
  
  useEffect(() => {
    console.log(item);
    dispatch(getAllJobDescription(item.idPosition));
  }, []);

  return (
    <StyledRoot className="page">
      <SnackBarCustom config={snackBarConfig} setConfig={setSnackBarConfig} />
      <Container maxWidth="lg" sx={{ paddingBottom: "5%" }}>
        <Grid container alignItems={"center"} justifyContent="space-between">
          <Grid xs={6}>
            <StyledHeadingPage>
              <Typography variant="h4" style={{ paddingTop: 8 }}>
                รายละเอียดตำแหน่ง
              </Typography>
            </StyledHeadingPage>
          </Grid>

          <Grid item container spacing={1} xs={6} justifyContent={"end"}>
            <Grid item>
              <Tooltip title={`${t("Download")} Excel`}>
                <ButtonBlue
                  className="download"
                  variant="outlined"
                  disabled={jobDescriptions && jobDescriptions.length === 0}
                  aria-label="download"
                  onClick={handleExportExcel}
                  startIcon={<FileDownloadIcon />}
                >
                  {t("Download")} Excel
                </ButtonBlue>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={`${t("Download")} PDF`}>
                <ButtonBlue
                  className="download"
                  variant="outlined"
                  disabled={jobDescriptions && jobDescriptions.length === 0}
                  aria-label="download"
                  onClick={() => handlerExportPDF(jobDescriptions)}
                  startIcon={<FileDownloadIcon />}
                >
                  {t("Download")} PDF
                </ButtonBlue>
              </Tooltip>
            </Grid>
            <Grid item>
              <ButtonBlue
                variant="contained"
                startIcon={<AddIcon />}
                style={{ marginRight: 10 }}
                onClick={() => handleClickOpen(null, "add")}
              >
                {t("AddTask")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </Grid>
        <CardStyle>
          <Grid
            container
            justifyContent={"space-between"}
            sx={{ padding: "24px" }}
          >
            <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
              <Typography className={classes.headText}>
                {t("JobPosition")}
              </Typography>
              <Typography className={classes.mainData}>
                {i18n.language === "th"
                  ? item.positionName
                  : item.positionName_EN}
              </Typography>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
              <Typography className={classes.headText}>
                {t("positionCode")}
              </Typography>
              <Typography className={classes.mainData}>
                {item.positionCode}
              </Typography>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
              <Typography className={classes.headText}>
                {t("JobGroup")}
              </Typography>
              <Typography className={classes.mainData}>
                {item.jobGroupName || "-"}
              </Typography>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
              <Typography className={classes.headText}>
                {t("Quantity")}
              </Typography>
              <Typography className={classes.mainData}>
                {item.numberOfEmp || "-"}
              </Typography>
            </Grid>
          </Grid>
        </CardStyle>
        <CardStyle style={{ marginTop: 10 }}>
          <TableContainer component={Paper} style={{ minHeight: 600 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((c, index) => (
                    <StyledCellHeader
                      key={index}
                      sx={{
                        textAlign: c.headerTextAlign
                          ? c.headerTextAlign
                          : undefined,
                        minWidth: c.minWidth ? c.minWidth : undefined,
                        width: c.width ? c.width : undefined,
                        maxWidth: c.maxWidth ? c.maxWidth : undefined,
                      }}
                    >
                      {c.key === "Weight" ? `${t(c.key)}(%)` : t(c.key)}
                    </StyledCellHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {jobDescriptions &&
                  jobDescriptions.length > 0 &&
                  jobDescriptions.map((r, rIndex) => {
                    return (
                      <TableRow>
                        {columns.map((c, cIndex) => (
                          <TableCell
                            key={cIndex}
                            style={{
                              textAlign: c.textAlign ? c.textAlign : undefined,
                              fontSize: c.cellFontSize
                                ? c.cellFontSize
                                : undefined,
                              minWidth: c.minWidth ? c.minWidth : undefined,
                              width: c.width ? c.width : undefined,
                              maxWidth: c.maxWidth ? c.maxWidth : undefined,
                            }}
                          >
                            {c.cellRender ? (
                              c.cellRender(r)
                            ) : c.fieldKey ? (
                              <Typography fontSize="inherit">
                                {r[c.fieldKey]}
                              </Typography>
                            ) : null}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {jobDescriptions && jobDescriptions.length === 0 && (
              <Box
                style={{
                  minHeight: 400,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>{t("NoData")}</Typography>
              </Box>
            )}
          </TableContainer>
        </CardStyle>
        {jobDescriptions && jobDescriptions.length > 0 && (
          <Typography sx={{ marginTop: "20px", textAlign: "end" }}>
            อัพเดทเมื่อ {getLastedUpdate()}
          </Typography>
        )}

        <AddJobDescription
          drawerConfig={drawerConfig}
          setDrawerConfig={setDrawerConfig}
          onClose={handleCloseDialog}
          idPosition={item.idPosition}
          setSnackBarConfig={setSnackBarConfig}
        />

        <Dialog
          open={openDialogDelete}
          onClose={() => {
            setOpenDialogDelete(false);
          }}
          className={classes.dialogBackground}
          style={{ backgroundColor: "rgba(100, 100, 100, 0.4)" }}
        >
          {loading ? (
            <img src={LoadingIcon} style={{ height: 80, width: 80 }} />
          ) : (
            <>
              <DialogTitle style={{ fontSize: "17px", fontWeight: "bold" }}>
                {t("ConfirmDeleteItem")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText style={{ fontSize: "14px", color: "black" }}>
                  {t("AreYouSure")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  className={classes.cancelButton}
                  onClick={() => {
                    setOpenDialogDelete(false);
                  }}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  variant="contained"
                  className={classes.confirmButton}
                  onClick={() => {
                    handleConfirmDelete("delete", selectedRow);
                  }}
                >
                  {t("Confirm")}
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </Container>
    </StyledRoot>
  );
};

export default JobDescription;
