import React, { useEffect, useState } from 'react';
import {
    Box,
    Breadcrumbs,
    CardActionArea,
    Container, styled,
    Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link, useLocation } from 'react-router-dom';
import ButtonBlue from '../../../shared/general/ButtonBlue';
import StyledCard from "../../../shared/general/Card";
import { AddDevTalentPlan } from './addDevPlan';
import AlertResponse from '../../../shared/general/AlertResponse';
import { getDevelopmentTalentPlan } from '../../../../../actions/employee';
import loading from '../../../assets/social-media.gif';

const StyledRoot = styled("div")({
    backgroundColor: "#FFFFFF !important",
});

export default function DevelopmentTalentPlan() {
    const { t } = useTranslation();
    // const history = useHistory();
    const location = useLocation();
    const { employee, year } = location.state;
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [devTalentPlan, setDevTalentPlan] = useState(null);

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const fetchedEmployeeDevTalent = async (idEmployeeTalent) => {
        try {
            setIsFetching(true);
            const response = await getDevelopmentTalentPlan({ idEmployeeTalent });
            if (response && response.data && response.data.length > 0) {
                setDevTalentPlan(response.data);
            };
        } catch (error) {
            console.error(error);
        } finally {
            setIsUpdated(false);
            setIsFetching(false);
        };
    };

    useEffect(() => {
        if (employee && employee.idEmployeeTalent) {
            fetchedEmployeeDevTalent(employee.idEmployeeTalent);
        };
    }, [employee]);

    useEffect(() => {
        if (isUpdated && employee && employee.idEmployeeTalent) {
            fetchedEmployeeDevTalent(employee.idEmployeeTalent);
        };
    }, [employee, isUpdated]);

    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={"/home"}
                    >
                        {t("AppMenu.Home")}
                    </Link>
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={"/admin/talent"}
                    >
                        Talent
                    </Link>
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={{
                            pathname: "/talent-summary",
                            state: { year: year }
                        }}
                    >
                        ข้อมูลสรุป Talent
                    </Link>
                    <Typography color="text.primary">
                        แผนพัฒนา Talent
                    </Typography>
                </Breadcrumbs>

                <div style={{ marginBottom: 8, marginTop: 10 }}>
                    <Typography variant="h4">
                        แผนพัฒนา Talent
                    </Typography>
                </div>

                <Box
                    sx={{
                        p: 2,
                        mt: 2
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#f4f4f4",
                            borderRadius: 4,
                            height: 200,
                        }}
                    >
                        <Typography variant="h6">
                            เฟรมข้อมูลแผนพัฒนา Talent
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            mt: 6
                        }}
                    >
                        <ButtonBlue variant={"contained"} onClick={() => setOpenAddDialog(true)}>
                            ทำแผนพัฒนา
                        </ButtonBlue>
                    </Box>

                    {isFetching ? (
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src={loading} style={{ width: "80px", height: "80px" }} />
                        </Box>
                    ) : (
                        <Box sx={{ mt: 2 }}>
                            {devTalentPlan && devTalentPlan.length > 0 ? (
                                <>
                                    {devTalentPlan.map((item, index) => (
                                        <Box sx={{ mt: 2 }} key={index}>
                                            <StyledCard>
                                                <CardActionArea>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                            padding: "8px 0",
                                                            borderBottom: "1px solid #f4f4f4",
                                                            p: 2
                                                        }}
                                                    >
                                                        <Typography variant="body1">{item.planName}</Typography>
                                                        <Box
                                                            sx={{
                                                                borderWidth: 1,
                                                                borderRadius: 4,
                                                                borderColor: "#f4f4f4",
                                                                padding: "4px 12px",
                                                                backgroundColor: `${item.status === 1 ? "lightgreen" : (item.status === 0 ? "#ffa000" : "lightgray")}`, // Status color
                                                            }}
                                                        >
                                                            <Typography variant="body2" sx={{ color: `${item.status === 1 ? "#2e7d32" : (item.status === 0 ? "#bf360c" : "#757575")}` }}>
                                                                {item.status === 1 ? "สำเร็จ" : (item.status === 0 ? "กำลังดำเนินการ" : "รอดำเนินการ")}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </CardActionArea>
                                            </StyledCard>
                                        </Box>
                                    ))}
                                </>
                            ) : (
                                <StyledCard>
                                    <Typography textAlign={"center"}>
                                        {t("NoData")}
                                    </Typography>
                                </StyledCard>
                            )}
                        </Box>
                    )}
                </Box>
            </Container>

            <AddDevTalentPlan
                open={openAddDialog}
                setOpen={setOpenAddDialog}
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
                setAlertType={setAlertType}
                setIsUpdated={setIsUpdated}
                idEmployees={employee && employee.idEmployees}
                idCompany={employee && employee.idCompany}
                idEmployeeTalent={employee && employee.idEmployeeTalent}
            />

            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </StyledRoot>
    );
}   