import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Grid, MenuItem, styled, Typography } from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import NumberFormatTheme from "../../shared/general/NumberFormatTheme";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import {
  updateCompanyProfile,
  getCompanyProfile,
} from "../../../../actions/company";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import NumberFormatComponent from "../../shared/general/NumberFormatComponent";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  marginBottom: 8,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DrawerAddress = (props) => {
  const { open, onClose, selectedCompany } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: companyProfile } = useSelector(
    (state) => state.companyProfile
  );
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      address: "",
      district: "",
      province: "",
      address_EN: "",
      district_EN: "",
      province_EN: "",
      areaCode: "",
    },
  });

  useEffect(() => {
    if (companyProfile) {
      reset({
        address: companyProfile.address,
        district: companyProfile.district,
        province: companyProfile.province,
        address_EN: companyProfile.address_EN,
        district_EN: companyProfile.district_EN,
        province_EN: companyProfile.province_EN,
        areaCode: companyProfile.areaCode,
      });
    }
  }, [companyProfile]);

  const onHandleSubmit = async (data) => {
    const res = await dispatch(
      updateCompanyProfile({ ...data, idCompany: selectedCompany.idCompany })
    );
    if (res) {
      dispatch(getCompanyProfile({idCompany: selectedCompany.idCompany}));
      onClose();
    }
  };

  return (
    <DrawerCustom
      title={`${t("EditDataAddress")}`}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(onHandleSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledContentLabel>{`${t("Address")}`}</StyledContentLabel>
              <Controller
                name="address"
                control={control}
                render={({ field }) => <TextFieldTheme {...field} fullWidth />}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledContentLabel>{`${t("District")}`}</StyledContentLabel>
              <Controller
                name="district"
                control={control}
                render={({ field }) => <TextFieldTheme {...field} fullWidth />}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledContentLabel>{`${t("Province")}`}</StyledContentLabel>
              <Controller
                name="province"
                control={control}
                render={({ field }) => <TextFieldTheme {...field} fullWidth />}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledContentLabel>{`${t("Address")} (${t("English")})`}</StyledContentLabel>
              <Controller
                name="address_EN"
                control={control}
                render={({ field }) => <TextFieldTheme {...field} fullWidth />}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledContentLabel>{`${t("District")} (${t("English")})`}</StyledContentLabel>
              <Controller
                name="district_EN"
                control={control}
                render={({ field }) => <TextFieldTheme {...field} fullWidth />}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledContentLabel>{`${t("Province")} (${t("English")})`}</StyledContentLabel>
              <Controller
                name="province_EN"
                control={control}
                render={({ field }) => <TextFieldTheme {...field} fullWidth />}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledContentLabel>{`${t("AreaCode")}`}</StyledContentLabel>
              <Controller
                name="areaCode"
                control={control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    fullWidth
                    inputProps={{
                      allowNegative: false,
                    }}
                    InputProps={{
                      inputComponent: NumberFormatComponent,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <StyledFooter>
            <ButtonBlue variant="text" className="cancel" onClick={onClose}>
              {`${t("Cancel")}`}
            </ButtonBlue>
            <ButtonBlue variant="contained" type="submit">
              {`${t("SaveData")}`}
            </ButtonBlue>
          </StyledFooter>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DrawerAddress;
