import * as React from 'react';
import { fontWeight, height, styled, width } from '@mui/system';
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';
import { Typography } from "@mui/material";

import { useTranslation } from 'react-i18next';

import Customer from "../landing/image/MainManuTab/CustomerTab.png";
import LeaveWork from "../landing/image/MainManuTab/LeaveWorkTab.png";
import Salary from "../landing/image/MainManuTab/SalaryTab.png";
import WorkingHouse from "../landing/image/MainManuTab/WorkingHouseTab.png";

export default function AddManuTap() {
  const { t, i18n } = useTranslation();
  return (
    <Tabs defaultValue={0}>
      <TabsList>
        <Tab value={0}>{t("employees")}</Tab>
        <Tab value={1}>{t("workingHours")}</Tab>
        <Tab value={2}>{t("leave")}</Tab>
        <Tab value={3}>{t("payroll")}</Tab>
      </TabsList>
      <TabPanel value={0}>
        <ContentSection
          title={t("EmployeeTitle")}
          subtitle={t("EmployeeSubtitle")}
          description={t("EmployeeDescription")}
          image={Customer}
        />
      </TabPanel>
      <TabPanel value={1}>
        <ContentSection
            title={t("WorkingTimeTitle")}
            subtitle={t("WorkingTimeSubtitle")}
            description={t("WorkingTimeDescription")}
            image={WorkingHouse}
        />
      </TabPanel>
      <TabPanel value={2}>
      <ContentSection
            title={t("LeaveTitle")}
            subtitle={t("LeaveSubtitle")}
            description={t("LeaveDescription")}
            image={LeaveWork}
            />
      </TabPanel>
      <TabPanel value={3}>
      <ContentSection
        title={t("PayrollTitle")}
        subtitle={t("PayrollSubtitle")}
        description={t("PayrollDescription")}
        image={Salary}
        />
      </TabPanel>
    </Tabs>
  );
}

function ContentSection({ title, subtitle, description, image }) {
  return (
    <ContentSectionWrapper>
      <div className="text-content">
        <Typography variant="h3" align='left' gutterBottom sx={{ fontSize: { xs: 25, sm: 40, md: 45 }, marginRight: 5 }}>
          {title}
          <br />
          <span style={{ color: "rgba(83, 24, 129, 0.7)" }}>{subtitle}</span>
        </Typography>
        <Typography variant="subtitle1" align='left' sx={{ fontWeight: 200, marginRight: 5, fontSize: { xs: '0.875rem', sm: '1rem' } }}>
          {description}
        </Typography>
      </div>
      <img
        src={image}
        alt={title}
        className="image-content"
      />
    </ContentSectionWrapper>
  );
}

const ContentSectionWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 85px;

  @media (max-width: 800px) {
    flex-direction: column-reverse; 
    text-align: center;
    margin-bottom: 50px !important; 
  }

  .text-content {
    display: flex;
    flex-direction: column;
    max-width: 60%;

    @media (max-width: 800px) {
      max-width: 100%;
      margin-top: 20px;
      margin-bottom: 50px;
    }
  }

  .image-content {
    max-width: 450px;
    height: auto;
    border-radius: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    @media (max-width: 800px) {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }
`;


const Tab = styled(BaseTab)`
  font-family: 'Kanit';
  color: #000000;
  cursor: pointer;
  font-size: 24px;
  font-weight: 400;
  background-color: #ffffff;
  width: 100%;
  padding: 12px 5px;
  margin: 10px 60px;
  border: none;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  white-space: nowrap;

  &:hover {
    color: rgba(83, 24, 129, 0.7);
    background-color: rgba(194, 176, 229, 0.3);
  }

  &:focus {
    color: rgba(83, 24, 129, 0.7);
    background-color: rgba(194, 176, 229, 0.3);
  }

  &.${tabClasses.selected} {
    color: rgba(83, 24, 129, 0.7);
    background-color: rgba(194, 176, 229, 0.3);
  }

  &.${buttonClasses.disabled} {
    opacity: 0.2;
    cursor: not-allowed;
  }

    @media (max-width: 800px) {
    width: auto; 
    padding: 10px 20px;
    font-size: 20px; 
    margin: 10px; 
  }
`;

const TabPanel = styled(BaseTabPanel)`
  width: 100%;
  height: 300px;
  align-items: center;
  font-family: 'Kanit';
  font-size: 0.875rem;
  padding: 20px 12px;
  background: #ffffff;
  border-radius: 12px;
  opacity: 1;
`;

const TabsList = styled(BaseTabsList)`
  min-width: 400px;
  background-color: #ffffff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  place-content: space-between center;

  @media (max-width: 800px) {
    flex-wrap: wrap; 
    justify-content: center; 
    min-width: 100%; 
  }

`;

