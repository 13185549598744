import React, { useState, useEffect } from "react";
import {
  Grid,
  Avatar,
  Typography,
  Container,
  IconButton,
  Chip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import VerifiedIcon from "@mui/icons-material/Verified";
import StarIcon from "@mui/icons-material/Star";
import TodayIcon from "@mui/icons-material/Today";
import { getEmployeeReward } from "../../../../actions/project";
import CardStyle from "../../shared/general/Card";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../utils/userData";
import { Redirect, useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "20px",
    minHeight: "calc(100vh - 20px)",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  nameText: {
    fontSize: 29,
    fontWeight: "bold",
    marginBottom: 20,
  },
  colorMD: {
    borderTop: "5px solid green",
  },
  colorMG: {
    borderTop: "5px solid red",
  },
  colorSM: {
    borderTop: "5px solid blue",
  },
  bigAvatar: {
    width: 150,
    height: 150,
    margin: 20,
  },
  icon: {
    fontSize: 30,
  },
  borderIcon: {
    height: 50,
    padding: 10,
    textAlign: "center",
    borderRadius: "10%",
  },
  topic: {
    marginBottom: 8,
    fontSize: 20,
    fontWeight: 500,
  },
  dataInTopic: {
    fontSize: 16,
    padding: 8,
    fontWeight: 500,
  },
}));

const PaperSowData = ({ name, value, icon }) => {
  return (
    <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
      <div style={{ marginLeft: 30, marginRight: 30 }}>
        <IconButton disabled size="large" style={{ color: "#DB4178" }}>
          {icon}
        </IconButton>
        <Typography style={{ fontSize: 23, fontWeight: "bold", marginTop: 10 }}>
          {value}
        </Typography>
        <Typography style={{ fontSize: 15 }}>{name}</Typography>
      </div>
    </Grid>
  );
};

const ShowRewardDetail = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [projectData, setProjectData] = useState(null);
  const { state } = useLocation();

  if (!(state && state.idEmployeeReward)) {
    return <Redirect to="/project" />;
  }

  useEffect(() => {
    const fetchedData = async (idEmployeeReward) => {
      try {
        const response = await getEmployeeReward(idEmployeeReward);
        if (response && response.data) {
          setProjectData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchedData(state.idEmployeeReward);
  }, [state.idEmployeeReward]);

  return (
    <div className={`page ${classes.root}`}>
      <Container>
        <Typography variant="h5" fontWeight={500} marginBottom={4}>
          {t("Details")}
        </Typography>
        {projectData && (
          <CardStyle
            className={[
              `${projectData.size == "big" && classes.colorMG}`,
              `${projectData.size == "medium" && classes.colorMD}`,
              `${projectData.size == "small" && classes.colorSM}`,
            ].join(" ")}
          >
            <div style={{ padding: 20 }}>
              <Typography
                className={classes.nameText}
                style={{ textAlign: "center" }}
              >
                {projectData.rewardName}
              </Typography>
              <CardStyle
                style={{
                  backgroundColor: "rgba(244, 245, 245, 1)",
                  padding: 20,
                  marginTop: "10px",
                  marginBottom: "16px",
                }}
              >
                <Grid container spacing={2.5}>
                  <PaperSowData
                    name={t("Type")}
                    value={projectData.rewardType}
                    icon={
                      <DynamicFeedIcon
                        className={classes.icon}
                        sx={{ color: "primary.darker" }}
                      />
                    }
                  />
                  <PaperSowData
                    name={t("Institution")}
                    value={projectData.institution}
                    icon={
                      <StarIcon
                        className={classes.icon}
                        sx={{ color: "primary.darker" }}
                      />
                    }
                  />
                  <PaperSowData
                    name={t("ReceivedDate")}
                    value={dayjs(projectData.receivedDate).format(
                      i18n.resolvedLanguage === "th" ? "BBBB-MM-DD" : "YYYY-MM-DD"
                    )}
                    icon={
                      <TodayIcon
                        className={classes.icon}
                        sx={{ color: "warning.darker" }}
                      />
                    }
                  />
                </Grid>
              </CardStyle>
              <div style={{ marginBottom: 32 }}>
                <Typography className={classes.topic}>
                  {t("Status")} :
                </Typography>
                <div style={{ padding: "8px" }}>
                  <Chip
                    color={
                      projectData.isApproved === 1
                        ? "success"
                        : projectData.isApproved === 0
                        ? "error"
                        : "warning"
                    }
                    label={
                      projectData.isApproved === 1
                        ? t("Approved")
                        : projectData.isApproved === 0
                        ? t("NotApproved")
                        : t("WaitingForApproval")
                    }
                  />
                </div>
                <Typography className={classes.topic}>
                  {t("Detail")} :
                </Typography>
                <Typography className={classes.dataInTopic}>
                  {projectData.detail}
                </Typography>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <VerifiedIcon sx={{ color: "#210c96" }} />
                    <Typography
                      fontSize={22}
                      fontWeight={500}
                      color={"#210c96"}
                    >
                      {t("Approver")}
                    </Typography>
                  </div>
                  <Avatar
                    src={projectData.imageURL}
                    className={classes.bigAvatar}
                  />
                  <Typography
                    style={{
                      fontSize: 20,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {getUserFullName({
                      firstname_TH: projectData.appoveBy_firstname_TH,
                      lastname_TH: projectData.appoveBy_lastname_TH,
                      firstname_EN: projectData.appoveBy_firstname_EN,
                      lastname_EN: projectData.appoveBy_lastname_EN,
                    })}
                  </Typography>
                  <Typography fontWeight={500}>
                    {projectData.verifyDate
                      ? dayjs(projectData.verifyDate).format(
                          i18n.resolvedLanguage === "th"
                            ? "BBBB-MM-DD"
                            : "YYYY-MM-DD"
                        )
                      : t("PendingApproval")}
                  </Typography>
                </div>
              </div>
            </div>
          </CardStyle>
        )}
      </Container>
    </div>
  );
};

export default ShowRewardDetail;
