import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Container,
  Breadcrumbs,
  Grid,
  Divider,
  Avatar,
  CardActionArea,
  DialogContent,
  MenuItem,
  InputLabel,
  FormControl,
  DialogActions,
  Select,
  Dialog,
  Autocomplete,
  TextField,
  Card,
  Stack,
  useMediaQuery,
  Button,
  IconButton,
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CardStyle from "../../shared/general/Card";
import RadialChartInfo from "../compared/chart/radialChartInfo";
import { getAllEmployeeTalentByCompanyAndTalent, updateEmployeeTalent } from "../../../../actions/employee";
import ButtonBlue from "../../shared/general/ButtonBlue";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import utils from "../../../../utils";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { getUserDepartment, getUserFullName, getUserPosition, getUserSection } from "../../../../utils/userData";
import { useLocation } from "react-router-dom";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 50,
  "& .search-filter-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .filter-wrap": {},
    "& .action-container": {},
  },
});

const StyledDetailCard = styled("div")({
  paddingTop: 16,
  "& .part-header-detail": {
    paddingBottom: 24,
  },
});

const StyledHeader = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  "& .level-text": {
    alignText: "center",
    color: "#46cbe2",
    fontSize: "2rem",
    fontWeight: 600,
    marginLeft: "40px",
  },
  "& .person-text": {
    alignText: "center",
    color: "#46cbe2",
    fontSize: "2rem",
    fontWeight: 600,
    marginRight: "40px",
  },
});

const StyledRight = styled("div")({
  padding: "20px 20px 20px calc(20px + 0.75em)",
  "& .box-header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "80px",
    backgroundColor: "#46cbe2",
    borderRadius: "20px",

    "& .empHeadingText": {
      color: "white",
      fontSize: "20px",
      fontWeight: 500,
      alignSelf: "center",
    },
    "& .empPositionText": {
      color: "white ",
      fontSize: "1rem",
      fontWeight: 400,
      textAlign: "left",
    },
    "& .empImage": {
      minHeight: "80px",
      minWidth: "80px",
      alignSelf: "right",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      outline: "3px solid white",
      marginBottom: "11px",
      marginLeft: "40px",
    },
  },
  "& .content-profile": {
    direction: "column",
    alignItems: "flex-start",
    padding: "18px 18px 18px calc(18px + 0.75em)",

    "& .textTopic": {
      color: "gray",
      fontSize: "1rem",
      fontWeight: 300,
      textAlign: "left",
    },
    "& .textContent": {
      color: "black",
      fontSize: "1rem",
      fontWeight: 600,
      textAlign: "left",
    },
  },
});

const StyledFooter = styled("div")({
  "& .footer-profile": {
    display: "flex",
    direction: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px 15px 15px 15px",

    "& .footer-text-topic": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "gray ",
      fontSize: "1rem",
      fontWeight: 300,
      alignSelf: "center",
    },
    "& .styleBox": {
      width: "70px",
      border: "1.5px solid #46cbe2",
      borderRadius: 5,
      marginTop: "25px",
      "& .text-box": {
        textAlign: "center",
        fontSize: "1.2rem",
        fontWeight: 600,
      },
    },
  },
});

const StyledContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  "& .emp-avatar": {
    alignItems: "center",
    minHeight: "100px",
    minWidth: "100px",
    margin: "auto",
  },
  "& .emp-name": {
    textAlign: "center",
    fontSize: "0.8rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "white",
    marginLeft: "9px",
    marginRight: "3px",
  },
});

const StyledAutoComplete = styled(Autocomplete)({
  "& .MuiFilledInput-root": {
    padding: "13.5px 14px",
    paddingRight: "32px",
    "& input": {
      padding: 0
    }
  }
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&:after": {
      border: "none",
    },
    "&:before": {
      border: "none",
      borderBottomStyle: "none !important",
    }
  },
});

const colorMapping = {
  "A3": "#C3F6E7",
  "A2": "#A1F3DB",
  "A1": "#7AF3D0",
  "B3": "#D2F7FF",
  "B2": "#B1EEFC",
  "B1": "#84E9FF",
  "C3": "#FBEEDB",
  "C2": "#FFE0B2",
  "C1": "#FFD08C",
};


const ListInfoPage = ({ item }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { name, idTalent, year, idCompany } = location.state.empData;
  const [empTalent, setEmpTalent] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [selectedTalent, setSelectedTalent] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showEmployeeData, setShowEmployeeData] = useState(false);

  const presentDay = new Date();

  // const CURRENT_YEAR_DISPLAY =
  //   dayjs().get("month") >= 11
  //     ? dayjs().get("year")
  //     : dayjs().subtract(1, "year").get("year");

  const talentOptions = [
    { value: 1, label: "A1" },
    { value: 2, label: "A2" },
    { value: 3, label: "A3" },
    { value: 4, label: "B1" },
    { value: 5, label: "B2" },
    { value: 6, label: "B3" },
    { value: 7, label: "C1" },
    { value: 8, label: "C2" },
    { value: 9, label: "C3" }
  ];

  useEffect(() => {
    const fetchedData = async (idTalent, idCompany, year) => {
      try {
        const response = await getAllEmployeeTalentByCompanyAndTalent({ idTalent: idTalent, idCompany: idCompany, year: year });
        if (response && response.data && response.data.length > 0) {
          const result = response.data;
          const filteredResult = result.filter(item => item.idTalent === idTalent && item.year === parseInt(year));
          setEmpTalent(filteredResult);
        };
      } catch (error) {
        console.error(error);
      };
    };

    if (idTalent && idCompany && year) {
      fetchedData(idTalent, idCompany, year);
    };
  }, [idTalent, idCompany, year]);

  const handleShowEmployeeData = (data) => {
    setShowEmployeeData(true);
    setSelectedEmployee(data);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedTalent(null);
    setSelectedEmployee(null);
    setShowEmployeeData(false);
  };

  const handleTalentChange = (event) => {
    setSelectedTalent(event.target.value);
  };

  const handleUpdateTalent = async (data, newTalentId) => {
    try {
      const formData = data;
      delete formData.idTalent;
      formData.idTalent = newTalentId;

      const response = await updateEmployeeTalent(formData);
      if (response && response.status === 200) {
        handleCloseDialog();
        const removeEmp = empTalent && empTalent.filter((i) => i.idEmployees !== data.idEmployees);
        setEmpTalent(removeEmp);
      };
    } catch (error) {
      console.error(error);
    };
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const seeProfile = (idCompany, idEmployees) => {
    history.push(`/information/${idCompany}/${idEmployees}`)
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to={"/admin/talent"}
          >
            {t("All")}
          </Link>
          <Typography color="text.primary">
            {t("Details")} {t("TalentManagement")}
          </Typography>
        </Breadcrumbs>

        <div style={{ marginBottom: 5, marginTop: 10 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            {t("Details")} {t("TalentManagement")}
          </Typography>
        </div>

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', flexDirection: "column" }}>
          <Box>
            <Typography fontSize={18} sx={{ p: 1 }}>
              {t("SearchEmp")}
            </Typography>
            <StyledAutoComplete
              options={empTalent ? empTalent : []}
              getOptionLabel={(option) =>
                getUserFullName(option)
              }
              isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  InputProps={{ ...params.InputProps, endAdornment: null }}
                  variant="filled"
                  placeholder={t("SearchEmp")}
                />
              )}
              value={searchValue}
              onChange={(event, newValue) => {
                setSearchValue(newValue);
                setSelectedEmployee(null);
                setShowEmployeeData(false);
              }}
              onInputChange={(event, newInputValue) => {
                setSelectedEmployee(null);
                setShowEmployeeData(false);
                setInputValue(newInputValue);
              }}
              noOptionsText={t("NoData")}
              disableClearable
            />
          </Box>
        </Box>

        <Grid
          container
          spacing={2}
          justifyContent={selectedEmployee ? "flex-start" : "center"}
        >
          <Grid item xs={12} md={7}>
            <Card sx={{ mt: 4 }}>
              <StyledDetailCard>
                <StyledHeader>
                  <Typography className="level-text">{name}</Typography>
                  <Typography className="person-text">{empTalent && empTalent.length > 0 ? empTalent.length : 0} {t("Person")}</Typography>
                </StyledHeader>
                <StyledContent>
                  <Grid container spacing={2} justifyContent={"space-evenly"}>
                    {empTalent && empTalent.filter((i) =>
                      inputValue
                        ? ((i.firstname_TH + " " + i.lastname_TH) === inputValue ||
                          (i.firstname_EN + " " + i.lastname_EN) === inputValue)
                        : true
                    ).map((emp, index) => (
                      <Grid item key={index} xs={4}>
                        <CardActionArea onClick={() => handleShowEmployeeData(emp)}>
                          <Stack
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <Avatar className="emp-avatar" src={emp.imageProfile} />
                            <Box
                              style={{
                                marginTop: "10px",
                                backgroundColor: "#46cbe2",
                                borderRadius: "20px",
                                padding: 3,
                                width: isSmallScreen ? 110 : 150
                              }}
                            >
                              <Typography textAlign={"center"} color={"#fff"} fontSize={isSmallScreen ? 12 : 14}>
                                {getUserFullName(emp)}
                              </Typography>
                            </Box>
                          </Stack>
                        </CardActionArea>
                      </Grid>
                    ))}
                  </Grid>
                </StyledContent>
              </StyledDetailCard>
            </Card>
          </Grid>

          {selectedEmployee && showEmployeeData ? (
            <Grid item xs={12} md={5}>
              <Card sx={{ mt: 4 }}>
                <StyledDetailCard>
                  <StyledRight>
                    <Grid container spacing={2} className="box-header">
                      <Grid item xs={isSmallScreen ? 5 : 4}>
                        <Avatar variant="circle" className={"empImage"} src={selectedEmployee.imageProfile} />
                      </Grid>

                      <Grid item xs={isSmallScreen ? 7 : 8}>
                        <Typography className="empHeadingText">
                          {getUserFullName(selectedEmployee)}
                        </Typography>
                        <Typography className="empPositionText">
                          {selectedEmployee.position.length > 0 ? selectedEmployee.position
                            .filter(date =>
                              dayjs(date.start).isSameOrBefore(presentDay, 'day') &&
                              (dayjs(date.end).isSameOrAfter(presentDay, 'day') || !date.end)
                            )
                            .map(i => getUserPosition(i)) : "-"}
                        </Typography>
                      </Grid>

                      <Box sx={{ position: "relative" }}>
                        <Box sx={{ position: "absolute", top: -50, right: 3 }}>
                          <IconButton onClick={() => seeProfile(selectedEmployee.idCompany, selectedEmployee.idEmployees)}>
                            <PersonIcon style={{ color: "#fff" }} />
                          </IconButton>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid container spacing={1} className="content-profile">
                      {/* First Row */}
                      <Grid item>
                        <Typography variant="body1">
                          <span className="textTopic">{t("Company")} : </span>
                          <span className="textContent">{selectedEmployee.companyName || "-"}</span>
                        </Typography>

                        <Typography variant="body1" sx={{ mt: 1 }}>
                          <span className="textTopic">{t("Division")} : </span>
                          <span className="textContent">{selectedEmployee.position.length > 0 ? selectedEmployee.position
                            .filter(date =>
                              dayjs(date.start).isSameOrBefore(presentDay, 'day') &&
                              (dayjs(date.end).isSameOrAfter(presentDay, 'day') || !date.end)
                            )
                            .map(i => i.divisionName || "-") : "-"}</span>
                        </Typography>

                        <Typography variant="body1" sx={{ mt: 1 }}>
                          <span className="textTopic">{t("Department")} : </span>
                          <span className="textContent">{selectedEmployee.position.length > 0 ? selectedEmployee.position
                            .filter(date =>
                              dayjs(date.start).isSameOrBefore(presentDay, 'day') &&
                              (dayjs(date.end).isSameOrAfter(presentDay, 'day') || !date.end)
                            )
                            .map(i => getUserDepartment(i)) : "-"}</span>
                        </Typography>

                        <Typography variant="body1" sx={{ mt: 1 }}>
                          <span className="textTopic">{t("Section")} : </span>
                          <span className="textContent">{selectedEmployee.position.length > 0 ? selectedEmployee.position
                            .filter(date =>
                              dayjs(date.start).isSameOrBefore(presentDay, 'day') &&
                              (dayjs(date.end).isSameOrAfter(presentDay, 'day') || !date.end)
                            )
                            .map(i => getUserSection(i)) : "-"}</span>
                        </Typography>

                        <Typography variant="body1" sx={{ mt: 1 }}>
                          <span className="textTopic">{t("personalLevel")} : </span>
                          <span className="textContent">
                            {selectedEmployee.position
                              .filter(date =>
                                dayjs(date.start).isSameOrBefore(presentDay, 'day') &&
                                (dayjs(date.end).isSameOrAfter(presentDay, 'day') || !date.end)
                              )
                              .map(item => item.levelName ? (item.levelName + " ") : "-")}
                          </span>
                        </Typography>

                        <Typography variant="body1" sx={{ mt: 1 }}>
                          <span className="textTopic">{t("ESY")} : </span>
                          <span className="textContent">
                            {selectedEmployee.hiringDate
                              ? `${utils.getAgeMonthYear(t,
                                dayjs(selectedEmployee.hiringDate).format("DD/MM/YYYY"),
                                i18n.resolvedLanguage
                              )}`
                              : "-"}
                          </span>
                        </Typography>

                        <Typography variant="body1" sx={{ mt: 1 }}>
                          <span className="textTopic">{t("TIP")} : </span>
                          <span className="textContent">
                            {selectedEmployee.position
                              .filter(date =>
                                dayjs(date.start).isSameOrBefore(presentDay, 'day') &&
                                (dayjs(date.end).isSameOrAfter(presentDay, 'day') || !date.end)
                              )
                              .map(item =>
                                item.start
                                  ? `${utils.getAgeMonthYear(t,
                                    dayjs(item.start).format("DD/MM/YYYY"),
                                    i18n.resolvedLanguage
                                  )}`
                                  : "-"
                              )}
                          </span>
                        </Typography>

                        <Typography variant="body1" sx={{ mt: 1 }}>
                          <span className="textTopic">{t("TIG")} : </span>
                          <span className="textContent">
                            {selectedEmployee.employeePersonnelLevelStart
                              ? `${utils.getAgeMonthYear(t,
                                dayjs(selectedEmployee.employeePersonnelLevelStart).format("DD/MM/YYYY"),
                                i18n.resolvedLanguage
                              )
                              } `
                              : "-"}
                          </span>
                        </Typography>
                      </Grid>

                      {/* Third Row */}
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <span className="textTopic">{t("Performance")} : </span>
                          <span className="textContent">
                            {Array.from({ length: 3 }, (_, index) => {
                              // const year = CURRENT_YEAR_DISPLAY - index;
                              const performanceForYear = selectedEmployee.performance.find(
                                (performance) => performance.yearPerformance === year
                              );
                              return (
                                <>
                                  {performanceForYear ? (
                                    <>
                                      {performanceForYear.name + " "}
                                    </>
                                  ) : null}
                                </>
                              );
                            })}
                          </span>
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <span className="textTopic">{t("Potential")} : </span>
                          <span className="textContent">
                            {Array.from({ length: 3 }, (_, index) => {
                              // const year = CURRENT_YEAR_DISPLAY - index;
                              const potentialYear = selectedEmployee.potential.find(
                                (potential) => potential.year === year
                              );
                              return (
                                <>
                                  {potentialYear ? (
                                    <>
                                      {(
                                        potentialYear.Level === "H" ? "H" :
                                          potentialYear.Level === "Normal" ? "N" :
                                            potentialYear.Level === "Normal-Low" ? "NL" : "L") + " "}
                                    </>
                                  ) : null}
                                </>
                              );
                            })}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />

                    <StyledFooter onClick={() => handleOpenDialog()}>
                      <Grid container spacing={3} className="footer-profile">
                        {/* First Row */}
                        <Grid item container spacing={1} justifyContent={"center"} alignItems={"center"}>
                          {/* <Grid item xs={6}>
                            <Typography className="footer-text-topic">
                              Talent Score
                            </Typography>
                            <RadialChartInfo />
                          </Grid> */}
                          <Grid item xs={4}>
                            <Typography className="footer-text-topic">
                              {t("Performance")}
                            </Typography>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                className="styleBox"
                                sx={{
                                  p: 2,
                                }}
                              >
                                <Typography className="text-box">
                                  {
                                    (selectedEmployee &&
                                      selectedEmployee.performance &&
                                      selectedEmployee.performance.length > 0 &&
                                      selectedEmployee.performance.find(e => e.yearPerformance === parseInt(year)) &&
                                      selectedEmployee.performance.find(e => e.yearPerformance === parseInt(year)).score
                                    ) || "-"
                                  }
                                </Typography>
                              </Box>
                            </div>
                          </Grid>

                          <Grid item xs={4}>
                            <Typography className="footer-text-topic">
                              {t("potential")}
                            </Typography>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                className="styleBox"
                                sx={{
                                  p: 2,
                                }}
                              >
                                <Typography className="text-box">
                                  {
                                    (selectedEmployee &&
                                      selectedEmployee.potential &&
                                      selectedEmployee.potential.length > 0 &&
                                      selectedEmployee.potential.find(e => e.year === parseInt(year)) &&
                                      selectedEmployee.potential.find(e => e.year === parseInt(year)).score
                                    ) || "-"
                                  }
                                </Typography>
                              </Box>
                            </div>
                          </Grid>

                          <Grid item xs={4}>
                            <Typography className="footer-text-topic">
                              {t("talentResult")}
                            </Typography>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                className="styleBox"
                                sx={{
                                  p: 2,
                                  bgcolor: colorMapping[name]
                                }}
                              >
                                <Typography className="text-box">{name}</Typography>
                              </Box>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </StyledFooter>
                  </StyledRight>
                </StyledDetailCard>
              </Card>
            </Grid>
          ) : null}
        </Grid>
      </Container>

      <Dialog open={openDialog} onClose={() => handleCloseDialog()}>
        <Box sx={{ p: 2 }}>
          <DialogContent style={{ width: '300px', height: '150px' }}>
            <Typography variant="h6">{t("Move")} {t("Talent")}</Typography>
            <Grid>
              <Grid item xl={10} lg={5} md={10} sm={12} xs={12} sx={{ marginTop: 2 }}>
                <FormControl fullWidth>
                  <InputLabel>{t("Select")}</InputLabel>
                  <Select
                    value={selectedTalent}
                    name="talent"
                    label="talent"
                    sx={{ width: '100%' }}
                    onChange={(e) => handleTalentChange(e)}
                  >
                    {talentOptions.filter(i => i.label !== name).map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <ButtonBlue
              variant="text"
              onClick={() => handleCloseDialog()}
            >
              {t("Close")}
            </ButtonBlue>
            <ButtonBlue
              variant="contained"
              onClick={() => handleUpdateTalent(selectedEmployee, selectedTalent)}
              disabled={selectedTalent === null}
            >
              {t("Save")}
            </ButtonBlue>
          </DialogActions>
        </Box>
      </Dialog>
    </StyledRoot >
  );
};

export default ListInfoPage;


// {index % 4 === 3 && index !== 15 && (
//   <Grid item xs={12}>
//     <Divider />
//   </Grid>
// )}