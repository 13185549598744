import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Container,
  SwipeableDrawer,
  Typography,
  IconButton,
  Divider,
  Drawer,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import GalleryIcon from "../../../assets/gallery.png";
import { styled } from "@mui/styles";
import DatePickerCustom from "../../../shared/date/datePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import AlertResponse from "../../../shared/general/AlertResponse";
import { useDispatch } from "react-redux";
import {
  addExpensePaymentDetail,
  getHistoryExpenseByIdCompany,
} from "../../../../../actions/expense";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 16,
    marginButton: 4,
  },
  "&.MuiTypography-h5": {
    fontSize: 18,
    paddingLeft: "6px",
    borderLeft: "4px solid #46cbe2",
    marginBottom: "12px",
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 24,
  backgroundColor: "#f9f9fb",
  "& .dropzone-leave": {
    // height: 98,
    borderRadius: 8,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    // height: 90,
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#efefef",
    flexDirection: "column",
    padding: "40px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const StyledBox = styled(Box)({
  padding: "24px",
});

const StyledDivider = styled(Divider)({
  marginTop: 24,
  marginBottom: 20,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const PaymentDrawer = (props) => {
  const {
    selectedCompany,
    idExpense,
    anchor,
    open,
    onClose,
    onOpen,
    paymentDate,
  } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [date, setDate] = useState(dayjs());
  const SentFormDialogTitle = t("AccountantSentPaymentDialogTitle");
  const SentFormDialogDes = t("AccountantSentPaymentDialogDes");
  const [openSentDialog, setOpenSentDialog] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const handleClickSentDialog = () => {
    setOpenSentDialog(true);
  };
  const handleCloseSentDialog = () => {
    setOpenSentDialog(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  useEffect(() => {
    setDate(paymentDate);
    if (!open) {
      setDate(paymentDate);
      setFile(null);
    }
  }, [open]);

  const handleOnClickSubmit = async () => {
    setIsSending(true);
    let formData = new FormData();
    formData.append("idExpense", idExpense);
    formData.append("paymentDate", dayjs(date).format("YYYY/MM/DD"));
    if (file) {
      formData.append("file", file);
    }
    handleCloseSentDialog();
    let result = null;
    result = await dispatch(addExpensePaymentDetail(formData));

    if (result) {
      setIsSending(false);
      if (result.status === 200) {
        handleChangeAlertType("success");
        handleOpenAlert();
        onClose();
        dispatch(getHistoryExpenseByIdCompany(selectedCompany.idCompany));
      } else {
        handleChangeAlertType("error");
        handleOpenAlert();
      }
    }
  };

  return (
    <Drawer anchor={anchor} open={open} onClose={onClose} onOpen={onOpen}>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      <StyledBox role="presentation">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="wrap-header">
              <StyledHeadLabel variant="h5">{t("payment")}</StyledHeadLabel>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div>
              <StyledHeadLabel variant="h6">{t("PaymentDate")}</StyledHeadLabel>
            </div>
            <DatePickerCustom
              inputFormat="DD/MM/YYYY"
              value={date}
              name="date"
              onChange={(newValue) => {
                setDate(newValue);
              }}
              placeHolder={t("PaymentDate")}
              isRequired
              disableClearable
              disableOpenPicker={false}
              renderInput={(params) => (
                <Fragment>
                  <TextFieldTheme
                    style={{ width: "100%" }}
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                  />
                </Fragment>
              )}
            />

            <StyledDivider />
          </Grid>

          <Grid item xs={12}>
            <div>
              <StyledHeadLabel variant="h6">{t("AttachFile")}</StyledHeadLabel>
            </div>
            {!file ? (
              <StyledDropzone>
                <Dropzone
                  accept="image/jpeg, image/png, application/pdf"
                  maxFiles={1}
                  multiple={false}
                  maxSize={3145728}
                  onDrop={(acceptedFiles, rejectedFiles) => {
                    if (acceptedFiles.length > 0) {
                      setFile(acceptedFiles[0]);
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps({ className: "dropzone-upload-file" })}
                    >
                      <div className="inner-dropzone">
                        <input {...getInputProps()} />
                        <div style={{ textAlign: "center" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img alt="add" src={GalleryIcon} />
                          </div>
                          <Typography
                            variant="caption"
                            style={{ lineHeight: 0.5, color: "#999999" }}
                          >
                            {t("imageFileNotMore500KB")}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </StyledDropzone>
            ) : (
              <Box
                style={{
                  position: "relative",
                  textAlign: "center",
                }}
              >
                <IconButton
                  style={{ position: "absolute", margin: 7, right: 0 }}
                  color="error"
                  onClick={() => {
                    setFile(null);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
                <div
                  style={{
                    padding: 15,
                    backgroundColor: "#f9f9fb",
                    display: "flex",
                    borderRadius: 15,
                  }}
                >
                  <FilePresentIcon
                    height="24"
                    style={{
                      marginRight: 10,
                    }}
                  ></FilePresentIcon>
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100%",
                    }}
                  >
                    {file.path}
                  </Typography>
                </div>
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <ButtonBlue
                variant="contained"
                startIcon={<SendRoundedIcon />}
                onClick={() => {
                  handleClickSentDialog();
                }}
                disabled={isSending}
              >
                {t("Submit")}
              </ButtonBlue>
              <Dialog
                open={openSentDialog}
                onClose={handleCloseSentDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {SentFormDialogTitle}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {SentFormDialogDes}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseSentDialog} color="error">
                    {t("Cancel")}
                  </Button>
                  <ButtonBlue
                    onClick={() => {
                      handleOnClickSubmit();
                    }}
                  >
                    {t("Confirm")}
                  </ButtonBlue>
                </DialogActions>
              </Dialog>
            </div>
          </Grid>
        </Grid>
      </StyledBox>
    </Drawer>
  );
};

export default PaymentDrawer;
