import React, { useState, useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import "dayjs/locale/th";

import {
  Typography,
  TextField,
  Grid,
  Popper,
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Menu,
  MenuItem,
  IconButton,
  createFilterOptions,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import Dropzone, { useDropzone } from "react-dropzone";

import DeleteIcon from "@mui/icons-material/Delete";
import FilePresentIcon from "@mui/icons-material/FilePresent";

import GalleryIcon from "../../../../assets/gallery.png";
import utils from "../../../../../../utils";
import { currency } from "../../../../assets/data/currency";
import Backdrop from "../../../../shared/general/Backdrop";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import AlertResponse from "../../../../shared/general/AlertResponse";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";

import SelectApprover from "../../select-approver";
import ActionButtons from "../action-buttons";
import SelectCC from "../../select-cc";

import {
  getExpenseAdvanceById,
  getExpensePaymentTypes,
  getExpenseReturnAdvanceTypes,
  updateExpenseAdvance,
  updateFileExpense,
} from "../../../../../../actions/expense";
import { getEmployeesRoleAdmin } from "../../../../../../actions/admins";
import { getUserFullName } from "../../../../../../utils/userData";
import DatePickerCustom from "../../../../shared/date/datePicker";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Comments from "../../comments";
import Actions from "../../actions";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 24,
  marginBottom: 20,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 24,
  backgroundColor: "#f9f9fb",

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "40px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const Advance = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { idExpense, typeExpense, typeEdit, createUpdateFileFormData } = props;
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employees } = useSelector((state) => state.employees);
  const { result: admins } = useSelector((state) => state.admins);
  const { result: expensePaymentTypes } = useSelector(
    (state) => state.expensePaymentTypes
  );
  const { result: expenseReturnAdvanceTypes } = useSelector(
    (state) => state.expenseReturnAdvanceTypes
  );
  const { result: expenseDetail } = useSelector((state) => state.expenseDetail);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [fileSelected, setFileSelected] = useState([]);
  const today = dayjs().toDate();
  const [tempDate, setTempDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate())
  );
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  let [paymentTypes, setPaymentTypes] = useState([]);
  let [returnTypes, setReturnTypes] = useState([]);
  const [readOnly, setReadOnly] = useState(false);
  const [modalStatusConfig, setModalStatusConfig] = useState({
    open: false,
    title: "",
    content: "",
    type: "success",
  });
  const [formData, setFormData] = useState({
    nameExpense: "",
    description: "",
    currency: "THB",
    currencyItem: currency()[0],
    paymentType: null,
    returnType: null,
    receiveDate: null,
    amount: 0,
    file: null,
    remark: "",
    typeExpenseName: typeExpense,
    total: 0,
    net: 0,
    approver: "",
    cc_email: [],
    reviewer: null,
  });
  const currencyArray = currency();
  const defaultCurrency = currency()[0];
  const MAX_FILE_SIZE = 512000; //500KB

  const validFileExtensions = { file: ["jpg", "png", "jpeg"] };

  const isValidFileType = (fileName, fileType) => {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  };

  const filterOptions = createFilterOptions({
    stringify: (value) =>
      `${value.firstname_TH} ${value.lastname_TH} ${value.email}`,
  });

  const validationSchema = Yup.object().shape({
    nameExpense: Yup.string().required(t("ThisFieldIsRequired")),
    approver: Yup.string().required(t("ThisFieldIsRequired")),
    currency: Yup.string().required(t("ThisFieldIsRequired")),
    idExpensePaymentType: Yup.string().required(t("ThisFieldIsRequired")),
    idExpenseReturnAdvanceType: Yup.string().required(t("ThisFieldIsRequired")),
    receiveDate: Yup.string().nullable(),
    returnDate: Yup.string().nullable(),
    amount: Yup.string().required(t("ThisFieldIsRequired")),
    remark: Yup.string(),
    net: Yup.string().required(t("ThisFieldIsRequired")).default(0),
    file: Yup.mixed()
      .test("is-valid-type", "Not a valid file type", (value) => {
        if (value) {
          return isValidFileType(value && value.name.toLowerCase(), "file");
        } else {
          return true;
        }
      })
      .test("is-valid-size", "Max allowed size is 500KB", (value) => {
        if (value) {
          return value && value.size <= MAX_FILE_SIZE;
        } else {
          return true;
        }
      }),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    watch,
    reset,
    register,
  } = useForm({
    defaultValues: { ...formData },
    resolver: yupResolver(validationSchema),
  });

  const checkValidation = (error) => {
    console.log("error : ", error);
    setModalStatusConfig({
      open: true,
      title: "",
      content: t("ThisFieldIsRequired"),
      type: "error",
    });
  };

  useEffect(() => {
    if (!admins) {
      dispatch(getEmployeesRoleAdmin());
    }
    if (userProfile && userProfile.idManagerLV1 && employees) {
      setFormData({
        ...formData,
        approver: userProfile.idManagerLV1,
      });
      setValue("approver", userProfile.idManagerLV1);
    }
  }, [employees, userProfile]);

  useEffect(() => {
    if (userProfile) {
      dispatch(getExpensePaymentTypes());
      dispatch(getExpenseReturnAdvanceTypes());
      dispatch(getExpenseAdvanceById(idExpense));
    }
  }, [userProfile]);

  useEffect(() => {
    if (expensePaymentTypes) {
      setPaymentTypes(expensePaymentTypes);
    }
  }, [expensePaymentTypes]);

  useEffect(() => {
    if (expenseReturnAdvanceTypes) {
      setReturnTypes(expenseReturnAdvanceTypes);
    }
  }, [expenseReturnAdvanceTypes]);

  useEffect(() => {
    if (
      expenseDetail &&
      expenseDetail.idExpense == idExpense &&
      expensePaymentTypes &&
      expenseReturnAdvanceTypes &&
      returnTypes &&
      paymentTypes
    ) {
      reset(expenseDetail);
      setValue("currencyUnit", expenseDetail.currency);
      setValue(
        "currencyItem",
        currencyArray[
          currencyArray.findIndex(
            (element) => element.unit === expenseDetail.currency
          )
        ]
      );
      setValue("approver", expenseDetail.idEmpApprover);
      setValue(
        "paymentType",
        paymentTypes[
          paymentTypes.findIndex(
            (element) =>
              element.idExpensePaymentType ===
              expenseDetail.idExpensePaymentType
          )
        ]
      );
      // setValue("receiveDate", dayjs(expenseDetail.receiveDate, "YYYY-MM-DD").format("YYYY-MM-DD"));
      setValue(
        "returnType",
        returnTypes[
          returnTypes.findIndex(
            (element) =>
              element.idExpenseReturnAdvanceType ===
              expenseDetail.idExpenseReturnAdvanceType
          )
        ]
      );
      // setValue("returnDate", dayjs(expenseDetail.returnDate, "YYYY-MM-DD").format("YYYY-MM-DD"));
      if (expenseDetail.status == 10) {
        setReadOnly(true);
      }
    }
  }, [
    expenseDetail,
    expensePaymentTypes,
    expenseReturnAdvanceTypes,
    paymentTypes,
    returnTypes,
  ]);

  const isValidDate = (value) => {
    const date = new Date(value);
    return !isNaN(date.getTime());
  };

  const createFormData = (statusId) => {
    console.log(isValidDate(getValues("receiveDate")));
    console.log(isValidDate(getValues("returnDate")));
    let data = new FormData();
    data.append("idExpense", expenseDetail.idExpense);
    data.append("idExpenseAdvance", expenseDetail.idExpenseAdvance);
    data.append("nameExpense", getValues("nameExpense"));
    data.append("description", getValues("description"));
    data.append("currency", getValues("currency"));
    data.append("amount", getValues("amount"));
    data.append("remark", getValues("remark"));
    data.append(
      "receiveDate",
      isValidDate(getValues("receiveDate"))
        ? dayjs(getValues("receiveDate")).format("YYYY-MM-DD")
        : JSON.stringify(null)
    );
    data.append(
      "returnDate",
      isValidDate(getValues("returnDate"))
        ? dayjs(getValues("returnDate")).format("YYYY-MM-DD")
        : JSON.stringify(null)
    );
    data.append("idExpensePaymentType", getValues("idExpensePaymentType"));
    data.append(
      "idExpenseReturnAdvanceType",
      getValues("idExpenseReturnAdvanceType")
    );
    data.append(
      "bankName",
      getValues("bankName") ? getValues("bankName") : JSON.stringify(null)
    );
    data.append(
      "bankAccountNumber",
      getValues("bankAccountNumber")
        ? getValues("bankAccountNumber")
        : JSON.stringify(null)
    );
    data.append("typeExpense", 6);
    data.append("typeExpenseName", getValues("typeExpenseName"));
    data.append("lastUpdateDate", dayjs(today).format("YYYY/MM/DD HH:mm"));
    data.append("status", statusId);
    data.append("net", getValues("amount"));
    data.append("idEmpApprover", getValues("approver"));
    data.append(
      "idEmpReviewer",
      getValues("reviewer")
        ? getValues("reviewer").idEmployees
        : JSON.stringify(null)
    );
    // data.append(
    //   "cc_email",
    //   JSON.stringify(
    //     getValues("cc_email")
    //       ? getValues("cc_email")
    //           .map((e) => e.email)
    //           .join(";")
    //       : null
    //   )
    // );
    data.append("idPosition", userProfile.idPosition);
    if (getValues("file")) {
      data.append("file", getValues("file"), getValues("file.path"));
    }
    if (!getValues("fileURL") && expenseDetail.fileURL) {
      data.append("deletedFile", expenseDetail.fileURL.path);
    }
    data.append(
      "comment",
      JSON.stringify(
        getValues("replyComment") ? getValues("replyComment") : null
      )
    );
    return data;
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleOnClickCleardata = () => {
    reset(formData);
  };

  const handleOnClickSavedraft = async () => {
    let data = createFormData(1);
    let result = null;
    if (userProfile && userProfile.idCompany && userProfile.idEmployees) {
      console.log(userProfile);
      result = await dispatch(updateExpenseAdvance(data));
    }
    if (result) {
      if (result.status === 200) {
        handleChangeAlertType("success");
        handleOpenAlert();
        dispatch(getExpenseAdvanceById(idExpense));
      } else {
        handleChangeAlertType("error");
        handleOpenAlert();
      }
    }
  };

  const handleOnClickSent = async () => {
    let status = 2;
    if (typeEdit === "Edit") {
      status = 3;
    }

    let result = null;
    if (expenseDetail.status == 10) {
      let data = createUpdateFileFormData(expenseDetail, getValues);
      if (userProfile && userProfile.idCompany && userProfile.idEmployees) {
        result = await dispatch(updateFileExpense(data));
      }
    } else {
      let data = createFormData(status);
      if (userProfile && userProfile.idCompany && userProfile.idEmployees) {
        result = await dispatch(updateExpenseAdvance(data));
      }
    }

    if (result) {
      if (result.status === 200) {
        handleChangeAlertType("success");
        handleOpenAlert();
        history.push(`/expense`);
      } else {
        handleChangeAlertType("error");
        handleOpenAlert();
      }
    }
  };

  return (
    <div>
      {expenseDetail ? (
        <Box>
          <AlertResponse
            open={openAlert}
            handleClose={handleCloseAlert}
            alertType={alertType}
          />
          <form>
            <Grid container spacing={2} style={{}} alignItems={"center"}>
              <Grid item xs={12}>
                <div className="wrap-head">
                  <StyledHeadLabel variant="h6">
                    {t("GeneralInformation")}
                    <Typography variant="subtitle" color="text.secondary">
                      &nbsp;({t(`${typeEdit}`)})
                    </Typography>
                  </StyledHeadLabel>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <StyledHeadLabel
                    variant="body2"
                    color="text.third"
                    gutterBottom
                  >
                    {t("TitleName")}
                  </StyledHeadLabel>
                  <FormControl
                    fullWidth
                    error={errors && errors.nameExpense ? true : false}
                  >
                    <Controller
                      name="nameExpense"
                      control={control}
                      errors={errors}
                      render={({ field }) => (
                        <>
                          <TextFieldTheme
                            {...field}
                            name="nameExpense"
                            inputProps={{ maxLength: 200, readOnly: readOnly }}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          />
                          {errors &&
                            errors.nameExpense &&
                            errors.nameExpense.message && (
                              <FormHelperText error>
                                {errors.nameExpense.message}
                              </FormHelperText>
                            )}
                          <Typography
                            component="p"
                            textAlign="right"
                            variant="caption"
                            color="text.secondary"
                          >{`${field.value.length}/200`}</Typography>
                        </>
                      )}
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <StyledHeadLabel
                    variant="body2"
                    color="text.third"
                    gutterBottom
                  >
                    {t("Descriptions")}
                  </StyledHeadLabel>
                  <FormControl
                    fullWidth
                    error={errors && errors.description ? true : false}
                  >
                    {errors &&
                      errors.description &&
                      errors.description.message && (
                        <FormHelperText error>
                          {errors.description.message}
                        </FormHelperText>
                      )}
                    <Controller
                      name="description"
                      control={control}
                      errors={errors}
                      render={({ field }) => (
                        <>
                          <TextFieldTheme
                            {...field}
                            name="description"
                            inputProps={{ maxLength: 500, readOnly: readOnly }}
                            multiline
                            rows={2}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          />
                          <Typography
                            component="p"
                            textAlign="right"
                            variant="caption"
                            color="text.secondary"
                          >{`${field.value.length}/500`}</Typography>
                        </>
                      )}
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={errors && errors.approver ? true : false}
                >
                  {errors && errors.approver && errors.approver.message && (
                    <FormHelperText error>
                      {errors.approver.message}
                    </FormHelperText>
                  )}
                  <Controller
                    name="approver"
                    control={control}
                    errors={errors}
                    render={({ field }) => (
                      <>
                        <SelectApprover
                          {...field}
                          idApprover={getValues("approver")}
                          handleChange={setValue}
                          position={userProfile && userProfile.idCompany === 3}
                          readOnly={readOnly}
                        />
                      </>
                    )}
                  />
                </FormControl>
              </Grid>
              {/* <Grid item xs={12}>
                <SelectCC control={control} errors={errors} />
              </Grid> */}

              <Grid item xs={12}>
                <StyledHeadLabel
                  variant="body2"
                  color="text.third"
                  gutterBottom
                >
                  {t("Inspector")}
                </StyledHeadLabel>
                <FormControl
                  fullWidth
                  error={errors && errors.reviewer ? true : false}
                >
                  {errors && errors.reviewer && errors.reviewer.message && (
                    <FormHelperText error>
                      {errors.reviewer.message}
                    </FormHelperText>
                  )}
                  <Controller
                    name="reviewer"
                    control={control}
                    errors={errors}
                    render={({ field }) => (
                      <>
                        <StyledAutocomplete
                          {...field}
                          options={admins ? admins : []}
                          readOnly={readOnly}
                          getOptionLabel={(option) => getUserFullName(option)}
                          isOptionEqualToValue={(option, value) =>
                            option.idEmployees === value.idEmployees
                          }
                          filterOptions={filterOptions}
                          renderOption={(props, option) => (
                            <MenuItem {...props} key={option.idEmployees}>
                              <Typography>{getUserFullName(option)}</Typography>
                            </MenuItem>
                          )}
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              placeholder={t("AddInspector")}
                              onBlur={field.onBlur}
                            />
                          )}
                          value={field.value}
                          onChange={(_, value) => {
                            field.onChange(value);
                          }}
                          noOptionsText={t("NoData")}
                        />
                      </>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <StyledDivider />
            <Grid item xs={12}>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">
                  {t("receiveAdvance")}
                </StyledHeadLabel>
              </div>
            </Grid>
            <Grid>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <StyledHeadLabel
                      variant="body2"
                      color="text.third"
                      gutterBottom
                    >
                      {t("Currency")}
                    </StyledHeadLabel>
                    <FormControl
                      fullWidth
                      error={errors && errors.currency ? true : false}
                    >
                      <Controller
                        name="currencyItem"
                        control={control}
                        errors={errors}
                        render={({ field }) => (
                          <>
                            <StyledAutocomplete
                              {...field}
                              fullWidth
                              readOnly={readOnly}
                              options={currency()}
                              onChange={(e, newValue) => {
                                if (newValue === null) {
                                  setValue("currencyItem", defaultCurrency);
                                  setValue("currency", "THB");
                                } else {
                                  setValue("currencyItem", newValue);
                                  setValue("currency", newValue.unit);
                                }
                              }}
                              popupIcon={
                                <i class="fa-light fa-chevron-down"></i>
                              }
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <img
                                    loading="lazy"
                                    width="20"
                                    style={{ marginRight: 16, flexShrink: 0 }}
                                    src={`https://flagcdn.com/16x12/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/32x24/${option.code.toLowerCase()}.png 2x`}
                                    alt={option.code}
                                  />
                                  {t(`${option.label}`)}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder=""
                                  name="vatType"
                                />
                              )}
                              PopperComponent={StyledPopper}
                              noOptionsText={t("NoData")}
                            />
                          </>
                        )}
                      />
                      {errors && errors.currency && errors.currency.message && (
                        <FormHelperText error>
                          {errors.currency.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <div>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("AmountOfMoney")}
                      </StyledHeadLabel>
                      <FormControl
                        fullWidth
                        error={errors && errors.amount ? true : false}
                      >
                        <Controller
                          name="amount"
                          control={control}
                          errors={errors}
                          render={({ field }) => (
                            <>
                              <TextFieldTheme
                                {...field}
                                name="amount"
                                InputProps={{
                                  inputComponent: NumberFormatTheme,
                                  readOnly: readOnly,
                                }}
                                inputProps={{
                                  decimalScale: 2,
                                  allowNegative: false,
                                  value: field.value,
                                  onValueChange: (values) => {
                                    const { value } = values;
                                    field.onChange(value);
                                  },
                                }}
                                onChange={() => {}}
                              />
                            </>
                          )}
                        />
                        {errors && errors.amount && errors.amount.message && (
                          <FormHelperText error>
                            {errors.amount.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <StyledHeadLabel
                      variant="body2"
                      color="text.third"
                      gutterBottom
                    >
                      {t("receiveType")}
                    </StyledHeadLabel>
                    <FormControl
                      fullWidth
                      error={
                        errors && errors.idExpensePaymentType ? true : false
                      }
                    >
                      <Controller
                        name="paymentType"
                        control={control}
                        errors={errors}
                        render={({ field }) => (
                          <StyledAutocomplete
                            {...field}
                            readOnly={readOnly}
                            options={paymentTypes}
                            getOptionLabel={(option) =>
                              i18n.resolvedLanguage === "th"
                                ? option.name_TH
                                : option.name_EN
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.idExpensePaymentType ===
                              value.idExpensePaymentType
                            }
                            getOptionSelected={(option, value) =>
                              option.idExpensePaymentType ===
                              value.idExpensePaymentType
                            }
                            filterOptions={filterOptions}
                            renderOption={(props, option) => (
                              <MenuItem
                                {...props}
                                key={option.idExpensePaymentType}
                              >
                                <Box>
                                  <Typography>
                                    {i18n.resolvedLanguage === "th"
                                      ? option.name_TH
                                      : option.name_EN}
                                  </Typography>
                                </Box>
                              </MenuItem>
                            )}
                            renderInput={(params) => (
                              <TextFieldTheme
                                {...params}
                                placeholder={t("Search")}
                                onBlur={field.onBlur}
                              />
                            )}
                            value={field.value}
                            onChange={(_, value) => {
                              field.onChange(value);
                              if (value) {
                                setValue(
                                  "idExpensePaymentType",
                                  value.idExpensePaymentType
                                );
                                if (value.idExpensePaymentType != 1) {
                                  setValue("bookBank", null);
                                  setValue("bankAccountNumber", null);
                                }
                              } else {
                                setValue("idExpensePaymentType", null);
                                setValue("bookBank", null);
                                setValue("bankAccountNumber", null);
                              }
                            }}
                            noOptionsText={t("NoData")}
                          ></StyledAutocomplete>
                        )}
                      />
                      {errors &&
                        errors.idExpensePaymentType &&
                        errors.idExpensePaymentType.message && (
                          <FormHelperText
                            error
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            {errors.idExpensePaymentType.message}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledHeadLabel
                      variant="body2"
                      color="text.third"
                      gutterBottom
                    >
                      {t("receiveDate")}
                    </StyledHeadLabel>
                    <FormControl
                      fullWidth
                      error={errors && errors.receiveDate ? true : false}
                    >
                      {errors &&
                        errors.receiveDate &&
                        errors.receiveDate.message && (
                          <FormHelperText
                            error
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            {errors.receiveDate.message}
                          </FormHelperText>
                        )}
                      <Controller
                        name={`receiveDate`}
                        control={control}
                        errors={errors}
                        render={({ field }) => (
                          <DatePickerCustom
                            {...field}
                            disabled={readOnly}
                            inputFormat="DD/MM/YYYY"
                            onChange={(e) => {
                              if (e) {
                                field.onChange(dayjs(e).format("YYYY/MM/DD"));
                              } else {
                                field.onChange(null);
                              }
                            }}
                            renderInput={(params) => (
                              <TextFieldTheme fullWidth {...params} />
                            )}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {watch("idExpensePaymentType") == 1 && (
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                      <div>
                        <StyledHeadLabel
                          variant="body2"
                          color="text.third"
                          gutterBottom
                        >
                          {t("BookBank")}
                        </StyledHeadLabel>
                        <FormControl
                          fullWidth
                          error={errors && errors.bankName ? true : false}
                        >
                          {errors &&
                            errors.bankName &&
                            errors.bankName.message && (
                              <FormHelperText error>
                                {errors.bankName.message}
                              </FormHelperText>
                            )}
                          <Controller
                            name="bankName"
                            control={control}
                            errors={errors}
                            render={({ field }) => (
                              <>
                                <TextFieldTheme
                                  {...field}
                                  name="bankName"
                                  inputProps={{
                                    maxLength: 100,
                                    readOnly: readOnly,
                                  }}
                                  onChange={(e) => {
                                    field.onChange(e.target.value);
                                  }}
                                />
                              </>
                            )}
                          />
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div>
                        <StyledHeadLabel
                          variant="body2"
                          color="text.third"
                          gutterBottom
                        >
                          {t("BookID")}
                        </StyledHeadLabel>
                        <FormControl
                          fullWidth
                          error={
                            errors && errors.bankAccountNumber ? true : false
                          }
                        >
                          {errors &&
                            errors.bankAccountNumber &&
                            errors.bankAccountNumber.message && (
                              <FormHelperText error>
                                {errors.bankAccountNumber.message}
                              </FormHelperText>
                            )}
                          <Controller
                            name="bankAccountNumber"
                            control={control}
                            errors={errors}
                            render={({ field }) => (
                              <>
                                <TextFieldTheme
                                  {...field}
                                  name="bankAccountNumber"
                                  inputProps={{
                                    maxLength: 100,
                                    readOnly: readOnly,
                                  }}
                                  onChange={(e) => {
                                    field.onChange(e.target.value);
                                  }}
                                />
                              </>
                            )}
                          />
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <StyledDivider />
              <Grid item xs={12}>
                <div className="wrap-head">
                  <StyledHeadLabel variant="h6">
                    {t("returnAdvance")}
                  </StyledHeadLabel>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <StyledHeadLabel
                      variant="body2"
                      color="text.third"
                      gutterBottom
                    >
                      {t("returnType")}
                    </StyledHeadLabel>
                    <FormControl
                      fullWidth
                      error={
                        errors && errors.idExpenseReturnAdvanceType
                          ? true
                          : false
                      }
                    >
                      <Controller
                        name="returnType"
                        control={control}
                        errors={errors}
                        render={({ field }) => (
                          <StyledAutocomplete
                            {...field}
                            options={returnTypes}
                            readOnly={readOnly}
                            getOptionLabel={(option) =>
                              i18n.resolvedLanguage === "th"
                                ? option.name_TH
                                : option.name_EN
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.idExpenseReturnAdvanceType ===
                              value.idExpenseReturnAdvanceType
                            }
                            getOptionSelected={(option, value) =>
                              option.idExpenseReturnAdvanceType ===
                              value.idExpenseReturnAdvanceType
                            }
                            filterOptions={filterOptions}
                            renderOption={(props, option) => (
                              <MenuItem
                                {...props}
                                key={option.idExpenseReturnAdvanceType}
                              >
                                <Box>
                                  <Typography>
                                    {i18n.resolvedLanguage === "th"
                                      ? option.name_TH
                                      : option.name_EN}
                                  </Typography>
                                </Box>
                              </MenuItem>
                            )}
                            renderInput={(params) => (
                              <TextFieldTheme
                                {...params}
                                placeholder={t("Search")}
                                onBlur={field.onBlur}
                              />
                            )}
                            value={field.value}
                            onChange={(_, value) => {
                              field.onChange(value);
                              if (value) {
                                setValue(
                                  "idExpenseReturnAdvanceType",
                                  value.idExpenseReturnAdvanceType
                                );
                                if (value.idExpenseReturnAdvanceType != 2) {
                                  setValue("returnDate", null);
                                }
                              } else {
                                setValue("idExpenseReturnAdvanceType", null);
                              }
                            }}
                            noOptionsText={t("NoData")}
                          ></StyledAutocomplete>
                        )}
                      />
                      {errors &&
                        errors.idExpenseReturnAdvanceType &&
                        errors.idExpenseReturnAdvanceType.message && (
                          <FormHelperText
                            error
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            {errors.idExpenseReturnAdvanceType.message}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                  {watch("idExpenseReturnAdvanceType") == 2 && (
                    <Grid item xs={12} sm={6}>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("returnDate")}
                      </StyledHeadLabel>
                      <FormControl
                        fullWidth
                        error={errors && errors.returnDate ? true : false}
                      >
                        {errors &&
                          errors.returnDate &&
                          errors.returnDate.message && (
                            <FormHelperText
                              error
                              sx={{
                                textAlign: "center",
                              }}
                            >
                              {errors.returnDate.message}
                            </FormHelperText>
                          )}
                        <Controller
                          name={`returnDate`}
                          control={control}
                          errors={errors}
                          render={({ field }) => (
                            <DatePickerCustom
                              {...field}
                              disabled={readOnly}
                              inputFormat="DD/MM/YYYY"
                              maxDate={dayjs(dayjs().format("YYYY-MM-DD")).add(
                                3,
                                "months"
                              )}
                              onChange={(e) => {
                                field.onChange(dayjs(e).format("YYYY/MM/DD"));
                                console.log(watch("returnDate"));
                              }}
                              renderInput={(params) => (
                                <TextFieldTheme fullWidth {...params} />
                              )}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <StyledDivider />
            <div>
              <div>
                <div className="wrap-row-sum">
                  <Typography variant="h5">
                    {t("TotalNetWorth")}&nbsp;&nbsp;
                  </Typography>
                  <Typography variant="h4">
                    {utils.numberWithCommas(parseFloat(watch("amount")))}
                  </Typography>
                  <Typography variant="h5">
                    &nbsp;&nbsp;{t(`CurrencyUnit.${watch("currency")}`)}
                  </Typography>
                </div>
              </div>
            </div>
            <StyledDivider />
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">
                  {t("AttachFile")}
                </StyledHeadLabel>
              </div>
              {!watch("file") && !watch("fileURL") ? (
                <StyledDropzone>
                  <Dropzone
                    accept="image/jpeg, image/png, application/pdf"
                    maxFiles={1}
                    multiple={false}
                    maxSize={3145728}
                    onDrop={(acceptedFiles, rejectedFiles) => {
                      if (acceptedFiles.length > 0) {
                        setValue("file", acceptedFiles[0]);
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps({
                          className: "dropzone-upload-file",
                        })}
                      >
                        <div className="inner-dropzone">
                          <input {...getInputProps()} />
                          {/* <Fragment>{thumbs}</Fragment> */}
                          <div style={{ textAlign: "center" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <img alt="add" src={GalleryIcon} />
                            </div>
                            <Typography
                              variant="caption"
                              style={{ lineHeight: 0.5, color: "#999999" }}
                            >
                              {t("imageFileNotMore500KB")}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </StyledDropzone>
              ) : !watch("fileURL") && watch("file") ? (
                <Box
                  style={{
                    position: "relative",
                    textAlign: "center",
                  }}
                >
                  <IconButton
                    style={{ position: "absolute", margin: 7, right: 0 }}
                    color="error"
                    onClick={() => {
                      setValue("file", null);
                      setValue("fileURL", null);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <div
                    style={{
                      padding: 15,
                      backgroundColor: "#f9f9fb",
                      display: "flex",
                      borderRadius: 15,
                    }}
                  >
                    <FilePresentIcon
                      height="24"
                      style={{
                        marginRight: 10,
                      }}
                    ></FilePresentIcon>
                    <Typography
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                      }}
                    >
                      {watch("file.path")}
                    </Typography>
                  </div>
                </Box>
              ) : (
                <Box
                  style={{
                    position: "relative",
                    textAlign: "center",
                  }}
                >
                  <IconButton
                    style={{ position: "absolute", margin: 7, right: 0 }}
                    color="error"
                    onClick={() => {
                      setValue("file", null);
                      setValue("fileURL", null);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <div
                    style={{
                      padding: 15,
                      backgroundColor: "#f9f9fb",
                      display: "flex",
                      borderRadius: 15,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      expenseDetail.fileURL &&
                        window.open(`${expenseDetail.fileURL.URL}`, "_blank");
                    }}
                  >
                    <FilePresentIcon
                      height="24"
                      style={{
                        marginRight: 10,
                      }}
                    ></FilePresentIcon>
                    {expenseDetail &&
                      expenseDetail.fileURL &&
                      expenseDetail.fileURL.path && (
                        <Typography
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "100%",
                          }}
                        >
                          {expenseDetail.fileURL.path}
                        </Typography>
                      )}
                  </div>
                </Box>
              )}
            </div>
            <StyledDivider />
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">{t("Note")}</StyledHeadLabel>
              </div>
              <Controller
                name={"remark"}
                control={control}
                errors={errors}
                render={({ field }) => (
                  <>
                    <TextFieldTheme
                      {...field}
                      multiline
                      rows={4}
                      InputProps={{ readOnly: readOnly }}
                    />
                    <Typography
                      component="p"
                      textAlign="right"
                      variant="caption"
                      color="text.secondary"
                    >{`${
                      field.value ? field.value.length : 0
                    }/500`}</Typography>
                  </>
                )}
              />
            </div>
            {typeEdit &&
            typeEdit === "Edit" &&
            expenseDetail &&
            expenseDetail.comments &&
            expenseDetail.comments.length &&
            expenseDetail.comments.length > 0 ? (
              <div>
                <Comments comments={expenseDetail.comments} />
                <StyledDivider />
                <FormControl
                  fullWidth
                  error={errors && errors.replyComment ? true : false}
                >
                  {errors &&
                    errors.replyComment &&
                    errors.replyComment.message && (
                      <FormHelperText error>
                        {errors.replyComment.message}
                      </FormHelperText>
                    )}
                  <StyledHeadLabel variant="h6">{t("Reply")}</StyledHeadLabel>
                  <Controller
                    name="replyComment"
                    control={control}
                    errors={errors}
                    render={({ field }) => (
                      <>
                        <TextFieldTheme
                          {...field}
                          name="replyComment"
                          sx={{ marginTop: 1 }}
                          multiline
                          rows={2}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                        />
                      </>
                    )}
                  />
                </FormControl>
              </div>
            ) : (
              <span></span>
            )}
            <StyledDivider />
            {expenseDetail.actions &&
            expenseDetail.actions.length &&
            expenseDetail.actions.length > 0 ? (
              <Actions actions={expenseDetail.actions} />
            ) : (
              <span></span>
            )}
            <ActionButtons
              typeEdit={typeEdit}
              handleOnClickCleardata={handleOnClickCleardata}
              handleSubmitDraft={handleSubmit(
                handleOnClickSavedraft,
                checkValidation
              )}
              handleSubmitSent={handleSubmit(
                handleOnClickSent,
                checkValidation
              )}
            />
          </form>
        </Box>
      ) : (
        <Backdrop open={true} />
      )}
    </div>
  );
};

export default Advance;
