import * as React from 'react';
import { fontWeight, styled } from '@mui/system';
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';
import { Typography } from "@mui/material";

import { useTranslation } from 'react-i18next';

import Culture from "../landing/image/AddManuTab/CultureTab.png";
import Learning from "../landing/image/AddManuTab/LearningTab.png";
import Recruitment from "../landing/image/AddManuTab/RecruitmentTab.png";
import Expense from "../landing/image/AddManuTab/ExpenseTab.png";

export default function AddManuTap() {
  const { t, i18n } = useTranslation();
  return (
    <Tabs defaultValue={0}>
      <TabsList>
        <Tab value={0}>{t("learning")}</Tab>
        <Tab value={1}>{t("culture")}</Tab>
        <Tab value={2}>{t("recruitment")}</Tab>
        <Tab value={3}>{t("expenses")}</Tab>
      </TabsList>
      <TabPanel value={0}>
        <ContentSection
          title= {t("LearningTitle")}
          subtitle={t("LearningSubtitle")}
          description={t("LearningDescription")}
          image={Learning}
        />
      </TabPanel>
      <TabPanel value={1}>
        <ContentSection
            title={t("CultureTitle")}
            subtitle={t("CultureSubtitle")}
            description={t("CultureDescription")}
            image={Culture}
        />
      </TabPanel>
      <TabPanel value={2}>
      <ContentSection
          title={ 
              <>
                  <span style={{ color: "#EB94C0" }}>
                  {t("RecruitmentTitle")}
                  </span>
              </>
          }
          subtitle={
              <>
                  <span style={{ color: "#000000" }}>
                  {t("RecruitmentSubtitle")}
                  </span>
              </>
          }
            description={t("RecruitmentDescription")}            
            image={Recruitment}
            />
      </TabPanel>
      <TabPanel value={3}>
      <ContentSection
        title={ 
            <>
                <span style={{ color: "#EB94C0" }}>
                {t("ExpensesTitle")}
                </span>
            </>
        }
        subtitle={
            <>
                <span style={{ color: "#000000" }}>
                {t("ExpensesSubtitle")}
                </span>
            </>
        }
        description={t("ExpensesDescription")}
        image={Expense}
        />
      </TabPanel>
    </Tabs>
  );
}

function ContentSection({ title, subtitle, description, image }) {
  return (
    <ContentSectionWrapper>
      <div className="text-content">
        <Typography variant="h3" align='left' gutterBottom sx={{ fontSize: { xs: 20, sm: 30, md: 35 }, marginRight: 5 }}>
          {title}
          <br />
          <span style={{ color: "#EB94C0" }}>{subtitle}</span>
        </Typography>
        <Typography variant="subtitle1" align='left' sx={{ fontWeight: 200, marginRight: 5, fontSize: { md: '14px', sm: '12px' } }}>
          {description}
        </Typography>
      </div>
      <img
        src={image}
        alt={title}
        className="image-content"
      />
    </ContentSectionWrapper>
  );
}

const ContentSectionWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 85px;

  @media (max-width: 800px) {
    flex-direction: column-reverse; /* รูปภาพจะอยู่บน */
    align-items: center;
    text-align: center;
    margin-bottom: 100px !important; 
    margin-top: 50px;
  }

  .text-content {
    display: flex;
    flex-direction: column;
    max-width: 60%;

    @media (max-width: 800px) {
      max-width: 100%;
      margin-top: 20px;
      margin-bottom: 70px;
    }
  }

  .image-content {
    max-width: 450px;
    height: auto;
    border-radius: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    @media (max-width: 800px) {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }
`;


const Tab = styled(BaseTab)`
  font-family: 'Kanit';
  color: #000000;
  cursor: pointer;
  font-size: 24px;
  font-weight: 400;
  background-color: #ffffff;
  width: 20%;
  padding: 12px 5px;
  margin: 10px 60px;
  border: none;
  border-radius: 45px;
  display: flex;
  justify-content: center;

  &:hover {
    color:  #EB94C0;
    background-color: rgba(246, 221, 234, 0.5);
  }

  &:focus {
    color:  #EB94C0;
    background-color: rgba(246, 221, 234, 0.5);
  }

  &.${tabClasses.selected} {
    color: #EB94C0;
    background-color: rgba(246, 221, 234, 0.5);
  }

  &.${buttonClasses.disabled} {
    opacity: 0.2;
    cursor: not-allowed;
  }
  @media (max-width: 800px) {
    width: auto; 
    padding: 10px 20px;
    font-size: 20px; 
    margin: 10px; 
  }
`;

const TabPanel = styled(BaseTabPanel)`
  width: 100%;
  height: 300px;
  align-items: center;
  font-family: 'Kanit';
  font-size: 0.875rem;
  padding: 20px 12px;
  background: #ffffff;
  border-radius: 12px;
  opacity: 1;
`;

const TabsList = styled(BaseTabsList)`
  min-width: 400px;
  background-color: #ffffff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  place-content: space-between center;

  @media (max-width: 800px) {
    flex-wrap: wrap; 
    justify-content: center; 
    min-width: 100%; 
  }
`;
