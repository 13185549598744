import DoneAll from "@mui/icons-material/DoneAll";
import DeleteIcon from "@mui/icons-material/Delete";
import { Avatar, Box, styled, Typography, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import TableCustom from "../../../shared/tableCustom";
import DialogConfirmReject from "../DialogConfirmReject";
// import CardRequest from "./CardRequest";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import {
  getUserFullName,
  getUserPosition,
} from "../../../../../utils/userData";
import {
  getAdminProjectApprove,
  updateAdminProjectApprove,
} from "../../../../../actions/project";

const StyledButtonCancel = styled(ButtonBlue)({
  backgroundColor: "#E793B8",
  borderRadius: "8px",
  color: "#FFFFFF",
  marginRight: 8,
  width: 130,
  "&:hover": {
    backgroundColor: "#DC6384",
  },
});

const RequestProject = ({
  searchDate,
  setSnackBarConfig,
  setNumberOfList,
  selectedCompany,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: dataProject, isFetching } = useSelector(
    (state) => state.dataProject
  );

  const [pendingRows, setPendingRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const mobileResponsive = useMediaQuery("(max-width:600px)");
  const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);

  const columns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>{getUserFullName(row)}</Typography>
            <Typography color="text.third" fontSize="14px">
              {getUserPosition(row)}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("ProjectName")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Typography fontSize={14}>{row.projectName}</Typography>
      ),
    },
    {
      name: `${t("Impact")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => <Typography fontSize={14}>{row.impact}</Typography>,
    },
    {
      name: `${t("Size")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => <Typography fontSize={14}>{row.size}</Typography>,
    },
    {
      name: `${t("Role")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => <Typography fontSize={14}>{row.role}</Typography>,
    },
    {
      name: `${t("Type")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => <Typography fontSize={14}>{row.type}</Typography>,
    },
    {
      name: `${t("Start")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {i18n.resolvedLanguage === "th"
              ? dayjs(row.dateStart).format("DD MMM") +
                " " +
                (parseInt(dayjs(row.dateStart).format("YYYY")) + 543)
              : dayjs(row.dateStart).format("DD MMM YYYY")}
          </Typography>
          <Typography>{dayjs(row.dateStart).format("HH:mm")}</Typography>
        </Box>
      ),
    },
    {
      name: `${t("End")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {i18n.resolvedLanguage === "th"
              ? dayjs(row.dateEnd).format("DD MMM") +
                " " +
                (parseInt(dayjs(row.dateEnd).format("YYYY")) + 543)
              : dayjs(row.dateEnd).format("DD MMM YYYY")}
          </Typography>
          <Typography>{dayjs(row.dateEnd).format("HH:mm")}</Typography>
        </Box>
      ),
    },
    {
      name: `${t("Details")}`,
      minWidth: "200px",
      cellRender: (row) => <Typography fontSize="14px">{row.note}</Typography>,
    },
  ];

  const handleConfirmReject = async (comment) => {
    try {
      const idEmployeeProjectList = selectedRows.map(
        (item) => item.idEmployeeProject
      );
      const response = await updateAdminProjectApprove({
        idEmployeeProjectList,
        isApproved: 0,
        note: comment,
      });

      if (response && response.status === 200) {
        setIsOpenRejectDialog(false);
        setSnackBarConfig({
          open: true,
          message: `${t("Successfully")}`,
          type: "success",
        });
        dispatch(
          getAdminProjectApprove({
            idCompany: selectedCompany.idCompany,
            start: searchDate.start
              ? dayjs(searchDate.start).format("YYYY-MM-DD")
              : null,
            end: searchDate.end
              ? dayjs(searchDate.end).format("YYYY-MM-DD")
              : null,
          })
        );
      } else {
        setSnackBarConfig({
          open: true,
          message: `${t("Unsuccessful")}`,
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
      setSnackBarConfig({
        open: true,
        message: `${t("Unsuccessful")}`,
        type: "error",
      });
    }
  };

  const handleApproveProject = async (status) => {
    try {
      const idEmployeeProjectList = selectedRows.map(
        (item) => item.idEmployeeProject
      );
      const response = await updateAdminProjectApprove({
        idEmployeeProjectList,
        isApproved: status,
      });
      if (response && response.status === 200) {
        dispatch(
          getAdminProjectApprove({
            idCompany: selectedCompany.idCompany,
            start: searchDate.start
              ? dayjs(searchDate.start).format("YYYY-MM-DD")
              : null,
            end: searchDate.end
              ? dayjs(searchDate.end).format("YYYY-MM-DD")
              : null,
          })
        );
        setSnackBarConfig({
          open: true,
          message: `${t("Successfully")}`,
          type: "success",
        });
        selectedRows([]);
      } else {
        setSnackBarConfig({
          open: true,
          message: `${t("Unsuccessful")}`,
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
      setSnackBarConfig({
        open: true,
        message: `${t("Unsuccessful")}`,
        type: "error",
      });
    }
  };

  const handleClickAll = () => {
    setSelectedRows(pendingRows);
  };

  const handleCancel = () => {
    setSelectedRows([]);
  };

  useEffect(() => {
    if (dataProject) {
      let pendingList = [...dataProject];
      setPendingRows(pendingList);
      setNumberOfList(pendingList.length);
    }
  }, [dataProject]);

  return (
    <Box>
      {mobileResponsive && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          {(selectedRows ? selectedRows.length : 0) > 0 && (
            <StyledButtonCancel
              startIcon={<DeleteIcon />}
              onClick={handleCancel}
            >
              {`${t("Cancel")}`}
            </StyledButtonCancel>
          )}
          {!(
            (selectedRows ? selectedRows.length : 0) === pendingRows.length
          ) && (
            <ButtonBlue
              startIcon={<DoneAll />}
              variant="contained"
              onClick={handleClickAll}
            >
              {`${t("SelectAll")}`}
            </ButtonBlue>
          )}
        </Box>
      )}
      {selectedRows.length > 0 && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          <ButtonBlue
            style={{ marginRight: 8 }}
            startIcon={<DoneAll />}
            variant="contained"
            onClick={() => handleApproveProject(1)}
          >
            {`${t("Approved")} ${
              selectedRows.length > 0
                ? `${selectedRows.length} ${t("List")}`
                : ""
            }`}
          </ButtonBlue>
          <ButtonBlue
            startIcon={<DoneAll />}
            variant="outlined"
            onClick={() => setIsOpenRejectDialog(true)}
          >
            {`${t("NotApproved")} ${
              selectedRows.length > 0
                ? `${selectedRows.length} ${t("List")}`
                : ""
            }`}
          </ButtonBlue>
        </Box>
      )}

      {!mobileResponsive ? (
        <TableCustom
          columns={columns}
          rows={dataProject && dataProject.length > 0 ? pendingRows : []}
          canSelect
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      ) : (
        <div>
          {/* {dataProject && dataProject.length > 0 ? (
            pendingRows.map((row) => (
              <CardRequest
                row={row}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />
            ))
          ) : (
            <div>
              <Typography align="center">{`${t("NoData")}`}</Typography>
            </div>
          )} */}
        </div>
      )}

      <DialogConfirmReject
        rejectCount={selectedRows.length}
        open={isOpenRejectDialog}
        onClose={() => {
          setIsOpenRejectDialog(false);
        }}
        handleSubmit={(comment) => {
          handleConfirmReject(comment);
        }}
      />
    </Box>
  );
};

export default RequestProject;
