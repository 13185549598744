import { httpClient } from "./httpClient";

const calculateOT = (date, start, end, value) => {
    return httpClient.post(`/calculate/ot?date=`+date+`&start=`+start+`&end=`+end, value);
};

const calculateRetirementEmployees = (idCompany) => {
    return httpClient.post(`/calculate/retirement-employees/${idCompany}`);
};

export default {
    calculateOT,
    calculateRetirementEmployees,
};