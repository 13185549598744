import React, { useEffect, useState } from "react";
import { Box, Container, styled, Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAffiliateOrganization } from "../../../../../actions/company";
import SelectAffiliateCompany from "../../../shared/general/SelectAffiliateCompany";
import KpiAdminEvaluation from "./KpiAdminEvaluation";
import KpiAdminApprovePlan from "./KpiAdminApprovePlan";

const StyledRoot = styled("div")(({ theme }) => ({
  background: "#FFFFFF !important",
  "& .displayFlexEnd": {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "8px 0",
    },
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const KpiAdminApprove = () => {
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );

  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
  };

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (
      userProfile &&
      affiliateOrganizationList &&
      affiliateOrganizationList.length > 0
    ) {
      let ownCompany = affiliateOrganizationList.find(
        (x) => x.idCompany === userProfile.idCompany
      );
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany);
    }
  }, [userProfile, affiliateOrganizationList]);

  return (
    <StyledRoot className="page">
      <Container>
        <Box marginBottom="24px">
          <SelectAffiliateCompany
            options={affiliateOrganizationList}
            value={selectedCompany}
            onChange={(_, value) => {
              handleChangeCompany(value);
            }}
          />
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={t("PendingKPIApproval")} {...a11yProps(0)} />
            <Tab label={t("KPIEvaluation")} {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <KpiAdminApprovePlan selectedCompany={selectedCompany} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <KpiAdminEvaluation selectedCompany={selectedCompany} />
        </CustomTabPanel>
      </Container>
    </StyledRoot>
  );
};

export default KpiAdminApprove;
