import * as React from "react";
import { Container, Typography, Avatar, Grid, Box, Card  } from '@mui/material';

import { useTranslation } from 'react-i18next';

import KhunBig from "../landing/image/CustomerSay/k'big.jpg";
import KhunKie from "../landing/image/CustomerSay/k'kie.jpg";
import KhunKieng from "../landing/image/CustomerSay/k'kieng.jpg";
import KhunApichat from "../landing/image/CustomerSay/k'apichat.jpg";

import SCGC from "../landing/image/Customer/scgc-logo.png";
import SCGhome from "../landing/image/Customer/scg-home-logo.png";
import WakeUpWaste from "../landing/image/Customer/wake-up-waste-logo.png";
import QGen from "../landing/image/Customer/qgen-logo.png";

export default function CustomerSay() {
    const { t, i18n } = useTranslation();
    const partners = [
        {
          name: t("partnerName1"),
          position: "Marketing Lead , Home Solution",
          testimonial: t("partnerTestimonial1"),
          avatar: KhunKieng,
          logo: SCGhome,
          alt: "SCG Home"
        },
        {
          name: t("partnerName2"),
          position: "HR Share Service Manager SCGC",
          testimonial: t("partnerTestimonial2"),
          avatar: KhunBig,
          logo: SCGC,
          alt: "SCGC",
          logoStyle: { height: "40px" }
        },
        {
          name: t("partnerName3"),
          position: "CEO Wake Up Waste : Start Up Company",
          testimonial:t("partnerTestimonial3"),
          avatar: KhunKie,
          logo: WakeUpWaste,
          alt: "WakeUpWaste",
          logoStyle: { height: "100px", width: "auto", transform: "translateY(20px)" }
        },
        {
          name: t("partnerName4"),
          position: "CEO, Q Gen Consultant",
/*           (
            <>
              CEO, Q Gen Consultant
              <br />
              <span dangerouslySetInnerHTML={{ __html: t("partnerPosition4") }} />
            </>
          ),   */                         
          testimonial: t("partnerTestimonial4"),
          avatar: KhunApichat,
          logo: QGen,
          alt: "QGen"
        }
      ];
    return (
    <Container maxWidth="lg">
        <Typography
          variant="h2"
          align="center"
          sx={{ fontSize: "48px", fontWeight: 400, marginTop: "60px", marginBottom: "50px" }}
        >
          {t("ourCustomersAndPartnersSay")}
        </Typography>
        <Grid container rowSpacing={3} columnSpacing={8}>
          {partners.map((partner, index) => (
            <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
              <Card className="partner-card">
                <Box className="partner-card-header">
                  <Box className="partner-info">
                    <Avatar className="partner-avatar" src={partner.avatar} />
                    <Box className="partner-details">
                      <Typography className="partner-testimonial">
                        {partner.testimonial}
                      </Typography>
                      <Typography className="partner-name">{partner.name}</Typography>
                      <Typography className="partner-position" sx={{ lineHeight: 1 }} dangerouslySetInnerHTML={{ __html: partner.position }}></Typography>
                    </Box>
                  </Box>
                  <img
                    src={partner.logo}
                    alt={partner.alt}
                    className="partner-logo"
                    style={partner.logoStyle || {}}
                  />
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      
    )
}
