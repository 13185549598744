import React, { useState, useEffect } from 'react';
import {
    Container,
} from '@mui/material'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/system';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import 'react-quill/dist/quill.snow.css';
import '../styles/quillStyles.css'
import DocumentService from "../../../../../services/document.service";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import UsagePopup from '../dialog/UsagePopup.js';

import {
    getSmartDocumentHtmlById,
    upsertPdfSmartDocument,
} from "../../../../../actions/document";
import QuillEditor from './QuillEditor.js';
import Loading from '../../../shared/loading/index.js';
import LoadingFullPage from '../../../shared/loadingFullPage/index.js';

const StyledRoot = styled("div")({
    backgroundColor: "#FFFFFF !important",
    position: 'relative'
});

const create = (props) => {
    const { row, userProfile } = props.location.state || {};

    const [loading, setLoading] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const [content, setContent] = useState('');

    const [showPopup, setShowPopup] = useState(false);
    const [dontShowAgain, setDontShowAgain] = useState(false);
    const [isShowOption, setIsShowOption] = useState(true);
    // const [signatureDataList, setSignatureDataList] = useState([]);

    const { result: allSmartDocument, isFetching: isFetchingAllSmartDocument } = useSelector((state) => state.allSmartDocument);

    useEffect(() => {
        if (!row || !userProfile) {
            history.push('/smart-documents');
        }
    }, [row, userProfile]);

    useEffect(() => {
        const showNotification = localStorage.getItem("showNotification");
        if (showNotification === null) {
            localStorage.setItem("showNotification", "true");
            setShowPopup(true);
        } else if (showNotification === "false") {
            setShowPopup(false);
        } else {
            setShowPopup(true);
        }
    }, []);

    useEffect(() => {
        if (row && row.smartDocumentId) {
            dispatch(getSmartDocumentHtmlById(row.smartDocumentId))
        }
    }, [])

    useEffect(() => {
        if (allSmartDocument) {
            setContent(allSmartDocument.content)
        }
    }, [allSmartDocument])

    const handleClosePopup = () => {
        if (dontShowAgain) {
            localStorage.setItem("showNotification", "false");
        }
        setShowPopup(false);
    };

    const handleOnSubmit = async () => {
        try {

            const data = {
                "smartDocumentId": row ? row.smartDocumentId : null,
                "content": content.replace('<img', `<img class="image-class" `)
            }

            const result = await dispatch(upsertPdfSmartDocument(data));

            if (result) {
                const text = result.status === 200 ? `${t('Update')} ${t('Succeed')} ` : t('Document_created_successfully')
                Swal.fire({
                    title: `${t("Succeed")}!!`,
                    text: `${text}!!`,
                    icon: 'success',
                    confirmButtonText: `${t("Ok")}`,
                });
            }
        } catch (error) {
            console.error('Error submitting data:', error);

            Swal.fire({
                title: `${t("AnErrorOccurred")}`,
                text: `${t("Unable_to_create_document")}!!`,
                icon: 'error',
                confirmButtonText: `${t("Ok")}`,
            });
        } finally {
            setLoading(false);
        }

    };

    // const handleDownloadPdf = async () => {
    //     try {
    //         const response = await DocumentService.getSmartDocumentPDF(row.smartDocumentId);

    //         if (!response.data.Content || !response.data.filename) {
    //             throw new Error("Missing HTML content or filename");
    //         }

    //         const contentHtml = response.data.Content;
    //         const filename = `${response.data.filename}.pdf`;

    //     const pdfContent = document.createElement('div');
    //         pdfContent.innerHTML = contentHtml;

    //         const styleElement = document.createElement('style');
    //         styleElement.innerHTML = `
    //         @import url('https://fonts.googleapis.com/css2?family=THSarabunNew&family=Noto+Sans+Thai&display=swap');
    //         @import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:wght@400;700&display=swap');
    //         /* Quill editor styles */
    //         @import url('https://cdn.quilljs.com/1.3.6/quill.snow.css');
    //         /* Custom styles if any */
    //          div{
    //             font-size: 16px;
    //          }   
    //          .ql-align-right {
    //             text-align: right;
    //         }
    //          .ql-align-left {
    //             text-align: left;
    //         }
    //          .ql-align-center {
    //             text-align: center;
    //         }

    //         .image-class {
    //             width: 120px;
    //             max-width: 100%;
    //             height: auto;
    //         }

    //         .ql-font-THSarabunNew {
    //             font-family: 'THSarabunNew', sans-serif;
    //         }

    //         .ql-font-serif {
    //             font-family: serif;
    //         }

    //         .ql-font-NotoSansThai {
    //             font-family: 'Noto Sans Thai', sans-serif;
    //         }

    //     `;

    //         document.head.appendChild(styleElement);

    //         document.body.appendChild(pdfContent);

    //         // รอให้ฟอนต์และรูปภาพโหลดเสร็จ
    //         await new Promise(resolve => setTimeout(resolve, 1000)); // หน่วงเวลา 1 วินาที

    //         const canvas = await html2canvas(pdfContent, {
    //             useCORS: true,        // การใช้ CORS เพื่อโหลดแหล่งที่มาผ่านภาพ
    //             logging: true,        // เปิดการบันทึกการทำงาน
    //             backgroundColor: '#ffffff', // สีพื้นหลังของ canvas
    //             imageTimeout: 0,      // ตั้งเวลารอภาพ
    //             allowTaint: true,     // อนุญาตให้โหลดภาพจากแหล่งที่มาต่างๆ
    //             scrollX: 0,           // ตั้งค่าการเลื่อนแนวนอน
    //             scrollY: 0,           // ตั้งค่าการเลื่อนแนวตั้ง
    //         });

    //         const imgData = canvas.toDataURL('image/png');

    //         const doc = new jsPDF('p', 'mm', 'a4');
    //         const pageWidth = doc.internal.pageSize.getWidth();
    //         const pageHeight = doc.internal.pageSize.getHeight();

    //         const paddingLeft = 15; // in mm
    //         const paddingRight = 15; // in mm
    //         const contentWidth = pageWidth - paddingLeft - paddingRight;

    //         const paddingTop = 12; // in mm
    //         const paddingBottom = 12; // in mm
    //         const contentHeight = pageHeight - paddingTop - paddingBottom;

    //         const imgHeight = (canvas.height * contentWidth) / canvas.width;

    //         // Ensure the image height does not exceed the page height
    //         const finalImgHeight = Math.min(imgHeight, contentHeight);

    //         doc.addImage(imgData, 'PNG', paddingLeft, paddingTop, contentWidth, finalImgHeight);
    //         doc.save(filename);

    //         Swal.fire({
    //             title: 'Success!',
    //             text: 'Document generated successfully!',
    //             icon: 'success',
    //             confirmButtonText: 'Ok',
    //         });

    //         document.body.removeChild(pdfContent);
    //         document.head.removeChild(styleElement);

    //     } catch (error) {
    //         console.error('Error generating PDF:', error);
    //         Swal.fire({
    //             title: 'Error!',
    //             text: 'Unable to generate document!',
    //             icon: 'error',
    //             confirmButtonText: 'Ok',
    //         });
    //     }
    // };

    const handleDownloadPdfPreview = async () => {
        try {
            const contentHtml = content
            const filename = `preview.pdf`;

            const pdfContent = document.createElement('div');
            pdfContent.id = 'pdfContent';
            pdfContent.className = 'pdfContent';
            pdfContent.innerHTML = contentHtml;

            const styleElement = document.createElement('style');
            styleElement.innerHTML = `
            @import url('https://fonts.googleapis.com/css2?family=THSarabunNew&family=Noto+Sans+Thai&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:wght@400;700&display=swap');
            /* Quill editor styles */
            @import url('https://cdn.quilljs.com/1.3.6/quill.snow.css');
            /* Custom styles if any */
             .pdfContent {
                padding:45px 56px;
             }   
            #pdfContent {
                padding:45px 56px;
            }
             .ql-align-right {
                text-align: right;
            }
             .ql-align-left {
                text-align: left;
            }
             .ql-align-center {
                text-align: center;
            }

            .image-class {
                width: 120px;
                max-width: 100%;
                height: auto;
            }

            .ql-font-THSarabunNew {
                font-family: 'THSarabunNew', sans-serif;
            }

            .ql-font-serif {
                font-family: serif;
            }

            .ql-font-NotoSansThai {
                font-family: 'Noto Sans Thai', sans-serif;
            }

        `;

            document.head.appendChild(styleElement);

            document.body.appendChild(pdfContent);

            // รอให้ฟอนต์และรูปภาพโหลดเสร็จ
            await new Promise(resolve => setTimeout(resolve, 100)); // หน่วงเวลา 1 วินาที

            const canvas = await html2canvas(pdfContent, {
                useCORS: true,        // การใช้ CORS เพื่อโหลดแหล่งที่มาผ่านภาพ
                logging: true,        // เปิดการบันทึกการทำงาน
                backgroundColor: '#ffffff', // สีพื้นหลังของ canvas
                imageTimeout: 0,      // ตั้งเวลารอภาพ
                allowTaint: true,     // อนุญาตให้โหลดภาพจากแหล่งที่มาต่างๆ
                scrollX: 0,           // ตั้งค่าการเลื่อนแนวนอน
                scrollY: 0,           // ตั้งค่าการเลื่อนแนวตั้ง
            });

            const imgData = canvas.toDataURL('image/png');

            const doc = new jsPDF('p', 'mm', 'a4');
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();

            const paddingLeft = 0; // in mm
            const paddingRight = 0; // in mm
            const contentWidth = pageWidth - paddingLeft - paddingRight;

            const paddingTop = 0; // in mm
            const paddingBottom = 0; // in mm
            const contentHeight = pageHeight - paddingTop - paddingBottom;

            const imgHeight = (canvas.height * contentWidth) / canvas.width;

            // Ensure the image height does not exceed the page height
            const finalImgHeight = Math.min(imgHeight, contentHeight);

            doc.addImage(imgData, 'PNG', paddingLeft, paddingTop, contentWidth, finalImgHeight);
            doc.save(filename);

            Swal.fire({
                title: `${t("Succeed")}!!`,
                text: `PDF ${t('Succeed')}!!`,
                icon: 'success',
                confirmButtonText: `${t("Ok")}`,
            });

            document.body.removeChild(pdfContent);
            document.head.removeChild(styleElement);

        } catch (error) {
            console.error('Error generating PDF:', error);
            Swal.fire({
                title: `${t("AnErrorOccurred")}`,
                text: `${t("Unable_to_Generate_pdf_document")}!!`,
                icon: 'error',
                confirmButtonText: `${t("Ok")}`,
            });
        }
    };

    // useEffect(() => {
    //     setSignatureDataList([]);
    // }, [row]);

    return (
        <StyledRoot className={`page`}>
            {loading && <LoadingFullPage />}
            <Container
                maxWidth="lg"
                style={{ paddingTop: "24px", paddingBottom: "72px", minWidth: '900px', maxWidth: '900px', position: 'relative' }}
            >
                {
                    isFetchingAllSmartDocument ?
                        <Loading />
                        :
                        <QuillEditor value={content} setValue={setContent} handleOnSubmit={handleOnSubmit} status={content ? true : false} handleDownloadPdf={handleDownloadPdfPreview} setShowPopup={setShowPopup} row={row} />
                }
            </Container>
            <UsagePopup open={showPopup} onClose={handleClosePopup} isShowOption={isShowOption} setDontShowAgain={setDontShowAgain} />
        </StyledRoot>
    );
};
export default create;



