import { Divider, Grid, styled, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import EditRoundedIcon from "@mui/icons-material/EditRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";

import dayjs from "dayjs";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DialogEdit from "./DialogEdit";

import utils from "../../../../../../utils";
import DialogWorkingLocation from "./DialogWorkLocation";
import DialogManager from "./DialogManager";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import {
  getUserBusinessUnit,
  getUserCompany,
  getUserDepartment,
  getUserDivision,
  getUserFullName,
  getUserPosition,
  getUserSection,
} from "../../../../../../utils/userData";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "&.inner": {
    marginBottom: 0,
  },
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const WorkInfo = (props) => {
  const { changeGroupTap, changeTabs } = props;
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [openDialog, setOpenDialog] = useState(false);
  const [mode, setMode] = useState("");

  const [drawerWorkingLocationConfig, setDrawerWorkingLocationConfig] =
    useState({
      isOpen: false,
      isEdit: false,
      data: {},
    });

  const [drawerManagerConfig, setDrawerManagerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {},
  });

  const handleClick = (mode) => {
    setOpenDialog(true);
    setMode(mode);
  };

  const handleClickTransfer = (mode) => {
    changeGroupTap("work");
    changeTabs(6);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const calculatorESY = () => {
    let Serv = utils.getAgeNumber(
      dayjs(employeeProfile.hiringDate).format("DD/MM/YYYY")
    );

    let monthAge = (Serv.months + employeeProfile.OESY_M) % 12;
    let yearAge = Math.floor((Serv.months + employeeProfile.OESY_M) / 12);

    return `${Serv.years + employeeProfile.OESY_Y + yearAge} ${t(
      "Year"
    )} ${monthAge} ${t("Month")}`;
  };

  const renderMethodAttendance = (value) => {
    switch (value) {
      case "point": {
        return `${t("AllLocation(coordinates)")}`;
      }
      case "main": {
        return `${t("OneMainWorkingPoint")}`;
      }
      case "Custom": {
        return `${t("Custom")}`;
      }
      default: {
        return `${t("AllWorkingPoint")}`;
      }
    }
  };

  return (
    <div>
      <div>
        <StyledWrapTop>
          <StyledHeadLabel variant="body2" gutterBottom>
            {t("DateEntry")}/{t("ContractTerminationDate")}
          </StyledHeadLabel>
          {!userProfile.readOnly && (
            <ButtonBlue
              variant="text"
              size="small"
              startIcon={<EditRoundedIcon />}
              onClick={() => handleClick("hiring-contract-date")}
            >
              {t("EditData")}
            </ButtonBlue>
          )}
        </StyledWrapTop>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <StyledHeadLabel color="text.secondary">
              {t("DateEntry")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.hiringDate
                ? dayjs(employeeProfile.hiringDate).format(
                  i18n.resolvedLanguage === "th"
                    ? "DD MMMM BBBB"
                    : "DD MMMM YYYY"
                )
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledHeadLabel color="text.secondary">
              {t("ContractTerminationDate")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.contractTermainatoinDate
                ? dayjs(employeeProfile.contractTermainatoinDate).format(
                  i18n.resolvedLanguage === "th"
                    ? "DD MMMM BBBB"
                    : "DD MMMM YYYY"
                )
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledHeadLabel color="text.secondary">
              {t("retirementDate")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.retirementDate
                ? dayjs(employeeProfile.retirementDate).format(
                  i18n.resolvedLanguage === "th"
                    ? "DD MMMM BBBB"
                    : "DD MMMM YYYY"
                )
                : "-"}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom></StyledHeadLabel>
        {!userProfile.readOnly && (
          <ButtonBlue
            variant="text"
            startIcon={<EditRoundedIcon />}
            size="small"
            endIcon={<KeyboardArrowRightRoundedIcon />}
            onClick={handleClickTransfer}
          >
            {t("Transfer")}
          </ButtonBlue>
        )}
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("Company")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {getUserCompany(employeeProfile)}
            </Typography>
            {/* <Typography variant="h6">
              {employeeProfile.companyName
                ? employeeProfile.companyName
                    .replace("บริษัท ", "")
                    .replace(" จำกัด", "")
                : "-"}
            </Typography> */}
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("BusinessUnit")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {getUserBusinessUnit(employeeProfile)}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("Division")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {getUserDivision(employeeProfile)}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("Department")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {getUserDepartment(employeeProfile)}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("Section")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {getUserSection(employeeProfile)}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("Position")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {getUserPosition(employeeProfile)}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom></StyledHeadLabel>
        {!userProfile.readOnly && (
          <ButtonBlue
            variant="text"
            size="small"
            startIcon={<EditRoundedIcon />}
            onClick={() => handleClick("experience")}
          >
            {t("EditData")}
          </ButtonBlue>
        )}
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">{t("OESY")}</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.OESY_Y !== null ||
                employeeProfile.OESY_M !== null ? (
                <Fragment>
                  {employeeProfile.OESY_Y && `${employeeProfile.OESY_Y} ${t("Year")}`}{" "}
                  &nbsp;
                  {employeeProfile.OESY_M > 0 &&
                    `${employeeProfile.OESY_M} ${t("Month")}`}
                </Fragment>
              ) : (
                <Fragment>{`-`}</Fragment>
              )}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">{t("ServiceYear")}</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.hiringDate
                ? `${utils.getAgeMonthYear(t,
                  dayjs(employeeProfile.hiringDate).format("DD/MM/YYYY"),
                  i18n.resolvedLanguage
                )}`
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">{t("ESY")}</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.hiringDate ? calculatorESY() : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">{t("TIG")}</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.employeePersonnelLevelStart
                ? `${utils.getAgeMonthYear(t,
                  dayjs(employeeProfile.employeePersonnelLevelStart).format("DD/MM/YYYY"),
                  i18n.resolvedLanguage
                )}`
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">{t("TIP")}</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.employeePositionStart
                ? `${utils.getAgeMonthYear(t,
                  dayjs(employeeProfile.employeePositionStart).format("DD/MM/YYYY"),
                  i18n.resolvedLanguage
                )}`
                : "-"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom></StyledHeadLabel>
        {!userProfile.readOnly && (
          <ButtonBlue
            variant="text"
            size="small"
            startIcon={<EditRoundedIcon />}
            onClick={
              // () => handleClick("employeeType")
              () => {
                setDrawerWorkingLocationConfig((prev) => ({
                  ...prev,
                  isOpen: true,
                  isEdit: true,
                  data: {
                    employmentTime: employeeProfile.employmentTime,
                    filed_office: employeeProfile.filed_office,
                    workingLocation: employeeProfile.workingLocation,
                    methodAttendance: employeeProfile.methodAttendance,
                    mainWorkingLocationPoint: employeeProfile.mainWorkingLocationPoint,
                    isHomeAttendance: employeeProfile.isHomeAttendance,
                    isFinger: employeeProfile.isFinger,
                    isFingerAffectPayroll: employeeProfile.isFingerAffectPayroll,
                    isFingerCustomMonday: employeeProfile.isFingerCustomMonday,
                    customMethodAttendanceMonday: employeeProfile.customMethodAttendanceMonday,
                    mainWorkingLocationPointMonday: employeeProfile.mainWorkingLocationPointMonday,
                    isFingerCustomTuesday: employeeProfile.isFingerCustomTuesday,
                    customMethodAttendanceTuesday: employeeProfile.customMethodAttendanceTuesday,
                    mainWorkingLocationPointTuesday: employeeProfile.mainWorkingLocationPointTuesday,
                    isFingerCustomWednesday: employeeProfile.isFingerCustomWednesday,
                    customMethodAttendanceWednesday: employeeProfile.customMethodAttendanceWednesday,
                    mainWorkingLocationPointWednesday: employeeProfile.mainWorkingLocationPointWednesday,
                    isFingerCustomThursday: employeeProfile.isFingerCustomThursday,
                    customMethodAttendanceThursday: employeeProfile.customMethodAttendanceThursday,
                    mainWorkingLocationPointThursday: employeeProfile.mainWorkingLocationPointThursday,
                    isFingerCustomFriday: employeeProfile.isFingerCustomFriday,
                    customMethodAttendanceFriday: employeeProfile.customMethodAttendanceFriday,
                    mainWorkingLocationPointFriday: employeeProfile.mainWorkingLocationPointFriday,
                    isFingerCustomSaturday: employeeProfile.isFingerCustomSaturday,
                    customMethodAttendanceSaturday: employeeProfile.customMethodAttendanceSaturday,
                    mainWorkingLocationPointSaturday: employeeProfile.mainWorkingLocationPointSaturday,
                    isFingerCustomSunday: employeeProfile.isFingerCustomSunday,
                    customMethodAttendanceSunday: employeeProfile.customMethodAttendanceSunday,
                    mainWorkingLocationPointSunday: employeeProfile.mainWorkingLocationPointSunday,
                    customWorkingLocationPoint: employeeProfile.customWorkingLocationPoint,
                    startDate: employeeProfile.startDate,
                    endDate: employeeProfile.endDate
                  },
                }));
              }
            }
          >
            {t("EditData")}
          </ButtonBlue>
        )}
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("JobGroup")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.jobGroupName
                ? employeeProfile.jobGroupName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("JobLevel")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.jobLevelName
                ? employeeProfile.jobLevelName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("Level")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.personnelLevelName
                ? employeeProfile.personnelLevelName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("EmploymentType")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.employmentTypeName
                ? employeeProfile.employmentTypeName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("EmploymentTime")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.employmentTime
                ? employeeProfile.employmentTime
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("WorkingLocation")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.workingLocation
                ? `${employeeProfile.workingLocation}`
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("FieldOffice")}
            </StyledHeadLabel>
            <Typography variant="h6" style={{ textTransform: "capitalize" }}>
              {employeeProfile.filed_office
                ? employeeProfile.filed_office
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("MethodAttendance")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {renderMethodAttendance(employeeProfile.methodAttendance)}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("GpsLocationWork")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.groupGpsLocationsName
                ? employeeProfile.groupGpsLocationsName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("FingerScan")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.isFinger === 1 ? `${t("Yes")}` : `${t("No")}`}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("isHomeAttendance")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.isHomeAttendance === 1 ? `${t("Yes")}` : `${t("No")}`}
            </Typography>
          </div>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              การบันทึกเวลามีผลต่อเงินเดือน
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.isFingerAffectPayroll === 1 ? "ใช่" : "ไม่ใช่"}
            </Typography>
          </div>
        </Grid> */}
      </Grid>
      <StyledDivider />
      <div>
        <StyledWrapTop>
          <StyledHeadLabel variant="body2" gutterBottom>
            {t("ManagerInfo")}
          </StyledHeadLabel>
          {!userProfile.readOnly && (
            <ButtonBlue
              variant="text"
              size="small"
              startIcon={<EditRoundedIcon />}
              // onClick={() => handleClick("manager")}
              onClick={() => {
                setDrawerManagerConfig((prev) => ({
                  ...prev,
                  isOpen: true,
                  isEdit: true,
                  data: {
                    idManagerLV1: employeeProfile.managerLV1_idManagerLV1 || null,
                    idManagerLV2: employeeProfile.managerLV2_idManagerLV2 || null,
                    idManagerGroupLV1: employeeProfile.idManagerGroupLV1 || null,
                    idManagerGroupLV2: employeeProfile.idManagerGroupLV2 || null,
                  },
                }));
              }}
            >
              {t("EditData")}
            </ButtonBlue>
          )}
        </StyledWrapTop>

        <Fragment>
          <Fragment>
            {employeeProfile.managerLV1_idManagerLV1 && (
              <div style={{ marginBottom: 24 }}>
                <StyledWrapTop className="inner">
                  <StyledHeadLabel variant="body2" gutterBottom>
                    {`*${t("No_Number")} 1`}
                  </StyledHeadLabel>
                </StyledWrapTop>
                <div>
                  <StyledHeadLabel color="text.secondary">
                    {t("FullName")}
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {getUserFullName({
                      firstname_TH: employeeProfile.managerLV1_firstname_TH,
                      lastname_TH: employeeProfile.managerLV1_lastname_TH,
                      firstname_EN: employeeProfile.managerLV1_firstname_EN,
                      lastname_EN: employeeProfile.managerLV1_lastname_EN,
                    })}
                  </Typography>
                </div>
                <div>
                  <StyledHeadLabel color="text.secondary">
                    {t("Position")}
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {getUserPosition({
                      positionName: employeeProfile.managerLV1_positionName || "-",
                      positionName_EN: employeeProfile.managerLV1_positionName_EN || "-",
                    })}
                  </Typography>
                </div>
                <div>
                  <StyledHeadLabel color="text.secondary">
                    {t("Email")}
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {employeeProfile.managerLV1_email}
                  </Typography>
                </div>
              </div>
            )}
            {employeeProfile.idManagerGroupLV1 && (
              <div style={{ marginBottom: 24 }}>
                <StyledWrapTop className="inner">
                  <StyledHeadLabel variant="body2" gutterBottom>
                    {`*${t("No_Number")} 1`}
                  </StyledHeadLabel>
                </StyledWrapTop>
                <div>
                  <StyledHeadLabel color="text.secondary">
                    {t("ManagerGroup")}
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {employeeProfile.managerGroupLV1_managerGroupName}
                  </Typography>
                </div>
                {/* <div>
                  <StyledHeadLabel color="text.secondary">
                    จำนวนหัวหน้างาน
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {employeeProfile.managerGroupLV1_managerGroupName}
                  </Typography>
                </div>
                <div>
                  <StyledHeadLabel color="text.secondary">
                    E-mail
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {employeeProfile.managerLV1_email}
                  </Typography>
                </div> */}
              </div>
            )}
            {employeeProfile.managerLV2_idManagerLV2 && (
              <div style={{ marginBottom: 24 }}>
                <StyledWrapTop className="inner">
                  <StyledHeadLabel variant="body2" gutterBottom>
                    {`*${t("No_Number")} 2`}
                  </StyledHeadLabel>
                </StyledWrapTop>
                <div>
                  <StyledHeadLabel color="text.secondary">
                    {t("FullName")}
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {getUserFullName({
                      firstname_TH: employeeProfile.managerLV2_firstname_TH,
                      lastname_TH: employeeProfile.managerLV2_lastname_TH,
                      firstname_EN: employeeProfile.managerLV2_firstname_EN,
                      lastname_EN: employeeProfile.managerLV2_lastname_EN,
                    })}
                  </Typography>
                </div>
                <div>
                  <StyledHeadLabel color="text.secondary">
                    {t("Position")}
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {employeeProfile.managerLV2_positionName
                      ? employeeProfile.managerLV2_positionName
                      : "-"}
                  </Typography>
                </div>
                <div>
                  <StyledHeadLabel color="text.secondary">
                  {t("Email")}l
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {employeeProfile.managerLV2_email}
                  </Typography>
                </div>
              </div>
            )}
            {employeeProfile.idManagerGroupLV2 && (
              <div style={{ marginBottom: 24 }}>
                <StyledWrapTop className="inner">
                  <StyledHeadLabel variant="body2" gutterBottom>
                    {`*${t("No_Number")} 2`}
                  </StyledHeadLabel>
                </StyledWrapTop>
                <div>
                  <StyledHeadLabel color="text.secondary">
                    {t("ManagerGroup")}
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {employeeProfile.managerGroupLV2_managerGroupName}
                  </Typography>
                </div>
                {/* <div>
                  <StyledHeadLabel color="text.secondary">
                    จำนวนหัวหน้างาน
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {employeeProfile.managerGroupLV1_managerGroupName}
                  </Typography>
                </div>
                <div>
                  <StyledHeadLabel color="text.secondary">
                    E-mail
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {employeeProfile.managerLV1_email}
                  </Typography>
                </div> */}
              </div>
            )}
          </Fragment>
        </Fragment>
      </div>
      <StyledDivider />
      <DialogEdit
        mode={mode}
        open={openDialog}
        handleCloseDialog={handleCloseDialog}
      />

      <DialogWorkingLocation
        drawerConfig={drawerWorkingLocationConfig}
        onClose={() => {
          setDrawerWorkingLocationConfig((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }}
      />

      <DialogManager
        drawerConfig={drawerManagerConfig}
        onClose={() => {
          setDrawerManagerConfig((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }}
      />
    </div >
  );
};

export default WorkInfo;
