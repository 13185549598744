import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import XLSX from "xlsx";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  TextField,
  Paper,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Chip,
  Box,
  Popper,
} from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";

import DatePickerCustom from "../../shared/date/datePicker";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import SearchIcon from "@mui/icons-material/Search";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import QrCodeIcon from "@mui/icons-material/QrCode2";
import GpsFixedRoundedIcon from "@mui/icons-material/GpsFixedRounded";
import MoreTimeRoundedIcon from "@mui/icons-material/MoreTimeRounded";

import { getDepartment } from "../../../../actions/company";
import { getAttendanceOnce } from "../../../../actions/attendance";
import { getLeaveRequestOnce } from "../../../../actions/employee";
import { allLeaveType } from "../../../../actions/leave";

import ButtonBlue from "../../shared/general/ButtonBlue";
import Backdrop from "../../shared/general/Backdrop";

import utils from "../../../../utils";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName, getUserPosition, getUserFirstName, getUserLastName, getUserTitle } from "../../../../utils/userData";

const StyledRoot = styled("div")({
  paddingBottom: 48,
  "& .wrap-check, .wrap-leave": {
    marginTop: 36,
  },
  "& .wrap-check-in, .wrap-check-out, .leave": {
    borderRadius: 16,
    "& .head": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 24,
    },
  },
  "& .leave": {
    "& .filter": {
      padding: 24,
      display: "flex",
      justifyContent: "space-between",
    },
  },
});

const StyledLabelHead = styled(Typography)({
  fontSize: 24,
  fontWeight: 600,
  "& i": {
    marginRight: 8,
  },
});

const StyledChipTime = styled(Chip)({
  height: 28,
  minWidth: 80,
  fontSize: 14,
  borderRadius: 8,
  border: "none",
  "&.check-in": {
    color: "#229a16",
    backgroundColor: "#54d62c29",
    "& .MuiSvgIcon-root": {
      color: "#229a16",
      fontSize: 20,
    },
  },
  "&.check-out": {
    color: "#b72136",
    backgroundColor: "#ff484229",
    "& .MuiSvgIcon-root": {
      color: "#b72136",
      fontSize: 20,
    },
  },
});

const StyledChipLeave = styled(Chip)({
  fontSize: 16,
  borderRadius: 8,
  color: "#637381",
  backgroundColor: "#f2f3f5",
  border: "none",
});

const StyledDivider = styled(Divider)({
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
});

const StyledListCheckEmployees = styled(List)({
  height: 450,
  overflow: "auto",
  "& .secondaryAction": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    "& .location": {
      fontWeight: 600,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: 180,
      textAlign: "right",
    },
  },
  "& .MuiListItemText-primary": {
    fontWeight: 500,
  },
  "& .MuiListItemText-secondary": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 330,
  },
});

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: 36,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const ListCheckEmployees = (props) => {
  // const { classCheck, searchText } = props;
  // const { result: attendance } = useSelector((state) => state.attendance);

  const { data, classCheck, selectedDepartment } = props;
  const [dataFilter, setDataFilter] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (data.length > 0 && selectedDepartment) {
      let temp = data.filter((row) => {
        if (selectedDepartment) {
          return row.idDepartment === selectedDepartment;
        } else {
          return row;
        }
      });
      setDataFilter([...temp]);
    } else {
      setDataFilter([...data]);
    }
  }, [selectedDepartment]);

  // const rowsFilter = () => {
  //   let data = [...attendance];
  //   if (classCheck === "check-in") {
  //     data = data.filter((x) => x.isCheckIn);
  //   } else {
  //     data = data.filter((x) => !x.isCheckIn);
  //   }

  //   console.log("data: ", data);
  //   console.log("searchText: ", searchText);

  //   if (searchText.length > 0) {
  //     return data.filter((item, index) => {
  //       if (
  //         item.firstname_TH.toUpperCase().indexOf(searchText.toUpperCase()) >=
  //           0 ||
  //         item.lastname_TH.toUpperCase().indexOf(searchText.toUpperCase()) >= 0
  //       )
  //         return item;
  //     });
  //   } else {
  //     return data;
  //   }
  // };

  return (
    <StyledListCheckEmployees>
      {dataFilter && dataFilter.length > 0 ? (
        dataFilter.map((row, index) => (
          <Fragment key={index}>
            <ListItem
              secondaryAction={
                <div className="secondaryAction">
                  <StyledChipTime
                    icon={
                      row.idAttendanceType === 2 ? (
                        <FingerprintIcon />
                      ) : row.idAttendanceType === 5 ? (
                        <QrCodeIcon />
                      ) : row.idAttendanceType === 4 ? (
                        <GpsFixedRoundedIcon />
                      ) : row.idAttendanceType === 8 ? (
                        <MoreTimeRoundedIcon />
                      ) : (
                        <LocationOnIcon />
                      )
                    }
                    className={classCheck}
                    label={row.time}
                    variant="outlined"
                  />
                  <Typography
                    className="location"
                    color="text.secondary"
                    variant="caption"
                  >
                    {row.gpsAddress && row.idAttendanceType === 4 ? row.gpsAddress : row.gpsLocationsName} {row.diffDistance > 0 ? `, ${row.diffDistance} ${t("Meter")}` : ""}
                  </Typography>
                </div>
              }
            >
              <ListItemAvatar>
                <Avatar src={row.imageProfile} />
              </ListItemAvatar>
              <ListItemText
                primary={getUserFullName(row)}
                secondary={getUserPosition(row)}
              />
            </ListItem>
            <StyledDivider variant="inset" component="li" />
          </Fragment>
        ))
      ) : (
        <ListItem style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h6">{t("NoData")}</Typography>
        </ListItem>
      )}
    </StyledListCheckEmployees>
  );
};

const ListLeaveEmployees = (props) => {
  const { result: leaveEmployeeList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { t, i18n } = useTranslation();

  return (
    <StyledListCheckEmployees>
      {leaveEmployeeList && leaveEmployeeList.length > 0 ? (
        leaveEmployeeList.filter(x => x.isActive === 1 && !x.isWithdraw).map((row, index) => (
          <Fragment key={index}>
            <ListItem
              secondaryAction={
                <StyledChipLeave label={row.name} variant="outlined" />
              }
            >
              <ListItemAvatar>
                <Avatar
                // src={`${process.env.REACT_APP_API_URL}image/vendor/${row.idVendor}/${row.personalId}.jpg`}
                />
              </ListItemAvatar>
              <ListItemText
                primary={getUserFullName(row)}
                secondary={getUserPosition(row)}
              />
            </ListItem>
            <StyledDivider variant="inset" component="li" />
          </Fragment>
        ))
      ) : (
        <ListItem style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h6">{t("LeaveNotFound")}</Typography>
        </ListItem>
      )}
    </StyledListCheckEmployees>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 360,
    },
  },
};

const DailyPanel = (props) => {
  const { selectedCompany } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: departmentList } = useSelector((state) => state.department);
  const { result: leaveTypeList } = useSelector((state) => state.leaveType);
  const { result: attendance } = useSelector((state) => state.attendance);
  const { result: leaveEmployeeList } = useSelector(
    (state) => state.leaveEmployees
  );
  const [search, setSearch] = useState(new Date());
  const [searchText, setSearchText] = useState("");
  const [selectedDepartment, setSelectedDepartment] = React.useState(null);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(
        getAttendanceOnce({
          date: dayjs(search).format("YYYY-MM-DD"),
          idCompany: selectedCompany.idCompany,
        })
      );
      dispatch(getDepartment({ idCompany: selectedCompany.idCompany }));
      dispatch(
        getLeaveRequestOnce({
          date: dayjs(search).format("YYYY-MM-DD"),
          filter: "admin",
          idCompany: selectedCompany.idCompany,
        })
      );
      dispatch(allLeaveType({ idCompany: selectedCompany.idCompany }));
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (userProfile && departmentList) {
      if (userProfile.isFillIn === 1) {
        setSelectedDepartment(userProfile.idDepartment);
      }
    }
  }, [userProfile]);

  const handleSearch = () => {
    dispatch(
      getAttendanceOnce({
        date: dayjs(search).format("YYYY-MM-DD"),
        idCompany: selectedCompany.idCompany,
      })
    );
    dispatch(
      getLeaveRequestOnce({
        date: dayjs(search).format("YYYY-MM-DD"),
        filter: "admin",
        idCompany: selectedCompany.idCompany,
      })
    );
    dispatch(allLeaveType({ idCompany: selectedCompany.idCompany }));
  };

  const onExportExcel = () => {
    const elementList = [];
    for (let index = 0; index < attendance.length; index++) {
      const element = {
        id: index + 1,
        title: getUserTitle(attendance[index]),
        name: getUserFirstName(attendance[index]),
        lastname: getUserLastName(attendance[index]),
        positionsName: getUserPosition(attendance[index]),
        attendanceDateTime: attendance[index].attendanceDateTimeText,
        gpsLocationsName: attendance[index].gpsLocationsName,
        statusCheck: attendance[index].isCheckIn
          ? t("ClockIn")
          : t("ClockOut"),
      };
      elementList.push(element);
    }
    const workSheet = XLSX.utils.json_to_sheet(elementList);

    workSheet["A1"].v = "#";
    workSheet["B1"].v = t("Title");
    workSheet["C1"].v = t("FirstName");
    workSheet["D1"].v = t("LastName");
    workSheet["E1"].v = t("Position");
    workSheet["F1"].v = t("Date");
    workSheet["G1"].v = t("ClockInLocation");
    workSheet["H1"].v = t("ClockInType");

    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, t("WorkingTimeReport"));
    XLSX.writeFile(workBook, `${t("WorkingTimeReport")}.xlsx`);
  };

  const renderLeaveType = () => {
    console.log("renderLeaveType");
    const leaveTypeUniqueList = utils.getUniqueListBy(
      leaveTypeList,
      "idLeaveType"
    );

    leaveTypeUniqueList.forEach((element) => {
      element.count = leaveEmployeeList.filter(
        (x) => x.idLeaveType === element.idLeaveType
      ).length;
    });

    return leaveTypeUniqueList;
  };

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <StyledRoot>
      <StyledWrapFilter>
        <Grid container spacing={2} alignItems="center">
          {/* <Grid item xs={12} sm={4}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                ชื่อ-สกุล
              </Typography>
              <div className="search-name">
                <FormControl fullWidth variant="filled">
                  <StyledFilledInput
                    id="filled-sarch-name"
                    placeholder="ค้นหาชื่อ-สกุล"
                    name="search"
                    onChange={handleChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </StyledBoxSearch>
          </Grid> */}
          <Grid item xs={12} sm={4}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("SearchDate")}
              </Typography>
              <div className="search-date">
                <DatePickerCustom
                  minDate={dayjs().subtract(4, "year")}
                  maxDate={dayjs()}
                  inputFormat="DD/MM/YYYY"
                  value={search}
                  name="start"
                  views={["year", "month", "day"]}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                  onlyDialog={true}
                />
              </div>
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("Department")}
              </Typography>
              <div className="search-name">
                {departmentList && userProfile && (
                  <StyledAutocomplete
                    options={departmentList.filter((item) => {
                      if (userProfile.isFillIn === 1) {
                        return item.idDepartment === userProfile.idDepartment;
                      } else {
                        return item.idDepartment;
                      }
                    })}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSelectedDepartment(newValue.idDepartment);
                      } else {
                        setSelectedDepartment(null);
                      }
                    }}
                    popupIcon={<i className="fa-light fa-chevron-down"></i>}
                    getOptionLabel={(option) => option.departmentName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        placeholder={`${t("AllDepartment")}`}
                      />
                    )}
                    value={
                      selectedDepartment
                        ? departmentList.find(
                          (item) => item.idDepartment === selectedDepartment
                        ) || null
                        : null
                    }
                    PopperComponent={StyledPopper}
                    noOptionsText={`${t("NoData")}`}
                  />
                )}
              </div>
            </StyledBoxSearch>
          </Grid>

          <Grid item xs={12} sm={4}>
            <StyledBoxSearch>
              <div className="wrap-search-action">
                <div>
                  <ButtonBlue
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={handleSearch}
                  >
                    {t("Search")}
                  </ButtonBlue>
                </div>
                <div className="btn-export">
                  <ButtonBlue
                    variant="outlined"
                    startIcon={<FileDownloadIcon />}
                    onClick={onExportExcel}
                    disabled={!(attendance && attendance.length > 0)}
                  >
                    {t("Download")}
                  </ButtonBlue>
                </div>
              </div>
            </StyledBoxSearch>
          </Grid>
        </Grid>
      </StyledWrapFilter>
      {attendance ? (
        <Fragment>
          <div className="wrap-check">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Paper variant="outlined" className="wrap-check-in">
                  <div className="head">
                    <StyledLabelHead variant="h4">
                      <i className="fa-light fa-right-to-bracket"></i>
                      {t("ClockIn")}
                    </StyledLabelHead>
                    <div>
                      <StyledLabelHead variant="h4">
                        {`${attendance
                          .filter((item) => item.isCheckIn === 1 && (userProfile && userProfile.isFillIn === 1 ? item.idDepartment === selectedDepartment : true))
                          .reduce((uniqueEmployees, item) => {
                            if (!uniqueEmployees.has(item.idEmployees)) {
                              uniqueEmployees.add(item.idEmployees);
                            }
                            return uniqueEmployees;
                          }, new Set()).size} ${t("Person")}`}
                      </StyledLabelHead>
                    </div>
                  </div>

                  {/* <ListCheckEmployees
                    classCheck="check-in"
                    searchText={searchText}
                  /> */}
                  <ListCheckEmployees
                    classCheck="check-in"
                    data={attendance.filter((item) => item.isCheckIn === 1 && (userProfile && userProfile.isFillIn === 1 ? item.idDepartment === selectedDepartment : true))}
                    selectedDepartment={selectedDepartment}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper variant="outlined" className="wrap-check-out">
                  <div className="head">
                    <StyledLabelHead variant="h4">
                      <i className="fa-light fa-right-from-bracket"></i>
                      {t("ClockOut")}
                    </StyledLabelHead>
                    <div>
                      {/* <StyledLabelHead variant="h4">{`${attendance.filter((item) => item.isCheckIn === 0).length
                        } ${t("Person")}`}</StyledLabelHead> */}
                      <StyledLabelHead variant="h4">
                        {`${attendance
                          .filter((item) => item.isCheckIn === 0 && (userProfile && userProfile.isFillIn === 1 ? item.idDepartment === selectedDepartment : true))
                          .reduce((uniqueEmployees, item) => {
                            if (!uniqueEmployees.has(item.idEmployees)) {
                              uniqueEmployees.add(item.idEmployees);
                            }
                            return uniqueEmployees;
                          }, new Set()).size} ${t("Person")}`}
                      </StyledLabelHead>
                    </div>
                  </div>

                  <ListCheckEmployees
                    classCheck="check-out"
                    data={attendance.filter((item) => item.isCheckIn === 0 && (userProfile && userProfile.isFillIn === 1 ? item.idDepartment === selectedDepartment : true))}
                    selectedDepartment={selectedDepartment}
                  />
                </Paper>
              </Grid>
            </Grid>
          </div>
          <div className="wrap-leave">
            <Paper variant="outlined" className="leave">
              <div className="filter">
                <StyledLabelHead variant="h4">
                  <i className="fa-light fa-house-person-leave"></i>
                  {t("Leave")}
                </StyledLabelHead>
                {/* <StyledFormControlSelect>
                  <Select
                    multiple
                    displayEmpty
                    value={selectedLeave}
                    onChange={handleChangeSelectedLeave}
                    input={<FilledInput label="Tag" />}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <Typography color="text.secondary">
                            {`ทุกประเภท (${
                              leaveEmployeeList && leaveEmployeeList.length
                            } คน)`}
                          </Typography>
                        );
                      }
                      return selected.join(", ");
                    }}
                    MenuProps={MenuProps}
                    IconComponent={IconComponent}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {leaveTypeList &&
                      leaveEmployeeList &&
                      renderLeaveType().map((item, index) => (
                        <MenuItem key={index}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selectedLeave}
                          />
                          <ListItemText
                            primary={`${item.count} คน : ${item.name}`}
                          />
                        </MenuItem>
                      ))}
                  </Select>
                </StyledFormControlSelect> */}
              </div>
              <ListLeaveEmployees />
            </Paper>
          </div>
        </Fragment>
      ) : (
        <Backdrop open={true} />
      )}
    </StyledRoot>
  );
};

export default DailyPanel;
