import React, { useEffect, useState } from "react";
import { styled } from "@mui/styles";
import {
  Grid,
  TextField,
  InputAdornment,
  Container,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Box,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  Avatar,
  Chip,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import Crop32Icon from "@mui/icons-material/Crop32";
import { useHistory, useLocation } from "react-router-dom";
import DialogDropzone from "../DialogDropzoneKpi";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AlertResponse from "../../../shared/general/AlertResponse";
import DatePickerCustom from "../../../shared/date/datePicker";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { addKpiOrganization, updateKpiOrganization } from "../../../../../actions/kpi";
import { Redirect } from "react-router-dom";
import { getAllEmployees } from "../../../../../actions/employee";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

const ContainerStyled = styled(Container)({
  minHeight: "100vh",
  height: "100%",
  paddingTop: "100px",
});

const initialValues = {
  kpiName: "",
  scope: "",
  target: "",
  measureType: "",
  evidence: "",
  description: "",
  weight: "",
  startDate: dayjs().startOf("year"),
  endDate: dayjs().endOf("year"),
  year: dayjs().get("year"),
  isCascade: null,
  employeeSelected: [],
};

const validationSchema = yup.object({
  kpiName: yup.string().max(500).required(),
  scope: yup.string().max(500).required(),
  target: yup.string().max(500).required(),
  evidence: yup.string().max(500).required(),
  description: yup.string().max(500).required(),
  measureType: yup.string().required(),
  weight: yup.number().required(),
  startDate: yup.date().required(),
  endDate: yup.date().when("startDate", (startDate) => {
    return yup.date().min(dayjs(startDate).add(1, "day"));
  }),
  year: yup.number().required(),
  isCascade: yup.boolean().nullable(),
  employeeSelected: yup.array().when("isCascade", {
    is: (value) => value,
    then: (schema) => schema.min(1)
  }),
});

function KpiOrganizationPlan() {
  const { t, i18n } = useTranslation();
  const { state } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeesList } = useSelector((state) => state.employees);

  if (!state) {
    return <Redirect to="/kpi/organization" />
  }

  const { control, handleSubmit, watch, formState: { isSubmitting, errors } } = useForm({
    defaultValues: state.planList || initialValues,
    resolver: yupResolver(validationSchema),
  });

  const handleOnSubmit = async (formData) => {
    let res = null;
    formData.isCascade = formData.isCascade ? 1 : 0 
    if (formData.idKpiOrganization) {
      res = await dispatch(updateKpiOrganization(formData));
    } else {
      res = await dispatch(addKpiOrganization(formData));
    }
    
    if (res && (res.status === 201 || res.status === 200)) { 
      Swal.fire({
        title: 'สำเร็จ',
        text: formData.idKpiOrganization ? 'การแก้ไขข้อมูลสำเร็จแล้ว' : 'การเพิ่มข้อมูลสำเร็จแล้ว',
        icon: 'success',
        timer: 2000,
        showConfirmButton: false
      });
    } else {
      Swal.fire({
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถบันทึกข้อมูลได้',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      });
    }

    history.push("/kpi/organization");
};

const showAlert = async (formData) => {
  const result = await Swal.fire({
    title: t("ConfirmSaveData"),
    text: t("DoYouWantToSaveThisData"),
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: t("Confirm"),
    cancelButtonText: t("Cancel"),
  });

  if (result.isConfirmed) {
    handleOnSubmit(formData);
  }
};

  const [openDialog, setDialog] = useState(false);

  const handleCloseDialogDropzoneKpi = () => {
    setDialog(false);
  };

  const [openAlert, setOpenAlert] = useState({
    open: false,
    alertType: null,
    label: null,
  });

  const handleOpenAlert = (alertType, label) => {
    setOpenAlert({
      open: true,
      alertType: alertType,
      label: label,
    });
  };

  const handleCloseAlert = () => {
    setOpenAlert({
      open: false,
      alertType: null,
      label: null,
    });
  };

  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);

  return (
    <ContainerStyled>
      {userProfile && (
        <Box paddingBottom="16px" marginBottom="16px" borderRadius="15px" bgcolor="#ffffff">
          <div className="header-section">
            <h3 className="header-topic">{t("KPIOrganizationPlanning")}</h3>
          </div>
          <Box paddingLeft={"16px"} paddingRight={"16px"}>
            <form onSubmit={handleSubmit(showAlert)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="kpiName"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("Name") + "KPI"}
                        placeholder={t("Name") + "KPI"}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error && fieldState.error.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <StarIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="scope"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("Scope")}
                        placeholder={t("Scope")}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error && fieldState.error.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Crop32Icon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="target"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("Target")}
                        placeholder={t("Target")}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error && fieldState.error.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Crop32Icon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="evidence"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("Evidence")}
                        placeholder={t("Evidence")}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error && fieldState.error.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Crop32Icon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("Descriptions")}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error && fieldState.error.message}
                        multiline
                        rows={3}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Controller
                    name="measureType"
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth error={Boolean(fieldState.error)}>
                        <InputLabel>{t("TypeMeasurementData")}</InputLabel>
                        <Select {...field} label={t("TypeMeasurementData")}>
                          <MenuItem value={"number"}>{t("Number")}</MenuItem>
                          <MenuItem value={"explanation"}>{t("Explanation")}</MenuItem>
                        </Select>
                        {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Controller
                    name="weight"
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth error={Boolean(fieldState.error)}>
                        <InputLabel>{t("Weight")}</InputLabel>
                        <Select
                          {...field}
                          label={t("Weight")}
                        >
                          {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50]
                            .filter((value) => value <= 100 - state.totalWeight)
                            .map((value) => (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                        </Select>
                        {fieldState.error && (
                          <FormHelperText>{fieldState.error.message}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                      <DatePickerCustom
                        {...field}
                        variant="outlined"
                        label={t("StartDate")}
                        minDate={dayjs().subtract(1, "year").startOf("year")}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => (
                      <DatePickerCustom
                        {...field}
                        variant="outlined"
                        label={t("EndDate")}
                        minDate={dayjs(watch("startDate"))}
                        maxDate={dayjs().add(1, "year").endOf("year")}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Controller
                    name="year"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextFieldTheme
                        {...field}
                        select
                        label={t("Year")}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error && fieldState.error.message}
                      >
                        <MenuItem value={dayjs().get("year")}>
                          {dayjs().format(i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY")}
                        </MenuItem>
                        <MenuItem value={dayjs().get("year") - 1}>
                          {dayjs().subtract(1, "year").format(i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY")}
                        </MenuItem>
                      </TextFieldTheme>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="isCascade"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} />}
                        label={t("Cascade")}
                      />
                    )}
                  />
                  {watch("isCascade") && (
                    <Controller
                      name={"employeeSelected"}
                      control={control}
                      defaultValue={[]}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          {...field}
                          multiple
                          options={employeesList || []}
                          getOptionLabel={(option) =>
                            `${option.firstname_TH} ${ option.lastname_TH}`
                          }
                          onChange={(e, newValue) =>
                            field.onChange(newValue)
                          }
                          renderOption={(props, option) => (
                            <Box
                              {...props}
                              display="flex"
                              alignItems="center"
                            >
                              <Avatar
                                src={option.imageProfile}
                                alt={`${option.firstname_TH} ${option.lastname_TH}`}
                                sx={{
                                  width: 24,
                                  height: 24,
                                  marginRight: 1,
                                }}
                              />
                              {`${option.firstname_TH} ${option.lastname_TH}`}
                            </Box>
                          )}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                {...getTagProps({ index })}
                                avatar={ <Avatar src={option.imageProfile} />}
                                label={`${option.firstname_TH} ${option.lastname_TH}`}
                                key={option.idEmployees}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              placeholder={`${t("SelectEmp")}`}
                              error={Boolean(fieldState.error)}
                              helperText={fieldState.error && fieldState.error.message}
                            />
                          )}
                          filterSelectedOptions
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="flex-end" gap="8px">
                  {state.planList && (
                    <ButtonBlue type="button" color="error" onClick={() => history.push("/kpi-individual")}>
                      {t("Cancel")}
                    </ButtonBlue>
                  )}
                  <ButtonBlue type="submit" variant="contained" disabled={isSubmitting}>
                    {t("Save")}
                  </ButtonBlue>
                </Grid>
              </Grid>
            </form>
            {openDialog && (
              <DialogDropzone
                open={openDialog}
                onClose={handleCloseDialogDropzoneKpi}
                handleOpenAlert={handleOpenAlert}
              />
            )}
            <AlertResponse
              open={openAlert.open}
              handleClose={handleCloseAlert}
              alertType={openAlert.alertType}
              label={openAlert.label}
            />
          </Box>
        </Box>
      )}
    </ContainerStyled>
  );
}

export default KpiOrganizationPlan;
