import React from "react";
import { Container, Typography, Grid, List, ListItem, ListItemAvatar, Avatar, ListItemText, Button } from "@mui/material";
import { styled } from "@mui/system";
import { NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import CentrallIcon from "../landing/image/MainIcon/CentralIcon.png";
import PayrollIcon from "../landing/image/MainIcon/PayrollIcon.png";
import SelfIcon from "../landing/image/MainIcon/SelfIcon.png";
import TimeIcon from "../landing/image/MainIcon/TimeIcon.png";
import CareerIcon from "../landing/image/AddIcon/CareerIcon.png";
import CultureIcon from "../landing/image/AddIcon/CultureIcon.png";
import DocumentIcon from "../landing/image/AddIcon/DocumentIcon.png";
import ExpenseIcon from "../landing/image/AddIcon/ExpenseIcon.png";
import KpiIcon from "../landing/image/AddIcon/KpiIcon.png";
import LearningIcon from "../landing/image/AddIcon/LearningIcon.png";
import OnOffIcon from "../landing/image/AddIcon/OnOffIcon.png";
import RecruitmentIcon from "../landing/image/AddIcon/RecruitmentIcon.png";
import TalentIcon from "../landing/image/AddIcon/TalentIcon.png";
import WelfareIcon from "../landing/image/AddIcon/WelfareIcon.png";




const StyledAvatar = styled(Avatar)`
  width: 71px;
  height: 71px;
  margin-right: 5px;
  background-color: #ebeaee;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 800px) {
    width: 35px;
    height: 35px;
    margin-right: 15px;
    margin-left: -40px;
    padding: 5px;

    & img {
      width: 50%;
      height: 50%;
      object-fit: cover;
    }
  }
`;

const StyledPrimaryText = styled("div")`
  font-size: 30px;
  font-weight: 400;
  text-align: left;

  @media (max-width: 800px) {
    font-size: 20px;
    margin-left: -58px;
    margin-right: 5px;
    margin-top: 3px;
    line-height: 1;
    text-align: left;
  }
`;

const StyledSecondaryText = styled("div")`
  font-size: 18px;
  font-weight: 200;
  text-align: left;

  @media (max-width: 800px) {
    font-size: 12px;
    margin-left: -58px;
    padding-right: 20px;
    margin-top: 2px;
    text-align: left;
  }
`;


const FeatureList = ({ features }) => (
  <Grid container spacing={2}>
    {features.map((feature, index) => (
      <Grid item xs={12} sm={6} key={index}>
        <List sx={{ width: "100%", maxWidth: 500, bgcolor: "background.paper", marginLeft: 5 }}>
          <ListItem>
            <ListItemAvatar sx={{ marginRight: "20px" }}>
              <StyledAvatar>
                <img src={feature.icon} alt={feature.title} style={{ width: 34, height: 34 }} />
              </StyledAvatar>
            </ListItemAvatar>
            <ListItemText
              primary={<StyledPrimaryText>{feature.title}</StyledPrimaryText>}
              secondary={<StyledSecondaryText>{feature.description}</StyledSecondaryText>}
            />
          </ListItem>
        </List>
      </Grid>
    ))}
  </Grid>
);

export default function Feature() {
  const { t, i18n } = useTranslation();


  const features = [
  {
    icon: CentrallIcon,
    title: "HR Central Data",
    description: t('hrCentralDataDescription'),
  },
  {
    icon: TimeIcon,
    title: "Time Management",
    description: t('timeManagementDescription'),
  },
  {
    icon: PayrollIcon,
    title: "Payroll Management",
    description: t('payrollManagementDescription'),
  },
  {
    icon: SelfIcon,
    title: "Employee Self Service",
    description: t('employeeSelfServiceDescription'),
  },
];

const additionalFeatures = [
  {
    icon: LearningIcon,
    title: "Learning Platform",
    description: t('learningPlatformDescription'),
  },
  {
    icon: CultureIcon,
    title: "Culture & Engagement",
    description: t('cultureAndEngagementDescription'),
  },
  {
    icon: RecruitmentIcon,
    title: "Recruitment",
    description: t('recruitmentDescription'),
  },
  {
    icon: ExpenseIcon,
    title: "Expense",
    description: t('expenseFeatureDescription'),
  },
  {
    icon: KpiIcon,
    title: "KPI/OKRs",
    description: t('kpiOkrsDescription'),
  },
  {
    icon: WelfareIcon,
    title: "Welfare & Benefit",
    description: t('welfareBenefitDescription')
  },
  {
    icon: OnOffIcon,
    title: "On boarding/Off boarding",
    description: t('onOffBoardingDescription'),
  },
  {
    icon: DocumentIcon,
    title: "Document Management",
    description: t('documentManagementDescription'),
  },
  {
    icon: TalentIcon,
    title: "Talent & Successor",
    description: t('talentSuccessorDescription'),
  },
  {
    icon: CareerIcon,
    title: "Career Management",
    description: t('communicationSocialDescription'),
  },
];

  return (
    <Container maxWidth="lg">
      <Typography
        variant="body2"
        align="left"
        sx={{
          color: "rgba(83, 24, 129, 0.7)!important",
          fontSize: 35,
          fontWeight: 500,
          marginTop: { md: "100px", sm: "350px" },
          marginBottom: "20px",
        }}
      >
        {t('mainFeature')}
      </Typography>

      <FeatureList features={features} />

      <Typography
        variant="body2"
        align="left"
        sx={{
          color: "#EB94C0!important",
          fontSize: 35,
          fontWeight: 500,
          marginTop: "50px",
          marginBottom: "20px",
        }}
      >
         {t('otherFeatures')}
      </Typography>

      <FeatureList features={additionalFeatures} />

      <Button
        component={NavLink}
        to="/contact-us"
        variant={"contained"}
          sx={{
            marginTop: 10,
            marginBottom: 10,
            borderRadius: 10,
            height: 70,
            width: "100%",
            fontSize: 24,
            backgroundColor: '#531881',
                '&:hover': {
                  backgroundColor: '#ffffff', 
                  color: '#531881',
                  border: "3px solid #531881"
                },
              '@media (max-width: 800px)': {
                  height: 50,         
                  fontSize: 18,       
                  padding: '8px 20px',
                  marginTop: "50px",
                },
            }}
          >
            {t("exploreMoreFeaturesFreeTrial")}
      </Button>
    </Container>
  );
}
