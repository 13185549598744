import React from "react";
import { useSelector } from "react-redux";
import ProjectAndRewardAdmin from "../admin/project";
import ProjectAndRewardUser from "../user/project";

const ProjectAndRewardMain = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <div>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ? (
          <ProjectAndRewardAdmin />
        ) :
          userProfile.roles.includes("ROLE_USER") ? (
            <ProjectAndRewardUser />
          ) : (
            null
          )
      ) : null}
    </div>
  );
};

export default ProjectAndRewardMain;
