import React from "react";
import CardStyle from "../../../../shared/general/Card";
import {
  Avatar,
  Chip,
  Grid,
  Typography,
  CardActionArea,
  Divider,
  styled,
} from "@mui/material";
import {
  getUserFullName,
  getUserPosition,
} from "../../../../../../utils/userData";

const typeStyles = {
  boss: {
    backgroundColor: "#EC5B6A",
  },
  peer: {
    backgroundColor: "#ff8d00",
  },
  crossfunction: {
    backgroundColor: "#7d55c2",
  },
  customer: {
    backgroundColor: "#008dd1",
  },
  subordinate: {
    backgroundColor: "#00bdb1",
  },
  project: {
    backgroundColor: "#ffc700",
  },
  outsider: {
    backgroundColor: "#820747",
  },
  oneself: {
    backgroundColor: "#b46c6c",
  },
  default: {
    backgroundColor: "#b46c6c",
  },
};
const ChipSampleSize = styled(Chip)(({ type }) => ({
  ...(typeStyles[type] || typeStyles.default),
}));
const CardAppraisee = (props) => {
  const { appraisee, setOpenDialog } = props;

  return (
    <CardStyle
    style={{
        border: `2px solid ${appraisee.isComplete ? "green" : "red"}`,
      }}
    >
      <CardActionArea onClick={setOpenDialog}>
        <Grid
          container
          sx={{
            padding: "16px",
          }}
        >
          <Grid
            item
            container
            xs={12}
            sx={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <Avatar
                sx={{
                  width: 60,
                  height: 60,
                  "& img": { objectFit: "contain" },
                }}
                src={appraisee.imageURL}
              />
              <div
                style={{
                  marginLeft: "15px",
                  flexGrow: 1,
                }}
              >
                <Typography
                  sx={{ fontWeight: "500", fontSize: "18px" }}
                  gutterBottom
                >
                  {getUserFullName(appraisee)}
                </Typography>
                <Typography variant="body2" color={"text.secondary"}>
                  {getUserPosition(appraisee)}
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              mt: "15px",
            }}
          >
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              mt: "15px",
            }}
          >
            {appraisee.appraisers.map((e, indexX) => (
              <div
                key={indexX}
                style={{
                  marginBottom: "15px",
                }}
              >
                <Typography color="text.secondary" variant="subtitle2">
                  ผู้ประเมินคนที่ {indexX + 1}
                </Typography>
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  
                  <Typography color="text.secondary" variant="subtitle2">
                    ratio(%)
                  </Typography>
                </div> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="subtitle1" gutterBottom>
                    {getUserFullName(e)}
                  </Typography>
                  <ChipSampleSize
                    label={e.role}
                    size="small"
                    type={e.role}
                    sx={{
                      color: "white",
                    }}
                  />
                </div>
              </div>
            ))}
          </Grid>
        </Grid>
      </CardActionArea>
    </CardStyle>
  );
};

export default CardAppraisee;
