import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Button } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { upsertWarningLetterTemplate } from "../../../../../actions/warningLetter";
import { useDispatch } from "react-redux";

const DialogCreate = ({ open, handleClose, data, idCompany }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    name: yup.string().max(100).required(t("titleIsRequired")),
    detail: yup.string().max(450),
    content: yup.string(),
  });

  const { control, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: {
      name: "",
      detail: "",
      content: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const handleSave = async (values) => {
    try {
      let formData = { ...values, idCompany: idCompany };

      if (data) {
        formData = {
          ...formData,
          idWarningLetterTemplate: data.idWarningLetterTemplate,
          idWarningLetterTemplateHtml: data.idWarningLetterTemplateHtml,
          content: data.content,
        };
      }

      const res = await dispatch(upsertWarningLetterTemplate(formData));

      reset();
      handleClose();
      if (res.status === 200) {
        await Swal.fire({
          title: data ? t("Save") : t("Create"),
          text: `${data ? t("Save") : t("Create")} ${t("Succeed")}`,
          icon: "success",
          confirmButtonText: t("Ok"),
        });
      } else {
        await Swal.fire({
          title: t("Error"),
          text: t("AnErrorOccurred"),
          icon: "error",
          confirmButtonText: t("Ok"),
        });
      }
    } catch (error) {
      console.error("Error saving data:", error);
      handleClose();
      await Swal.fire({
        title: t("Error"),
        text: t("AnErrorOccurred"),
        icon: "error",
        confirmButtonText: t("Ok"),
      });
    } 
  };

  React.useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>{data ? t("Edit") : t("Create")}</DialogTitle>
      <form onSubmit={handleSubmit(handleSave)}>
        <DialogContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={t("Name")}
                    error={Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="detail"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    label={t("Details")}
                    error={Boolean(errors.detail)}
                    helperText={errors.detail && errors.detail.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="error" type="button" onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <ButtonBlue variant="contained" type="submit">
            {t("Confirm")}
          </ButtonBlue>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogCreate;
