import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  styled,
  Typography,
  Chip,
  Avatar,
  IconButton,
  Tab,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tabs,
  Box,
  Container,
  Divider,
} from "@mui/material";
import {
  getUserFullName,
  getUserPosition,
} from "../../../../../../utils/userData";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetailAnswerPotentialAssessment,
  getQuestionListByIdPotentialAssessment,
} from "../../../../../../actions/potential";
const typeStyles = {
  boss: {
    backgroundColor: "#EC5B6A",
  },
  peer: {
    backgroundColor: "#ff8d00",
  },
  crossfunction: {
    backgroundColor: "#7d55c2",
  },
  customer: {
    backgroundColor: "#008dd1",
  },
  subordinate: {
    backgroundColor: "#00bdb1",
  },
  project: {
    backgroundColor: "#ffc700",
  },
  outsider: {
    backgroundColor: "#820747",
  },
  oneself: {
    backgroundColor: "#b46c6c",
  },
  default: {
    backgroundColor: "#b46c6c",
  },
};
const ChipSampleSize = styled(Chip)(({ type }) => ({
  ...(typeStyles[type] || typeStyles.default),
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const DetailOverviewDialog = (props) => {
  const { drawerConfig, onClose } = props;
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [roleOptions, setRoleOptions] = useState([]);
  const [ratio, setRatio] = useState([]);
  const { result: data, isFetching: dataLoading } = useSelector(
    (state) => state.potentialDetailOverviewAppraiser
  );
  const { isFetching: questionLoading, result: questions } = useSelector(
    (state) => state.PotentialQuestionList
  );
  useEffect(() => {
    if (drawerConfig.isOpen) {
      dispatch(getDetailAnswerPotentialAssessment(drawerConfig.idAppraisee));
      dispatch(
        getQuestionListByIdPotentialAssessment(
          drawerConfig.idPotentialAssessmentQuestionSet
        )
      );
    }
  }, []);
  useEffect(() => {
    if (data) {
      let temp = [];
      let temp1 = [];
      data.forEach((e) => {
        if (!temp.includes(e.role)) {
          temp.push(e.role);
          temp1.push(e.ratio);
        }
      });
      setRoleOptions(temp);
      console.log(temp1);
      setRatio(temp1);
    }
  }, [data]);

  return (
    <Dialog
      maxWidth={"lg"}
      fullWidth={true}
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        style: {
          height: "100%",
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>รายละเอียด</Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Tabs
          value={tab}
          onChange={(event, valueTab) => {
            setTab(valueTab);
          }}
          aria-label="wrapped label tabs"
          sx={{
            marginBottom: "15px",
          }}
        >
          <Tab label="รายคำถาม" value={0} {...a11yProps(0)} />
          <Tab label="รายบุคคล" value={1} {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={tab} index={0}>
          <Grid container spacing={2}>
            {questions &&
              questions.map((e, index) => (
                <Grid key={index} item xs={12}>
                  <Accordion
                    sx={{
                      padding: "10px",
                      borderRadius: "16px !important",
                      border: "1px solid #919eab29", // กำหนดเส้นขอบ
                      boxShadow: "none", // ปิดเงา
                      "&:before": {
                        display: "none", // ซ่อนเส้นแบ่งก่อน Accordion
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        fontSize={"20px"}
                      >
                        {index + 1}. {e.questionName}?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={3}>
                        {data &&
                          data.map(
                            (k, indexk) =>
                              k.answers && (
                                <Grid item container xs={12} key={indexk}>
                                  <Grid item xs={12} variant="subtitle1">
                                    {k.answers &&
                                      k.answers
                                        .filter(
                                          (j) => e.idQuestion == j.idQuestion
                                        )
                                        .map((j, i) => (
                                          <div>
                                            <Typography fontSize={18}>{j.answer}</Typography>
                                           {
                                              j.feedback !== null && (
                                                <div>
                                                  <Typography variant="body2" color={"text.secondary"}>ความคิดเห็น:</Typography>
                                                  <Typography variant="body1" >{j.feedback}</Typography>
                                                </div>
                                              )
                                           }
                                          </div>
                                        ))}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      marginTop:"20px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Avatar
                                        sx={{
                                          mr: "10px",
                                          width: 35,
                                          height: 35,
                                          "& img": { objectFit: "contain" },
                                        }}
                                        src={k.ImageURL}
                                      />
                                      <div>
                                        <Typography variant="body1" >
                                          {getUserFullName(k)}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          color={"text.secondary"}
                                        >
                                          {getUserPosition(k)}
                                        </Typography>
                                      </div>
                                    </div>
                                    <div>
                                      <ChipSampleSize
                                        label={k.role}
                                        type={k.role}
                                        sx={{
                                          fontSize: "16px",
                                          color: "white",
                                        }}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      marginTop: "15px",
                                    }}
                                  >
                                    <Divider />
                                  </Grid>
                                </Grid>
                              )
                          )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
          </Grid>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          {roleOptions.map((e, index) => (
            <Grid
              key={index}
              container
              sx={{
                padding: "24px",
                border: "1px solid #919eab29",
                borderRadius: "16px",
                marginBottom: "15px",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  marginBottom: "15px",
                  display:"flex",
                  justifyContent:"space-between"
                }}
              >
                  <ChipSampleSize
                    label={`${e} ${ratio[index]}%`}
                    type={e}
                    sx={{
                      fontSize: "16px",
                      color: "white",
                    }}
                  />


                <Typography>
                  คะแนนเฉลี่ย(%)
                </Typography>
              </Grid>
              {data &&
                data.map((k, indexK) => {
                  if (k.role === e) {
                    return (
                      <Grid
                        xs={12}
                        item
                        container
                        key={indexK}
                        sx={{
                          marginBottom: "10px",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              sx={{
                                mr: "10px",
                                width: 35,
                                height: 35,
                                "& img": { objectFit: "contain" },
                              }}
                              src={k.ImageURL}
                            />
                            <div>
                              <Typography variant="body1">
                                {getUserFullName(k)}
                              </Typography>
                              <Typography
                                variant="body2"
                                color={"text.secondary"}
                              >
                                {getUserPosition(k)}
                              </Typography>
                            </div>
                          </div>

                          <div>
                            {k.isComplete != 1 ? (
                              <Chip
                                label={"ยังไม่ได้ประเมิน"}
                                size="small"
                                color="error"
                              />
                            ) : (
                              <Typography fontSize={"20"} fontWeight={600}>{k.scoreAvg}</Typography>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    );
                  }
                })}
            </Grid>
          ))}
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

export default DetailOverviewDialog;
