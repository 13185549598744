import React, { useEffect, useState } from "react";
import { Grid, IconButton, Typography, MenuItem, Chip, Avatar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllEmployeeReward } from "../../../../actions/project";
import PageviewIcon from "@mui/icons-material/Pageview";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from "../../shared/general/Card";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { getManager } from "../../../../actions/manager";
import Loading from "../../shared/loading";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import AddReward from "./AddReward";
import { getUserDepartment, getUserDivision, getUserFullName, getUserPosition, getUserSection } from "../../../../utils/userData";

const useStyles = makeStyles(() => ({
  headerText: {
    fontSize: "1.2rem",
    fontWeight: "600",
    color: "#007afc",
  },
  textSmall: {
    fontSize: 14,
    color: "#9e9e9e",
  },
  textNoData: {
    fontSize: 22,
    fontWeight: "600",
    textAlign: "center",
  },
}));

const EmployeeReward = ({ idCompany }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: employeeReward, isFetching } = useSelector(
    (state) => state.employeeReward
  );
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(dayjs().get("year"));
  const [rewardSelected, setRewardSeleted] = useState(null);

  const handleClickOpen = (data) => {
    setOpen(true);
    setRewardSeleted(data);
  };

  const handleClose = () => {
    setOpen(false);
    setRewardSeleted(null);
  };

  useEffect(() => {
    dispatch(getAllEmployeeReward({ idCompany, year: selectedYear }));
  }, [idCompany, selectedYear]);

  useEffect(() => {
    dispatch(getManager({ idCompany }));
  }, [idCompany]);

  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: 16 }}>
        <Grid item xl={8} lg={8} md={6} xs={12}>
          <Typography variant="h4" fontWeight={500}>
            {t("Reward")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <div>
            <Typography fontSize={14} fontWeight={600} color="text.third">
              {t("Year")}
            </Typography>
            <TextFieldTheme
              select
              variant="filled"
              value={selectedYear}
              onChange={(event) => setSelectedYear(event.target.value)}
            >
              <MenuItem value={dayjs().get("year")}>
                {dayjs().get("year")}
              </MenuItem>
              <MenuItem value={dayjs().get("year") - 1}>
                {dayjs().get("year") - 1}
              </MenuItem>
            </TextFieldTheme>
          </div>
          <ButtonBlue
            variant="contained"
            size={"large"}
            startIcon={<AddIcon />}
            onClick={() => handleClickOpen(null)}
          >
            {t("AddReward")}
          </ButtonBlue>
        </Grid>
      </Grid>
      {isFetching ? (
        <Loading />
      ) : employeeReward && employeeReward.length > 0 ? (
        employeeReward.map((val, index) => (
          <div style={{ marginBottom: 16 }} key={index}>
            <CardStyle style={{ padding: 20 }}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"space-between"}
                  flexWrap={"wrap"}
                >
                  <Typography className={classes.headerText}>
                    {val.rewardName}
                  </Typography>
                  <Chip
                    size="small"
                    color={
                      val.isApproved === 1
                        ? "success"
                        : val.isApproved === 0
                        ? "error"
                        : "warning"
                    }
                    label={
                      val.isApproved === 1
                        ? t("Approved")
                        : val.isApproved === 0
                        ? t("NotApproved")
                        : t("WaitingForApproval")
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Avatar src={val.imageURL} alt="employee" />
                  <Typography>{getUserFullName(val)}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Typography className={classes.textSmall}>
                    {t("Division")}
                  </Typography>
                  <Typography>{getUserDivision(val)}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                <Typography className={classes.textSmall}>
                    {t("Department")}
                  </Typography>
                  <Typography>{getUserDepartment(val)}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Typography className={classes.textSmall}>
                    {t("Section")}
                  </Typography>
                  <Typography>{getUserSection(val)}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Typography className={classes.textSmall}>
                    {t("Position")}
                  </Typography>
                  <Typography>{getUserPosition(val)}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography className={classes.textSmall}>
                    {t("Institution")}
                  </Typography>
                  <Typography>{val.institution}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Typography className={classes.textSmall}>
                    {t("RewardType")}
                  </Typography>
                  <Typography>
                    {val.rewardType === "internal" ? t("Internal") : t("External")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Typography className={classes.textSmall}>
                    {t("ReceivedDate")}
                  </Typography>
                  <Typography>
                    {dayjs(val.receivedDate).format(i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Typography className={classes.textSmall}>
                    {t("CreationDate")}
                  </Typography>
                  <Typography>
                    {dayjs(val.createDate).format(i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  <Link
                    to={{
                      pathname: "/reward/detail",
                      state: { idEmployeeReward: val.idEmployeeReward },
                    }}
                  >
                    <IconButton sx={{ color: "success.dark" }}>
                      <PageviewIcon style={{ fontSize: 28 }} />
                    </IconButton>
                  </Link>
                  <IconButton
                    sx={{ color: "primary.main" }}
                    onClick={() => handleClickOpen(val)}
                  >
                    <EditIcon style={{ fontSize: 28 }} />
                  </IconButton>
                </Grid>
              </Grid>
            </CardStyle>
          </div>
        ))
      ) : (
        <CardStyle style={{ padding: 20 }}>
          <Typography className={classes.textNoData}>{t("NoData")}</Typography>
        </CardStyle>
      )}

      {open && (
        <AddReward
          idEmployeeReward={rewardSelected && rewardSelected.idEmployeeReward}
          open={open}
          handleClose={handleClose}
          idCompany={idCompany} 
          selectedYear={selectedYear}
        />
      )}
    </>
  );
};

export default EmployeeReward;
