import React, { Fragment, useEffect, useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import { Add as AddIcon, Edit as EditIcon } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import AccordionRow from "./accordionRow";
import { useTranslation } from "react-i18next";
import {
  deleteExpenseSettingCostElement,
  getExpenseSettingsCostElement,
} from "../../../../../actions/expenseSetting";
import DialogConfirmDelete from "../../../shared/general/DialogConfirmDelete";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import CostElementSettingForm from "./costElementSettingForm";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  marginTop: "30px",
  "& .MuiTypography-h6": {
    fontSize: "1.5rem",
  },
});

const ExpenseSettingCostElementPage = (props) => {
  const {
    selectedCompany,
    drawerConfig,
    setDrawerConfig,
    openAlert,
    setOpenAlert,
    alertType,
    setAlertType,
    alertConfig,
    setAlertConfig,
  } = props;
  const [isReadOnly, setIsReadOnly] = useState(false);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: expenseSettingsCostElement } = useSelector(
    (state) => state.expenseSettingsCostElement
  );

  const [deleteConfig, setDeleteConfig] = useState({
    isOpen: false,
    data: {},
  });

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getExpenseSettingsCostElement(selectedCompany.idCompany));
    }
  }, [selectedCompany]);

  const handleOpenDrawer = () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: true,
      isEdit: false,
      data: {},
    }));
  };

  const handleCloseDrawer = () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };
  const handleCloseDeleteModal = () => {
    setDeleteConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleDeleteExpenseSetting = async () => {
    const response = await dispatch(
      deleteExpenseSettingCostElement(
        deleteConfig.data.idExpenseSettingCostElementGroup
      )
    );

    if (response) {
      if (response.status === 200) {
        setOpenAlert(true);
        setAlertType("success");
        setAlertConfig((prev) => ({
          ...prev,
          isOpen: true,
          type: "success",
          message: `${t("DeletedSuccessfully")}`,
        }));
        dispatch(getExpenseSettingsCostElement(selectedCompany.idCompany));
        handleCloseDeleteModal();
      } else {
        setOpenAlert(true);
        setAlertType("error");
        setAlertConfig((prev) => ({
          ...prev,
          isOpen: true,
          type: "error",
          message: `${t("CantDeletedPleaseContactAdmin")}`,
        }));
      }
    } else {
      setOpenAlert(true);
      setAlertType("error");
      setAlertConfig((prev) => ({
        ...prev,
        isOpen: true,
        type: "error",
        message: `${t("AnErrorOccurred")} ${t("PleaseContactAdmin")}`,
      }));
    }

    setDeleteConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  return (
    <StyledRoot>
      {!drawerConfig.isOpen && (
        <Fragment>
          <Box
            marginBottom="16px"
            display="flex"
            justifyContent="space-between"
          >
            <Typography fontSize="24px" fontWeight="500"></Typography>
            <ButtonBlue
              variant={"contained"}
              startIcon={<AddIcon />}
              onClick={handleOpenDrawer}
            >
              {t("addExpenseSetting")}
            </ButtonBlue>
          </Box>

          {expenseSettingsCostElement &&
            expenseSettingsCostElement.map((item, index) => (
              <AccordionRow
                data={item}
                key={`accordion_${index}`}
                setDrawerConfig={setDrawerConfig}
                setDeleteConfig={setDeleteConfig}
              />
            ))}
        </Fragment>
      )}

      {drawerConfig.isOpen && (
        <CostElementSettingForm
          drawerConfig={drawerConfig}
          onClose={handleCloseDrawer}
          setAlertType={setAlertType}
          setOpenAlert={setOpenAlert}
          selectedCompany={selectedCompany}
          isReadOnly={isReadOnly}
        />
      )}
      <DialogConfirmDelete
        open={deleteConfig.isOpen}
        handleClose={handleCloseDeleteModal}
        label={`${t("costElementGroup")}`}
        text={deleteConfig.data.name}
        handleDelete={handleDeleteExpenseSetting}
        isReadOnly={isReadOnly}
      />
    </StyledRoot>
  );
};

export default ExpenseSettingCostElementPage;
