import {
    POTENTIAL_UNI_QUESTION_SUCCESS,
    POTENTIAL_UNI_QUESTION_FETCHING,
    POTENTIAL_UNI_QUESTION_FAILED
} from "../actions/types";

const initialState = {
  result: null,
  isFetching: false,
  isError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case POTENTIAL_UNI_QUESTION_FETCHING:
      return { ...state, result: null, isFetching: true, isError: false };
    case POTENTIAL_UNI_QUESTION_FAILED:
      return { ...state, result: null, isFetching: false, isError: true };
    case POTENTIAL_UNI_QUESTION_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    default:
      return state;
  }
}
