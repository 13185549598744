import React, { Fragment, useEffect, useState } from 'react';
import { Autocomplete, Box, Grid, Stack, styled, TextField, Typography } from "@mui/material"
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from '../../../shared/general/TextFieldTheme';
import { useTranslation } from 'react-i18next';
import DatePickerCustom from '../../../shared/date/datePicker';
import dayjs from 'dayjs';
import ButtonBlue from '../../../shared/general/ButtonBlue';
import { useDispatch, useSelector } from 'react-redux';
import { getManager } from '../../../../../actions/manager';
import { addDevelopmentTalentPlan } from '../../../../../actions/employee';

const StyledRoot = styled(Box)({
    padding: "16px",
    "& .MuiAutocomplete-root": {
        "& .MuiOutlinedInput-root": {
            padding: "13.5px 14px",
            paddingRight: "32px",
            "& input": {
                padding: 0,
            },
        },
    },
    "& .field-label": {
        fontSize: "14px",
        fontWeight: "500",
        paddingBottom: "4px",
    },
});

const StyledAutoComplete = styled(Autocomplete)({
    "& .MuiFilledInput-root": {
        padding: "13.5px 14px",
        paddingRight: "32px",
        "& input": {
            padding: 0
        }
    }
})

const StyledTextField = styled(TextField)({
    width: "100%",
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "& .MuiFilledInput-input": {
            padding: "7px 4px",
        },
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            "& button": {
                color: "#919EAB",
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&:after": {
            border: "none",
        },
        "&:before": {
            border: "none",
            borderBottomStyle: "none !important",
        }
    },
});

export const AddDevTalentPlan = (props) => {
    const {
        open,
        setOpen,
        setOpenAlert,
        setAlertType,
        idEmployees,
        idCompany,
        idEmployeeTalent,
        setIsUpdated
    } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [devName, setDevName] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [endDate, setEndDate] = useState(dayjs());
    const [startDate, setStartDate] = useState(dayjs());
    const [selectedMaterial, setSelectedMethod] = useState(null);
    const [selectedAssessor, setSelectedAssessor] = useState(null);
    const [selectedAssessmentMethod, setSelectedAssessmentMethod] = useState(null);

    const { result: managers } = useSelector((state) => state.manager);

    useEffect(() => {
        if (idCompany) {
            dispatch(getManager({ idCompany }));
        };
    }, [idCompany]);

    const handleClose = () => {
        setOpen(false);
        setDevName(null);
        setEndDate(dayjs());
        setStartDate(dayjs());
        setSelectedMethod(null);
        setSelectedAssessor(null);
        setSelectedAssessmentMethod(null);
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    const devMaterial = [
        { idMaterial: 1, materialName: "Project" },
        { idMaterial: 2, materialName: "Rotation" },
        { idMaterial: 3, materialName: "Coaching" },
    ];

    const assesmentMethod = [
        { idMethod: 1, methodName: "ทำข้อสอบ" },
        { idMethod: 2, methodName: "ทดลอง 1" },
        { idMethod: 3, methodName: "ทดลอง 2" },
    ];

    const handleStartDateChange = (newValue) => {
        const newStartDate = dayjs(newValue);
        if (newStartDate.isAfter(endDate)) {
            setEndDate(newStartDate);
        }
        setStartDate(newStartDate);
    };

    const handleEndDateChange = (newValue) => {
        const newEndDate = dayjs(newValue);
        if (newEndDate.isBefore(startDate)) {
            setStartDate(newEndDate);
        }
        setEndDate(newEndDate);
    };

    const validateInputs = () => {
        return devName && selectedMaterial && startDate && endDate && selectedAssessor && selectedAssessmentMethod;
    };

    const handleSave = async () => {
        try {
            setIsSaving(true);

            if (!validateInputs()) {
                handleOpenAlert(true);
                handleChangeAlertType(t("PleaseFillInRequiredInformations"));
                return;
            };

            const dataToSave = {
                planName: devName,
                material: selectedMaterial.materialName,
                assessmentMethod: selectedAssessmentMethod.methodName,
                idEmployees: idEmployees,
                assessor: selectedAssessor.idEmployees,
                startDate: dayjs(startDate).format("YYYY-MM-DD"),
                endDate: dayjs(endDate).format("YYYY-MM-DD"),
                idEmployeeTalent: idEmployeeTalent
            };

            const response = await addDevelopmentTalentPlan(dataToSave);
            if (response && response.status === 200) {
                handleOpenAlert(true);
                handleChangeAlertType("success");
                handleClose();
                setIsUpdated(true);
            } else {
                handleOpenAlert(true);
                handleChangeAlertType(`${t("Unsuccessful")}`);
            };
        } catch (error) {
            handleOpenAlert(true);
            handleChangeAlertType(`${t("Unsuccessful")} - ${error.message || error}`);
            console.error("Error saving data:", error);
        } finally {
            setIsSaving(false);
        };
    };

    return (
        <DrawerCustom title={`แผนพัฒนา Talent`} anchor="right" open={open} onClose={handleClose}>
            <StyledRoot style={{ width: 400 }}>
                <Box sx={{ p: 2 }}>
                    <Grid container spacing={2} alignItems={"center"}>
                        <Grid item xs={12}>
                            <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                                ชื่อการพัฒนา
                            </Typography>
                            <TextFieldTheme variant={"outlined"} onChange={(e) => setDevName(e.target.value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                                เครื่องมือการพัฒนา
                            </Typography>
                            <StyledAutoComplete
                                options={devMaterial ? devMaterial : []}
                                getOptionLabel={(option) => {
                                    return option.materialName;
                                }}
                                disabled={isSaving}
                                renderInput={(params) => (
                                    <StyledTextField
                                        {...params}
                                        variant="filled"
                                        placeholder={"เลือก Grade"}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: params.InputProps.endAdornment,
                                        }}
                                    />
                                )}
                                value={selectedMaterial}
                                onChange={(_, value) => {
                                    setSelectedMethod(value);
                                }}
                                noOptionsText={t("NoData")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                                {t("StartDate")}
                            </Typography>
                            <div className="search-date">
                                <DatePickerCustom
                                    inputFormat="DD MMMM YYYY"
                                    views={['year', 'month', 'day']}
                                    value={startDate}
                                    name="start"
                                    disabled={isSaving}
                                    onChange={handleStartDateChange}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                                {t("EndDate")}
                            </Typography>
                            <div className="search-date">
                                <DatePickerCustom
                                    inputFormat="DD MMMM YYYY"
                                    views={['year', 'month', 'day']}
                                    value={endDate}
                                    name="end"
                                    disabled={isSaving}
                                    onChange={handleEndDateChange}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                                ผู้ประเมิน
                            </Typography>
                            <StyledAutoComplete
                                options={managers ? managers : []}
                                getOptionLabel={(option) => {
                                    return `${option && option.firstname_TH ? option.firstname_TH : "-"} ${option && option.lastname_TH ? option.lastname_TH : "-"}`;
                                }}
                                disabled={isSaving}
                                renderInput={(params) => (
                                    <StyledTextField
                                        {...params}
                                        variant="filled"
                                        placeholder={"เลือกผู้ประเมิน"}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: params.InputProps.endAdornment,
                                        }}
                                    />
                                )}
                                value={selectedAssessor}
                                onChange={(_, value) => {
                                    setSelectedAssessor(value);
                                }}
                                noOptionsText={t("NoData")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                                วิธีการประเมิน
                            </Typography>
                            <StyledAutoComplete
                                options={assesmentMethod ? assesmentMethod : []}
                                getOptionLabel={(option) => {
                                    return `${option.methodName}`;
                                }}
                                disabled={isSaving}
                                renderInput={(params) => (
                                    <StyledTextField
                                        {...params}
                                        variant="filled"
                                        placeholder={"เลือกวิธีการประเมิน"}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: params.InputProps.endAdornment,
                                        }}
                                    />
                                )}
                                value={selectedAssessmentMethod}
                                onChange={(_, value) => {
                                    setSelectedAssessmentMethod(value);
                                }}
                                noOptionsText={t("NoData")}
                            />
                        </Grid>
                    </Grid>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            mt: 4
                        }}
                    >
                        <Stack direction={"row"} alignItems={"center"} spacing={2}>
                            <ButtonBlue onClick={() => handleClose()} disabled={isSaving}>
                                ยกเลิก
                            </ButtonBlue>
                            <ButtonBlue
                                variant={"contained"}
                                onClick={() => handleSave()}
                                disabled={!(devName || selectedMaterial || startDate || endDate || selectedAssessor || selectedAssessmentMethod || isSaving)}
                            >
                                บันทึก
                            </ButtonBlue>
                        </Stack>
                    </Box>
                </Box>
            </StyledRoot>
        </DrawerCustom>
    )
}