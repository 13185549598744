import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Tabs, { tabsClasses } from "@mui/material/Tabs";
import { Container, Typography, Box, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpenseSettingPage from "./setting-main";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliateOrganization } from "../../../../actions/company";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { useTranslation } from "react-i18next";
import Notification from "../../shared/general/Notification";
import AlertResponse from "../../shared/general/AlertResponse";
import ExpenseSettingMileagePage from "./milage";
import ExpenseSettingAllowancePage from "./allowance";
import ExpenseSettingCostElementPage from "./cost-element";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledWrapTabs = styled("div")({
  paddingBottom: 36,
  marginTop: 36,
  width: "100%",
});

const SettingPage = () => {
  const dispatch = useDispatch();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    type: "success",
    message: "",
  });

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: null,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
  };

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    setOpenAlert(false);
    setAlertType(false);
    setAlertConfig({ isOpen: false, type: "success", message: "" });
    setDrawerConfig({
      isOpen: false,
      isEdit: false,
      data: null,
    });
  }, [value, selectedCompany]);

  useEffect(() => {
    if (
      userProfile &&
      affiliateOrganizationList &&
      affiliateOrganizationList.length > 0
    ) {
      let ownCompany = affiliateOrganizationList.find(
        (x) => x.idCompany === userProfile.idCompany
      );
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany);
    }
  }, [userProfile, affiliateOrganizationList]);

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          {t("expenseSetting")}
        </Typography>
        <StyledWrapTabs>
          <Box>
            <Box marginBottom="24px">
              <SelectAffiliateCompany
                options={affiliateOrganizationList}
                value={selectedCompany}
                onChange={(_, value) => {
                  onChangeCompany(value);
                }}
              />
            </Box>
            {userProfile && (
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="visible arrows tabs example"
                sx={{
                  "& .MuiTab-root": {
                    textTransform: "none",
                  },
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                  "& .MuiTab-root.Mui-selected": {
                    color: "#46cbe2",
                  },
                }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#46cbe2",
                  },
                }}
              >
                <Tab
                  label={`${t("expenseSetting")}`}
                  {...a11yProps(0)}
                  value={0}
                />
                <Tab label={`${t("allowance")}`} {...a11yProps(1)} value={1} />
                <Tab label={`${t("mileage")}`} {...a11yProps(2)} value={2} />
                <Tab label={`Cost Element`} {...a11yProps(3)} value={3} />
              </Tabs>
            )}
          </Box>
          <TabPanel value={value} index={0}>
            <ExpenseSettingPage
              selectedCompany={selectedCompany}
              drawerConfig={drawerConfig}
              setDrawerConfig={setDrawerConfig}
              openAlert={openAlert}
              setOpenAlert={setOpenAlert}
              alertType={alertType}
              setAlertType={setAlertType}
              alertConfig={alertConfig}
              setAlertConfig={setAlertConfig}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ExpenseSettingAllowancePage
              selectedCompany={selectedCompany}
              drawerConfig={drawerConfig}
              setDrawerConfig={setDrawerConfig}
              openAlert={openAlert}
              setOpenAlert={setOpenAlert}
              alertType={alertType}
              setAlertType={setAlertType}
              alertConfig={alertConfig}
              setAlertConfig={setAlertConfig}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ExpenseSettingMileagePage
              selectedCompany={selectedCompany}
              drawerConfig={drawerConfig}
              setDrawerConfig={setDrawerConfig}
              openAlert={openAlert}
              setOpenAlert={setOpenAlert}
              alertType={alertType}
              setAlertType={setAlertType}
              alertConfig={alertConfig}
              setAlertConfig={setAlertConfig}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ExpenseSettingCostElementPage
              selectedCompany={selectedCompany}
              drawerConfig={drawerConfig}
              setDrawerConfig={setDrawerConfig}
              openAlert={openAlert}
              setOpenAlert={setOpenAlert}
              alertType={alertType}
              setAlertType={setAlertType}
              alertConfig={alertConfig}
              setAlertConfig={setAlertConfig}
            />
          </TabPanel>
        </StyledWrapTabs>
      </Container>
      <Notification notify={alertConfig} setNotify={setAlertConfig} />

      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </StyledRoot>
  );
};

export default SettingPage;
