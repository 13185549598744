import React, { Fragment, useEffect, useState } from "react";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
//Translator TH-EN
import { useTranslation } from "react-i18next";

import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Snackbar,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DrawerBeneficiary from "./drawerBeneficiary";
import DialogPFDate from "./dialogPFDate";
import DialogLeftDate from "./dialogLeftPF";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

import { openNotificationAlert } from "../../../../../../actions/notificationAlert";
import {
  getBeneficiaryEmployeeByIdEmployee,
  deleteBeneficiary,
} from "../../../../../../actions/beneficiary";
import {
  addProvidentFundDate,
  getProvidentFundEmployeeByIdEmployee,
  leaveProvidentFund,
  updateProvidentFundUserByAdmin,
  updateProvidentFundDate
} from "../../../../../../actions/providentFund";
  
const StyledRoot = styled(Box)(({ leftpf }) => ({
  "& .Topic": {
    fontWeight: 600,
    fontSize: 20,
  },
  "& .beneficiary": {
    marginTop: 16,
    "& .navButton": {
      display: "flex",
      justifyContent: "flex-end",
    },
    "& .tableContainer": {
      "& .TableCellHead": {
        padding: "0 !important",
      },
      "& .MuiTableCell-root": {
        borderWidth: 0,
        padding: "16px 8px",
      },
      "& .icon": {
        fontSize: 16,
        cursor: "pointer",
      },
    },
  },
  "& .text": {
    color: leftpf === "true" ? "#bdbdbd" : "initial",
  },
}));

const StyledDivider = styled(Divider)({
  marginTop: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const MinusIconButton = styled(IconButton)({
  border: "1px solid #ff0000",
  color: "#ff0000",
  "&.Mui-disabled": {
    border: "1px solid #bdbdbd",
  },
});

const AddIconButton = styled(IconButton)({
  border: "1px solid #1976d2",
  color: "#1976d2",
  "&.Mui-disabled": {
    border: "1px solid #bdbdbd",
  },
});

const LabelAndSwitch = ({ label, value, handleChange }) => {
  const { result: EmployeeProvidentFunds } = useSelector(
    (state) => state.employeeProvidentFunds
  );

  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" style={{ width:"100%" }}>
      <Box>
        <Typography 
          className="text"
          style={{ fontSize: 16, fontWeight: 400 }}
        >{label}</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        {isLoading ? 
          <CircularProgress variant="indeterminate" size="24px" thickness="5" /> :
          <Switch 
            checked={value} 
            disabled={!EmployeeProvidentFunds || (EmployeeProvidentFunds && !EmployeeProvidentFunds.idEmployeePFDate)} 
            onChange={(event) => {
              handleChange(event.target.checked, setIsLoading);
            }} 
          />
        }
      </Box>
    </Box>
  );
};

const PF = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: AllBeneficiarys } = useSelector(
    (state) => state.employeeBeneficiarys
  );
  const { result: EmployeeProvidentFunds } = useSelector(
    (state) => state.employeeProvidentFunds
  );
  const [openDrawerBeneficiary, setOpenDrawerBeneficiary] = useState(false);
  const [beneficiarySelected, setBeneficiarySelected] = useState(null);
  const [snackBar, setSnackBar] = useState({
    isOpen: false,
    result: null,
    label: "",
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState({
    isOpen: false,
    idSelected: null,
  });
  const [openDialogChangePFDate, setOpenDialogChangePFDate] = useState(false);
  const [openDialogChangePF, setOpenDialogChangePF] = useState(false);
  const [openDialogLeftPF, setOpenDialogLeftPF] = useState(false);
  const [leftPF, setLeftPF] = useState(false);
  const [newCompanyPF, setNewCompanyPF] = useState(null);
  const [isAutoCompanyPF, setIsAutoCompanyPF] = useState(false);
  const [useDateUserPF, setUseDateUserPF] = useState(
    dayjs().date(1).add(1, "month").format("YYYY-MM-DD")
  );
  const [isAutoPF, setIsAutoPF] = useState(false);
  const [isLoadingAutoPF, setIsLoadingAutoPF] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      newEmployeePF: "",
    },
  });

  useEffect(() => {
    if(employeeProfile && employeeProfile.idEmployees){
        dispatch(getBeneficiaryEmployeeByIdEmployee(employeeProfile.idEmployees));
        dispatch(getProvidentFundEmployeeByIdEmployee(employeeProfile.idEmployees));
    }
  },[employeeProfile]);

  useEffect(() => {
    if (EmployeeProvidentFunds && EmployeeProvidentFunds.newUserPF) {
      setValue("newEmployeePF", EmployeeProvidentFunds.newUserPF);
      setNewCompanyPF(EmployeeProvidentFunds.newCompanyPF);
    } else {
      setValue("newEmployeePF", "");
      setNewCompanyPF(null);
    }

    if(EmployeeProvidentFunds){
      if(Boolean(EmployeeProvidentFunds.isAutoPF) !== isAutoPF){
        setIsAutoPF(Boolean(EmployeeProvidentFunds.isAutoPF));
      }

      if(Boolean(EmployeeProvidentFunds.isAutoCompanyPF) !== isAutoCompanyPF){
        setIsAutoCompanyPF(Boolean(EmployeeProvidentFunds.isAutoCompanyPF));
      }
    }

    let defaultUseDateUserPF = dayjs().date(1).add(1, "month").format("YYYY-MM-DD");

    if (
      !EmployeeProvidentFunds ||
      !EmployeeProvidentFunds.PFDate ||
      (!!EmployeeProvidentFunds.leftDate &&
        (
          dayjs().isAfter(dayjs(EmployeeProvidentFunds.leftDate).add(1, 'month'), "month")
        )
      )
    ) {
      setLeftPF(true);
    } else {
      if (
        EmployeeProvidentFunds &&
        EmployeeProvidentFunds.leftDate &&
        !dayjs(EmployeeProvidentFunds.leftDate).isAfter(
          dayjs(),
          "month"
        )
      ) {
        defaultUseDateUserPF = dayjs(EmployeeProvidentFunds.leftDate).date(1).format("YYYY-MM-DD");
      }
      setLeftPF(false);
    }

    setUseDateUserPF(defaultUseDateUserPF);
  }, [EmployeeProvidentFunds]);

  const CompanyPFByTextField = () => {
    let result = 0;
    let newEmployeePF = watch("newEmployeePF");
    if (
      newEmployeePF && 
      EmployeeProvidentFunds &&
      EmployeeProvidentFunds.listEmployeePF &&
      EmployeeProvidentFunds.listEmployeePF.length > 0
    ) {
      EmployeeProvidentFunds.listEmployeePF.map((pf) => {
        if (
          pf.minEmployeePF <= newEmployeePF &&
          pf.maxEmployeePF >= newEmployeePF
        ) {
          result = pf.companyPF;
        }
      });
    }
    return result;
  };

  const onHandleSubmit = async () => {
    setOpenDialogChangePF(false);
    
    let data = {
      idEmployees: employeeProfile.idEmployees,
      companyPF: newCompanyPF,
      userPF: getValues("newEmployeePF"),
      useDate: useDateUserPF,
      isAutoCompanyPF: isAutoCompanyPF ? 1 : 0
    };

    if(isAutoCompanyPF && (newCompanyPF !== CompanyPFByTextField())){
      data.isAutoCompanyPF = 0;
    }
    
    const res = await dispatch(updateProvidentFundUserByAdmin(data));
    if (res.status === 200) {
      await dispatch(
        getProvidentFundEmployeeByIdEmployee(employeeProfile.idEmployees)
      );
      // setUseDateUserPF(dayjs().date(1).add(1, "month").format("YYYY-MM-DD"));
      setSnackBar({
        isOpen: true,
        result: "success",
        label: `เปลี่ยนแปลงอัตราสะสมสำเร็จ`,
      });
    } else {
      // setUseDateUserPF(dayjs().date(1).add(1, "month").format("YYYY-MM-DD"));
      setSnackBar({
        isOpen: true,
        result: "error",
        label: `เปลี่ยนแปลงอัตราสะสมไม่สำเร็จ โปรด Refresh หน้าเว็บแล้วลองอีกครั้ง`,
      });
    }
  };

  const handleCloseNotify = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({ isOpen: false, result: null, label: "" });
  };

  const handleDelete = async (idBeneficiary) => {
    const res = await dispatch(deleteBeneficiary(idBeneficiary));
    if (res.status === 200) {
      setSnackBar({
        isOpen: true,
        result: "success",
        label: `ลบผู้ได้รับผลประโยชน์สำเร็จ`,
      });
      await dispatch(
        getBeneficiaryEmployeeByIdEmployee(employeeProfile.idEmployees)
      );
    } else {
      setSnackBar({
        isOpen: true,
        result: "error",
        label: `ลบผู้ได้รับผลประโยชน์ไม่สำเร็จ`,
      });
    }
    setOpenDeleteDialog({ isOpen: false, idSelected: null });
  };

  const onClickMinusButton = () => {
    if (newCompanyPF > 0) {
      let newValue = newCompanyPF - 1;
      setNewCompanyPF(newValue);
    }
  };

  const onClickAddButton = () => {
    if (newCompanyPF < 20) {
      let newValue = newCompanyPF + 1;
      setNewCompanyPF(newValue);
    }
  };

  const handleSubmitLeftDate = async (leftDate) => {
    if (employeeProfile) {
      let result = await dispatch(
        leaveProvidentFund({
          idEmployees: employeeProfile.idEmployees,
          leftDate: dayjs(leftDate).endOf('month').format("YYYY-MM-DD"),
        })
      );
      if (result.status === 200) {
        dispatch(
          openNotificationAlert({
            type: "success",
            message: "ออกจากกองทุนสำเร็จ",
          })
        );
        await dispatch(
          getProvidentFundEmployeeByIdEmployee(employeeProfile.idEmployees)
        );
      } else {
        dispatch(
          openNotificationAlert({
            type: "error",
            message: t("ErrorOccurredContactAdmin"),
          })
        );
      }
    } else {
      dispatch(
        openNotificationAlert({
          type: "error",
          message: t("ErrorOccurredContactAdmin"),
        })
      );
    }
    setOpenDialogLeftPF(false);
  };

  const handleJoinPF = async () => {
    if (employeeProfile) {
      let result = await dispatch(
        addProvidentFundDate({
          idEmployees: employeeProfile.idEmployees,
        })
      );
      if (result.status === 200) {
        dispatch(
          openNotificationAlert({
            type: "success",
            message: "ออกจากกองทุนสำเร็จ",
          })
        );
        await dispatch(
          getProvidentFundEmployeeByIdEmployee(employeeProfile.idEmployees)
        );
      } else {
        dispatch(
          openNotificationAlert({
            type: "error",
            message: t("ErrorOccurredContactAdmin"),
          })
        );
      }
    } else {
      dispatch(
        openNotificationAlert({
          type: "error",
          message: t("ErrorOccurredContactAdmin"),
        })
      );
    }
  };

  const displaySevYearMonth = (ServPFDate) => {
    let diff = dayjs().diff(dayjs(ServPFDate), "month", true);
    let allMonth = Math.floor(diff > 0 ? diff : 0);
    let year = Math.floor(allMonth / 12);
    let month = allMonth - (year * 12);
    if (year === 0 && month === 0) {
      return `0 ${t("Month")}`;
    } else {
      return `${year > 0 ? `${year} ${t("Year")} ` : ""}${
        month > 0 ? `${month} ${t("Month")}` : ""
      }`;
    }
  };

  const handleChangeAutoPF = async (checked, setLoading) => {
    if(EmployeeProvidentFunds && EmployeeProvidentFunds.idEmployeePFDate){
      setIsAutoPF(checked);
      setLoading(true);
      await dispatch(updateProvidentFundDate({
        idEmployeePFDate : EmployeeProvidentFunds.idEmployeePFDate,
        isAutoPF : checked ? 1 : 0
      })).then((res) => {
        if(res && res.status === 200){
          setLoading(false);
          dispatch(
            openNotificationAlert({
              type: "success",
              message: `${checked ? t("Active") : t("Disable")}${t("minimumEmployeeContributionRate")}`,
            })
          );
        }else{
          setIsAutoPF(!checked);
          setLoading(false);
          dispatch(
            openNotificationAlert({
              type: "error",
              message: t("ErrorOccurredContactAdmin"),
            })
          );
        }
      }).catch(() => {
        setIsAutoPF(!checked);
        setLoading(false);
        dispatch(
          openNotificationAlert({
            type: "error",
            message: t("ErrorOccurredContactAdmin"),
          })
        );
      });
    }
  };

  const handleChangeAutoCompanyPF = async (checked, setLoading) => {
    if(EmployeeProvidentFunds && EmployeeProvidentFunds.idEmployeePFDate){
      setIsAutoCompanyPF(checked);
      setLoading(true);
      await dispatch(updateProvidentFundDate({
        idEmployeePFDate : EmployeeProvidentFunds.idEmployeePFDate,
        isAutoCompanyPF : checked ? 1 : 0
      })).then((res) => {
        if(res && res.status === 200){
          setLoading(false);
          dispatch(
            openNotificationAlert({
              type: "success",
              message: `${checked ? t("Active") : t("Disable")}${t("companyContributionRate")}`,
            })
          );
        }else{
          setIsAutoCompanyPF(!checked);
          setLoading(false);
          dispatch(
            openNotificationAlert({
              type: "error",
              message: t("ErrorOccurredContactAdmin"),
            })
          );
        }
      }).catch(() => {
        setIsAutoCompanyPF(!checked);
        setLoading(false);
        dispatch(
          openNotificationAlert({
            type: "error",
            message: t("ErrorOccurredContactAdmin"),
          })
        );
      });
    }
  };

  const displayTitle = (title_TH) => {
    if(!title_TH){
      return "";
    }
    if(title_TH === "นาย"){
      return t("Mr");
    }
    if(title_TH === "นาง"){
      return t("Mrs");
    }
    if(title_TH === "นางสาว"){
      return t("Ms");
    }
    if(title_TH === "เด็กชาย"){
      return t("Master");
    }
    if(title_TH === "เด็กหญิง"){
      return t("Miss");
    }
  };

  return (
    <StyledRoot leftpf={`${leftPF}`}>
      <form onSubmit={handleSubmit(() => setOpenDialogChangePF(true))}>
        <Grid container spacing={2}>
          {!leftPF && !userProfile.readOnly && (
            <Grid item xs={12} container justifyContent={"flex-end"}>
              <Grid item>
                <ButtonBlue
                  size="small"
                  startIcon={<EditIcon />}
                  onClick={() => setOpenDialogChangePFDate(true)}
                >
                  {t("Edit")}
                </ButtonBlue>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={6} style={{ padding: "24px 16px" }}>
            <Typography align="center" className="text" style={{ fontWeight: "600" }}>
              {t("ServiceDateInFund")}
            </Typography>
            <Typography
              className="text"
              style={{ fontSize: 24, fontWeight: 400, marginTop: 16 }}
              align="center"
            >
              {(EmployeeProvidentFunds &&
                EmployeeProvidentFunds.hiringDate &&
                `${dayjs(EmployeeProvidentFunds.hiringDate).format(
                  i18n.resolvedLanguage === "th" ? "DD MMMM BBBB" : "DD MMMM YYYY"
                )}`) ||
                "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "24px 16px" }}>
            <Typography align="center" className="text" style={{ fontWeight: "600" }}>
              {t("YearOfService")}
            </Typography>
            <Typography
              className="text"
              style={{ fontSize: 24, fontWeight: 400, marginTop: 16 }}
              align="center"
            >
              {(EmployeeProvidentFunds &&
                EmployeeProvidentFunds.hiringDate &&
                `${displaySevYearMonth(EmployeeProvidentFunds.hiringDate)}`) ||
                "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "24px 16px" }}>
            <Typography align="center" className="text" style={{ fontWeight: "600" }}>
              {t("FundEntryDate")}
            </Typography>
            <Typography
              className="text"
              style={{ fontSize: 24, fontWeight: 400, marginTop: 16 }}
              align="center"
            >
              {(EmployeeProvidentFunds &&
                EmployeeProvidentFunds.PFDate &&
                `${dayjs(EmployeeProvidentFunds.PFDate).format(
                  i18n.resolvedLanguage === "th" ? "DD MMMM BBBB" : "DD MMMM YYYY"
                )}`) ||
                "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "24px 16px" }}>
            <Typography align="center" className="text" style={{ fontWeight: "600" }}>
              {t("YearInFund")}
            </Typography>
            <Typography
              className="text"
              style={{ fontSize: 24, fontWeight: 400, marginTop: 16 }}
              align="center"
            >
              {(EmployeeProvidentFunds &&
                EmployeeProvidentFunds.PFDate &&
                `${displaySevYearMonth(EmployeeProvidentFunds.PFDate)}`) ||
                "-"}
            </Typography>
          </Grid>
          {EmployeeProvidentFunds &&
          [0, 1].includes(EmployeeProvidentFunds.isServYPFDate) ? (
            <Grid
              item
              xs={12}
              container
              style={{ padding: "8px 0px 8px 16px" }}
              justifyContent={"space-between"}
            >
              <Grid item>
                <Typography
                  className="text"
                  style={{ fontSize: 16, fontWeight: 400 }}
                >
                  {t("CalYearInFund")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className="text"
                  style={{ fontSize: 20, fontWeight: 600 }}
                >
                  {EmployeeProvidentFunds.isServYPFDate 
                    ? t("FundEntryDate")
                    : t("ServiceDateInFund")}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12}>
            <LabelAndSwitch label= {t("minimumEmployeeContributionRate")} value={isAutoPF} handleChange={(value, setLoading) => handleChangeAutoPF(value, setLoading)} />
          </Grid>
          <Grid item xs={12}>
            <LabelAndSwitch label={t("companyContributionRate")} value={isAutoCompanyPF} handleChange={(value, setLoading) => handleChangeAutoCompanyPF(value, setLoading)} />
          </Grid>
          <StyledDivider />
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <Typography className="Topic text">{t("AccumulatedRate")}</Typography>
              {EmployeeProvidentFunds && EmployeeProvidentFunds.leftDate &&
                <Typography>{`${t("MonthExitFund")} : ${dayjs(
                  EmployeeProvidentFunds.leftDate
                ).format("MMM YYYY")}`}</Typography>
              }
              {
              (
                userProfile &&
                !EmployeeProvidentFunds || 
                !EmployeeProvidentFunds.leftDate || 
                (dayjs().isAfter(dayjs(EmployeeProvidentFunds.leftDate), 'month'))
               ) && 
                (
                  (
                    (
                      EmployeeProvidentFunds &&
                      (
                        !EmployeeProvidentFunds.PFDate ||
                        (EmployeeProvidentFunds.leftDate && dayjs().isAfter(dayjs(EmployeeProvidentFunds.leftDate), 'month'))
                      )
                    )
                    && !userProfile.readOnly
                  ) ? (
                    <ButtonBlue variant="contained" onClick={handleJoinPF}>
                      {t("๋JoinFund")}
                    </ButtonBlue>
                  ) : (!userProfile.readOnly && EmployeeProvidentFunds && !EmployeeProvidentFunds.leftDate) ? (
                    <ButtonBlue
                      variant="outlined"
                      onClick={() => setOpenDialogLeftPF(true)}
                    >
                      {t("LeaveFund")}
                    </ButtonBlue>
                  ) : <></>
                )
              }
            </div>
          </Grid>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={4}>
            <Typography align="center" className="text" style={{ fontWeight: "600" }}>
              {`${t("EmployeeSection")}(%)`}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography align="center" className="text" style={{ fontWeight: "600" }}>
              {`${t("ContributionCompany")}(%)`}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography align="center" className="text" style={{ fontWeight: "600" }}>
              {t("CurrentAccumulatedRate")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" align="center" className="text">
              {(EmployeeProvidentFunds &&
                EmployeeProvidentFunds.userPF &&
                `${EmployeeProvidentFunds.userPF}`) ||
                "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" align="center" className="text">
              {(EmployeeProvidentFunds &&
                EmployeeProvidentFunds.companyPF &&
                `${EmployeeProvidentFunds.companyPF}`) ||
                "-"}
            </Typography>
          </Grid>
          {!userProfile.readOnly && (
            <>
              <Grid
                item
                xs={12}
                md={4}
                style={{ display: "flex", alignItems: "center", justifyContent:"center" }}
              >
                <Typography className="text" align="center" style={{ fontWeight: "600" }}>
                  {t("NewAccumulatedRate")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name="newEmployeePF"
                  control={control}
                  rules={{
                    required: { value: true, message: t("PleaseSelectNewCumulativeRate") },
                  }}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        if (EmployeeProvidentFunds && !Boolean(EmployeeProvidentFunds.isAutoCompanyPF) && !newCompanyPF && newCompanyPF !== 0) {
                          setNewCompanyPF(
                            (EmployeeProvidentFunds &&
                              EmployeeProvidentFunds.companyPF) ||
                              0
                          );
                        }else{
                          let newCompanyPF = CompanyPFByTextField();
                          setNewCompanyPF(newCompanyPF);
                        }
                      }}
                      select
                      disabled={leftPF}
                      helperText={
                        errors &&
                        errors.newEmployeePF &&
                        errors.newEmployeePF.message
                      }
                      error={errors && errors.newEmployeePF ? true : false}
                    >
                      {[...Array(16)].map((_, index) => {
                        return (
                          <MenuItem value={index} key={index}>
                            {index}
                          </MenuItem>
                        );
                      })}
                    </TextFieldTheme>
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <MinusIconButton
                  onClick={onClickMinusButton}
                  disabled={
                    (!newCompanyPF && newCompanyPF !== 0) ||
                    newCompanyPF === 0 ||
                    leftPF
                  }
                >
                  <RemoveIcon />
                </MinusIconButton>
                <Typography variant="h6" align="center" className="text">
                  {newCompanyPF || (newCompanyPF === 0 ? 0 : "-")}
                </Typography>
                <AddIconButton
                  onClick={onClickAddButton}
                  disabled={
                    (!newCompanyPF && newCompanyPF !== 0) ||
                    newCompanyPF === 20 ||
                    leftPF
                  }
                >
                  <AddIcon />
                </AddIconButton>
              </Grid>
              {!(leftPF) && (
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <ButtonBlue variant="contained" type="submit">
                      {t("Save")}
                    </ButtonBlue>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </form>
      {EmployeeProvidentFunds &&
        EmployeeProvidentFunds.listPFDate &&
        EmployeeProvidentFunds.listPFDate.length > 0 && (
          <Box>
            <Typography className="Topic text">{t("HistoryInFund")}</Typography>
            {EmployeeProvidentFunds.listPFDate.map((pfDate, index) => {
              return (
                <Fragment key={`${pfDate.idEmployeePFDate}_${index+1}`}>
                  <div style={{ display:"flex", marginTop: "16px" }}>
                    <div style={{ minWidth:"80px" }}>
                      <Typography style={{ fontWeight: "600" }}>
                        {`${t("RoundNo")} ${index + 1} :`}
                      </Typography>
                    </div>
                    <div>
                      <Typography>
                        {`${dayjs(pfDate.PFDate).format(
                            i18n.resolvedLanguage === 'th' ? "MMMM BBBB" : "MMMM YYYY"
                          )}${
                          pfDate.leftDate
                            ? ` - ${dayjs(pfDate.leftDate).format(
                              i18n.resolvedLanguage === 'th' ? "MMMM BBBB" : "MMMM YYYY"
                            )}`
                            : ` - ${t("Current")}`
                        }`}
                      </Typography>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </Box>
        )}
      <StyledDivider />
      <Box className="beneficiary">
        <Typography className="Topic text">{t("Beneficiary")}</Typography>
        {!userProfile.readOnly && (
          <Box className="navButton">
            <ButtonBlue
              variant="contained"
              disabled={leftPF}
              onClick={() => setOpenDrawerBeneficiary(true)}
            >
              {t("AddBeneficiary")} 
            </ButtonBlue>
          </Box>
        )}
        <Box
          style={{
            width: "100%",
            overflowX: "auto",
          }}
        >
          <TableContainer className="tableContainer">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className="TableCellHead"
                    style={{ width: 56, minWidth: 56 }}
                  ></TableCell>
                  <TableCell className="TableCellHead"></TableCell>
                  <TableCell className="TableCellHead"></TableCell>
                  <TableCell
                    className="TableCellHead"
                    style={{ width: 88, minWidth: 88 }}
                  ></TableCell>
                  <TableCell
                    className="TableCellHead"
                    style={{ width: 104, minWidth: 104 }}
                  ></TableCell>
                  <TableCell
                    className="TableCellHead"
                    style={{ width: 40, minWidth: 40 }}
                  ></TableCell>
                  <TableCell
                    className="TableCellHead"
                    style={{ width: 48, minWidth: 48 }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {AllBeneficiarys && AllBeneficiarys.length > 0 ? (
                  AllBeneficiarys.map((beneficiary, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography className="text">
                          {displayTitle(beneficiary.title_TH)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className="text">
                          {beneficiary.firstname_TH}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className="text">
                          {beneficiary.lastname_TH}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className="text">
                          {beneficiary.relationship_TH}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className="text">
                          {beneficiary.telephoneMobile}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className="text">{`${beneficiary.percentage}%`}</Typography>
                      </TableCell>
                      {!userProfile.readOnly && (
                        <TableCell>
                          <Box style={{ display: "flex" }}>
                            <Box
                              style={{ marginRight: 16 }}
                              onClick={() => {
                                if (!leftPF) {
                                  setBeneficiarySelected(beneficiary);
                                  setOpenDrawerBeneficiary(true);
                                }
                              }}
                            >
                              <i
                                className="fa-regular fa-pen icon"
                                style={{
                                  color: leftPF ? "#bdbdbd" : "#1976D2",
                                  cursor: leftPF ? "default" : "pointer",
                                }}
                              ></i>
                            </Box>
                            <Box
                              onClick={() => {
                                if (!leftPF) {
                                  setOpenDeleteDialog({
                                    isOpen: true,
                                    idSelected: beneficiary.idBeneficiary,
                                  });
                                }
                              }}
                            >
                              <i
                                className="fa-regular fa-trash icon"
                                style={{
                                  color: leftPF ? "#bdbdbd" : "#ff0000",
                                  cursor: leftPF ? "default" : "pointer",
                                }}
                              ></i>
                            </Box>
                          </Box>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box
                        style={{
                          width: "100%",
                          height: 100,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography align="center" className="text">
                          {t("NoBeneficiary")}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {openDrawerBeneficiary && (
        <DrawerBeneficiary
          open={openDrawerBeneficiary}
          handleClose={() => {
            setOpenDrawerBeneficiary(false);
            setBeneficiarySelected(null);
          }}
          data={beneficiarySelected}
          setSnackBar={setSnackBar}
        />
      )}
      {openDeleteDialog.isOpen && (
        <Dialog
          open={openDeleteDialog.isOpen}
          onClose={() => setOpenDeleteDialog(false)}
        >
          <DialogContent style={{ width: 200 }}>
            <Typography variant="h5" align="center">
              {t("ConfirmToDelete")}
            </Typography>
            <Grid
              container
              justifyContent="space-between"
              style={{ marginTop: 16 }}
            >
              <Grid item>
                <ButtonBlue
                  onClick={() =>
                    setOpenDeleteDialog({ isOpen: false, idSelected: null })
                  }
                >
                  {t("Cancel")}
                </ButtonBlue>
              </Grid>
              <Grid item>
                <ButtonBlue
                  variant="contained"
                  onClick={() => handleDelete(openDeleteDialog.idSelected)}
                > 
                  {t("Confirm")}
                </ButtonBlue>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
      {openDialogChangePF && (
        <Dialog
          open={openDialogChangePF}
          onClose={() => setOpenDialogChangePF(false)}
        >
          <DialogContent style={{ width: 350 }}>
            <Typography variant="h5" align="center">
              <i
                className="fa-solid fa-triangle-exclamation"
                style={{ marginRight: 16, color: "#FFBF00" }}
              />
              {t("ConfirmCumulativeRateChange")}
            </Typography>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TextFieldTheme
                style={{
                  marginTop: 16,
                  width: 250,
                }}
                select
                value={useDateUserPF}
                onChange={(e) => {
                  setUseDateUserPF(e.target.value);
                }}
                label={t("SelectDate")}
              >
                {(!EmployeeProvidentFunds.PFDate || !(dayjs(EmployeeProvidentFunds.PFDate).isAfter(dayjs().date(1).subtract(1, "month"), 'month'))) ?
                  <MenuItem
                    value={dayjs().date(1).subtract(1, "month").format("YYYY-MM-DD")}
                  >
                    {`${t("Date")} 1 ${dayjs().subtract(1, "month").format("MMMM")} ${dayjs()
                      .subtract(1, "month")
                      .format("YYYY")}`}
                  </MenuItem>
                  : <></>
                }
                {(!EmployeeProvidentFunds.leftDate || !(dayjs(EmployeeProvidentFunds.leftDate).isBefore(dayjs().date(1), 'month'))) ?
                  <MenuItem value={dayjs().date(1).format("YYYY-MM-DD")}>
                    {`${t("Date")} 1 ${dayjs().format("MMMM")} ${dayjs().format(
                      "YYYY"
                    )}`}
                  </MenuItem> : <></>
                }
                {(!EmployeeProvidentFunds.leftDate || !(dayjs(EmployeeProvidentFunds.leftDate).isBefore(dayjs().date(1).add(1, "month"), 'month'))) ?
                  <MenuItem
                    value={dayjs().date(1).add(1, "month").format("YYYY-MM-DD")}
                  >
                    {`${t("Date")} 1 ${dayjs().add(1, "month").format("MMMM")} ${dayjs()
                      .add(1, "month")
                      .format("YYYY")}`}
                  </MenuItem>
                  : <></>
                }
              </TextFieldTheme>
            </div>
            <Grid
              container
              justifyContent="space-between"
              style={{ marginTop: 16 }}
            >
              <Grid item>
                <ButtonBlue onClick={() => setOpenDialogChangePF(false)}>
                  {t("Cancel")}
                </ButtonBlue>
              </Grid>
              <Grid item>
                <ButtonBlue variant="contained" onClick={onHandleSubmit}>
                  {t("Confirm")}
                </ButtonBlue>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
      {openDialogChangePFDate && EmployeeProvidentFunds && (
        <DialogPFDate
          open={openDialogChangePFDate}
          onClose={() => setOpenDialogChangePFDate(false)}
          values={EmployeeProvidentFunds}
        />
      )}
      {(openDialogLeftPF && EmployeeProvidentFunds) && 
        <DialogLeftDate 
          open={openDialogLeftPF}
          onClose={() => setOpenDialogLeftPF(false)}
          handleSubmit={handleSubmitLeftDate}
        />
      }
      {snackBar.isOpen && (
        <Snackbar
          open={snackBar.isOpen}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseNotify}
        >
          <Alert severity={snackBar.result} onClose={handleCloseNotify}>
            {snackBar.label}
          </Alert>
        </Snackbar>
      )}
    </StyledRoot>
  );
};

export default PF;
