import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
  IconButton,
  Autocomplete,
  autocompleteClasses,
  Popper,
  Divider,
  FormControlLabel,
  Radio,
  MenuItem,
  Chip,
  RadioGroup,
  Menu,
} from "@mui/material";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Dropzone, { useDropzone } from "react-dropzone";
import CardStyle from "../../../shared/general/Card";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  getExpenseProrateTypes,
  getExpenseAllowanceTypes,
  updateExpenseSetting,
  addExpenseSetting,
  updateExpenseSettingMileage,
  addExpenseSettingMileage,
  getExpenseSettingsMileage,
} from "../../../../../actions/expenseSetting";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import GalleryIcon from "../../../assets/gallery.png";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import SaveIcon from "@mui/icons-material/Save";
import { getAllPositions } from "../../../../../actions/positions";
import { getLevelByidCompany } from "../../../../../actions/level";
import { getEmploymentTypeByidCompany } from "../../../../../actions/employment-type";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import TimePicker from "../../../shared/general/TimePicker";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledRadio = styled((props) => <Radio {...props} />)(
  ({ theme, checked }) => ({
    "&.Mui-checked": {
      color: "#46CBE2",
    },
  })
);

class newAdapter extends AdapterDayjs {
  constructor({ locale, formats, instance }) {
    super({ locale, formats, instance });
  }
  formatByString = (date, format) => {
    const yearFormat = i18next.resolvedLanguage === "th" ? "BBBB" : "YYYY";
    const newFormat = format.replace(/\bYYYY\b/g, yearFormat);
    return this.dayjs(date).format(newFormat);
  };
}

function MileageSettingForm(props) {
  const {
    drawerConfig,
    onClose,
    setOpenAlert,
    setAlertType,
    selectedCompany,
    isReadOnly,
  } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: expenseSetting } = useSelector(
    (state) => state.expenseSetting
  );
  const { result: expenseProrateTypes } = useSelector(
    (state) => state.expenseProrateTypes
  );
  const { result: expenseAllowanceTypes } = useSelector(
    (state) => state.expenseAllowanceTypes
  );
  const { result: employmentTypes } = useSelector(
    (state) => state.employmentType
  );
  const { result: positions } = useSelector((state) => state.positions);
  const { result: groupLevels } = useSelector((state) => state.level);
  const [isSending, setIsSending] = useState(false);
  const [employmentTypeOption, setEmploymentTypeOption] = useState(null);
  const [groupLevelOption, setGroupLevelOption] = useState(null);
  const [levelOption, setLevelOption] = useState(null);
  const [positionOption, setPositionOption] = useState(null);
  const defaultValue = {
    name: "",
    name_EN: "",
    mileageRate: 5,
    mileageGovermentRate: 4,
    isUseGroupLevel: 1,
    employmentTypeList: [],
    levelList: [],
    groupLevelList: [],
    positionList: [],
  };
  const [formData, setFormData] = useState(defaultValue);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("ThisFieldIsRequired")),
    name_EN: Yup.string().required(t("ThisFieldIsRequired")),
    employmentTypeList: Yup.array().min(1).required(t("ThisFieldIsRequired")),
    isUseGroupLevel: Yup.number().required(t("ThisFieldIsRequired")),
    levelList: Yup.array().when("isUseGroupLevel", {
      is: (isUseGroupLevel) => isUseGroupLevel === 0,
      then: (schema) => schema.min(1).required(t("ThisFieldIsRequired")),
    }),
    groupLevelList: Yup.array().when("isUseGroupLevel", {
      is: (isUseGroupLevel) => isUseGroupLevel === 1,
      then: (schema) => schema.min(1).required(t("ThisFieldIsRequired")),
    }),
    positionList: Yup.array().min(1).required(t("ThisFieldIsRequired")),
    mileageRate: Yup.string().required(t("ThisFieldIsRequired")).default(0),
    mileageGovermentRate: Yup.string()
      .required(t("ThisFieldIsRequired"))
      .default(0),
  });
  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { ...formData },
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const [modalStatusConfig, setModalStatusConfig] = useState({
    open: false,
    title: "",
    content: "",
    type: "success",
  });

  const checkValidation = (error) => {
    console.log("error : ", error);
    setModalStatusConfig({
      open: true,
      title: "",
      content: t("ThisFieldIsRequired"),
      type: "error",
    });
  };

  useEffect(() => {
    if (selectedCompany) {
      dispatch(
        getEmploymentTypeByidCompany({ idCompany: selectedCompany.idCompany })
      );
      dispatch(getLevelByidCompany({ idCompany: selectedCompany.idCompany }));
      dispatch(getAllPositions({ idCompany: selectedCompany.idCompany }));
    }
  }, [selectedCompany]);

  useEffect(() => {
    let list = [
      {
        idEmploymentType: "",
        employmentTypeName: t("All"),
      },
    ];

    if (employmentTypes) {
      employmentTypes.map((item) => {
        list.push({
          idEmploymentType: item.idEmploymentType,
          employmentTypeName: item.employmentTypeName,
        });
      });
      setEmploymentTypeOption(list);
    }
  }, [employmentTypes]);

  useEffect(() => {
    let listGroupLevel = [
      {
        idGroupLevel: "",
        groupLevelName: t("All"),
      },
    ];

    let listLevel = [
      {
        idLevel: "",
        levelName: t("All"),
      },
    ];

    if (groupLevels) {
      groupLevels.map((item) => {
        listGroupLevel.push({
          idGroupLevel: item.idGroupLevel,
          groupLevelName: item.groupLevelName,
        });

        item.level.map((level) => {
          listLevel.push({
            idLevel: level.idLevel,
            levelName: level.levelName,
          });
        });
      });

      setGroupLevelOption(listGroupLevel);
      setLevelOption(listLevel);
    }
  }, [groupLevels]);

  useEffect(() => {
    let list = [
      {
        idPosition: "",
        positionName: t("All"),
      },
    ];
    if (positions) {
      positions.map((item) => {
        list.push({
          idPosition: item.idPosition,
          positionName: item.positionName,
        });
      });
      setPositionOption(list);
    }
  }, [positions]);

  useEffect(() => {
    if (getValues("isUseGroupLevel") === 1) {
      setValue("levelList", []);
    } else {
      setValue("groupLevelList", []);
    }
  }, [watch("isUseGroupLevel")]);

  useEffect(() => {
    if (drawerConfig.isOpen) {
      if (selectedCompany) {
        setValue("idCompany", selectedCompany.idCompany);
      }

      if (drawerConfig.isEdit) {
        let setting = drawerConfig.data;
        let defaultData = {
          idExpenseSettingMileage: setting.idExpenseSettingMileage,
          name: setting.name,
          name_EN: setting.name_EN,
          mileageRate: setting.mileageRate,
          mileageGovermentRate: setting.mileageGovermentRate,
          isUseGroupLevel: setting.isUseGroupLevel,
          employmentTypeList:
            setting.employmentTypeList === null
              ? [""]
              : setting.employmentTypeList.map((employment) => {
                  return employment.idEmploymentType;
                }),
          levelList:
            setting.levelList === null
              ? setting.isUseGroupLevel === 1
                ? []
                : [""]
              : setting.levelList.map((level) => {
                  return level.idLevel;
                }),
          groupLevelList:
            setting.groupLevelList === null
              ? setting.isUseGroupLevel === 1
                ? [""]
                : []
              : setting.groupLevelList.map((groupLevel) => {
                  return groupLevel.idGroupLevel;
                }),
          positionList:
            setting.positionList === null
              ? [""]
              : setting.positionList.map((position) => {
                  return position.idPosition;
                }),
        };
        reset(defaultData);
        setFormData(defaultData);
      }
    }
  }, [drawerConfig.isOpen]);

  const handleChangeCancle = () => {
    if (drawerConfig.isEdit === false) {
      reset(defaultValue);
    }
    onClose();
  };

  const onSubmit = async () => {
    setIsSending(true);
    setValue("idCompany", selectedCompany.idCompany);
    const data = getValues();

    let res;
    if (drawerConfig.isEdit) {
      res = await dispatch(updateExpenseSettingMileage(data));
    } else {
      res = await dispatch(addExpenseSettingMileage(data));
    }

    if (res.status === 200) {
      setIsSending(false);
      setOpenAlert(true);
      setAlertType("success");
      onClose();
      dispatch(getExpenseSettingsMileage(selectedCompany.idCompany));
    } else {
      setIsSending(false);
      setOpenAlert(true);
      setAlertType("error");
    }
  };

  return (
    <>
      <div style={{ marginBottom: 12 }}>
        <Typography variant="h5" style={{ paddingTop: 8 }}>
          {t("addExpenseSetting")}
        </Typography>
      </div>
      {employmentTypeOption &&
        groupLevelOption &&
        levelOption &&
        positionOption && (
          <CardStyle>
            <Box sx={{ padding: "24px", marginTop: "16px" }}>
              <form handleSubmit={onSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={6}>
                    <div>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("Name")}
                      </StyledHeadLabel>
                      <FormControl
                        fullWidth
                        error={errors && errors.name ? true : false}
                      >
                        {errors && errors.name && errors.name.message && (
                          <FormHelperText error>
                            {errors.name.message}
                          </FormHelperText>
                        )}
                        <Controller
                          name="name"
                          control={control}
                          errors={errors}
                          render={({ field }) => (
                            <>
                              <TextFieldTheme
                                {...field}
                                handleChange={setValue}
                                control={control}
                                watch={watch}
                              />
                            </>
                          )}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("NameEN")}
                      </StyledHeadLabel>
                      <FormControl
                        fullWidth
                        error={errors && errors.name_EN ? true : false}
                      >
                        {errors && errors.name_EN && errors.name_EN.message && (
                          <FormHelperText error>
                            {errors.name_EN.message}
                          </FormHelperText>
                        )}
                        <Controller
                          name="name_EN"
                          control={control}
                          errors={errors}
                          render={({ field }) => (
                            <>
                              <TextFieldTheme
                                {...field}
                                handleChange={setValue}
                                control={control}
                                watch={watch}
                              />
                            </>
                          )}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledHeadLabel
                      variant="body2"
                      color="text.third"
                      gutterBottom
                    >
                      {t("EmployeeType")}
                    </StyledHeadLabel>
                    <Controller
                      name="employmentTypeList"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextFieldTheme
                          {...field}
                          select
                          SelectProps={{
                            multiple: true,
                            renderValue: (select) => (
                              <Box>
                                {select.map((value) => (
                                  <Chip
                                    key={value}
                                    label={
                                      employmentTypeOption.find(
                                        (x) => x.idEmploymentType === value
                                      ).employmentTypeName || ""
                                    }
                                  />
                                ))}
                              </Box>
                            ),
                          }}
                          onChange={(e) => {
                            if (
                              e.target.value.length > 0 &&
                              e.target.value[e.target.value.length - 1] !== ""
                            ) {
                              const temp = e.target.value.filter(
                                (x) => x !== ""
                              );
                              field.onChange(temp);
                            } else if (e.target.value.includes("")) {
                              setValue(`employmentTypeList`, [""]);
                            } else {
                              field.onChange(e.target.value);
                            }
                          }}
                          error={fieldState.error ? true : false}
                          helperText={
                            fieldState.error ? fieldState.error.message : null
                          }
                        >
                          {employmentTypeOption.map((item, typeIndex) => {
                            return (
                              <MenuItem
                                value={item.idEmploymentType}
                                key={item.idEmploymentType}
                              >
                                {item.employmentTypeName}
                              </MenuItem>
                            );
                          })}
                        </TextFieldTheme>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledHeadLabel
                      variant="body2"
                      color="text.third"
                      gutterBottom
                    >
                      {t("Position")}
                    </StyledHeadLabel>
                    <Controller
                      name={`positionList`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextFieldTheme
                          {...field}
                          select
                          disabled={
                            watch(`employmentTypeList`) &&
                            watch(`employmentTypeList`).length === 0
                          }
                          SelectProps={{
                            multiple: true,
                            renderValue: (select) => (
                              <Box>
                                {select.map((value) => (
                                  <Chip
                                    key={value}
                                    label={
                                      positionOption.find(
                                        (x) => x.idPosition === value
                                      ).positionName
                                    }
                                  />
                                ))}
                              </Box>
                            ),
                          }}
                          onChange={(e) => {
                            if (
                              e.target.value.length > 0 &&
                              e.target.value[e.target.value.length - 1] !== ""
                            ) {
                              const temp = e.target.value.filter(
                                (x) => x !== ""
                              );
                              field.onChange(temp);
                            } else if (e.target.value.includes("")) {
                              setValue(`positionList`, [""]);
                            } else {
                              field.onChange(e.target.value);
                            }
                          }}
                          error={fieldState.error ? true : false}
                          helperText={
                            fieldState.error ? fieldState.error.message : null
                          }
                        >
                          {positionOption.map((item, typeIndex) => {
                            return (
                              <MenuItem
                                value={item.idPosition}
                                key={item.idPosition}
                              >
                                {item.positionName}
                              </MenuItem>
                            );
                          })}
                        </TextFieldTheme>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("employeeLevelType")}
                      </StyledHeadLabel>
                      <FormControl
                        fullWidth
                        error={errors && errors.isUseGroupLevel ? true : false}
                      >
                        <Controller
                          name="isUseGroupLevel"
                          control={control}
                          errors={errors}
                          render={({ field }) => (
                            <RadioGroup
                              {...field}
                              row
                              onChange={(e) =>
                                field.onChange(Number(e.target.value))
                              }
                            >
                              <FormControlLabel
                                value={1}
                                control={<StyledRadio />}
                                label={t("EmployeeGroupLevel")}
                              />
                              <FormControlLabel
                                value={0}
                                control={<StyledRadio />}
                                label={t("EmployeePersonalLevel")}
                              />
                            </RadioGroup>
                          )}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  {watch("isUseGroupLevel") === 1 ? (
                    <Grid item xs={12} md={6}>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("EmployeeGroupLevel")}
                      </StyledHeadLabel>
                      <Controller
                        name={`groupLevelList`}
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextFieldTheme
                            {...field}
                            select
                            disabled={
                              watch(`employmentTypeList`) &&
                              watch(`employmentTypeList`).length === 0
                            }
                            SelectProps={{
                              multiple: true,
                              renderValue: (select) => (
                                <Box>
                                  {select.map((value) => (
                                    <Chip
                                      key={value}
                                      label={
                                        groupLevelOption.find(
                                          (x) => x.idGroupLevel === value
                                        ).groupLevelName
                                      }
                                    />
                                  ))}
                                </Box>
                              ),
                            }}
                            onChange={(e) => {
                              if (
                                e.target.value.length > 0 &&
                                e.target.value[e.target.value.length - 1] !== ""
                              ) {
                                const temp = e.target.value.filter(
                                  (x) => x !== ""
                                );
                                field.onChange(temp);
                              } else if (e.target.value.includes("")) {
                                setValue(`groupLevelList`, [""]);
                              } else {
                                field.onChange(e.target.value);
                              }
                            }}
                            error={fieldState.error ? true : false}
                            helperText={
                              fieldState.error ? fieldState.error.message : null
                            }
                          >
                            {groupLevelOption.map((item) => (
                              <MenuItem
                                value={item.idGroupLevel}
                                keyvalue={item.idGroupLevel}
                              >
                                {item.groupLevelName}
                              </MenuItem>
                            ))}
                          </TextFieldTheme>
                        )}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={6}>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("EmployeePersonalLevel")}
                      </StyledHeadLabel>
                      <Controller
                        name={`levelList`}
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextFieldTheme
                            {...field}
                            select
                            SelectProps={{
                              multiple: true,
                              renderValue: (select) => (
                                <Box>
                                  {select.map((value) => (
                                    <Chip
                                      key={value}
                                      label={
                                        levelOption.find(
                                          (x) => x.idLevel === value
                                        ).levelName
                                      }
                                    />
                                  ))}
                                </Box>
                              ),
                            }}
                            onChange={(e) => {
                              if (
                                e.target.value.length > 0 &&
                                e.target.value[e.target.value.length - 1] !== ""
                              ) {
                                const temp = e.target.value.filter(
                                  (x) => x !== ""
                                );
                                field.onChange(temp);
                              } else if (e.target.value.includes("")) {
                                setValue(`levelList`, [""]);
                              } else {
                                field.onChange(e.target.value);
                              }
                            }}
                            error={fieldState.error ? true : false}
                            helperText={
                              fieldState.error ? fieldState.error.message : null
                            }
                          >
                            {levelOption.map((item) => (
                              <MenuItem value={item.idLevel} key={item.idLevel}>
                                {item.levelName}
                              </MenuItem>
                            ))}
                          </TextFieldTheme>
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    <div>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("mileageRate")}
                      </StyledHeadLabel>
                      <FormControl
                        fullWidth
                        error={errors && errors.mileageRate ? true : false}
                      >
                        {errors &&
                          errors.mileageRate &&
                          errors.mileageRate.message && (
                            <FormHelperText error>
                              {errors.mileageRate.message}
                            </FormHelperText>
                          )}
                        <Controller
                          name="mileageRate"
                          control={control}
                          errors={errors}
                          render={({ field }) => (
                            <>
                              <TextFieldTheme
                                {...field}
                                handleChange={setValue}
                                control={control}
                                watch={watch}
                              />
                            </>
                          )}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("mileageGovermentRate")}
                      </StyledHeadLabel>
                      <FormControl
                        fullWidth
                        error={
                          errors && errors.mileageGovermentRate ? true : false
                        }
                      >
                        {errors &&
                          errors.mileageGovermentRate &&
                          errors.mileageGovermentRate.message && (
                            <FormHelperText error>
                              {errors.mileageGovermentRate.message}
                            </FormHelperText>
                          )}
                        <Controller
                          name="mileageGovermentRate"
                          control={control}
                          errors={errors}
                          render={({ field }) => (
                            <>
                              <TextFieldTheme
                                {...field}
                                handleChange={setValue}
                                control={control}
                                watch={watch}
                              />
                            </>
                          )}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <ButtonBlue
                        variant="contained"
                        type
                        color="error"
                        startIcon={<DeleteOutlineIcon />}
                        onClick={() => handleChangeCancle(false)}
                        sx={{ marginRight: "16px" }}
                      >
                        {t("Cancel")}
                      </ButtonBlue>
                      <ButtonBlue
                        variant="contained"
                        type
                        startIcon={<SaveIcon />}
                        onClick={handleSubmit(onSubmit, checkValidation)}
                        disabled={isSending}
                      >
                        {t("Save")}
                      </ButtonBlue>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </CardStyle>
        )}
    </>
  );
}

export default MileageSettingForm;
