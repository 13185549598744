import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  Grid,
  MenuItem,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { getJobGroup } from "../../../../actions/jobRecruit";
import {
  getAllJobProfile,
  getAllPositions,
} from "../../../../actions/positions";
import { getLevelByidCompany } from "../../../../actions/level";
import { getOrganizationStructure } from "../../../../actions/organizationStructure";
import Notification from "../../shared/general/Notification";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../actions/company";
import PositionCard from "./PositionCard";
import DialogUpdateJobProfile from "./DialogUpdatePosition";
import AlertResponse from "../../shared/general/AlertResponse";

const StyledHeadingPage = styled("div")({
  marginBottom: 16,
});

const StyledAutoComplete = styled(Autocomplete)({
  "& .MuiFilledInput-root": {
    padding: "13.5px 14px",
    paddingRight: "32px",
    "& input": {
      padding: 0,
    },
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&:after": {
      border: "none",
    },
    "&:before": {
      border: "none",
      borderBottomStyle: "none !important",
    },
  },
});
const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: "5%",
  "& .form-radio-label-type": {
    ["@media only screen and (max-width: 600px)"]: {
      flexBasis: "25%",
      width: "fit-content",
      margin: 0,
    },
    "& .label-radio": {
      ["@media only screen and (max-width: 600px)"]: {
        fontSize: 14,
      },
    },
  },
});

function JobProfile() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [positionList, setPositionList] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [typeCorePosition, setTypeCorePosition] = useState({
    idCoreType: 0,
    name: "ทั้งหมด",
  })
  const options = [
    {
      idCoreType: 0,
      name: "ทั้งหมด",
    },
    {
      idCoreType: 1,
      name: "ตำแหน่งสำคัญ",
    },
    {
      idCoreType: 2,
      name: "ตำแหน่งปกติ",
    },
  ];
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );

  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    type: "success",
    message: "",
  });

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: null,
  });

  const [filterConfig, setFilterConfig] = useState({
    type: "position",
    name: "",
  });

  const handleChangeFilter = (event) => {
    setFilterConfig((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const fetchedJobProfileData = async (idCompany) => {
    try {
      const response = await getAllJobProfile({ idCompany: idCompany });
      if (response && response.data) {
        setPositionList(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseDialog = async () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));

    await fetchedJobProfileData(selectedCompany && selectedCompany.idCompany);
  };

  useEffect(() => {
    if (selectedCompany) {
      fetchedJobProfileData(selectedCompany.idCompany);
    }
  }, [selectedCompany]);

  const getFilteredData = () => {
    if (positionList) {
      if (filterConfig.name === "" && typeCorePosition.idCoreType === 0) return positionList;

      const filterData = positionList.filter((p) => {
        const filterName = filterConfig.name.toLowerCase();
        if (filterConfig.type === "position") {
          return (
            p.positionName && p.positionName.toLowerCase().includes(filterName)
          );
        } else if (filterConfig.type === "jobGroup") {
          return (
            p.jobGroupName && p.jobGroupName.toLowerCase().includes(filterName)
          );
        }
      });
      if (typeCorePosition.idCoreType != 0) {
        return filterData.filter((e) => e.idTypeCorePosition === typeCorePosition.idCoreType);
      }
      return filterData;
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getAllPositions({ idCompany: selectedCompany.idCompany }));
      dispatch(getJobGroup());
      dispatch(
        getOrganizationStructure({ idCompany: selectedCompany.idCompany })
      );
      dispatch(getLevelByidCompany({ idCompany: selectedCompany.idCompany }));
    }
  }, [selectedCompany]);

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
  };

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (
      userProfile &&
      affiliateOrganizationList &&
      affiliateOrganizationList.length > 0
    ) {
      let ownCompany = affiliateOrganizationList.find(
        (x) => x.idCompany === userProfile.idCompany
      );
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany);
    }
  }, [userProfile, affiliateOrganizationList]);

  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertError, setOpenAlertError] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const handleAlertCloseError = () => {
    setOpenAlertError(false);
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <StyledHeadingPage>
          <Grid container justifyContent="space-between">
            <Typography variant="h4" style={{ paddingTop: 8 }}>
              {t("jobProfile")}
            </Typography>
          </Grid>
        </StyledHeadingPage>

        <Box marginBottom="24px">
          <SelectAffiliateCompany
            options={affiliateOrganizationList}
            value={selectedCompany}
            onChange={(_, value) => {
              onChangeCompany(value);
            }}
          />
        </Box>

        <Box display="flex" gap="8px" marginBottom="16px">
          <TextFieldTheme
            name="type"
            value={filterConfig.type}
            onChange={handleChangeFilter}
            style={{ width: "200px" }}
            select
          >
            <MenuItem value="position">{t("JobPosition")}</MenuItem>
            <MenuItem value="jobGroup">{t("JobGroup")}</MenuItem>
          </TextFieldTheme>
          <TextFieldTheme
            name="name"
            value={filterConfig.name}
            onChange={handleChangeFilter}
            inputProps={{
              placeholder: `${t("Search")}`,
            }}
          />
        </Box>
        <Box>
          <Typography
            fontSize="14px"
            fontWeight="600"
            marginBottom="4px"
            color="text.third"
          >
            ประเภท
          </Typography>
          <StyledAutoComplete
            options={options ? options : []}
            getOptionLabel={(option) => {
              return option.name;
            }}
            isOptionEqualToValue={(option, value) =>
              option.idCoreType === value.idCoreType
            }
            renderInput={(params) => (
              <StyledTextField
                {...params}
                InputProps={{ ...params.InputProps, endAdornment: null }}
                variant="filled"
                placeholder="เลือกประเภทตำแหน่ง"
              />
            )}
            value={typeCorePosition}
            onChange={(_, value) => {
              setTypeCorePosition(value)
            }}
            noOptionsText={t("NoData")}
            disableClearable
          />
        </Box>

        <PositionCard
          data={getFilteredData()}
          selectedCompany={selectedCompany}
          setDrawerConfig={setDrawerConfig}
          setOpenAlert={setOpenAlert}
          setOpenAlertError={setOpenAlertError}
        />

        {/* alert for updating and adding data */}
        <AlertResponse
          open={openAlert}
          handleClose={handleAlertClose}
          alertType={"success"}
        />

        <AlertResponse
          open={openAlertError}
          handleClose={handleAlertCloseError}
          alertType={"error"}
        />

        <DialogUpdateJobProfile
          drawerConfig={drawerConfig}
          setDrawerConfig={setDrawerConfig}
          onClose={handleCloseDialog}
          selectedCompany={selectedCompany}
          setOpenAlert={setOpenAlert}
          setOpenAlertError={setOpenAlertError}
        />

        <Notification notify={alertConfig} setNotify={setAlertConfig} />
      </Container>
    </StyledRoot>
  );
}

export default JobProfile;
