import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Container,
  Breadcrumbs,
  Grid,
  Avatar,
  Divider,
  Paper,
  Stack,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import CardStyle from "../../../shared/general/Card";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { getSuccessorEmployeesById, getSuccessorRole, deleteSuccessor } from "../../../../../actions/successor";
import DialogSuccessor from "../DialogSuccessor";
import DialogSuccessorById from "../DialogSuccessorById";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import utils from "../../../../../utils";
import { getUserBusinessUnit, getUserCompany, getUserDepartment, getUserDivision, getUserFullName, getUserPosition, getUserSection } from "../../../../../utils/userData";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 50,
  "& .search-filter-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .filter-wrap": {},
    "& .action-container": {},
  },
});

const StyledDetailCard = styled("div")({
  padding: 24,
  paddingTop: 16,
  "& .part-header-detail": {
    paddingBottom: 24,
  },
});

const StyledLeft = styled("div")({
  width: "100%",
  "& .header-profile": {
    display: "flex",
    flexDirection: "column",
    alignItmes: "center",
    padding: 30,

    "& .empHeadingText": {
      color: "black !important",
      fontSize: "1.8rem",
      fontWeight: 600,
      alignSelf: "center",
      marginTop: "10px",
    },
    "& .jopHeadingText": {
      color: "gray !important",
      fontSize: "1.2rem",
      fontWeight: 400,
      alignSelf: "center",
    },
    "& .empImage": {
      minHeight: "150px",
      minWidth: "150px",
      boxShadow: "none",
      alignSelf: "center",
    },
  },
  "& .content-profile": {
    direction: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "30px 30px 30px calc(30px + 0.75em)",

    "& .text-topic": {
      color: "gray !important",
      fontSize: "1.2rem",
      fontWeight: 400,
    },
    "& .text-content": {
      color: "black !important",
      fontSize: "1.1rem",
      fontWeight: 600,
    },
  },
});

const StyledRight = styled("div")({
  "& .number-successor": {
    color: "black",
    fontSize: "18px",
    fontWeight: 600,
    marginTop: "10px",
    textAlign: "left",
    marginLeft: 10,
  },
  "& .icon-header": {
    marginTop: "10px",
    textAlign: "right",
    marginLeft: 10,
  },
  "& .header-profile": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 10,
    marginTop: "10px",

    "& .empHeadingText": {
      color: "black",
      fontSize: "18px",
      fontWeight: 500,
      alignSelf: "center",
    },
    "& .empPositionText": {
      color: "gray ",
      fontSize: "1rem",
      fontWeight: 300,
      textAlign: "left",
    },
    "& .empImage": {
      minHeight: "80px",
      minWidth: "80px",
      alignSelf: "center",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      outline: "3px solid white",
      marginBottom: "5px",
    },
    "& .styleButtonReady": {
      textAlign: "center",
      color: "white",
      backgroundColor: "#46e2ba",
      borderRadius: 20,
      marginTop: "3px",
      marginRight: "120px",
      padding: "1px",
      cursor: "pointer",
    },
    "& .styleButtonNotReady": {
      textAlign: "center",
      color: "white",
      backgroundColor: "#e24668",
      borderRadius: 20,
      marginTop: "3px",
      marginRight: "120px",
      padding: "1px",
      cursor: "pointer",
    },
  },
  "& .content-profile": {
    direction: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "10px 10px 10px calc(10px + 0.75em)",

    "& .textTopic": {
      color: "gray ",
      fontSize: "0.8rem",
      fontWeight: 300,
      textAlign: "left",
    },
    "& .textContent": {
      color: "black",
      fontSize: "0.8rem",
      fontWeight: 600,
      textAlign: "left",
    },
  },
});

const StyledFooter = styled("div")({
  "& .footer-profile": {
    display: "flex",
    direction: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 10px 10px calc(10px + 0.75em)",

    "& .footer-text-topic": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "gray ",
      fontSize: "0.8rem",
      fontWeight: 300,
      alignSelf: "center",
    },
    "& .styleBox": {
      width: "70px",
      border: "1.5px solid #46cbe2",
      borderRadius: 5,
      marginTop: "25px",
      "& .text-box": {
        textAlign: "center",
        fontSize: "1.2rem",
        fontWeight: 600,
      },
    },
  },
});

const DetailPage = (props) => {
  const { idEmployees } = useParams();
  // const { handleChange } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [employeeData, setEmployeeData] = useState(null);
  const [successorRole, setSuccessorRole] = useState(null);

  const fetchedData = async (idEmployees) => {
    try {
      const response = await getSuccessorEmployeesById(idEmployees);
      if (response) {
        setEmployeeData(response.data[0]);

        const successorRoleResponse = await getSuccessorRole(response.data[0].idEmployeePosition);
        if (successorRoleResponse) {
          setSuccessorRole(successorRoleResponse.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (idEmployees) {
      fetchedData(idEmployees);
    }
  }, [idEmployees]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear() + 543;

    return `${day} ${getMonthAbbreviation(month)} ${year}`;
  }

  const colorMapping = {
    "A3": "#C3F6E7",
    "A2": "#A1F3DB",
    "A1": "#7AF3D0",
    "B3": "#D2F7FF",
    "B2": "#B1EEFC",
    "B1": "#84E9FF",
    "C3": "#FBEEDB",
    "C2": "#FFE0B2",
    "C1": "#FFD08C",
  };

  function getMonthAbbreviation(monthIndex) {
    const monthsAbbreviation = [
      t("Months.January"), t("Months.February"), t("Months.March"), t("Months.April"), t("Months.May"), t("Months.June"),
      t("Months.July"), t("Months.August"), t("Months.September"), t("Months.October"), t("Months.November"), t("Months.December")
    ];

    return monthsAbbreviation[monthIndex];
  }
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [dialog2IdEmployees, setDialog2IdEmployees] = useState(null);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  }

  const handleOpenDialog2 = (idEmployees) => {
    if (idEmployees) {
      setOpenDialog2(true);
      setDialog2IdEmployees(idEmployees);
    }
  }

  const onDeleteSuccessor = async (idEmployee, idEmployeePostion) => {
    await dispatch(deleteSuccessor(idEmployee, idEmployeePostion));
  };

  const onDelete = async (idEmployee, idEmployeePostion) => {
    try {
      const result = await Swal.fire({
        title: t("AreYouSure"),
        text: t("CannotUndo") + "!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Confirm"),
        cancelButtonText: t("Cancel"),
      });

      if (result.isConfirmed) {
        Swal.fire({
          title: t("DeletedSuccessfully")+"!",
          text: "Successor "+t("thisPersonHasBeenDeleted"),
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: t("Success"),
          didDestroy: () => {
            window.location.reload();
          }
        });
        await onDeleteSuccessor(idEmployee, idEmployeePostion);
      } else {
      }
    } catch (error) {
      console.error("Error deleting Successor:", error);
    }
  };

  const CURRENT_YEAR_DISPLAY = dayjs().get("month") >= 11 ? dayjs().get("year") : dayjs().subtract(1, "year").get("year");

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to={"/successor"}
          >
            {t("All")} Successor
          </Link>
          <Typography color="text.primary">{t("Details")} Successor</Typography>
        </Breadcrumbs>
        <div style={{ marginBottom: 8, marginTop: 10 }}>
          <Typography variant="h4">
            {t("Details")} Successor
          </Typography>
        </div>

        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
          }}
        >
          <Grid item xs={12} md={7}>
            <CardStyle style={{ marginTop: "5vh" }}>
              <StyledDetailCard>
                <StyledLeft>

                  <div className="header-profile">
                    <Avatar variant="circle" className={"empImage"}></Avatar>
                    <Typography className="empHeadingText">
                      {employeeData && getUserFullName(employeeData)}
                    </Typography>
                    <Typography className="jopHeadingText">
                      {employeeData && getUserPosition(employeeData)}
                    </Typography>
                  </div>

                  <Divider></Divider>

                  <Grid container spacing={2} className="content-profile">
                    {/* First Row */}
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Typography className="text-topic">{t("Company")}</Typography>
                        <Typography className="text-content">{employeeData && getUserCompany(employeeData)}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className="text-topic">{t("Division")}</Typography>
                        <Typography className="text-content">{employeeData && getUserDivision(employeeData)}</Typography>
                      </Grid>
                    </Grid>

                    {/* Second Row */}
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Typography className="text-topic">
                          {t("Department")}
                        </Typography>
                        <Typography className="text-content">{employeeData && getUserDepartment(employeeData)}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className="text-topic">{t("Section")}</Typography>
                        <Typography className="text-content">
                          {employeeData && getUserSection(employeeData)}
                        </Typography>
                      </Grid>
                    </Grid>

                    {/* Third Row */}
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Typography className="text-topic">{t("ESY")}</Typography>
                        <Typography className="text-content">
                          {employeeData && employeeData.hiringDate
                            ? `${utils.getAgeMonthYear(t,
                              dayjs(employeeData.hiringDate).format("DD/MM/YYYY"),
                              i18n.resolvedLanguage
                            )}`
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className="text-topic">
                          {t("ServiceYear")}
                        </Typography>
                        <Typography className="text-content">
                          {employeeData && employeeData.hiringDate
                            ? `${utils.getAgeMonthYear(t,
                              dayjs(employeeData.hiringDate).format("DD/MM/YYYY"),
                              i18n.resolvedLanguage
                            )}`
                            : "-"}
                        </Typography>
                      </Grid>
                    </Grid>

                    {/* Fourth Row */}
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Typography className="text-topic">{t("TIG")}</Typography>
                        <Typography className="text-content">
                          {employeeData && employeeData.employeePersonnelLevelStart
                            ? `${utils.getAgeMonthYear(t,
                              dayjs(employeeData.employeePersonnelLevelStart).format("DD/MM/YYYY"),
                              i18n.resolvedLanguage
                            )}`
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className="text-topic">{t("TIP")}</Typography>
                        <Typography className="text-content">
                          {employeeData && employeeData.start
                            ? `${utils.getAgeMonthYear(t,
                              dayjs(employeeData.start).format("DD/MM/YYYY"),
                              i18n.resolvedLanguage
                            )}`
                            : "-"}
                        </Typography>
                      </Grid>
                    </Grid>

                    {/* Fifth Row */}
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Typography className="text-topic">{t("Age")}</Typography>
                        <Typography className="text-content">
                          {employeeData && employeeData.birthday
                            ? `${utils.getAgeMonthYear(t,
                              dayjs(employeeData.birthday).format("DD/MM/YYYY"),
                              i18n.resolvedLanguage
                            )}`
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}></Grid>
                    </Grid>
                  </Grid>
                  <Divider></Divider>
                  <div className="content-profile">
                    <Typography className="text-topic">{t("Experience")}</Typography>
                    <Typography className="text-content"> {employeeData && employeeData.start ? (
                      `[${formatDate(employeeData.start)} - ${employeeData.end ? formatDate(employeeData.end) : t("Current")}]`
                    ) : "[-]"} {employeeData && getUserPosition(employeeData)} </Typography>

                    <Grid container>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <span className="textTopic">{t("Section")} : </span>
                          <span className="textContent"> {employeeData && getUserSection(employeeData)} </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <span className="textTopic">{t("Agency")} : </span>
                          <span className="textContent"> {employeeData && getUserDepartment(employeeData)} </span>
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <span className="textTopic">{t("Division")} : </span>
                          <span className="textContent"> {employeeData && getUserDivision(employeeData)} </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <span className="textTopic">BU : </span>
                          <span className="textContent"> {employeeData && getUserBusinessUnit(employeeData)} </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>

                  <div className="content-profile">
                    <Typography className="text-topic">{t("Education")}</Typography>
                    <Typography className="text-content">{employeeData && employeeData.degree ? employeeData.degree : "-"} {employeeData && employeeData.university ? employeeData.university : ""}</Typography>
                  </div>

                </StyledLeft>
              </StyledDetailCard>
            </CardStyle>
          </Grid>

          <Grid item xs={12} md={5}>
            <div
              style={{
                minHeight: 1207,
                padding: 20,
                borderRadius: "0 0 20px 20px",
                position: "relative",
                marginTop: "2vh",
              }}
            >
              <Stack spacing={2}>
                {successorRole && successorRole.map((item, index) => {
                  const findTheLastTalent = item && item.talent && item.talent.find(talent => talent.year === CURRENT_YEAR_DISPLAY);

                  return (
                    <Paper
                      style={{
                        minHeight: "auto",
                        padding: 10,
                        borderRadius: 20
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          minHeight: "auto",
                          padding: 10,
                          borderRadius: 20,
                          marginTop: 20,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <StyledRight>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className="number-successor">Successor {index + 1}</div>
                            <div className="icon-header">
                              <DeleteIcon
                                className="delete-icon"
                                style={{ color: "#e24668" }}
                                onClick={() => onDelete(item && item.idEmployees, item.idEmployeePosition)}
                              />
                              <SearchIcon
                                onClick={() => handleOpenDialog2(item && item.idEmployees)}
                              />
                            </div>
                          </div>
                        </StyledRight>

                        <Box sx={{ p: 2 }}>
                          <div className="header-profile">
                            <Grid container spacing={2} justifyContent={"space-around"} alignItems={"center"} sx={{ p: 2 }}>
                              <Stack>
                                <Avatar
                                  variant="circle"
                                  className={"empImage"}
                                  sx={{ width: 56, height: 56 }}
                                // src="/static/images/avatar/1.jpg"
                                />
                              </Stack>
                              <Stack spacing={1}>
                                <Typography className="empHeadingText">
                                  {item && getUserFullName(item)}
                                </Typography>
                                <Typography className="empPositionText">
                                  {item && getUserPosition(item)}
                                </Typography>
                                <Grid>
                                  <Box
                                    p={0.5}
                                    border={2}
                                    borderRadius={1}
                                    borderColor={item && item.ready === "พร้อมทันที" ? "#4caf50" : item && item.ready === "พร้อมภายใน 1 ปี" ? "#ffc400" : "#ff1744"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "1rem",
                                      }}
                                      textAlign={"center"}
                                    >
                                      {item && item.ready}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Stack>
                            </Grid>
                          </div>
                          <Divider />
                          <Grid container spacing={2} className="content-profile" sx={{ p: 1 }}>
                            {/* First Row */}
                            <Grid item>
                              <Typography variant="body1">
                                <span className="textTopic">{t("Company")} : </span>
                                <span className="textContent"> {item && getUserCompany(item)}</span>
                              </Typography>

                              <Typography variant="body1" sx={{ mt: 2 }}>
                                <span className="textTopic">{t("Division")} : </span>
                                <span className="textContent"> {item && getUserDivision(item)}</span>
                              </Typography>

                              <Typography variant="body1" sx={{ mt: 2 }}>
                                <span className="textTopic">{t("Department")} : </span>
                                <span className="textContent"> {item && getUserDepartment(item)}</span>
                              </Typography>

                              <Typography variant="body1" sx={{ mt: 2 }}>
                                <span className="textTopic">{t("Section")} : </span>
                                <span className="textContent"> {item && getUserSection(item)}</span>
                              </Typography>

                              <Typography variant="body1" sx={{ mt: 2 }}>
                                <span className="textTopic">{t("JobGroup")} : </span>
                                <span className="textContent"> {item && item.jobLevel && item.jobLevel.jobGroupName || "-"}</span>
                              </Typography>

                              <Typography variant="body1" sx={{ mt: 2 }}>
                                <span className="textTopic">{t("JobLevel")} : </span>
                                <span className="textContent"> {item && item.jobLevel && item.jobLevel.levelName || "-"}</span>
                              </Typography>

                              <Typography variant="body1" sx={{ mt: 2 }}>
                                <span className="textTopic">{t("personalLevel")} : </span>
                                <span className="textContent"> {item && item.personalLevel && item.personalLevel.levelName || "-"}</span>
                              </Typography>
                            </Grid>

                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <span className="textTopic">{t("Performance")} : </span>
                                <span className="textContent">
                                  {Array.from({ length: 3 }, (_, i) => {
                                    const year = CURRENT_YEAR_DISPLAY - i;
                                    const performanceForYear = item.performance && item.performance.find(
                                      (performance) => performance.yearPerformance === year
                                    );
                                    return (
                                      <>
                                        {performanceForYear ? (
                                          <>
                                            {performanceForYear.name + " "}
                                          </>
                                        ) : null}
                                      </>
                                    );
                                  })}
                                </span>
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <span className="textTopic">{t("Potential")} : </span>
                                <span className="textContent">
                                  {Array.from({ length: 3 }, (_, i) => {
                                    const year = CURRENT_YEAR_DISPLAY - i;
                                    const potentialYear = item.potential && item.potential.find(
                                      (potential) => potential.year === year
                                    );
                                    return (
                                      <>
                                        {potentialYear ? (
                                          <>
                                            {(
                                              potentialYear.Level === "High" ? "H" :
                                                potentialYear.Level === "Normal" ? "N" :
                                                  potentialYear.Level === "Normal-Low" ? "NL" : "L") + " "}
                                          </>
                                        ) : null}
                                      </>
                                    );
                                  })}
                                </span>

                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                          <StyledFooter>
                            <Grid
                              container
                              spacing={2}
                              alignItems={"center"}
                              justifyContent={"center"}
                              className="footer-profile"
                            >
                              {/* First Row */}
                              {/* <Grid item xs={6}>
                                  <Typography className="footer-text-topic">
                                    Talent Score
                                  </Typography>
                                  <TalentScoreChart />
                                </Grid> */}
                              <Grid item xs={6}>
                                <Typography className="footer-text-topic">
                                  {t("talentResult")}
                                </Typography>
                                <Stack
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  spacing={2}
                                  direction={"row"}
                                >
                                  {/* {item && item.talent && item.talent.map((talent) => (
                                      <Box className="styleBox" p={2}>
                                        <Typography className="text-box">
                                          {talent.talentName}
                                        </Typography>
                                      </Box>
                                    ))} */}
                                  <Box
                                    className="styleBox"
                                    sx={{
                                      p: 2,
                                      bgcolor: findTheLastTalent && findTheLastTalent.talentName ? colorMapping[findTheLastTalent.talentName] : 'transparent'
                                    }}
                                  >
                                    <Typography className="text-box">
                                      {findTheLastTalent && findTheLastTalent.talentName ? findTheLastTalent.talentName : "-"}
                                    </Typography>
                                  </Box>
                                </Stack>
                              </Grid>
                            </Grid>
                          </StyledFooter>
                        </Box>
                      </div>
                    </Paper>
                  )
                })}

                {successorRole && successorRole.length < 3 && (
                  <div>
                    <Paper
                      style={{
                        minHeight: "auto",
                        padding: 10,
                        borderRadius: 20
                      }}
                    >
                      <Typography fontSize={18} fontWeight={"bold"} sx={{ p: 2 }}>
                        {t("Add")} Successor
                      </Typography>
                      <Stack justifyContent={"center"} alignItems={"center"}>
                        <AddCircleIcon
                          style={{
                            fontSize: '80px',
                            padding: '10px',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignSelf: 'center',
                            color: '#46e2ba',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleOpenDialog()}
                        />
                      </Stack>
                    </Paper>
                  </div>
                )}
              </Stack>

              <DialogSuccessor
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                idEmployeePosition={employeeData && employeeData.idEmployeePosition ? employeeData.idEmployeePosition : null}
                bg={colorMapping}
                checkedYear={CURRENT_YEAR_DISPLAY}
              />

              <DialogSuccessorById
                open={openDialog2}
                onClose={() => setOpenDialog2(false)}
                idEmployees={dialog2IdEmployees}
              />

            </div>
          </Grid >
        </Grid >
      </Container >
    </StyledRoot >
  );
};

export default DetailPage;



