import kpiService from "../services/kpi.service";
import {
  GET_KPI_ID_FETCHING,
  GET_KPI_ID_FAILED,
  GET_KPI_ID_SUCCESS,
  KPI_FETCHING,
  KPI_FAILED,
  KPI_SUCCESS,
  KPI_APPROVAL_FETCHING,
  KPI_APPROVAL_SUCCESS,
  KPI_APPROVAL_FAILED,
  GET_KPI_UPDATE_FETCHING,
  GET_KPI_UPDATE_SUCCESS,
  GET_KPI_UPDATE_FAILED,
  GET_KPI_UPDATE_ID_FETCHING,
  GET_KPI_UPDATE_ID_FAILED,
  GET_KPI_UPDATE_ID_SUCCESS,
  KPI_APPROVAL_RESULT_FETCHING,
  KPI_APPROVAL_RESULT_SUCCESS,
  KPI_APPROVAL_RESULT_FAILED,
  KPI_EVALUATION_FETCHING,
  KPI_EVALUATION_SUCCESS,
  KPI_EVALUATION_FAILED,
  KPI_CARLIBRATE_FETCHING,
  KPI_CARLIBRATE_SUCCESS,
  KPI_CARLIBRATE_FAILED,
  KPI_DEPARTMENTCARLIBRATE_FETCHING,
  KPI_DEPARTMENTCARLIBRATE_SUCCESS,
  KPI_DEPARTMENTCARLIBRATE_FAILED,
  KPI_IsSuccessful_FETCHING,
  KPI_IsSuccessful_SUCCESS,
  KPI_IsSuccessful_FAILED,
  KPI_UpdateAllAchievement_ID_FETCHING,
  KPI_UpdateAllAchievement_ID_SUCCESS,
  KPI_UpdateAllAchievement_ID_FAILED,
  KPI_All_FETCHING,
  KPI_All_SUCCESS,
  KPI_All_FAILED,
  KPI_ORGANIZATION_FETCHING,
  KPI_ORGANIZATION_FAILED,
  KPI_ORGANIZATION_SUCCESS,
  KPI_TEAM_FETCHING,
  KPI_TEAM_FAILED,
  KPI_TEAM_SUCCESS,
  KPI_DATE_FETCHING,
  KPI_DATE_FAILED,
  KPI_DATE_SUCCESS,
} from "./types";

export const getKPI = (query) => async (dispatch) => {
  try {
    dispatch({ type: KPI_FETCHING });
    const res = await kpiService.getKPI(query);
    if (res) {
      dispatch({
        type: KPI_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_FAILED,
    });
  }
};

export const getKPIManagerByidEmployee = (query) => async (dispatch) => {
  try {
    dispatch({ type: KPI_FETCHING });
    const res = await kpiService.getKPIManagerByidEmployee(query);
    if (res) {
      dispatch({
        type: KPI_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_FAILED,
      payload: err.message,
    });
  }
};

export const addKPI = (formData) => async () => {
  try {
    const res = await kpiService.addKPI(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const addManagerKPI = (formData) => async () => {
  try {
    const res = await kpiService.addManagerKPI(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateKPI = (idKPI, formData) => async () => {
  try {
    const res = await kpiService.updateKPI(idKPI, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};
export const updateManagerKPI = (idKPI, formData) => async () => {
  try {
    const res = await kpiService.updateManagerKPI(idKPI, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const deleteKPI = (idKPI) => async () => {
  try {
    const res = await kpiService.deleteKPI(idKPI);
    if (res) {
      return res;
    }
  } catch (error) {
    const massage =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateKPISubmit = (idKPI) => async () => {
  try {
    const res = await kpiService.updateKPISubmit(idKPI);
    if (res) {
      return res;
    }
  } catch (error) {
    const massage =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getKpiByid = (idKPI) => async (dispatch) => {
  try {
    dispatch({ type: GET_KPI_ID_FETCHING });
    const res = await kpiService.getKpiById(idKPI);
    if (res) {
      dispatch({
        type: GET_KPI_ID_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_KPI_ID_FAILED,
    });
  }
};

export const getAllKpiApprove = () => async (dispatch) => {
  try {
    dispatch({ type: KPI_APPROVAL_FETCHING });
    const res = await kpiService.getAllKpiApprove();
    if (res) {
      dispatch({
        type: KPI_APPROVAL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_APPROVAL_FAILED,
    });
  }
};

export const getKpiPlan = () => async (dispatch) => {
  try {
    dispatch({ type: KPI_APPROVAL_FETCHING });
    const res = await kpiService.getKpiPlan();
    if (res) {
      dispatch({
        type: KPI_APPROVAL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_APPROVAL_FAILED,
    });
  }
};

export const updateKPIApprove = async (data) => {
  try {
    const res = await kpiService.updateKPIApprove(data);

    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error(
        `Failed to update KPI approval. Server responded with status: ${res.status}`
      );
    }
  } catch (error) {
    console.error("Error updating KPI approval:", error);
    return "Error";
  }
};

export const updateKPIplanApprove = async (data) => {
  try {
    const res = await kpiService.updateKPIplanApprove(data);

    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error(
        `Failed to update KPI approval. Server responded with status: ${res.status}`
      );
    }
  } catch (error) {
    console.error("Error updating KPI approval:", error);
    return "Error";
  }
};

export const getKpiApprove = () => async (dispatch) => {
  try {
    dispatch({ type: KPI_APPROVAL_RESULT_FETCHING });
    const res = await kpiService.getKpiApprove();
    if (res) {
      dispatch({
        type: KPI_APPROVAL_RESULT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_APPROVAL_RESULT_FAILED,
    });
  }
};

export const getKpiUpdate = () => async (dispatch) => {
  try {
    dispatch({ type: GET_KPI_UPDATE_FETCHING });
    const res = await kpiService.getKpiUpdate();
    if (res) {
      dispatch({
        type: GET_KPI_UPDATE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_KPI_UPDATE_FAILED,
    });
  }
};

export const getKpiUpdateAndPlan = (query) => async (dispatch) => {
  try {
    dispatch({ type: GET_KPI_UPDATE_FETCHING });
    const res = await kpiService.getKpiUpdateAndPlan(query);
    if (res) {
      dispatch({
        type: GET_KPI_UPDATE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_KPI_UPDATE_FAILED,
    });
  }
};

export const getKpiUpdateProgress = () => async (dispatch) => {
  try {
    dispatch({ type: GET_KPI_UPDATE_FETCHING });
    const res = await kpiService.getKpiUpdateProgress();
    if (res) {
      dispatch({
        type: GET_KPI_UPDATE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_KPI_UPDATE_FAILED,
    });
  }
};

export const getKpiOrganizationCascadeUpdatePlan = (query) => async (dispatch) => {
  try {
    dispatch({ type: KPI_ORGANIZATION_FETCHING });
    const res = await kpiService.getKpiOrganizationCascadeUpdatePlan(query);
    if (res) {
      dispatch({
        type: KPI_ORGANIZATION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_ORGANIZATION_FAILED,
    });
  }
};

export const getKpiUpdateById = (idKPI) => async (dispatch) => {
  try {
    dispatch({ type: GET_KPI_UPDATE_ID_FETCHING });
    const res = await kpiService.getKpiUpdateById(idKPI);
    if (res) {
      dispatch({
        type: GET_KPI_UPDATE_ID_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_KPI_UPDATE_ID_FAILED,
    });
  }
};

export const addKpiProgress = (formData) => async () => {
  try {
    const res = await kpiService.addKpiProgress(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const addKpiProgressbar = (formData) => async () => {
  try {
    const res = await kpiService.addKpiProgressbar(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const addKpiPlan = (formData) => async () => {
  try {
    const res = await kpiService.addKpiPlan(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getKpiEvaluation = () => async (dispatch) => {
  try {
    dispatch({ type: KPI_EVALUATION_FETCHING });
    const res = await kpiService.getKpiEvaluation();
    if (res) {
      dispatch({
        type: KPI_EVALUATION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_EVALUATION_FAILED,
    });
  }
};

export const updateKPIEvaluationManager = (idKPI, formData) => async () => {
  try {
    const res = await kpiService.updateKPIEvaluationManager(idKPI, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateKPIEvaluationEmployee = (idKPI, formData) => async () => {
  try {
    const res = await kpiService.updateKPIEvaluationEmployee(idKPI, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getKpiCarlibrate = () => async (dispatch) => {
  try {
    dispatch({ type: KPI_CARLIBRATE_FETCHING });
    const res = await kpiService.getKpiCarlibrate();
    if (res) {
      dispatch({
        type: KPI_CARLIBRATE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_CARLIBRATE_FAILED,
    });
  }
};

export const addKpiGrade = (formData) => async () => {
  try {
    const res = await kpiService.addKpiGrade(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateKpiGrade = async (formData) => {
  try {
    const res = await kpiService.updateKpiGrade(formData);
    if (res) {
      return res;
    } else {
      throw new Error("Failed to update KPI grade.");
    }
  } catch (error) {
    throw error;
  }
};

export const getKpiCalibrateDepartment = () => async (dispatch) => {
  try {
    dispatch({ type: KPI_DEPARTMENTCARLIBRATE_FETCHING });
    const res = await kpiService.getKpiCalibrateDepartment();
    if (res) {
      dispatch({
        type: KPI_DEPARTMENTCARLIBRATE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_DEPARTMENTCARLIBRATE_FAILED,
    });
  }
};

export const getKpiSuccessful = (query) => async (dispatch) => {
  try {
    dispatch({ type: KPI_IsSuccessful_FETCHING });
    const res = await kpiService.getKpiSuccessful(query);
    if (res) {
      dispatch({
        type: KPI_IsSuccessful_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_IsSuccessful_FAILED,
    });
  }
};

export const getKpiIsSuccessfulManager = () => async (dispatch) => {
  try {
    dispatch({ type: KPI_IsSuccessful_FETCHING });
    const res = await kpiService.getKpiIsSuccessfulManager();
    if (res) {
      dispatch({
        type: KPI_IsSuccessful_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_IsSuccessful_FAILED,
    });
  }
};

export const getKpiUpdateAllAchievementById = () => async (dispatch) => {
  try {
    dispatch({
      type: KPI_UpdateAllAchievement_ID_FETCHING
    });
    const res = await kpiService.getKpiUpdateAllAchievementById();
    if (res) {
      dispatch({
        type: KPI_UpdateAllAchievement_ID_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_UpdateAllAchievement_ID_FAILED,
    });
  }
};

export const getKpiAll = () => async (dispatch) => {
  try {
    dispatch({
      type: KPI_All_FETCHING,
    });
    const res = await kpiService.getKpiAll();
    if (res) {
      dispatch({
        type: KPI_All_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_All_FAILED,
    });
  }
};

export const uploadExcelKpi = async (formData) => {
  try {
    let res = await kpiService.uploadExcelKpi(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    if (err.response) {
      return err.response.data;
    }
  }
};

export const uploadExcelKpiGrade = async (formData) => {
  try {
    let res = await kpiService.uploadExcelKpiGrade(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    if (err.response) {
      return err.response.data;
    }
  }
};

export const putKpiIsSubmitById = async (idKPI) => {
  try {
    const res = await kpiService.putKpiIsSubmitById(idKPI);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getKPIByManager = () => async (dispatch) => {
  try {
    dispatch({ type: KPI_FETCHING });
    const res = await kpiService.getKPIByManager();
    if (res) {
      dispatch({
        type: KPI_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_FAILED,
    });
  }
};

export const getKPIByAdmin = (query) => async (dispatch) => {
  try {
    dispatch({ type: KPI_FETCHING });
    const res = await kpiService.getKPIByAdmin(query);
    if (res) {
      dispatch({
        type: KPI_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_FAILED,
    });
  }
};

export const addKPIDate = (formData) => async (dispatch) => {
  try {
    const res = await kpiService.addKPIDate(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.error("Error:", message);
    return { error: message };
  }
};

export const getKPIDate = (query) => async (dispatch) => {
  try {
    dispatch({ type: KPI_DATE_FETCHING });
    const res = await kpiService.getKPIDate(query);
    if (res) {
      dispatch({
        type: KPI_DATE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_DATE_FAILED,
    });
  }
};

export const deleteKPIDate = (idKpiEditDate) => async () => {
  try {
    const res = await kpiService.deleteKPIDate(idKpiEditDate);
    if (res) {
      return res;
    }
  } catch (error) {
    const massage =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateKPIDate = (idKpiEditDate, formData) => async (dispatch) => {
  try {
    const res = await kpiService.updateKPIDate(idKpiEditDate, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getKpiOrganization = (query) => async (dispatch) => {
  try {
    dispatch({ type: KPI_ORGANIZATION_FETCHING });
    const res = await kpiService.getKpiOrganization(query);
    if (res) {
      dispatch({
        type: KPI_ORGANIZATION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_ORGANIZATION_FAILED,
    });
  }
};

export const addKpiOrganization = (formData) => async (dispatch) => {
  try {
    const res = await kpiService.addKpiOrganization(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: KPI_ORGANIZATION_FAILED,
    });
  }
};

export const updateKpiOrganization = (formData) => async (dispatch) => {
  try {
    const res = await kpiService.updateKpiOrganization(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: KPI_ORGANIZATION_FAILED,
    });
  }
};

export const deleteKpiOrganization = (id) => async (dispatch) => {
  try {
    const res = await kpiService.deleteKpiOrganization(id);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: KPI_ORGANIZATION_FAILED,
    });
  }
};

export const getKpiOrganizationCascade = (idKpiOrganization) => async (dispatch) => {
  try {
    dispatch({ type: KPI_FETCHING });
    const res = await kpiService.getKpiOrganizationCascade(idKpiOrganization);
    if (res) {
      dispatch({
        type: KPI_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_FAILED,
    });
  }
};

export const addKpiOrganizationCascade = (formData) => async (dispatch) => {
  try {
    const res = await kpiService.addKpiOrganizationCascade(formData);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: KPI_FAILED,
    });
  }
};

export const deleteKpiOrganizationCascade = (idKpiOrganizationEmployees) => async (dispatch) => {
  try {
    const res = await kpiService.deleteKpiOrganizationCascade(idKpiOrganizationEmployees);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: KPI_FAILED,
    });
  }
};

export const getKpiOrganizationProgressById = (idKpiOrganization) => async (dispatch) => {
  try {
    dispatch({ type: GET_KPI_UPDATE_ID_FETCHING });
    const res = await kpiService.getKpiOrganizationProgressById(idKpiOrganization);
    if (res) {
      dispatch({
        type: GET_KPI_UPDATE_ID_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_KPI_UPDATE_ID_FAILED,
    });
  }
};

export const addKpiOrganizationProgress = (formData) => async (dispatch) => {
  try {
    const res = await kpiService.addKpiOrganizationProgress(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: GET_KPI_UPDATE_ID_FAILED,
    });
  }
};

export const updateKpiOrganizationProgressPercent = (formData) => async (dispatch) => {
  try {
    const res = await kpiService.updateKpiOrganizationProgressPercent(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: GET_KPI_UPDATE_ID_FAILED,
    });
  }
};

export const updateKpiOrganizationEvaluate = (formData) => async (dispatch) => {
  try {
    const res = await kpiService.updateKpiOrganizationEvaluate(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const massage =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getKpiEditDate = (query) => async (dispatch) => {
  try {
    dispatch({ type: KPI_DATE_FETCHING });
    const res = await kpiService.getKpiEditDate(query);
    if (res) {
      dispatch({
        type: KPI_DATE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_DATE_FAILED,
    });
  }
};

export const getKpiTeamByCompany = (query) => async (dispatch) => {
  try {
    dispatch({ type: KPI_TEAM_FETCHING });
    const res = await kpiService.getKpiTeamByCompany(query);
    if (res) {
      dispatch({
        type: KPI_TEAM_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_TEAM_FAILED,
    });
  }
};

export const getKpiTeam = (query) => async (dispatch) => {
  try {
    dispatch({ type: KPI_TEAM_FETCHING });
    const res = await kpiService.getKpiTeam(query);
    if (res) {
      dispatch({
        type: KPI_TEAM_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_TEAM_FAILED,
    });
  }
};

export const addKpiTeam = (formData) => async (dispatch) => {
  try {
    const res = await kpiService.addKpiTeam(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: KPI_TEAM_FAILED,
    });
  }
};

export const updateKpiTeam = (formData) => async (dispatch) => {
  try {
    const res = await kpiService.updateKpiTeam(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: KPI_TEAM_FAILED,
    });
  }
};

export const deleteKpiTeam = (id) => async (dispatch) => {
  try {
    const res = await kpiService.deleteKpiTeam(id);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: KPI_TEAM_FAILED,
    });
  }
};

export const getKpiTeamProgressById = (idKpiTeam) => async (dispatch) => {
  try {
    dispatch({ type: GET_KPI_UPDATE_ID_FETCHING });
    const res = await kpiService.getKpiTeamProgressById(idKpiTeam);
    if (res) {
      dispatch({
        type: GET_KPI_UPDATE_ID_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_KPI_UPDATE_ID_FAILED,
    });
  }
};

export const addKpiTeamProgress = (formData) => async (dispatch) => {
  try {
    const res = await kpiService.addKpiTeamProgress(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: GET_KPI_UPDATE_ID_FAILED,
    });
  }
};

export const updateKpiTeamProgressPercent = (formData) => async (dispatch) => {
  try {
    const res = await kpiService.updateKpiTeamProgressPercent(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: GET_KPI_UPDATE_ID_FAILED,
    });
  }
};

export const updateKpiTeamEvaluate = (formData) => async (dispatch) => {
  try {
    const res = await kpiService.updateKpiTeamEvaluate(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const massage =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getKpiPendingApprove = (query) => async (dispatch) => {
  try {
    dispatch({ type: KPI_APPROVAL_FETCHING });
    const res = await kpiService.getKpiPendingApprove(query);
    if (res) {
      dispatch({
        type: KPI_APPROVAL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_APPROVAL_FAILED,
    });
  }
};

export const getKpiPendingEvaluation = (query) => async (dispatch) => {
  try {
    dispatch({ type: KPI_APPROVAL_FETCHING });
    const res = await kpiService.getKpiPendingEvaluation(query);
    if (res) {
      dispatch({
        type: KPI_APPROVAL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: KPI_APPROVAL_FAILED,
    });
  }
};
