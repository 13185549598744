import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
    Typography,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    Paper,
    TableBody,
    TableCell,
    Grid,
    Box,
    Divider,
    IconButton
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import CardStyle from "../../../shared/general/Card";
import { useTranslation } from "react-i18next";
import SelectAffiliateCompany from "../../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../../actions/company";
import { Link } from "react-router-dom";
import { getWarningLetterTemplate, deleteWarningLetterTemplate } from "../../../../../actions/warningLetter";
import DialogCreate from "./dialogCreate";
import Swal from "sweetalert2";

const StyledRoot = styled("div")({
    backgroundColor: "#f5f5f5",
});

const WrapHeader = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ["@media only screen and (max-width:600px)"]: {
        flexDirection: "column",
    },
    marginTop: 8,
    marginBottom: 16,
});

const WarningLetterTemplate = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [companySelected, setCompanySelected] = useState(null);
    const [selectLetter, setSelectLetter] = useState(null);

    const { result: affiliateOrganization } = useSelector((state) => state.affiliateOrganization);
    const { result: warningLetterTemplate } = useSelector((state) => state.warningLetterTemplate);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectLetter(null);
    };

    const handleChangeCompanySelected = (event, value) => {
        setCompanySelected(value);
    };

    const handleEdit = (item) => {
        setSelectLetter(item);
        setOpen(true);
    };

    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: t("ConfirmDelete"),
            text: t("AreYouSure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: t("Yes"),
            cancelButtonText: t("No"),
        });

        if (result.isConfirmed) {
            await dispatch(deleteWarningLetterTemplate(id));

            Swal.fire({
                title: t("Success"),
                text: t("DeletedSuccessfully"),
                icon: "success",
                confirmButtonText: t("OK"),
            });
        }
        await dispatch(getWarningLetterTemplate(companySelected.idCompany));
    };

    useEffect(async () => {
        await dispatch(getAffiliateOrganization());
    }, []);

    useEffect(() => {
        if (affiliateOrganization && affiliateOrganization.length > 0) {
            setCompanySelected(affiliateOrganization[0]);
        }
    }, [affiliateOrganization]);

    useEffect(async () => {
        if (companySelected) {
            await dispatch(getWarningLetterTemplate(companySelected.idCompany));
        }
    }, [companySelected]);

    useEffect(() => {
        if (companySelected && !open) {
            dispatch(getWarningLetterTemplate(companySelected.idCompany));
        }
    }, [open]);


    return (
        <StyledRoot className={"page"}>
            <Container maxWidth="lg">
                <WrapHeader>
                    <Typography variant="h4">{t("WarningLetter")}</Typography>
                    <ButtonBlue
                        variant="contained"
                        color="error"
                        style={{ marginLeft: 8 }}
                        component={Link}
                        to="/warning-letter"
                    >
                        {t('Back')}
                    </ButtonBlue>
                </WrapHeader>
                <CardStyle style={{ padding: 16 }}>
                    <Grid container spacing={1} marginBottom={3} display={'flex'} justifyContent={'space-between'} alignItems={'end'}>
                        <Grid item xs={12} sm={6} marginTop={"26px"}>
                            <Typography
                                fontSize="14px"
                                fontWeight="600"
                                marginBottom="4px"
                                color="text.third"
                            >
                                {t("Company")}
                            </Typography>
                            <SelectAffiliateCompany
                                options={affiliateOrganization}
                                value={companySelected}
                                onChange={handleChangeCompanySelected}
                            />
                        </Grid>
                        <ButtonBlue variant="contained" startIcon={<AddIcon />} onClick={handleClickOpen}>
                            {t("AddItem")}
                        </ButtonBlue>
                    </Grid>

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ flex: 4 }}>{t("Name")}</TableCell>
                                    <TableCell sx={{ flex: 6 }}>{t("Details")}</TableCell>
                                    <TableCell sx={{ minWidth: '80px', maxWidth: '100px' }}>{t("File")}</TableCell>
                                    <TableCell sx={{ minWidth: '80px', maxWidth: '100px' }}>{t("Action")}</TableCell>
                                </TableRow>
                            </TableHead>
                            {warningLetterTemplate && warningLetterTemplate.length > 0 &&
                                <TableBody>
                                    {warningLetterTemplate && warningLetterTemplate.length > 0 && warningLetterTemplate.map((item, index) => (
                                        <TableRow key={`WarningLetterTemplate-${index}`}>
                                            <TableCell display={'flex'} sx={{ flex: 4 }}>{item.name}</TableCell>
                                            <TableCell display={'flex'} sx={{ flex: 6 }}>{item.detail}</TableCell>
                                            <TableCell sx={{ maxWidth: '10px' }}>

                                                <ButtonBlue variant="outlined" component={Link} to={`/warning-letter-template/setting/${item.idWarningLetterTemplateHtml}`}>
                                                    {t("View")} {item.idCompany && `/ ${t("Edit")}`}
                                                </ButtonBlue>
                                            </TableCell>
                                            <TableCell sx={{ maxWidth: '10px' }}>
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => handleEdit(item)}
                                                    disabled={item.idCompany === null}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    color="error"
                                                    onClick={() => handleDelete(item.idWarningLetterTemplate)}
                                                    disabled={item.idCompany === null}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            }
                        </Table>
                    </TableContainer>
                </CardStyle>
            </Container>
            <DialogCreate open={open} handleClose={handleClose} data={selectLetter} idCompany={companySelected && companySelected.idCompany} />
        </StyledRoot>
    );
};

export default WarningLetterTemplate;
