import React, { Fragment, useEffect, useState } from 'react';
import { Avatar, Box, Breadcrumbs, Container, Grid, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useHistory } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import loading from '../../../assets/social-media.gif';
import { useDispatch, useSelector } from 'react-redux';
import { getAffiliateOrganization } from '../../../../../actions/company';
import { getSummaryDevelopmentTalentPlan } from '../../../../../actions/employee';
import SelectAffiliateCompany from '../../../shared/general/SelectAffiliateCompany';
import { getUserFullName, getUserPosition } from '../../../../../utils/userData';
import SelectEmployees from '../../../shared/general/selectEmployees';

const StyledRoot = styled("div")({
    backgroundColor: "#FFFFFF !important",
});

const StyledBox = styled(Box)({
    "&.open": {
        paddingLeft: 16,
        width: "calc(100% - 1000px)",
    },
    "&.close": {
        paddingLeft: 0,
        width: "100%",
    },
});

const StyledCellHeader = styled(TableCell)({
    borderBottom: "1px dashed rgba(224, 224, 224, 1)",
    color: "#637381",
    backgroundColor: "#f4f6f8",
    whiteSpace: "nowrap",
    "& .MuiTypography-root": {
        color: "#637381",
        backgroundColor: "#f4f6f8",
        "&.weekend": {
            fontStyle: "oblique",
        },
        "&.workday": {
            fontWeight: 600,
        },
    },
    "&.sticky": {
        padding: 0,
        position: "sticky",
        left: 0,
        zIndex: 4,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        boxShadow: "20px 0px 20px #EEEEEE",
        "& .MuiTableCell-root": {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
        },
    },
    "&:last-of-type": {
        paddingRight: 24,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
});

const StyledRowContent = styled(TableRow)({
    cursor: "pointer",
    textDecoration: "none",
    height: "57px",
    "&.MuiTableRow-hover:hover": {
        backgroundColor: "#f7f7f7",
        "& .sticky": {
            backgroundColor: "#f7f7f7",
        },
    },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
    borderBottom: "1px dashed rgba(224, 224, 224, 1)",
    padding: 8,
    whiteSpace: "nowrap",
    "&.sticky": {
        position: "sticky",
        left: 0,
        backgroundColor: "#FFFFFF",
        boxShadow: "8px 20px 20px #EEEEEE",
    },
    "& .avatar": {
        minWidth: 240,
        "& .MuiAvatar-root": {
            marginRight: 8,
        },
        display: "flex",
        alignItems: "center",
    },
    "& .fullname": {
        fontWeight: 600,
    },
    "& .position": {
        color: theme.palette.text.secondary,
        fontSize: 14,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: 300,
    },
    "& .secondaryAction": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .location": {
            fontWeight: 600,
        },
    },
    "&:first-of-type": {
        paddingLeft: 0,
    },
    "&:last-of-type": {
        paddingRight: 24,
    },
}));

export default function SummaryDevelopmentPlan() {
    const history = useHistory();
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const { year } = location.state;
    const [isFetching, setIsFetching] = useState(false);
    const [summaryData, setSummaryData] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);

    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const columns = [
        { id: "JobPosition", label: t("Position"), minWidth: 170 },
        { id: "PlanName", label: t("planName"), minWidth: 170 },
        { id: "StartDate", label: t("StartDate"), minWidth: 170 },
        { id: "EndDate", label: t("EndDate"), minWidth: 170 },
        { id: "Assessor", label: t("Assessor"), minWidth: 170 },
        { id: "Status", label: t("Status"), minWidth: 170 },
        // { id: "Department", label: t("Department"), minWidth: 170 },
        // { id: "Section", label: t("Section"), minWidth: 100 },
    ];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        dispatch(getAffiliateOrganization());
    }, []);

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    };

    useEffect(() => {
        if (userProfile && affiliateOrganizationList.length > 0) {
            setSelectedCompany(
                affiliateOrganizationList.find(
                    (x) => x.idCompany === userProfile.idCompany
                ) || null
            );
        }
    }, [userProfile, affiliateOrganizationList]);

    const fetchedSummaryDevPlan = async () => {
        try {
            setIsFetching(true);
            const response = await getSummaryDevelopmentTalentPlan({ idCompany: selectedCompany && selectedCompany.idCompany, });
            if (response && response.data && response.data.length > 0) {
                setSummaryData(response.data);
            } else {
                setSummaryData([]);
            };
        } catch (error) {
            console.error(error);
        } finally {
            setIsFetching(false);
        };
    };

    useEffect(() => {
        if (selectedCompany && selectedCompany.idCompany) {
            fetchedSummaryDevPlan();
        };
    }, [selectedCompany]);

    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const handleSelectEmployee = (employee) => {
        setSelectedEmployee(employee);
    };

    const filteredSummaryData = summaryData && summaryData.length > 0 && summaryData.filter(item => selectedEmployee ? (item.idEmployees === (selectedEmployee && selectedEmployee.idEmployees)) : true)

    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={"/home"}
                    >
                        {t("AppMenu.Home")}
                    </Link>
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={"/admin/talent"}
                    >
                        Talent
                    </Link>
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={{
                            pathname: "/talent-summary",
                            state: { year: year }
                        }}
                    >
                        ข้อมูลสรุป Talent
                    </Link>
                    <Typography color="text.primary">
                        ข้อมูลสรุปแผนพัฒนา Talent
                    </Typography>
                </Breadcrumbs>

                <div style={{ marginBottom: 8, marginTop: 10 }}>
                    <Typography variant="h4">
                        ข้อมูลสรุปแผนพัฒนา Talent
                    </Typography>
                </div>
   
                <Box sx={{ mt: 2, mb: 4 }}>
                    <Grid container spacing={2} alignItems={"center"}>
                        <Grid item xs={4}>
                            <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                                {t("Company")}
                            </Typography>
                            <SelectAffiliateCompany
                                options={affiliateOrganizationList}
                                value={selectedCompany}
                                disabled={isFetching}
                                onChange={(_, value) => {
                                    onChangeCompany(value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Box style={{ marginBottom: 22 }}>
                                <SelectEmployees
                                    value={selectedEmployee}
                                    selectedCompany={selectedCompany}
                                    handleChange={handleSelectEmployee}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box style={{ display: "flex", position: "relative" }}>
                    <StyledBox className={"close"}>
                        <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <StyledCellHeader
                                            align={"center"}
                                            className="sticky"
                                            style={{ width: 160 }}
                                        >
                                            {t("FullName")}
                                        </StyledCellHeader>

                                        {columns.map((column) => (
                                            <StyledCellHeader key={column.id}>
                                                {column.label}
                                            </StyledCellHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isFetching === true ? (
                                        <StyledRowContent>
                                            <StyledCellContent
                                                colSpan={5}
                                                style={{ height: 150 }}
                                            >
                                                <Typography align="center">
                                                    {t("LoadingEmpData")}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img alt="loading" src={loading} style={{ width: 100, height: 100 }} />
                                                </Box>
                                            </StyledCellContent>
                                        </StyledRowContent>
                                    ) : (
                                        <Fragment>
                                            {filteredSummaryData && filteredSummaryData.length > 0 ? (
                                                filteredSummaryData
                                                    .filter(item => selectedEmployee ? (item.idEmployees === (selectedEmployee && selectedEmployee.idEmployees)) : true)
                                                    .slice(
                                                        page * rowsPerPage,
                                                        page * rowsPerPage + rowsPerPage
                                                    ).map((row, index) =>
                                                        <StyledRowContent hover key={index}>
                                                            <StyledCellContent
                                                                className="sticky"
                                                                style={{
                                                                    borderBottom:
                                                                        "1px dashed rgba(224, 224, 224, 1)",
                                                                }}
                                                            >
                                                                <div className="avatar">
                                                                    <Avatar alt={row.employeeID} src={`${row.imageProfile}`} />
                                                                    <div style={{ paddingLeft: "8px" }}>
                                                                        <Typography className="fullname">
                                                                            {getUserFullName(row)
                                                                                ? getUserFullName(row)
                                                                                : row.firstname_TH +
                                                                                " " +
                                                                                row.lastname_TH}
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </StyledCellContent>

                                                            <StyledCellContent>
                                                                <Typography>
                                                                    {getUserPosition(row) ? getUserPosition(row) : "-"}
                                                                </Typography>
                                                            </StyledCellContent>
                                                            <StyledCellContent>
                                                                <Typography>
                                                                    {row.planName ? row.planName : "-"}
                                                                </Typography>
                                                            </StyledCellContent>
                                                            <StyledCellContent>
                                                                <Typography>
                                                                    {row.startDate ? row.startDate : "-"}
                                                                </Typography>
                                                            </StyledCellContent>
                                                            <StyledCellContent>
                                                                <Typography>
                                                                    {row.endDate ? row.endDate : "-"}
                                                                </Typography>
                                                            </StyledCellContent>
                                                            <StyledCellContent>
                                                                <Typography>
                                                                    {row.AssessorFirstname && row.AssessorLastname ? `${row.AssessorFirstname + " " + row.AssessorLastname}` : "-"}
                                                                </Typography>
                                                            </StyledCellContent>
                                                            <StyledCellContent>
                                                                <Box
                                                                    sx={{
                                                                        borderWidth: 1,
                                                                        borderRadius: 4,
                                                                        borderColor: "#f4f4f4",
                                                                        padding: "4px 12px",
                                                                        backgroundColor: `${row.status === 1 ? "lightgreen" : (row.status === 0 ? "#ffa000" : "lightgray")}`,
                                                                    }}
                                                                >
                                                                    <Typography sx={{ color: `${row.status === 1 ? "#2e7d32" : (row.status === 0 ? "#bf360c" : "#757575")}` }}>
                                                                        {row.status === 1 ? "สำเร็จ" : (row.status === 0 ? "กำลังดำเนินการ" : "รอดำเนินการ")}
                                                                    </Typography>
                                                                </Box>
                                                            </StyledCellContent>
                                                        </StyledRowContent>
                                                    )
                                            ) : (
                                                <StyledRowContent>
                                                    <StyledCellContent
                                                        colSpan={5}
                                                        style={{ height: 150 }}
                                                    >
                                                        <Typography align="center">
                                                            {t("NoEmployee")}
                                                        </Typography>
                                                    </StyledCellContent>
                                                </StyledRowContent>
                                            )}
                                        </Fragment>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={filteredSummaryData ? filteredSummaryData.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={`${t("RowsPerPage")}`}
                            labelDisplayedRows={({ from, to, count }) =>
                                `${from}-${to} ${t("OutOf")} ${count !== -1 ? count : `${t("MoreThan")} ${to}`
                                }`
                            }
                        />
                    </StyledBox>
                </Box>
            </Container>
        </StyledRoot>
    );
}