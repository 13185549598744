import React, { Fragment, useEffect, useState } from "react";
import {
    Box,
    Container,
    Grid,
    styled,
} from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import AI from "./component/AI";

import InfoCard from "./component/InfoCard";

import CustomizingCalendar from "./component/Calendar"
import WorkTime from "./component/WorkTime";
import Notification from "./component/Notification";
import AbsenceAndRate from "./component/AbsenceAndRate";
import Leave from "./component/Leave";
import OT from "./component/OT";
import Tax from "./component/Tax";

import { getUserProfile } from "../../../../actions/user";
import { getAttendanceById } from '../../../../actions/attendance';
import { openNotificationAlert } from '../../../../actions/notificationAlert';
import { getSummaryTime } from "../../../../actions/summary-time";
import { getLeaveAvaiable, getLeaveSetting } from "../../../../actions/leave";
import { getLeaveRequestAllYear } from "../../../../actions/employee";
import { allHoliday, getAllHolidayByIdEmployeesAndYear } from "../../../../actions/holiday";

import leaveService from "../../../../services/leave.service";
import { getNotification } from "../../../../actions/overviewUser";
import Loading from "../../shared/loading";
import { da } from "date-fns/locale";


// Styled component for the root
const StyledRoot = styled(Box)({
    "& .filter-container": {
        marginBottom: "16px",
        display: "flex",
        justifyContent: "flex-end",
        gap: "8px",
        "& .MuiInputBase-root": {
            maxHeight: "48px",
        },
        "& .filter-month": {
            minWidth: "150px",
        },
        "& .filter-year": {
            minWidth: "150px",
        },
    },
    "& .menu-container": {
        boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
        borderRadius: 20,
        backgroundColor: "#ffffff",
        width: "fit-content",
        overflow: "hidden",
    },
    "& .MuiAutocomplete-root": {
        "& .MuiOutlinedInput-root, & .MuiFilledInput-root": {
            padding: "13.5px 14px",
            paddingRight: "32px",
            "& input": {
                padding: 0,
                borderRadius: 0,
            },
        },
    },
    "& .MuiSelect-filled": {
        padding: "16px 32px 16px 12px",
    },
});

function OverviewUser() {
    const { t, i18n } = useTranslation();
    const today = dayjs();

    const dispatch = useDispatch();
    const { user: currentUser } = useSelector((state) => state.auth);
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: summaryTime } = useSelector((state) => state.summaryTime);
    const { result: leaveAvailable, isFetching: leaveAvailableFetching } = useSelector((state) => state.leaveAvailable);
    const { result: holiday, isFetching: holidayFetching } = useSelector((state) => state.holiday);
    const { result: leaveEmployeesList, isFetching: leaveEmployeesListFetching } = useSelector((state) => state.leaveEmployees);
    const { result: notificationList, isFetching: notificationFetching } = useSelector((state) => state.overviewUserNotification);
    const { isFetching: absenceAndLateFetching } = useSelector((state) => state.attendance);

    useEffect(() => {
        if (currentUser) {
            dispatch(getUserProfile(currentUser.username));
        }
    }, []);

    const [todayAttendance, setTodayAttendance] = useState();
    const [absenceAndLate, setAbsenceAndLate] = useState();

    const fetchAttendance = async () => {
        const result = await dispatch(
            getAttendanceById({
                start: today.subtract(1, 'month').startOf('month').startOf('day'),
                end: today.endOf('day').toDate(),
            })
        );

        await dispatch(
            getSummaryTime({
                start: today.subtract(1, 'month').startOf('month').startOf('day').add(1, 'day'),
                end: today.subtract(1, 'month').endOf('month').endOf('day'),
            })
        );

        if (result.status !== 200) {
            await dispatch(openNotificationAlert({ message: t("AnErrorOccurred") }));
        } else {

            setTodayAttendance(
                result.data && result.data.find(item =>
                    dayjs(item.date).isSame(today, 'day')
                ).attendanceDisplay[0]
            );

            const filteredAbsenceAndLate = result.data ? result.data
                .filter(item =>
                    dayjs(item.date).isBetween(
                        today.startOf('month').subtract(1, 'month'),
                        today.subtract(1, 'day'),
                        'day',
                        '[]'
                    ) && (item.isLate === true || item.absent === true || item.isEarlyOut === true)
                )
                .map(item => ({
                    date: item.date,
                    startdate: dayjs(item.date).startOf('day'),
                    enddate: dayjs(item.date).endOf('day'),
                    status: item.isLate ? 'Late' : item.absent ? 'Absent' : item.isEarlyOut ? 'EarlyOut' : '',
                })) : null;

            setAbsenceAndLate(filteredAbsenceAndLate);
        }
    };

    const getSummaries = (leave = [], holiday = [], absent = []) => {
        const leaveArray = Array.isArray(leave) ? leave : [];
        const holidayArray = Array.isArray(holiday) ? holiday : [];
        const absentArray = Array.isArray(absent) ? absent : [];

        const combinedEvents = [...leaveArray, ...holidayArray, ...absentArray];
        return combinedEvents.map(event => {
            const isStatus = event.status

            if (isStatus) {
                return {
                    start:event.startdate,
                    end: event.enddate,
                    title: isStatus === 'Absent' ? t('Absent') : isStatus === 'Late' ? t('Late') : isStatus === 'EarlyOut' ? t('LeaveEarly') : null,
                    status: isStatus,
                };
            } else {
                return {
                    start: event.start
                        ? new Date(dayjs(event.start).toDate())
                        : new Date(dayjs(event.dateHoliday || new Date()).startOf('day').toDate()),
                    end: event.end
                        ? event.end === event.start
                            ? new Date(dayjs(event.end).endOf('day').toDate())
                            : new Date(dayjs(event.end).toDate())
                        : new Date(dayjs(event.dateHoliday || new Date()).endOf('day').toDate()),
                    title: i18n.resolvedLanguage === 'th'
                        ? event.name
                        : event.name_EN!==null ? event.name_EN : event.name || event.description || "Unnamed Event",
                    status: event.status || null,
                };
            }

        });
    };


    useEffect(async () => {
        await fetchAttendance();
    }, []);

    const [selectedLeaveRoundDate, setSelecetedLeaveroundDate] = useState("");

    useEffect(() => {
        if (userProfile) {
            dispatch(getLeaveSetting());
            dispatch(getAllHolidayByIdEmployeesAndYear(dayjs(new Date()).format("YYYY"), { idEmployees: userProfile.idEmployees }));
            fetchNotification();
            fetchLeaveRound();
        }
    }, [userProfile]);

    const fetchNotification = () => {
        dispatch(getNotification({ idEmployees: userProfile.idEmployees, role: 'user', idCompany: userProfile.idCompany }))
    }

    const fetchLeaveRound = () => {
        leaveService
            .getLeaveRoundList()
            .then((res) => {
                const foundLeaveRound = res.data.find((item) =>
                    dayjs(today).isBetween(dayjs(item.start), dayjs(item.end), "[]")
                );
                setSelecetedLeaveroundDate(foundLeaveRound.date);
            })
            .catch((error) => {
                setSelecetedLeaveroundDate("");
            });
    };

    useEffect(() => {
        if (selectedLeaveRoundDate) {
            dispatch(
                getLeaveRequestAllYear({
                    filter: dayjs(selectedLeaveRoundDate).format("YYYY"),
                })
            );
            dispatch(
                getLeaveAvaiable({
                    date: dayjs(selectedLeaveRoundDate).format("YYYY-MM-DD"),
                })
            );

        }
    }, [selectedLeaveRoundDate]);

    const renderContent = () => {
        const renderLoading = (isFetching, condition = true) => (isFetching || !condition ? <Loading /> : null);

        return (<Fragment>
            {renderLoading(leaveEmployeesListFetching || holidayFetching, leaveEmployeesList) ||
                <CustomizingCalendar t={t} i18n={i18n} events={getSummaries(leaveEmployeesList, holiday, absenceAndLate)} birthday={userProfile.birthday} />}

            <WorkTime t={t} todayAttendance={todayAttendance} />

            {renderLoading(notificationFetching) ||
                <Notification t={t} i18n={i18n} notificationList={notificationList || null} fetchNotification={fetchNotification} />}

            {renderLoading(absenceAndLateFetching) ||
                <AbsenceAndRate t={t} currentLocale={i18n.resolvedLanguage} absenceAndLate={absenceAndLate} />}

            {renderLoading(leaveAvailableFetching, leaveAvailable) ||
                <Leave t={t} leaveAvailable={leaveAvailable} />}

            <OT t={t} summaryTime={summaryTime} />
            <Tax t={t} />
        </Fragment>)

    };

    return (
        <StyledRoot className="page">
            <Container
                maxWidth="lg"
                style={{ marginTop: "16px", marginBottom: "16px" }}
            >
                <Grid container spacing={6}>
                    <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <InfoCard
                            image={userProfile.imageProfile || 'https://example.com/image1.jpg'}
                            name={`${userProfile[`firstname_${i18n.resolvedLanguage === 'en' || i18n.resolvedLanguage === 'th' ? i18n.resolvedLanguage.toUpperCase() : 'EN'}`]} ${userProfile[`lastname_${i18n.resolvedLanguage === 'en' || i18n.resolvedLanguage === 'th' ? i18n.resolvedLanguage.toUpperCase() : 'EN'}`]}`}
                            id={userProfile.employeeID}
                            job={`${userProfile[`positionName${i18n.resolvedLanguage === 'en' ? '_EN' : ''}`]}`}
                            t={t}
                        />
                        {renderContent()}
                    </Grid>

                    <Grid item xs={4}>
                        <AI title={"AI"} />
                    </Grid>
                </Grid>
            </Container>
        </StyledRoot>
    );
}

export default OverviewUser;
