import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
  Radio,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import CardStyle from "../../../shared/general/Card";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  updateExpenseSettingCostElement,
  addExpenseSettingCostElement,
  getExpenseSettingsCostElement,
} from "../../../../../actions/expenseSetting";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SaveIcon from "@mui/icons-material/Save";
import { useTranslation } from "react-i18next";

const StyledHeader = (props) => {
  const { header } = props;

  return (
    <Grid item xs={12}>
      <Typography
        sx={{
          paddingLeft: "6px",
          borderLeft: "4px solid #46cbe2",
        }}
        fontSize="16px"
        fontWeight="500"
      >
        {header}
      </Typography>
    </Grid>
  );
};

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledDivider = styled(Divider)({
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const CostElements = (props) => {
  const { subGroupIndex, errors, control, setValue, watch } = props;
  const { t, i18n } = useTranslation();
  const defaultValue = { name: "", code: "", description: "" };

  const {
    fields: fieldCostElements,
    append: addCostElement,
    remove: removeCostElement,
  } = useFieldArray({
    control,
    name: `costElementSubGroups.${subGroupIndex}.costElements`,
  });

  return (
    <Fragment>
      <Grid item xs={12}>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <ButtonBlue
            variant="contained"
            type="button"
            startIcon={<AddIcon />}
            onClick={() =>
              addCostElement({
                name: "",
                code: "",
                description: "",
              })
            }
            sx={{ marginRight: "16px" }}
          >
            {t("addCostElement")}
          </ButtonBlue>
        </div>
      </Grid>
      <StyledHeader header={t("costElements")} />
      {fieldCostElements &&
        fieldCostElements.map((costElement, costElementIndex) => (
          <Fragment key={costElement.id}>
            <Grid
              item
              xs={12}
              md={1}
              container
              justifyContent="center"
              alignItems="center"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                }}
              >
                {subGroupIndex + 1}.{costElementIndex + 1}.
              </div>
            </Grid>
            <Grid item xs={12} sm={5}>
              <div>
                <StyledHeadLabel
                  variant="body2"
                  color="text.third"
                  gutterBottom
                >
                  {t("Name")}
                </StyledHeadLabel>
                <FormControl
                  fullWidth
                  error={
                    errors &&
                    errors.costElementSubGroups &&
                    errors.costElementSubGroups[subGroupIndex] &&
                    errors.costElementSubGroups[subGroupIndex].costElements &&
                    errors.costElementSubGroups[subGroupIndex].costElements[
                      costElementIndex
                    ] &&
                    errors.costElementSubGroups[subGroupIndex].costElements[
                      costElementIndex
                    ].name
                      ? true
                      : false
                  }
                >
                  <Controller
                    name={`costElementSubGroups.${subGroupIndex}.costElements.${costElementIndex}.name`}
                    control={control}
                    errors={errors}
                    render={({ field, fieldState }) => (
                      <>
                        <TextFieldTheme
                          {...field}
                          handleChange={setValue}
                          control={control}
                          watch={watch}
                          error={fieldState.error ? true : false}
                          helperText={
                            fieldState.error ? fieldState.error.message : null
                          }
                        />
                      </>
                    )}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={5}>
              <div>
                <StyledHeadLabel
                  variant="body2"
                  color="text.third"
                  gutterBottom
                >
                  {t("Code")}
                </StyledHeadLabel>
                <FormControl
                  fullWidth
                  error={
                    errors &&
                    errors.costElementSubGroups &&
                    errors.costElementSubGroups[subGroupIndex] &&
                    errors.costElementSubGroups[subGroupIndex].costElements &&
                    errors.costElementSubGroups[subGroupIndex].costElements[
                      costElementIndex
                    ] &&
                    errors.costElementSubGroups[subGroupIndex].costElements[
                      costElementIndex
                    ].code
                      ? true
                      : false
                  }
                >
                  <Controller
                    name={`costElementSubGroups.${subGroupIndex}.costElements.${costElementIndex}.code`}
                    control={control}
                    errors={errors}
                    render={({ field, fieldState }) => (
                      <>
                        <TextFieldTheme
                          {...field}
                          handleChange={setValue}
                          control={control}
                          watch={watch}
                          error={fieldState.error ? true : false}
                          helperText={
                            fieldState.error ? fieldState.error.message : null
                          }
                        />
                      </>
                    )}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={1}
              container
              justifyContent="center"
              alignItems="center"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  paddingTop: 16,
                }}
              >
                {fieldCostElements.length > 1 &&
                  costElementIndex == fieldCostElements.length - 1 && (
                    <ButtonBlue
                      variant="contained"
                      type="button"
                      color="error"
                      startIcon={<DeleteOutlineIcon />}
                      sx={{ marginRight: "16px" }}
                      disabled={
                        fieldCostElements.length == 1 ||
                        costElementIndex != fieldCostElements.length - 1
                      }
                      onClick={() => removeCostElement(costElementIndex)}
                    >
                      {t("Delete")}
                    </ButtonBlue>
                  )}
              </div>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={12} sm={10}>
              <div>
                <StyledHeadLabel
                  variant="body2"
                  color="text.third"
                  gutterBottom
                >
                  {t("Descriptions")}
                </StyledHeadLabel>
                <FormControl
                  fullWidth
                  error={
                    errors &&
                    errors.costElementSubGroups &&
                    errors.costElementSubGroups[subGroupIndex] &&
                    errors.costElementSubGroups[subGroupIndex].costElements &&
                    errors.costElementSubGroups[subGroupIndex].costElements[
                      costElementIndex
                    ] &&
                    errors.costElementSubGroups[subGroupIndex].costElements[
                      costElementIndex
                    ].description
                      ? true
                      : false
                  }
                >
                  <Controller
                    name={`costElementSubGroups.${subGroupIndex}.costElements.${costElementIndex}.description`}
                    control={control}
                    errors={errors}
                    render={({ field, fieldState }) => (
                      <>
                        <TextFieldTheme
                          {...field}
                          handleChange={setValue}
                          control={control}
                          watch={watch}
                          error={fieldState.error ? true : false}
                          helperText={
                            fieldState.error ? fieldState.error.message : null
                          }
                          multiline
                          rows={3}
                        />
                      </>
                    )}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={1}></Grid>
          </Fragment>
        ))}
    </Fragment>
  );
};

function CostElementSettingForm(props) {
  const {
    drawerConfig,
    onClose,
    setOpenAlert,
    setAlertType,
    selectedCompany,
    isReadOnly,
  } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [isSending, setIsSending] = useState(false);
  const defaultValue = {
    name: "",
    code: "",
    costElementSubGroups: [
      {
        name: "",
        code: "",
        costElements: [
          {
            name: "",
            code: "",
            description: "",
          },
        ],
      },
    ],
  };
  const [formData, setFormData] = useState(defaultValue);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("ThisFieldIsRequired")),
    code: Yup.string().required(t("ThisFieldIsRequired")),

    costElementSubGroups: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required(t("ThisFieldIsRequired")),
          code: Yup.string().required(t("ThisFieldIsRequired")),

          costElements: Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string().required(t("ThisFieldIsRequired")),
                code: Yup.string().required(t("ThisFieldIsRequired")),
                description: Yup.string().required(t("ThisFieldIsRequired")),
              })
            )
            .min(1, t("ThisFieldIsRequired")),
        })
      )
      .min(1, t("ThisFieldIsRequired")),
  });
  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { ...formData },
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const {
    fields: fieldSubGroups,
    append: addSubGroup,
    remove: removeSubGroup,
  } = useFieldArray({
    control,
    name: "costElementSubGroups",
  });

  const [modalStatusConfig, setModalStatusConfig] = useState({
    open: false,
    title: "",
    content: "",
    type: "success",
  });

  const checkValidation = (error) => {
    console.log("error : ", error);
    setModalStatusConfig({
      open: true,
      title: "",
      content: t("ThisFieldIsRequired"),
      type: "error",
    });
  };

  useEffect(() => {
    if (drawerConfig.isOpen) {
      if (selectedCompany) {
        setValue("idCompany", selectedCompany.idCompany);
      }

      if (drawerConfig.isEdit) {
        let setting = drawerConfig.data;
        let defaultData = {
          idExpenseSettingCostElementGroup:
            setting.idExpenseSettingCostElementGroup,
          name: setting.name,
          code: setting.code,
          costElementSubGroups: setting.costElementSubGroups,
        };
        reset(defaultData);
        setFormData(defaultData);
      }
    }
  }, [drawerConfig.isOpen]);

  const handleChangeCancle = () => {
    if (drawerConfig.isEdit === false) {
      reset(defaultValue);
    }
    onClose();
  };

  const onSubmit = async () => {
    setIsSending(true);
    setValue("idCompany", selectedCompany.idCompany);
    const data = getValues();
    console.log("data", data);

    let res;
    if (drawerConfig.isEdit) {
      res = await dispatch(updateExpenseSettingCostElement(data));
    } else {
      res = await dispatch(addExpenseSettingCostElement(data));
    }

    if (res.status === 200) {
      setIsSending(false);
      setOpenAlert(true);
      setAlertType("success");
      onClose();
      dispatch(getExpenseSettingsCostElement(selectedCompany.idCompany));
    } else {
      setIsSending(false);
      setOpenAlert(true);
      setAlertType("error");
    }
  };

  return (
    <>
      <div style={{ marginBottom: 12 }}>
        <Typography variant="h5" style={{ paddingTop: 8 }}>
          {t("addExpenseSettingCostElementGroup")}
        </Typography>
      </div>

      <CardStyle>
        <Box sx={{ padding: "24px", marginTop: "16px" }}>
          <form handleSubmit={onSubmit}>
            <Grid container spacing={6}>
              <StyledHeader header={t("costElementGroup")} />
              <Grid item xs={12} sm={6}>
                <div>
                  <StyledHeadLabel
                    variant="body2"
                    color="text.third"
                    gutterBottom
                  >
                    {t("Name")}
                  </StyledHeadLabel>
                  <FormControl
                    fullWidth
                    error={errors && errors.name ? true : false}
                  >
                    <Controller
                      name="name"
                      control={control}
                      errors={errors}
                      render={({ field, fieldState }) => (
                        <>
                          <TextFieldTheme
                            {...field}
                            handleChange={setValue}
                            control={control}
                            watch={watch}
                            error={fieldState.error ? true : false}
                            helperText={
                              fieldState.error ? fieldState.error.message : null
                            }
                          />
                        </>
                      )}
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <StyledHeadLabel
                    variant="body2"
                    color="text.third"
                    gutterBottom
                  >
                    {t("Code")}
                  </StyledHeadLabel>
                  <FormControl
                    fullWidth
                    error={errors && errors.code ? true : false}
                  >
                    <Controller
                      name="code"
                      control={control}
                      errors={errors}
                      render={({ field, fieldState }) => (
                        <>
                          <TextFieldTheme
                            {...field}
                            handleChange={setValue}
                            control={control}
                            watch={watch}
                            error={fieldState.error ? true : false}
                            helperText={
                              fieldState.error ? fieldState.error.message : null
                            }
                          />
                        </>
                      )}
                    />
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={12}>
                <StyledDivider />
              </Grid>

              <Grid item xs={12}>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <ButtonBlue
                    variant="contained"
                    type="button"
                    startIcon={<AddIcon />}
                    onClick={() =>
                      addSubGroup({
                        name: "",
                        code: "",
                        costElements: [{ name: "", code: "", description: "" }],
                      })
                    }
                    sx={{ marginRight: "16px" }}
                  >
                    {t("addSubGroup")}
                  </ButtonBlue>
                </div>
              </Grid>

              {fieldSubGroups &&
                fieldSubGroups.map((subGroup, subGroupIndex) => {
                  return (
                    <Fragment key={subGroup.id}>
                      <StyledHeader header={t("costElementSubGroup")} />
                      <Grid
                        item
                        xs={12}
                        md={1}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {subGroupIndex + 1}.
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <div>
                          <StyledHeadLabel
                            variant="body2"
                            color="text.third"
                            gutterBottom
                          >
                            {t("Name")}
                          </StyledHeadLabel>
                          <FormControl
                            fullWidth
                            error={
                              errors &&
                              errors.costElementSubGroups &&
                              errors.costElementSubGroups[subGroupIndex] &&
                              errors.costElementSubGroups[subGroupIndex].name
                                ? true
                                : false
                            }
                          >
                            <Controller
                              name={`costElementSubGroups.${subGroupIndex}.name`}
                              control={control}
                              errors={errors}
                              render={({ field, fieldState }) => (
                                <>
                                  <TextFieldTheme
                                    {...field}
                                    handleChange={setValue}
                                    control={control}
                                    watch={watch}
                                    error={fieldState.error ? true : false}
                                    helperText={
                                      fieldState.error
                                        ? fieldState.error.message
                                        : null
                                    }
                                  />
                                </>
                              )}
                            />
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <div>
                          <StyledHeadLabel
                            variant="body2"
                            color="text.third"
                            gutterBottom
                          >
                            {t("Code")}
                          </StyledHeadLabel>
                          <FormControl
                            fullWidth
                            error={
                              errors &&
                              errors.costElementSubGroups &&
                              errors.costElementSubGroups[subGroupIndex] &&
                              errors.costElementSubGroups[subGroupIndex].code
                                ? true
                                : false
                            }
                          >
                            <Controller
                              name={`costElementSubGroups.${subGroupIndex}.code`}
                              control={control}
                              errors={errors}
                              render={({ field, fieldState }) => (
                                <>
                                  <TextFieldTheme
                                    {...field}
                                    handleChange={setValue}
                                    control={control}
                                    watch={watch}
                                    error={fieldState.error ? true : false}
                                    helperText={
                                      fieldState.error
                                        ? fieldState.error.message
                                        : null
                                    }
                                  />
                                </>
                              )}
                            />
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={1}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            paddingTop: 16,
                          }}
                        >
                          {fieldSubGroups.length > 1 &&
                            subGroupIndex == fieldSubGroups.length - 1 && (
                              <ButtonBlue
                                variant="contained"
                                type="button"
                                color="error"
                                startIcon={<DeleteOutlineIcon />}
                                onClick={() => removeSubGroup(subGroupIndex)}
                                sx={{ marginRight: "16px" }}
                                disabled={
                                  fieldSubGroups.length == 1 ||
                                  subGroupIndex != fieldSubGroups.length - 1
                                }
                              >
                                {t("Delete")}
                              </ButtonBlue>
                            )}
                        </div>
                      </Grid>

                      <CostElements
                        subGroupIndex={subGroupIndex}
                        errors={errors}
                        control={control}
                        setValue={setValue}
                        watch={watch}
                      />

                      <Grid item xs={12}>
                        <StyledDivider />
                      </Grid>
                    </Fragment>
                  );
                })}

              <Grid item xs={12}>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <ButtonBlue
                    variant="contained"
                    type
                    color="error"
                    startIcon={<DeleteOutlineIcon />}
                    onClick={() => handleChangeCancle(false)}
                    sx={{ marginRight: "16px" }}
                  >
                    {t("Cancel")}
                  </ButtonBlue>
                  <ButtonBlue
                    variant="contained"
                    type
                    startIcon={<SaveIcon />}
                    onClick={handleSubmit(onSubmit, checkValidation)}
                    disabled={isSending}
                  >
                    {t("Save")}
                  </ButtonBlue>
                </div>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardStyle>
    </>
  );
}

export default CostElementSettingForm;
