import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Paper, Box, Typography, Grid, Avatar } from "@mui/material";
import { useHistory } from "react-router-dom";
import ListInfoPage from "./listInfoPage";
import { getUserFullName } from "../../../../utils/userData";
import { useTranslation } from "react-i18next";


const StyledBox = styled(Box)({
  "& .box-header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "50px",
    fontSize: "20px",
    fontWeight: 600,
    color: "white",
    backgroundColor: "#46cbe2",
    marginRight: "90px",
    marginLeft: "90px",
    borderRadius: "0 0 20px 20px",
  },
});

const StyledHeader = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  "& .header-text": {
    alignText: "center",
    color: "white",
    fontSize: "2rem",
    fontWeight: 600,
    marginLeft: "20px",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
  },
});

const StyledContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  "& .emp-avatar": {
    alignItems: "center",
    minHeight: "80px",
    minWidth: "80px",
    margin: "auto",
  },
  "& .emp-name": {
    textAlign: "center",
    fontSize: "0.8rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "white",
    marginLeft: "9px",
    marginRight: "3px",
  },
});

const colorMapping = {
  A3: "#C3F6E7",
  A2: "#A1F3DB",
  A1: "#7AF3D0",
  B3: "#D2F7FF",
  B2: "#B1EEFC",
  B1: "#84E9FF",
  C3: "#FBEEDB",
  C2: "#FFE0B2",
  C1: "#FFD08C",
};

const ListPage = ({ item, empData, year, idCompany }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const bgColor = colorMapping[item.talentName] || "#FFFFFF";

  const handlePaperClick = (name, id, idCompany, year) => {
    history.push({
      pathname: "/talent-management/list-info-page",
      state: {
        empData: { name, idTalent: id, idCompany, year }
      },
    });
  };

  return (
    <div>
      <Paper
        style={{ minHeight: "450px", backgroundColor: bgColor }}
        onClick={() => handlePaperClick(item.talentName, item.idTalent, idCompany, year)}
      >
        <StyledBox>
          <Box className="box-header">{empData && empData.filter(i => i.idTalent === item.idTalent) && empData.filter(i => i.idTalent === item.idTalent).length} {t("Person")}</Box>
        </StyledBox>
        <StyledHeader>
          <Typography className="header-text">{item.talentName}</Typography>
          <div style={{ marginLeft: "200px" }}>
            <radialChartIndex />
          </div>
        </StyledHeader>
        <StyledContent>
          <Grid container spacing={2}>
            {empData && empData.length > 0 && empData.slice(0, 6).filter(i => i.idTalent === item.idTalent).map((e, index) => (
              <Grid item xs={4} key={index}>
                <Avatar className="emp-avatar" src={e.imageProfile} />
                <Box
                  style={{
                    marginTop: "10px",
                    backgroundColor: "#46cbe2",
                    borderRadius: "20px",
                  }}
                >
                  <Typography className="emp-name">{getUserFullName(e)}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </StyledContent>
      </Paper>
    </div>
  );
};

export default ListPage;
