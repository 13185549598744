import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import CardStyle from "../../../shared/general/Card";
import { useTranslation } from "react-i18next";
import { ArrowForwardIosSharp } from "@mui/icons-material";

const AccordionStyled = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummaryStyled = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderRadius: 8,
  marginBottom: 8,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .wrap": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledDivider = styled(Divider)({
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledDetailSubGroupDiv = (props) => {
  const { header, code, name } = props;
  const { t, i18n } = useTranslation();
  return (
    <>
      <Grid item xs={12}>
        <Typography fontSize="14px" fontWeight="500" color="text.secondary">
          {header}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography>
          &nbsp;&nbsp;{t("Code")}: &nbsp;{code}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography>&nbsp;&nbsp;{name}</Typography>
      </Grid>
    </>
  );
};

const StyledDetailDiv = (props) => {
  const { code, name, description, index } = props;
  const { t, i18n } = useTranslation();
  return (
    <>
      <Grid item xs={0} md={1}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {index + 1}.
        </div>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography>
          &nbsp;&nbsp;{t("Code")}: &nbsp;{code}
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Typography>&nbsp;&nbsp;{name}</Typography>
      </Grid>
      <Grid item xs={0} md={1}></Grid>
      <Grid item xs={12} md={11}>
        <Typography>&nbsp;&nbsp;&nbsp;{description}</Typography>
      </Grid>
    </>
  );
};

const StyledHeaderDetailDiv = (props) => {
  const { header } = props;

  return (
    <Grid item xs={12}>
      <Typography
        sx={{
          paddingLeft: "6px",
          borderLeft: "4px solid #46cbe2",
        }}
        fontSize="16px"
        fontWeight="500"
      >
        {header}
      </Typography>
    </Grid>
  );
};

const DetailCard = (props) => {
  const { expenseSetting, handleChangeIsEditting } = props;
  const { t, i18n } = useTranslation();

  return (
    <CardStyle sx={{ padding: "0px" }}>
      <Box sx={{ padding: "36px 48px" }}>
        <Grid container spacing={2}>
          {expenseSetting ? (
            <>
              <StyledHeaderDetailDiv header={expenseSetting.name} />
              {expenseSetting.costElementSubGroups.map((subGroup) => (
                <Fragment>
                  <StyledDetailSubGroupDiv
                    header={t("costElementSubGroup")}
                    code={subGroup.code}
                    name={subGroup.name}
                  />
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        fontSize="14px"
                        fontWeight="500"
                        color="text.secondary"
                      >
                        &nbsp;&nbsp;{t("costElements")}
                      </Typography>
                    </Grid>
                    {subGroup.costElements &&
                      subGroup.costElements.map(
                        (costElement, costElementIndex) => (
                          <StyledDetailDiv
                            index={costElementIndex}
                            code={costElement.code}
                            name={costElement.name}
                            description={costElement.description}
                          />
                        )
                      )}
                  </Grid>
                  <Grid item xs={12}>
                    <StyledDivider />
                  </Grid>
                </Fragment>
              ))}
            </>
          ) : (
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "16px",
                }}
              >
                <StyledHeadLabel variant="body2">{`${t(
                  "NoData"
                )}`}</StyledHeadLabel>
              </div>
            </Grid>
          )}
        </Grid>
      </Box>
    </CardStyle>
  );
};

function AccordionRow(props) {
  const { data, setDrawerConfig, setDeleteConfig } = props;
  const { t, i18n } = useTranslation();
  const [isExpand, setIsExpand] = useState(false);

  return (
    <AccordionStyled
      expanded={isExpand}
      onChange={() => {
        setIsExpand((prev) => !prev);
      }}
    >
      <AccordionSummaryStyled>
        <Box flexGrow="1" display="flex" alignItems="center">
          <Typography flexGrow="1">
            {data.code}&nbsp;&nbsp;{data.name}
          </Typography>

          <Box>
            <IconButton
              aria-label="edit"
              onClick={(e) => {
                e.stopPropagation();
                setDrawerConfig((prev) => ({
                  ...prev,
                  isOpen: true,
                  isEdit: true,
                  data: data,
                }));
              }}
            >
              <EditNoteRoundedIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={(e) => {
                e.stopPropagation();
                setDeleteConfig((prev) => ({
                  ...prev,
                  isOpen: true,
                  data: {
                    idExpenseSettingCostElementGroup:
                      data.idExpenseSettingCostElementGroup,
                    name: data.name,
                  },
                }));
              }}
            >
              <DeleteOutlineRoundedIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
      </AccordionSummaryStyled>
      <AccordionDetails>
        <DetailCard expenseSetting={data} />
      </AccordionDetails>
    </AccordionStyled>
  );
}

export default AccordionRow;
