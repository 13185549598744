import React, { useState } from "react";

import {
  Avatar,
  Grid,
  LinearProgress,
  Typography,
  styled,
} from "@mui/material";

import CardStyle from "../../../../shared/general/Card";
import {
  getUserPosition,
  getUserFullName,
} from "../../../../../../utils/userData";
import GaugeChart from "react-gauge-chart";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { useTranslation } from "react-i18next";
import DetailOverviewDialog from "./detailOverviewDialog";

const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: "#DCFAF8",
  "& .MuiLinearProgress-bar": {
    borderRadius: 5,
    backgroundColor: "#16DBCC",
  },
}));
const CardShowOverview = (props) => {
  const { t } = useTranslation();
  const { appraisee } = props;
  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    appraisers: null,
    answers: null,
    questions: null,
  });

  const handleCloseDrawer = async () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };
  return (
    <CardStyle>
      <Grid
        container
        spacing={2}
        sx={{
          padding: "24px",
        }}
      >
        <Grid item xs={6}>
          <div
            style={{
              display: "flex",
              padding: "24px",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                width: 120,
                height: 120,
                "& img": { objectFit: "contain" },
              }}
              src={appraisee.imageURL}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "20px",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              <Typography
                sx={{ fontWeight: "500", fontSize: "18px" }}
                gutterBottom
              >
                {getUserFullName(appraisee)}
              </Typography>
              <Typography color="text.secondary">
                {getUserPosition(appraisee)}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div
            style={{
              padding: "24px",
              height: "100%",
            }}
          >
            <Typography
              variant="body1"
              color={"text.label"}
              textAlign={"center"}
            >
              คะแนนเฉลี่ยทั้งหมด
            </Typography>
            <GaugeChart
              nrOfLevels={420}
              arcsLength={[0.2, 0.5, 0.3]}
              colors={["#ff5722", "#ffcd56", "#47c2c2"]}
              percent={appraisee.scoreAvg / 100}
              arcPadding={0.02}
              textColor="#222f3e"
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <Typography variant="body1" color={"text.secondary"}>
              ประเมินแล้ว
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: "18px" }}>
              {appraisee.total_appraiser_complete}/
              {appraisee.total_appraiser}
            </Typography>
          </div>
          <ProgressBar
            variant="determinate"
            value={(appraisee.total_appraiser_complete * 100) / appraisee.total_appraiser}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <ButtonBlue
            variant="contained"
            onClick={() => {
              setDrawerConfig((prev) => ({
                ...prev,
                isOpen: true,
                idAppraisee:appraisee.idAppraisee,
                idPotentialAssessmentQuestionSet:appraisee.idPotentialAssessmentQuestionSet
              }));
            }}
          >
            ดูรายละเอียดเพิ่มเติม
          </ButtonBlue>
        </Grid>
      </Grid>
      {drawerConfig.isOpen && (
        <DetailOverviewDialog
          drawerConfig={drawerConfig}
          onClose={handleCloseDrawer}
        />
      )}
    </CardStyle>
  );
};

export default CardShowOverview;

