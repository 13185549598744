import {
  JOBDESCRIPTION_FAILED,
  JOBDESCRIPTION_FETCHING,
  JOBDESCRIPTION_SUCCESS,
} from "./types.js";

import jobDescriptionService from "../services/jobDescription.service.js";

export const getAllJobDescription = (idPosition) => async (dispatch) => {
  try {
    dispatch({
      type: JOBDESCRIPTION_FETCHING,
    });
    const res = await jobDescriptionService.getAllJobDescription(idPosition);
    if (res) {
      dispatch({
        type: JOBDESCRIPTION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: JOBDESCRIPTION_FAILED,
    });
    console.log(error);
  }
};

export const addJobDescriptions = (formData) => async () => {
  try {
    const res = await jobDescriptionService.addJobDescriptions(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const updateJobDescription = (formData) => async () => {
  try {
    const res  = await jobDescriptionService.updateJobDescription(formData);
    if(res){
        return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};


export const deleteJobDescription = (formData) => async () => {
  try {
    const res = await jobDescriptionService.deleteJobDescription(formData)
    if (res) {
        return res;
      }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};



