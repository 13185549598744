import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  styled,
  Box,
  Autocomplete,
  Divider,
  MenuItem,
} from "@mui/material";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getUserFullName } from "../../../../../../utils/userData";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

const StyleAutocomplete = styled(Autocomplete)({
  "& .MuiInputBase-root": {
    padding: "13.5px 14px",
    "& .MuiInputBase-input": {
      padding: "0px !important",
    },
  },
});

export default function AddAppraiserDialog(porps) {
  const { openDialog, onClose, ratioSampleSize } = porps;
  const { t } = useTranslation();
  const { result: potentialAppraisers } = useSelector(
    (state) => state.potentialAppraiser
  );
  const [roleOptions, setRoleOption] = useState();
  const dispatch = useDispatch();
  const { result: employeesList } = useSelector((state) => state.employees);
  const validateYupSchema = yup.object().shape({
    appraisers: yup.array().of(
      yup.object().shape({
        idEmployees: yup
          .number("กรุณาเลือกผู้ประเมิน")
          .nullable()
          .required("กรุณาเลือกผู้ประเมิน"),
        role: yup
          .string("กรุณาระบุฐานนะ")
          .nullable()
          .required("กรุณาระบุฐานนะ"),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      appraisers: [
        {
          idEmployees: null,
          firstname_TH: null,
          lastname_TH: null,
          firstname_EN: null,
          lastname_EN: null,
          role: null,
          ratio: 0,
        },
      ],
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "appraisers",
  });
  const isDisabled = (index) => {
    if (index < 2) {
      return openDialog.data.appraisers[index].idEmployees !== null;
    }
    return false;
  };
  useEffect(() => {
    const appraisers = openDialog.data.appraisers;
    const temp = [];
    let ratioBoss = 0;
    for (let i = 0; i < ratioSampleSize.ratio.length; i++) {
      if (ratioSampleSize.ratio[i].ratio > 0) {
        temp.push(ratioSampleSize.ratio[i].role);
        if (ratioSampleSize.ratio[i].role == "boss") {
          ratioBoss = ratioSampleSize.ratio[i].ratio;
        }
      }
    }
    console.log(temp);
    setRoleOption(temp);
    if (appraisers) {
      const temp1 = appraisers.map((e) => {
        return {
          idEmployees: e.idEmployees,
          firstname_TH: e.firstname_TH ? e.firstname_TH : null,
          lastname_TH: e.lastname_TH ? e.lastname_TH : null,
          firstname_EN: e.firstname_EN ? e.firstname_EN : null,
          lastname_EN: e.lastname_EN ? e.lastname_EN : null,
          role:  e.role?e.role: null,
          ratio: e.ratio ?e.ratio : null,
        };
      });
      reset({
        appraisers: temp1,
      });
    }
  }, [openDialog]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    e.stopPropagation();
    let selectedItems = new Set();
    data.appraisers.map((e)=>{
      selectedItems.add(e.role); 
    })
    if (selectedItems.size === roleOptions.length) {
      for (let i = 0; i < potentialAppraisers.length; i++) {
        if (
          potentialAppraisers[i].idEmployees === openDialog.data.idEmployees
        ) {
          potentialAppraisers[i].appraisers = data.appraisers;
          potentialAppraisers[i].isComplete = true;
          break; // ออกจากลูป
        }
      }
      onClose();
    } else {
      console.log(selectedItems);
      console.log(roleOptions.length);
      dispatch(
        openNotificationAlert({
          type: "error",
          message: "กรุณาเพิ่มผู้ประเมินให้สมบูรณ์",
        })
      );
    }
  };

  return (
    <Dialog
      open={openDialog.isOpen}
      onClose={onClose}
      maxWidth="lg"
      fullWidth={true}
      PaperProps={{
        style: {
          height: "100%",
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">เพิ่มผู้ประเมิน</Typography>

          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={(e) => {
            e.stopPropagation(); //
            handleSubmit(onSubmit)(e);
          }}
        >
          <Grid container spacing={3}>
            {fields.map((item, index) => {
              return (
                <Grid item key={index} container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      textAlign="left"
                      fontSize="16px"
                      fontWeight="500"
                      marginTop={"20px"}
                    >
                      ผู้ประเมินคนที่ {index + 1}
                    </Typography>

                    {index >= 2 && (
                      <IconButton
                        onClick={() => remove(index)}
                        size="small"
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Typography className="field-label" color="text.secondary">
                      ประเมินในฐานนะ
                    </Typography>
                    <Controller
                      name={`appraisers.${index}.role`}
                      control={control}
                      render={({ field, fieldState }) => {
                        return (
                          <StyleAutocomplete
                            {...field}
                            disabled={isDisabled(index)}
                            options={roleOptions}
                            onChange={(_, newValue) => {
                              field.onChange(newValue ? newValue : null);
                             
                              const [ratio] = ratioSampleSize.ratio.filter(
                                (e) => e.role == newValue
                              );
                              setValue(
                                `appraisers.${index}.ratio`,
                                ratio.ratio
                              );
                            }}
                            getOptionLabel={(option) => option}
                            renderOption={(props, option) => (
                              <MenuItem
                                {...props}
                                key={option}
                              >
                                {option}
                              </MenuItem>
                            )}
                            disableClearable
                            renderInput={(params) => (
                              <TextFieldTheme
                                {...params}
                                helperText={
                                  fieldState.error
                                    ? fieldState.error.message
                                    : ""
                                }
                                error={!!fieldState.error}
                              />
                            )}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={8} sm={8}>
                    <Typography className="field-label" color="text.secondary">
                      เลือกผู้ประมิน
                    </Typography>
                    <Controller
                      control={control}
                      name={`appraisers.${index}.idEmployees`}
                      render={({ field, fieldState }) => (
                        <StyleAutocomplete
                          {...field}
                          disabled={isDisabled(index)}
                          options={employeesList ? employeesList : []}
                          noOptionsText={`${t("NoData")}`}
                          onChange={(_, newValue) => {
                            field.onChange(
                              newValue ? newValue.idEmployees : null
                            );
                            setValue(
                              `appraisers.${index}.firstname_EN`,
                              newValue.firstname_EN
                            );
                            setValue(
                              `appraisers.${index}.lastname_EN`,
                              newValue.lastname_EN
                            );
                            setValue(
                              `appraisers.${index}.firstname_TH`,
                              newValue.firstname_TH
                            );
                            setValue(
                              `appraisers.${index}.lastname_TH`,
                              newValue.lastname_TH
                            );
                          }}
                          getOptionLabel={(option) => {
                            //ดักในกรณีพวก manager roger
                            if (isDisabled(index)) {
                              return getUserFullName(
                                openDialog.data.appraisers[index]
                              );
                            }
                            const employee = employeesList.find(
                              (emp) => emp.idEmployees === option
                            );
                            return employee
                              ? getUserFullName(employee)
                              : "ไม่พบข้อมูล";
                          }}
                          renderOption={(props, option) => (
                            <MenuItem {...props} key={option.idEmployees}>
                              <Box>
                                <Typography variant="subtitle1">
                                  {getUserFullName(option)}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  style={{ color: "#00000080" }}
                                >{`Position: ${option.positionName}`}</Typography>
                              </Box>
                            </MenuItem>
                          )}
                          disableClearable
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              helperText={
                                fieldState.error ? fieldState.error.message : ""
                              }
                              error={!!fieldState.error}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider variant="middle" sx={{ margin: "0px 0px" }} />
                  </Grid>
                </Grid>
              );
            })}
            {true && (
              <Grid item xs={12}>
                <ButtonBlue
                  variant="outlined"
                  onClick={() => {
                    append({
                      idEmployees: null,
                      firstname_TH: null,
                      lastname_TH: null,
                      firstname_EN: null,
                      lastname_EN: null,
                      role: null,
                      ratio: 0,
                    });
                  }}
                >
                  เพิ่มผู้ประเมิน
                </ButtonBlue>
              </Grid>
            )}
            <Grid item xs={12}>
              <ButtonBlue variant="contained" type="submit">
                {t("บันทึก")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
