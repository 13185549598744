import React, { useState } from 'react';
import { Box, Typography, Button, Menu, MenuItem, Grid, List, ListItem, ListItemIcon, ListItemText, Container, Autocomplete, TextField, } from '@mui/material';

import KeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import { NavLink } from 'react-router-dom';
import { makeStyles } from "@mui/styles"; 

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "48px",
    fontWeight: 450,
    marginTop: "60px",
    textAlign: "left",
    whiteSpace: "nowrap",
    "@media only screen and (max-width: 800px)": {
      whiteSpace: "nowrap",

    },

  },
  filterContainer: {
    marginLeft: 300,
    marginRight: 0,
    marginTop: "80px",
    "@media only screen and (max-width: 800px)": {
      marginLeft: "50px",
      
    },
  },
  numTitle: {
    fontSize: "28px",
    textAlign: "left",
    whiteSpace: "nowrap",
    marginLeft: "5px",
    "@media only screen and (max-width: 800px)": {
      whiteSpace: "nowrap",
      marginTop: "0px",
      marginRight: "20px",
      textAlign: "left",
      marginLeft: "-20px",
    },
  },
  subNumTitle: {
    color: "rgba(83, 24, 129, 0.7)",
    fontSize: "16px",
    fontWeight: 200,
    display: "block",
    textAlign: "left",
    lineHeight: 1,
    marginLeft: "5px",
    "@media only screen and (max-width: 800px)": {
      whiteSpace: "nowrap",
      textAlign: "left",
      marginLeft: "-20px",
    },
  },
  packageName: {
    fontSize: "32px",
    fontWeight: 450,
    "@media only screen and (max-width: 800px)": {
      fontSize: "22px", 
      textAlign: "left",
    },
  },
  packagePrice: {
    fontSize: "32px",
    fontWeight: 450,
    textAlign: "right",
    "@media only screen and (max-width: 800px)": {
      fontSize: "22px", 
      textAlign: "right",
    },
  },
  packageUnit: {
    fontSize: 16,
    fontWeight: 200,
    marginTop: '-5px',
    textAlign: 'right',
    display: 'block',
    width: '100%',
    "@media only screen and (max-width: 800px)": {
      fontSize: 14, 
      textAlign: 'right',
    },
  },
  listContainer: {
    display: 'flex',           
    justifyContent: 'space-between',  
    width: '100%',  
    "@media only screen and (max-width: 800px)": {
      fontSize: "5px", 
     
    },

  },
  listLeft: {
    listStyleType: 'none',      
    paddingLeft: 0,             
    margin: 0,                  
    flex: 1,                    
  },
  listRight: {
    listStyleType: 'none',      
    paddingLeft: 0,             
    margin: 0,                 
    flex: 1,  
    width: '100%',
    "@media only screen and (max-width: 800px)": {
      marginLeft: "-50px"
    },                   
  },
  listItem: {
    transform: 'translateX(-15px)',
  },
  listItemText: {
    transform: 'translateX(-40px)',
    fontSize: '14px !important',
    "@media only screen and (max-width: 800px)": {
      fontSize: '10px !important',
    },   
  },
  button: {
    marginTop: "30px",
    marginBottom: 1,
    borderRadius: 50,
    height: 53,
    width: '100%',
    fontSize: 24,
    "@media only screen and (max-width: 800px)": {
      fontSize: 16, 
      height: 40,
      marginTop: "20px"
    },
  },
  container: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  box: {
    [theme.breakpoints.up('sm')]: {
      width: '48%', // ใช้ 48% เพื่อให้ทั้งสองกล่องอยู่ข้างกัน
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%', // ใช้ 100% เมื่อหน้าจอเล็กกว่า
      marginBottom: theme.spacing(4),
    },
  },

}));

export default function PriceBox() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(t("lessThan100People"));
  const [dense, setDense] = useState(true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
    handleClose();
  };

  const onSelectOption = (value) => {
    setSelectedOption(value);
  };

  const generateListItem = (text, isLeft = true) => (
    <ListItem className={classes.listItem}>
      <ListItemIcon sx={{ fontSize: "small", color: isLeft ? "rgba(83, 24, 129, 0.7)" : "rgba(235, 148, 192, 1)", marginRight: "15px" }}>
        <FiberManualRecordRoundedIcon />
      </ListItemIcon>
      <ListItemText primary={<Typography variant="body1">{text}</Typography>} className={classes.listItemText} />
    </ListItem>
  );

    return (
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '20px auto' }}>
          <Typography variant="h2" align="left" className={classes.title}>
            {t("initialPriceTitle")}
          </Typography>
          <Box className={classes.filterContainer}>
            <Typography variant="h5" align="left" className={classes.numTitle}>
            {t("employeeNumberTitle")}
            </Typography>
            <Typography variant="caption" align="left" className={classes.subNumTitle}>
            {t("employeeNumberDescription")}
            </Typography>
          </Box>
       
    
{/*         <Box sx={{ marginTop: 4 }}>
          <Button
            variant="contained"
            onClick={handleClick}
            endIcon={<KeyboardArrowDownRounded />}
            sx={{
              maxWidth: '500px',
              whiteSpace: 'nowrap',
              width: 'auto',
              height: '55px',
              fontSize: '20px',
              backgroundColor: '#ffffff',
              color: "rgba(83, 24, 129, 0.7)",
              borderColor: '#C2B0E5',
              borderRadius: '22px',
              boxShadow: 'none',
              border: '2px solid #C2B0E5',
              bottom: '-20px',
              margin: '0 20px',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: '#ffffff', 
                boxShadow: 'none',
              },
              '@media (max-width: 800px)': {
                fontSize: '18px',
                width: '100%', 
                height: '50px',
                marginTop: '12px', 
                marginLeft: '0px',
              },
            }}
          >
            {selectedOption}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            PaperProps={{ sx: { width: 'auto', padding: '2px 10px', boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)' } }}
          >
            <MenuItem onClick={() => handleSelect(selectedOption === t("lessThan100People") ? t("moreThan100People") : t("lessThan100People"))}>
              {selectedOption === t("lessThan100People") ? t("moreThan100People") : t("lessThan100People")}
            </MenuItem>
          </Menu>
        </Box> */}

        <Box maxWidth="220px" width="100%" sx={{ transform: 'translateY(40px)'}}>
          <Autocomplete
            options={selectedOption === t("lessThan100People") ? [t("moreThan100People")] : [t("lessThan100People")]}
            getOptionLabel={(option) => option}
            renderOption={(props, option) => (
              <MenuItem {...props} key={option}>
                <Box>
                  <Typography>{option}</Typography>
                </Box>
              </MenuItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="SelectOption"
              />
            )}
            value={selectedOption}
            onChange={(_, value) => {
              onSelectOption(value);
            }}
            noOptionsText="NoData"
            disableClearable
          />
        </Box>

      </Box> 
    
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12} sm={6} className={classes.box}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 4,
                borderRadius: '22px',
                boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
                backgroundColor: 'white',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography variant="h4" className={classes.packageName} sx={{ color: "rgba(83, 24, 129, 0.7)!important",}} >
                  Lite Package
                </Typography>
                <div>
                  <Typography variant="h5" className={classes.packagePrice} sx={{ color: "rgba(83, 24, 129, 0.7)!important",}} >
                    {selectedOption === t("moreThan100People")  ? '30-80' : '3,000-8,000'}
                  </Typography>
                  <Typography variant="caption" className={classes.packageUnit}>
                    {selectedOption === t("moreThan100People") ? t("perPersonPerMonth") : t("perMonth")}
                  </Typography>
                </div>
              </Box>
    
              <Box className={classes.listContainer}>
                <List className={classes.listLeft}>
                  {generateListItem(t("employeeDatabase"), true)}
                  {generateListItem(t("leaveAndApproval"), true)}
                  {generateListItem(t("otAndApproval"), true)}
                  {generateListItem("Employee Self Service", true)}
                  {generateListItem("Web Application", true)}
                </List>
                <List className={classes.listRight}>
                  {generateListItem(t("timeInOut"), true)}
                  {generateListItem(t("workShiftManagement"), true)}
                  {generateListItem(t("workflowApproval"), true)}
                  {generateListItem("Dashboard Analytic", true)}
                </List>
              </Box>
    
              <Button
                component={NavLink}
                to="/contact-us"
                variant="contained"
                className={classes.button}
                sx={{
                  backgroundColor: '#531881',
                  '&:hover': {
                    backgroundColor: '#ffffff',  // พื้นหลังเป็นสีขาว
                    color: '#531881',  // ตัวอักษรเป็นสีม่วง
                    border: "3px solid #531881",  // ขอบเป็นสีม่วง
                  },
                }}
              >
                {t("contactForFreeTrial")}
              </Button>
            </Box> 
          </Grid>
    
          <Grid item xs={12} sm={6} className={classes.box}>
            {/* Right Box Content */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 4,
                borderRadius: '22px',
                boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
                backgroundColor: 'white',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography variant="h4" className={classes.packageName} sx={{ color: "#EB94C0 !important", }} >
                  Standard Package
                </Typography>
                <div>
                  <Typography variant="h5" className={classes.packagePrice} sx={{ color: "rgba(235, 148, 192, 1)!important",}} >
                    {selectedOption === t("moreThan100People") ? '40-100' : '5,000-15,000'}
                  </Typography>
                  <Typography variant="caption" className={classes.packageUnit}>
                    {selectedOption === t("moreThan100People") ? t("perPersonPerMonth") : t("perMonth")}
                  </Typography>
                </div>
              </Box>
    
              <Box className={classes.listContainer}>
                <List className={classes.listLeft}>
                  {generateListItem(t("employeeDatabase"), false)}
                  {generateListItem(t("leaveAndApproval"), false)}
                  {generateListItem(t("otAndApproval"), false)}
                  {generateListItem("Employee Self Service", false)}
                  {generateListItem(t("payrollSystem"), false)}
                  {generateListItem(t("documentsAndCertificates"), false)}
                  {generateListItem("Web Application", false)}
                </List>
                <List className={classes.listRight}>
                  {generateListItem(t("timeInOut"), false)}
                  {generateListItem(t("workShiftManagement"), false)}
                  {generateListItem(t("workflowApproval"), false)}
                  {generateListItem("Dashboard Analytic", false)}
                  {generateListItem(t("onlinePayslip"), false)}
                  {generateListItem("Report/Analytic", false)}
                  {generateListItem("Mobile Application", false)}
                </List>
              </Box>
    
              <Button
                component={NavLink}
                to="/contact-us"
                variant="contained"
                className={classes.button}
                sx={{
                  backgroundColor: '#000000',
                  '&:hover': {
                    backgroundColor: '#ffffff',  
                    color: '#000000',  
                    border: "3px solid #000000",  
                  },
                }}
              >
                 {t("contactForMoreInfo")}
              </Button>
            </Box> 
          </Grid>
        </Grid>
    
 
</Container>

)

}