import React, { useState, useEffect } from "react";
import {
  Grid,
  Avatar,
  Typography,
  Container,
  IconButton,
  Chip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import VerifiedIcon from "@mui/icons-material/Verified";
import StarIcon from "@mui/icons-material/Star";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import TodayIcon from "@mui/icons-material/Today";
import EventIcon from "@mui/icons-material/Event";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { getEmployeeProject } from "../../../../actions/project";
import CardStyle from "../../shared/general/Card";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../utils/userData";
import Utils from "../../../../utils";
import { Redirect, useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "20px",
    minHeight: "calc(100vh - 20px)",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  nameText: {
    fontSize: 29,
    fontWeight: "bold",
    marginBottom: 20,
  },
  colorMD: {
    borderTop: "5px solid green",
  },
  colorMG: {
    borderTop: "5px solid red",
  },
  colorSM: {
    borderTop: "5px solid blue",
  },
  bigAvatar: {
    width: 150,
    height: 150,
    margin: 20,
  },
  icon: {
    fontSize: 30,
  },
  borderIcon: {
    height: 50,
    padding: 10,
    textAlign: "center",
    borderRadius: "10%",
  },
  topic: {
    marginBottom: 8,
    fontSize: 20,
    fontWeight: 500,
  },
  dataInTopic: {
    fontSize: 16,
    padding: 8,
    fontWeight: 500,
  },
}));

const PaperSowData = ({
  name,
  value,
  icon,
}) => {
  const classes = useStyles();
  return (
    <Grid
      item
      xl={3}
      lg={3}
      md={6}
      sm={6}
      xs={12}
      style={{ textAlign: "center" }}
    >
      <div style={{ marginLeft: 30, marginRight: 30 }}>
        <IconButton disabled size="large" style={{ color: "#DB4178" }}>
          {icon}
        </IconButton>
        <Typography style={{ fontSize: 23, fontWeight: "bold", marginTop: 10 }}>
          {value}
        </Typography>
        <Typography style={{ fontSize: 15 }}>{name}</Typography>
      </div>
    </Grid>
  );
};

const ShowProjectDetail = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [projectData, setProjectData] = useState(null);
  const { state } = useLocation();

  if (!(state && state.idEmployeeProject)) {
    return <Redirect to="/project" />;
  }

  useEffect(() => {
    const fetchedData = async (idEmployeeProject) => {
      try {
        const response = await getEmployeeProject(idEmployeeProject);
        if (response && response.data) {
          setProjectData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchedData(state.idEmployeeProject);
  }, [state.idEmployeeProject]);

  return (
    <div className={`page ${classes.root}`}>
      <Container>
        <Typography variant="h5" fontWeight={500} marginBottom={4}>{t("Details")}</Typography>
        {projectData && (
          <CardStyle
            className={[
              `${projectData.size == "big" && classes.colorMG}`,
              `${projectData.size == "medium" && classes.colorMD}`,
              `${projectData.size == "small" && classes.colorSM}`,
            ].join(" ")}
          >
            <div style={{ padding: 20 }}>
              <Typography
                className={classes.nameText}
                style={{ textAlign: "center" }}
              >
                {projectData.projectName}
              </Typography>
              <CardStyle
                style={{
                  backgroundColor: "rgba(244, 245, 245, 1)",
                  padding: 20,
                  marginTop: "10px",
                  marginBottom: "24px",
                }}
              >
                <Grid container spacing={2.5}>
                  <PaperSowData
                    name={t("Type")}
                    value={projectData.type}
                    icon={
                      <DynamicFeedIcon
                        className={classes.icon}
                        sx={{ color: "primary.darker" }}
                      />
                    }
                    backgroundColor="primary"
                    bgIconColor1="#b8a3ef"
                    bgIconColor2="#7b5dca"
                  />

                  <PaperSowData
                    name={t("Impact")}
                    value={projectData.impact}
                    icon={
                      <StarIcon
                        className={classes.icon}
                        sx={{ color: "primary.darker" }}
                      />
                    }
                    backgroundColor="primary"
                    bgIconColor1="#4ac91d"
                  />

                  <PaperSowData
                    name={t("DirectReturn")}
                    value={projectData.directReturn ? t("Yes") : t("No")}
                    icon={
                      <AssignmentReturnIcon
                        className={classes.icon}
                        sx={{ color: "error.darker" }}
                      />
                    }
                    backgroundColor="error"
                    bgIconColor1="#21b232"
                  />

                  <PaperSowData
                    name={t("Return")}
                    value={Utils.numberWithCommasWithoutDot(
                      projectData.return
                    )}
                    icon={
                      <AttachMoneyIcon
                        className={classes.icon}
                        sx={{ color: "error.darker" }}
                      />
                    }
                    backgroundColor="error"
                    bgIconColor1="#d94343"
                  />

                  <PaperSowData
                    name={t("Size")}
                    value={projectData.size}
                    icon={
                      <AspectRatioIcon
                        className={classes.icon}
                        sx={{ color: "success.darker" }}
                      />
                    }
                    backgroundColor="success"
                    bgIconColor1="#cd34c8"
                  />
                  <PaperSowData
                    name={t("Role")}
                    value={projectData.role}
                    icon={
                      <PeopleAltIcon
                        className={classes.icon}
                        sx={{ color: "success.darker" }}
                      />
                    }
                    backgroundColor="success"
                    bgIconColor1="#ed9c4c"
                  />
                  <PaperSowData
                    name={t("StartDate")}
                    value={dayjs(projectData.dateStart).format(
                      i18n.resolvedLanguage === "th" ? "BBBB-MM-DD" : "YYYY-MM-DD"
                    )}
                    icon={
                      <TodayIcon
                        className={classes.icon}
                        sx={{ color: "warning.darker" }}
                      />
                    }
                    backgroundColor="warning"
                    bgIconColor1="#0867bc"
                  />
                  <PaperSowData
                    name={t("EndDate")}
                    value={dayjs(projectData.dateEnd).format(
                      i18n.resolvedLanguage === "th" ? "BBBB-MM-DD" : "YYYY-MM-DD"
                    )}
                    icon={
                      <EventIcon
                        className={classes.icon}
                        sx={{ color: "warning.darker" }}
                      />
                    }
                    backgroundColor="warning"
                    bgIconColor1="#2b9cc5"
                  />
                </Grid>
              </CardStyle>
              <div style={{ marginBottom: 32 }}>
                <Typography className={classes.topic}>
                  {t("Status")} :
                </Typography>
                <div style={{ padding: "8px" }}>
                  <Chip
                    color={
                      projectData.isApproved === 1
                        ? "success"
                        : projectData.isApproved === 0
                        ? "error"
                        : "warning"
                    }
                    label={
                      projectData.isApproved === 1
                        ? t("Approved")
                        : projectData.isApproved === 0
                        ? t("NotApproved")
                        : t("WaitingForApproval")
                    }
                  />
                </div>
                <Typography className={classes.topic}>
                  {t("Reason")} :
                </Typography>
                <Typography className={classes.dataInTopic}>
                  {projectData.reason}
                </Typography>
                <Typography className={classes.topic}>
                  {t("Note")} :
                </Typography>
                <Typography className={classes.dataInTopic}>
                  {projectData.note}
                </Typography>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                    <VerifiedIcon sx={{ color: "#210c96" }} />
                    <Typography fontSize={22} fontWeight={500} color={"#210c96"}>
                      {t("Approver")}
                    </Typography>
                  </div>
                  <Avatar
                    src={projectData.imageURL}
                    className={classes.bigAvatar}
                  />
                  <Typography
                    style={{
                      fontSize: 20,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {getUserFullName({
                      firstname_TH: projectData.appoveBy_firstname_TH,
                      lastname_TH: projectData.appoveBy_lastname_TH,
                      firstname_EN: projectData.appoveBy_firstname_EN,
                      lastname_EN: projectData.appoveBy_lastname_EN,
                    })}
                  </Typography>
                  <Typography fontWeight={500}>
                    {projectData.verifyDate
                      ? dayjs(projectData.verifyDate).format(
                          i18n.resolvedLanguage === "th"
                            ? "BBBB-MM-DD"
                            : "YYYY-MM-DD"
                        )
                      : t("PendingApproval")}
                  </Typography>
                </div>
              </div>
            </div>
          </CardStyle>
        )}
      </Container>
    </div>
  );
};

export default ShowProjectDetail;
