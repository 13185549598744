import React from "react";
import { styled } from "@mui/material/styles";

import {
  Typography,
  Box,
  Div,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../utils/userData";

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledTable = styled(Table)({
  maxWidth: 640,
  marginBottom: 24,
  "& .MuiTableCell-root": {
    textAlign: "center",
    "&.nothing": {
      padding: 32,
    },
  },
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
  },
  "& .tableCellHead:first-of-type": {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "& .tableCellHead:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledDiv = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyItems: "center",
  alignItems: "center",
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const Actions = (props) => {
  const { actions } = props;
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="wrap-head">
        <StyledHeadLabel variant="h6">
          {t("TransactionHistory")}
        </StyledHeadLabel>
      </div>
      <StyledDiv>
        <StyledTable>
          <TableHead>
            <TableCell className="tableCellHead">{t("Date")}</TableCell>
            <TableCell className="tableCellHead">{t("FullName")}</TableCell>
            <TableCell className="tableCellHead">{t("ActionType")}</TableCell>
          </TableHead>
          <TableBody>
            {actions.map((action) => (
              <TableRow>
                <TableCell>
                  {dayjs(action.date).format(
                    i18n.resolvedLanguage === "th"
                      ? "DD/MM/BBBB HH:mm"
                      : "DD/MM/YYYY h:mm A"
                  )}
                </TableCell>
                <TableCell>{getUserFullName(action)}</TableCell>
                <TableCell>{action.actionType}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
        <StyledDivider />
      </StyledDiv>
    </div>
  );
};

export default Actions;
