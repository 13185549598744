import React, { useState } from "react";
import { Box, Container, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import ShowProject from "./showProject";
import EmployeeReward from "./Reward";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProjectAndReward = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={`page`}>
      <Container>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={t("Project")} {...a11yProps(0)} />
            <Tab label={t("Reward")} {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <ShowProject />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <EmployeeReward />
        </CustomTabPanel>
      </Container>
    </div>
  );
};

export default ProjectAndReward;
