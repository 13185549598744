import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Autocomplete,
  styled,
  IconButton,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import DrawerCustom from "../../shared/general/Drawer";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { addJobDescriptions ,getAllJobDescription,updateJobDescription} from "../../../../actions/jobDescription";
import { useDispatch } from "react-redux";
const StyleAutocomplete = styled(Autocomplete)({
  "& .MuiOutlinedInput-root": {
    padding: "16px 12px",
    "& .MuiInputBase-input": {
      padding: "0 !important",
    },
  },
});

const AddJobDescription = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { drawerConfig, onClose ,idPosition,setSnackBarConfig} = props;

  const validateYupSchema = yup.object().shape({
    tasks: yup.array().of(
      yup.object().shape({
        task: yup.string().trim().max(200,"ข้อความไม่เกิน 200 ตัวอักษร").required("กรุณากรอกข้อมูล"),
        core: yup
          .object({
            label: yup.string().required(),
            value: yup.number().required(),
          })
          .required("กรุณาระบุ core"),
        percent: yup
          .number("กรุณากรอกข้อมูลที่เป็นตัวเลข")
          .typeError("กรุณากรอกข้อมูลที่เป็นตัวเลข")
          .required("กรุณากรอกข้อมูล")
          .positive("ไม่สามารถมีค่าน้อยกว่า 0")
          .min(0, "ไม่สามารถมีค่าน้อยกว่า 0")
          .max(100, "ไม่สามารถมีค่ามากกว่า 100"),
      })
    ),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tasks: [{ task: "", core: { label: "Critical", value: 1 }, percent: 0 }],
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tasks",
  });



  const onSubmit = async(data) => {
    let formData = [];
    let result;
    for(const e of data.tasks){
      const temp ={
        "idJobDescription":null, 
        "task":e.task,
        "type_core":e.core.value,
        "coreName":e.core.label,
        "weight":e.percent,
        "idPosition":idPosition
      };
      formData.push(temp);
    }
    if(drawerConfig.isEdit){
      formData[0].idJobDescription = drawerConfig.data.idJobDescription
      result = await dispatch(updateJobDescription(formData[0]));
    }else{
    result = await dispatch(addJobDescriptions(formData));
    }
    if(result && result.status === 200){
      //show notification
      setSnackBarConfig({
        open: true,
        message: `${t("Successfully")}`,
        type: "success",
      });
      //clear data 
      reset({
        tasks: [
          {
            task: "",
            core: { label: "Critical", value: 1 },
            percent: 0,
          },
        ],
      });
      //close drawer
      onClose()
      dispatch(getAllJobDescription(idPosition));
    }else{
      // show notification error
      setSnackBarConfig({
        open: true,
        message: `${t("Unsuccessful")}`,
        type: "error",
      });
    }
  };


  useEffect(() => {
    console.log("call reset");
    reset({
      tasks: [
        {
          task: "",
          core: { label: "Critical", value: 1 },
          percent: 0,
        },
      ],
    });


    if (drawerConfig && drawerConfig.data != null) {
      console.log(drawerConfig.data.weight); // object  not array
      reset({
        tasks: [
          {
            task: drawerConfig.data.task,
            core:
              drawerConfig.data.type_core === 1
                ? { label: "Critical", value: 1 }
                : { label: "Non-Critical", value: 2 },
            percent: drawerConfig.data.weight,
          },
        ],
      });
    }
  }, [drawerConfig]);

  return (
    <DrawerCustom
      title={
        drawerConfig.isEdit ? "แก้ไขรายละเอียดงาน" : "เพิ่มรายละเอียดงาน"
      }
      anchor={"right"}
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "600px",
          padding: "16px",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ padding: "16px 24px" }}>
          {fields.map((item, index) => (
            <Grid
              container
              spacing={1}
              key={item.id}
              sx={{ borderBottom: "1px solid #e0e0e0", paddingBottom: 2 }}
            >
              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <Typography>{t("Task")} {index + 1}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  textAlign="left"
                  fontSize="14px"
                  fontWeight="600"
                  marginBottom="4px"
                >
                  {t("Task")}
                </Typography>
                <Controller
                  name={`tasks.${index}.task`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextFieldTheme
                      {...field}
                      multiline
                      inputProps={{
                        maxLength: 200,
                      }}
                      rows={4}
                      helperText={
                        fieldState.error ? fieldState.error.message : ""
                      }
                      error={!!fieldState.error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  textAlign="left"
                  fontSize="14px"
                  fontWeight="600"
                  marginBottom="4px"
                >
                  {t("core")}
                </Typography>
                <Controller
                  name={`tasks.${index}.core`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <StyleAutocomplete
                      {...field}
                      disableClearable
                      options={[
                        { label: "Critical", value: 1 },
                        { label: "Non-Critical", value: 2 },
                      ]}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      onChange={(_, selectedOption) => {
                        field.onChange(selectedOption ? selectedOption : null);
                      }}
                      defaultValue={{ label: "Critical", value: 2 }}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          helperText={
                            fieldState.error ? fieldState.error.message : ""
                          }
                          error={!!fieldState.error}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  textAlign="left"
                  fontSize="14px"
                  fontWeight="600"
                  marginBottom="4px"
                >
                  {t("Weight")}(%)
                </Typography>
                <Controller
                  name={`tasks.${index}.percent`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextFieldTheme
                      {...field}
                      helperText={
                        fieldState.error ? fieldState.error.message : ""
                      }
                      error={!!fieldState.error}
                    />
                  )}
                />
              </Grid>
              {
                !drawerConfig.isEdit  && (
                  <Grid item xs={12} textAlign="right">
               
              </Grid>
                )
              }
            </Grid>
          ))}
          {!drawerConfig.isEdit && (
            <Grid item xs={12}>
              <ButtonBlue
                onClick={() =>
                  append({
                    task: "",
                    core: { label: "Critical", value: 1 },
                    percent: 0,
                  })
                }
                variant="outlined"
              >
                {t("AddTask")}
              </ButtonBlue>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sx={{
              marginBottom: "20px",
              display:"flex",
              justifyContent:"end"
            }}
          >
            <ButtonBlue type="submit" variant="contained">
              {
                drawerConfig.isEdit? "บันทึกการแก้ไข":t("บันทึก")
              }
            </ButtonBlue>
          </Grid>
        </Grid>
      </form>
    </DrawerCustom>
  );
};

export default AddJobDescription;
