import React, { useState, useEffect, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './styles/quillEditor.css';
import { Select, MenuItem, FormControl, InputLabel, ListItemIcon, Box, ListItemText, TextField, styled, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReactDOM from 'react-dom';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { useTranslation } from 'react-i18next';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ButtonBlue from '../../../../../../shared/general/ButtonBlue';
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#ADD8E6',
    boxShadow: '0 3px 5px rgba(0,0,0,0.3)',
    '&:hover': {
      backgroundColor: '#87CEEB',
    },
  },
}));

const StyledInstructionsButton = styled(Button)({
  backgroundColor: '#B0BEC5',
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.3)',
  opacity: 0.9,
  color: '#FFFFFF',
  fontWeight: 'semi-bold',
  borderRadius: '10px',
  // padding: '15px 12px',
  width: '44.5px',
  height: '44.5px',
  minWidth: '44.5px',
  fontSize: '1.1rem',
  backgroundImage: 'linear-gradient(45deg, #CFD8DC, #B0BEC5)',
});

// Customize and register Size format
const Size = Quill.import('attributors/style/size');
Size.whitelist = ['16px', '18px', '20px', '24px', '28px', '32px'];
Quill.register(Size, true);

const Font = Quill.import('formats/font');
Font.whitelist = ['THSarabunNew', 'serif', 'NotoSansThai'];
Quill.register(Font, true);

const QuillEditor = ({ value, setValue, handleOnSubmit, status, handleDownloadPdf, setShowPopup, row, isDisable }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [signature, setSignature] = useState('');
  const [showList, setShowList] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const history = useHistory();
  const [optionData] = useState([
    "employeeID",
    "title_TH",
    "firstname_TH",
    "lastname_TH",
    "title_EN",
    "firstname_EN",
    "lastname_EN",
    "nickname_TH",
    "gender_TH",
    "gender_EN",
    "personalID",
    "nationality",
    "religion",
    "maritalStatus",
    "passportNumber",
    "workPermitNumber",
    "taxID",
    "ssoID",
    "birthday",
    "houseNo",
    "village",
    "villageNo",
    "alley",
    "road",
    "subDistrict",
    "district",
    "provience",
    "areaCode",
    "username",
    "email",
    "telephoneMobile",
    "emergencyContact",
    "emergencyRelationship",
    "emergencyPhone",
    "numberOfChildren",
    "spouseFirstname",
    "spouseLastname",
    "spouseBirthday",
    "spouseTaxID",
    "employmentTypeName",
    "employmentTime",
    "isFinger",
    "workingLocation",
    "groupGpsLocationName",
    "filed_office",
    "jobLevelName",
    "personnelLevelGroupName",
    "personnelLevelName",
    "jobGroupName",
    "subJobGroupName",
    "positionName",
    "companyName",
    "branchName",
    "businessUnitName",
    "divisionName",
    "departmentName",
    "sectionName",
    "hiringDate",
    "managerLV1_fullname",
    "managerLV1_positionName",
    "managerLV1_email",
    "managerLV2_fullname",
    "managerLV2_positionName",
    "managerLV2_email",
    "workingType",
    "shiftGroupName",
    "shiftName",
    "timeIn",
    "timeOut",
    "paymentTypeName",
    "paymentRoundName",
    "salary",
    "oldSalary",
    "diffSalary",
    "salaryDifference",
    "performance_TH",
    "performance_EN",
    "bonus",
    "lastPositionDate",
    "lastSalary",
    "lastSalaryDate",
    "lastSpecialAdjustmentIncrease",
    "lastSpecialAdjustmentIncreaseDate",
    "lastJobLevelDate",
    "lastPersonnelLevelDate",
    "contractType",
    "retirementDate",
    "contractTermainatoinDate",
    "contractTermainatoinStatus",
    "resignDate",
    "OESY_Y",
    "OESY_M",
    "OESY",
    "SERV_Y",
    "SERV_M",
    "SERV",
    "ESY_Y",
    "ESY_M",
    "ESY",
    "TIG_Y",
    "TIG_M",
    "TIG",
    "AGE_Y",
    "AGE_M",
    "AGE",
    "degree_1",
    "university_1",
    "faculty_1",
    "major_1",
    "fromYear_1",
    "endYear_1",
    "gpa_1",
    "degree_2",
    "university_2",
    "faculty_2",
    "major_2",
    "gpa_2",
    "fromYear_2",
    "endYear_2",
    "degree_3",
    "university_3",
    "faculty_3",
    "major_3",
    "gpa_3",
    "fromYear_3",
    "endYear_3",
    "performance_past_1",
    "performance_past_2",
    "performance_past_3",
    "performance_past_4",
    "performance_past_5",
    "potential_past_1",
    "potential_past_2",
    "potential_past_3",
    "leaderRole",
    "costCenter",
    "costElement",
    "io",
    "bookBank",
    "bookBankBranchName",
    "bookID",
    "reportBankBankName",
    "reportBankBankID",
    "reportBankBookBankID",
    "reportBankName",
    "reportBankRef",
    "Authorized_Person_1",
    "Authorized_Person_2",
    "Certifying_Authority_EN",
    "Certifying_Authority_TH",
    "Witness",
    "Position_Authorized_Person_1",
    "Position_Authorized_Person_2",
    "Position_Certifying_Authority_EN",
    "Position_Certifying_Authority_TH",
    "Position_Witness",
    "Warning_Level",
    "Warning_Title",
    "Warning_Detail",
    "Warning_Create_Date"
  ]);

  const [options] = useState([
    {
      value: "ลายเซ็นพนักงาน",
      translationKey: "Employee_Signature",
      icon: <AssignmentIcon fontSize="small" />,
      img: 'Employee'
    },
    {
      value: "ลายเซ็นผู้ออกหนังสือรับรอง",
      translationKey: "Signature_of_the_Certifying_Authority",
      icon: <SupervisorAccountIcon fontSize="small" />,
      img: 'Certifying_Authority'
    },
    {
      value: "ลายเซ็นพยาน",
      translationKey: "Witness_Signature",
      icon: <SupervisorAccountIcon fontSize="small" />,
      img: 'Witness'
    },
    {
      value: "ลายเซ็นผู้มีอำนาจของบริษัท คนที่ 1",
      translationKey: "Authorized_Signature_Person_1",
      icon: <SupervisorAccountIcon fontSize="small" />,
      img: 'Authorized_Person_1'
    },
    {
      value: "ลายเซ็นผู้มีอำนาจของบริษัท คนที่ 2",
      translationKey: "Authorized_Signature_Person_2",
      icon: <SupervisorAccountIcon fontSize="small" />,
      img: 'Authorized_Person_2'
    },
  ]);

  const [listPosition, setListPosition] = useState({ top: 0, left: 0 });
  const [searchTerm, setSearchTerm] = useState('');
  const quillRef = useRef(null);

  const modules = {
    toolbar: [
      [{ font: Font.whitelist }],
      [{ size: Size.whitelist }],
      ['bold', 'italic', 'underline'],
      [{ align: [] }],
      ['image'],
    ],
  };

  const formats = ['font', 'size', 'bold', 'italic', 'underline', 'align', 'image'];

  const handleChange = (value) => {
    setValue(value);
  };

  const handleEditorKeyUp = (e) => {
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection();

    if (range) {
      const cursorPosition = range.index;
      const lastCharacter = quill.getText(cursorPosition - 1, 1);
      const beforeCharacter = quill.getText(cursorPosition - 2, 1);
      if (lastCharacter === '{' || (lastCharacter === '{' && beforeCharacter === '{')) {
        setShowList(true);
        setFilteredList(optionData);

        const bounds = quill.getBounds(cursorPosition);
        const editorWidth = quill.root.offsetWidth;
        const isCursorNearRight = bounds.left > editorWidth / 2;
        setListPosition({
          top: bounds.bottom,
          left: isCursorNearRight ? bounds.left - 324 : bounds.left,
          quill,
          range,
        });
      } else {
        setShowList(false);
      }
    }
  };

  const handleSelectListItem = (item) => {
    const { quill, range } = listPosition;

    if (range) {
      const position = range.index;
      const cursorPosition = range.index;
      const lastCharacter = quill.getText(cursorPosition - 1, 1);
      const beforeCharacter = quill.getText(cursorPosition - 2, 1);
      let firsttext = '{'
      let cursorPoint = 3
      if (lastCharacter === '{' && beforeCharacter === '{') {
        firsttext = ''
        cursorPoint = 2
      }

      quill.insertText(position, `${firsttext}${item}}}`);
      quill.setSelection(position + item.length + cursorPoint, 0);
      setShowList(false);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  useEffect(() => {
    setFilteredList(optionData.filter(item => item.toLowerCase().includes(searchTerm.toLowerCase())));
  }, [searchTerm])

  const handleEditorClickOutside = (e) => {
    const quillEditor = quillRef.current.getEditor();
    const editorContainer = quillEditor.root;

    const editorElement = document.getElementById('showList');

    if (editorElement && !editorElement.contains(e.target) && !e.target.closest('.ql-toolbar')) {
      setShowList(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleEditorClickOutside);
    return () => {
      document.removeEventListener('click', handleEditorClickOutside);
    };
  }, []);

  const handleSignatureChange = (event) => {
    const signatureValue = event.target.value;
    setSignature(signatureValue);
  };

  const addImage = (imageURL) => {
    const quill = quillRef.current.getEditor();
    if (!quill) {
      console.error('Quill editor not found!');
      return;
    }

    const range = quill.getSelection() || { index: 0, length: 0 };
    const style = "width: 120px; height: auto;";
    const id = "image-id";
    const className = "image-class";
    const alt = "image-alt"
    if (imageURL && range) {
      const imageTag = `<img src="${imageURL}" style="${style}" id="${id}" class="${className}" alt="${alt}"/>`;
      quill.clipboard.dangerouslyPasteHTML(range.index, imageTag);
    } else {
      console.error('Invalid image URL or range');
    }
  };

  useEffect(() => {
    if (signature) {
      const signatureObj = options.find(item => item.translationKey === signature);
      const imageURL = signatureObj
        ? `/assets/signature/${signatureObj.img}.png`
        : '/assets/icon/120.png';
      addImage(imageURL);
    }
  }, [signature]);

  useEffect(() => {
    const toolbar = document.querySelector('.ql-toolbar');
    if (toolbar) {
      const existingSelect = toolbar.querySelector('.ql-signature');
      if (!existingSelect) {
        const signatureSelectWrapper = document.createElement('span');
        signatureSelectWrapper.classList.add('ql-signature');
        toolbar.appendChild(signatureSelectWrapper);

        const select = (
          <FormControl variant="standard" sx={{ minWidth: '200px' }}>
            <InputLabel id="signature-select-label">Select Signature</InputLabel>
            <Select
              labelId="signature-select-label"
              id="signature-select"
              value={signature}
              onChange={handleSignatureChange}
              label="Select Signature"
              size="medium"
              defaultValue=""
            >
              <MenuItem value="">Select Signature</MenuItem>
              {options.map((option) => (
                <MenuItem key={option.translationKey} value={option.translationKey}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    <ListItemIcon sx={{ minWidth: '24px' }}>{option.icon}</ListItemIcon>
                    <ListItemText
                      primary={t(option.translationKey)}
                      sx={{ fontSize: '0.8rem', margin: 0 }}
                    />
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );

        const renderMUIComponent = () => {
          const wrapper = document.querySelector('.ql-signature');
          if (wrapper) {
            wrapper.innerHTML = '';
            const div = document.createElement('div');
            wrapper.appendChild(div);
            ReactDOM.render(select, div);
          }
        };

        renderMUIComponent();
      }
    }
  }, [signature]);

  useEffect(() => {
    const fontPicker = document.querySelector('.ql-font .ql-picker-options');
    if (fontPicker) {
      fontPicker.querySelectorAll('.ql-picker-item').forEach(item => {
        const value = item.getAttribute('data-value');
        switch (value) {
          case 'THSarabunNew':
            item.innerText = 'TH Sarabun New';
            break;
          case 'serif':
            item.innerText = 'Serif';
            break;
          case 'NotoSansThai':
            item.innerText = 'Noto Sans Thai';
            break;
          default:
            item.innerText = value;
        }
      });
    }

  }, []);

  useEffect(() => {
    const fontPickerLabel = document.querySelector('.ql-font .ql-picker-label');
    const fontPickerOptions = document.querySelector('.ql-font .ql-picker-options');

    if (fontPickerLabel) {
      const defaultFontValue = 'THSarabunNew';
      const svgIcon = fontPickerLabel.querySelector('svg');
      const newLabelText = 'TH Sarabun New';

      fontPickerLabel.innerText = '';
      fontPickerLabel.appendChild(svgIcon);
      fontPickerLabel.appendChild(document.createTextNode(newLabelText));

      const quill = quillRef.current.getEditor();
      quill.format('font', defaultFontValue);
    }

    if (fontPickerOptions) {
      fontPickerOptions.addEventListener('click', (e) => {
        const selectedItem = e.target.closest('.ql-picker-item');
        if (selectedItem) {
          const selectedFontValue = selectedItem.getAttribute('data-value');
          const svgIcon = fontPickerLabel.querySelector('svg'); // Move this here to use it in the switch case

          switch (selectedFontValue) {
            case 'THSarabunNew':
              fontPickerLabel.innerText = '';
              fontPickerLabel.appendChild(svgIcon);
              fontPickerLabel.appendChild(document.createTextNode('TH Sarabun New'));
              break;
            case 'serif':
              fontPickerLabel.innerText = '';
              fontPickerLabel.appendChild(svgIcon);
              fontPickerLabel.appendChild(document.createTextNode('Serif'));
              break;
            case 'NotoSansThai':
              fontPickerLabel.innerText = '';
              fontPickerLabel.appendChild(svgIcon);
              fontPickerLabel.appendChild(document.createTextNode('Noto Sans Thai'));
              break;
            default:
              fontPickerLabel.innerText = '';
              fontPickerLabel.appendChild(svgIcon);
              fontPickerLabel.appendChild(document.createTextNode(selectedFontValue));
          }
        }
      });
    }

    const fontPicker = document.querySelector('.ql-font');
    if (fontPicker) {
      fontPicker.style.width = '150px';
    }

    return () => {
      if (fontPickerOptions) {
        fontPickerOptions.removeEventListener('click', (e) => { });
      }
    };
  }, []);

  const handleEditorKeyDown = (e) => {
    const quill = quillRef.current.getEditor();
    const contentHeight = quill.root.scrollHeight;

    if (e.key === 'Enter' && contentHeight > 940) {
      e.preventDefault();
      quill.history.undo();
      console.warn("Enter key disabled: Content height exceeds 908px, revert action performed.");
    }
  };

  return (
    <>
      {/* <Typography variant="h3" align={'center'}>{row && row.documentTypeName && row.documentTypeName}</Typography> */}
      <ReactQuill
        ref={quillRef}
        value={value}
        onChange={handleChange}
        onKeyUp={handleEditorKeyUp}
        onKeyDown={handleEditorKeyDown}
        theme="snow"
        modules={modules}
        formats={formats}
        style={{
          height: '100%',
          maxHeight: '1050px',
          minHeight: '942px',
          width: '100%',
          overflowY: 'auto',
          // paddingBottom: '50px',
        }} />
      {showList && (
        <div
          id='showList'
          style={{
            border: '1px solid #ccc',
            maxHeight: '150px',
            overflow: 'auto',
            position: 'absolute',
            backgroundColor: 'white',
            marginTop: '5px',
            left: `${listPosition.left + 24}px`,
            top: `${listPosition.top + 160}px`,
            zIndex: '10',
            padding: '8px',
            borderRadius: '6px'
          }}
        >

          <TextField
            label={t("Search")}
            variant="standard"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{
              padding: '0 8px 8px',
              width: 'calc(100% - 16px)',
              '& .MuiInputLabel-root': {
                paddingLeft: '8px',
              },
            }} />

          {filteredList.map((item, index) => (
            <div
              key={index}
              style={{ padding: '5px', cursor: 'pointer' }}
              onClick={() => handleSelectListItem(item)}
            >
              {item}
            </div>
          ))}
        </div>
      )}

      <div style={{
        position: 'fixed',
        bottom: '16px',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        justifyContent: 'center',
        gap: '16px',
      }}>
        <StyledInstructionsButton
          variant="contained"
          className={classes.button}
          onClick={() => {
            setShowPopup(true);
          }}
          startIcon={
            <HelpOutlineIcon style={{ width: "24px", height: "24px", marginLeft: "8px" }} />
          }
        >
        </StyledInstructionsButton >
        <ButtonBlue
          variant="contained"
          radius="style2"
          onClick={handleDownloadPdf}
          style={{ padding: "10px 40px", backgroundColor: '#daa7cd' }}
        >
          {t("Preview")}
        </ButtonBlue>
        <Button
          variant="outlined"
          style={{ color: 'red', border: '1px solid red', borderRadius: '8px' }}
          // onClick={() => {
          //   history.goBack();
          // }}
          component={Link}
          to="/warning-letter-template/setting"

        >
          {`${t("Back")}`}
        </Button>
        <ButtonBlue
          variant="contained"
          radius="style2"
          onClick={handleOnSubmit}
          disabled={!value || value === '<p><br></p>' || isDisable}
          style={{ padding: "10px 40px" }}
        >
          {t("Save")}
        </ButtonBlue>
      </div>

      {/* <div>
        <h3>Editor Output:</h3>
        <div>{value}</div>
      </div> */}
    </>
  );
};

export default QuillEditor;
