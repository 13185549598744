import * as React from "react";
import { Container, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles"; 

import { useTranslation } from 'react-i18next';

import Rewards from "../landing/image/Reward/reward.png";
import HRtechLogo from "../landing/image/Reward/hrTech-logo.png";
import PMATLogo from "../landing/image/Reward/pmat-logo.png";

const useStyles = makeStyles({
    wavyBg: {
        position: "relative", 
        height: 700,
        maxWidth: "1600px",
        width: "100%",
        zIndex: 1, 
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
          'linear-gradient(180deg, #FFFFFF -0.78%, rgba(255, 255, 255, 0) 99.22%), ' +
          'linear-gradient(90deg, rgba(235, 148, 192, 0.4) -0.51%, rgba(194, 176, 230, 0.4) 50.01%, rgba(165, 221, 231, 0.4) 99.49%)',
        backgroundRepeat: 'no-repeat',
    
        clipPath: 'polygon(0% 0%, 0% 82.5%, 1.69492% 84.3293%, 3.38983% 86.1385%, 5.08475% 87.9078%, 6.77966% 89.6179%, 8.47458% 91.25%, 10.1695% 92.7862%, 11.8644% 94.2098%, 13.5593% 95.505%, 15.2542% 96.6578%, 16.9492% 97.6555%, 18.6441% 98.487%, 20.339% 99.1435%, 22.0339% 99.6176%, 23.7288% 99.9041%, 25.4237% 100%, 27.1186% 99.9041%, 28.8136% 99.6176%, 30.5085% 99.1435%, 32.2034% 98.487%, 33.8983% 97.6555%, 35.5932% 96.6578%, 37.2881% 95.505%, 38.9831% 94.2098%, 40.678% 92.7862%, 42.3729% 91.25%, 44.0678% 89.6179%, 45.7627% 87.9078%, 47.4576% 86.1385%, 49.1525% 84.3293%, 50.8475% 82.5%, 52.5424% 80.6708%, 54.2373% 78.8616%, 55.9322% 77.0922%, 57.6271% 75.3821%, 59.322% 73.75%, 61.017% 72.2138%, 62.7119% 70.7902%, 64.4068% 69.495%, 66.1017% 68.3422%, 67.7966% 67.3446%, 69.4915% 66.513%, 71.1864% 65.8565%, 72.8814% 65.3824%, 74.5763% 65.0959%, 76.2712% 65%, 77.9661% 65.0959%, 79.661% 65.3824%, 81.3559% 65.8565%, 83.0509% 66.513%, 84.7458% 67.3446%, 86.4407% 68.3422%, 88.1356% 69.495%, 89.8305% 70.7902%, 91.5254% 72.2138%, 93.2203% 73.75%, 94.9153% 75.3821%, 96.6102% 77.0922%, 98.3051% 78.8616%, 100% 80.6708%, 100% 0%)',
        shapeOutside: 'polygon(0% 0%, 0% 82.5%, 1.69492% 84.3293%, 3.38983% 86.1385%, 5.08475% 87.9078%, 6.77966% 89.6179%, 8.47458% 91.25%, 10.1695% 92.7862%, 11.8644% 94.2098%, 13.5593% 95.505%, 15.2542% 96.6578%, 16.9492% 97.6555%, 18.6441% 98.487%, 20.339% 99.1435%, 22.0339% 99.6176%, 23.7288% 99.9041%, 25.4237% 100%, 27.1186% 99.9041%, 28.8136% 99.6176%, 30.5085% 99.1435%, 32.2034% 98.487%, 33.8983% 97.6555%, 35.5932% 96.6578%, 37.2881% 95.505%, 38.9831% 94.2098%, 40.678% 92.7862%, 42.3729% 91.25%, 44.0678% 89.6179%, 45.7627% 87.9078%, 47.4576% 86.1385%, 49.1525% 84.3293%, 50.8475% 82.5%, 52.5424% 80.6708%, 54.2373% 78.8616%, 55.9322% 77.0922%, 57.6271% 75.3821%, 59.322% 73.75%, 61.017% 72.2138%, 62.7119% 70.7902%, 64.4068% 69.495%, 66.1017% 68.3422%, 67.7966% 67.3446%, 69.4915% 66.513%, 71.1864% 65.8565%, 72.8814% 65.3824%, 74.5763% 65.0959%, 76.2712% 65%, 77.9661% 65.0959%, 79.661% 65.3824%, 81.3559% 65.8565%, 83.0509% 66.513%, 84.7458% 67.3446%, 86.4407% 68.3422%, 88.1356% 69.495%, 89.8305% 70.7902%, 91.5254% 72.2138%, 93.2203% 73.75%, 94.9153% 75.3821%, 96.6102% 77.0922%, 98.3051% 78.8616%, 100% 80.6708%, 100% 0%)',
        zIndex: -1,
    
        '@media only screen and (max-width: 800px)': {
            maxHeight: 320,
            height: "100%",
            width: "auto",
            marginTop: "400px",
          },
      },
    },
    rewardPic: {
        height: "240px", 
        '@media only screen and (max-width: 800px)': {
          width: "auto",  
          height: "180px", 
        },
      },
    });


export default function Reward() {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    return (
        <Container maxWidth="lg" className={classes.wavyBg}>
        
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "5px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={HRtechLogo} style={{ height: "66px", width: "auto", marginRight: "15px", marginLeft: "120px" }} />
                    <Typography
                        variant="h4"
                        align="left"
                        sx={{
                            lineHeight: 1,
                            fontSize: { sm: "16px", md: "24px", lg: "24px", xl: "24px" },
                            marginRight: { sm: "15px" },
                            marginLeft: { sm: "-8px" },
                        }}
                        >
                        THAILAND HR TECH 2022
                        <br />
                        <Typography
                            component="span"
                            sx={{
                            fontSize: { sm: "14px", md: "20px", lg: "20px", xl: "20px" },
                            }}
                        >
                            Conference & Exposition
                        </Typography>
                        <br />
                        <Typography
                            component="span"
                            sx={{
                            fontSize: { sm: "9px", md: "15px", lg: "15px", xl: "15px" },
                            fontWeight: 200,
                            }}
                        >
                            18 - 19 Oct 2022 @Royal Paragon Hall 5th
                        </Typography>
                    </Typography>
                </div>
                
                <img src={PMATLogo} style={{ height: "75px", width: "auto", marginRight: "70px" }} />
            </div>

            <Typography
                variant="h2"
                align="center"
                sx={{ fontSize: "48px", fontWeight: 400, marginTop: "30px", marginBottom: "20px" }}
            >
                {t("ourAwardsTitle")}
            </Typography>

            <Typography
                variant="h1"
                align="center"
                sx={{ fontSize: "36px", fontWeight: 500,  marginBottom: "30px", color: "#531881 !important", lineHeight:1, padding: { sm: "0px 40px", md: "0px 200px" }  }}
            >
                {t("awardDescription")}
            </Typography>

            <Typography
                variant="h2"
                align="center"
                sx={{ fontSize: "26px", fontWeight: 400, marginBottom: "50px", lineHeight: 1.25 }}
            >
                {t("awardHighlightTitle")}
                <br/>
                <span style={{ fontSize: "20px", lineHeight: 0.75 }}>
                    {t("awardHighlightSubtitle")}
                </span>
            </Typography>

            <div style={{ textAlign: "center" }}>
                <img src={Rewards} className={classes.rewardPic} />
            </div>


        </Container>

    )

}