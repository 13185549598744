import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getKpiPendingApprove,
  updateKPIApprove,
} from "../../../../../actions/kpi";
import {
  getUserFullName,
  getUserPosition,
} from "../../../../../utils/userData";
import TableCustom from "../../../shared/tableCustom";
import Loading from "../../../shared/loading";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import dayjs from "dayjs";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  borderRadius: "30px",
  overflow: "hidden",
  border: "1px solid #EEEEEE",
  display: "flex",
  justifyContent: "center",
  width: "fit-content",
});

const StyledToggleButton = styled(ToggleButton)(({ selectedColor }) => ({
  flex: 1,
  borderRadius: "30px",
  "&.Mui-selected": {
    backgroundColor: selectedColor,
    color: "#FFFFFF",
  },
  "&.MuiToggleButtonGroup-grouped": {
    border: "none",
    "&:not(:first-of-type)": {
      borderLeft: "1px solid #EEEEEE",
    },
  },
  "&:hover": {
    backgroundColor: `${selectedColor} !important`,
    color: "#FFFFFF",
  },
  backgroundColor: "#EEEEEE",
  color: "#000000",
  textAlign: "center",
  minWidth: "100px",
  maxWidth: "200px",
}));

const KpisIndividualPlanView = ({ data, open, handleClose, idCompany }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [approve, setApprove] = useState(1);

  const handleChangeApprove = (event, newAlignment) => {
    if (newAlignment !== null) {
      setApprove(newAlignment);
    }
  };

  const handleApprovalRequest = async () => {
    const formData = [{
      idKPI: data.idKPI,
      isApprove: approve,
    }];

    const res = await updateKPIApprove({ kpiData: formData });
    if (res) {
      dispatch(
        openNotificationAlert({
          type: "success",
          message: approve ? t("ApprovalSuccessful") : t("RejectionSuccessful"),
        })
      );
      dispatch(getKpiPendingApprove({ idCompany }));
    } else {
      dispatch(
        openNotificationAlert({
          type: "error",
          message: t("AnErrorOccurred"),
        })
      );
    }
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{ style: { borderRadius: "15px" } }}
    >
      <div
        style={{
          background: "rgb(79, 101, 223)",
          padding: "16px",
        }}
      >
        <Typography variant="h5" fontWeight={500} color="#ffffff">
          {t("KPIDetail")}
        </Typography>
      </div>
      <DialogContent>
        <Stack spacing={2}>
          <Typography fontSize="22px">{data.kpiName}</Typography>
          <Stack gap={1}>
            <Box
              display="flex"
              alignItems="center"
              gap={"8px"}
              marginBottom="8px"
            >
              <AccessTimeIcon fontSize="14px" style={{ color: "#919eab" }} />
              <Typography fontSize="14px" lineHeight="1" color="text.third">
                {`${dayjs(data.startDate).format(
                  i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                )} - ${dayjs(data.endDate).format(
                  i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                )}`}
              </Typography>
            </Box>
            <Typography>
              <span style={{ color: "#919eab", paddingRight: "8px" }}>
                {t("Year")}
              </span>
              {i18n.resolvedLanguage === "th" ? data.year + 543 : data.year}
            </Typography>
            <Typography>
              <span style={{ color: "#919eab", paddingRight: "8px" }}>
                {`${t("Scope")}`}
              </span>
              {data.scope}
            </Typography>
            <Typography>
              <span style={{ color: "#919eab", paddingRight: "8px" }}>
                {`${t("Target")}`}
              </span>
              {data.target}
            </Typography>
            <Typography>
              <span style={{ color: "#919eab", paddingRight: "8px" }}>
                {`${t("TypeMeasurement")}`}
              </span>
              {data.measureType}
            </Typography>
            <Typography>
              <span style={{ color: "#919eab", paddingRight: "8px" }}>
                {t("Evidence")}
              </span>
              {data.evidence}
            </Typography>
            <Typography>
              <span style={{ color: "#919eab", paddingRight: "8px" }}>
                {`${t("Descriptions")}`}
              </span>
              {data.description}
            </Typography>
            <Typography>
              <span style={{ color: "#919eab", paddingRight: "8px" }}>
                {`${t("Weight")}`}
              </span>
              {`${data.weight}%`}
            </Typography>
          </Stack>
          <StyledToggleButtonGroup
            value={approve}
            exclusive
            onChange={handleChangeApprove}
          >
            <StyledToggleButton value={1} selectedColor="#50B498">
              {t("Approve")}
            </StyledToggleButton>
            <StyledToggleButton value={0} selectedColor="#FF4C4C">
              {t("Reject")}
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        </Stack>
      </DialogContent>
      <DialogActions>
        <ButtonBlue onClick={handleClose}>{t("Close")}</ButtonBlue>
        <ButtonBlue variant="contained" onClick={handleApprovalRequest}>
          {t("Confirm")}
        </ButtonBlue>
      </DialogActions>
    </Dialog>
  );
};

const KpiAdminApprovePlan = ({ selectedCompany }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: kpiApproval, isFetching } = useSelector(
    (state) => state.kpiApproval
  );

  const [pendingRows, setPendingRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedKpi, setSelectedKpi] = useState(null);

  const handleOpen = (kpi) => {
    setOpen(true);
    setSelectedKpi(kpi);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedKpi(null);
  };

  const columns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>{getUserFullName(row)}</Typography>
            <Typography color="text.third" fontSize="14px">
              {getUserPosition(row)}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("KPIName")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Typography textAlign={"center"}>{row.kpiName}</Typography>
      ),
    },
    {
      name: `${t("Year")}`,
      headerTextAlign: "center",
      minWidth: "80px",
      width: "80px",
      cellRender: (row) => (
        <Typography textAlign={"center"}>
          {i18n.resolvedLanguage === "th" ? row.year + 543 : row.year}
        </Typography>
      ),
    },
    {
      name: `${t("Approver")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Typography textAlign={"center"}>
          {i18n.resolvedLanguage === "th"
            ? `${row.asessor_firstname_TH} ${row.asessor_lastname_TH}`
            : `${row.asessor_firstname_EN} ${row.asessor_lastname_EN}`}
        </Typography>
      ),
    },
    {
      name: `${t("Approve")}`,
      headerTextAlign: "center",
      minWidth: "120px",
      width: "120px",
      cellRender: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            align: "center",
          }}
        >
          <ButtonBlue variant="contained" onClick={() => handleOpen(row)}>
            {t("Approve")}
          </ButtonBlue>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getKpiPendingApprove({ idCompany: selectedCompany.idCompany }));
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (kpiApproval) {
      setPendingRows(kpiApproval);
    }
  }, [kpiApproval]);

  return (
    <>
      {isFetching ? (
        <Loading />
      ) : (
        <TableCustom columns={columns} rows={pendingRows ? pendingRows : []} />
      )}
      {open && (
        <KpisIndividualPlanView
          open={open}
          handleClose={handleClose}
          data={selectedKpi}
          idCompany={selectedCompany.idCompany}
        />
      )}
    </>
  );
};

export default KpiAdminApprovePlan;
