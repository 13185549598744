import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Typography,
  Container,
  styled,
  Card,
  Grid,
  LinearProgress,
  CardContent,
  FormControl, InputLabel, Select, MenuItem, 
  Autocomplete
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getKPIByAdmin } from "../../../../../actions/kpi";
import { useHistory } from "react-router-dom";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import ExcelJS from 'exceljs';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DialogDropzone from "../DialogDropzoneKpi";
import EmployeeSearch from "../EmployeeSearch";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { getUserFirstName, getUserFullName, getUserLastName, getUserPosition } from "../../../../../utils/userData";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { getAffiliateOrganization } from "../../../../../actions/company";

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const ContainerStyled = styled(Container)({
  height: "100%",
  paddingTop: "95px",
  backgroundColor: "transparent",
  minHeight: "100vh",
});

const GridStyled = styled(Grid)({
  backgroundColor: "#ffff",
  padding: "16px",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "30px",
  marginTop: "-10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const ScrollableContainer = styled(Box)({
  height: "640px",
  overflowY: "auto",
  paddingRight: "16px",
});

const StyledRoot = styled("div")({
  minHeight: "100vh",
  padding: "20px 0",
  "& .MuiContainer-root": {
    paddingBottom: 16,
  },
});

const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: "#DCFAF8",
  "& .MuiLinearProgress-bar": {
    borderRadius: 5,
    backgroundColor: "#16DBCC",
  },
}));

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const KpiCard = ({ data }) => {
  const history = useHistory();

  if (!data) {
    console.error("No data provided to KpiCard");
    return null;
  }

  const kpiApprove = data.kpis.filter(item => item.isApprove = 1)
  const totalWeight = kpiApprove.reduce((prev, curr) => prev + curr.weight , 0);
  const isOverWeight = totalWeight > 100;
  const isExactWeight = totalWeight === 100;
  const { t, i18n } = useTranslation();
  const handleCardClick = () => {
    history.push("/kpi-admin-evalution", { data });
  };

  return (
    <Card
      onClick={handleCardClick}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        borderRadius: "16px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        height: "fit-content",
        justifyContent: "center",
        cursor: "pointer",
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" component="div" fontWeight="bold">
              {getUserFullName(data)}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {getUserPosition(data)}
            </Typography>
          </Box>
          <Avatar src={data.imageURL} sx={{ width: 72, height: 72 }} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
          <Typography variant="body2" color="text.secondary">
            KPI {t("All")} {kpiApprove.length} {t("List")}
          </Typography>
          <Typography variant="body2" color={isOverWeight ? "#FF4C4C" : isExactWeight ? "#2D9596" : "text.secondary"}>
            {t("Weight")} {totalWeight}%
          </Typography>
        </Box>
        <Box sx={{ mb: 1 }}>
          <ProgressBar variant="determinate" value={totalWeight} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {isOverWeight && (
            <Typography variant="body2" color="#FF4C4C">
              {t("MaxWeightAllowed")} 100%
            </Typography>
          )}
          {isExactWeight && (
            <Typography variant="body2" color="#2D9596">
              {t("PlanningCompleted")}
            </Typography>
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end", flex: 1 }}>
            <Typography variant="body2" color="primary">
              {t("Action")} KPI <span style={{ fontWeight: 'bold' }}> &gt;&gt; </span>
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const KpiAdminIndividual = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: kpiData, isFetching } = useSelector((state) => state.kpi);
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);
  const [filteredData, setFilteredData] = useState([]);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedYear, setSelectedYear] = useState(dayjs().get("year"));
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    if (selectedYear && selectedCompany) {
      dispatch(getKPIByAdmin({
        idCompany: selectedCompany.idCompany,
        year: selectedYear,
      }));
    }
  }, [selectedYear, selectedCompany]);

  useEffect(() => {
    setFilteredData(kpiData || []);
  }, [kpiData]);
  
  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (affiliateOrganizationList) {
      setSelectedCompany(affiliateOrganizationList[0])
    }
  }, []);

  const getUniqueValues = (key) => {
    if (!kpiData || !Array.isArray(kpiData)) {
      return [];
    }
    return [...new Set(kpiData.map((item) => item[key]).filter(Boolean))];
  };

  const businessUnits = getUniqueValues("businessUnitName");
  const divisions = getUniqueValues("divisionName");
  const departments = getUniqueValues("departmentName");
  const sections = getUniqueValues("sectionName");
  const positions = getUniqueValues("positionName");
  
  const handleSearch = (searchParams) => {
    const filtered = kpiData.filter((data) => {
      const {
        name,
        company,
        businessUnit,
        division,
        department,
        section,
        position,
        manager,
      } = searchParams;
  
      const matchesName = !name || (getUserFirstName(data) && getUserFirstName(data).toLowerCase().includes(name.toLowerCase())) || (getUserLastName(data) && getUserLastName(data).toLowerCase().includes(name.toLowerCase()));
      const matchesCompany = !company || company.idCompany === "all" || (data.companyName && data.companyName === company.companyName);
      const matchesBusinessUnit = !businessUnit || businessUnit.idBusinessUnit === "all" || (data.businessUnitName && data.businessUnitName === businessUnit.businessUnitName);
      const matchesDivision = !division || division.idDivision === "all" || (data.divisionName && data.divisionName === division.divisionName);
      const matchesDepartment = !department || department.idDepartment === "all" || (data.departmentName && data.departmentName === department.departmentName);
      const matchesSection = !section || section.idSection === "all" || (data.sectionName && data.sectionName === section.sectionName);
      const matchesPosition = !position || position.idPosition === "all" || (data.positionName && data.positionName === position.positionName);
      const matchesManager =
        !manager ||
        manager.idEmployees === "all" ||
        (data.manager1Firstname && data.manager1Firstname.includes(manager.firstname_TH)) ||
        (data.manager2Firstname && data.manager2Firstname.includes(manager.firstname_TH));
      
      const totalWeight = Number(data.totalWeight || 0);
      const matchesStatus =
        selectedStatus === "" ||
        (selectedStatus === 1 && totalWeight === 100) || 
        (selectedStatus === 0 && totalWeight < 100); 
  
      return (
        matchesName &&
        matchesCompany &&
        matchesBusinessUnit &&
        matchesDivision &&
        matchesDepartment &&
        matchesSection &&
        matchesPosition &&
        matchesManager &&
        matchesStatus
      );
    });

    setFilteredData(filtered);
  };

  const handleExport = async () => {
    // const header = [
    //   t("EmployeeID"), 
    //   t("FirstName"), 
    //   t("LastName"), 
    //   t("KPIName"), 
    //   t("Scope"), 
    //   t("Target"), 
    //   t("Evidence"), 
    //   t("Details"), 
    //   t("TypeMeasurement"), 
    //   t("Weight"), 
    //   t("Assessor"), 
    //   t("Evaluate") ,t("Rate") , t("StartDate"), t("EndDate"), t("Status")
    // ];
    
    const header = [
      t("EmployeeID"),
      t("FirstName"),
      t("LastName"),
      t("Division"),
      t("Department"),
      t("Section"),
      t("Position"),
      t("KPIName"),
      t("Weight"),
      t("StartDate"),
      t("EndDate"),
      t("CreateDate"),
      t("Year"),
      t("Status"),
      t("ApprovedDate"),
      t("ApprovedBy"),
      t("Details"),
      t("Target"),
      t("Evidence"),
      t("TypeMeasurement"),
      t("Scope"),
      t("ProgressPercentage"),
      t("EvaluationStatus"),
      t("EvaluationDate"),
      t("Rating"),
      t("Status"),
    ];
  
    const filteredForExport = filteredData.filter(employee => {
      if (selectedStatus === "") {
        return true; 
      }
      const hasKPI = employee.kpis && employee.kpis.length > 0;
      const totalWeight = employee.kpis.reduce((acc, kpi) => acc + Number(kpi.weight || 0), 0);
  
      if (hasKPI) {
        return (
          (selectedStatus === 1 && totalWeight === 100) || 
          (selectedStatus === 0 && totalWeight < 100)   
        );
      } else {
        return selectedStatus === 0; 
      }
    });
    
    const th = i18n.resolvedLanguage === "th"

    const dataToExport = filteredForExport.flatMap(employee => {
      // const totalWeight = employee.KPIs.reduce((acc, kpi) => acc + Number(kpi.weight || 0), 0);
      // const status = totalWeight === 100 ? t("Success") : t("Failure");
  
      if (employee.kpis && employee.kpis.length > 0) {
        return employee.kpis.map(kpi => ({
          employeeID: employee.employeeID,
          firstname: getUserFirstName(employee),
          lastname: getUserLastName(employee),
          division: th ? employee.divisionName : employee.divisionName_EN,
          department: th ? employee.departmentName : employee.departmentName_EN,
          section: th ? employee.sectionName : employee.sectionName_EN,
          position: th ? employee.positionName : employee.positionName_EN,
          KPIName: kpi.kpiName,
          weight: kpi.weight,
          startDate: dayjs(kpi.startDate).format(th ? "DD-MM-BBBB" : "DD-MM-YYYY"),
          endDate: dayjs(kpi.endDate).format(th ? "DD-MM-BBBB" : "DD-MM-YYYY"),
          createAt: dayjs(kpi.createAt).format(th ? "DD-MM-BBBB" : "DD-MM-YYYY"),
          year: th ? kpi.year + 543 : kpi.year,
          approve:
            kpi.isApprove === 1
              ? t("Approved")
              : kpi.isApprove === 0
              ? t("NotApproved")
              : t("PendingApproval"),
          approveDate: dayjs(kpi.approveDate).format(th ? "DD-MM-BBBB" : "DD-MM-YYYY"),
          approveBy: th ? kpi.approveBy_TH : kpi.approveBy_EN,
          description: kpi.description,
          target: kpi.target,
          evidence: kpi.evidence,
          measureType: kpi.measureType,
          scope: kpi.scope,
          progress: kpi.progress,
          evaluateStatus: kpi.managerEvaluate === null
            ? t("WaitingForEvaluation")
            : t("Evaluated"),
          managerEvaluateAt: dayjs(kpi.managerEvaluateAt).format(th ? "DD-MM-BBBB" : "DD-MM-YYYY"),
          managerRating: kpi.managerRating,
          managerEvaluate: 
            kpi.managerEvaluate === 1 
              ? t("Success") 
              : kpi.managerEvaluate === 0 
              ? t("Failure") 
              : "",
        }));
      } else {
        return [{
          employeeID: employee.employeeID,
          firstname: getUserFirstName(employee),
          lastname: getUserLastName(employee),
          division: "",
          department: "",
          section: "",
          position: "",
          KPIName: "",
          weight: "",
          startDate: "",
          endDate: "",
          createAt: "",
          year: "",
          approve: "",
          approveDate: "",
          approveBy: "",
          description: "",
          target: "",
          evidence: "",
          measureType: "",
          scope: "",
          progress: "",
          evaluateStatus: "",
          managerEvaluateAt: "",
          managerRating: "",
          managerEvaluate: "",
        }];
      }
    });
  
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(t("KPIData"));
  
    const colWidths = [
      { width: 15 }, 
      { width: 30 }, 
      { width: 30 },
      { width: 40 }, 
      { width: 40 },
      { width: 40 },
      { width: 40 },
      { width: 60 },
      { width: 20 },
      { width: 10 },
      { width: 40 },
      { width: 15 },
      { width: 15 },
      { width: 30 },
      { width: 30 }, 
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];
  
    worksheet.columns = colWidths.map(col => ({ width: col.width }));
  
    const headerRow = worksheet.addRow(header);
  
    const headerStyle = {
      font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
      alignment: { horizontal: "center", vertical: 'middle' },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '081F5C' }
      },
      border: {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } }
      }
    };
  
    headerRow.eachCell((cell) => {
      cell.font = headerStyle.font;
      cell.alignment = headerStyle.alignment;
      cell.fill = headerStyle.fill;
      cell.border = headerStyle.border;
    });
  
    worksheet.getRow(1).height = 50;
  
    const bodyStyle = {
      font: { size: 18, name: 'TH SarabunPSK' },
      border: {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } }
      }
    };
  
    dataToExport.forEach(item => {
      const row = [
        item["employeeID"],
        item["firstname"],
        item["lastname"],
        item["division"],
        item["department"],
        item["section"],
        item["position"],
        item["KPIName"],
        item["weight"],
        item["startDate"],
        item["endDate"],
        item["createAt"],
        item["year"],
        item["approve"],
        item["approveDate"],
        item["approveBy"],
        item["description"],
        item["target"],
        item["evidence"],
        item["measureType"],
        item["scope"],
        item["progress"],
        item["evaluateStatus"],
        item["managerEvaluateAt"],
        item["managerRating"],
        item["managerEvaluate"],
      ];
      const excelRow = worksheet.addRow(row);
  
      excelRow.eachCell((cell, colNumber) => {
        cell.font = bodyStyle.font;
        cell.border = bodyStyle.border;
  
        if (colNumber === 16) { 
          if (item["สถานะ"] === t("Success")) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '00FF00' } 
            };
          } else if (item["สถานะ"] === t("Failure")) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FF0000' } 
            };
          }
        }
      });
  
      for (let i = 1; i <= 16; i++) {
        if (!excelRow.getCell(i).border) {
          excelRow.getCell(i).border = bodyStyle.border;
        }
      }
    });
  
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.download = `KPIs_${t("AdminEmployees")}.xlsx`;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <ContainerStyled>
      <div className="container-xl">
        <StyledRoot>
          <GridStyled container spacing={2}>
            <Grid xs={12} display="flex" alignItems="center" justifyContent={"space-between"}>
              <Typography variant="h6" className="kpi" sx={{ ml: 1, fontSize: "30px" }}>
               {t("AssignKpisEmployees")}
              </Typography>
              <Box>
                <ButtonBlue
                  variant="outlined"
                  color="primary"
                  sx={{ height: "40px", ml: 2 }}
                  onClick={() => setOpenUploadDialog(true)}
                  startIcon={<FileUploadIcon />}
                >
                  {t("UploadData")}
                </ButtonBlue>
                <ButtonBlue
                  variant="outlined"
                  color="primary"
                  sx={{ height: "40px", ml: 2 }}
                  onClick={handleExport}
                  startIcon={<FileDownloadIcon />}
                >
                  {t("DownloadData")}
                </ButtonBlue>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography fontSize={14} fontWeight={600} color="text.third">
                {t("Year")}
              </Typography>
              <TextFieldTheme
                select
                variant="filled"
                value={selectedYear}
                onChange={(event) => setSelectedYear(event.target.value)}
              >
                <MenuItem value={dayjs().get("year")}>
                  {dayjs().get("year")}
                </MenuItem>
                <MenuItem value={dayjs().get("year") - 1}>
                  {dayjs().get("year") - 1}
                </MenuItem>
              </TextFieldTheme>
            </Grid>
            <Grid item xs={12} md={8}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("Company")}
                </Typography>
                {affiliateOrganizationList && (
                  <StyledAutocomplete
                    options={affiliateOrganizationList ? affiliateOrganizationList :  []}
                    getOptionLabel={(option) =>
                      i18n.resolvedLanguage === "th" ? option.companyName : option.companyName_EN
                    }
                    // isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                    value={selectedCompany}
                    onChange={(_, value) => setSelectedCompany(value)}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        variant="filled"
                        placeholder={t("Company")}
                      />
                    )}
                    disableClearable
                  />
                )}
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} md={2}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("Status")}
                </Typography>
                <FormControl fullWidth variant="filled">
                  <Select
                    labelId="status-select-label"
                    id="status-select"
                    value={selectedStatus || ""}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    displayEmpty
                    sx={{
                      backgroundColor: "#919eab14",
                      height: 56,
                      padding: "0px 12px",
                      borderRadius: 2,
                      '&:before': {
                        borderBottom: 'none',
                      },
                      '&:after': {
                        borderBottom: 'none',
                      },
                      '&:hover:not(.Mui-disabled):before': {
                        borderBottom: 'none',
                      },
                      "& .MuiInputLabel-root": {
                        top: "8px",
                      },
                      "& .MuiSelect-filled": {
                        paddingTop: "18px",
                        paddingBottom: "18px",
                        backgroundColor: "transparent",
                      },
                      "& .MuiSelect-icon": {
                        top: "calc(50% - 12px)",
                      },
                    }}
                  >
                    <MenuItem value="" sx={{ fontStyle: 'normal' }}>
                      {t("All")}
                    </MenuItem>
                    <MenuItem value={1}>{t("Success")}</MenuItem>
                    <MenuItem value={0}>{t("Failure")}</MenuItem>
                  </Select>
                </FormControl>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} marginBottom={"5px"} marginTop={"1px"}>
              {selectedCompany && (
                <EmployeeSearch
                  onSearch={handleSearch}
                  organization={selectedCompany.organization}
                />
              )}
            </Grid>
          </GridStyled>
          <Box
            sx={{
              overflowY: "auto",
              paddingRight: "16px",
            }}
          >
            <Grid container spacing={2}>
              {filteredData.length === 0 ? (
                <Grid item xs={12}>
                  <Typography variant="h6" align="center" color="text.secondary">
                    {t("NoSearchResults")}
                  </Typography>
                </Grid>
              ) : (
                filteredData.map((data, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <KpiCard data={data} />
                  </Grid>
                ))
              )}
            </Grid>
          </Box>

        </StyledRoot>
      </div>
      <DialogDropzone
        open={openUploadDialog}
        onClose={() => setOpenUploadDialog(false)}
        handleOpenAlert={(type, message) => alert(`${type}: ${message}`)}
      />
    </ContainerStyled>
  );
};

export default KpiAdminIndividual;
