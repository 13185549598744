import React, { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";

import { Button, Divider, Grid, Menu, MenuItem } from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PanToolIcon from "@mui/icons-material/PanTool";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import ConfirmDialog from "../../../user/expense/confirm-dialog";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getExpensePaymentTypes } from "../../../../../actions/expense";

const StyledGird = styled(Grid)({
  "& .MuiGrid-item": {
    paddingTop: 40,
    paddingLeft: 10,
    paddingRight: 20,
  },
});

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: 1,
    minWidth: 190,
    maxWidth: 300,
    color: "#263238",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
  },
}));

const RejectButton = styled(Button)(({ variant, theme }) => ({
  backgroundColor: "#ef5350",
  color: "#ffffff",
  paddingLeft: 16,
  paddingRight: 16,
  borderRadius: 14,
  marginLeft: 12,
  marginRight: 12,
  minWidth: 165,
  width: "100%",
  height: 48,
  "&:hover": {
    color: "#ffffff",
    backgroundColor: "#e53935",
  },
}));

const HoldButton = styled(Button)(({ variant, theme }) => ({
  color: "#ffffff",
  backgroundColor: "#ffca28",
  paddingLeft: 16,
  paddingRight: 16,
  borderRadius: 14,
  marginLeft: 12,
  marginRight: 12,
  minWidth: 165,
  width: "100%",
  height: 48,
  "&:hover": {
    color: "#ffffff",
    backgroundColor: "#ffb300",
  },
}));

const WaitingDocumentButton = styled(Button)(({ variant, theme }) => ({
  color: "#ffffff",
  backgroundColor: "#42a5f5",
  paddingLeft: 16,
  paddingRight: 16,
  borderRadius: 14,
  marginLeft: 12,
  marginRight: 12,
  minWidth: 165,
  width: "100%",
  height: 48,
  "&:hover": {
    color: "#ffffff",
    backgroundColor: "#1e88e5",
  },
}));

const PayButton = styled(Button)(({ variant, theme }) => ({
  color: "#ffffff",
  backgroundColor: "#66bb6a",
  paddingLeft: 16,
  paddingRight: 16,
  borderRadius: 14,
  marginLeft: 12,
  marginRight: 12,
  minWidth: 165,
  width: "100%",
  height: 48,
  "&:hover": {
    color: "#ffffff",
    backgroundColor: "#43a047",
  },
}));

const ActionButtons = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: expensePaymentTypes } = useSelector(
    (state) => state.expensePaymentTypes
  );
  const { handleSubmit, comment, handleRejectFlexCoin, isFlexCoin } = props;
  const RejectFormDialogTitle = t("RejectFormDialogTitle");
  const HoldFormDialogTitle = t("HoldFormDialogTitle");
  const WaitingDocumentFormDialogTitle = t("WaitingDocumentFormDialogTitle");
  const ApproveFormDialogTitle = t("AccApproveFormDialogTitle");
  const RejectFormDialogDes = t("RejectFormDialogDes");
  const HoldFormDialogDes = t("HoldFormDialogDes");
  const WaitingDocumentFormDialogDes = t("WaitingDocumentFormDialogDes");
  const ApproveFormDialogDes = t("AccApproveFormDialogDes");
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [openHoldDialog, setOpenHoldDialog] = useState(false);
  const [openWaitingDocumentDialog, setOpenWaitingDocumentDialog] =
    useState(false);
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [paymentType, setPaymentType] = useState(null);

  useEffect(() => {
    dispatch(getExpensePaymentTypes());
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenRejectDialog = () => {
    setOpenRejectDialog(true);
  };
  const handleCloseRejectDialog = () => {
    setOpenRejectDialog(false);
  };
  const handleOnClickRejectDialog = (action, status) => {
    if (isFlexCoin) {
      handleRejectFlexCoin(comment);
    } else {
      handleSubmit(action, status, comment, null);
    }

    setOpenRejectDialog(false);
  };

  const handleOpenHoldDialog = () => {
    setOpenHoldDialog(true);
  };
  const handleCloseHoldDialog = () => {
    setOpenHoldDialog(false);
  };
  const handleOnClickHoldDialog = (action, status) => {
    handleSubmit(action, status, comment, null);
    setOpenHoldDialog(false);
  };

  const handleOpenWaitingDocumentDialog = () => {
    setOpenWaitingDocumentDialog(true);
  };
  const handleCloseWaitingDocumentDialog = () => {
    setOpenWaitingDocumentDialog(false);
  };
  const handleOnClickWaitingDocumentDialog = (action, status) => {
    handleSubmit(action, status, comment, null);
    setOpenWaitingDocumentDialog(false);
  };

  const handleOpenApproveDialog = () => {
    setOpenApproveDialog(true);
  };
  const handleCloseApproveDialog = () => {
    setOpenApproveDialog(false);
  };
  const handleOnClickApproveDialog = (action, status) => {
    handleSubmit(action, status, comment, paymentType);
    setOpenApproveDialog(false);
  };

  return (
    <StyledGird
      container
      spacing={2}
      justifyContent="flex-end"
      direction={{ xs: "column", md: "row" }}
    >
      <Grid item xs={12} md={3}>
        <RejectButton
          startIcon={<CancelIcon />}
          onClick={handleOpenRejectDialog}
        >
          {t("Reject")}
        </RejectButton>
        <ConfirmDialog
          title={RejectFormDialogTitle}
          description={RejectFormDialogDes}
          open={openRejectDialog}
          handleOnClick={() => {
            handleOnClickRejectDialog(4, 7);
          }}
          handleClose={handleCloseRejectDialog}
        ></ConfirmDialog>
      </Grid>
      <Grid item xs={12} md={3}>
        <HoldButton startIcon={<PanToolIcon />} onClick={handleOpenHoldDialog}>
          {t("Hold")}
        </HoldButton>
        <ConfirmDialog
          title={HoldFormDialogTitle}
          description={HoldFormDialogDes}
          open={openHoldDialog}
          handleOnClick={() => {
            handleOnClickHoldDialog(5, 4);
          }}
          handleClose={handleCloseHoldDialog}
        ></ConfirmDialog>
      </Grid>
      <Grid item xs={12} md={3}>
        <WaitingDocumentButton
          startIcon={<InsertDriveFileIcon />}
          onClick={handleOpenWaitingDocumentDialog}
        >
          {t("WaitingForDocument")}
        </WaitingDocumentButton>
        <ConfirmDialog
          title={WaitingDocumentFormDialogTitle}
          description={WaitingDocumentFormDialogDes}
          open={openWaitingDocumentDialog}
          handleOnClick={() => {
            handleOnClickWaitingDocumentDialog(10, 10);
          }}
          handleClose={handleCloseWaitingDocumentDialog}
        ></ConfirmDialog>
      </Grid>
      <Grid item xs={12} md={3}>
        <div>
          <PayButton
            startIcon={<CheckCircleIcon />}
            id="customized-button"
            aria-controls={open ? "customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {t("AccApprove")}
          </PayButton>
          <ConfirmDialog
            title={ApproveFormDialogTitle}
            description={ApproveFormDialogDes}
            open={openApproveDialog}
            handleOnClick={() => {
              handleOnClickApproveDialog(7, 5);
            }}
            handleClose={handleCloseApproveDialog}
          ></ConfirmDialog>
          <StyledMenu
            id="customized-menu"
            MenuListProps={{
              "aria-labelledby": "-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {expensePaymentTypes &&
              expensePaymentTypes.map((paymentType, index) => (
                <>
                  <MenuItem
                    onClick={() => {
                      setPaymentType(paymentType.idExpensePaymentType);
                      handleOpenApproveDialog();
                    }}
                    disableRipple
                  >
                    {i18n.resolvedLanguage === "th"
                      ? paymentType.name_TH
                      : paymentType.name_EN}
                  </MenuItem>
                  {index != expensePaymentTypes.length - 1 && (
                    <Divider sx={{ my: 0.5 }} />
                  )}
                </>
              ))}
          </StyledMenu>
        </div>
      </Grid>
    </StyledGird>
  );
};

export default ActionButtons;
