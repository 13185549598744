import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Avatar,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  MenuItem,
  Chip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteProject,
  getEmployeeRewardByEmp,
  getProjectByEmp,
} from "../../../../actions/project";
import DeleteIcon from "@mui/icons-material/Delete";
import PageviewIcon from "@mui/icons-material/Pageview";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from "../../shared/general/Card";
import dayjs from "dayjs";
import AddProject from "./addProject";
import { useTranslation } from "react-i18next";
import { getManager } from "../../../../actions/manager";
import Utils from "../../../../utils";
import Loading from "../../shared/loading";
import { openNotificationAlert } from "../../../../actions/notificationAlert";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import AddReward from "./AddReward";

const useStyles = makeStyles(() => ({
  headerText: {
    fontSize: "1.2rem",
    fontWeight: "600",
    color: "#007afc",
  },
  textSmall: {
    fontSize: 14,
    color: "#9e9e9e",
  },
  textNoData: {
    fontSize: 22,
    fontWeight: "600",
    textAlign: "center",
  },
}));

const EmployeeReward = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: employeeReward, isFetching } = useSelector(
    (state) => state.employeeReward
  );
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(dayjs().get("year"));
  const [rewardSelected, setRewardSeleted] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleClickOpen = (data) => {
    setOpen(true);
    setRewardSeleted(data);
  };

  const handleClose = () => {
    setOpen(false);
    setRewardSeleted(null);
  };

  const handleDeleteConfirmation = async () => {
    try {
      const response = await deleteProject(rewardSelected.idEmployeeReward);
      if (response && response.status === 200) {
        dispatch(
          openNotificationAlert({
            type: "success",
            message: t("DeletedSuccessfully"),
          })
        );
      } else {
        dispatch(
          openNotificationAlert({
            type: "error",
            message: t("AnErrorOccurred"),
          })
        );
      }

      dispatch(getEmployeeRewardByEmp({ year: selectedYear }));
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(getEmployeeRewardByEmp({ year: selectedYear }));
  }, [selectedYear]);

  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: 16 }}>
        <Grid item xl={8} lg={8} md={6} xs={12}>
          <Typography variant="h4" fontWeight={500}>
            {t("Reward")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <div>
            <Typography fontSize={14} fontWeight={600} color="text.third">
              {t("Year")}
            </Typography>
            <TextFieldTheme
              select
              variant="filled"
              value={selectedYear}
              onChange={(event) => setSelectedYear(event.target.value)}
            >
              <MenuItem value={dayjs().get("year")}>
                {dayjs().get("year")}
              </MenuItem>
              <MenuItem value={dayjs().get("year") - 1}>
                {dayjs().get("year") - 1}
              </MenuItem>
            </TextFieldTheme>
          </div>
          <ButtonBlue
            variant="contained"
            size={"large"}
            startIcon={<AddIcon />}
            onClick={() => handleClickOpen(null)}
          >
            {t("AddReward")}
          </ButtonBlue>
        </Grid>
      </Grid>
      {isFetching ? (
        <Loading />
      ) : employeeReward && employeeReward.length > 0 ? (
        employeeReward.map((val, index) => (
          <div style={{ marginBottom: 16 }} key={index}>
            <CardStyle>
              <div style={{ padding: 20 }}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"space-between"}
                    flexWrap={"wrap"}
                  >
                    <Typography className={classes.headerText}>
                      {val.rewardName}
                    </Typography>
                    <Chip
                      size="small"
                      color={
                        val.isApproved === 1
                          ? "success"
                          : val.isApproved === 0
                          ? "error"
                          : "warning"
                      }
                      label={
                        val.isApproved === 1
                          ? t("Approved")
                          : val.isApproved === 0
                          ? t("NotApproved")
                          : t("WaitingForApproval")
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography className={classes.textSmall}>
                      {t("Institution")}
                    </Typography>
                    <Typography>{val.institution}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography className={classes.textSmall}>
                      {t("RewardType")}
                    </Typography>
                    <Typography>{val.rewardType === "internal" ? t("Internal") : t("External")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography className={classes.textSmall}>
                      {t("ReceivedDate")}
                    </Typography>
                    <Typography>
                      {dayjs(val.receivedDate).format(
                        i18n.resolvedLanguage === "th"
                          ? "DD/MM/BBBB"
                          : "DD/MM/YYYY"
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    display={"flex"}
                    justifyContent={"flex-end"}
                  >
                    <Link
                      to={{
                        pathname: "/reward/detail",
                        state: { idEmployeeReward: val.idEmployeeReward },
                      }}
                    >
                      <IconButton sx={{ color: "success.dark" }}>
                        <PageviewIcon style={{ fontSize: 28 }} />
                      </IconButton>
                    </Link>
                    <IconButton
                      sx={{ color: "primary.main" }}
                      onClick={() => handleClickOpen(val)}
                    >
                      <EditIcon style={{ fontSize: 28 }} />
                    </IconButton>
                    <IconButton
                      style={{ color: "red" }}
                      onClick={() => {
                        setRewardSeleted(val);
                        setDeleteDialogOpen(true);
                      }}
                    >
                      <DeleteIcon style={{ fontSize: 28 }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            </CardStyle>
          </div>
        ))
      ) : (
        <Paper
          elevation={2}
          style={{ padding: 20, margin: 20, textAlign: "center" }}
        >
          <Typography className={classes.textNoData}>{t("NoData")}</Typography>
        </Paper>
      )}

      {open && (
        <AddReward
          idEmployeeReward={rewardSelected && rewardSelected.idEmployeeReward}
          open={open}
          handleClose={handleClose}
        />
      )}
      {deleteDialogOpen && (
        <Dialog
          fullWidth
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <DialogTitle>{t("ConfirmToDelete")}</DialogTitle>
          <DialogContent>
            <Alert severity="warning" sx={{ marginBottom: "8px" }}>
              {t("ItemWillBePermanentlyDeleted")}
            </Alert>
            <Typography fontWeight={500}>
              {rewardSelected.rewardName}
            </Typography>
          </DialogContent>
          <DialogActions>
            <ButtonBlue onClick={() => setDeleteDialogOpen(false)}>
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue variant="contained" onClick={handleDeleteConfirmation}>
              {t("Delete")}
            </ButtonBlue>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default EmployeeReward;
