import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Box, Typography, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { getPotentailAssessment } from "../../../../actions/potential";
import { useTranslation } from "react-i18next";
import CardPotentailAssessment from "./components/CardPotentialAssessment";
import LoadingIcon from "../../assets/social-media.gif";
const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: "5%",
  "& .form-radio-label-type": {
    ["@media only screen and (max-width: 600px)"]: {
      flexBasis: "25%",
      width: "fit-content",
      margin: 0,
    },
    "& .label-radio": {
      ["@media only screen and (max-width: 600px)"]: {
        fontSize: 14,
      },
    },
  },
});

const PotentialPerson = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    isFetching: potentialAssessmentLoading,
    result: potentialAssessments,
  } = useSelector((state) => state.potentialAssessment);

  useEffect(() => {
    dispatch(getPotentailAssessment());
  }, []);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div style={{ marginBottom: "16px" }}>
          <Typography variant="h5" style={{ fontWeight: "600" }}>
            ประเมินศักยภาพ
          </Typography>
        </div>

        {potentialAssessmentLoading && (
          <Box
            sx={{
              minHeight: 400,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={LoadingIcon} style={{ height: 80, width: 80 }} />
          </Box>
        )}

        {potentialAssessments && potentialAssessments.length >= 1 ? (
          <Grid container spacing={3}>
            {potentialAssessments.map((e, index) => (
              <Grid item key={index} xs={12}>
                <CardPotentailAssessment potentialAssessment={e} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box
            sx={{
              minHeight: 400,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >

            ไม่มีแบบประเมินในขณะนี้
          </Box>
        )}
      </Container>
    </StyledRoot>
  );
};

export default PotentialPerson;
