import React, { useEffect } from "react";
import {
  MenuItem,
  Typography,
  Grid,
  TextField,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  styled,
  Autocomplete,
  Popper,
  autocompleteClasses,
  FormHelperText,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import dayjs from "dayjs";
import {
  getEmployeeReward,
  updateEmployeeReward,
  addEmployeeReward,
  getEmployeeRewardByEmp,
  getAllEmployeeReward,
} from "../../../../actions/project";
import DatePickerCustom from "../../shared/date/datePicker";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { openNotificationAlert } from "../../../../actions/notificationAlert";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import SelectEmployees from "../../shared/general/selectEmployees";

const StyledPopper = styled(Popper)({
  width: "330px !important",
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const AddReward = ({ idEmployeeReward, open, handleClose, idCompany, selectedYear }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: managers } = useSelector((state) => state.manager);

  const validationSchema = yup.object().shape({
    employee: yup.object().nullable().required(),
    rewardName: yup.string().max(200).required(),
    institution: yup.string().max(200).required(),
    rewardType: yup.string().required(),
    receivedDate: yup.date().required(),
    detail: yup.string().max(200).required(),
    manager: yup.object().nullable().required(),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      employee: null,
      rewardName: "",
      institution: "",
      rewardType: "",
      receivedDate: dayjs(),
      detail: "",
      manager: null,
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (formData) => {
    try {
      const approveBy = formData.manager.idEmployees;
      formData.approveBy = approveBy;

      delete formData.manager;

      let response = null;
      if (idEmployeeReward) {
        response = await updateEmployeeReward(idEmployeeReward, {
          rewardName: formData.rewardName,
          institution: formData.institution,
          rewardType: formData.rewardType,
          receivedDate: formData.receivedDate,
          detail: formData.detail,
          approveBy,
        });
      } else {
        const data = { 
          ...formData,
          idEmployees: formData.employee.idEmployees, 
        }
        delete data.employee;
        response = await addEmployeeReward(data);
      }
      if (response && response.status === 200) {
        dispatch(
          openNotificationAlert({
            type: "success",
            message: t("DataSaveSuccessful"),
          })
        );
      } else {
        dispatch(
          openNotificationAlert({
            type: "error",
            message: t("AnErrorOccurred"),
          })
        );
      }

      dispatch(getAllEmployeeReward({ idCompany, year: selectedYear }));
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const fetchedData = async (id) => {
    try {
      console.log("fetchedData", id);
      const response = await getEmployeeReward(id);
      if (response && response.data) {
        const data = response.data;
        const findManager = managers.find(
          (item) => item.idEmployees === data.approveBy
        );
        reset({
          manager: findManager,
          receivedDate: dayjs(data.receivedDate),
          ...data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (idEmployeeReward) {
      fetchedData(idEmployeeReward);
    }
  }, [idEmployeeReward]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {idEmployeeReward ? t("EditReward") : t("AddReward")}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="employee"
                render={({ field, fieldState }) => (
                  <>
                    <SelectEmployees
                      {...field}
                      handleChange={field.onChange}
                    />
                    {Boolean(fieldState.error) && (
                      <FormHelperText sx={{ color: "#d32f2f"}}>{fieldState.error.message}</FormHelperText>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={500} color="text.third" gutterBottom>
                {t("RewardName")}
              </Typography>
              <Controller
                control={control}
                name="rewardName"
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    fullWidth
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error && fieldState.error.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={500} color="text.third" gutterBottom>
                {t("Institution")}
              </Typography>
              <Controller
                control={control}
                name="institution"
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    fullWidth
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error && fieldState.error.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography fontWeight={500} color="text.third" gutterBottom>
                {t("RewardType")}
              </Typography>
              <Controller
                control={control}
                name="rewardType"
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    select
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error && fieldState.error.message}
                  >
                    <MenuItem value={"internal"}>{t("Internal")}</MenuItem>
                    <MenuItem value={"external"}>{t("External")}</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography fontWeight={500} color="text.third" gutterBottom>
                {t("ReceivedDate")}
              </Typography>
              <Controller
                control={control}
                name="receivedDate"
                render={({ field, fieldState }) => (
                  <DatePickerCustom
                    {...field}
                    views={["year", "month", "day"]}
                    minDate={dayjs().subtract(4, "year").startOf("year")}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        error={Boolean(fieldState.error)}
                        helperText={
                          fieldState.error && fieldState.error.message
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={500} color="text.third" gutterBottom>
                {t("Details")}
              </Typography>
              <Controller
                control={control}
                name="detail"
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    fullWidth
                    multiline
                    rows={2}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error && fieldState.error.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={500} color="text.third" gutterBottom>
                {t("SearchManager")}
              </Typography>
              <Controller
                control={control}
                name="manager"
                render={({ field, fieldState }) => (
                  <StyledAutocomplete
                    {...field}
                    options={
                      managers
                        ? managers.sort(
                            (a, b) =>
                              a.positionName &&
                              a.positionName.localeCompare(b.positionName)
                          )
                        : []
                    }
                    onChange={(_, newValue) => {
                      field.onChange(newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.idEmployees === value.idEmployees
                    }
                    getOptionLabel={(option) =>
                      i18n.resolvedLanguage === "th"
                        ? `${option.firstname_TH} ${option.lastname_TH}`
                        : `${option.firstname_EN} ${option.lastname_EN}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        error={Boolean(fieldState.error)}
                        helperText={
                          fieldState.error && fieldState.error.message
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: null,
                        }}
                      />
                    )}
                    popupIcon={<i className="fa-light fa-chevron-down"></i>}
                    PopperComponent={StyledPopper}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonBlue type="button" onClick={handleClose}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue
            variant="contained"
            type="submit"
            startIcon={<SaveIcon />}
            disabled={isSubmitting}
          >
            {t("Submit")}
          </ButtonBlue>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddReward;
