import { JOBDESCRIPTION_FETCHING, JOBDESCRIPTION_FAILED, JOBDESCRIPTION_SUCCESS } from "../actions/types";


const initialState = {
    result: null,
    isFetching: false,
    isError: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case JOBDESCRIPTION_FETCHING:
        return { ...state, result: null, isFetching: true, isError: false };
      case JOBDESCRIPTION_FAILED:
        return { ...state, result: null, isFetching: false, isError: true };
      case JOBDESCRIPTION_SUCCESS:
        return { ...state, result: payload, isFetching: false, isError: false };
      default:
        return state;
    }
}