import React, { useState, useRef, useEffect } from "react";
import { Grid, Avatar, styled, Typography, Paper, Box, LinearProgress, Stack, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import { Radar } from "react-chartjs-2";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import CardStyle from "../../shared/general/Card";
import { getAllEmployees, getEmployeeProfileList } from "../../../../actions/employee";
import CircleIcon from "@mui/icons-material/Circle";
import { personalityDISCTypes } from "../../user/personalityCharacterTest/DISC1/type";
import { personalityBusinessTypes } from "../../user/personalityCharacterTest/DISC2/type";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import utils from "../../../../utils";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { t } from "i18next";
import { getUserCompany, getUserDepartment, getUserDivision, getUserFullName, getUserPosition, getUserSection } from "../../../../utils/userData";

const useStyles = makeStyles(() => ({
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  paper: {
    margin: 10,
    marginBottom: 20,
  },
  mainText: {
    // marginTop: 0,
    fontSize: 25,
    fontWeight: "bold",
    textAlign: "center",
  },
  bigText: {
    textAlign: "center",
    fontSize: 21,
  },
  highlightText: {
    fontWeight: "bold",
    fontSize: 20,
    marginTop: 20,
  },
  text: {
    fontSize: 16,
    marginTop: 10,
    color: "#5b5b5b",
  },
  year: {
    fontSize: 16,
  },
  grade: {
    fontSize: 20,
    marginTop: 5,
    fontWeight: 600,
  },
  iconStar: {
    fontSize: 36,
    marginBottom: 7,
  },
  normal: {
    color: "black",
    fontSize: 18,
    fontWeight: "600",
  },
  potentail: {
    fontSize: 44,
    fontWeight: "bold",
  },
  mainNumber: {
    fontSize: 55,
    fontWeight: "bold",
    textAlign: "center",
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          sx={{
            backgroundColor: "gray",
            "& .MuiLinearProgress-barColorPrimary": {
              backgroundColor: "#DB4178",
            },
            borderRadius: "10px",
            height: 10,
          }}
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};


const StyleTypography = styled(Typography)({
  borderLeft: "4px solid transparent",
  borderImage:
    "linear-gradient(0deg, rgba(136,213,254,1) 0%, rgba(254,184,207,1) 99%, rgba(254,184,207,1) 100%) 5",
  padding: 5,
});

const Compare = ({ idEmp1, idEmp2, handleCloseCompare }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [profileList, setProfileList] = useState(null);

  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);

  const fetchedData = async (idCompany, idEmp, idEmp2) => {
    try {
      const response = await getEmployeeProfileList(idCompany, idEmp, idEmp2);
      if (response && response.data) {
        setProfileList(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (idEmp1.idEmployees && idEmp2.idEmployees) {
      fetchedData((idEmp1.idCompany || idEmp2.idCompany), idEmp1.idEmployees, idEmp2.idEmployees);
    }
  }, [idEmp1.idEmployees, idEmp2.idEmployees]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <CardStyle style={{ marginBottom: 50 }}>
            {idEmp1 ? (
              <div>
                {profileList &&
                  profileList.filter(
                    (val) =>
                      val.idEmployees == idEmp1.idEmployees
                  ).map((val, index) =>
                    <ShowData key={index} data={val} />
                  )}
              </div>
            ) : null}
          </CardStyle>
        </Grid>

        <Grid elevation={3} item xl={6} lg={6} md={12} sm={12} xs={12}>
          <CardStyle style={{ marginBottom: 50 }}>
            {idEmp2 ? (
              <div>
                {profileList &&
                  profileList.filter(
                    (val) =>
                      val.idEmployees == idEmp2.idEmployees
                  ).map((val, index) =>
                    <ShowData key={index} data={val} />
                  )}
              </div>
            ) : null}
          </CardStyle>
        </Grid>
      </Grid>

      <Stack justifyContent={"center"} alignItems={"center"} sx={{ mb: 5 }}>
        <ButtonBlue onClick={() => handleCloseCompare()} variant={"contained"}>
          {t("Close")}
        </ButtonBlue>
      </Stack>
    </Box>
  );
};

const ShowData = ({ data }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [openProjectDialog, setOpenProjectDialog] = useState(false);
  const [projectinDialog, setProjectinDialog] = useState(null);

  const handleOpen = (skills) => {
    setSelectedSkills(skills);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenProjectDialog = (projects) => {
    setOpenProjectDialog(true);
    setProjectinDialog(projects);
  };

  const handleCloseProjectDialog = () => {
    setOpenProjectDialog(false);
  };

  const formatSalary = (salary) => {
    if (salary === undefined || salary === null) return "-";
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(salary);
  };

  const chartDataCompetency = (data) => {
    return {
      labels: data.map(list => list.competencyName),
      datasets: [
        {
          label: 'Rate Level',
          data: data.map(item => item.approveLevel * 20),
          backgroundColor: "rgba(219, 65, 120, 0.5)",
          borderColor: "#DB4178",
          borderWidth: 1,
        },
      ],
    }
  };

  const plugin = {
    beforeDraw: (chart) => {
      const { ctx, scales, config } = chart;
      const { xCenter, yCenter, drawingArea: radius } = scales.r;
      ctx.save();
      ctx.arc(
        Math.abs(xCenter),
        Math.abs(yCenter),
        Math.abs(radius),
        0,
        Math.abs(Math.PI * 2)
      );
      ctx.fillStyle = "rgba(238, 238, 238,0.4)";
      ctx.fill();
      ctx.restore();
    },
  };

  const option = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        suggestedMin: 1,
        suggestedMax: 20,
        pointLabels: {
          font: { size: 16 },
        },
        ticks: {
          maxTicksLimit: 4,
          display: false,
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
    },
  };

  const personalityTypes1 = personalityDISCTypes;
  const personalityTypes2 = personalityBusinessTypes;

  const presentDay = new Date();

  const CURRENT_YEAR_DISPLAY = dayjs().get("month") >= 11 ?
    dayjs().get("year") :
    dayjs().subtract(1, "year").get("year");

  return (
    <div>
      <Box sx={{ mt: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Avatar
          style={{ textAlign: "center" }}
          sx={{ width: 170, height: 170 }}
          src={data.imageProfile}
        />
      </Box>
      {/* <Typography
          style={{
            marginTop: 10,
            color: "#ffc927",
            fontSize: 25,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <StarIcon className={classes.iconStar} />
          4.7
        </Typography> */}
      <Typography variant="subtitle2" className={classes.mainText}>
        {getUserFullName(data)}
      </Typography>
      <Typography
        variant="subtitle2"
        style={{ color: "#767575", fontSize: 18, marginBottom: "10px" }}
        align="center"
      >
        {data && data.job && data.job
          .filter(
            date => dayjs(date.start).format("YYYY-MM-DD") <= dayjs(presentDay).format("YYYY-MM-DD") &&
              (dayjs(date.end).format("YYYY-MM-DD") >= dayjs(presentDay).format("YYYY-MM-DD") ||
                !date.end)
          )
          .map(item => (getUserPosition(data)))}
      </Typography>

      <Typography
        variant="subtitle2"
        style={{ color: "#767575", fontSize: 18, marginBottom: "20px" }}
        align="center"
      >
        {data && data.job && data.job
          .filter(
            date => dayjs(date.start).format("YYYY-MM-DD") <= dayjs(presentDay).format("YYYY-MM-DD") &&
              (dayjs(date.end).format("YYYY-MM-DD") >= dayjs(presentDay).format("YYYY-MM-DD") ||
                !date.end)
          )
          .map(item => (item.jobGroupName || "-"))}
      </Typography>

      <Grid container>
        <Grid item xl={5} md={5} xs={5} sx={{ marginLeft: "10%" }}>
          <Grid container>
            <Grid item xs>
              <Typography className={classes.text}>{t("TIG")}</Typography>
              <Typography className={classes.normal}>
                {data.employeePersonnelLevelStart
                  ? `${utils.getAgeMonthYear(t,
                    dayjs(data.employeePersonnelLevelStart).format("DD/MM/YYYY"),
                    i18n.resolvedLanguage
                  )}`
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <Typography className={classes.text}>{t("ESY")}</Typography>
              <Typography className={classes.normal}>
                {data.hiringDate
                  ? `${utils.getAgeMonthYear(t,
                    dayjs(data.hiringDate).format("DD/MM/YYYY"),
                    i18n.resolvedLanguage
                  )}`
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={4} md={4} xs={4} sx={{ marginLeft: "10%" }}>
          <Grid container>
            <Grid item xs>
              <Typography className={classes.text}>{t("TIP")}</Typography>
              <Typography className={classes.normal}>
                {data.employeePositionStart
                  ? `${utils.getAgeMonthYear(t,
                    dayjs(data.employeePositionStart).format("DD/MM/YYYY"),
                    i18n.resolvedLanguage
                  )}`
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <Typography className={classes.text}>{t("ServiceYear")}</Typography>
              <Typography className={classes.normal}>
                {data.hiringDate
                  ? `${utils.getAgeMonthYear(t,
                    dayjs(data.hiringDate).format("DD/MM/YYYY"),
                    i18n.resolvedLanguage
                  )}`
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xl={12} md={12} xs={12} sx={{ marginLeft: "10%" }}>
          <Grid container>
            <Grid item xs>
              <Typography className={classes.text}>{t("JobGroup")}</Typography>
              <Typography className={classes.normal}>
                {data && data.job
                  .filter(
                    date => dayjs(date.start).format("YYYY-MM-DD") <= dayjs(presentDay).format("YYYY-MM-DD") &&
                      (dayjs(date.end).format("YYYY-MM-DD") >= dayjs(presentDay).format("YYYY-MM-DD") ||
                        !date.end)
                  )
                  .map(item => item.jobGroupName || "-")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <Typography className={classes.text}>{t("Company")}</Typography>
              <Typography className={classes.normal}>{getUserCompany(data)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xl={5} md={5} xs={5} sx={{ marginLeft: "10%" }}>
          <Grid container>
            <Grid item xs>
              <Typography className={classes.text}>{t("JobLevel")}</Typography>
              <Typography className={classes.normal}>
                {data && data.job
                  .filter(
                    date => dayjs(date.start).format("YYYY-MM-DD") <= dayjs(presentDay).format("YYYY-MM-DD") &&
                      (dayjs(date.end).format("YYYY-MM-DD") >= dayjs(presentDay).format("YYYY-MM-DD") ||
                        !date.end)
                  )
                  .map(item => item.jobLevelName ? (item.jobLevelName + " ") : "-")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <Typography className={classes.text}>{t("Division")}</Typography>
              <Typography className={classes.normal}>
                {getUserDivision(data) || "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <Typography className={classes.text}>{t("Section")}</Typography>
              <Typography className={classes.normal}>{getUserSection(data) || "-"}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={4} md={4} xs={4} sx={{ marginLeft: "10%" }}>
          <Grid container>
            <Grid item xs>
              <Typography className={classes.text}>{t("personalLevel")}</Typography>
              <Typography className={classes.normal}>
                {data && data.levelName || "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <Typography className={classes.text}>{t("Department")}</Typography>
              <Typography className={classes.normal}>
                {getUserDepartment(data) || "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container sx={{ p: 2 }}>
        <Grid
          item
          xl={12}
          md={12}
          xs={12}
          style={{ marginTop: 20, borderTop: "2px solid #dcdcdc" }}
        >
          <StyleTypography className={classes.highlightText}>
            {t("job")}
          </StyleTypography>
          <br />
          <Grid container sx={{ ml: 4 }}>
            {data.job && data.job.length > 0 && data.job
              .filter(
                date => dayjs(date.start).format("YYYY-MM-DD") <= dayjs(presentDay).format("YYYY-MM-DD") &&
                  (dayjs(date.end).format("YYYY-MM-DD") >= dayjs(presentDay).format("YYYY-MM-DD") ||
                    !date.end)
              ).map((job, index) => (
                <Grid container spacing={2} sx={{ mt: index > 0 ? 1 : 0 }} key={index}>
                  <Stack spacing={2} sx={{ ml: 3 }}>
                    <Stack direction="row" spacing={1}>
                      <Typography fontWeight={600}>{t("JobPosition")}:</Typography>
                      <Typography>{getUserPosition(job)}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                      <Typography fontWeight={600}>{t("JobGroup")}:</Typography>
                      <Typography>{job.jobGroupName || "-"}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                      <Typography fontWeight={600}>{t("JobLevel")}:</Typography>
                      <Typography>{job.jobLevelName || "-"}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                      <Typography fontWeight={600}>{t("Salary")}:</Typography>
                      <Typography>{formatSalary(job.salary)}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                      <Typography fontWeight={600}>{t("minimumSalary") + " - " + t("maximumSalary")}:</Typography>
                      <Typography>{job.min_salary && job.max_salary ? formatSalary(job.min_salary) + " - " + formatSalary(job.max_salary) : t("NoData")}</Typography>
                    </Stack>
                  </Stack>
                </Grid>
              ))}
          </Grid>

          <br />

          <Grid
            item
            xl={12}
            md={12}
            xs={12}
            style={{ marginTop: 20, borderTop: "2px solid #dcdcdc" }}
          >
            <StyleTypography className={classes.highlightText}>
              {t("performancePotential")}
            </StyleTypography>
            <br />
            <Grid container justifyContent={"space-between"} sx={{ pl: 4, pr: 4, mt: 2 }}>
              {Array.from({ length: 3 }, (_, i) => {
                const year = CURRENT_YEAR_DISPLAY - i;
                const performanceForYear = data.performance &&
                  data.performance.find(
                    (performance) => performance.yearPerformance === year
                  );
                return (
                  <Grid item xl={3} lg={3} md={3} xs={12}>
                    <Stack justifyContent={"center"} alignItems={"center"}>
                      <Typography className={classes.year} fontWeight={400}>
                        {t("Performance")}
                      </Typography>
                      <Typography className={classes.year} fontWeight={400}>
                        {year}
                      </Typography>
                    </Stack>

                    <Typography className={classes.grade} textAlign={"center"}>
                      {performanceForYear ? performanceForYear.name : "-"}
                    </Typography>
                  </Grid>
                )
              })}
            </Grid>
            <br />
            <Grid container justifyContent={"space-between"} sx={{ pl: 4, pr: 4, mt: 2 }}>
              {Array.from({ length: 3 }, (_, i) => {
                const year = CURRENT_YEAR_DISPLAY - i;
                const potentialForYear = data.potential &&
                  data.potential.find(
                    (performance) => performance.year === year
                  );
                return (
                  <Grid item xl={3} lg={3} md={3} xs={12}>
                    <Stack justifyContent={"center"} alignItems={"center"}>
                      <Typography className={classes.year} fontWeight={400}>
                        {t("Potential")}
                      </Typography>
                      <Typography className={classes.year} fontWeight={400}>
                        {year}
                      </Typography>
                    </Stack>
                    <Typography className={classes.grade} textAlign={"center"}>
                      {potentialForYear ?
                        (potentialForYear.Level === "High" ? "H" :
                          potentialForYear.Level === "Normal" ? "N" :
                            potentialForYear.Level === "Normal-Low" ? "NL" :
                              potentialForYear.Level === "Low" ? "L" : "-")
                        : "-"}
                    </Typography>
                  </Grid>
                )
              })}
            </Grid>
            <br />

            <Grid container style={{ height: "100%" }}>
              <Grid item xl={12} md={12} xs={12}>
                <StyleTypography className={classes.highlightText}>
                  {t("competency")}
                </StyleTypography>
                <Grid container justifyContent="center" sx={{ p: 2 }}>
                  <Paper
                    style={{
                      width: "70vw",
                      height: "50vh",
                      borderRadius: "20px",
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/Information/BG.svg)`,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px"
                    }}
                  >

                    <Radar
                      data={chartDataCompetency(data && data.competency)}
                      options={option}
                      plugins={[plugin]}
                    />

                  </Paper>
                </Grid>
              </Grid>

              <Grid
                item
                xl={12}
                md={12}
                xs={12}
                style={{ marginTop: 20, borderTop: "2px solid #dcdcdc", height: 240 }}
              >
                <StyleTypography className={classes.highlightText}>
                  {t("skills")}
                </StyleTypography>
                {data && data.skills && data.skills.length > 0 ? (
                  <>
                    {data.skills.slice(0, 2).map((profile, index) => (
                      <Stack key={index} sx={{ p: 2 }}>
                        <Stack direction={"row"} alignItems={"center"} spacing={1}>
                          <CircleIcon style={{ fontSize: "small", color: "#DB4178" }} />
                          <Typography fontSize={18} fontWeight={500}>
                            {profile.skillName + " " + "(" + "L." + profile.level + ")"}
                          </Typography>
                        </Stack>
                      </Stack>
                    ))}

                    {data.skills.length > 2 && (
                      <Stack justifyContent={"center"} alignItems={"center"}>
                        <ButtonBlue onClick={() => handleOpen(data.skills)} sx={{ mt: 2 }} variant={"text"}>
                          {t("seeMore")}
                        </ButtonBlue>
                      </Stack>
                    )}
                  </>
                ) : (
                  <Typography fontSize={18} fontWeight={500} sx={{ mt: 10, textAlign: "center" }}>
                    {t("NoData")}
                  </Typography>
                )}
              </Grid>

              <Grid container>
                <Grid
                  item
                  xl={12}
                  md={12}
                  xs={12}
                  style={{ marginTop: 20, borderTop: "2px solid #dcdcdc", height: 280 }}
                >
                  <StyleTypography className={classes.highlightText}>
                    {t("projectProfile")}
                  </StyleTypography>

                  {data.project && data.project.length > 0 ? (
                    <>
                      {data.project.slice(0, 2).map((profile, index) => (
                        <Stack key={index} sx={{ p: 2 }}>
                          <Stack direction={"row"} alignItems={"center"} spacing={1}>
                            <CircleIcon style={{ fontSize: "small", color: "#DB4178" }} />
                            <Typography fontSize={16} fontWeight={400}>
                              {profile.projectName || "-"} &nbsp;
                            </Typography>
                          </Stack>
                          <Typography style={{ color: "#616161", marginLeft: 20 }}>
                            <b>{profile.size || "-"}</b>, <b>{profile.role || "-"}</b>
                          </Typography>
                        </Stack>
                      ))}

                      {data.project.length > 2 && (
                        <Stack justifyContent={"center"} alignItems={"center"}>
                          <ButtonBlue onClick={() => handleOpenProjectDialog(data.project)} sx={{ mt: 2 }} variant={"text"}>
                            {t("seeMore")}
                          </ButtonBlue>
                        </Stack>
                      )}
                    </>
                  ) : (
                    <Typography fontSize={18} fontWeight={500} sx={{ mt: 10, textAlign: "center" }}>
                      {t("NoData")}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                xl={12}
                md={12}
                xs={12}
                style={{ marginTop: 20, borderTop: "2px solid #dcdcdc", height: 320 }}
              >
                <StyleTypography className={classes.highlightText}>
                  {t("character")} 1
                </StyleTypography>
                {data.character && data.character[0] ? (
                  <div
                    style={{
                      height: "85%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography className={classes.mainNumber}>
                      <img
                        src={`${personalityTypes1(t).find(character => character.id === (data.character && data.character[0].result)).imagePath}`}
                        style={{
                          width: "40%",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginBottom: "auto",
                        }}
                      />
                      <Typography
                        style={{ fontSize: "16px", marginBottom: "10px" }}
                      >
                        {personalityTypes1(t).find(character => character.id === (data.character && data.character[0].result)).content.shortText}
                      </Typography>
                    </Typography>
                  </div>
                ) : (
                  <div
                    style={{
                      height: "85%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography className={classes.mainNumber}>
                      <Stack justifyContent={"center"} alignItems={"center"} spacing={2}>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#C0C0C0",
                            borderRadius: "100%",
                            width: "190px",
                            height: "190px",
                          }}
                        >
                          <Typography style={{ fontSize: "16px" }}>
                            {t("noImageAvailable")}
                          </Typography>
                        </Box>

                        <Typography fontSize={18} fontWeight={500} sx={{ textAlign: "center" }}>
                          {t("NoData")}
                        </Typography>
                      </Stack>
                    </Typography>
                  </div>
                )}
              </Grid>

              <Grid
                item
                xl={12}
                md={12}
                xs={12}
                style={{ marginTop: 20, borderTop: "2px solid #dcdcdc", height: 500 }}
              >
                <StyleTypography className={classes.highlightText}>
                  {t("character")} 2
                </StyleTypography>
                {data.character && data.character[1] ? (
                  <div
                    style={{
                      height: "85%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography className={classes.mainNumber}>
                      <img
                        src={`${personalityTypes2(t).find(character => character.id === (data.character && data.character[1].result)).imagePath}`}
                        style={{
                          width: "40%",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginBottom: "auto",
                        }}
                      />
                      <Typography
                        style={{ fontSize: "16px", marginBottom: "10px", marginTop: 20 }}
                      >
                        {personalityTypes2(t).find(character => character.id === (data.character && data.character[1].result)).content.longText}
                      </Typography>
                    </Typography>
                  </div>
                ) : (
                  <div
                    style={{
                      height: "85%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography className={classes.mainNumber}>
                      <Stack justifyContent={"center"} alignItems={"center"} spacing={2} sx={{ mt: 2 }}>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#C0C0C0",
                            borderRadius: "100%",
                            width: "180px",
                            height: "180px",
                          }}
                        >
                          <Typography style={{ fontSize: "16px" }}>
                            {t("noImageAvailable")}
                          </Typography>
                        </Box>

                        <Typography fontSize={18} fontWeight={500} sx={{ textAlign: "center" }}>
                          {t("NoData")}
                        </Typography>
                      </Stack>
                    </Typography>
                  </div>
                )}
              </Grid>

              <Grid
                item
                xl={12}
                md={12}
                xs={12}
                style={{ marginTop: 20, borderTop: "2px solid #dcdcdc" }}
              >
                <StyleTypography className={classes.highlightText}>
                  {t("leadershipScore")}
                </StyleTypography>
                {/* <div
                  style={{
                    height: "85%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RadialChartIndex />
                </div> */}
                <Typography fontSize={18} fontWeight={500} sx={{ p: 4, textAlign: "center" }}>
                  {t("NoData")}
                </Typography>
              </Grid>

              <Grid
                item
                xl={12}
                md={12}
                xs={12}
                style={{ marginTop: 20, borderTop: "2px solid #dcdcdc", height: 170 }}
              >
                <StyleTypography className={classes.highlightText}>
                  {t("talentAssessment")}
                </StyleTypography>
                {data.talent && data.talent.length > 0 ? (
                  <Grid container justifyContent={"space-between"} sx={{ pl: 4, pr: 4, mt: 2 }}>
                    {data.talent.map((talent, index) => (
                      <Grid item xl={2} md={2} xs={2} key={index} style={{ textAlign: "center" }}>
                        <Typography className={classes.year} fontWeight={400}>
                          Talent {talent.year}
                        </Typography>
                        <Typography className={classes.grade}>
                          {talent.talentName}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography fontSize={18} fontWeight={500} sx={{ p: 4, textAlign: "center" }}>
                    {t("NoData")}
                  </Typography>
                )}
              </Grid>

              <Grid
                item
                xl={12}
                md={12}
                xs={12}
                style={{ marginTop: 20, borderTop: "2px solid #dcdcdc", height: 250 }}
              >
                <StyleTypography className={classes.highlightText}>
                  {t("AppMenu.Successor")}
                </StyleTypography>
                {data.successor && data.successor.length > 0 ? (
                  <Stack spacing={2} sx={{ p: 2 }} alignItems={"center"} justifyContent={"center"}>
                    {data.successor.map((success, i) => (
                      <Stack key={i} spacing={1}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <CircleIcon style={{ fontSize: "small", color: "#DB4178" }} />
                          <Typography fontWeight={500}>
                            {getUserFullName(success)}
                          </Typography>
                        </Stack>
                        <Typography style={{ marginLeft: 20 }}>
                          {t("AppMenu.Successor")} {i + 1}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                ) : (
                  <Typography fontSize={18} fontWeight={500} sx={{ mt: 8, textAlign: "center" }}>
                    {t("NoData")}
                  </Typography>
                )}
              </Grid>

              <Grid
                item
                xl={12}
                md={12}
                xs={12}
                style={{ marginTop: 20, borderTop: "2px solid #dcdcdc" }}
              >
                <StyleTypography className={classes.highlightText}>
                  {t("careerAspiration")}
                </StyleTypography>

                <Stack sx={{ p: 2 }}>
                  {data.careerPlan && data.careerPlan.length > 0 ? (
                    data.careerPlan.map((plan, i) => (
                      <Stack>
                        <Typography fontSize={20} fontWeight={500}>
                          {t("ManagerSuggestion")}
                        </Typography>
                        <Stack spacing={2} sx={{ mt: 2 }}>
                          <Stack spacing={1} direction={"row"} alignItems={"center"}>
                            <CircleIcon
                              style={{ fontSize: "small", color: "#DB4178" }}
                            />
                            <Typography fontSize={18} fontWeight={500}>
                              {plan.managerSugJobPos1 || "-"}
                            </Typography>
                          </Stack>
                          <Stack spacing={1} direction={"row"} alignItems={"center"}>
                            <CircleIcon
                              style={{ fontSize: "small", color: "#DB4178" }}
                            />
                            <Typography fontSize={18} fontWeight={500}>
                              {plan.managerSugJobPos2 ? plan.managerSugJobPos2 : t("NoData")}
                            </Typography>
                          </Stack>
                          <Stack spacing={1} direction={"row"} alignItems={"center"}>
                            <CircleIcon
                              style={{ fontSize: "small", color: "#DB4178" }}
                            />
                            <Typography fontSize={18} fontWeight={500}>
                              {plan.managerSugJobPos3 ? plan.managerSugJobPos3 : t("NoData")}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    ))) : (
                    <Stack>
                      <Typography fontSize={20} fontWeight={500}>
                        {t("ManagerSuggestion")}
                      </Typography>
                      <Stack spacing={2} sx={{ mt: 2 }}>
                        <Stack spacing={1} direction={"row"} alignItems={"center"}>
                          <CircleIcon
                            style={{ fontSize: "small", color: "#DB4178" }}
                          />
                          <Typography fontSize={18} fontWeight={500}>
                            {t("NoData")}
                          </Typography>
                        </Stack>
                        <Stack spacing={1} direction={"row"} alignItems={"center"}>
                          <CircleIcon
                            style={{ fontSize: "small", color: "#DB4178" }}
                          />
                          <Typography fontSize={18} fontWeight={500}>
                            {t("NoData")}
                          </Typography>
                        </Stack>
                        <Stack spacing={1} direction={"row"} alignItems={"center"}>
                          <CircleIcon
                            style={{ fontSize: "small", color: "#DB4178" }}
                          />
                          <Typography fontSize={18} fontWeight={500}>
                            {t("NoData")}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  )}
                </Stack>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* skills */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>{t("seeMoreSkillDetails")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {selectedSkills && selectedSkills.length > 0 && selectedSkills.map((profile, index) => (
              <Grid item xs={6} key={index}>
                <Stack sx={{ p: 2 }}>
                  <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <CircleIcon style={{ fontSize: "small", color: "#DB4178" }} />
                    <Typography fontSize={18} fontWeight={500}>
                      {profile.skillName + " " + "(" + "L." + profile.level + ")"}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonBlue onClick={handleClose} variant={"text"}>
            {t("Close")}
          </ButtonBlue>
        </DialogActions>
      </Dialog>

      {/* projects */}
      <Dialog open={openProjectDialog} onClose={handleCloseProjectDialog} fullWidth maxWidth="lg">
        <DialogTitle>{t("projectDetails")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {projectinDialog && projectinDialog.length > 0 && projectinDialog.map((profile, index) => (
              <Grid item xs={6} key={index}>
                <Stack sx={{ p: 2 }}>
                  <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <CircleIcon style={{ fontSize: "small", color: "#DB4178" }} />
                    <Typography fontSize={16} fontWeight={400}>
                      {profile.projectName || "-"} &nbsp;
                    </Typography>
                  </Stack>
                  <Typography style={{ color: "#616161", marginLeft: 20 }}>
                    <b>{profile.size || "-"}</b>, <b>{profile.role || "-"}</b>
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonBlue onClick={handleCloseProjectDialog} variant={"text"}>
            {t("Close")}
          </ButtonBlue>
        </DialogActions>
      </Dialog>

    </div >
  )
};

export default Compare;