import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Avatar,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  MenuItem,
  Chip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteProject, getProjectByEmp } from "../../../../actions/project";
import DeleteIcon from "@mui/icons-material/Delete";
import PageviewIcon from "@mui/icons-material/Pageview";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from "../../shared/general/Card";
import dayjs from "dayjs";
import AddProject from "./addProject";
import { useTranslation } from "react-i18next";
import { getManager } from "../../../../actions/manager";
import Utils from "../../../../utils";
import Loading from "../../shared/loading";
import { openNotificationAlert } from "../../../../actions/notificationAlert";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

const useStyles = makeStyles(() => ({
  headerText: {
    fontSize: "1.2rem",
    fontWeight: "600",
    color: "#007afc",
  },
  textSmall: {
    fontSize: 14,
    color: "#9e9e9e",
  },
  textNoData: {
    fontSize: 22,
    fontWeight: "600",
    textAlign: "center",
  },
  backgroundColorMD: {
    backgroundColor: "#43a047",
  },
  backgroundColorMG: {
    backgroundColor: "#e53935",
  },
  backgroundColorSM: {
    backgroundColor: "#039be5",
  },
}));

const ShowProject = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: dataProject, isFetching } = useSelector(
    (state) => state.dataProject
  );
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(dayjs().get("year"));
  const [projectSelected, setProjectSeleted] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setProjectSeleted(null);
  };

  useEffect(() => {
    dispatch(getProjectByEmp({ year: selectedYear }));
  }, [selectedYear]);

  useEffect(() => {
    dispatch(getManager());
  }, []);

  const handleDeleteConfirmation = async () => {
    try {
      const response = await deleteProject(projectSelected.idEmployeeProject);
      if (response && response.status === 200) {
        dispatch(
          openNotificationAlert({
            type: "success",
            message: t("DeletedSuccessfully"),
          })
        );
      } else {
        dispatch(
          openNotificationAlert({
            type: "error",
            message: t("AnErrorOccurred"),
          })
        );
      }

      dispatch(getProjectByEmp({ year: selectedYear }));
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: 16 }}>
        <Grid item xl={8} lg={8} md={6} xs={12}>
          <Typography variant="h4" fontWeight={500}>
            {t("Project")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <div>
            <Typography fontSize={14} fontWeight={600} color="text.third">
              {t("Year")}
            </Typography>
            <TextFieldTheme
              select
              variant="filled"
              value={selectedYear}
              onChange={(event) => setSelectedYear(event.target.value)}
            >
              <MenuItem value={dayjs().get("year")}>
                {dayjs().get("year")}
              </MenuItem>
              <MenuItem value={dayjs().get("year") - 1}>
                {dayjs().get("year") - 1}
              </MenuItem>
            </TextFieldTheme>
          </div>
          <ButtonBlue
            variant="contained"
            size={"large"}
            startIcon={<AddIcon />}
            onClick={handleClickOpen}
          >
            {t("AddNewProject")}
          </ButtonBlue>
        </Grid>
      </Grid>

      {isFetching ? (
        <Loading />
      ) : dataProject && dataProject.length > 0 ? (
        dataProject.map((val, index) => (
          <div style={{ marginBottom: 16 }} key={index}>
            <CardStyle>
              <div style={{ padding: 20 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={1}>
                    <Avatar
                      style={{ width: 50, height: 50 }}
                      className={[
                        `${val.sizeMini == "MD" && classes.backgroundColorMD}`,
                        `${val.sizeMini == "MG" && classes.backgroundColorMG}`,
                        `${val.sizeMini == "SM" && classes.backgroundColorSM}`,
                      ].join(" ")}
                    >
                      {val.sizeMini}
                    </Avatar>
                  </Grid>
                  <Grid item xs={12} md={11}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        display={"flex"}
                        justifyContent={"space-between"}
                        flexWrap={"wrap"}
                      >
                        <div>
                          <Typography className={classes.headerText}>
                            {val.projectName}
                          </Typography>
                          <Typography>
                            {val.size}
                          </Typography>
                        </div>
                        <Chip
                          size="small"
                          color={
                            val.isApproved === 1
                              ? "success"
                              : val.isApproved === 0
                              ? "error"
                              : "warning"
                          }
                          label={
                            val.isApproved === 1
                              ? t("Approved")
                              : val.isApproved === 0
                              ? t("NotApproved")
                              : t("WaitingForApproval")
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <Typography className={classes.textSmall}>
                          {t("return")}
                        </Typography>
                        <Typography>
                          {Utils.numberWithCommasWithoutDot(val.return)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <Typography className={classes.textSmall}>
                          {t("Role")}
                        </Typography>
                        <Typography>
                          {val.role}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <Typography className={classes.textSmall}>
                          {t("StartDate")}
                        </Typography>
                        <Typography>
                          {dayjs(val.dateStart).format(i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <Typography className={classes.textSmall}>
                          {t("EndDate")}
                        </Typography>
                        <Typography>
                          {dayjs(val.dateEnd).format(i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY")}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        display={"flex"}
                        justifyContent={"flex-end"}
                      >
                        <Link
                          to={{
                            pathname: "/project/detail",
                            state: { idEmployeeProject: val.idEmployeeProject },
                          }}
                        >
                          <IconButton sx={{ color: "success.dark" }}>
                            <PageviewIcon style={{ fontSize: 28 }} />
                          </IconButton>
                        </Link>
                        <IconButton
                          sx={{ color: "primary.main" }}
                          onClick={() => {
                            setProjectSeleted(val);
                            setOpen(true);
                          }}
                        >
                          <EditIcon style={{ fontSize: 28 }} />
                        </IconButton>
                        <IconButton
                          style={{ color: "red" }}
                          onClick={() => {
                            setProjectSeleted(val);
                            setDeleteDialogOpen(true);
                          }}
                        >
                          <DeleteIcon style={{ fontSize: 28 }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </CardStyle>
          </div>
        ))
      ) : (
        <Paper
          elevation={2}
          style={{ padding: 20, margin: 20, textAlign: "center" }}
        >
          <Typography className={classes.textNoData}>{t("NoData")}</Typography>
        </Paper>
      )}

      {open && (
        <AddProject
          idEmployeeProject={
            projectSelected && projectSelected.idEmployeeProject
          }
          open={open}
          handleClose={handleClose}
        />
      )}
      {deleteDialogOpen && (
        <Dialog
          fullWidth
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <DialogTitle>{t("ConfirmToDelete")}</DialogTitle>
          <DialogContent>
            <Alert severity="warning" sx={{ marginBottom: "8px" }}>
              {t("ItemWillBePermanentlyDeleted")}
            </Alert>
            <Typography fontWeight={500}>
              {projectSelected.projectName}
            </Typography>
          </DialogContent>
          <DialogActions>
            <ButtonBlue onClick={() => setDeleteDialogOpen(false)}>
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue variant="contained" onClick={handleDeleteConfirmation}>
              {t("Delete")}
            </ButtonBlue>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ShowProject;
