import { Container, Divider, Typography } from "@mui/material";

import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useTheme } from '@mui/material/styles';

import React, { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { Button, Menu, Toolbar, AppBar} from "@mui/material";
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Logo from "../assets/logo/unihr_logo.png";
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import Success from "../assets/approved.png";
import Error from "../assets/rejected.png";

import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';

import { postContactUs } from "../../../actions/contactUs";


const StyledRoot = styled("div")({
  background: 'linear-gradient(90deg, rgba(235, 148, 192, 0.2) -0.51%, rgba(194, 176, 230, 0.2) 50.01%, rgba(165, 221, 231, 0.2) 99.49%)',
  backgroundRepeat: 'no-repeat',
  zIndex: 0,
  "& .head": {
    marginBottom: 48,
  },
  "& .phone": {
    marginBottom: 24,
    "& i": {
      marginRight: 8,
    },
    "& .MuiTypography-root": {
      fontSize: 26,
    },
  },
  "& .email": {
    marginBottom: 24,
    "& i": {
      marginRight: 8,
    },
    "& .MuiTypography-root": {
      fontSize: 26,
    },
  },
  "& .alert": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "& img": {
      marginBottom: 16,
    },
  },

});

const StyledAppBar = styled(AppBar)(({}) => ({
  boxShadow: "none",
  backgroundColor: "transparent",
  "& .MuiToolbar-regular": {
    color: "#212b36",
    backgroundColor: "#ffffffcc",
    transition:
      "height 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    backdropFilter: "blur(6px)",
    ["@media (min-width: 900px)"]: {
      height: 76,
    },

    "& .MuiContainer-root": {
      display: "flex",
      alignItems: "center",
      ["@media (max-width: 768px)"]: {
        padding: 0,
      },
      "& .headerAction": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& .translate, .MuiDivider-root, .contact-us": {
          ["@media (max-width: 768px)"]: {
            display: "none",
          },
        },
      },
    },
  },
}));

const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = "Required";
  }

  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 8) {
    errors.password = "Contain at least 8 characters";
  }

  return errors;
};

const ContactUsPage = (props) => {

  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [anchorEl_Trans, setAnchorEl_Trans] = useState(null);
  const open_Trans = Boolean(anchorEl_Trans);
  
  const handleClick_Trans = (event) => {
    setAnchorEl_Trans(event.currentTarget);
  };
  
  const handleClose_Trans = () => {
    setAnchorEl_Trans(null);
  };
  
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleClose_Trans();
  };

  const dispatch = useDispatch();
  const matchesBig = useMediaQuery("(min-width:1200px)");

  const [employeeCount, setEmployeeCount] = React.useState('');

  const handleEmployeeChange = (event) => {
    setEmployeeCount(event.target.value);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      company: "",
      employeecount: "",
      message: "",
    },
    //validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values);
      resetForm();
    },
    validateOnChange: false,
  });

  const [msgStatus, setMsgStatus] = React.useState("fetch");
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async (values) => {
    console.log(values);
    values.createdBy = "UniHR";
    handleOpen();
    setMsgStatus("fetch");
    const result = await dispatch(postContactUs(values));
    if (result) {
      console.log("result: ", result);
      if (result.status === 200) {
        setMsgStatus("success");
      } else {
        setMsgStatus("error");
      }
    } else {
      setMsgStatus("error");
    }
  };

  return (
    <Fragment>
<StyledAppBar>
  <Toolbar>
    <Container maxWidth="lg" sx={{ display: "flex", alignItems: "center" }}>
      {/* Logo */}
      <div>
        <NavLink to="/landing">
          <img src={Logo} alt="logo" width={80} />
        </NavLink>
      </div>

      {/* Spacer */}
      <div style={{ flexGrow: 1 }}></div>

      {/* Header Actions */}
      <div className="headerAction" style={{ display: "flex", alignItems: "center" }}>
        {/* Language Selector */}
        <div className="translate">
          <Button
            aria-label="translate"
            size="small"
            aria-controls={open_Trans ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open_Trans ? "true" : undefined}
            onClick={handleClick_Trans}
            sx={{
              marginRight: 2,
              padding: 1,
              borderRadius: 50,
              "&:hover": { backgroundColor: "#EBEAEE" },
            }}
          >
            <LanguageRoundedIcon
              fontSize="medium"
              sx={{
                borderRadius: 50,
                width: "auto",
                fontSize: 24,
                color:"#000000"
              }}
            />
          </Button>

          {/* Language Menu */}
          <Menu
            anchorEl={anchorEl_Trans}
            open={open_Trans}
            onClose={handleClose_Trans}
            MenuListProps={{ "aria-labelledby": "basic-button" }}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            PaperProps={{
              style: {
                width: "230px",
                boxShadow:
                  "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                fontSize: "12px",
              },
            }}
          >
            {[
              { code: "th", label: "ภาษาไทย", lang: "Thai" },
              { code: "en", label: "English" },
              { code: "la", label: "ລາວ", lang: "Lao" },
              { code: "jp", label: "日本", lang: "Japanese" },
              { code: "kh", label: "កម្ពុជា", lang: "Cambodian" },
              { code: "mm", label: "မြန်မာ", lang: "Myanmar" },
              { code: "cn", label: "中国", lang: "Chinese" },
              { code: "kr", label: "대한민국", lang: "Korean" },
              { code: "vn", label: "Việt Nam", lang: "Vietnamese" },
            ].map(({ code, label, lang }) => (
              <MenuItem key={code} onClick={() => changeLanguage(code)}>
                {label}
                {lang && (
                  <span style={{ color: "#B4B4B4", marginLeft: "10px" }}>
                    {lang}
                  </span>
                )}
              </MenuItem>
            ))}
          </Menu>
        </div>

        {/* Divider */}
        <Divider
          orientation="vertical"
          flexItem
          sx={{ mx: 2,my: 5, height: 24 }}
        />

        {/* Login Button */}
        <Button
          variant="contained"
          component={NavLink}
          to="/login"
          sx={{
            borderRadius: 50,
            height: 45,
            padding: "0 20px",
            fontSize: 18,
            fontWeight: 500,
            backgroundColor: "#531881",
            color: "#ffffff",
            boxShadow: "none",
            marginLeft: '35px',
            "&:hover": {
              backgroundColor: "#ffffff",
              color: "#531881",
              border: "3px solid #531881",
              boxShadow: "none",
            },
          }}
        >
          {t("LogIn")}
        </Button>
      </div>
    </Container>
  </Toolbar>
</StyledAppBar>

    {/* </Header> */}

      <StyledRoot className="page">
        <Container maxWidth="lg" style={{ paddingTop: 8, paddingBottom: 20 }}>
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h4" align="left" sx={{ 
                    color: "#531881 !important", 
                    fontSize: 40, 
                    fontWeight: 500, 
                    marginTop: {
                      md: "60px",
                      sm: "50px"
                     },
                    marginBottom: {
                      md: "20px",
                      sm: "20px"
                    },
                  }}>
                    {t("titleContactUs")}
                </Typography>
                <Typography variant="body1" align="left" sx={{ 
                    color: "#000", 
                    fontSize: 18, 
                    fontWeight: 300, 
                    marginRight: 15,
                    marginTop: {
                      md: "50px",
                      sm: "50px"
                     },
                    marginBottom: {
                      md: "20px",
                      sm: "20px"
                    },
                  }}>
                    {t("descriptionUniHR")}
                  </Typography>

                <Box sx={{
                    display: "flex", 
                    marginBottom: 15, 
                    marginTop: "160px", 
                    flexDirection: "column",
                    [theme.breakpoints.down('sm')]: { 
                      transform: "translateY(-120px)",
                      marginBottom: "-120px",
                    },
                  }}>
                  <div style={{ display: "flex", marginBottom: "12px"}}>
                    <MailOutlineRoundedIcon sx={{ color: "#000000", marginRight: "15px", marginTop: "5px" }} />
                    <Typography variant="h5" sx={{fontSize: "26px", color: "#000000", fontWeight: 500 }}>
                      puttinun@anthr.co
                    </Typography>
                  </div>
          
                  <div style={{ display: "flex"}}>
                    <PhoneIphoneOutlinedIcon sx={{ color: "#000000", marginRight: "15px", marginTop: "5px" }}/>
                    <Typography variant="h5" sx={{fontSize: "26px", color: "#000000", fontWeight: 500 }}>
                      082-889-4498
                    </Typography>
                  </div>
                </Box>

              </Grid>
              <Grid item xs={12} sm={6}>
              <div style={{ backgroundColor: "#ffffff", padding: "50px", borderRadius: 30, display: "flex", height: "480px", marginTop: "50px" }}>
                <form
                  onSubmit={formik.handleSubmit}
                  autoComplete="off"
                  noValidate
                >
                  <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                      <div>
                        <TextField
                          label={t("companyName")}
                          name="company"
                          variant="outlined"
                          onChange={formik.handleChange}
                          sx={{ width: "100%" }}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          <InputLabel id="employee-select-label">{t("employeeCount")}</InputLabel>
                          <Select
                            labelId="employee-select-label"
                            id="employee-select"
                            name="employeecount"
                            value={formik.values.employeecount} 
                            label={t("employeeCount")}
                            
                            onChange={formik.handleChange} 
                          >
                            <MenuItem value={10}>1-200</MenuItem>
                            <MenuItem value={20}>200-500</MenuItem>
                            <MenuItem value={30}>500-1,000</MenuItem>
                            <MenuItem value={40}>1,000-5,000</MenuItem>
                            <MenuItem value={50}>5,000-10,000</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <div>
                        <TextField
                          label={t("contactPerson")}
                          name="name"
                          variant="outlined"
                          onChange={formik.handleChange}
                          sx={{ width: "100%" }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div>
                        <TextField
                          label={t("PhoneNumber")}
                          variant="outlined"
                          name="phone"
                          onChange={formik.handleChange}
                          sx={{ width: "100%" }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div>
                        <TextField
                          name="email"
                          label={t("email")}
                          variant="outlined"
                          onChange={formik.handleChange}
                          sx={{ width: "100%" }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div>
                        <TextField
                          label={t("notes")}
                          variant="outlined"
                          name="message"
                          onChange={formik.handleChange}
                          sx={{ width: "100%" }}
                        />
                      </div>
                    </Grid>
     
                    <Grid item xs={12}>
                      <Button type="submit" variant="contained" 
                          sx={{
                            borderRadius: 50,
                            height: "auto",
                            width: "190px",
                            padding: "10px",
                            fontSize: 20,
                            fontWeight: 500,
                            backgroundColor: '#000000',
                            color: '#ffffff',
                            boxShadow: 'none',
                            '&:hover': {
                              backgroundColor: '#ffffff', 
                              color: '#000000',
                              boxShadow: 'none',
                              border: '3px solid #000000',
                            },
                          }}
                          >
                      {t("SendMessage")}
                      </Button>

                    </Grid>
                  </Grid>
                </form>
              </div>
              </Grid>
            </Grid>
          </div>
        </Container>

        <Backdrop
          sx={{ backgroundColor: "#000000d9", color: "#fff", zIndex: 1000 }}
          open={open}
          onClick={handleClose}
        >
          {msgStatus === "fetch" ? (
            <CircularProgress color="inherit" />
          ) : (
            <Fragment>
              {msgStatus === "error" ? (
                <div className="alert">
                  <img src={Error} width="60" />
                  <Typography style={{ color: "#FFFFFF" }} variant="h6" gutterBottom>
                    {t("AnErrorOccurred")}
                  </Typography>
                  <Typography style={{ color: "#FFFFFF" }} variant="h4" gutterBottom>
                    {t("TryAgain")}
                  </Typography>
                  <Typography style={{ color: "#FFFFFF" }}>{t("ThankYou")}</Typography>
                </div>
              ) : (
                <div className="alert">
                  <img src={Success} width="60" />
                  <Typography style={{ color: "#FFFFFF" }} variant="h6" gutterBottom>
                    {t("DataSaveSuccessful")}
                  </Typography>
                  <Typography
                    align="center"
                    style={{ color: "#FFFFFF" }}
                    variant="h4"
                    gutterBottom
                  >
                    {t("TeamWillContact")}
                  </Typography>
                  <Typography style={{ color: "#FFFFFF" }}>{t("ThankYou")}</Typography>
                </div>
              )}
            </Fragment>
          )}
        </Backdrop>
      </StyledRoot>
    </Fragment>
  );
};

export default ContactUsPage;
