import { httpClient } from "./httpClient";

const getWarningLetter = (idCompany) => {
  return httpClient.get("warning-letter", { params: { idCompany } });
};

const addWarningLetter = (formData) => {
  return httpClient.post("warning-letter", formData);
};

const updateWarningLetter = (formData) => {
  return httpClient.put("warning-letter", formData);
};

const deleteWarningLetter = (id) => {
  return httpClient.delete(`warning-letter/${id}`);
};

const updateWarningLetterStatus = (formData) => {
  return httpClient.put("warning-letter-status", formData);
};

const getWarningLetterByIdEmployee = () => {
  return httpClient.get("warning-letter-employee");
};

const getWarningLevel = (idCompany) => {
  return httpClient.get("warning-letter-level", { params: { idCompany } });
};

const updateWarningLevel = (formData) => {
  return httpClient.put("warning-letter-level", formData);
};

const getWarningLetterTemplate = (idCompany) => {
  return httpClient.get(`warning-letter-template?idCompany=${idCompany}`);
};
const getWarningLetterTemplateHtml = (id) => {
  return httpClient.get(`warning-letter-template-html/${id}`);
};
const upsertWarningLetterTemplate = (formData) => {
  return httpClient.put(`warning-letter-template`, formData);
};
const upsertWarningLetterTemplateHtml = (formData) => {
  return httpClient.put(`warning-letter-template-html`, formData);
};
const deleteWarningLetterTemplate = (id) => {
  return httpClient.delete(`warning-letter-template/${id}`);
};

export default {
  getWarningLetter,
  addWarningLetter,
  updateWarningLetter,
  deleteWarningLetter,
  updateWarningLetterStatus,
  getWarningLetterByIdEmployee,
  getWarningLevel,
  updateWarningLevel,
  getWarningLetterTemplate,
  upsertWarningLetterTemplate,
  deleteWarningLetterTemplate,
  getWarningLetterTemplateHtml,
  upsertWarningLetterTemplateHtml
};
