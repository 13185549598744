import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from "@pdf-lib/fontkit";
import dayjs from 'dayjs';
import 'dayjs/locale/th';

function CheckNumber(Number) {
    var decimal = false;
    Number = Number.toString();
    Number = Number.replace(/ |,|บาท|฿/gi, '');
    for (var i = 0; i < Number.length; i++) {
        if (Number[i] == '.') {
            decimal = true;
        }
    }
    if (decimal == false) {
        Number = Number + '.00';
    }
    return Number
}

function ArabicNumberToText(Number) {
    var Number = CheckNumber(Number);
    var NumberArray = new Array("ศูนย์", "หนึ่ง", "สอง", "สาม", "สี่", "ห้า", "หก", "เจ็ด", "แปด", "เก้า", "สิบ");
    var DigitArray = new Array("", "สิบ", "ร้อย", "พัน", "หมื่น", "แสน", "ล้าน");
    var BahtText = "";
    if (isNaN(Number)) {
        return "ข้อมูลนำเข้าไม่ถูกต้อง";
    } else {
        if ((Number - 0) > 9999999.9999) {
            return "ข้อมูลนำเข้าเกินขอบเขตที่ตั้งไว้";
        } else {
            Number = Number.split(".");
            if (Number[1].length > 0) {
                Number[1] = Number[1].substring(0, 2);
            }
            var NumberLen = Number[0].length - 0;
            for (var i = 0; i < NumberLen; i++) {
                var tmp = Number[0].substring(i, i + 1) - 0;
                if (tmp != 0) {
                    if ((i == (NumberLen - 1)) && (tmp == 1)) {
                        BahtText += "เอ็ด";
                    } else
                        if ((i == (NumberLen - 2)) && (tmp == 2)) {
                            BahtText += "ยี่";
                        } else
                            if ((i == (NumberLen - 2)) && (tmp == 1)) {
                                BahtText += "";
                            } else {
                                BahtText += NumberArray[tmp];
                            }
                    BahtText += DigitArray[NumberLen - i - 1];
                }
            }
            BahtText += "บาท";
            if ((Number[1] == "0") || (Number[1] == "00")) {
                BahtText += "ถ้วน";
            } else {
                var DecimalLen = Number[1].length - 0;
                for (var i = 0; i < DecimalLen; i++) {
                    var tmp = Number[1].substring(i, i + 1) - 0;
                    if (tmp != 0) {
                        if ((i == (DecimalLen - 1)) && (tmp == 1)) {
                            BahtText += "เอ็ด";
                        } else
                            if ((i == (DecimalLen - 2)) && (tmp == 2)) {
                                BahtText += "ยี่";
                            } else
                                if ((i == (DecimalLen - 2)) && (tmp == 1)) {
                                    BahtText += "";
                                } else {
                                    BahtText += NumberArray[tmp];
                                }
                        BahtText += DigitArray[DecimalLen - i - 1];
                    }
                }
                BahtText += "สตางค์";
            }
            return BahtText;
        }
    }
};

export const Tawi50PDFfile = async (type, selectedYear, data, imageData) => {
    const fontSize = 16;
    const url = `${process.env.REACT_APP_API_URL}files/${type}.pdf`;
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
    const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);

    const font = await pdfDoc.embedFont(fontBytes);

    const numPages = data.Employee50TawiResult.length;

    for (let i = 1; i < numPages; i++) {
        const [newPage] = await pdfDoc.copyPages(pdfDoc, [0]);
        pdfDoc.addPage(newPage);
    }

    const updatedPages = pdfDoc.getPages();

    for (let i = 0; i < numPages; i++) {
        const currentPage = updatedPages[i];
        const Employee50TawiData = data && data.Employee50TawiResult[i];
        // const PayrunItem = data && data.PayrunDetailResult[0];
        // const Payrun50TawiItem = data && data.Payrun50TawiResult[0];
        // const PayrunMainItem = data && data.PayrunDetailMainResult[0];
        // const DeductionItem = data && data.DeductionResult[0];
        // const employeeData = data && data.employeeDetails;

        if (Employee50TawiData) {

            const index = Employee50TawiData.orderNumber;
            if (index !== undefined) {
                currentPage.drawText(`${index}`, { x: index > 100 ? 95 : 105, y: 605, size: fontSize, font: font });
            }
        }

        { (Employee50TawiData && Employee50TawiData.companyName) && currentPage.drawText(`${Employee50TawiData.companyName}`, { x: 60, y: 730, size: fontSize, font: font }); }

        if (Employee50TawiData && Employee50TawiData.companyTaxId) {
            let companyTaxId = [
                Employee50TawiData.companyTaxId[0],
                Employee50TawiData.companyTaxId.slice(1, 5),
                Employee50TawiData.companyTaxId.slice(5, 10),
                Employee50TawiData.companyTaxId.slice(10, 12),
                Employee50TawiData.companyTaxId[12]
            ];
            currentPage.drawText(`${companyTaxId[0]} `, { x: 378, y: 747, size: fontSize, font: font });
            currentPage.drawText(`${companyTaxId[1][0]}  ${companyTaxId[1][1]} ${companyTaxId[1][2]}  ${companyTaxId[1][3]} `, { x: 397, y: 747, size: fontSize, font: font });
            currentPage.drawText(`${companyTaxId[2][0]}  ${companyTaxId[2][1]} ${companyTaxId[2][2]}  ${companyTaxId[2][3]}  ${companyTaxId[2][4]} `, { x: 451, y: 747, size: fontSize, font: font });
            currentPage.drawText(`${companyTaxId[3][0]} ${companyTaxId[3][1]} `, { x: 518, y: 747, size: fontSize, font: font });
            currentPage.drawText(`${companyTaxId[4]} `, { x: 548, y: 747, size: fontSize, font: font });
        }

        currentPage.drawText(`${Employee50TawiData.addressCompany || ""} ${Employee50TawiData.districtCompany || ""} ${Employee50TawiData.provinceCompany || ""} ${Employee50TawiData.areaCodeCompany || ""}`, { x: 60, y: 707, size: fontSize, font: font });

        //taxpayer
        currentPage.drawText(`${(Employee50TawiData.title_TH ? Employee50TawiData.title_TH : "-") + " " + (Employee50TawiData.firstname_TH ? Employee50TawiData.firstname_TH : "-") + " " + (Employee50TawiData.lastname_TH ? Employee50TawiData.lastname_TH : "-")}`, { x: 60, y: 658, size: fontSize, font: font });

        if (Employee50TawiData.personalID) {
            let personalID = [
                Employee50TawiData.personalID[0],
                Employee50TawiData.personalID.slice(1, 5),
                Employee50TawiData.personalID.slice(5, 10),
                Employee50TawiData.personalID.slice(10, 12),
                Employee50TawiData.personalID[12]
            ];
            currentPage.drawText(`${personalID[0]} `, { x: 378, y: 679, size: fontSize, font: font });
            currentPage.drawText(`${personalID[1][0]}  ${personalID[1][1]} ${personalID[1][2]}  ${personalID[1][3]} `, { x: 397, y: 679, size: fontSize, font: font });
            currentPage.drawText(`${personalID[2][0]}  ${personalID[2][1]} ${personalID[2][2]}  ${personalID[2][3]}  ${personalID[2][4]} `, { x: 451, y: 679, size: fontSize, font: font });
            currentPage.drawText(`${personalID[3][0]} ${personalID[3][1]} `, { x: 518, y: 679, size: fontSize, font: font });
            currentPage.drawText(`${personalID[4]} `, { x: 548, y: 679, size: fontSize, font: font });
        }

        const checkIfBangKok = Employee50TawiData.province === "กรุงเทพมหานคร" || Employee50TawiData.province === "จังหวัดกรุงเทพมหานคร" || Employee50TawiData.province === "กรุงเทพ" || Employee50TawiData.province === "กรุงเทพฯ" || Employee50TawiData.province === "กรุงเทพ" || Employee50TawiData.province === "ก.ท.ม." || Employee50TawiData.province === "กทม." || Employee50TawiData.province === "กทม";

        const empAddress = `${Employee50TawiData.houseNo || ""}${Employee50TawiData.village ? ` หมู่บ้าน${Employee50TawiData.village}` : ""}${Employee50TawiData.villageNo ? ` หมู่ที่ ${Employee50TawiData.villageNo}` : ""}${Employee50TawiData.alley ? ` ซอย${Employee50TawiData.alley}` : ""}${Employee50TawiData.road ? ` ถนน${Employee50TawiData.road}` : ""}${Employee50TawiData.subDistrict ? (checkIfBangKok ? ` ${Employee50TawiData.subDistrict}` : ` ตำบล${Employee50TawiData.subDistrict}`) : ""}${Employee50TawiData.district ? (checkIfBangKok ? ` ${Employee50TawiData.district}` : ` อำเภอ${Employee50TawiData.district}`) : ""}${Employee50TawiData.province ? (checkIfBangKok ? ` จังหวัดกรุงเทพมหานคร` : ` จังหวัด${Employee50TawiData.province}`) : ""}${Employee50TawiData.areaCode ? ` ${Employee50TawiData.areaCode}` : ""} `

        currentPage.drawText(`${empAddress}`, {
            x: 60, y: 632, size: empAddress.length > 100 ? 14 : fontSize, font: font
        });

        currentPage.drawText(`X`, { x: 212, y: 605, size: fontSize, font: font });

        if (Employee50TawiData) {
            let AllSalary = 0;

            let OtherPaid = 0;
            let OtherTax = 0;

            if (Employee50TawiData && typeof Employee50TawiData.totalEarning !== "undefined") {
                AllSalary = Employee50TawiData.totalEarning || 0;
            }

            if (Employee50TawiData && typeof Employee50TawiData.retirementAmount !== "undefined") {
                OtherPaid = Employee50TawiData.retirementAmount || 0;
            }

            if (Employee50TawiData && typeof Employee50TawiData.retirementTax !== "undefined") {
                OtherTax = Employee50TawiData.retirementTax || 0;
            }

            // let AllSalary = PayrunItem.SUM_totalEarnings ? PayrunItem.SUM_totalEarnings + (PayrunItem.beforeAccumulateEarnings ? PayrunItem.beforeAccumulateEarnings : 0) - (DeductionItem.value ? DeductionItem.value : 0) - (TotalLateAndAbsentValue) : 0;
            let totalTax = Employee50TawiData.totalTax ? Employee50TawiData.totalTax : 0;

            //วัน เดือน ปี ที่จ่าย
            selectedYear && currentPage.drawText(`${parseInt(selectedYear) + 543}`, { x: 354, y: 536, size: fontSize, font: font });

            //2. จำนวนเงินที่จ่าย
            if (AllSalary) {
                let allSalaryDeduction = AllSalary - (Employee50TawiData.retirementAmount || 0)
                currentPage.drawText(`${allSalaryDeduction.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `, {
                    x: AllSalary < 10 ? 450 :
                        AllSalary < 100 ? 444 :
                            AllSalary < 1000 ? 438 :
                                AllSalary < 10000 ? 430 :
                                    AllSalary < 100000 ? 424 :
                                        AllSalary < 1000000 ? 418 : 410,
                    y: 536, size: fontSize, font: font
                });
            }


            let totalTaxDeduction = totalTax - (Employee50TawiData.retirementTax || 0)
            //2. ภาษีที่หัก
            currentPage.drawText(`${totalTaxDeduction.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `, { x: 495, y: 536, size: fontSize, font: font });


            //6. อื่นๆ
            if (OtherPaid > 0) {
                currentPage.drawText(`เงินได้ที่นายจ้างจ่ายให้ครั้งเดียวเพราะออกจากงาน`, {
                    x: 95,
                    y: 202, size: fontSize, font: font
                });

                currentPage.drawText(`${OtherPaid.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `, {
                    x: OtherPaid < 10 ? 450 :
                        OtherPaid < 100 ? 444 :
                            OtherPaid < 1000 ? 438 :
                                OtherPaid < 10000 ? 430 :
                                    OtherPaid < 100000 ? 424 :
                                        OtherPaid < 1000000 ? 418 : 410,
                    y: 202, size: fontSize, font: font
                });
                currentPage.drawText(`${OtherTax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `, {
                    x: OtherTax < 10 ? 523 :
                        OtherTax < 100 ? 517 :
                            OtherTax < 1000 ? 511 :
                                OtherTax < 10000 ? 503 :
                                    OtherTax < 100000 ? 497 :
                                        OtherTax < 1000000 ? 491 : 495,
                    y: 202, size: fontSize, font: font
                });
            }
            //รวม
            currentPage.drawText(`${AllSalary.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `, {
                x: AllSalary < 10 ? 450 :
                    AllSalary < 100 ? 444 :
                        AllSalary < 1000 ? 438 :
                            AllSalary < 10000 ? 430 :
                                AllSalary < 100000 ? 424 :
                                    AllSalary < 1000000 ? 418 : 410,
                y: 182, size: fontSize, font: font
            });
            currentPage.drawText(`${totalTax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `, {
                x: totalTax < 10 ? 523 :
                    totalTax < 100 ? 517 :
                        totalTax < 1000 ? 511 :
                            totalTax < 10000 ? 503 :
                                totalTax < 100000 ? 497 :
                                    totalTax < 1000000 ? 491 : 495,
                y: 182, size: fontSize, font: font
            });

            currentPage.drawText(`0.00`, { x: 250, y: 145, size: 12, font: font });

            let sumSSO = 0;

            if (Employee50TawiData && typeof Employee50TawiData.accumulateSSOCompany !== "undefined") {
                sumSSO = Employee50TawiData.accumulateSSOCompany || 0;
            }

            // let sumSSO = (PayrunMainItem.SUM_SSO || 0) + (PayrunItem.beforeAccumulateSSO || 0);
            currentPage.drawText(`${sumSSO > 0 ? sumSSO.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00"}`, { x: 372, y: 145, size: 12, font: font });

            let sumSF = 0;

            if (Employee50TawiData && typeof Employee50TawiData.accumulatePFCompany !== "undefined") {
                sumSF = Employee50TawiData.accumulatePFCompany || 0;
            }
            // let sumSF = (PayrunMainItem.SUM_PF || 0) + (PayrunItem.beforeAccumulatePF || 0);
            currentPage.drawText(`${sumSF > 0 ? sumSF.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00"}`, { x: 501, y: 145, size: 12, font: font })

            //Thai Baht to Text
            const thaibath = ArabicNumberToText(Number.parseFloat(totalTax).toFixed(2));
            if (thaibath && totalTax > 0) {
                currentPage.drawText(`${thaibath} `, { x: 195, y: 162, size: fontSize, font: font });
            } else if (totalTax < 1) {
                currentPage.drawText("ศูนย์บาทถ้วน", { x: 195, y: 162, size: fontSize, font: font });
            }
            currentPage.drawText(`X`, { x: 85, y: 121, size: fontSize, font: font });

            const presentDay = new Date();
            const presentDayTH = dayjs(presentDay).locale('th').format("DD");
            const presentMonthTH = dayjs(presentDay).locale('th').format("MMMM");
            const thaiYear = dayjs(presentDay).format('YYYY')
            const formattedThaiYear = parseInt(thaiYear) + 543;

            if (imageData) {
                const pngImage = await pdfDoc.embedPng(imageData);
                const pngDims = pngImage.scale(0.05);
                currentPage.drawImage(pngImage, {
                    x: 380, y: 83, width: pngDims.width, height: pngDims.height
                })
            }

            currentPage.drawText(`${presentDayTH}`, { x: 350, y: 76, size: 13, font: font });
            currentPage.drawText(`${presentMonthTH}`, { x: 372, y: 76, size: 13, font: font });
            currentPage.drawText(`${formattedThaiYear}`, { x: 430, y: 76, size: 13, font: font });
        }
    }
    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    window.open(docUrl, "_blank");
}