import React, { Fragment, useEffect, useState } from "react";
import {
    Box,
    Paper,
    List,
    ListItem,
    Grid,
    Typography,
    Divider,
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    styled,
    MenuItem,
    Autocomplete,
    Drawer,
} from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from "@mui/icons-material";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import axios from "axios"; // Make sure to install axios if you haven't already
import { handleDownloadPdf } from "../../../smart-documents/CreatePdf/utils";
import { deleteAdditionalSmartDocument, upsertAdditionalSmartDocument } from "../../../../../../actions/document";
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployeesByIdCompany } from "../../../../../../actions/employee";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { getUserFullName } from "../../../../../../utils/userData";

const DividerStyled = styled(Divider)({
    margin: "8px 0",
});

const CardItem = ({ data, selectedEmployee, selectedCompany, fetch, editor = false, employeesList }) => {
    const { t, i18n } = useTranslation();
    const [openModal, setOpenModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const { control, handleSubmit, register, setValue, watch, reset } = useForm();
    const [selectedEmployees, setSelectedEmployees] = useState([]);

    const handleEmployeeChange = (event, value) => {
        setSelectedEmployees(value);
    };

    const dispatch = useDispatch();

    const handleOpenModal = (item = null) => {
        setCurrentItem(item);
        setSelectedEmployees(item ? item.idEmployees||[] : []);
        if (!item) {
            reset({
                name: "",
                smartDocumentId: "",
                isShow: false,
            });
        } else {
            reset({
                name: item.name || "",
                smartDocumentId: item.smartDocumentId || "",
                isShow: item.isShow || false,
            });
        }
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setCurrentItem(null);
        setSelectedEmployees([]);
    };

    const onSubmit = async (data) => {
        if (currentItem) {
            await handleEdit(currentItem.idAdditionalSmartDocument, data);
        } else {
            await handleCreate(data);
        }
        fetch();
        handleCloseModal();
    };

    const handleEdit = async (id, data) => {
        try {
            const formdata = {
                idAdditionalSmartDocument: id,
                ...data,
                idCompany: selectedCompany.idCompany,
                idEmployees: selectedEmployees.length === employeesList.length ? [] : selectedEmployees.map((employee) => employee.idEmployees)
            }
            await dispatch(upsertAdditionalSmartDocument(formdata))
            Swal.fire({
                title: `${t("Succeed")}!!`,
                text: `${t('Succeed')}!!`,
                icon: 'success',
                confirmButtonText: `${t("Ok")}`,
            });

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: `${t("AnErrorOccurred")}`,
                text: 'An error occurred while editing the item.',
            });
            console.error("Error editing item:", error);
        }
    };

    const handleCreate = async (data) => {
        try {
            const formdata = {
                ...data,
                idCompany: selectedCompany,
                idEmployees: selectedEmployees.length === employeesList.length ? [] : selectedEmployees.map((employee) => employee.idEmployees)
            }
            await dispatch(upsertAdditionalSmartDocument(formdata));
            Swal.fire({
                title: `${t("Succeed")}!!`,
                text: `${t('Succeed')}!!`,
                icon: 'success',
                confirmButtonText: `${t("Ok")}`,
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: `${t("AnErrorOccurred")}`,
                text: 'An error occurred while creating the item.',
            });
            console.error("Error creating item:", error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await dispatch(deleteAdditionalSmartDocument(id));
            console.log("Delete item with id:", id);
            Swal.fire({
                title: `${t("Succeed")}!!`,
                text: `${t('Succeed')}!!`,
                icon: 'success',
                confirmButtonText: `${t("Ok")}`,
            });
            fetch();
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: `${t("AnErrorOccurred")}`,
                text: 'An error occurred while deleting the item.',
            });
            console.error("Error creating item:", error);
        }
    };

    return (
        <Box sx={{ mt: 4, mb: 4 }}>
            <Box sx={{ mb: 2, pr: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h4" style={{ paddingTop: 8 }}>
                    {t("Smart Document")}
                </Typography>
                {editor && <ButtonBlue
                    variant="contained"
                    onClick={() => handleOpenModal()}
                    style={{ width: 95 }}
                >
                    {t("Create")}
                </ButtonBlue>}
            </Box>

            <Paper className="paper-list" sx={{ pt: 2, pb: 2, pl: 0, pr: 0 }}>
                {data && data.additional && (
                    <List>
                        {data.additional.map((item) => (
                            <Fragment key={item.idAdditionalSmartDocument}>
                                <ListItem>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
                                            <Typography className="filename" variant="body1">
                                                {item.name}
                                            </Typography>
                                            {editor && (item.isShow === 1 ? <VisibilityIcon /> : <VisibilityOffIcon />)}
                                        </Grid>

                                        <Grid item>
                                            <div className="navBox">
                                                <ButtonBlue
                                                    variant="contained"
                                                    disabled={item.smartDocumentId === null}
                                                    onClick={() => handleDownloadPdf(t,item.smartDocumentId, selectedEmployee ? selectedEmployee : null, selectedCompany ? selectedCompany : null, item.name)}
                                                    style={{ width: 95, marginRight: '8px' }}
                                                >
                                                    {t("Download")}
                                                </ButtonBlue>
                                                {editor && <>
                                                    <IconButton color="primary" onClick={() => handleOpenModal(item)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton color="error" onClick={() => handleDelete(item.idAdditionalSmartDocument)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </>}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <DividerStyled />
                            </Fragment>
                        ))}
                    </List>
                )}
            </Paper>

            {/* <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
                <DialogTitle>{currentItem ? t("Edit") : t("Create")}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} pt={1} alignItems="center">
                            <Grid item xs={12} sm={5}>
                                <TextField
                                    fullWidth
                                    label={t("Name")}
                                    variant="outlined"
                                    margin="none"
                                    defaultValue={currentItem ? currentItem.name : ""}
                                    {...register("name", { required: true })}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="smartDocumentId"
                                    control={control}
                                    defaultValue={currentItem ? currentItem.smartDocumentId : ""}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={t("SelectItem")}
                                            select
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e);
                                            }}
                                            fullWidth
                                        >
                                            {data && data.option && data.option.map((item, index) => (
                                                <MenuItem key={`${index}_allSmartDocument`} value={item.smartDocumentId}>
                                                    {item.documentTypeName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={1}>
                                <Controller
                                    name="isShow"
                                    control={control}
                                    defaultValue={false}
                                    render={({ field }) => (
                                        <IconButton
                                            color={field.value ? "default" : "primary"}
                                            onClick={() => {
                                                field.onChange(!field.value);
                                            }}
                                            {...field}
                                        >
                                            {field.value ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                <Grid
                                    xs={12}
                                    className="GridTopicInput"
                                    mb={1}
                                    sx={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'space-between' }}
                                >
                                    {`${t("SelectEmp")}`}
                                    <ButtonBlue
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => setSelectedEmployees(employeesList)}
                                        sx={{ mb: 2 }}
                                    >
                                        {t("SelectAll")}
                                    </ButtonBlue>
                                </Grid>

                                <Autocomplete
                                    multiple
                                    disableListWrap
                                    disablePortal
                                    options={employeesList || []}
                                    getOptionLabel={(option) => getUserFullName(option)}
                                    // isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
                                    getOptionKey={(option) => option.idEmployees}
                                    value={selectedEmployees || []}
                                    onChange={handleEmployeeChange}
                                    renderOption={(props, option) => (
                                        <ListItem {...props} key={option.idEmployees}>
                                            {getUserFullName(option)}
                                        </ListItem>
                                    )}
                                    renderInput={(params) => (
                                        <TextFieldTheme {...params} label={t("PleaseSelectEmployee")} fullWidth />
                                    )}
                                />
                            </Grid>

                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="error">
                        {t("Cancel")}
                    </Button>
                    <Button onClick={handleSubmit(onSubmit)} color="primary">
                        {currentItem ? t("Save") : t("Create")}
                    </Button>
                </DialogActions>
            </Dialog> */}

            <Drawer
                anchor="right"
                open={openModal}
                onClose={handleCloseModal}
                sx={{ maxWidth: '500px', width: '100%' }}
            >
                <DialogTitle>{currentItem ? t("Edit") : t("Create")}</DialogTitle>
                <DialogContent sx={{ maxWidth: '500px', width: '100%' }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} pt={1} alignItems="center">
                            <Grid item xs={12} sm={5}>
                                <TextField
                                    fullWidth
                                    label={t("Name")}
                                    variant="outlined"
                                    margin="none"
                                    defaultValue={currentItem ? currentItem.name : ""}
                                    {...register("name", { required: true })}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="smartDocumentId"
                                    control={control}
                                    defaultValue={currentItem ? currentItem.smartDocumentId : ""}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={t("SelectItem")}
                                            select
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e);
                                            }}
                                            fullWidth
                                        >
                                            {data && data.option && data.option.map((item, index) => (
                                                <MenuItem key={`${index}_allSmartDocument`} value={item.smartDocumentId}>
                                                    {item.documentTypeName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={1}>
                                <Controller
                                    name="isShow"
                                    control={control}
                                    defaultValue={false}
                                    render={({ field }) => (
                                        <IconButton
                                            color={field.value ? "default" : "primary"}
                                            onClick={() => {
                                                field.onChange(!field.value);
                                            }}
                                            {...field}
                                        >
                                            {field.value ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <Grid
                                    xs={12}
                                    className="GridTopicInput"
                                    mb={1}
                                    sx={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'space-between' }}
                                >
                                    {`${t("SelectEmp")}`}
                                    <ButtonBlue
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => setSelectedEmployees(employeesList)}
                                        sx={{ mb: 2 }}
                                    >
                                        {t("SelectAll")}
                                    </ButtonBlue>
                                </Grid>

                                <Autocomplete
                                    multiple
                                    disableListWrap
                                    disablePortal
                                    options={employeesList || []}
                                    getOptionLabel={(option) => getUserFullName(option)}
                                    getOptionKey={(option) => option.idEmployees}
                                    value={selectedEmployees || []}
                                    onChange={handleEmployeeChange}
                                    renderOption={(props, option) => (
                                        <ListItem {...props} key={option.idEmployees}>
                                            {getUserFullName(option)}
                                        </ListItem>
                                    )}
                                    renderInput={(params) => (
                                        <TextFieldTheme {...params} label={t("PleaseSelectEmployee")} fullWidth InputLabelProps={{ sx: { paddingTop: '7px' } }} />
                                    )}
                                />
                            </Grid>

                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="error">
                        {t("Cancel")}
                    </Button>
                    <Button onClick={handleSubmit(onSubmit)} color="primary">
                        {currentItem ? t("Save") : t("Create")}
                    </Button>
                </DialogActions>
            </Drawer >
        </Box >
    );
};

export default CardItem;
