import React from "react"
import ButtonBlue from "../../../../shared/general/ButtonBlue"
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useTranslation } from "react-i18next";

const UploadFileAssessor = (props)=>{
    const {}= props;
    const { t } = useTranslation();
    const {setOpenUploadDialog} = props;
    


    return (
        <ButtonBlue
        variant="outlined"
        color="primary"
        sx={{ height: "40px", ml: 2 }}
        onClick={() => setOpenUploadDialog(true)}
        startIcon={<FileUploadIcon />}
      >
        {t("UploadData")}
      </ButtonBlue>
    )
}

export default UploadFileAssessor;