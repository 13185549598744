import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  Container,
  Grid,
  Tab,
  Tabs,
  Table,
  TablePagination,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Badge,
  Box,
  CardContent,
  Button,
  Chip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditIcon from "@mui/icons-material/Edit";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ClearIcon from "@mui/icons-material/Clear";
import CardStyle from "../../shared/general/Card";

import ConfirmDialog from "./confirm-dialog";

import {
  getAllExpense,
  updateStatusExpense,
} from "../../../../actions/expense";
import dayjs from "dayjs";
import utils from "../../../../utils";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 24,
  },
  "& .head": {
    paddingTop: 8,
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .head-2": {
    paddingTop: 8,
    marginBottom: 24,
    marginTop: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .wrap-sum-amount": {
    marginBottom: 16,
  },
});

const StyledDivTab = styled("div")(({ theme }) => ({
  width: "100%",
  padding: "0 8px",
  marginBottom: 16,
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  fontSize: 16,
  "& .MuiBadge-badge": {
    right: -16,
    top: 12,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#e46a76",
  },
}));

const StyledTable = styled(Table)({
  "& .MuiTableCell-root": {
    textAlign: "center",
    "&.nothing": {
      padding: 32,
    },
  },
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
  },
  "& .tableCellHead:first-of-type": {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "& .cellAction": {
    width: 48,
    padding: "0px 0px 0px 4px",
  },
  "& .tableCellHead-document-no": {
    width: 140,
  },
  "& .tableCellHead-document-types": {
    width: 144,
  },
  "& .tableCellHead-document-name": {
    textAlign: "left",
    //width: 250,
  },
  "& .tableCellHead-document-amount": {
    width: 120,
  },
  "& .tableCellHead-document-status": {
    width: 140,
  },
  "& .tableCellHead:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  "& .tableCellBody-document-name": {
    textAlign: "left",
  },

  "& .tableCellWeekend": {
    color: "#959595",
    fontStyle: "oblique",
  },
  "& .cellStatusAction": {
    textAlign: "left",
    "& .wrap-status": {
      display: "flex",
      "& .item": {
        marginRight: 4,
      },
    },
  },
  "& .cellRequest": {},
  "& .cellStartStop": {
    minWidth: 80,
    "& .MuiButtonBase-root": {
      marginRight: 2,
    },
  },
  "& .rowLink": {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(243, 246, 248, 0.5)",
    },
  },
});

const StyledChipTab = styled(Chip)({
  display: "flex",
  flexDirection: "column",
  height: 20,
  marginLeft: 5,
  backgroundColor: "orangered",
  color: "#fff",
  "& .MuiChip-label": {
    display: "block",
    whiteSpace: "normal",
    textAlign: "center",
  },
});

const StyledChipStatus = styled(Chip)({
  display: "flex",
  flexDirection: "column",
  width: 152,
  "& .MuiChip-label": {
    display: "block",
    whiteSpace: "normal",
    textAlign: "center",
    padding: 8,
  },
  "&.Draft-status": {
    color: "#455a64",
    borderColor: "#fafafa",
  },
  "&.Waiting-status": {
    color: "#7c4dff",
    borderColor: "#7c4dff",
    backgroundColor: "#ede7f6",
  },
  "&.WaitingForAdmin-status": {
    color: "#ff4081",
    borderColor: "#ff4081",
    backgroundColor: "#FFF3FC",
  },
  "&.WaitingForReview-status": {
    color: "#ff4081",
    borderColor: "#ff4081",
    backgroundColor: "#FFF3FC",
  },
  "&.Processing-status": {
    color: "#007AFE",
    borderColor: "#007AFE",
    backgroundColor: "#e3f2fd",
  },
  "&.Holding-status": {
    color: "#ff6d00",
    borderColor: "#ff6d00",
    backgroundColor: "#fff8e1",
  },
  "&.WaitingForPaymentSlip-status": {
    color: "#ffc107",
    borderColor: "#ffc107",
    backgroundColor: "#fffde7",
  },
  "&.WaitingForDocument-status": {
    color: "#64dd17",
    borderColor: "#64dd17",
    backgroundColor: "#f1f8e9",
  },
  "&.Completed-status": {
    color: "#00c853",
    borderColor: "#00c853",
    backgroundColor: "#e8f5e9",
  },
  "&.Rejected-status": {
    color: "#d50000",
    borderColor: "#d50000",
    backgroundColor: "#ffebee",
  },
});

const EditButton = styled(Button)(({ variant, theme }) => ({
  color: "#ff6d00",
  backgroundColor: "transparent",
  border: "solid 2px",
  borderColor: "#ff6d00",
  paddingLeft: 12,
  paddingRight: 12,
  paddingTop: 4,
  paddingBottom: 4,
  borderRadius: 8,
  "&:hover": {
    color: "#ffffff",
    backgroundColor: "#ff6d00",
  },
}));

const StyledTabs = styled(Tabs)({
  "& .Mui-selected": {
    color: "#46cbe2 !important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#46cbe2 !important",
  },
  "& .fal": {
    marginRight: 8,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SubTable(props) {
  const { data, handleClick, ...other } = props;
  const { t, i18n } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const statusList = {
    StatusDraft: t("Draft"),
    StatusWaiting: t("PendingApproval"),
    StatusProcessing: t("InProgress"),
    StatusHolding: t("Holding"),
    StatusCompleted: t("Completed"),
    StatusRejected: t("Rejected"),
    StatusWaitingForAdmin: t("WaitingForReviewRights"),
    StatusWaitingForReview: t("PendingReview"),
    StatusWaitingForDocument: t("WaitingForDocument"),
    StatusWaitingForPaymentSlip: t("WaitingForPaymentSlip"),
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div>
      <TableContainer>
        <StyledTable aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={`tableCellHead tableCellHead-document-no`}>
                {t("DocumentNumber")}
              </TableCell>
              <TableCell
                className={`tableCellHead tableCellHead-document-types`}
              >
                {t("Type")}
              </TableCell>
              <TableCell
                className={`tableCellHead tableCellHead-document-name`}
              >
                {t("ExpenseTitle")}
              </TableCell>
              <TableCell
                className={`tableCellHead tableCellHead-document-amount`}
              >
                {t("TotalAmount")}
              </TableCell>

              <TableCell
                className={`tableCellHead tableCellHead-document-status`}
              >
                {t("Status")}
              </TableCell>
            </TableRow>
          </TableHead>

          {data && data.length && data.length > 0 ? (
            <TableBody>
              {(rowsPerPage > 0
                ? data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : data
              ).map((row, index) => (
                <TableRow
                  className="rowLink"
                  onClick={() => {
                    handleClick(row.idExpense, row.expenseType);
                  }}
                >
                  <TableCell>{row.documentId}</TableCell>
                  <TableCell>{t(row.expenseType)}</TableCell>
                  <TableCell className="tableCellBody-document-name">
                    {row.expenseName}
                  </TableCell>
                  <TableCell>
                    {`${utils.numberWithCommas(parseFloat(row.net))} ${t(
                      "Baht"
                    )}`}
                  </TableCell>
                  <TableCell>
                    <StyledChipStatus
                      label={statusList[`Status${row.name}`]}
                      className={`${row.name}-status`}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableCell className="nothing" colSpan={5}>
                {t("NoData")}
              </TableCell>
            </TableBody>
          )}
        </StyledTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[
          10,
          20,
          30,
          40,
          50,
          { label: t("All"), value: -1 },
        ]}
        component="div"
        count={data && data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={`${t("RowsPerPage")}`}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} ${t("OutOf")} ${
            count !== -1 ? count : `${t("MoreThan")} ${to}`
          }`
        }
      />
    </div>
  );
}

function SubTableWithButton(props) {
  const {
    data,
    textButton,
    iconButton,
    handleClick,
    dialog,
    dialogTitle,
    dialogDescription,
    ...other
  } = props;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({
    isOpen: false,
    idExpense: null,
  });

  const statusList = {
    StatusDraft: t("Draft"),
    StatusWaiting: t("PendingApproval"),
    StatusProcessing: t("InProgress"),
    StatusHolding: t("Holding"),
    StatusCompleted: t("Completed"),
    StatusRejected: t("Rejected"),
    StatusWaitingForAdmin: t("WaitingForReviewRights"),
    StatusWaitingForReview: t("PendingReview"),
    StatusWaitingForDocument: t("WaitingForDocument"),
    StatusWaitingForPaymentSlip: t("WaitingForPaymentSlip"),
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <TableContainer>
        <StyledTable aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={`tableCellHead tableCellHead-document-no`}>
                {t("DocumentNumber")}
              </TableCell>
              <TableCell
                className={`tableCellHead tableCellHead-document-types`}
              >
                {t("Type")}
              </TableCell>
              <TableCell
                className={`tableCellHead tableCellHead-document-name`}
              >
                {t("ExpenseTitle")}
              </TableCell>
              <TableCell
                className={`tableCellHead tableCellHead-document-amount`}
              >
                {t("TotalAmount")}
              </TableCell>

              <TableCell
                className={`tableCellHead tableCellHead-document-status`}
              >
                {t("Status")}
              </TableCell>
              <TableCell
                className={`tableCellHead tableCellHead-document-status`}
              ></TableCell>
            </TableRow>
          </TableHead>

          {data && data.length && data.length > 0 ? (
            <TableBody>
              {(rowsPerPage > 0
                ? data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : data
              ).map((row, index) => (
                <TableRow key={row.idExpense}>
                  <TableCell>{row.documentId}</TableCell>
                  <TableCell>{t(row.expenseType)}</TableCell>
                  <TableCell className="tableCellBody-document-name">
                    {row.expenseName}
                  </TableCell>
                  <TableCell>
                    {`${utils.numberWithCommas(parseFloat(row.net))} ${t(
                      "Baht"
                    )}`}
                  </TableCell>
                  <TableCell>
                    <StyledChipStatus
                      label={statusList[`Status${row.name}`]}
                      className={`${row.name}-status`}
                    />
                  </TableCell>
                  <TableCell>
                    {dialog ? (
                      <div>
                        <EditButton
                          startIcon={iconButton}
                          onClick={() => {
                            setDialogConfig({
                              ...dialogConfig,
                              isOpen: true,
                              idExpense: row.idExpense,
                              expenseTypeId: row.expenseTypeId,
                            });
                          }}
                        >
                          {textButton}
                        </EditButton>
                      </div>
                    ) : (
                      <EditButton
                        startIcon={iconButton}
                        onClick={() => {
                          handleClick(row.idExpense, row.expenseType);
                        }}
                      >
                        {textButton}
                      </EditButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableCell className="nothing" colSpan={6}>
                {t("NoData")}
              </TableCell>
            </TableBody>
          )}
        </StyledTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[
          10,
          20,
          30,
          40,
          50,
          { label: t("All"), value: -1 },
        ]}
        component="div"
        count={data && data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={`${t("RowsPerPage")}`}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} ${t("OutOf")} ${
            count !== -1 ? count : `${t("MoreThan")} ${to}`
          }`
        }
      />
      <ConfirmDialog
        id={dialogConfig.idExpense}
        title={dialogTitle}
        description={dialogDescription}
        open={dialogConfig.isOpen}
        handleOnClick={() => {
          handleClick(dialogConfig.idExpense, dialogConfig.expenseTypeId);
        }}
        handleClose={() => {
          setDialogConfig({ ...dialogConfig, isOpen: false });
        }}
      ></ConfirmDialog>
    </div>
  );
}

const ExpensePage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [numberOfList, setNumberOfList] = useState(0);
  const [statusTab, setStatusTab] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [state, setstate] = useState([]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: expense } = useSelector((state) => state.expense);

  useEffect(() => {
    dispatch(getAllExpense());
  }, [userProfile]);

  const handleApproveTabsChange = (_, newValue) => {
    setStatusTab(newValue);
  };

  const renderListCount = () => {};

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleView = (idExpense, expenseType) => {
    window.open(`/expense/${expenseType}/view/${idExpense}/page`, "_blank");
  };

  const handleEditDraft = (idExpense, expenseType) => {
    history.push(`/expense/${expenseType}/Draft/${idExpense}`);
  };

  const handleEdit = (idExpense, expenseType) => {
    if (idExpense) {
      history.push(`/expense/${expenseType}/Edit/${idExpense}`);
    }
  };

  const handleUploadPaymentSlip = (idExpense, expenseType) => {
    if (idExpense) {
      history.push(`/expense/${expenseType}/Upload-payment/${idExpense}`);
    }
  };

  const handleCancel = async (idExpense, expenseType) => {
    let data = {
      date: dayjs(dayjs().toDate()).format("YYYY/MM/DD HH:mm"),
      status: 1,
      actionType: 2,
      comment: "",
      typeExpense: expenseType,
    };
    if (userProfile && userProfile.idCompany && userProfile.idEmployees) {
      let result = await dispatch(updateStatusExpense(idExpense, data));
      if (result && result.status === 200) {
        dispatch(getAllExpense());
      }
    }
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div className="head">
          <Typography variant="h4">{t("AppMenu.Expense")}</Typography>
          <div>
            <ButtonBlue
              variant="contained"
              startIcon={<AddRoundedIcon />}
              onClick={() => history.push("/expense/create")}
            >
              {t("CreateExpense")}
            </ButtonBlue>
          </div>
        </div>

        <CardStyle>
          {expense && (
            <CardContent>
              <StyledDivTab>
                <StyledTabs
                  value={statusTab}
                  onChange={handleApproveTabsChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="basic tabs example"
                  centered
                >
                  <Tab
                    style={{ minWidth: 180 }}
                    label={
                      <StyledBadge color="secondary">
                        {t("All")}
                        {expense.All &&
                        expense.All.length &&
                        expense.All.length > 0 ? (
                          <StyledChipTab label={expense.All.length} />
                        ) : (
                          <span></span>
                        )}
                      </StyledBadge>
                    }
                    {...a11yProps(0)}
                  />
                  {"Draft" in expense && (
                    <Tab
                      style={{ minWidth: 180 }}
                      label={
                        <StyledBadge color="secondary">
                          {t("Draft")}
                          {expense.Draft &&
                          expense.Draft.length &&
                          expense.Draft.length > 0 ? (
                            <StyledChipTab label={expense.Draft.length} />
                          ) : (
                            <span></span>
                          )}
                        </StyledBadge>
                      }
                      {...a11yProps(1)}
                    />
                  )}
                  {"Waiting" in expense && (
                    <Tab
                      style={{ minWidth: 180 }}
                      label={
                        <StyledBadge color="secondary">
                          {t("PendingApproval")}
                          {expense.Waiting &&
                          expense.Waiting.length &&
                          expense.Waiting.length > 0 ? (
                            <StyledChipTab label={expense.Waiting.length} />
                          ) : (
                            <span></span>
                          )}
                        </StyledBadge>
                      }
                      {...a11yProps(2)}
                    />
                  )}

                  {"WaitingForAdmin" in expense && (
                    <Tab
                      style={{ minWidth: 180 }}
                      label={
                        <StyledBadge color="secondary">
                          {t("PendingReviewRights")}
                          {expense.WaitingForAdmin &&
                          expense.WaitingForAdmin.length &&
                          expense.WaitingForAdmin.length > 0 ? (
                            <StyledChipTab
                              label={expense.WaitingForAdmin.length}
                            />
                          ) : (
                            <span></span>
                          )}
                        </StyledBadge>
                      }
                      {...a11yProps(3)}
                    />
                  )}
                  {"WaitingForReview" in expense && (
                    <Tab
                      style={{ minWidth: 180 }}
                      label={
                        <StyledBadge color="secondary">
                          {t("PendingReview")}
                          {expense.WaitingForReview &&
                          expense.WaitingForReview.length &&
                          expense.WaitingForReview.length > 0 ? (
                            <StyledChipTab
                              label={expense.WaitingForReview.length}
                            />
                          ) : (
                            <span></span>
                          )}
                        </StyledBadge>
                      }
                      {...a11yProps(4)}
                    />
                  )}
                  {"Holding" in expense && (
                    <Tab
                      style={{ minWidth: 180 }}
                      label={
                        <StyledBadge color="secondary">
                          {t("Holding")}
                          {expense.Holding &&
                          expense.Holding.length &&
                          expense.Holding.length > 0 ? (
                            <StyledChipTab label={expense.Holding.length} />
                          ) : (
                            <span></span>
                          )}
                        </StyledBadge>
                      }
                      {...a11yProps(5)}
                    />
                  )}
                  {"Processing" in expense && (
                    <Tab
                      style={{ minWidth: 180 }}
                      label={
                        <StyledBadge color="secondary">
                          {t("InProgress")}
                          {expense.Processing &&
                          expense.Processing.length &&
                          expense.Processing.length > 0 ? (
                            <StyledChipTab label={expense.Processing.length} />
                          ) : (
                            <span></span>
                          )}
                        </StyledBadge>
                      }
                      {...a11yProps(6)}
                    />
                  )}
                  {"WaitingForPaymentSlip" in expense && (
                    <Tab
                      style={{ minWidth: 180 }}
                      label={
                        <StyledBadge color="secondary">
                          {t("WaitingForPaymentSlip")}
                          {expense.WaitingForPaymentSlip &&
                          expense.WaitingForPaymentSlip.length &&
                          expense.WaitingForPaymentSlip.length > 0 ? (
                            <StyledChipTab
                              label={expense.WaitingForPaymentSlip.length}
                            />
                          ) : (
                            <span></span>
                          )}
                        </StyledBadge>
                      }
                      {...a11yProps(7)}
                    />
                  )}
                  {"WaitingForDocument" in expense && (
                    <Tab
                      style={{ minWidth: 180 }}
                      label={
                        <StyledBadge color="secondary">
                          {t("WaitingForDocument")}
                          {expense.WaitingForDocument &&
                          expense.WaitingForDocument.length &&
                          expense.WaitingForDocument.length > 0 ? (
                            <StyledChipTab
                              label={expense.WaitingForDocument.length}
                            />
                          ) : (
                            <span></span>
                          )}
                        </StyledBadge>
                      }
                      {...a11yProps(8)}
                    />
                  )}
                  {"Completed" in expense && (
                    <Tab
                      style={{ minWidth: 180 }}
                      label={
                        <StyledBadge color="secondary">
                          {t("Completed")}
                          {expense.Completed &&
                          expense.Completed.length &&
                          expense.Completed.length > 0 ? (
                            <StyledChipTab label={expense.Completed.length} />
                          ) : (
                            <span></span>
                          )}
                        </StyledBadge>
                      }
                      {...a11yProps(9)}
                    />
                  )}
                  {"Rejected" in expense && (
                    <Tab
                      style={{ minWidth: 180 }}
                      label={
                        <StyledBadge color="secondary">
                          {t("Rejected")}
                          {expense.Rejected &&
                          expense.Rejected.length &&
                          expense.Rejected.length > 0 ? (
                            <StyledChipTab label={expense.Rejected.length} />
                          ) : (
                            <span></span>
                          )}
                        </StyledBadge>
                      }
                      {...a11yProps(10)}
                    />
                  )}
                </StyledTabs>
              </StyledDivTab>

              <Box className="wrap-table">
                <TabPanel value={statusTab} index={0}>
                  <SubTable
                    data={"All" in expense ? expense.All : null}
                    handleClick={handleView}
                  ></SubTable>
                </TabPanel>
                <TabPanel value={statusTab} index={1}>
                  <SubTableWithButton
                    data={"Draft" in expense ? expense.Draft : null}
                    textButton={t("Edit")}
                    iconButton={<EditIcon />}
                    handleClick={handleEditDraft}
                  ></SubTableWithButton>
                </TabPanel>
                <TabPanel value={statusTab} index={2}>
                  <SubTableWithButton
                    data={"Waiting" in expense ? expense.Waiting : null}
                    textButton={t("Cancel")}
                    iconButton={<ClearIcon />}
                    handleClick={handleCancel}
                    dialog={true}
                    dialogTitle={t("CancelFormDialogTitle")}
                    dialogDescription={t("CancelFormDialogDes")}
                  ></SubTableWithButton>
                </TabPanel>
                <TabPanel value={statusTab} index={3}>
                  <SubTable
                    data={
                      "WaitingForAdmin" in expense
                        ? expense.WaitingForAdmin
                        : null
                    }
                    handleClick={handleView}
                  ></SubTable>
                </TabPanel>
                <TabPanel value={statusTab} index={4}>
                  <SubTable
                    data={
                      "WaitingForReview" in expense
                        ? expense.WaitingForReview
                        : null
                    }
                    handleClick={handleView}
                  ></SubTable>
                </TabPanel>

                <TabPanel value={statusTab} index={5}>
                  <SubTableWithButton
                    data={"Holding" in expense ? expense.Holding : null}
                    textButton={t("Edit")}
                    iconButton={<EditIcon />}
                    handleClick={handleEdit}
                  ></SubTableWithButton>
                </TabPanel>
                <TabPanel value={statusTab} index={6}>
                  <SubTable
                    data={"Processing" in expense ? expense.Processing : null}
                    handleClick={handleView}
                  ></SubTable>
                </TabPanel>
                <TabPanel value={statusTab} index={7}>
                  <SubTableWithButton
                    data={
                      "WaitingForPaymentSlip" in expense
                        ? expense.WaitingForPaymentSlip
                        : null
                    }
                    textButton={t("AttachFile")}
                    iconButton={<FileUploadIcon />}
                    handleClick={handleUploadPaymentSlip}
                  ></SubTableWithButton>
                </TabPanel>
                <TabPanel value={statusTab} index={8}>
                  <SubTableWithButton
                    data={
                      "WaitingForDocument" in expense
                        ? expense.WaitingForDocument
                        : null
                    }
                    textButton={t("AttachFile")}
                    iconButton={<FileUploadIcon />}
                    handleClick={handleEdit}
                  ></SubTableWithButton>
                </TabPanel>
                <TabPanel value={statusTab} index={9}>
                  <SubTable
                    data={"Completed" in expense ? expense.Completed : null}
                    handleClick={handleView}
                  ></SubTable>
                </TabPanel>
                <TabPanel value={statusTab} index={10}>
                  <SubTable
                    data={"Rejected" in expense ? expense.Rejected : null}
                    handleClick={handleView}
                  ></SubTable>
                </TabPanel>
              </Box>
            </CardContent>
          )}
        </CardStyle>
      </Container>
    </StyledRoot>
  );
};

export default ExpensePage;
