import { httpClient } from "./httpClient";

const getKPI = (query) => {
  return httpClient.get(`kpi`, { params: query });
};

const getKPIManagerByidEmployee = (query) => {
  return httpClient.get('kpiManagerByidEmployee', { params: query });
};

const addKPI = (formData) => {
  return httpClient.post("kpi", formData);
};

const addManagerKPI = (formData) => {
  return httpClient.post("Managerkpi", formData);
};
const updateManagerKPI = (idKPI, fromdata) => {
  return httpClient.put(`kpi-manager/${idKPI}`, fromdata);
};

const updateKPI = (idKPI, fromdata) => {
  return httpClient.put(`kpi/${idKPI}`, fromdata);
};

const deleteKPI = (idKPI) => {
  return httpClient.delete(`kpi/${idKPI}`);
};

const updateKPISubmit = (idKPI) => {
  return httpClient.put(`kpi-submit/${idKPI}`)
};

const getKpiById = (idKPI) => {
  return httpClient.get(`kpi/${idKPI}`)
}

const getAllKpiApprove = () => {
  return httpClient.get(`kpi-approve`)
};

const getKpiPlan = () => {
  return httpClient.get(`kpi-approveplan`)
};

const updateKPIApprove = (data) => {
  return httpClient.put("kpi-approve", data);
};

const updateKPIplanApprove = (data) => {
  return httpClient.put("kpi-approve-planning", data);
};

const getKpiApprove = () => {
  return httpClient.get(`kpiResultApprove`)
};

const getKpiUpdate = () => {
  return httpClient.get(`kpi-update`)
};

export const getKpiUpdateAndPlan = (query) => {
  return httpClient.get(`kpi-update-plan`, { params: query });
};


const getKpiUpdateProgress = () => {
  return httpClient.get(`kpi-updateprogress`)
};

const getKpiOrganizationCascadeUpdatePlan = (query) => {
  return httpClient.get(`kpi-organization-cascade`, { params: query })
};

const getKpiUpdateById = (idKPI) => {
  return httpClient.get(`kpi-update/${idKPI}`)
}
const addKpiProgress = (formData) => {
  console.log("FormData:", formData); 
  return httpClient.post(`kpi-progress`, formData);
};

const addKpiProgressbar = (formData) => {
  return httpClient.post(`kpi-progresbar`, formData);
};

const addKpiPlan = (formData) => {
  return httpClient.post(`kpi-planprogress`, formData);
};

// const getKpiUpdateMonthById = (idKPI) => {
//   return httpClient.get(`kpiUpdateMonth/${idKPI}`)
// };

const updateKpiProgress = (idKPI, formData) => {
  return httpClient.put(`kpi-progress/${idKPI}`, formData);
};

const getKpiEvaluation = () => {
  return httpClient.get("kpi-evaluation");
};

const updateKPIEvaluationManager = (idKPI, formData) => {
  return httpClient.put(`kpi-evaluation-manager/${idKPI}`, formData);
};

const updateKPIEvaluationEmployee = (idKPI, formData) => {
  return httpClient.put(`kpi-evaluation-employee/${idKPI}`, formData);
};

const getKpiCarlibrate = () => {
  return httpClient.get(`kpi-carlibrate`);
};

const updateKpiGrade = (formData) => {
  return httpClient.put(`kpi-grade`, formData);
};

const getKpiCalibrateDepartment = () => {
  return httpClient.get(`kpi-calibrate-department`);
};

const getKpiSuccessful = (query) => {
  return httpClient.get(`kpi-successful`, { params: query });
};

const getKpiIsSuccessfulManager = () => {
  return httpClient.get(`kpi-successful-manager`);
};

const getKpiUpdateAllAchievementById = () => {
  return httpClient.get(`kpiUpdateAllAchievement`);
};

const getKpiAll = () => {
  return httpClient.get(`kpiAll`);
};

// const putKpiIsSuccessfulById = (idKPI) => {
//   return httpClient.put(`kpiIsSuccessful/${idKPI}`)
// };

const uploadExcelKpi = (formData) => {
  return httpClient.post(`kpi-excel`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const uploadExcelKpiGrade = (formData) => {
  return httpClient.post(`kpi/uploadexcelkpigrade`, formData, { headers: { "Content-Type": "multipart/form-data" } });
};

const getKPIByManager = () => {
  return httpClient.get(`kpiManager`);
};

const getKPIByAdmin = (query) => {
  return httpClient.get(`kpiAdmin`, { params: query });
};

const addKPIDate = (formData) => {
  return httpClient.post("kpiDate", formData);
};

const getKPIDate = (query) => {
  return httpClient.get(`kpiDate`, { params: query });
};

const deleteKPIDate = (idKpiEditDate) => {
  return httpClient.delete(`kpiDate/${idKpiEditDate}`);
};

const updateKPIDate = (idKpiEditDate, formData) => {
  return httpClient.put(`kpi-Date/${idKpiEditDate}`, formData);
};

const getKpiOrganization = (query) => {
  return httpClient.get(`kpi-organization`, { params: query });
};

const addKpiOrganization = (formData) => {
  return httpClient.post(`kpi-organization`, formData);
};

const updateKpiOrganization = (formData) => {
  return httpClient.put(`kpi-organization/${formData.idKpiOrganization}`, formData);
};

const deleteKpiOrganization = (idKpiOrganization) => {
  return httpClient.delete(`kpi-organization/${idKpiOrganization}`);
};

const getKpiOrganizationCascade = (idKpiOrganization) => {
  return httpClient.get(`kpi-organization/${idKpiOrganization}/cascade`);
};

const addKpiOrganizationCascade = (formData) => {
  return httpClient.post(`kpi-organization/cascade`, formData);
};

const deleteKpiOrganizationCascade = (idKpiOrganizationEmployees) => {
  return httpClient.delete(`kpi-organization/${idKpiOrganizationEmployees}/cascade`);
};

const getKpiOrganizationProgressById = (idKpiOrganization) => {
  return httpClient.get(`kpi-organization/${idKpiOrganization}/progress`);
};

const addKpiOrganizationProgress = (formData) => {
  return httpClient.post(`kpi-organization/progress`, formData);
};

const updateKpiOrganizationProgressPercent = (formData) => {
  return httpClient.patch(`kpi-organization/${formData.idKpiOrganization}/percent`, formData);
};

const updateKpiOrganizationEvaluate = (formData) => {
  return httpClient.patch(`kpi-organization/${formData.idKpiOrganization}/evaluate`, formData);
};

const getKpiTeamByCompany = (query) => {
  return httpClient.get(`kpi-team/company`, { params: query });
};

const getKpiTeam = (query) => {
  return httpClient.get(`kpi-team`, { params: query });
};

const addKpiTeam = (formData) => {
  return httpClient.post(`kpi-team`, formData);
};

const updateKpiTeam = (formData) => {
  return httpClient.put(`kpi-team/${formData.idKpiTeam}`, formData);
};

const deleteKpiTeam = (idKpiTeam) => {
  return httpClient.delete(`kpi-team/${idKpiTeam}`);
};

const getKpiTeamProgressById = (idKpiTeam) => {
  return httpClient.get(`kpi-team/${idKpiTeam}/progress`);
};

const addKpiTeamProgress = (formData) => {
  return httpClient.post(`kpi-team/progress`, formData);
};

const updateKpiTeamProgressPercent = (formData) => {
  return httpClient.patch(`kpi-team/${formData.idKpiTeam}/percent`, formData);
};

const updateKpiTeamEvaluate = (formData) => {
  return httpClient.patch(`kpi-team/${formData.idKpiTeam}/evaluate`, formData);
};

const getKpiPendingApprove = (query) => {
  return httpClient.get(`kpi-pending-approve`, { params: query });
};

const getKpiPendingEvaluation = (query) => {
  return httpClient.get(`kpi-pending-evaluation`, { params: query });
};

export default {
  getKPI,
  getKPIManagerByidEmployee,
  addKPI,
  addManagerKPI,
  updateKPI,
  deleteKPI,
  updateKPISubmit,
  getKpiById,
  getAllKpiApprove,
  updateKPIApprove,
  updateKPIplanApprove,
  getKpiUpdate,
  getKpiUpdateAndPlan,
  getKpiUpdateProgress,
  getKpiOrganizationCascadeUpdatePlan,
  getKpiUpdateById,
  addKpiProgress,
  addKpiProgressbar,
  addKpiPlan,
  getKpiPlan,
  getKpiApprove,
  getKpiEvaluation,
  updateManagerKPI,
  // getKpiUpdateMonthById,
  updateKpiProgress,
  updateKPIEvaluationManager,
  updateKPIEvaluationEmployee,
  getKpiCarlibrate,
  updateKpiGrade,
  getKpiCalibrateDepartment,
  getKpiSuccessful,
  getKpiIsSuccessfulManager,
  getKpiUpdateAllAchievementById,
  getKpiAll,
  uploadExcelKpi,
  uploadExcelKpiGrade,
  getKPIByManager,
  getKPIByAdmin,
  addKPIDate,
  getKPIDate,
  deleteKPIDate,
  updateKPIDate,
  getKpiOrganization,
  addKpiOrganization,
  updateKpiOrganization,
  deleteKpiOrganization,
  getKpiOrganizationCascade,
  addKpiOrganizationCascade,
  deleteKpiOrganizationCascade,
  getKpiOrganizationProgressById,
  addKpiOrganizationProgress,
  updateKpiOrganizationProgressPercent,
  updateKpiOrganizationEvaluate,
  getKpiTeamByCompany,
  getKpiTeam,
  addKpiTeam,
  updateKpiTeam,
  deleteKpiTeam,
  getKpiTeamProgressById,
  addKpiTeamProgress,
  updateKpiTeamProgressPercent,
  updateKpiTeamEvaluate,
  getKpiPendingApprove,
  getKpiPendingEvaluation,
};