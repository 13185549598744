import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { getAffiliateOrganization } from "../../../../actions/company";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { useTranslation } from "react-i18next";
import { getLevelByidCompany } from "../../../../actions/level";
import TableAppraiser from "./component/createAssessment/TableAppraiser";
import DatePickerCustom from "../../shared/date/datePicker";
import {
  Grid,
  Typography,
  Container,
  autocompleteClasses,
  Autocomplete,
  Popper,
  Box,
  Chip,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import FormQuestion from "./component/createAssessment/formQuestion";
import { openNotificationAlert } from "../../../../actions/notificationAlert";
import {
  getUniQuestion,
  postPotentailAssessment,
  getAppraiserByIdLevelGroup,
} from "../../../../actions/potential";
import { getAllEmployeesByIdCompany } from "../../../../actions/employee";
import DialogDropzoneAssessor from "./component/createAssessment/dialogDropZoneAssessor";
import UploadFileAssessor from "./component/createAssessment/uploadFileAssessor";
import SetRatioSampleSize from "./component/createAssessment/setRationSampleSize";
import CardStyle from "../../shared/general/Card";
import dayjs from "dayjs";

const StyledHeadingPage = styled("div")({
  marginBottom: 16,
});

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: "5%",
  "& .form-radio-label-type": {
    ["@media only screen and (max-width: 600px)"]: {
      flexBasis: "25%",
      width: "fit-content",
      margin: 0,
    },
    "& .label-radio": {
      ["@media only screen and (max-width: 600px)"]: {
        fontSize: 14,
      },
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});
const StyledAutocomplete = styled(Autocomplete)({
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    minHeight: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const AddPotentialQuestionPage = () => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [levels, setLevels] = useState([]);
  const [selectedTypeQuestionForm, setSelectedTypeQuestionForm] = useState(1);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [ratioSampleSize, SetRatioSample] = useState({
    isComplete: false,
    ratio: [],
  });
  const [loadingCraeteAssessment, setLoadingCraeteAssessment] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );
  const { result: potentialAppraisers } = useSelector(
    (state) => state.potentialAppraiser
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: groupLevel } = useSelector((state) => state.level);
  const { result: uniQuestion } = useSelector(
    (state) => state.potentialUniQuestion
  );

  const validationSchema = yup.object().shape({
    questionSetName: yup
      .string()
      .max(100, `${t("NotMoreThan")} 100 ${t("Characters")}`)
      .required(t("ThisFieldIsRequired")),
    detailQuestion: yup
      .string()
      .max(200, `${t("NotMoreThan")} 200 ${t("Characters")}`)
      .required(t("ThisFieldIsRequired")),
    answerScale: yup.number(),
    levels: yup.array().min(1, t("ThisFieldIsRequired")),
    questionList: yup.array().of(
      yup.object().shape({
        question: yup
          .string()
          .max(200, `${t("NotMoreThan")} 200 ${t("Characters")}`)
          .required(t("ThisFieldIsRequired")),
        questionType: yup.string().required(t("ThisFieldIsRequired")),
      })
    ),
    startDate: yup.string().required(`${t("ThisFieldIsRequired")}`),
    endDate: yup.string().required(`${t("ThisFieldIsRequired")}`),
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      questionSetName: "",
      detailQuestion: "",
      questionList: [
        {
          question: "",
          questionType: "",
        },
      ],
      answerScale: 6,
      levels: [],
      isSelfAssessment: false,
      isManagerAssessment: true,
      idCompany: "",
      startDate: "",
      endDate: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    rules: {
      maxLength: 10,
    },
    control: control,
    name: "questionList",
  });

  const onChangeCompany = (value) => {
    setValue("idCompany", selectedCompany);
    setSelectedCompany(value);
  };

  const onSubmit = async (data) => {
    let hasIncompleteData = false;
    let appraisees = [];
    potentialAppraisers.forEach((e) => {
      if (!e.isComplete) {
        hasIncompleteData = true;
        return;
      }
      appraisees.push({
        idEmployees: e.idEmployees,
        appraisers: e.appraisers,
      });
    });
    if (hasIncompleteData) {
      dispatch(
        openNotificationAlert({
          type: "error",
          message: "กรุณากรอกข้อมูลผู้ประเมินให้เรียบร้อย",
        })
      );
    } else {
      let formData = {
        assessment_p_questionName: data.questionSetName,
        assessment_p_detail: data.detailQuestion,
        answerScale: data.answerScale,
        levels: data.levels,
        questionList: data.questionList,
        appraisees: appraisees,
        idCompany: selectedCompany.idCompany,
        startDate: dayjs(data.startDate),
        endDate: dayjs(data.endDate),
      };
      const result = await dispatch(postPotentailAssessment(formData));
      if (result && result.status === 200) {
        dispatch(
          openNotificationAlert({
            type: "success",
            message: t("DataSaveSuccessful"),
          })
        );
        //navigate to this replace
        ///potentail-assessment
        history.replace("/potential-assessment");
      } else {
        dispatch(
          openNotificationAlert({
            type: "error",
            message: t("AnErrorOccurred"),
          })
        );
      }
    }
  };

  const fetchingUniQuestion = async () => {
    setSelectedTypeQuestionForm(2);
    dispatch(getUniQuestion());
  };

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (
      userProfile &&
      affiliateOrganizationList &&
      affiliateOrganizationList.length > 0
    ) {
      let ownCompany = affiliateOrganizationList.find(
        (x) => x.idCompany === userProfile.idCompany
      );
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany);
      setValue("groupLevel", null);
    }
  }, [userProfile, affiliateOrganizationList]);

  useEffect(() => {
    if (selectedCompany != null) {
      console.log(selectedCompany);
      dispatch(
        getLevelByidCompany({
          idCompany: selectedCompany.idCompany,
        })
      );

      dispatch(getAllEmployeesByIdCompany(selectedCompany.idCompany));
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (groupLevel && groupLevel.length > 0) {
      setLevels(
        groupLevel
          .filter((group) => group.level && Array.isArray(group.level))
          .flatMap((group) => group.level)
      );
    }
  }, [groupLevel]);

  useEffect(() => {
    if (groupLevel && selectedLevels != null) {
      const levels = selectedLevels.map((level) => level.idLevel);
      console.log(levels);

      dispatch(
        getAppraiserByIdLevelGroup({
          idLevels: levels,
        })
      );
    }
  }, [selectedLevels]);

  useEffect(() => {
    if (uniQuestion && uniQuestion.length > 0) {
      const updatedQuestionList = uniQuestion.map((e) => ({
        question: e.question,
        questionType: "",
      }));

      reset({
        ...watch(),
        questionList: updatedQuestionList,
      });
    }
  }, [uniQuestion, setValue]);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg" sx={{ paddingBottom: "5%" }}>
        <StyledHeadingPage>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            ประเมินศักยภาพ
          </Typography>
        </StyledHeadingPage>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={6}>
              <Typography className="label" color="text.third">
                {`${t("Company")}`}
              </Typography>
              <SelectAffiliateCompany
                options={affiliateOrganizationList}
                value={selectedCompany}
                onChange={(_, value) => {
                  onChangeCompany(value);
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label" color="text.third">
                {t("JobLevel")}
              </Typography>
              <Controller
                control={control}
                name="levels"
                render={({ field, fieldState }) => (
                  <StyledAutocomplete
                    {...field}
                    multiple
                    options={[...(levels || [])]}
                    onChange={(e, newValue) => {
                      field.onChange(newValue ? newValue : null);
                      setSelectedLevels(newValue);
                    }}
                    getOptionLabel={(option) => option.levelName}
                    noOptionsText={`${t("NoData")}`}
                    isOptionEqualToValue={(option, value) =>
                      option.idLevel === value.idLevel
                    }
                    PopperComponent={StyledPopper}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        key={option.idLevel}
                        value={option.idLevel}
                      >
                        {option.levelName}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        variant="filled"
                        error={Boolean(fieldState.error)}
                        helperText={
                          fieldState.error && fieldState.error.message
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <SetRatioSampleSize SetRatioSample={SetRatioSample} />
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "10px",
                }}
              >
                <Typography className="label" color="text.third">
                  เลือกคนประเมินให้พนักงาน
                </Typography>
                {ratioSampleSize.isComplete && (
                  <UploadFileAssessor
                    setOpenUploadDialog={setOpenUploadDialog}
                  />
                )}
              </div>
              {ratioSampleSize.isComplete ? (
                <TableAppraiser
                  SetRatioSample={SetRatioSample}
                  ratioSampleSize={ratioSampleSize}
                />
              ) : (
                <CardStyle>
                  <Box
                    style={{
                      minHeight: 400,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography className="label" color="text.third">
                      กรุณากรอก ratio ให้สมบูรณ์ก่อน
                    </Typography>
                  </Box>
                </CardStyle>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography className="label" color="text.third">
                {t("QuestionSet")}
              </Typography>
              <Controller
                control={control}
                name={"questionSetName"}
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error && fieldState.error.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="label" color="text.third">
                {t("Descriptions")}
              </Typography>
              <Controller
                control={control}
                name={"detailQuestion"}
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    multiline
                    inputProps={{
                      maxLength: 200,
                    }}
                    rows={4}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    error={!!fieldState.error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className="label" color="text.third">
                {t("StartDate")}
              </Typography>
              <Controller
                name="startDate"
                control={control}
                render={({ field, fieldState }) => (
                  <DatePickerCustom
                    {...field}
                    inputFormat="DD/MM/YYYY"
                    minDate={dayjs()}
                    onChange={(date) => {
                      setValue("startDate", date);
                      if (date.isAfter(dayjs(watch("endDate")))) {
                        setValue("endDate", dayjs(date).add(1, "day"));
                      }
                    }}
                    views={["year", "month", "day"]}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        helperText={
                          fieldState.error ? fieldState.error.message : ""
                        }
                        error={!!fieldState.error}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className="label" color="text.third">
                {t("EndDate")}
              </Typography>
              <Controller
                name="endDate"
                control={control}
                render={({ field, fieldState }) => (
                  <DatePickerCustom
                    {...field}
                    minDate={dayjs(watch("startDate"))}
                    defa
                    inputFormat="DD/MM/YYYY"
                    views={["year", "month", "day"]}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        helperText={
                          fieldState.error ? fieldState.error.message : ""
                        }
                        error={!!fieldState.error}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className="label" color="text.third">
                รูปแบบคำถาม
              </Typography>
              <Chip
                label="สร้างคำถามเอง"
                component="a"
                variant={selectedTypeQuestionForm === 1 ? "filled" : "outlined"}
                clickable
                sx={{
                  marginRight: "5px",
                  backgroundColor:
                    selectedTypeQuestionForm === 1 ? "blue" : "default",
                  color: selectedTypeQuestionForm === 1 ? "white" : "default",
                  "&:hover": {
                    backgroundColor:
                      selectedTypeQuestionForm === 1 ? "blue" : "default",
                  },
                }}
                onClick={
                  selectedTypeQuestionForm === 1
                    ? null
                    : () => {
                        reset({
                          ...watch(),
                          questionList: [
                            {
                              question: "",
                              questionType: "",
                            },
                          ],
                        });
                        setSelectedTypeQuestionForm(1);
                      }
                }
              />
              <Chip
                label="ใช้คำถามจาก UNI"
                component="a"
                variant={selectedTypeQuestionForm === 2 ? "filled" : "outlined"}
                clickable
                sx={{
                  backgroundColor:
                    selectedTypeQuestionForm === 2 ? "blue" : "default",
                  color: selectedTypeQuestionForm === 2 ? "white" : "default",
                  "&:hover": {
                    backgroundColor:
                      selectedTypeQuestionForm === 2 ? "blue" : "default",
                  },
                }}
                onClick={
                  selectedTypeQuestionForm === 2
                    ? null
                    : () => fetchingUniQuestion()
                }
              />
            </Grid>
          </Grid>
          {fields.map((question, index) => (
            <FormQuestion
              control={control}
              question={question}
              index={index}
              remove={remove}
            />
          ))}
          {fields.length < 10 && (
            <ButtonBlue
              variant="outlined"
              startIcon={<AddIcon />}
              disable={isSubmitting}
              onClick={() =>
                append({
                  question: "",
                  questionType: "",
                })
              }
            >
              {t("AddQuestion")}
            </ButtonBlue>
          )}
          <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
            <ButtonBlue
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              {t("Save")}
            </ButtonBlue>
          </Grid>
        </form>

        {openUploadDialog && (
          <DialogDropzoneAssessor
            ratioSampleSize = {ratioSampleSize}
            open={openUploadDialog}
            onClose={() => setOpenUploadDialog(false)}
          />
        )}
      </Container>
    </StyledRoot>
  );
};

export default AddPotentialQuestionPage;
