import {
  POTENTIAL_APPRAISER_SUCCESS,
  POTENTIAL_APPRAISER_FETCHING,
  POTENTIAL_APPRAISER_FAILED,

  POTENTIAL_UNI_QUESTION_SUCCESS,
  POTENTIAL_UNI_QUESTION_FETCHING,
  POTENTIAL_UNI_QUESTION_FAILED,

  POTENTIAL_ASSESSMENT_FAILED,
  POTENTIAL_ASSESSMENT_SUCCESS,
  POTENTIAL_ASSESSMENT_FETCHING,

  POTENTIAL_QUESTION_LIST_FAILED,
  POTENTIAL_QUESTION_LIST_SUCCESS,
  POTENTIAL_QUESTION_LIST_FETCHING,
  
  POTENTIAL_OVERVIEW_APPRAISER_SUCCESS,
  POTENTIAL_OVERVIEW_APPRAISER_FAILED,
  POTENTIAL_OVERVIEW_APPRAISER_FETCHING,

  POTENTIAL_DETAIL_OVERVIEW_APPRAISER_SUCCESS,
  POTENTIAL_DETAIL_OVERVIEW_APPRAISER_FAILED,
  POTENTIAL_DETAIL_OVERVIEW_APPRAISER_FETCHING,

} from "./types";
import potentialService from "../services/potential.service";

export const getAllPotential = async () => {
  try {
    const res = await potentialService.getAllPotential()
    if (res) {
      return res;
    };
  } catch (err) {
    return err;
  }
};

export const getPotentailAssessmentAdmin = (filter)=> async(dispatch)=>{
  try {
    dispatch({
      type:POTENTIAL_ASSESSMENT_FETCHING
    });
    const res = await potentialService.getPotentailAssessmentAdmin(filter);
    if(res){
      dispatch({
        type:POTENTIAL_ASSESSMENT_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
     dispatch({
          type: POTENTIAL_ASSESSMENT_FAILED,
        });
        console.log(error);
  }
}

export const getPotentailAssessment   =(filter)=> async (dispatch) => {
  try {
    dispatch({
      type:POTENTIAL_ASSESSMENT_FETCHING
    });
    const res = await potentialService.getPotentailAssessment(filter);
    if(res){
      dispatch({
        type:POTENTIAL_ASSESSMENT_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
     dispatch({
          type: POTENTIAL_ASSESSMENT_FAILED,
        });
        console.log(error);
  }
};
export const   getQuestionListByIdPotentialAssessment=(idPotentialAssessmentQuestionSet)=> async (dispatch) => {
  try {
    dispatch({
      type:POTENTIAL_QUESTION_LIST_FETCHING
    });
    const res = await potentialService.getQuestionListByIdPotentialAssessment(idPotentialAssessmentQuestionSet);
    if(res){
      dispatch({
        type:POTENTIAL_QUESTION_LIST_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
     dispatch({
          type: POTENTIAL_QUESTION_LIST_FAILED,
        });
        console.log(error);
  }
};



export const getUniQuestion = () =>async (dispatch)=>{
  try {
    dispatch({
      type:POTENTIAL_UNI_QUESTION_FETCHING
    });
    const res = await potentialService.getUniQuestion();
    if(res){
      dispatch({
        type:POTENTIAL_UNI_QUESTION_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
     dispatch({
          type: POTENTIAL_UNI_QUESTION_FAILED,
        });
        console.log(error);
  }

}
export const getAppraiserByIdLevelGroup = (query) =>async (dispatch)=>{
  try {
    dispatch({
      type:POTENTIAL_APPRAISER_FETCHING
    });
    const res = await potentialService.getAppraiserByIdLevelGroup(query);
    if(res){
      dispatch({
        type:POTENTIAL_APPRAISER_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
     dispatch({
          type: POTENTIAL_APPRAISER_FAILED,
        });
        console.log(error);
  }
}



export const postPotentailAssessment = (formData)=> async()=>{
  try {
     const res = await potentialService.postPotentailAssessment(formData);
     if (res) {
       return res;
     }
   } catch (error) {
     const message =
       (error.response && error.response.data && error.response.data.name) ||
       error.name ||
       error.toString();
     return error;
   }
}

export const postAnswerPotentialAssessment = (formData) => async()=>{
  try {
    const res = await potentialService.postAnswerPotentialAssessment(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
}


export const getOverviewAssessmentByIdQuestionSet = (idAssessmentQuestionSet) =>async (dispatch)=>{
  try {
    dispatch({
      type:POTENTIAL_OVERVIEW_APPRAISER_FETCHING
    });
    const res = await potentialService.getOverviewAssessmentByIdQuestionSet(idAssessmentQuestionSet);
    if(res){
      dispatch({
        type:POTENTIAL_OVERVIEW_APPRAISER_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
     dispatch({
          type: POTENTIAL_OVERVIEW_APPRAISER_FAILED,
        });
        console.log(error);
  }
}
export const getDetailAnswerPotentialAssessment = (idAppraisee) =>async (dispatch)=>{
  try {
    dispatch({
      type:POTENTIAL_DETAIL_OVERVIEW_APPRAISER_FETCHING
    });
    const res = await potentialService.getDetailAnswerPotentialAssessment(idAppraisee);
    if(res){
      dispatch({
        type:POTENTIAL_DETAIL_OVERVIEW_APPRAISER_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
     dispatch({
          type: POTENTIAL_DETAIL_OVERVIEW_APPRAISER_FAILED,
        });
        console.log(error);
  }
}


