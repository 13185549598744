import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useTranslation } from "react-i18next";

const handlerExportPDF = async (tableData) => {
  const columns = [
    { key: 'Task', label: "Task", width: '25%' },
    { key: 'Core', label: 'Core', width: '10%' },
    { key: 'Weight', label: "Weight(%)", width: '10%' },
  ];

  const tableContainer = document.createElement('div');
  tableContainer.style.width = '800px';
  tableContainer.style.padding = '20px';
  tableContainer.style.fontFamily = 'Arial, sans-serif';
  const table = document.createElement('div');
  table.style.display = 'table';
  table.style.width = '800px';
  table.style.border = '1px solid #ccc';

  const headerRow = document.createElement('div');
  headerRow.style.display = 'table-row';
  columns.forEach((col) => {
    const headerCell = document.createElement('div');
    headerCell.style.display = 'table-cell';
    headerCell.style.padding = '10px';
    headerCell.style.fontWeight = 'bold';
    headerCell.style.fontSize = '18px';
    headerCell.style.backgroundColor = '#f2f2f2';
    headerCell.style.textAlign = 'center';
    headerCell.style.border = '1px solid #ccc';
    headerCell.style.width = col.width;
    headerCell.textContent = col.label;
    headerRow.appendChild(headerCell);
  });
  table.appendChild(headerRow);
  tableData.forEach((row) => {
    const rowElement = document.createElement('div');
    rowElement.style.display = 'table-row';

    const taskCell = document.createElement('div');
    taskCell.style.display = 'table-cell';
    taskCell.style.padding = '10px';
    taskCell.style.fontSize = '14px';
    taskCell.style.textAlign = 'left';
    taskCell.style.border = '1px solid #ccc';
    taskCell.style.width = '25%';
    taskCell.textContent = row.task;

    
    const coreCell = document.createElement('div');
    coreCell.style.display = 'table-cell';  
    coreCell.style.padding = '10px';
    coreCell.style.textAlign = 'center';
    coreCell.style.backgroundColor = "#ffffff";
    coreCell.style.border = '1px solid #ccc';
    coreCell.style.width = '10%';
    coreCell.textContent = row.coreName.toUpperCase();


    const weightCell = document.createElement('div');
    weightCell.style.display = 'table-cell';
    weightCell.style.padding = '10px';
    weightCell.style.textAlign = 'center';
    weightCell.style.border = '1px solid #ccc';
    weightCell.style.width = '10%';
    weightCell.textContent = row.weight;


    rowElement.appendChild(taskCell);
    rowElement.appendChild(coreCell);
    rowElement.appendChild(weightCell);

    table.appendChild(rowElement);
  });

  tableContainer.appendChild(table);
  document.body.appendChild(tableContainer); // เพิ่มเพื่อสร้างใน DOM แบบชั่วคราว

  try {
    const canvas = await html2canvas(tableContainer, { scale: 3 }); // เพิ่มความละเอียดของภาพ
    const imgData = canvas.toDataURL('image/png');

    const doc = new jsPDF();
    const pdfWidth = doc.internal.pageSize.width;
    const pdfHeight = doc.internal.pageSize.height;

    const imgWidth = pdfWidth - 20; // ขนาดของภาพให้พอดีกับความกว้างของหน้า PDF
    const imgHeight = (canvas.height * imgWidth) / canvas.width; // คำนวณอัตราส่วนของภาพ

    doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight); // เพิ่มภาพลงใน PDF
    doc.save('job_description.pdf');
  } catch (error) {
    console.error('Error generating canvas:', error);
  } finally {
    document.body.removeChild(tableContainer);
  }
};

export default handlerExportPDF;
