import React, { useEffect, useState } from "react";
import {
  Fab,
  Typography,
  Grid,
  InputAdornment,
  styled,
} from "@mui/material";
import personImage from "../../assets/person.gif";
import { Controller, useForm } from "react-hook-form";
import CardStyle from "../../../../shared/general/Card";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));

const GroupController = ({ name, control, disabledCondition,setValue ,totalRatio}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <TextFieldTheme
            {...field}
            width="40"
            type="number"
            disabled={totalRatio===100 && field.value == 0}
            onChange={(e)=>{
              if(Number.isNaN(parseInt(e.target.value))){
                setValue(name,parseInt(0));
              }
              else{
                if(totalRatio - 100 < 0){
                  setValue(name,parseInt(e.target.value));
                }else{
                  setValue(name,parseInt(0));
                }
              }
              

            }}
            variant="outlined"
            inputProps={{
              min: 0,
              max: 100,
              step: 5,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              ),
            }}
          />
        )}
      />
      <FabRole
        variant="extended"
        aria-label="add"
        disabled={disabledCondition}
        group={name}
        sx={{
          marginTop: "15px",
        }}
      >
        {name}
      </FabRole>
    </div>
  );
};

const FabRole = styled(Fab)(({ group }) => {
  const btnMaster = {
    minWidth: 100,
    color: "#FFFFFF",
    height: 40,
    fontSize: 16,
  };

  switch (group) {
    case "boss":
      return {
        backgroundColor: "#EC5B6A",
        "&:hover": { backgroundColor: "#EC5B6A" },
        ...btnMaster,
      };
    case "peer":
      return {
        backgroundColor: "#ff8d00",
        "&:hover": { backgroundColor: "#d38e39" },
        ...btnMaster,
      };
    case "crossfunction":
      return {
        backgroundColor: "#7d55c2",
        "&:hover": { backgroundColor: "#483170" },
        ...btnMaster,
      };
    case "customer":
      return {
        backgroundColor: "#008dd1",
        "&:hover": { backgroundColor: "#025780" },
        ...btnMaster,
      };
    case "subordinate":
      return {
        backgroundColor: "#00bdb1",
        "&:hover": { backgroundColor: "#027c74" },
        ...btnMaster,
      };
    case "project":
      return {
        backgroundColor: "#ffc700",
        "&:hover": { backgroundColor: "#c59b02" },
        ...btnMaster,
      };
    case "outsider":
      return {
        backgroundColor: "#820747",
        "&:hover": { backgroundColor: "#311432" },
        ...btnMaster,
      };
    case "oneself":
      return {
        backgroundColor: "#b46c6c",
        "&:hover": { backgroundColor: "#544541" },
        ...btnMaster,
      };

    default:
      return btnMaster;
  }
});

const SetRatioSampleSize = (props) => {

  const {SetRatioSample} = props;

  const [totalRatio, setTotalRatio] = useState(0);
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      boss: parseInt(50),
      peer: 0,
      project: 0,
      subordinate: 0,
      customer: 0,
      crossfunction: 0,
    },
    // resolver: yupResolver(),
    mode: "all",
  });

  const values = watch(); // Watch all fields

  useEffect(() => {
    const temp =
      parseInt(values.boss ? values.boss :0 ) +
      parseInt(values.peer?values.peer :0) +
      parseInt(values.project?values.project :0) +
      parseInt(values.subordinate?values.subordinate :0) +
      parseInt(values.customer?values.customer :0) +
      parseInt(values.crossfunction?values.crossfunction :0);
    setTotalRatio(temp?temp:0);
  }, [values]);


  useEffect(()=>{
    if(totalRatio==100){
      SetRatioSample(
        {
          isComplete: true,
          ratio: [
            {
               role : "boss",
               ratio:parseInt(values.boss)
            },
            {
               role : "peer",
               ratio:parseInt(values.peer)
            },
            {
               role : "project",
               ratio:parseInt(values.project)
            },
            {
               role : "subordinate",
               ratio:parseInt(values.subordinate)
            },
            {
               role : "customer",
               ratio:parseInt(values.customer)
            },
            {
               role : "crossfunction",
               ratio:parseInt(values.crossfunction)
            }
          ]
        }
      )
    }else{
      SetRatioSample(
        {
          isComplete: false,
          ratio: [
            {
               role : "boss",
               ratio:parseInt(values.boss)
            },
            {
               role : "peer",
               ratio:parseInt(values.peer)
            },
            {
               role : "project",
               ratio:parseInt(values.project)
            },
            {
               role : "subordinate",
               ratio:parseInt(values.subordinate)
            },
            {
               role : "customer",
               ratio:parseInt(values.customer)
            },
            {
               role : "crossfunction",
               ratio:parseInt(values.crossfunction)
            }
          ]
        }
      )
    }
  },[totalRatio])

  return (
    <form>
      <CardStyle>
        <Grid
          container
          spacing={3}
          sx={{
            padding: "24px",
          }}
        >
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <GroupController
              name="crossfunction"
              control={control}
              totalRatio={totalRatio}
              setValue={setValue}
              disabledCondition={watch("crossfunction") == 0}
            />
            <GroupController
              name="project"
              control={control}
              totalRatio={totalRatio}
              setValue={setValue}
              disabledCondition={watch("project") == 0}
            />
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <GroupController
              name="boss"
              control={control}
              totalRatio={totalRatio}
              setValue={setValue}
              disabledCondition={watch("boss") == 0}
            />
            <div
              style={{
                marginTop: "30px",
                marginBottom: "15px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  align="center"
                  color="primary"
                  style={{ fontWeight: 500 }}
                >
                  {totalRatio}
                </Typography>
                <Typography align="center" color="textSecondary">
                  {"/100%"}
                </Typography>
              </div>

              <BorderLinearProgress variant="determinate" value={totalRatio} />
            </div>
            <div
              style={{
                marginTop: "15px",
                marginBottom: "15px",
              }}
            >
              <img width={150} src={personImage} alt="me" />
            </div>
            <GroupController
              name="subordinate"
              control={control}
              totalRatio={totalRatio}
              setValue={setValue}
              disabledCondition={watch("subordinate") == 0}
            />
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <GroupController
              name="customer"
              control={control}
              totalRatio={totalRatio}
              setValue={setValue}
              disabledCondition={watch("customer") == 0}
            />
            <GroupController
              name="peer"
              control={control}
              totalRatio={totalRatio}
              setValue={setValue}
              disabledCondition={watch("peer") == 0}
            />
          </Grid>
        </Grid>
      </CardStyle>
    </form>
  );
};

export default SetRatioSampleSize;
