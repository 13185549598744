import React, { Fragment, useEffect, useState } from 'react';
import { Autocomplete, Avatar, Box, Breadcrumbs, Container, Grid, IconButton, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom'
import SelectAffiliateCompany from '../../shared/general/SelectAffiliateCompany';
import dayjs from 'dayjs';
import { getAffiliateOrganization } from '../../../../actions/company';
import { useDispatch, useSelector } from 'react-redux';
import DatePickerCustom from '../../shared/date/datePicker';
import { addEmployeeTalent, getAllEmployeeTalentByCompanyAndTalent, getTalentList, updateEmployeeTalent } from '../../../../actions/employee';
import { getUserCompany, getUserDepartment, getUserDivision, getUserFullName, getUserPosition, getUserSection } from '../../../../utils/userData';
import loading from '../../assets/social-media.gif';
import { useLocation, useHistory } from 'react-router-dom';
import ButtonBlue from '../../shared/general/ButtonBlue';
import EditIcon from '@mui/icons-material/Edit';
import AlertResponse from '../../shared/general/AlertResponse';

const StyledRoot = styled("div")({
    backgroundColor: "#FFFFFF !important",
});

const StyledAutoComplete = styled(Autocomplete)({
    "& .MuiFilledInput-root": {
        padding: "13.5px 14px",
        paddingRight: "32px",
        "& input": {
            padding: 0
        }
    }
})

const StyledTextField = styled(TextField)({
    width: "100%",
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "& .MuiFilledInput-input": {
            padding: "7px 4px",
        },
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            "& button": {
                color: "#919EAB",
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&:after": {
            border: "none",
        },
        "&:before": {
            border: "none",
            borderBottomStyle: "none !important",
        }
    },
});

const StyledBox = styled(Box)({
    "&.open": {
        paddingLeft: 16,
        width: "calc(100% - 1000px)",
    },
    "&.close": {
        paddingLeft: 0,
        width: "100%",
    },
});

const StyledCellHeader = styled(TableCell)({
    borderBottom: "1px dashed rgba(224, 224, 224, 1)",
    color: "#637381",
    backgroundColor: "#f4f6f8",
    whiteSpace: "nowrap",
    "& .MuiTypography-root": {
        color: "#637381",
        backgroundColor: "#f4f6f8",
        "&.weekend": {
            fontStyle: "oblique",
        },
        "&.workday": {
            fontWeight: 600,
        },
    },
    "&.sticky": {
        padding: 0,
        position: "sticky",
        left: 0,
        zIndex: 4,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        boxShadow: "20px 0px 20px #EEEEEE",
        "& .MuiTableCell-root": {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
        },
    },
    "&:last-of-type": {
        paddingRight: 24,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
});

const StyledRowContent = styled(TableRow)({
    cursor: "pointer",
    textDecoration: "none",
    height: "57px",
    "&.MuiTableRow-hover:hover": {
        backgroundColor: "#f7f7f7",
        "& .sticky": {
            backgroundColor: "#f7f7f7",
        },
    },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
    borderBottom: "1px dashed rgba(224, 224, 224, 1)",
    padding: 8,
    whiteSpace: "nowrap",
    "&.sticky": {
        position: "sticky",
        left: 0,
        backgroundColor: "#FFFFFF",
        boxShadow: "8px 20px 20px #EEEEEE",
    },
    "& .avatar": {
        minWidth: 240,
        "& .MuiAvatar-root": {
            marginRight: 8,
        },
        display: "flex",
        alignItems: "center",
    },
    "& .fullname": {
        fontWeight: 600,
    },
    "& .position": {
        color: theme.palette.text.secondary,
        fontSize: 14,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: 300,
    },
    "& .secondaryAction": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .location": {
            fontWeight: 600,
        },
    },
    "&:first-of-type": {
        paddingLeft: 0,
    },
    "&:last-of-type": {
        paddingRight: 24,
    },
}));

const SummaryTalent = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const { year } = location.state;
    const [isSaving, setIsSaving] = useState(false);
    const [talentList, setTalentList] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [dataToUpdate, setDataToUpdate] = useState([]);
    const [summaryTalent, setSummaryTalent] = useState([]);
    const [selectedTalent, setSelectedTalent] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [sortedTalentList, setSortedTalentList] = useState(null);
    const [newFormattedSumTalent, setNewFormattedSumTalent] = useState([]);
    const [selectedYear, setSelectedYear] = useState(dayjs(year).format("YYYY"));

    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // const [searchText, setSearchText] = useState("");

    const columns = [
        { id: "JobPosition", label: t("Position"), minWidth: 170 },
        { id: "Company", label: t("Company"), minWidth: 170 },
        { id: "Division", label: t("Division"), minWidth: 170 },
        { id: "Department", label: t("Department"), minWidth: 170 },
        { id: "Section", label: t("Section"), minWidth: 100 },
        { id: "Performance", label: t("Performance"), minWidth: 200 },
        { id: "Potential", label: t("Potential"), minWidth: 200 },
        { id: "Talent", label: t("Talent"), minWidth: 300 },
        { id: "Development", label: t("Development"), minWidth: 300 },
    ];

    const [selectedItemToUpdate, setSelectedItemToUpdate] = useState(null);

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        dispatch(getAffiliateOrganization());
    }, []);

    useEffect(() => {
        if (userProfile && affiliateOrganizationList.length > 0) {
            setSelectedCompany(
                affiliateOrganizationList.find(
                    (x) => x.idCompany === userProfile.idCompany
                ) || null
            );
        }
    }, [userProfile, affiliateOrganizationList]);

    const fetchedTalentData = async () => {
        try {
            setIsFetching(true);
            const response = await getTalentList();
            if (response && response.data) {
                setTalentList(response.data);
            };
        } catch (error) {
            console.error(error);
        } finally {
            setIsFetching(false);
        };
    };

    useEffect(() => {
        fetchedTalentData();
    }, []);

    const fetchedSummaryTalentData = async () => {
        try {
            setIsFetching(true);
            const response = await getAllEmployeeTalentByCompanyAndTalent({
                idTalent: selectedTalent && selectedTalent.idTalent,
                idCompany: selectedCompany && selectedCompany.idCompany,
                year: selectedYear
            });
            if (response && response.data) {
                setSummaryTalent(response.data);
            };
        } catch (error) {
            console.error(error);
        } finally {
            setIsFetching(false);
        };
    };

    useEffect(() => {
        if (selectedYear && selectedCompany && (selectedTalent || !selectedTalent)) {
            fetchedSummaryTalentData();
        };
    }, [selectedYear, selectedCompany, selectedTalent]);

    const extractTalentInfo = (talentName) => {
        const match = talentName.match(/^([A-Z])(\d+)$/);
        if (match) {
            return { category: match[1], number: parseInt(match[2]) };
        };
        return { category: "", number: NaN };
    };

    useEffect(() => {
        if (talentList && talentList.length > 0) {
            const sortedList = talentList && talentList.slice().sort((a, b) => {
                const infoA = extractTalentInfo(a.talentName);
                const infoB = extractTalentInfo(b.talentName);
                if (infoA.category !== infoB.category) {
                    return infoA.category.localeCompare(infoB.category);
                }
                return infoB.number - infoA.number;
            });

            setSortedTalentList(sortedList);
        };
    }, [talentList]);

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    };

    const formattedSumTalent = (data) => {
        const newResult = data.map((item) => {
            const findPerformance = item && item.performance && item.performance.length > 0 ?
                item.performance.find(item => item.yearPerformance === parseInt(selectedYear)) : null;

            const findPotential = item && item.potential && item.potential.length > 0 ?
                item.potential.find(item => item.year === parseInt(selectedYear)) : null;

            return {
                ...item,
                idTalent: item ? item.idTalent : null,
                talentName: item ? item.talentName : null,
                performance: findPerformance ? findPerformance : null,
                potential: findPotential ? findPotential : null,
                idEmployeeTalent: item ? item.idEmployeeTalent : null
            };
        });

        const filteredNewResult = newResult.filter((row) =>
            selectedTalent ?
                (row.idTalent === (selectedTalent && selectedTalent.idTalent)) :
                true
        );

        setNewFormattedSumTalent(filteredNewResult);
    };

    useEffect(() => {
        if ((summaryTalent && summaryTalent.length > 0) && (selectedTalent || !selectedTalent)) {
            formattedSumTalent(summaryTalent);
        };
    }, [summaryTalent, selectedTalent]);

    const handleUpdateTalentChange = (row) => {
        if (!row) {
            // ลบออกจาก dataToUpdate ในกรณีที่ไม่มีการเลือกค่า
            const updatedData = dataToUpdate.filter((item) => item.idEmployees !== selectedItemToUpdate.idEmployees);
            setDataToUpdate(updatedData);

            // Update the newFormattedSumTalent to remove the corresponding talent data
            setNewFormattedSumTalent((prev) => prev.map((item) => {
                if (item.idEmployees === selectedItemToUpdate.idEmployees) {
                    return {
                        ...item,
                        idTalent: null,  // Remove talent data when cleared
                        talentName: null,
                        idEmployeeTalent: null,
                    };
                } else {
                    return item;
                }
            }));
            return;
        };

        // If a value is selected, update the data
        let updatedData = [...dataToUpdate];
        const existingUpdateIndex = updatedData.findIndex(
            (item) => item.idEmployees === selectedItemToUpdate.idEmployees
        );

        if (existingUpdateIndex === -1) {
            // If no entry found for this employee, push a new entry
            updatedData.push({
                idEmployees: selectedItemToUpdate.idEmployees,
                idTalent: row.idTalent,
                talentName: row.talentName,
                idEmployeeTalent: selectedItemToUpdate.idEmployeeTalent || null,
                year: selectedYear
            });
        } else {
            // If an entry already exists for this employee, update it
            updatedData[existingUpdateIndex] = {
                ...updatedData[existingUpdateIndex],
                idTalent: row.idTalent,
                talentName: row.talentName,
                idEmployeeTalent: selectedItemToUpdate.idEmployeeTalent,
                year: selectedYear
            };
        };

        setDataToUpdate(updatedData);

        setNewFormattedSumTalent((prev) => prev.map((item) => {
            if (item.idEmployees === selectedItemToUpdate.idEmployees) {
                return {
                    ...item,
                    idTalent: row.idTalent,
                    talentName: row.talentName,
                    idEmployeeTalent: selectedItemToUpdate.idEmployeeTalent,
                };
            } else {
                return item;
            };
        }));
    };

    const handleSaveTalent = async () => {
        setIsSaving(true);

        try {
            const promises = dataToUpdate.map(async (item) => {
                if (item.idEmployeeTalent) {
                    return updateEmployeeTalent(item);
                } else {
                    return addEmployeeTalent(item);
                };
            });

            await Promise.all(promises);
            handleChangeAlertType("success");
            handleOpenAlert(true);
            setSelectedItemToUpdate(null);
            const response = await fetchedSummaryTalentData();
            if (response && response.data && response.data.length > 0) {
                formattedSumTalent(response.data);
            };
        } catch (error) {
            handleChangeAlertType(`${t("Unsuccessful")}`);
            handleOpenAlert(true);
            console.error("Error saving talent data:", error);
        } finally {
            setIsSaving(false);
        };
    };

    const gotoDevelopPlan = (item) => {
        history.push({
            pathname: "/admin/talent/development-plan",
            state: {
                employee: item,
                year: selectedYear
            }
        });
    };

    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={"/home"}
                    >
                        {t("AppMenu.Home")}
                    </Link>
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={"/admin/talent"}
                    >
                        Talent
                    </Link>
                    <Typography color="text.primary">
                        ข้อมูลสรุป Talent
                    </Typography>
                </Breadcrumbs>

                <div style={{ marginBottom: 8, marginTop: 10 }}>
                    <Typography variant="h4">
                        ข้อมูลสรุป Talent
                    </Typography>
                </div>

                <Box sx={{ mt: 2, mb: 4 }}>
                    <Grid container spacing={2} alignItems={"center"}>
                        <Grid item xs={4}>
                            <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                                {t("Company")}
                            </Typography>
                            <SelectAffiliateCompany
                                options={affiliateOrganizationList}
                                value={selectedCompany}
                                disabled={isFetching || isSaving}
                                onChange={(_, value) => {
                                    onChangeCompany(value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                                {t("SelectYear")}
                            </Typography>
                            <div className="search-date">
                                <DatePickerCustom
                                    inputFormat="YYYY"
                                    views={['year']}
                                    value={selectedYear}
                                    name="start"
                                    disabled={isFetching || isSaving}
                                    onChange={(newValue) => {
                                        setSelectedYear(dayjs(newValue).format("YYYY"));
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <div className="search-date">
                                <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                                    {"Grade"}
                                </Typography>
                                <StyledAutoComplete
                                    options={sortedTalentList ? sortedTalentList : []}
                                    getOptionLabel={(option) => {
                                        return option.talentName;
                                    }}
                                    disabled={isFetching || isSaving}
                                    renderInput={(params) => (
                                        <StyledTextField
                                            {...params}
                                            variant="filled"
                                            placeholder={"เลือก Grade"}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: params.InputProps.endAdornment,
                                            }}
                                        />
                                    )}
                                    value={selectedTalent}
                                    onChange={(_, value) => {
                                        setSelectedTalent(value);
                                    }}
                                    noOptionsText={t("NoData")}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ mt: 3.5 }}>
                                <ButtonBlue
                                    sx={{ mt: 3.5 }}
                                    variant="contained"
                                    onClick={() => history.push({
                                        pathname: "/talent/summary-development-plan",
                                        state: {
                                            year: selectedYear
                                        }
                                    })}
                                    disabled={isFetching || isSaving}
                                >
                                    แผนพัฒนา
                                </ButtonBlue>
                                <ButtonBlue
                                    sx={{ mt: 3.5 }}
                                    variant="contained"
                                    onClick={handleSaveTalent}
                                    disabled={isFetching || isSaving || (dataToUpdate && dataToUpdate.length === 0)}
                                >
                                    {t("Save")}
                                </ButtonBlue>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>

                <Box style={{ display: "flex", position: "relative" }}>
                    <StyledBox className={"close"}>
                        <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <StyledCellHeader
                                            align={"center"}
                                            className="sticky"
                                            style={{ minWidth: 240 }}
                                        >
                                            {t("FullName")}
                                        </StyledCellHeader>

                                        {columns.map((column) => (
                                            <StyledCellHeader key={column.id}>
                                                {column.label}
                                            </StyledCellHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isFetching === true ? (
                                        <StyledRowContent>
                                            <StyledCellContent
                                                colSpan={5}
                                                style={{ height: 150 }}
                                            >
                                                <Typography align="center">
                                                    {t("LoadingEmpData")}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img alt="loading" src={loading} style={{ width: 100, height: 100 }} />
                                                </Box>
                                            </StyledCellContent>
                                        </StyledRowContent>
                                    ) : (
                                        <Fragment>
                                            {newFormattedSumTalent && newFormattedSumTalent.length > 0 ? (
                                                newFormattedSumTalent
                                                    .slice(
                                                        page * rowsPerPage,
                                                        page * rowsPerPage + rowsPerPage
                                                    )
                                                    .map((row, index) =>
                                                        <StyledRowContent hover key={index}>
                                                            <StyledCellContent
                                                                className="sticky"
                                                                style={{
                                                                    borderBottom:
                                                                        "1px dashed rgba(224, 224, 224, 1)",
                                                                }}
                                                            >
                                                                <div className="avatar">
                                                                    <Avatar alt={row.employeeID} src={`${row.imageProfile}`} />
                                                                    <div style={{ paddingLeft: "8px" }}>
                                                                        <Typography className="fullname">
                                                                            {getUserFullName(row)
                                                                                ? getUserFullName(row)
                                                                                : row.firstname_TH +
                                                                                " " +
                                                                                row.lastname_TH}
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </StyledCellContent>

                                                            <StyledCellContent>
                                                                <Typography>
                                                                    {getUserPosition(row) ? getUserPosition(row) : "-"}
                                                                </Typography>
                                                            </StyledCellContent>

                                                            <StyledCellContent>
                                                                <Typography>
                                                                    {getUserCompany(row) ? getUserCompany(row) : "-"}
                                                                </Typography>
                                                            </StyledCellContent>

                                                            <StyledCellContent>
                                                                <Typography>
                                                                    {getUserDivision(row) ? getUserDivision(row) : "-"}
                                                                </Typography>
                                                            </StyledCellContent>

                                                            <StyledCellContent>
                                                                <Typography>
                                                                    {getUserDepartment(row) ? getUserDepartment(row) : "-"}
                                                                </Typography>
                                                            </StyledCellContent>

                                                            <StyledCellContent>
                                                                <Typography>
                                                                    {getUserSection(row) ? getUserSection(row) : "-"}
                                                                </Typography>
                                                            </StyledCellContent>

                                                            <StyledCellContent>
                                                                <Typography textAlign={"center"}>
                                                                    {row.performance ? `${row.performance.name} (${row.performance.score})` : "-"}
                                                                </Typography>
                                                            </StyledCellContent>

                                                            <StyledCellContent>
                                                                <Typography textAlign={"center"}>
                                                                    {row.potential ? `${row.potential.Level} (${row.potential.score})` : "-"}
                                                                </Typography>
                                                            </StyledCellContent>

                                                            <StyledCellContent>
                                                                {selectedItemToUpdate && selectedItemToUpdate.idEmployees === row.idEmployees ? (
                                                                    <Autocomplete
                                                                        options={talentList ? talentList : []}
                                                                        getOptionLabel={(option) => {
                                                                            return option.talentName;
                                                                        }}
                                                                        disabled={isFetching}
                                                                        renderInput={(params) => (
                                                                            <StyledTextField
                                                                                {...params}
                                                                                variant="filled"
                                                                                placeholder={"เลือก Grade"}
                                                                                InputProps={{
                                                                                    ...params.InputProps,
                                                                                    endAdornment: params.InputProps.endAdornment,
                                                                                }}
                                                                            />
                                                                        )}
                                                                        value={(selectedItemToUpdate && selectedItemToUpdate.talent)}
                                                                        onChange={(_, value) => {
                                                                            handleUpdateTalentChange(value);
                                                                        }}
                                                                        noOptionsText={t("NoData")}
                                                                    />
                                                                ) : (
                                                                    <Stack direction={"row"} spacing={1} justifyContent={"center"} alignItems={"center"}>
                                                                        <Typography>
                                                                            {row.talentName ? `${row.talentName}` : "-"}
                                                                        </Typography>
                                                                        <IconButton onClick={() => setSelectedItemToUpdate({
                                                                            idEmployees: row.idEmployees,
                                                                            idTalent: row.idTalent ? row.idTalent : null,
                                                                            talentName: row.talentName ? row.talentName : null,
                                                                            idEmployeeTalent: row.idEmployeeTalent ? row.idEmployeeTalent : null
                                                                        })}
                                                                        >
                                                                            <EditIcon sx={{ width: 16, height: 16 }} />
                                                                        </IconButton>
                                                                    </Stack>
                                                                )}
                                                            </StyledCellContent>

                                                            <StyledCellContent>
                                                                <ButtonBlue
                                                                    disabled={!row.idEmployeeTalent}
                                                                    variant={"outlined"}
                                                                    onClick={() => gotoDevelopPlan(row)}
                                                                >
                                                                    {`${t("Development")}`}
                                                                </ButtonBlue>
                                                            </StyledCellContent>
                                                        </StyledRowContent>
                                                    )
                                            ) : (
                                                <StyledRowContent>
                                                    <StyledCellContent
                                                        colSpan={5}
                                                        style={{ height: 150 }}
                                                    >
                                                        <Typography align="center">
                                                            {t("NoEmployee")}
                                                        </Typography>
                                                    </StyledCellContent>
                                                </StyledRowContent>
                                            )}
                                        </Fragment>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={newFormattedSumTalent ? newFormattedSumTalent.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={`${t("RowsPerPage")}`}
                            labelDisplayedRows={({ from, to, count }) =>
                                `${from}-${to} ${t("OutOf")} ${count !== -1 ? count : `${t("MoreThan")} ${to}`
                                }`
                            }
                        />
                    </StyledBox>
                </Box>
                <AlertResponse
                    open={openAlert}
                    handleClose={handleCloseAlert}
                    alertType={alertType}
                />
            </Container>
        </StyledRoot >
    );
};

export default SummaryTalent;