export const personalityDISCTypes = (t) => [
    {
        id: "D",
        title: t("Dominance"),
        imagePath: `${process.env.PUBLIC_URL}/assets/background/DISC1/Dominance/Dominance.svg`,
        content: {
            shortText: t("bullTypePeopleLikeChallenges"),
            longText:
                t("bullLongText"),
            Advantages:
                t("bullAdvantage"),
            Disadvantages:
                t("bullDisadvantage"),
            prominentPoints: [
                t("quickDecisionMaking"),
                t("hotTempered"),
                t("highConfidence"),
                t("decisive"),
                t("dislikeComplexTasks"),
            ],
        },
    },
    {
        id: "S",
        title: t("Steadiness"),
        imagePath: `${process.env.PUBLIC_URL}/assets/background/DISC1/Steadiness/Steadiness.svg`,
        content: {
            shortText: t("calmAndConsistentPerson"),
            longText:
                t("calmAndConsistentLong"),
            Advantages:
                t("calmAndConsistentAdvantage"),
            Disadvantages:
                t("calmAndConsistentDisadvantage"),
            prominentPoints: [
                t("steadyInComposure"),
                t("speaksOnlyWhenNecessary"),
                t("goodListener"),
                t("insightfulLovesStepByStepWork"),
                t("dislikesBeingForcedOrHastyDecisions"),
            ],
        },
    },
    {
        id: "C",
        title: t("Conscientiousness"),
        imagePath: `${process.env.PUBLIC_URL}/assets/background/DISC1/Conscientiousness/Conscientiousness.svg`,
        content: {
            shortText:
                t("principledBearType"),
            longText:
                t("bearLong"),
            Advantages:
                t("bearAdvantages"),
            Disadvantages:
                t("workStyleStressful"),
            prominentPoints: [
                t("everyTaskMustBePerfect"),
                t("highlyOrganized"),
                t("dislikesSocializingOrSpotlight"),
                t("focusesOnAccuracyAndDetails"),
                t("dislikesAnyRisk"),
            ],
        },
    },
    {
        id: "I",
        title: t("Influence"),
        imagePath: `${process.env.PUBLIC_URL}/assets/background/DISC1/Influence/Influence.svg`,
        content: {
            shortText:
                t("highlyEnergeticType"),
            longText:
                t("highlyEnergeticTypeLong"),
            Advantages:
                t("highlyEnergeticTypeAdvantage"),
            Disadvantages: t("easilyBored"),
            prominentPoints: [
                t("enthusiasticAndEnergetic"),
                t("neverRefusesSocializing"),
                t("optimisticLikeAProphet"),
                t("friendlyWithEveryone"),
                t("dislikesConflict"),
            ],
        },
    },
];