import React, { useEffect, useState } from "react";
import {
  Grid,
  InputAdornment,
  Container,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Box,
  FormHelperText,
  styled,
  Autocomplete,
  Typography,
  TextField,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import Crop32Icon from "@mui/icons-material/Crop32";
import { useHistory, useLocation } from "react-router-dom";
import DialogDropzone from "../DialogDropzoneKpi";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AlertResponse from "../../../shared/general/AlertResponse";
import DatePickerCustom from "../../../shared/date/datePicker";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { addKpiTeam, updateKpiTeam } from "../../../../../actions/kpi";
import { Redirect } from "react-router-dom";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { getAffiliateOrganization } from "../../../../../actions/company";
import { getDepartment } from "../../../../../actions/department";

const ContainerStyled = styled(Container)({
  minHeight: "100vh",
  height: "100%",
  paddingTop: "100px",
});

const initialValues = {
  selectCompany: "",
  division: null,
  department: null,
  section: null,
  kpiName: "",
  scope: "",
  target: "",
  measureType: "",
  evidence: "",
  description: "",
  weight: "",
  startDate: dayjs().startOf("year"),
  endDate: dayjs().endOf("year"),
  year: dayjs().get("year"),
};

const validationSchema = yup.object({
  kpiName: yup.string().max(500).required(),
  scope: yup.string().max(500).required(),
  target: yup.string().max(500).required(),
  evidence: yup.string().max(500).required(),
  description: yup.string().max(500).required(),
  measureType: yup.string().required(),
  weight: yup.number().required(),
  startDate: yup.date().required(),
  endDate: yup.date().when("startDate", (startDate) => {
    return yup.date().min(dayjs(startDate).add(1, "day"));
  }),
  year: yup.number().required(),
});

function KpiTeamPlan() {
  const { t, i18n } = useTranslation();
  const { state } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );

  if (!state) {
    return <Redirect to="/kpi/team/admin" />
  }

  const { control, handleSubmit, watch,  setValue, formState: { isSubmitting, errors } } = useForm({
    defaultValues: state.planList || initialValues,
    resolver: yupResolver(validationSchema),
  });

  const handleOnSubmit = async (formData) => {
    let res = null;
    if (state.planList) {
      res = await dispatch(updateKpiTeam(formData));
    } else {
      res = await dispatch(addKpiTeam(formData));
    }
    
    if (res && (res.status === 201 || res.status === 200)) { 
      Swal.fire({
        title: t("Success"),
        text: state.planList ? t("Data_edit_successful") : t("Data_added_successfully"),
        icon: 'success',
        timer: 2000,
        showConfirmButton: false
      });
    } else {
      Swal.fire({
        title: t("AnErrorOccurred"),
        text:  t("DataSaveUnsuccessful"),
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      });
    }
    history.push("/kpi/team/admin");
  };

  const showAlert = async (formData) => {
    formData.idDivision = formData.division ? formData.division.idDivision : null;
    formData.idDepartment = formData.department ? formData.department.idDepartment : null;
    formData.idSection = formData.section ? formData.section.idSection : null;
    
    if (
      formData.idDivision === null &&
      formData.idDepartment === null &&
      formData.idSection === null
    ) {
      Swal.fire({
        title: t("AnErrorOccurred"),
        text: t("PleaseSelectData"),
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }

    const result = await Swal.fire({
      title: t("ConfirmSaveData"),
      text: t("DoYouWantToSaveThisData"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirm"),
      cancelButtonText: t("Cancel"),
    });

    if (result.isConfirmed) {
      handleOnSubmit(formData);
    }
  };

  const [openDialog, setDialog] = useState(false);

  const handleCloseDialogDropzoneKpi = () => {
    setDialog(false);
  };

  const [openAlert, setOpenAlert] = useState({
    open: false,
    alertType: null,
    label: null,
  });

  const handleOpenAlert = (alertType, label) => {
    setOpenAlert({
      open: true,
      alertType: alertType,
      label: label,
    });
  };

  const handleCloseAlert = () => {
    setOpenAlert({
      open: false,
      alertType: null,
      label: null,
    });
  };

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, [dispatch]);
  
  useEffect(() => {
    dispatch(getDepartment());
  }, [dispatch]);

  const selectCompany = watch("company")

  return (
    <ContainerStyled>
      {userProfile && (
        <Box paddingBottom="16px" marginBottom="16px" borderRadius="15px" bgcolor="#ffffff">
          <div className="header-section">
            <h3 className="header-topic">{t("KpiTeamPlanning")}</h3>
          </div>
          <Box paddingLeft={"16px"} paddingRight={"16px"}>
            <form onSubmit={handleSubmit(showAlert)}>
              <Grid container spacing={2}>
                {affiliateOrganizationList && (
                  <>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="company"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Autocomplete
                            {...field}
                            options={affiliateOrganizationList}
                            isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                            getOptionLabel={(option) => option.companyName}
                            renderOption={(props, option) => (
                              <MenuItem {...props} key={option.idCompany}>
                                <Typography>{option.companyName}</Typography>
                              </MenuItem>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("Company")}
                                onBlur={field.onBlur}
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error && fieldState.error.message}
                              />
                            )}
                            onChange={(_, value) => field.onChange(value)}
                            disableClearable
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="division"
                        control={control}
                        render={({ field, fieldState }) => (
                        <Autocomplete
                          {...field}
                          options={
                            selectCompany
                              ? affiliateOrganizationList.find(
                                  (company) =>
                                    company.idCompany ===
                                    selectCompany.idCompany
                                ).division
                              : []
                          }
                          isOptionEqualToValue={(option, value) => option.idDivision === value.idDivision}
                          getOptionLabel={(option) => option.divisionName}
                          renderOption={(props, option) => (
                            <MenuItem {...props} key={option.idDivision}>
                              <Typography>{option.divisionName}</Typography>
                            </MenuItem>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("Division")}
                              onBlur={field.onBlur}
                              error={Boolean(fieldState.error)}
                              helperText={fieldState.error && fieldState.error.message}
                            />
                          )}
                          onChange={(_, value) => {
                            setValue("department", null)
                            setValue("section", null)
                            field.onChange(value)
                          }}
                          disableClearable
                        />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="department"
                        control={control}
                        render={({ field, fieldState }) => (
                        <Autocomplete
                          {...field}
                          options={
                            selectCompany
                              ? affiliateOrganizationList.find(
                                  (company) =>
                                    company.idCompany ===
                                    selectCompany.idCompany
                                ).department
                              : []
                          }
                          isOptionEqualToValue={(option, value) => option.idDepartment === value.idDepartment}
                          getOptionLabel={(option) => option.departmentName}
                          renderOption={(props, option) => (
                            <MenuItem {...props} key={option.idDepartment}>
                              <Typography>{option.departmentName}</Typography>
                            </MenuItem>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("Department")}
                              onBlur={field.onBlur}
                              error={Boolean(fieldState.error)}
                              helperText={fieldState.error && fieldState.error.message}
                            />
                          )}
                          onChange={(_, value) => {
                            setValue("division", null)
                            setValue("section", null)
                            field.onChange(value)
                          }}
                          disableClearable
                        />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="section"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Autocomplete
                            {...field}
                            options={
                              selectCompany
                                ? affiliateOrganizationList.find(
                                    (company) =>
                                      company.idCompany ===
                                      selectCompany.idCompany
                                  ).section
                                : []
                            }
                            isOptionEqualToValue={(option, value) => option.idSection === value.idSection}
                            getOptionLabel={(option) => option.sectionName}
                            renderOption={(props, option) => (
                              <MenuItem {...props} key={option.idSection}>
                                <Typography>{option.sectionName}</Typography>
                              </MenuItem>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("Section")}
                                onBlur={field.onBlur}
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error && fieldState.error.message}
                              />
                            )}
                            onChange={(_, value) => {
                              setValue("division", null)
                              setValue("department", null)
                              field.onChange(value)
                            }}
                            disableClearable
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Controller
                    name="kpiName"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextFieldTheme
                        {...field}
                        fullWidth
                        label={t("Name") + "KPI"}
                        placeholder={t("Name") + "KPI"}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error && fieldState.error.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <StarIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="scope"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextFieldTheme
                        {...field}
                        fullWidth
                        label={t("Scope")}
                        placeholder={t("Scope")}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error && fieldState.error.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Crop32Icon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="target"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextFieldTheme
                        {...field}
                        fullWidth
                        label={t("Target")}
                        placeholder={t("Target")}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error && fieldState.error.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Crop32Icon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="evidence"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextFieldTheme
                        {...field}
                        fullWidth
                        label={t("Evidence")}
                        placeholder={t("Evidence")}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error && fieldState.error.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Crop32Icon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextFieldTheme
                        {...field}
                        fullWidth
                        label={t("Descriptions")}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error && fieldState.error.message}
                        multiline
                        rows={3}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Controller
                    name="measureType"
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth error={Boolean(fieldState.error)}>
                        <InputLabel>{t("TypeMeasurementData")}</InputLabel>
                        <Select {...field} label={t("TypeMeasurementData")}>
                          <MenuItem value={"number"}>{t("Number")}</MenuItem>
                          <MenuItem value={"explanation"}>{t("Explanation")}</MenuItem>
                        </Select>
                        {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Controller
                    name="weight"
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth error={Boolean(fieldState.error)}>
                        <InputLabel>{t("Weight")}</InputLabel>
                        <Select
                          {...field}
                          label={t("Weight")}
                        >
                          {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50]
                            // .filter((value) => value <= 100 - state.totalWeight)
                            .map((value) => (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                        </Select>
                        {fieldState.error && (
                          <FormHelperText>{fieldState.error.message}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                      <DatePickerCustom
                        {...field}
                        variant="outlined"
                        label={t("StartDate")}
                        minDate={dayjs().startOf("year")}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => (
                      <DatePickerCustom
                        {...field}
                        variant="outlined"
                        label={t("EndDate")}
                        minDate={dayjs(watch("startDate"))}
                        maxDate={dayjs().endOf("year")}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Controller
                    name="year"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextFieldTheme
                        {...field}
                        select
                        label={t("Year")}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error && fieldState.error.message}
                      >
                        <MenuItem value={dayjs().get("year")}>
                          {dayjs().format(i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY")}
                        </MenuItem>
                        <MenuItem value={dayjs().get("year") - 1}>
                          {dayjs().subtract(1, "year").format(i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY")}
                        </MenuItem>
                      </TextFieldTheme>
                    )}
                  />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="flex-end" gap="8px">
                  {state.planList && (
                    <ButtonBlue type="button" color="error" onClick={() => history.push("/kpi/team/admin")}>
                      {t("Cancel")}
                    </ButtonBlue>
                  )}
                  <ButtonBlue type="submit" variant="contained" disabled={isSubmitting}>
                    {t("Save")}
                  </ButtonBlue>
                </Grid>
              </Grid>
            </form>
            {openDialog && (
              <DialogDropzone
                open={openDialog}
                onClose={handleCloseDialogDropzoneKpi}
                handleOpenAlert={handleOpenAlert}
              />
            )}
            <AlertResponse
              open={openAlert.open}
              handleClose={handleCloseAlert}
              alertType={openAlert.alertType}
              label={openAlert.label}
            />
          </Box>
        </Box>
      )}
    </ContainerStyled>
  );
}

export default KpiTeamPlan;
