import dayjs from 'dayjs';
import ExcelJS from 'exceljs';

export const PND1XlsxFile = (selectYear, selectedMonth, employeeDetails, contributionRate) => {
    const months = [
        { value: "01", name: "มกราคม" },
        { value: "02", name: "กุมภาพันธ์" },
        { value: "03", name: "มีนาคม" },
        { value: "04", name: "เมษายน" },
        { value: "05", name: "พฤษภาคม" },
        { value: "06", name: "มิถุนายน" },
        { value: "07", name: "กรกฎาคม" },
        { value: "08", name: "สิงหาคม" },
        { value: "09", name: "กันยายน" },
        { value: "10", name: "ตุลาคม" },
        { value: "11", name: "พฤศจิกายน" },
        { value: "12", name: "ธันวาคม" },
    ];

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");


    const selectedMonthObject = months.find(month => month.value == selectedMonth);
    const selectedMonthName = selectedMonthObject ? selectedMonthObject.name : "Unknown Month";

    const headerRow = worksheet.addRow([
        "ลำดับที่",
        "เลขบัตรประชาชน",
        "คำนำหน้า",
        "ชื่อ",
        "สกุล",
        "เงินได้ตามมาตรา",
        "วันเดือนปีที่จ่าย",
        "รวมรายได้",
        "ภาษีหัก ณ ที่จ่าย",
        "เงื่อนไข",
    ]);

    headerRow.height = 30;

    const headerStyle = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK' },
        alignment: { horizontal: "center", vertical: 'middle' },
        // fill: {
        //     type: 'pattern',
        //     pattern: 'solid',
        //     fgColor: { argb: 'DDDDDD' }
        // },
    };

    headerRow.eachCell((cell) => {
        cell.style = headerStyle;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    if (employeeDetails && employeeDetails.employeeDetails.length > 0) {
        for (let itemIndex = 0; itemIndex < employeeDetails.employeeDetails.length; itemIndex++) {
            const item = employeeDetails.employeeDetails[itemIndex];

            const row = [
                `${itemIndex + 1}`,
                item.personalID ? item.personalID : "",
                item.title_TH || "",
                item.firstname_TH || "",
                item.lastname_TH || "",
                "401N",
                item.payDate ? dayjs(item.payDate).format("DD/MM/") + selectYear : "-",
                item.valueActual.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                item.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                parseInt(1)
            ];
            worksheet.addRow(row);

            // Apply font size to the entire row
            const lastRow = worksheet.lastRow;
            lastRow.font = { size: 16, name: 'TH SarabunPSK' };
            lastRow.alignment = { horizontal: "center", vertical: 'middle' };

            lastRow.eachCell((cell) => {
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            });
        }

        const colWidths = [
            { key: "list", width: 30 },
            { key: "personalID", width: 30 },
            { key: "title", width: 30 },
            { key: "firstname", width: 30 },
            { key: "lastname", width: 30 },
            { key: "401N", width: 30 },
            { key: "payDate", width: 30 },
            { key: "total", width: 30 },
            { key: "tax", width: 30 },
            { key: "condition", width: 30 },
        ];

        colWidths.forEach((col, index) => worksheet.getColumn(index + 1).width = col.width);

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `ภงด-1.xlsx`;
            a.click();
            URL.revokeObjectURL(url);
        });
    } else {
        console.log("No data");
    }
};