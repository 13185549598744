import { Container, Grid, Box, Typography, styled, Breadcrumbs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import CardShowOverview from "./component/Dashboard/cardShowOverview";
// import SelectEmployees from "../../shared/general/selectEmployees";
import { useDispatch, useSelector } from "react-redux";
import { getOverviewAssessmentByIdQuestionSet } from "../../../../actions/potential";
import LoadingIcon from "../../assets/social-media.gif";
import { useTranslation } from "react-i18next";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from 'react-router-dom';

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: "5%",
  "& .form-radio-label-type": {
    ["@media only screen and (max-width: 600px)"]: {
      flexBasis: "25%",
      width: "fit-content",
      margin: 0,
    },
    "& .label-radio": {
      ["@media only screen and (max-width: 600px)"]: {
        fontSize: 14,
      },
    },
  },
});

const StyledHeadingPage = styled("div")({
  marginBottom: 16,
});
const DetailPotentialPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isFetching: appraiseesLoading, result: appraisees } = useSelector(
    (state) => state.potentialOverviewAppraiser
  );

  const {
    state: { potentialAssessment },
  } = useLocation();

  useEffect(() => {
    if (!potentialAssessment) {
      history.goBack();
    } else {
      dispatch(
        getOverviewAssessmentByIdQuestionSet(
          potentialAssessment.idPotentialAssessmentQuestionSet
        )
      );
    }
  }, [potentialAssessment]);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Box sx={{ mb: 2, mt: 2 }}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={"/potential-assessment"}
            >
              ประเมินศักยภาพ
            </Link>
            <Typography color="text.primary">ภาพรวมการประเมินศักยภาพ</Typography>
          </Breadcrumbs>
        </Box>
        <StyledHeadingPage>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            ภาพรวมการประเมินศักยภาพ
          </Typography>
        </StyledHeadingPage>
        <Grid container marginBottom={"20px"}>
          {/* <Grid item xs={4}>
            <SelectEmployees
              value={selectedEmployee}
              handleChange={(employee) => {
                setSelectedEmployee(employee);
              }}
            />
          </Grid> */}
        </Grid>
        <Grid container spacing={2}>
          {appraiseesLoading ? (
            <Grid item xs={12}>
              <Box
                sx={{
                  minHeight: 400,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={LoadingIcon} style={{ height: 80, width: 80 }} />
              </Box>
            </Grid>
          ) : appraisees && appraisees.length >= 1 ? (
            appraisees.map((e, index) => (
              <Grid key={index} item xs={12}>
                <CardShowOverview appraisee={e} />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Box
                style={{
                  minHeight: 400,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>{t("NoData")}</Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </StyledRoot>
  );
};

export default DetailPotentialPage;