import React, { useEffect, useState } from "react";
import { Box, Container, Grid, MenuItem, Tab, Tabs, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import EmployeeProject from "./Project";
import EmployeeReward from "./Reward";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../actions/company";
import { useDispatch, useSelector } from "react-redux";
import DatePickerCustom from "../../shared/date/datePicker";
import dayjs from "dayjs";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProjectAndReward = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeCompany = (event, newValue) => {
    setSelectedCompany(newValue);
  };

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if(affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      setSelectedCompany(affiliateOrganizationList[0])
    }
  }, [affiliateOrganizationList]);

  return (
    <div className={`page`}>
      <Container>
        <Typography className="label" color="text.third">
          {t("SelectCompany")}
        </Typography>
        <SelectAffiliateCompany
          options={affiliateOrganizationList}
          value={selectedCompany}
          onChange={handleChangeCompany}
        />
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
          >
            <Tab label={t("Project")} {...a11yProps(0)} />
            <Tab label={t("Reward")} {...a11yProps(1)} />
          </Tabs>
        </Box>
        {selectedCompany && (
          <>
            <CustomTabPanel value={value} index={0}>
              <EmployeeProject idCompany={selectedCompany.idCompany} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <EmployeeReward idCompany={selectedCompany.idCompany} />
            </CustomTabPanel>
          </>
        )}
      </Container>
    </div>
  );
};

export default ProjectAndReward;
