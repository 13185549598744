import React, { useEffect, useState } from "react";
import { Avatar, Box, styled, Typography, useMediaQuery } from "@mui/material";
import DoneAll from "@mui/icons-material/DoneAll";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import TableCustom from "../../../shared/tableCustom";
import DialogConfirmReject from "../DialogConfirmReject";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import {
  getUserFullName,
  getUserPosition,
} from "../../../../../utils/userData";
import {
  getManagerRewardApprove,
  updateAdminRewardApprove,
} from "../../../../../actions/project";

const StyledButtonCancel = styled(ButtonBlue)({
  backgroundColor: "#E793B8",
  borderRadius: "8px",
  color: "#FFFFFF",
  marginRight: 8,
  width: 130,
  "&:hover": {
    backgroundColor: "#DC6384",
  },
});

const RequestReward = ({
  searchDate,
  setSnackBarConfig,
  setNumberOfList,
  selectedCompany,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: employeeReward } = useSelector((state) => state.employeeReward);

  const [pendingRows, setPendingRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const mobileResponsive = useMediaQuery("(max-width:600px)");
  const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);

  const columns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>{getUserFullName(row)}</Typography>
            <Typography color="text.third" fontSize="14px">
              {getUserPosition(row)}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("RewardName")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Typography fontSize={14}>{row.rewardName}</Typography>
      ),
    },
    {
      name: `${t("Institution")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Typography fontSize={14}>{row.institution}</Typography>
      ),
    },
    {
      name: `${t("RewardType")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Typography fontSize={14}>{row.rewardType}</Typography>
      ),
    },
    {
      name: `${t("ReceivedDate")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Typography fontSize={14}>
          {dayjs(row.receivedDate).format(
            i18n.resolvedLanguage === "th" ? "DD MMM BBBB" : "DD MMM YYYY"
          )}
        </Typography>
      ),
    },
    {
      name: `${t("CreationDate")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Typography fontSize={14}>
          {dayjs(row.createDate).format(
            i18n.resolvedLanguage === "th" ? "DD MMM BBBB" : "DD MMM YYYY"
          )}
        </Typography>
      ),
    },
    {
      name: `${t("Details")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => <Typography fontSize={14}>{row.detail}</Typography>,
    },
  ];

  const handleConfirmReject = async (comment) => {
    try {
      const idEmployeeRewardList = selectedRows.map(
        (item) => item.idEmployeeReward
      );
      const response = await updateAdminRewardApprove({
        idEmployeeRewardList,
        isApproved: 0,
        note: comment,
      });

      if (response && response.status === 200) {
        setIsOpenRejectDialog(false);
        setSnackBarConfig({
          open: true,
          message: `${t("Successfully")}`,
          type: "success",
        });
        dispatch(
          getManagerRewardApprove({
            idCompany: selectedCompany.idCompany,
            start: searchDate.start
              ? dayjs(searchDate.start).format("YYYY-MM-DD")
              : null,
            end: searchDate.end
              ? dayjs(searchDate.end).format("YYYY-MM-DD")
              : null,
          })
        );
      } else {
        setSnackBarConfig({
          open: true,
          message: `${t("Unsuccessful")}`,
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
      setSnackBarConfig({
        open: true,
        message: `${t("Unsuccessful")}`,
        type: "error",
      });
    }
  };

  const handleApproveProject = async (status) => {
    try {
      const idEmployeeRewardList = selectedRows.map(
        (item) => item.idEmployeeReward
      );
      const response = await updateAdminRewardApprove({
        idEmployeeRewardList: idEmployeeRewardList,
        isApproved: status,
      });
      if (response && response.status === 200) {
        setSnackBarConfig({
          open: true,
          message: `${t("Successfully")}`,
          type: "success",
        });
        dispatch(
          getManagerRewardApprove({
            idCompany: selectedCompany.idCompany,
            start: searchDate.start
              ? dayjs(searchDate.start).format("YYYY-MM-DD")
              : null,
            end: searchDate.end
              ? dayjs(searchDate.end).format("YYYY-MM-DD")
              : null,
          })
        );
      } else {
        setSnackBarConfig({
          open: true,
          message: `${t("Unsuccessful")}`,
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
      setSnackBarConfig({
        open: true,
        message: `${t("Unsuccessful")}`,
        type: "error",
      });
    }
  };

  const handleClickAll = () => {
    setSelectedRows(pendingRows);
  };

  const handleCancel = () => {
    setSelectedRows([]);
  };
  
  useEffect(() => {
    if (employeeReward) {
      let pendingList = [...employeeReward];
      setPendingRows(pendingList);
      setNumberOfList(pendingList.length);
    }
  }, [employeeReward, searchDate]);

  return (
    <Box>
      {mobileResponsive && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          {(selectedRows ? selectedRows.length : 0) > 0 && (
            <StyledButtonCancel
              startIcon={<DeleteIcon />}
              onClick={handleCancel}
            >
              {`${t("Cancel")}`}
            </StyledButtonCancel>
          )}
          {!(
            (selectedRows ? selectedRows.length : 0) === pendingRows.length
          ) && (
            <ButtonBlue
              startIcon={<DoneAll />}
              variant="contained"
              onClick={handleClickAll}
            >
              {`${t("SelectAll")}`}
            </ButtonBlue>
          )}
        </Box>
      )}
      {selectedRows.length > 0 && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          <ButtonBlue
            style={{ marginRight: 8 }}
            startIcon={<DoneAll />}
            variant="contained"
            onClick={() => handleApproveProject(1)}
          >
            {`${t("Approved")} ${
              selectedRows.length > 0
                ? `${selectedRows.length} ${t("List")}`
                : ""
            }`}
          </ButtonBlue>
          <ButtonBlue
            startIcon={<DoneAll />}
            variant="outlined"
            onClick={() => setIsOpenRejectDialog(true)}
          >
            {`${t("NotApproved")} ${
              selectedRows.length > 0
                ? `${selectedRows.length} ${t("List")}`
                : ""
            }`}
          </ButtonBlue>
        </Box>
      )}

      <TableCustom
        columns={columns}
        rows={pendingRows || []}
        canSelect
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />

      <DialogConfirmReject
        rejectCount={selectedRows.length}
        open={isOpenRejectDialog}
        onClose={() => {
          setIsOpenRejectDialog(false);
        }}
        handleSubmit={(comment) => {
          handleConfirmReject(comment);
        }}
      />
    </Box>
  );
};

export default RequestReward;
