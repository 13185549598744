import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form"; 
import { styled } from "@mui/styles";
import { useLocation } from "react-router-dom";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import {
  Grid,
  Container,
  Typography,
  IconButton,
  Box,
  MenuItem,
  Stack,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  Collapse,
  Chip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getKpiOrganizationCascadeUpdatePlan, getKpiUpdateAndPlan } from "../../../../../actions/kpi";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import KPIPercent from "../assets/KPIPercent.png";
import KPIweight from "../assets/KPIweight.png";
import KPIappove from "../assets/KPIApprove.png";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import EditIcon from '@mui/icons-material/Edit';
import Dialogs from "../Dialog"; 
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ButtonBlue from '../../../shared/general/ButtonBlue';
import Swal from "sweetalert2";
import { t } from "i18next";
import { getUserFullName, getUserPosition } from "../../../../../utils/userData";

const ProgressBox = ({ progress, onEdit, isAdmin }) => {
  const {t} = useTranslation();
  const progressValue = progress !== null && progress !== undefined ? Number(progress) : 0.00;

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        padding: "16px",
        borderRadius: "16px",
        width: "30%",
        height: "60px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box flex={1}>
        <Typography variant="body2" sx={{ color: "#718EBF", fontWeight: 500 }}>
          {t("Progress")}
        </Typography>
        <Box display="flex" alignItems="center">
          <LinearProgress
            variant="determinate"
            value={progressValue}
            sx={{
              flexGrow: 1,
              height: "12px",
              borderRadius: "4px",
              marginRight: "8px",
              backgroundColor: "#FEEFAD",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#FFC107",
              },
            }}
          />
          <Typography
            variant="body2"
            sx={{ fontWeight: 700, fontSize: "20px", color: "#FFC107", marginLeft: "8px" }}
          >
            {progressValue.toFixed(2)}%
          </Typography>
        </Box>
      </Box>
      {isAdmin && (
        <IconButton onClick={() => onEdit('progress')} sx={{ marginTop: "16px", color: "#4535C1" }}>
          <EditIcon />
        </IconButton>
      )}
    </Box>
  );
};

const SuccessBox = ({ employeeEvaluate, employeeRating, onEdit, isAdmin }) => {
  const {t} = useTranslation();
  const starColor = employeeEvaluate === 1 ? "#50B498" : "#FF6969";

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        padding: "16px",
        borderRadius: "16px",
        width: "30%",
        height: "60px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography
          variant="body2"
          sx={{ fontWeight: 500, color: "#718EBF", textAlign: "left", marginLeft: "-24px" }}
        >
          {t("Employee")} {t("Evaluate")}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        {employeeEvaluate !== null ? (
          <>
            <Typography
              variant="body2"
              sx={{
                color: starColor,
                fontWeight: 700,
                fontSize: "20px",
                textAlign: "right",
              }}
            >
              {employeeEvaluate === 1 ? t("Success") : t("Failure")}
            </Typography>
            <Box display="flex" alignItems="center" mt={-0.5}>
              {[...Array(5)].map((_, i) =>
                i < employeeRating ? (
                  <StarIcon key={i} sx={{ color: starColor }} />
                ) : (
                  <StarBorderIcon key={i} sx={{ color: starColor }} />
                )
              )}
              <Typography
                variant="h6"
                sx={{ color: starColor, fontWeight: 700, marginLeft: "8px" }}
              >
                {employeeRating !== null ? employeeRating.toFixed(1) : "No Data"}
              </Typography>
            </Box>
          </>
        ) : (
          <Typography
            variant="body2"
            sx={{ color: "#000000", fontWeight: 500, fontSize: "16px" }}
          >
            {t("NoEvaluationYet")}
          </Typography>
        )}
      </Box>
      {isAdmin && (
        <IconButton onClick={() => onEdit('employee')} sx={{ marginTop: "16px", color: "#4535C1" }}>
          <EditIcon />
        </IconButton>
      )}
    </Box>
  );
};

const UnsuccessBox = ({ managerEvaluate, managerRating, onEdit, isAdmin }) => {
  const starColor = managerEvaluate === 1 ? "#50B498" : "#FF6969";

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        padding: "16px",
        borderRadius: "16px",
        width: "30%",
        height: "60px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography
          variant="body2"
          sx={{ fontWeight: 500, color: "#718EBF", textAlign: "left", marginLeft: "-24px" }}
        >
          {t("Manager")} {t("Evaluate")}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        {managerEvaluate !== null ? (
          <>
            <Typography
              variant="body2"
              sx={{
                color: starColor,
                fontWeight: 700,
                fontSize: "20px",
                textAlign: "right",
              }}
            >
              {managerEvaluate === 1 ? t("Success") : t("Failure")}
            </Typography>
            <Box display="flex" alignItems="center" mt={-0.5}>
              {[...Array(5)].map((_, i) =>
                i < managerRating ? (
                  <StarIcon key={i} sx={{ color: starColor }} />
                ) : (
                  <StarBorderIcon key={i} sx={{ color: starColor }} />
                )
              )}
              <Typography
                variant="h6"
                sx={{ color: starColor, fontWeight: 700, marginLeft: "8px" }}
              >
                {managerRating !== null ? managerRating.toFixed(1) : "No Data"}
              </Typography>
            </Box>
          </>
        ) : (
          <Typography
            variant="body2"
            sx={{ color: "#000000", fontWeight: 500, fontSize: "16px" }}
          >
            {t("NoEvaluationYet")}
          </Typography>
        )}
      </Box>
      {isAdmin && (
        <IconButton onClick={() => onEdit('manager')} sx={{ marginTop: "16px", color: "#4535C1" }}>
          <EditIcon />
        </IconButton>
      )}
    </Box>
  );
};

const monthNames = [
  t("January"), t("February"), t("March"), t("April"), t("May"), t("June"),
  t("July"), t("August"), t("September"), t("October"), t("November"), t("December")
];

const ContainerStyled = styled(Container)({
  height: "100%",
  paddingTop: "100px",
  backgroundColor: "transparent",
  minHeight: "100vh",
});

const GridStyled = styled(Grid)({
  backgroundColor: "#E6EFF5",
  padding: "16px",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "16px",
  height: "70px",
});

const StatusChip = styled(Chip)(({ status }) => {
  let backgroundColor;
  switch(status) {
    case 'approve':
      backgroundColor = '#50B498';
      break;
    case 'not approve':
      backgroundColor = '#FF6969';
      break;
    case 'waiting':
      backgroundColor = '#83B4FF';
      break;
    case 'unfinished':
      backgroundColor = '#FFDE4D';
      break;
    default:
      backgroundColor = '#CCCCCC';
      break;
  }
  return {
    backgroundColor,
    color: '#fff',
    borderRadius: '16px',
    minWidth: '100px',
    fontSize: '14px',
  };
});

const CircularProgressWithLabel = (props) => {
  return (
    <Box 
      position="relative" 
      display="inline-flex" 
      sx={{ 
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', 
        borderRadius: '16px', 
        width: '100px', 
        height: '100px', 
        backgroundColor: '#F4F6FA', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
      }}
    >
      <Typography 
        variant="caption" 
        component="div" 
        color="#4535C1"
        fontSize={20} 
        fontWeight="bold" 
        textAlign="center"
      >
        {t("Weight")}
        <br /> 
        {`${Math.round(props.value)}%`}
      </Typography>
    </Box>
  );
};
const TotalWeightProgress = styled(LinearProgress)({
  height: '10px',
  borderRadius: '5px',
  backgroundColor: '#DCFAF8',
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#16DBCC',
  },
});

const YearSelect = styled(TextFieldTheme)({
  width: "100px",
  height: "4 0px",
  '& .MuiOutlinedInput-root': {
    height: "100%",
    '& fieldset': {
      borderColor: '#4535C1',
    },
    '&:hover fieldset': {
      borderColor: '#4535C1',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#4535C1',
    },
  },
  '& .MuiSelect-select': {
    backgroundColor: 'white',
    borderRadius: '8px',
    height: "100%",
  },
});

const KpiCard = ({ index, kpi, expanded, handleExpandClick, handleEditClick , isAdmin }) => {
  const downloadFile = async (fileUrl) => {
    try {
      if (!fileUrl) {
        Swal.fire({
          title: t("AnErrorOccurred"),
          text: t("NoFileFoundForDownload"),
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
        });
        return;
      }
  
      const result = await Swal.fire({
        title: t("AreYouSure"),
        text: t("DoYouWantToDownloadThisFile"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Confirm"),
        cancelButtonText: t("Cancel"),
      });
  
      if (result.isConfirmed) {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileUrl.split('/').pop(); 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
        Swal.fire({
          title: t("Success"),
          text: t("YourFileHasBeenDownloaded"),
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      Swal.fire({
        title: t("AnErrorOccurred"),
        text: t("FailedToDownloadFile"),
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const status =
    kpi.isPlan === 1
      ? t("Approve")
      : kpi.isPlan === 0
      ? t("NotApproved")
      : kpi.isPlan === 2
      ? t("Waiting")
      : t("Unfinished");

  return (
<Card sx={{ marginBottom: '16px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '16px' }}>
  <CardContent>
    <Box display="flex" alignItems="center" flexDirection={{ xs: 'column', sm: 'row' }}>
      <Box mr={1} mt={1} mb={{ xs: 2, sm: 0 }} position={"relative"}>
        <CircularProgressWithLabel value={kpi.weight} />
      </Box>
      <Box flex={1} display="flex" flexDirection={{ xs: 'column', sm: 'row' }} ml={2} width="100%">
        <Box flex={2} mr={{ xs: 0, sm: 2 }} mb={{ xs: 2, sm: 0 }}>
          <Typography variant="body2">
            <span style={{ color: '#718EBF', fontWeight: 500 }}>{t("KPIName")}: </span>
            <Typography fontSize={16}>{kpi.kpiName}</Typography>
          </Typography>
          {kpi.isCascade && (
            <Box
              color={"#ffffff"}
              bgcolor={"#ff4e88"}
              borderRadius={"8px"}
              padding={"2px 4px"}
              fontSize={"14px"}
              width={"fit-content"}
            >
              {t("Cascade")}
            </Box>
          )}
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: 2 }} mb={{ xs: 2, sm: 0 }}>
          <Typography variant="body2">
            <span style={{ color: '#718EBF', fontWeight: 500 }}>{t("Date")}: </span>
            <Typography fontSize={16}>{dayjs(kpi.startDate).format("YYYY/MM/DD")} - {dayjs(kpi.endDate).format("YYYY/MM/DD")}</Typography>
          </Typography>
        </Box>
        {!kpi.isCascade && (
          <Box flex={0.9} mr={{ xs: 0, sm: 2 }} mb={{ xs: 2, sm: 0 }}>
            <Typography variant="body2">
              <span style={{ color: '#718EBF', fontWeight: 500 }}>{t("Assessor")}: </span>
              <Typography fontSize={16}>{kpi.asessor_firstname_TH} {kpi.asessor_lastname_TH}</Typography>
            </Typography>
          </Box>
        )}
        <Box flex={0.6}>
          <Typography variant="body2">
            <span style={{ color: '#718EBF', fontWeight: 500 }}>{t("PlanStatus")}: </span>
            <StatusChip label={status} status={status} />
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection={{ xs: 'row', sm: 'column' }} alignItems="flex-end" mt={{ xs: 2, sm: 0 }}>
        <IconButton
          onClick={() => handleExpandClick(index)}
          sx={{ color: '#4535C1' }}
        >
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
    </Box>
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <Box display="flex" justifyContent="space-between" flexDirection={{ xs: 'column', sm: 'row' }}>
      <ProgressBox progress={kpi.progress} onEdit={() => handleEditClick('progress')} isAdmin={isAdmin} />
      {!kpi.isCascade && (
        <>
          <SuccessBox employeeEvaluate={kpi.employeeEvaluate} employeeRating={kpi.employeeRaitng} onEdit={() => handleEditClick('employee')} isAdmin={isAdmin} />
          <UnsuccessBox managerEvaluate={kpi.managerEvaluate} managerRating={kpi.managerRating} onEdit={() => handleEditClick('manager')} isAdmin={isAdmin} />
        </>
      )}
      </Box>
      <Divider sx={{ borderColor: '#F5F5F5', borderWidth: '1px' }} />
      <Box mt={1}>
        {kpi.planupdate.map((plan, index) => {
          const monthName = monthNames[plan.month - 1];
          const successStatus =
            plan.kpiSuccess === 1
              ? t("Success")
              : plan.kpiSuccess === 0
              ? t("Failure")
              : "";

          const successColor = successStatus === t("Success") ? "#50B498" : successStatus === t("Failure") ? "#FF6969" : "#000";
          return (
            <Box
              key={index}
              sx={{
                padding: '12px',
                backgroundColor: '#fff',
                borderRadius: '16px',
                marginBottom: '16px',
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: '#718EBF', fontWeight: 500, fontSize: "18px" }}
              >
                {monthName} {successStatus && <span style={{ color: successColor }}> : {successStatus}</span>}
              </Typography>
              <Box mt={2}>
                <Typography variant="body2" sx={{ color: '#718EBF', fontWeight: 500, fontSize: "16px" }}>
                  {t("Plan")}
                </Typography>
                <Box
                  sx={{
                    borderRadius: '8px',
                    padding: '12px',
                    backgroundColor: '#F5F5F5',
                    marginTop: '4px',
                  }}
                >
                  <Typography variant="body2" sx={{ color: '#000', fontSize: "15px" }}>
                    {plan.kpiPlan}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" mt={2} justifyContent="space-between" alignItems="stretch" flexDirection={{ xs: 'column', sm: 'row' }}>
                <Box flex={5} mr={{ xs: 0, sm: 2 }} mb={{ xs: 2, sm: 0 }} display="flex" flexDirection="column">
                  <Typography variant="body2" sx={{ color: '#718EBF', fontWeight: 500, fontSize: "16px" }}>Achievement</Typography>
                  <Box
                    sx={{
                      borderRadius: '8px',
                      padding: '12px',
                      backgroundColor: '#F5F5F5',
                      marginTop: '4px',
                      flexGrow: 1,
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Typography variant="body2" sx={{ color: '#000', fontSize: "15px" }}>
                      {plan.kpiAchievement}
                    </Typography>
                  </Box>
                </Box>
                <Box flex={5} mr={{ xs: 0, sm: 2 }} mb={{ xs: 2, sm: 0 }} display="flex" flexDirection="column">
                  <Typography variant="body2" sx={{ color: '#718EBF', fontWeight: 500, fontSize: "16px" }}>Failure</Typography>
                  <Box
                    sx={{
                      borderRadius: '8px',
                      padding: '12px',
                      backgroundColor: '#F5F5F5',
                      marginTop: '4px',
                      flexGrow: 1,
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Typography variant="body2" sx={{ color: '#000', fontSize: "15px" }}>
                      {plan.kpiFailure}
                    </Typography>
                  </Box>
                </Box>
                <Box flex={5} mr={{ xs: 0, sm: 2 }} mb={{ xs: 2, sm: 0 }} display="flex" flexDirection="column">
                  <Typography variant="body2" sx={{ color: '#718EBF', fontWeight: 500, fontSize: "16px" }}>Key Learning</Typography>
                  <Box
                    sx={{
                      borderRadius: '8px',
                      padding: '12px',
                      backgroundColor: '#F5F5F5',
                      marginTop: '4px',
                      flexGrow: 1,
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Typography variant="body2" sx={{ color: '#000', fontSize: "15px" }}>
                      {plan.kpiKeyLearning}
                    </Typography>
                  </Box>
                </Box>
                <Box flex={1} display="flex" flexDirection="column" alignItems={{ xs: 'center', sm: 'flex-end' }}>
                  <Typography variant="body2" sx={{ color: '#718EBF', fontWeight: 500, fontSize: "16px", marginRight: { xs: "0", sm: "55px" } }}>file</Typography>
                  <ButtonBlue
                    className="download"
                    variant="outlined"
                    aria-label="download"
                    sx={{
                      marginTop: '4px',
                      width: '80px',
                      height: '50px',
                      minWidth: '50px',
                      minHeight: '50px',
                      borderRadius: '12px',
                      padding: 0,
                      display: 'flex',       
                      alignItems: 'center', 
                      justifyContent: 'center', 
                    }}
                    onClick={() => downloadFile(plan.file)} 
                  >
                    <FileDownloadIcon />
                  </ButtonBlue>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Collapse>
  </CardContent>
</Card>

  );
};

function KpisPlanAndUpdate() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { result: kpiUpdateStore } = useSelector((state) => state.kpiUpdate);
  const { result: kpiOrganization } = useSelector((state) => state.kpiOrganization);
  const { control, handleSubmit, reset } = useForm(); 
  const [selectedYear, setSelectedYear] = useState(dayjs().get("year"));
  const [planItems, setPlanItems] = useState([]);
  const [expandedKpiId, setExpandedKpiId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState(null);
  const [progressValue, setProgressValue] = useState(0);
  const [ratingValue, setRatingValue] = useState(null);

  const data = location.state.data || {};

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const isAdmin = userProfile && userProfile.roles && userProfile.roles.includes('ROLE_ADMIN');

  const individualWeight = planItems.reduce(
    (sum, item) => sum + (item.isApprove === 1 ? parseFloat(item.weight) : 0),
    0
  )
  const organizationWeight = kpiOrganization
    ? kpiOrganization.reduce((sum, item) => sum + item.weight, 0)
    : 0;
  const totalWeight = individualWeight + organizationWeight;
  

  const approvedCount = planItems.filter((item) => item.isApprove === 1).length;

  const handleYearChange = (event) => {
    const filterKPI = kpiUpdateStore.filter(
      (item) => dayjs(item.createAt).get("year") === event.target.value
    );
    setPlanItems(filterKPI);
    setSelectedYear(event.target.value);
  };

  const handleExpandClick = (idKPI) => {
    setExpandedKpiId((prevExpandedKpiId) => (prevExpandedKpiId === idKPI ? null : idKPI));
  };

  const handleEditClick = (type) => {
    setDialogType(type);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDialogType(null);
    reset(); 
  };

  const refreshData = () => {
    dispatch(getKpiUpdateAndPlan({
      idEmployees: location.state.data.idEmployees,
      year: selectedYear,
    }));
  };

  const onSubmit = (data) => {
    handleDialogClose();
  };

  useEffect(() => {
    if (location.state && location.state.data && location.state.data.idEmployees) {
      dispatch(getKpiUpdateAndPlan({
        idEmployees: location.state.data.idEmployees,
        year: selectedYear,
      }));
      dispatch(getKpiOrganizationCascadeUpdatePlan({
        idEmployees: location.state.data.idEmployees,
        year: selectedYear,
      }));
      
    }
  }, [selectedYear, location.state, dispatch]);

  useEffect(() => {
    if (kpiUpdateStore) {
      let kpiPlanList = [...kpiUpdateStore];
      setPlanItems(kpiPlanList);
    }
  }, [kpiUpdateStore]);

  return (
    <ContainerStyled>
      {kpiUpdateStore && kpiOrganization && (
        <>
          <Box marginBottom="32px">
            <GridStyled
              container
              justifyContent="space-between"
              alignItems="center"
              mt={2}
            >
              <Box display="flex" alignItems="center">
                <Typography
                  variant="h6"
                  className="kpi"
                  sx={{ ml: 1, fontSize: "30px" }}
                >
                  KPI
                </Typography>
              </Box>
            </GridStyled>

            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              alignItems="flex-start"
              mt={1}
              mb={4}
              sx={{
                gap: "16px",
                padding: { xs: "0 16px", sm: "0 8px" },
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  backgroundColor: "white",
                  padding: "16px",
                  borderRadius: "16px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  width: { xs: "100%", sm: "29%" },
                  height: "90px",
                }}
              >
                <img
                  src={KPIappove}
                  alt="KPIs"
                  style={{ height: "70px", marginRight: "16px" }}
                />
                <Box>
                  <Typography variant="body1" fontWeight="600">
                    {getUserFullName(data)}
                  </Typography>
                  <Typography variant="body1" mt={1}>
                    {getUserPosition(data)}
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  backgroundColor: "white",
                  padding: "16px",
                  borderRadius: "16px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  width: { xs: "100%", sm: "29%" },
                  height: "90px",
                }}
              >
                <img
                  src={KPIweight}
                  alt="KPIs"
                  style={{ height: "70px", marginRight: "16px" }}
                />
                <Box>
                  <Typography variant="body1" fontWeight="600">
                    KPI {t("All")}
                  </Typography>
                  <Typography variant="body1" mt={1}>
                    {t("All")} {approvedCount} {t("List")}
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  backgroundColor: "white",
                  padding: "16px",
                  borderRadius: "16px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  width: { xs: "100%", sm: "29%" },
                  height: "90px",
                }}
              >
                <img
                  src={KPIPercent}
                  alt="Total Weight"
                  style={{ height: "70px", marginRight: "16px" }}
                />
                <Box width="100%">
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1" fontWeight="600">
                      {t("TotalWeight")}
                    </Typography>
                    <Typography variant="body1" fontWeight="600">
                      {totalWeight} %
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={totalWeight > 100 ? 1 : 2}
                  >
                    <TotalWeightProgress
                      variant="determinate"
                      value={totalWeight}
                      sx={{ width: "100%" }}
                    />
                  </Box>
                  {totalWeight > 100 && (
                    <Typography
                      variant="body2"
                      color="error"
                      align="right"
                      mt={1}
                    >
                      {t("MaxWeightAllowed")} 100%
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={4}
          >
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Typography
                variant="h7"
                sx={{ color: "#343C6A", fontSize: "20px", fontWeight: "500" }}
              >
                {t("SelectYear")}
              </Typography>
              <YearSelect
                variant="outlined"
                select
                fullWidth
                value={selectedYear}
                onChange={handleYearChange}
                sx={{ width: { xs: "100%", sm: "auto" } }}
              >
                <MenuItem value={dayjs().get("year")}>
                  {dayjs().format(
                    i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"
                  )}
                </MenuItem>
                <MenuItem value={dayjs().get("year") - 1}>
                  {dayjs()
                    .subtract(1, "year")
                    .format(i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY")}
                </MenuItem>
              </YearSelect>
            </Stack>
          </Box>

          {[
            ...kpiOrganization,
            ...planItems.filter((item) => item.isApprove === 1),
          ].map((item, index) => (
            <KpiCard
              key={index}
              index={index}
              kpi={item}
              expanded={expandedKpiId === index}
              handleExpandClick={handleExpandClick}
              handleEditClick={handleEditClick}
              isAdmin={isAdmin} // Pass the isAdmin prop
            />
          ))}

          {planItems.length === 0 && (
            <Box
              height="120px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={4}
            >
              <Typography variant="h6">
                <FindInPageIcon />
                {t("NoData")}
              </Typography>
            </Box>
          )}
        </>
      )}
      {dialogOpen && (
        <Dialogs
          dialogType={dialogType}
          open={dialogOpen}
          onClose={handleDialogClose}
          progressValue={progressValue}
          setProgressValue={setProgressValue}
          control={control}
          ratingValue={ratingValue}
          setRatingValue={setRatingValue}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          dispatch={dispatch}
          idKPI={expandedKpiId}
          onSuccess={refreshData}
        />
      )}
    </ContainerStyled>
  );
}

export default KpisPlanAndUpdate;