import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import CircularProgress from "@mui/material/CircularProgress";
import { BottomNavigation } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Typography, Avatar, Grid, Menu, MenuItem, Button } from "@mui/material";

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';


import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';

import Logo from "../assets/logo/unihr_logo.png";

import ReactSlickDemo from "./slick";

import Unicorn from "../landing/image/WebExample/unicornMascot.png";

import Example from "../landing/image/WebExample/example.png";

import Ajinomoto from "../landing/image/Customer/ajinomoto-logo.png";
import BGrimm from "../landing/image/Customer/b.grimm-logo.jpg";
import BigIntersafety from "../landing/image/Customer/big-intersafety-logo.png";
import CInspire from "../landing/image/Customer/c-Inspire-logo.jpg";
import Cirplas from "../landing/image/Customer/cirplas-logo.png";
import Cookware from "../landing/image/Customer/cookware.png";
import Cotto from "../landing/image/Customer/cotto-logo.png";
import GrandSiam from "../landing/image/Customer/grand-siam-logo.png";
import Hongthong from "../landing/image/Customer/hongthong-logo.png";
import ICO from "../landing/image/Customer/ico-logo.jpg";
import Ifcg from "../landing/image/Customer/ifcg-logo.png";
import Korber from "../landing/image/Customer/korber-logo.png";
import Kubota from "../landing/image/Customer/kubota-logo.png";
import Megakleen from "../landing/image/Customer/megakleen-logo.png";
import Nawaplastic from "../landing/image/Customer/nawaplastic-logo.png";
import NeonWork from "../landing/image/Customer/neon-work-logo.jpg";
import Peoplepack from "../landing/image/Customer/peoplepack.png";
import Professional3P from "../landing/image/Customer/professional-3p-logo.jpg";
import PSgeneration from "../landing/image/Customer/ps-generation-logo.jpg";
import QGen from "../landing/image/Customer/qgen-logo.png";
import Repco from "../landing/image/Customer/repco-logo.jpg";
import SCGC from "../landing/image/Customer/scgc-logo.png";
import SCGhome from "../landing/image/Customer/scg-home-logo.png";
import SCG from "../landing/image/Customer/scg-logo.png";
import SiamRajathanee from "../landing/image/Customer/siamrajathanee-logo.png";
import SiamTohcello from "../landing/image/Customer/siamtohcello-logo.jpg";
import SOHO from "../landing/image/Customer/soho-hospitality-logo.png";
import Thaidrill from "../landing/image/Customer/thaidrill.png";
import Tietex from "../landing/image/Customer/tietex-logo.png";
import TPE from "../landing/image/Customer/tpe-logo.png";
import TVthunder from "../landing/image/Customer/tv-thunder-logo.jpg";
import ZeroToOne from "../landing/image/Customer/zero-to-one-logo.png";
import WakeUpWaste from "../landing/image/Customer/wake-up-waste-logo.png";

import { logout } from "../../../actions/auth";
import "./index.css";


//Translator TH-EN
import { useTranslation } from "react-i18next";
import { useHistory, Redirect } from "react-router-dom";

import MainManuTap from "./main-manu-tab.js";
import AddManuTap from "./additional-manu-tab.js";
import Carousel from "./carousel.js"
import Reward from "./reward.js";
import Feature from "./feature.js";
import Highlight from "./highlight.js";
import CustomerSay from "./customer-say.js";
import PriceBox from "./price.js";
import Additional from "./additional-systems.js";
import Service from "./service.js";
import Footer from "./footer.js";
import zIndex from "@mui/material/styles/zIndex.js";


const useStyles = makeStyles({
  firstBg: {
    height: 600,
    background: 
      'linear-gradient(360deg, #FFFFFF -0.78%, rgba(255, 255, 255, 0) 99.22%), ' +
      'linear-gradient(90deg, rgba(235, 148, 192, 0.4) -0.51%, rgba(194, 176, 230, 0.4) 50.01%, rgba(165, 221, 231, 0.4) 99.49%)',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    zIndex: 0,

    ["@media only screen and (max-width: 1200px)"]: {
      height: 320,
    },
    ["@media only screen and (max-width: 600px)"]: {
      height: 560,
    },
  },
  footerBg: {
    height: "100%",
    background: 
      'linear-gradient(180deg, #FFFFFF -0.78%, rgba(255, 255, 255, 0) 99.22%), ' +
      'linear-gradient(90deg, rgba(235, 148, 192, 0.4) -0.51%, rgba(194, 176, 230, 0.4) 50.01%, rgba(165, 221, 231, 0.4) 99.49%)',
    backgroundRepeat: 'no-repeat',
    zIndex: 0,
    position: 'relative',
    bottom: 0, 
    width: '100%',

    ["@media only screen and (max-width: 800px)"]: {
      height: "100%",
    },
  }, 
  secondBg: {
    height: 300,
    "& .wrapSecondContent": {
      height: "100%",
      "& .left": {
        "& .left-2": {
          fontSize: 20,
          marginTop: 16,
        },
        ["@media only screen and (max-width: 600px)"]: {
          marginTop: 36,
          margin: "0 8px",
          textAlign: "center",
        },
        ["@media only screen and (max-width: 800px)"]: {
          marginTop: 0,
          margin: "0 8px",
          textAlign: "center",
        },
        ["@media only screen and (min-width:600px)"]: {},
        ["@media only screen and (min-width:768px)"]: {
          marginTop: 72,
          margin: "auto",
          width: "70%",
          textAlign: "center",
          "& .left-2": {
            fontSize: 20,
            marginTop: 16,
            width: "100%",
          },
        },
        ["@media only screen and (min-width:992px)"]: {
          "& .left-2": {
            maxWidth: 450,
          },
          marginTop: 120,
          marginLeft: 56,
        },
      },
      "& .right": {
        "& .inner-right": {
          position: "relative",
          height: "100%",
          ["@media only screen and (max-width: 600px)"]: {
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            flexWrap: "wrap",
            padding: "20px 0",
            width: "100%",
            "& .item1, .item2, .item3, .item4, .item5, .item6, .item7": {
              display: "flex",
              margin: 16,
            },
          },
          ["@media only screen and (min-width:600px)"]: {},
          ["@media only screen and (min-width:768px)"]: {
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            flexWrap: "wrap",
            padding: "20px 0",
            width: "80%",
            "& .item1, .item2, .item3, .item4, .item5, .item6, .item7": {
              display: "flex",
              margin: 16,
            },
          },
          ["@media only screen and (min-width:992px)"]: {
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            flexWrap: "wrap",
            padding: "100px 0",
            width: "80%",
            "& .item1, .item2, .item3, .item4, .item5, .item6, .item7": {
              display: "flex",
              margin: 16,
            },
          },
          ["@media only screen and (min-width:1200px)"]: {
            display: "block",
            justifyContent: "center",
            margin: 0,
            flexWrap: "wrap",
            padding: 0,
            width: "100%",
            "& .item1": {
              position: "absolute",
              top: 80,
              left: "30%",
            },
            "& .item2": {
              position: "absolute",
              top: 80,
              right: "30%",
            },
            "& .item3": {
              position: "absolute",
              top: 230,
              left: "20%",
            },
            "& .item4": {
              position: "absolute",
              top: 230,
              left: "42.5%",
            },
            "& .item5": {
              position: "absolute",
              top: 230,
              right: "20%",
            },
            "& .item6": {
              position: "absolute",
              top: 380,
              right: "30%",
            },
            "& .item7": {
              position: "absolute",
              top: 380,
              left: "30%",
            },
          },
        },
      },
    },
    ["@media only screen and (max-width: 600px)"]: {
      height: 480,
    },
  },
  avatarAndBoost: {
    textAlign: "center",
  },
  thirdBg: {
    "& .MuiTypography-h3": {
      ["@media only screen and (max-width: 600px)"]: {
        padding: "16px 0",
        textAlign: "center",
      },
      ["@media only screen and (min-width:768px)"]: {
        padding: "16px 0",
        textAlign: "center",
      },
      ["@media only screen and (min-width:992px)"]: {
        paddingTop: 24,
        paddingLeft: 56,
        textAlign: "left",
      },
    },

    ["@media only screen and (max-width: 600px)"]: {},
    ["@media only screen and (max-width: 320px)"]: {},
  },
  fourBg: {
    "& .MuiTypography-h3": {
      padding: "32px 0",
    },
  },
  itemSectFour: {
    maxWidth: 500,
    "& .text-2": {
      minHeight: 48,
      fontSize: 22,
    },
    "& .itemImageSectFour": {
      margin: "36px 0",
    },
  },
  fiveBg: {
    height: 482,
    overflow: "auto",
    "& .wrapContentFive": {
      height: "100%",
      "& .left": {
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
      "& .innerContent": {
        height: "100%",
        maxWidth: 550,
        margin: "auto",
        "& .MuiTypography-h3": {
          paddingTop: 75,
          ["@media only screen and (max-width: 600px)"]: {
            paddingTop: 28,
          },
        },
        "& .MuiTypography-h6": {
          paddingTop: 24,
        },
      },
    },
    "& .MuiTypography-h4": {
      paddingLeft: 56,
    },
  },
  sixBg: {
    ["@media only screen and (max-width: 600px)"]: {
      height: 650,
    },
  },
  avatar: {
    width: 80,
    height: 80,
  },
});

const StyledFragment = styled("div")({
  
}); 

const StyledRoot = styled("div")(({}) => ({
  "& .MuiTypography-root": {
    fontFamily: `'Kanit' !important`,
    color: "#212B36",
  },
  ["@media (min-width: 900px)"]: {
    marginTop: 76,
  },
  ["@media (min-width: 600px)"]: {
    marginTop: 64,
  },
}));


const StyledWrapFirstContent = styled("div")(({}) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  height: "100%",
  position: "relative",

  "& .MuiTypography-root": {
    color: "#FFFFFF",
  },
  "& .wrap-panel-3d": {
    ["@media (max-width: 1200px)"]: {
      display: "none",
    },
  },
  "& .wrap3D": {
    position: "absolute",
    animation: "$ant 3.5s ease-in-out 0s infinite normal ",
    bottom: 200,
    left: 150,
    zIndex: 1000,
    transform: "scale(1.7)",
  },
  "& .translucentCirclePink": {
    position: "absolute",
    right: -4,
    top: 80,
    zIndex: 10,

    width: "262px",
    height: "262px",
    borderRadius: "50%",
    background: "rgba(246, 221, 234, 0.35)",
  },
  "& .emptyCircleBorder": {
    position: "absolute",
    right: -22,
    top: 60,
    zIndex: -10,

    width: "300px",
    height: "300px",
    borderRadius: "50%",
    border: "1px solid rgba(194, 176, 229, 0.5)",
    boxSizing: "border-box",
  },
  "& .gradientCirclePurple": {
    position: "absolute",
    right: 10,
    bottom: 100,
    zIndex: 0,

    width: "334px",
    height: "334px",
    borderRadius: "50%",
    background: "radial-gradient(50% 50% at 50% 50%, #E2A8F0 0%, rgba(226, 168, 240, 0) 100%)",
  },
  "& .gradientCircleBlue": {
    position: "absolute",
    left: -70,
    top: 120,
    zIndex: 100,

    width: "370px",
    height: "370px",
    borderRadius: "50%",
    background: "radial-gradient(50% 50% at 50% 50%, rgba(165, 221, 231, 0.6) 0%, rgba(165, 221, 231, 0) 51.5%, rgba(165, 221, 231, 0) 100%)",
  },
  "& .smallGradientPink": {
    position: "absolute",
    left: 40,
    top: 320,
    zIndex: 1000,

    width: "43px",
    height: "43px",
    borderRadius: "50%",
    background: "linear-gradient(180deg, #EB94C0 0%, #F7DDEA 100%)",
    transform: "rotate(-29.3deg)",
  },
  "& .smallGradientPurple": {
    position: "absolute",
    left: 120,
    top: 120,
    zIndex: 1000,

    width: "12px",
    height: "12px",
    borderRadius: "50%",
    background: "linear-gradient(180deg, #C2B0E6 0%, #DDD4ED 100%)",
    transform: "rotate(-50.94deg)",
  },
  "& .smallGradientBlue": {
    position: "absolute",
    left: 170,
    bottom: 160,
    zIndex: 100,

    width: "28px",
    height: "28px",
    borderRadius: "50%",
    background: "linear-gradient(180deg, #A5DDE7 0%, #DCFAFF 100%)",
    transform: "rotate(-29.3deg)",
  },
  "& .translucentCircleWhite": {
    position: "absolute",
    right: 80,
    bottom: 170,
    zIndex: 100,

    width: "70px",
    height: "70px",
    borderRadius: "50%",
    background: "rgba(255, 255, 255, 0.5)",
  },

  "& .wrap-header-text": {
    marginTop: 140,
    ["@media only screen and (max-width: 1200px)"]: {
      marginTop: 80,
    },
  },
  "& .wrap-text": {
    "& .MuiTypography-root": {
      lineHeight: 1.3,
    },
    "& .main-line1": {
      fontSize: 48,
      color: "#000000",
      lineHeight: 1,
      fontWeight: 500, 
      whiteSpace: "nowrap",
      zIndex: 10000,
      "& span": {
        fontSize: 45,
        color: "#531881",
      },
      ["@media only screen and (max-width: 800px)"]: {
        fontSize: 40,
        textAlign: "center",
      },
    },
    "& .main-line2": {
      fontSize: 32,
      color: "#000000",
      lineHeight: 1.2,
      ["@media only screen and (max-width: 800px)"]: {
        fontSize: 28,
        textAlign: "center",
      },
    },
    "& .main-line3": {
      color: "rgba(83, 24, 129, 0.6)",
      fontWeight: 500, 
      fontSize: 28,
      ["@media only screen and (max-width: 800px)"]: {
        textAlign: "center",
      },
    },
  },
  "& .wrap-start-button": {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    ["@media only screen and (max-width: 800px)"]: {
      justifyContent: "center",
    },
  },
  "& .header3D": {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    marginTop: 55,
    "& .wrapCircle3D": {
      width: 300,
      height: 300,
      position: "absolute",
    },
  },
}));

const StyledWrapExampleContent = styled("div")(({}) => ({
    display: "flex",
    position: "relative", 
    height: "auto",
    marginTop: "75px", 
    marginBottom: "100px",
    justifyContent: "center",
      "& .middleExample": {
        width: "auto",
        height: "407px",
        ["@media (max-width: 800px)"]: {
          marginTop: "100px",
          marginBottom: "0px",
          marginLeft: "auto",
          marginRight: "auto",
          height: "220px",  
          width: "auto",
          justifyContent: "center",
      },
    },
  }));

const StyledWrapPartner = styled("div")(({}) => ({
  "& .grid-container": {
    display: "grid",
    gridTemplateColumns: "auto auto auto auto",
    gridGap: 20,
    padding: 10,
    marginTop: 75,
    marginBottom: 100,
    alignItems: "center",
    position: "relative",
    ["@media only screen and (max-width: 800px)"]: {
      top: "-150px !important", 
      position: "relative !important", 
    },
    "& .main-line4": {
        fontSize: 32,
        color: "#000000",
        lineHeight: 1,
        fontWeight: 200, 
        justifyItems: "center",
        alignItems: "center",
        "& span": {
          fontSize: 64,
          color: "#531881",
          fontWeight: 600, 
        },
    },
    ["@media (max-width: 768px)"]: {
      gridTemplateColumns: "repeat(2, 1fr)",
      padding: 0,
      gridGap: 0,
      rowGap: 20,
    },
    ["@media (min-width: 768px)"]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    ["@media (min-width: 900px)"]: {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    ["@media (min-width: 1200px)"]: {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
  },
  "& .grid-item": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const StyledWrapFeatureContent = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative", 
  height: "auto",
  marginTop: "75px", 
  marginBottom: "100px",
  justifyContent: "center",
  flexDirection: "row",
  "& .FeatureContainer": {
    "& .featureItem": {
      display: "flex",
      justifyContent: "space-evenly",
      [theme.breakpoints.down("md")]: { // ใช้ breakpoint "md" จาก theme
        flexDirection: "column",
        height: "auto",
      },
    },
  },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginTop: '40px',
        marginBottom: '40px',
        alignItems: "center",
      },
}));

const StyledWrapOurTechnology = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative", 
  height: "auto",
  marginTop: "75px", 
  marginBottom: "100px",
  ["@media (max-width: 800px)"]: {
    marginTop: "-50px",
    marginBottom: "-30px"
  },
}));

const StyledWrapHighlight = styled("div")(({ theme }) => ({
  marginTop: 150,
  marginBottom: 150,
  "& .grid-container": {
    display: "flex",
    flexWrap: "wrap", 
    justifyContent: "space-between", 
  },
  "& .grid-container": {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)", 
    gap: "5px",  
  },

    // Image group
    "& .image-group": {
      position: "relative",
      width: "350px",
      height: "auto",
      zIndex: 1,
    }, 
  // Gradient styles
  "& .circle-container": {
    position: "relative",
    marginBottom: 0,
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
  },
  "& .circle": {
    position: "absolute",
    borderRadius: "50%",
    zIndex: 0,
  },
  "& .background-1": {
    width: "340px",
    height: "340px",
    background: "radial-gradient(50% 50% at 50% 50%, rgba(194, 176, 229, 0.4) 0%, rgba(194, 176, 229, 0) 100%)",
  },
  "& .background-2": {
    width: "375px",
    height: "375px",
    background: "radial-gradient(50% 50% at 50% 50%, rgba(235, 148, 192, 0.4) 0%, rgba(235, 148, 192, 0) 100%)",
    marginBottom: "50px",
  },
  "& .background-3": {
    width: "95px",
    height: "95px",
    background: "rgba(220, 250, 255, 0.4)",
    filter: "blur(25px)",
    marginBottom: "50px",
  },
  // Typography for highlighted text
  "& .highlight-text": {
    fontSize: "34px",
    lineHeight: 1.25,
    minHeight: "50px",
  },
  // Subtext styling
  "& .subtext": {
    fontSize: "20px",
    fontWeight: "200",
    marginTop: "10px",
    lineHeight: 1.6,
    wordWrap: "break-word",
  },

  "@media (max-width: 800px)": {
    "& .grid-container": {
      display: "flex",
      flexWrap: "wrap",  
      justifyContent: "space-between", 
    },
    "& .grid-item": {
      width: "30%", 
      marginBottom: "30px",
      display: "flex",
      flexDirection: "column",  
      justifyContent: "center", 
      alignItems: "center", 
    },
    "& .image-group": {
      height: "auto",
      width: "210px",
      margin: "0px"
    },
    "& .highlight-text": {
      fontSize: "26px",
      margin: "5px 0px",
      lineHeight: 1.2
    },
    "& .subtext": {
      fontSize: "16px",
      ineHeight: 0.5
    },
    "& .background-1": {
      width: "200px",
      height: "200px",
    },
    "& .background-2": {
      width: "250px",
      height: "250px",
    },
    "& .background-3": {
      marginTop: "50px",
    },
  },
}));



const StyledWrapCustomerSay = styled('div')(() => ({
  marginTop: 150,
  marginBottom: 150,
  "& .partner-grid": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  }, 

  "& .partner-card": {
    borderRadius: "8px",
    padding: "24px",
    height: "195px",
    width: "535px",
    borderRadius: "22px",
    backgroundColor: "rgba(235, 234, 238, 0.4)",
    boxShadow: "none",
  },

  "& .partner-card-header": {
    marginBottom: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    position: "relative",
  },

  "& .partner-info": {
    display: "flex",
    flexDirection: "row",
    textAlign: "left",
  },

  "& .partner-avatar": {
    backgroundColor: 'rgba(83, 24, 129, 0.7)',
    width: "90px",
    height: "100px",
    marginRight: "25px",
    marginLeft: "5px",
  },

  "& .partner-details": {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },

  "& .partner-testimonial": {
    fontSize: "16px",
    fontWeight: "200",
  },

  "& .partner-name": {
    fontSize: "20px",
    marginTop: "15px",
  },

  "& .partner-position": {
    fontSize: "14px",
    fontWeight: "200",
    marginTop: "0",
    color: "rgba(83, 24, 129, 0.7)",
  },

  "& .partner-logo": {
    height: "50px",
    width: "auto",
    position: "absolute",
    bottom: "0",
    right: "0",
    marginBottom: "15px",
    marginRight: "15px",
    marginLeft: "5px"
  },
  "@media (max-width: 800px)": {
    "& .partner-grid": {
      flexDirection: "column", 
      justifyContent: "center", 
      alignItems: "center", 
       
    },
    "& .partner-card": {
      width: "90% !important", 
      maxWidth: "100% !important", 
      marginBottom: "5px",
      height: "150px",
    },
  },
}));

const StyledWrapReward = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative", 
  height: "auto",
  width: "100%", 
  marginTop: "75px", 
  marginBottom: "100px",
  justifyContent: "center",
  alignItems: "center", 
  marginLeft: "auto",  
  marginRight: "auto", 
}));



const StyledWrapPrice = styled('div')(() => ({
  marginTop: 140,
  maxHeight: '100%',
  height: '100%',
  '& .specialText': {
    fontSize: '36px',
    fontWeight: 500,
    marginTop: '60px',
    marginBottom: '30px',
    color: '#A441BC',
    "@media only screen and (max-width: 800px)": {
      fontSize: '30px',
    },
  },
  '& .specialText .highlight': {
    fontSize: '40px',
    color: '#531881',
    padding: '0px 50px',
    "@media only screen and (max-width: 800px)": {
      fontSize: '34px',
    },
  },

}));



const StyledWrapAdditional = styled("div")(() => ({
  width: "100%",
  height: "100%",

  "& .additional-grid": {
    display: "flex",
    flexWrap: "wrap", 
    justifyContent: "space-between",  
    gap: "20px",  
    overflowX: "auto",  
    "@media (max-width: 800px)": {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",  
      gap: "5px 20px",  

    },
  },

  "& .additional-card": {
    borderRadius: "8px",
    border: "4px solid rgba(83, 24, 129, 0.5)",
    padding: "24px 30px",
    height: "100px",
    width: "88%",
    borderRadius: "30px",
    boxShadow: "none",
    alignItems: "center",
    "@media (max-width: 800px)": {
      height: "60%",
      width: "88%",
      padding: "auto 0px",
      alignItems: "center", 
    },
  },
}));

const StyledWrapService = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative", 
  height: "auto",
  marginTop: "75px", 
  marginBottom: "100px",
  justifyContent: "space-between",
  justifyContent: "center",
}));

const WrapFooter = styled("div")(() => ({
  marginTop: "70px",
}));



const StyledAppBar = styled(AppBar)(({}) => ({
  boxShadow: "none",
  backgroundColor: "transparent",
  "& .MuiToolbar-regular": {
    color: "#212b36",
    backgroundColor: "#ffffffcc",
    transition:
      "height 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    backdropFilter: "blur(6px)",
    ["@media (min-width: 900px)"]: {
      height: 76,
    },

    "& .MuiContainer-root": {
      display: "flex",
      alignItems: "center",
      ["@media (max-width: 768px)"]: {
        padding: 0,
      },
      "& .headerAction": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& .translate, .MuiDivider-root, .contact-us": {
          ["@media (max-width: 768px)"]: {
            display: "none",
          },
        },
      },
    },
  },
}));

const StyledIconButtonTranslate = styled(IconButton)(({}) => ({
  borderRadius: 50,
  padding: 8,
  marginRight: 8,
  fontSize: 24,

  "&:hover": {
    backgroundColor: "#EBEAEE",
  },
}));

const StyledIconButtonContactSmall = styled(IconButton)({
  ["@media (min-width: 800px)"]: {
    display: "none",
  },
  color: "#531881",
  backgroundColor: "#FFFFFF",
  borderRadius: 50,
  padding: 10,
  marginRight: 8,
  fontSize: 24,
  "&:hover": {
    color: "#FFFFFF",
    borderColor: "#531881",
    backgroundColor: "#531881",
  },
});

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const Landing = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [state, setState] = useState({ nav1: null, nav2: null });
  const slider1 = useRef();
  const slider2 = useRef();

  const { t, i18n } = useTranslation();
  const [anchorEl_Trans, setAnchorEl_Trans] = useState(null);
  const open_Trans = Boolean(anchorEl_Trans);

  const handleClick_Trans = (event) => {
    setAnchorEl_Trans(event.currentTarget);
  };

  const handleClose_Trans = () => {
    setAnchorEl_Trans(null);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleClose_Trans();
  };

  // ฟังก์ชันสำหรับการ animate ตัวเลข
  const animateValue = (id, start, end, duration) => {
    const element = document.getElementById(id);
    if (!element) return;

    const range = end - start;
    const increment = range / (duration / 10);
    let current = start;
    const timer = setInterval(() => {
      current += increment;
      if (current >= end) {
        current = end;
        clearInterval(timer);
      }
      element.textContent = Math.floor(current).toLocaleString();
    }, 10);
  };
  
  useEffect(() => {
    dispatch(logout());
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
      isMobile: window.screen.width <= 768,
    });

    
    animateValue("userCount", 0, 27000, 2000);
    animateValue("companyCount", 0, 122, 2000);

    
    document.querySelectorAll(".logoContainer").forEach((container) => {
      const slide = container.querySelector(".logoSlide");
      const clone = slide.cloneNode(true);
      container.appendChild(clone);
    });
  }, []);

  return (
    <StyledFragment>
      <ElevationScroll {...props}>
        <StyledAppBar>
          <Toolbar>
            <Container maxWidth="lg">
              <div>
                <img src={Logo} alt="logo" width={80} />
              </div>
              <div style={{ flexGrow: 1 }}></div>
              <div className={`headerAction`}>
                <div className="translate">
                  <StyledIconButtonTranslate
                    aria-label="translate"
                    size="small"
                    aria-controls={open_Trans ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open_Trans ? "true" : undefined}
                    onClick={handleClick_Trans}
                  >
                    <LanguageRoundedIcon fontSize="medium" color="#000000" />
                  </StyledIconButtonTranslate>

                  <Menu
                    anchorEl={anchorEl_Trans}
                    open={open_Trans}
                    onClose={handleClose_Trans}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    PaperProps={{ style: { width: '230px', boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)', transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)', fontSize: '12px' },}}
                  >
                  <MenuItem onClick={() => changeLanguage("th")}>
                    ภาษาไทย <span style={{ color: "#B4B4B4", marginLeft: "10px" }}>Thai</span>
                  </MenuItem>
                  <MenuItem onClick={() => changeLanguage("en")}>
                    English
                  </MenuItem>
                  <MenuItem onClick={() => changeLanguage("la")}>
                    ລາວ <span style={{ color: "#B4B4B4", marginLeft: "10px" }}>Lao</span>
                  </MenuItem>
                  <MenuItem onClick={() => changeLanguage("jp")}>
                    日本 <span style={{ color: "#B4B4B4", marginLeft: "10px" }}>Japanese</span>
                  </MenuItem>
                  <MenuItem onClick={() => changeLanguage("kh")}>
                    កម្ពុជា <span style={{ color: "#B4B4B4", marginLeft: "10px" }}>Cambodian</span>
                  </MenuItem>
                  <MenuItem onClick={() => changeLanguage("mm")}>
                    မြန်မာ <span style={{ color: "#B4B4B4", marginLeft: "10px" }}>Myanmar</span>
                  </MenuItem>
                  <MenuItem onClick={() => changeLanguage("cn")}>
                    中国 <span style={{ color: "#B4B4B4", marginLeft: "10px" }}>Chinese</span>
                  </MenuItem>
                  <MenuItem onClick={() => changeLanguage("kr")}>
                    대한민국 <span style={{ color: "#B4B4B4", marginLeft: "10px" }}>Korean</span>
                  </MenuItem>
                  <MenuItem onClick={() => changeLanguage("vn")}>
                    Việt Nam <span style={{ color: "#B4B4B4", marginLeft: "10px" }}>Vietnamese</span>
                  </MenuItem>

                  </Menu>
                </div>

                <Divider
                  style={{ margin: "0 16px", height: 24 }}
                  orientation="vertical"
                />
                <div>
                  <Button className="contact-us" variant="contained" component={NavLink} to="/contact-us"
                      sx={{
                        marginLeft: 0,
                        marginRight: 0,
                        borderRadius: 50,
                        height: 45,
                        width: "auto",
                        padding: "0px 20px",
                        fontSize: 18,
                        fontWeight: 500,
                        backgroundColor: '#ffffff',
                        color: '#531881',
                        border: '2px solid #531881',
                        boxShadow: 'none',
                        '&:hover': {
                          backgroundColor: '#531881', 
                          color: '#ffffff',
                          boxShadow: 'none',
                        },
                      }}
                      >
                  {t("ContactUs")}
                  </Button>

                  <StyledIconButtonContactSmall
                    aria-label="contact"
                    component={NavLink}
                    to="/login"
                    size="large"
                  >
                    <i class="fas fa-sign-in"></i>
                  </StyledIconButtonContactSmall>
                

                  <Button className="contact-us" variant="contained" component={NavLink} to="/login"
                      sx={{
                        marginLeft: 2,
                        marginRight: 0,
                        borderRadius: 50,
                        height: 45,
                        width: "auto",
                        padding: "0px 20px",
                        fontSize: 18,
                        fontWeight: 500,
                        backgroundColor: '#531881',
                        color: '#ffffff',
                        boxShadow: 'none',
                        '&:hover': {
                          backgroundColor: '#ffffff', 
                          color: '#531881',
                          boxShadow: 'none',
                          border: '3px solid #531881',
                        },
                      }}
                      >
                  {t("LogIn")}
                  </Button>

                </div>
              </div>
            </Container>
          </Toolbar>
        </StyledAppBar>
      </ElevationScroll>

      <StyledRoot>
        <div className={`${classes.firstBg}`}>
          <Container maxWidth="lg">

            <StyledWrapFirstContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={7}>
                  <div className={`wrap-header-text`}>
                    <div className="wrap-text">
                      <Typography className={`main-line1`}>
                        {t("hrProgramModern")}
                      </Typography>
                      <Typography className={`main-line1`} style={{marginBottom: "20px"}}>
                        <span>{t("easyCompleteHR")}</span>
                      </Typography>
                      <Typography className={`main-line2`}>
                        {t("worldClassHRSystem")}
                      </Typography>
                      <Typography className={`main-line2`} style={{marginBottom: "20px"}}>
                      {
                          t("costEffectiveHR")
                            .split("|") 
                            .map((part, index) => 
                              index === 1 ? (
                                <span key={index} style={{ fontSize: "1.5em", fontWeight: "500", color: "#531881" }}>
                                  {part}
                                </span>
                              ) : (
                                part
                              )
                            )
                        }
                      </Typography>
                      <Typography className={`main-line3`} style={{marginBottom: "20px"}}>
                        {t("changeToUniHR")}
                      </Typography>
                    </div>
                    <div className="wrap-start-button">
                      <Button
                        component={NavLink}
                        to="/contact-us"
                        variant={"contained"}
                        sx={{
                          marginTop: 2,
                          marginBottom: 20,
                          borderRadius: 10,
                          height: 70,
                          width: "auto",
                          padding: "0px 30px",
                          fontSize: 24,
                          backgroundColor: '#531881',
                          '&:hover': {
                            backgroundColor: '#ffffff', 
                            color: '#531881',
                            border: '3px solid #531881'
                          },
                          ["@media (max-width: 600px)"]: {
                            width: "80%",
                            height: 60,
                            fontSize: 18,
                          }
                        }}
                        >
                           {t("trialFreeUsage")}
                      </Button>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={5} className="wrap-panel-3d">
                  <div className={`header3D`}>
                    <div className={`wrap3D`}>
                      <img src={Unicorn} alt="unicorn" width="220" />
                    </div>
                    <div className={`translucentCirclePink`}></div>
                    <div className={`gradientCirclePurple`}></div>
                    <div className={`gradientCircleBlue`}></div>
                    <div className={`emptyCircleBorder`}></div>
                    <div className={`smallGradientPink`}></div>
                    <div className={`smallGradientPurple`}></div>
                    <div className={`smallGradientBlue`}></div>
                    <div className={`translucentCircleWhite`}></div>
                  </div>
                </Grid>
              </Grid>
            </StyledWrapFirstContent>
          </Container>
        </div>
        
        <StyledWrapExampleContent className={`${classes.secondBg}`}>
            <Container maxWidth="lg">
                <img src={Example} alt="Middle" className={`middleExample`} />
            </Container>
        </StyledWrapExampleContent>

        <StyledWrapPartner className={`${classes.secondBg}`}>
          <Container maxWidth="lg">
            <Typography variant="h2" align="center" sx={{ fontSize: "48px", fontWeight: 400, marginTop: 25}}>
              {t("clientsAndPartners")}
            </Typography>
            <Typography sx={{ fontSize: "28px", fontWeight: 200, textAlign: "center", marginTop: 7 }}>
              <span id="userCount" 
                    style={{ fontSize: "64px", fontWeight: 500, color: "rgba(83, 24, 129, 0.8)" }}
                    className="number-counter">
                27,000 +
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;{t("systemUsers")}&nbsp;&nbsp;&nbsp;&nbsp;
              <span id="companyCount"
                    style={{ fontSize: "64px", fontWeight: 500, color: "rgba(83, 24, 129, 0.8)" }}
                    className="number-counter">
                &nbsp;&nbsp;&nbsp;&nbsp;122
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;{`${t("Company")}`}
          </Typography>
            <div className="logoContainer">
              <div className="logoSlide">
                <img src={SCG} alt="SCG"/>
                <img src={SCGC} alt="SCGC"/>
                <img src={ZeroToOne} alt="ZeroToOne"/>
                <img src={SCGhome} alt="SCGhome"/>
                <img src={TPE} alt="TPE"/>
                <img src={ICO} alt="ICO"/>
                <img src={GrandSiam} alt="GrandSiam"/>
                <img src={Nawaplastic} alt="Nawaplastic"/>
                <img src={QGen} alt="QGen"/>
                <img src={Cotto} alt="Cotto"/>
                <img src={Korber} alt="Korber"/>
                <img src={SOHO} alt="SOHO"/>
                <img src={Kubota} alt="Kubota"/>
                <img src={Ajinomoto} alt="Ajinomoto"/>
                <img src={TVthunder} alt="TVthunder"/>
              </div>
            </div>
            <div className="logoContainer">
              <div className="logoSlide">
                <img src={Cirplas} alt="Cirplas"/>
                <img src={Thaidrill} alt="Thaidrill"/>
                <img src={Cookware} alt="Cookware"/>
                <img src={Peoplepack} alt="Peoplepack"/>
                <img src={CInspire} alt="CInspire"/>
                <img src={Professional3P} alt="Professional3P"/>
                <img src={SiamTohcello} alt="SiamTohcello"/>
                <img src={Tietex} alt="Tietex"/>
                <img src={Ifcg} alt="Ifcg"/>
                <img src={BGrimm} alt="BGrimm"/>
                <img src={NeonWork} alt="NeonWork"/>
                <img src={BigIntersafety} alt="BigIntersafety"/>
                <img src={PSgeneration} alt="PSgeneration"/>
                <img src={Megakleen} alt="Megakleen"/>
                <img src={Hongthong} alt="Hongthong"/>
                <img src={Repco} alt="Repco"/>
                <img src={SiamRajathanee} alt="SiamRajathanee"/>
              </div>
            </div>
          </Container>
        </StyledWrapPartner>
        
        <StyledWrapFeatureContent className={`${classes.secondBg}`} style={{ height:"auto" }}>
          <Container maxWidth="lg" style={{ width: '90%' }}>
            <Typography variant="h2" align="center" sx={{ 
              fontSize: "48px", 
              fontWeight: 400, 
              marginTop: 25}}>
              {t("allHRFeaturesInOne")}
            </Typography>
            <Typography variant="body2" align="center" sx={{ 
              color: "rgba(83, 24, 129, 0.7) !important", 
              fontSize: 25, 
              fontWeight: 500, 
              marginTop: {
                md: "50px",
                sm: "50px"
              },
              marginBottom: {
                md: "20px",
                sm: "20px"
              },
              }}>
              {t("mainFeatureExamples")}
            </Typography>
            <MainManuTap />
            <Typography variant="body2" align="center" sx={{ 
              color: "#EB94C0 !important", 
              fontSize: 25, 
              fontWeight: 500, 
              marginBottom: {
                md: "20px",
                sm: "50px"
              },
              paddingTop: {
                md: "10px",
                sm: "150px"
              },
              marginTop: {
                md: "70px",
                sm: "150px"
              }
              }}
            >
              {t("otherHRFeatures")}
            </Typography>
            <AddManuTap />
            <Feature/>
          </Container>
        </StyledWrapFeatureContent> 

        <StyledWrapOurTechnology>
          <Typography 
            variant="h2" 
            align="center" 
              sx={{ 
                fontSize: "48px", 
                fontWeight: 400, 
                marginBottom: "50px",
              }}>
              {t("easyToUseWithModernTechnology")}
          </Typography>
          <Carousel />
        </StyledWrapOurTechnology>

        <StyledWrapHighlight className={`${classes.thirdBg}`}>
            <Highlight/>
        </StyledWrapHighlight>

        <StyledWrapCustomerSay className={`${classes.thirdBg}`}>
            <CustomerSay />
        </StyledWrapCustomerSay>

        <StyledWrapReward>
            <Reward/>
        </StyledWrapReward>

        <StyledWrapPrice>
          <PriceBox/>
          <Typography variant="h2" align="center" className="specialText">
            <span className="highlight">{t("specialOfferTitle")}</span>{t("specialOfferDescription")}
          </Typography>
        </StyledWrapPrice>

        <StyledWrapAdditional>
          <Additional />
        </StyledWrapAdditional>

        <StyledWrapService>
          <Service />
        </StyledWrapService>
       

        <WrapFooter className={`${classes.footerBg}`}>
          <Footer />
        </WrapFooter>

      </StyledRoot>
    </StyledFragment>
  );
};

export default Landing;