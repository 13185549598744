import * as React from "react";
import { Container, Typography, Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { useRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles'; 

import Girl from '../landing/image/Girl.png';
import CorrectIcon from "../landing/image/Promote/corectIcon.png";

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    "@media (max-width: 800px)": {
      flexDirection: 'column',
    },
  },
  leftContainer: {
    flex: 1,
    maxWidth: '30%',
    maxHeight: '100%',
    left: 0,
    "@media (max-width: 800px)": {
      display: 'none',
    },
  },
  rightContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '70%',
    "@media (max-width: 800px)": {
      maxWidth: '100%',
      justifyContent: 'center',
    },
  },
  list: {
    width: '80%',
    maxWidth: '300px',
    bgcolor: 'background.paper',
    marginLeft: 5,
    "@media (max-width: 800px)": {
      width: '100%',
      marginLeft: "30px",
    },
  },
  priceText: {
    fontSize: '36px',
    fontWeight: 400,
    marginTop: "60px",
    marginBottom: "30px",
    color: "#A441BC !important",
    fontWeight: 500,
    "@media (max-width: 800px)": {
      fontSize: '34px', // ลดขนาดฟอนต์เมื่อหน้าจอเล็ก
    },
  },
}));

export default function Service() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const containerRef = useRef(null);

  useEffect(() => {

      }, 
        []); 

  return (
    <Container maxWidth="lg">
      <Typography variant="h2" align="center" sx={{ fontSize: "48px", fontWeight: 400, marginTop: "60px", marginBottom: "30px" }}>
        {t("payrollOutsourceServiceTitle")}
      </Typography>
      <div className={classes.container}>
        <div ref={containerRef} className={classes.leftContainer}>
          <img src={Girl} alt="Girl" 
                 style={{ height: '500px', width: 'auto', transform: 'scaleX(-1)', marginLeft: '70px', marginTop: '-20px' }} 
          />
        </div>

        <div className={classes.rightContainer}>
          {/* 1 */}
          <List className={classes.list}>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ width: 61, height: 61, marginRight: 5, background: 'rgba(255, 255, 255, 1)' + 'linear-gradient(90deg, rgba(235, 148, 192, 0.4) -0.51%, rgba(194, 176, 230, 0.4) 50.01%, rgba(165, 221, 231, 0.4) 99.49%)' }}>
                  <img src={CorrectIcon} style={{ width: 35, height: 35 }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText sx={{ whiteSpace: 'nowrap' }}>
                <Typography variant="h4" align="left" style={{ fontSize: '28px', fontWeight: 400, paddingRight: 50 }}>
                  <span style={{ color: 'rgba(83, 24, 129, 0.7)' }}>{t("payrollBackOffice")}</span>&nbsp;{t("payrollBackOfficeSpan")}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
          {/* 2 */}
          <List className={classes.list}>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ width: 61, height: 61, marginRight: 5, background: 'rgba(255, 255, 255, 1)' + 'linear-gradient(90deg, rgba(235, 148, 192, 0.4) -0.51%, rgba(194, 176, 230, 0.4) 50.01%, rgba(165, 221, 231, 0.4) 99.49%)' }}>
                  <img src={CorrectIcon} style={{ width: 35, height: 35 }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText sx={{ whiteSpace: 'nowrap' }}>
                <Typography variant="h4" align="left" style={{ fontSize: '28px', fontWeight: 400 }}>
                  <span style={{ color: 'rgba(83, 24, 129, 0.7)' }}>{t("reduceWorkAndTime")}</span><br />{t("reduceWorkAndTimeSpan")}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
          {/* 3 */}
          <List className={classes.list}>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ width: 61, height: 61, marginRight: 5, background: 'rgba(255, 255, 255, 1)' + 'linear-gradient(90deg, rgba(235, 148, 192, 0.4) -0.51%, rgba(194, 176, 230, 0.4) 50.01%, rgba(165, 221, 231, 0.4) 99.49%)' }}>
                  <img src={CorrectIcon} style={{ width: 35, height: 35 }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText sx={{ whiteSpace: 'nowrap' }}>
                <Typography variant="h4" align="left" style={{ fontSize: '28px', fontWeight: 400 }}>
                  <span style={{ color: 'rgba(83, 24, 129, 0.7)' }}>{t("longTermSavings")}</span><br />{t("longTermSavingsSpan")}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
          {/* 4 */}
          <List className={classes.list}>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ width: 61, height: 61, marginRight: 5, background: 'rgba(255, 255, 255, 1)' + 'linear-gradient(90deg, rgba(235, 148, 192, 0.4) -0.51%, rgba(194, 176, 230, 0.4) 50.01%, rgba(165, 221, 231, 0.4) 99.49%)' }}>
                  <img src={CorrectIcon} style={{ width: 35, height: 35 }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText sx={{ whiteSpace: 'nowrap' }}>
                <Typography variant="h4" align="left" style={{ fontSize: '28px', fontWeight: 400 }}>
                  <span style={{ color: 'rgba(83, 24, 129, 0.7)' }}>{t("preventLeaksAndErrors")}</span><br />{t("preventLeaksAndErrorsSpan")}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </div>
      </div>
      <Typography variant="h2" align="center" className={classes.priceText}>
        <span style={{ color: "#531881", fontWeight: 500 }}>{t("startingPrice")}</span><br />{t("startingPriceSpan")}
      </Typography>
    </Container>
  );
}