import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import "dayjs/locale/th";

import {
  Button,
  Container,
  Typography,
  CardContent,
  TablePagination,
  Grid,
  Box,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";

import FilePresentIcon from "@mui/icons-material/FilePresent";

import StyledCard from "../../../../shared/general/Card";
import Backdrop from "../../../../shared/general/Backdrop";
import utils from "../../../../../../utils";
import Comments from "../../comments";
import Actions from "../../actions";

import RowItemExpense from "./row-per-diem";

import { getExpenseAllowanceById } from "../../../../../../actions/expense";
import { getUserFullName } from "../../../../../../utils/userData";
import FileIcon from "../../file-icon";

const StyledRoot = styled("div")({
  width: "1280px !important",
  margin: "auto",
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 24,
  },
  "& .head": {
    paddingTop: 8,
    marginBottom: 24,
    "& .MuiTypography-subtitle1": {
      fontWeight: 500,
    },
  },
});

const StyledCardContent = styled(CardContent)({
  padding: 24,
  "& .MuiFormControl-root": {
    marginBottom: 8,
  },
  "& .wrap-head": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
  },
  "& .wrap-row-sum": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledTableCell = styled(TableCell)({
  border: "none",
  padding: 0,
  "& .MuiTypography-root": {
    marginBottom: 16,
  },
  "&.summary": {
    textAlign: "center",
  },
  "&.date-document": {
    width: 120,
  },
  "&.service": {
    width: 150,
  },
  "&.description": {
    width: 200,
  },
  "&.amount": {
    width: 104,
  },
  "&.price": {
    width: 104,
  },
  "&.discount": {
    width: 104,
  },
  "&.tax": {
    width: 40,
  },
  "&.price-before-tax": {
    width: 104,
  },
  "&.tax-withholding": {
    width: 104,
  },
  "&.delete": {
    width: 32,
  },
});

const StyledGrid = styled(Grid)({
  borderRight: "2px solid rgba(145, 158, 171, 0.32)",
});

const ViewExpensePerDiemPage = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: expenseDetail } = useSelector((state) => state.expenseDetail);
  const { idExpense } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const rowHeader = [
    {
      caption: t("StartDate"),
      className: "date-start-end",
    },
    {
      caption: t("EndDate"),
      className: "date-start-end",
    },
    {
      caption: t("Description"),
      className: "description",
    },
    {
      caption: t("Unit.Days"),
      className: "amount",
    },
  ];

  useEffect(() => {
    if (userProfile) {
      dispatch(getExpenseAllowanceById(idExpense));
    }
  }, [userProfile]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <div>
      {expenseDetail ? (
        <Box>
          <div>
            <div className="wrap-head">
              <StyledHeadLabel variant="h6">
                {`${t("GeneralInformation")} (${t("Allowance")})`}
              </StyledHeadLabel>
            </div>
            <div>
              <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
                {t("DocumentNumber")}
              </StyledHeadLabel>
              <Typography
                component="p"
                variant="subtitle1"
                sx={{
                  paddingLeft: 4,
                  marginBottom: 2,
                  marginTop: 1,
                }}
              >
                &nbsp;&nbsp;{expenseDetail.documentId}
              </Typography>
            </div>
            <div>
              <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
                {t("TitleName")}
              </StyledHeadLabel>
              <Typography
                component="p"
                variant="subtitle1"
                sx={{
                  paddingLeft: 4,
                  marginBottom: 4,
                  marginTop: 1,
                }}
              >
                &nbsp;&nbsp;{expenseDetail.nameExpense}
              </Typography>
            </div>
            <div>
              <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
                {t("Approver")}
              </StyledHeadLabel>
              <Typography
                component="p"
                variant="subtitle1"
                sx={{
                  paddingLeft: 4,
                  marginBottom: 4,
                  marginTop: 1,
                }}
              >
                &nbsp;&nbsp;
                {getUserFullName({
                  firstname_TH: expenseDetail.approver_firstname_TH,
                  lastname_TH: expenseDetail.approver_lastname_TH,
                  firstname_EN: expenseDetail.approver_firstname_EN,
                  lastname_EN: expenseDetail.approver_lastname_EN,
                })}
              </Typography>
            </div>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <StyledHeadLabel
                    variant="body2"
                    color="text.third"
                    gutterBottom
                  >
                    {t("LocationExpenses")}
                  </StyledHeadLabel>
                  <Typography
                    component="p"
                    variant="subtitle1"
                    sx={{
                      paddingLeft: 4,
                      marginBottom: 2,
                      marginTop: 1,
                    }}
                  >
                    &nbsp;&nbsp;
                    {expenseDetail.isInternational
                      ? t("International")
                      : t("Domestic")}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
          <StyledDivider />
          <div>
            <div className="wrap-head">
              <StyledHeadLabel variant="h6">{t("List")}</StyledHeadLabel>
            </div>

            <TableContainer>
              <Table
                style={{
                  minWidth: 650,
                  borderSpacing: "0 4px",
                  borderCollapse: "separate",
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    {rowHeader.map((item) => (
                      <StyledTableCell className={item.className}>
                        <StyledHeadLabel
                          variant="body2"
                          color="text.third"
                          gutterBottom
                          sx={{ textAlign: "center" }}
                        >
                          {t(item.caption)}
                        </StyledHeadLabel>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {expenseDetail.listExpense &&
                    expenseDetail.listExpense.length &&
                    expenseDetail.listExpense.length > 0 &&
                    expenseDetail.listExpense
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <RowItemExpense
                          key={index}
                          count={
                            expenseDetail.listExpense &&
                            expenseDetail.listExpense.length
                              ? expenseDetail.listExpense.length
                              : 0
                          }
                          data={expenseDetail.listExpense[index]}
                          index={index}
                          open={open}
                        />
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[
                10,
                20,
                30,
                40,
                50,
                { label: t("All"), value: -1 },
              ]}
              component="div"
              count={
                expenseDetail.listExpense && expenseDetail.listExpense.length
                  ? expenseDetail.listExpense.length
                  : 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={`${t("RowsPerPage")}`}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} ${t("OutOf")} ${
                  count !== -1 ? count : `${t("MoreThan")} ${to}`
                }`
              }
            />
          </div>
          <StyledDivider />
          <div>
            <div className="wrap-head">
              <StyledHeadLabel variant="h6">{t("SumaryItem")}</StyledHeadLabel>
            </div>
            <div>
              <div className="wrap-row-sum">
                <Typography color="text.third">
                  {t("TotalTravelDay")}&nbsp;&nbsp;
                </Typography>
                <Typography color="text.third" variant="h6">
                  {utils.numberWithCommas(expenseDetail.sumDays)}
                </Typography>
                <Typography color="text.third">
                  &nbsp;&nbsp;{t("Unit.Days")}
                </Typography>
              </div>
              <div className="wrap-row-sum">
                <Typography color="text.third">
                  {`${t("AllowanceExcessGovernmentRate")} (${t(
                    "TakenIntoAccountForIncomeTax"
                  )})`}
                  &nbsp;&nbsp;
                </Typography>
                <Typography color="text.third" variant="h6">
                  {utils.numberWithCommas(expenseDetail.sumSurplus)}
                </Typography>
                <Typography color="text.third">
                  &nbsp;&nbsp;{t("Baht")}
                </Typography>
              </div>
              <div className="wrap-row-sum">
                <Typography variant="h5">
                  {t("TotalNetAllowance")}&nbsp;&nbsp;
                </Typography>
                <Typography variant="h4">
                  {utils.numberWithCommas(expenseDetail.net)}
                </Typography>
                <Typography variant="h5">&nbsp;&nbsp;{t("Baht")}</Typography>
              </div>
            </div>
          </div>
          <StyledDivider />
          {expenseDetail.remark && (
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">{t("Note")}</StyledHeadLabel>
              </div>
              <Typography
                component="p"
                variant="subtitle1"
                sx={{
                  paddingLeft: 4,
                  marginBottom: 4,
                }}
              >
                &nbsp;&nbsp;{expenseDetail.remark}
              </Typography>
              <StyledDivider />
            </div>
          )}
          {expenseDetail.fileURL && (
            <FileIcon fileURL={expenseDetail.fileURL} />
          )}
          {expenseDetail.comments &&
          expenseDetail.comments.length &&
          expenseDetail.comments.length > 0 ? (
            <div>
              <StyledDivider />
              <Comments comments={expenseDetail.comments} />
            </div>
          ) : (
            <span></span>
          )}
          {expenseDetail.actions &&
          expenseDetail.actions.length &&
          expenseDetail.actions.length > 0 ? (
            <div>
              <StyledDivider />
              <Actions actions={expenseDetail.actions} />
            </div>
          ) : (
            <span></span>
          )}
        </Box>
      ) : (
        <Backdrop open={true} />
      )}
    </div>
  );
};

export default ViewExpensePerDiemPage;
