import * as React from "react";
import { Container, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles"; 
import { Grid, Card, Box } from '@mui/material';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    fontSize: "28px",
    fontWeight: 400,
    display: "flex",
    lineHeight: 1,
    justifyContent: "flex-start", 
    whiteSpace: 'nowrap',
    "@media (max-width: 800px)": {
      fontSize: "20px",
    },

  },
  cardDescription: {
    fontSize: "18px",
    marginTop: "5px",
    fontWeight: 300,
    display: "flex",
    lineHeight: 1.25,
    justifyContent: "flex-start", 
    whiteSpace: "normal",
    paddingRight: "15px",
    "@media (max-width: 800px)": {
      fontSize: "12px",
      whiteSpace: "normal",
    },

  },
  cardPrice: {
    fontSize: "28px",
    fontWeight: 400,
    display: "flex",
    lineHeight: 1,
    justifyContent: "flex-end", 
    whiteSpace: "nowrap",
    textAlign: "right",
    "@media (max-width: 800px)": {
      fontSize: "20px",
      whiteSpace: "nowrap",
      justifyContent: "flex-end", 
    },

  },
  cardUnit: {
    fontSize: "16px",
    fontWeight: 200,
    display: "flex",
    lineHeight: 1,
    justifyContent: "flex-end", 
    whiteSpace: "nowrap",
    "@media (max-width: 800px)": {
      fontSize: "10px",
      whiteSpace: "nowrap",
    },

  },
}));

export default function Additional() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  
  const cardData = [
    {
      title: "KPI/OKRS",
      description: t("kpiDescription"),
      price: "10-20",
      unit: t("pricePerPersonPerMonth")
    },
    {
      title: "Expense",
      description: t("expenseDescription"),
      price: "10-20",
      unit: t("pricePerPersonPerMonth")
    },
    {
      title: "Document",
      description: t("documentDescription"),
      price: (
        <span style={{ whiteSpace: "normal", fontSize: 24 , "@media only screen and (max-width: 800px)": { fontSize: "22px", textAlign: "right" } }}>
          {t("cloudStoragePrice")}
        </span>
      )
      
    },
    {
      title: "Learning Platform",
      description: t("learningPlatformDescription") ,
      price: "Free - 50",
      unit: t("pricePerPersonPerMonth")
    },
    {
      title: "Culture & Engagement",
      description: t("cultureEngagementDescription"),
      price: "Free - 40",
      unit: t("pricePerPersonPerMonth")
    },
    {
      title: "Welfare",
      description: t("welfareDescription"),
      price: "Free-30",
      unit: t("pricePerPersonPerMonth")
    },
    {
      title: "Recruitment",
      description: t("recruitmentDescription"),
      price: (
        <span style={{ whiteSpace: "normal", fontSize: 24 , "@media only screen and (max-width: 800px)": { fontSize: "22px", textAlign: "right" } }}>
          {t("creditBasedPricing")}
        </span>
      )
      
    },
    {
      title: "Talent & Successor",
      description: t("talentSuccessorDescription"),
      price: "Free - 20",
      unit: t("pricePerPersonPerMonth")
    },
    {
      title: "Onboarding/Offboarding",
      description: t("onboardingOffboardingDescription"),
      price: "Free - 10",
      unit: t("pricePerPersonPerMonth")
    },
    {
      title: "AI Chatbot",
      description: t("aiDescription"),
      price: (
        <span style={{ whiteSpace: "normal", fontSize: 24, "@media only screen and (max-width: 800px)": { fontSize: "22px", textAlign: "right" } }}>
          {t("customPrice")}
        </span>
      )
    
    },
  ];
  
  return (
    <Container maxWidth="lg">
      <div style={{ marginBottom: "50px" }}>
        <Typography
          variant="h2"
          align="center"
          sx={{ fontSize: "48px", fontWeight: 450, marginTop: "60px" }}
        >
          {t("additionalSystems")}
        </Typography>
        <Typography
          variant="h6"
          align="center"
          style={{ fontSize: "20px", fontWeight: "200", display: "block", marginTop: "-10px" }}
        >
          {t("approximatePrice")}
        </Typography>
      </div>

      <Grid container spacing={3}>
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Card className="additional-card">
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div>
                  <Typography className={classes.cardTitle}>{card.title}</Typography>
                  <Typography className={classes.cardDescription}>
                    {card.description.split("\n").map((line, i) => (
                      <React.Fragment key={i}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.cardPrice}>{card.price}</Typography>
                  {card.unit && (
                    <Typography className={classes.cardUnit}>{card.unit}</Typography>
                  )}
                </div>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}