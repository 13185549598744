import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Container,
  Grid,
  Typography,
  Box,
  Autocomplete,
  autocompleteClasses,
  Popper,
} from "@mui/material";
import LoadingIcon from "../../assets/social-media.gif";
import { getAffiliateOrganization } from "../../../../actions/company";
import { useTranslation } from "react-i18next";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { getPotentailAssessmentAdmin } from "../../../../actions/potential";
import CardPotentailAssessment from "./component/CardPotentialAssessment";
import { getLevelByidCompany } from "../../../../actions/level";
import ButtonBlue from "../../shared/general/ButtonBlue";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import DatePickerCustom from "../../shared/date/datePicker";
import dayjs from "dayjs";
const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: "5%",
  "& .form-radio-label-type": {
    ["@media only screen and (max-width: 600px)"]: {
      flexBasis: "25%",
      width: "fit-content",
      margin: 0,
    },
    "& .label-radio": {
      ["@media only screen and (max-width: 600px)"]: {
        fontSize: 14,
      },
    },
  },
});
const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledHeadingPage = styled("div")({
  marginBottom: 16,
});
const StyledAutocomplete = styled(Autocomplete)({
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    minHeight: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});
const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});
const AdminPotentialPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [filterYear, setFilterYear] = useState(dayjs());
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const dispatch = useDispatch();
  const {
    isFetching: potentialAssessmentLoading,
    result: potentialAssessments,
  } = useSelector((state) => state.potentialAssessment);

  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );

  useEffect(() => {
    if (selectedCompany) {
      dispatch(
        getPotentailAssessmentAdmin({
          idCompany: selectedCompany.idCompany,
          year: dayjs(filterYear).format("YYYY"),
        })
      );
    }
  }, [selectedCompany,filterYear]);

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (
      userProfile &&
      affiliateOrganizationList &&
      affiliateOrganizationList.length > 0
    ) {
      let ownCompany = affiliateOrganizationList.find(
        (x) => x.idCompany === userProfile.idCompany
      );
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany);
    }
  }, [userProfile, affiliateOrganizationList]);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <StyledHeadingPage>
            <Typography variant="h4" style={{ paddingTop: 8 }}>
              ประเมินศักยภาพ
            </Typography>
          </StyledHeadingPage>
          <ButtonBlue
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() =>
              history.push("/potential-assessment/add-form-potential")
            }
          >
            สร้างแบบประเมินศักยภาพ
          </ButtonBlue>
        </div>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              padding: "24px",
            }}
          >
            <Typography fontSize={18} gutterBottom>
              {`${t("Company")}`}
            </Typography>
            <SelectAffiliateCompany
              options={affiliateOrganizationList}
              value={selectedCompany}
              onChange={(_, value) => {
                setSelectedCompany(value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <StyledBoxSearch>
              <Typography
                color="text.third"
                fontSize="14px"
                fontWeight="600"
                marginBottom="8px"
              >
                {t("SelectYear")}
              </Typography>
              <div className="search-date">
                <DatePickerCustom
                  inputFormat="YYYY"
                  views={['year']}
                  value={filterYear}
                  name="start"
                  openTo="year"
                  onChange={(newValue) => {
                    setFilterYear(newValue);
                  }}
                />
              </div>
            </StyledBoxSearch>
          </Grid>

          {potentialAssessmentLoading && (
            <Grid item xs={12}>
              <Box
                sx={{
                  minHeight: 400,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={LoadingIcon} style={{ height: 80, width: 80 }} />
              </Box>
            </Grid>
          )}

          {potentialAssessments && potentialAssessments.length >= 1 && (
            <Grid item container spacing={3} marginTop={"15px"}>
              {potentialAssessments.map((e, index) => (
                <Grid item key={index} xs={12}>
                  <CardPotentailAssessment potentialAssessment={e} />
                </Grid>
              ))}
            </Grid>
          )}
          {potentialAssessments && potentialAssessments.length <= 0 && (
            <Grid item xs={12}>
              <Box
                style={{
                  minHeight: 400,
                  position: "sticky",
                  left: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>{t("NoData")}</Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </StyledRoot>
  );
};

export default AdminPotentialPage;
