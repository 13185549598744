import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import {
  Table,
  TablePagination,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@mui/material";
import Typography from "@mui/material/Typography";

import ListAltIcon from "@mui/icons-material/ListAlt";
import dayjs from "dayjs";
import utils from "../../../../../utils";
import { getUserFullName } from "../../../../../utils/userData";

const StyledTable = styled(Table)({
  "& .MuiTableCell-root": {
    textAlign: "center",
    "&.nothing": {
      padding: 32,
    },
  },
  "& .tableCellHead": {
    border: "none",
    whiteSpace: "nowrap",
    backgroundColor: "#f3f6f8",
  },
  "& .tableCellHead:first-of-type": {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "& .tableCellHead-document-no": {
    width: 168,
  },
  "& .tableCellHead-document-name": {
    textAlign: "center",
    width: 240,
  },
  "& .tableCellHead-document-amount": {
    width: 160,
  },
  "& .tableCellHead-document-status": {
    width: 140,
  },
  "& .tableCellHead-botton": {
    width: 172,
  },
  "& .tableCellHead:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  "& .tableCellBody-document-name": {
    textAlign: "left",
  },
  "& .tableCellBody-center": {
    textAlign: "center",
    wordBreak: "break-word",
    whiteSpace: "normal",
    maxWidth: "280px",
    minWidth: "156px",
  },
});

const DetailButton = styled(Button)(({ variant, theme }) => ({
  color: "#46cbe2",
  backgroundColor: "transparent",
  border: "solid 2px",
  borderColor: "#46cbe2",
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 4,
  paddingBottom: 4,
  borderRadius: 16,
  "&:hover": {
    color: "#ffffff",
    backgroundColor: "#46cbe2",
  },
}));

const SubTableWithButton = (props) => {
  const { t, i18n } = useTranslation();
  const {
    data,
    textButton,
    iconButton,
    handleClick,
    dialog,
    dialogTitle,
    dialogDescription,
    ...other
  } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <TableContainer>
        <StyledTable aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                className={`tableCellHead tableCellHead-document-status`}
              >
                {t("DocumentNumber")}
              </TableCell>
              <TableCell className={`tableCellHead tableCellHead-document-no`}>
                {t("FullName")}
              </TableCell>
              <TableCell
                className={`tableCellHead tableCellHead-document-name`}
              >
                Cost center
              </TableCell>
              <TableCell
                className={`tableCellHead tableCellHead-document-status`}
              >
                {t("Type")}
              </TableCell>
              <TableCell
                className={`tableCellHead tableCellHead-document-amount`}
              >
                {t("TitleName")}
              </TableCell>

              <TableCell
                className={`tableCellHead tableCellHead-document-status`}
              >
                {t("TotalNetWorth")}
              </TableCell>
              <TableCell
                className={`tableCellHead tableCellHead-document-status`}
              >
                {t("LastModifiedDate")}
              </TableCell>

              <TableCell
                className={`tableCellHead tableCellHead-botton`}
              ></TableCell>
            </TableRow>
          </TableHead>

          {data.length > 0 ? (
            <TableBody>
              {(rowsPerPage > 0
                ? data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : data
              ).map((row) => (
                <TableRow key={row.idExpense}>
                  <TableCell className="tableCellBody-center">
                    {row.documentId}
                    <br></br>
                    <Typography variant="body" color="text.secondary">
                      [
                      {i18n.resolvedLanguage == "th"
                        ? dayjs(row.createDate).format("DD-MM-BBBB")
                        : dayjs(row.createDate).format("DD-MM-YYYY")}
                      ]
                    </Typography>
                  </TableCell>
                  <TableCell className="tableCellBody-center">
                    {getUserFullName(row)}
                  </TableCell>
                  <TableCell className="tableCellBody-center">
                    {row.costCenterCharge}
                  </TableCell>
                  <TableCell className="tableCellBody-center">
                    {t(row.expenseType)}
                  </TableCell>
                  <TableCell className="tableCellBody-center">
                    {row.expenseName}
                  </TableCell>
                  <TableCell className="tableCellBody-center">
                    {utils.numberWithCommas(parseFloat(row.net))} {t("Baht")}
                  </TableCell>
                  <TableCell className="tableCellBody-center">
                    {dayjs(row.lastUpdateDate).format("DD/MM/BBBB")}
                  </TableCell>
                  <TableCell className="tableCellBody-center">
                    <DetailButton
                      startIcon={<ListAltIcon />}
                      onClick={() => {
                        handleClick(
                          row.idExpense,
                          row.expenseType,
                          row.idEmployee
                        );
                      }}
                    >
                      {t("Descriptions")}
                    </DetailButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableCell className="nothing" colSpan={8}>
                {t("NoData")}
              </TableCell>
            </TableBody>
          )}
        </StyledTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[
          10,
          20,
          30,
          40,
          50,
          { label: t("All"), value: -1 },
        ]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={`${t("RowsPerPage")}`}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} ${t("OutOf")} ${
            count !== -1 ? count : `${t("MoreThan")} ${to}`
          }`
        }
      />
    </div>
  );
};

export default SubTableWithButton;
