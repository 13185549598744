import React from "react";
import { Grid, Typography, CardActionArea, Button } from "@mui/material";
import CardStyle from "../../../shared/general/Card";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const CardPotentailAssessment = (props) => {
  const { potentialAssessment } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  return (
    <CardStyle
      style={{
        borderLeft: "10px solid #7d55c2",
      }}
    >
      <CardActionArea
        onClick={() => {
          history.push("/potential-assessment/detail-assessment", {
            potentialAssessment: potentialAssessment,
          });
        }}
      >
        <Grid container padding={"24px"}>
          <Grid item xs={12}>
            <Typography variant="h5" fontWeight={"600"} gutterBottom>
              {potentialAssessment.questionSetName}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              marginBottom: "20px",
            }}
          >
            <Typography variant="body2" gutterBottom color="text.secondary">
              {potentialAssessment.description}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
            }}
          >
            <Typography color="text.secondary" sx={{ marginRight: "15px" }}>
              {t("JobLevel")}:{" "}
            </Typography>
            {potentialAssessment.levels.map((e) => (
              <Typography>{e.levelName}</Typography>
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
            }}
          >
            <Typography color="text.secondary" sx={{ marginRight: "15px" }}>
              {t("Duration")}:{" "}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {dayjs(potentialAssessment.startDate).format(
                i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
              )}{" "}
              -{" "}
              {dayjs(potentialAssessment.endDate).format(
                i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
              )}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
            }}
          >
            <Typography color="text.secondary" sx={{ marginRight: "15px" }}>
              จำนวนคำถาม:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {potentialAssessment.questionLength}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
            }}
          >
            <Typography color="text.secondary" sx={{ marginRight: "15px" }}>
              จำนวผู้ถูกประเมิน:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {potentialAssessment.appraiseeLength}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "black",
                color: "white",
                borderRadius: "10px",
              }}
              onClick={() => {
                history.push("/potential-assessment/detail-assessment", {
                  potentialAssessment: potentialAssessment,
                });
              }}
            >
              ดูรายละเอียด
            </Button>
          </Grid>
        </Grid>
      </CardActionArea>
    </CardStyle>
  );
};

export default CardPotentailAssessment;