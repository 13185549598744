import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Link,
  Grid,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";
import AddIcon from "../../../../assets/add.png";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    minWidth: "600px",
    maxWidth: "600px",
    width: "80%",
  },
}));
const StyledImportFile = styled("div")({
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  marginTop: 24,
  "& .dropzone-upload-file": {
    textAlign: "center",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    height: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& .inner-dropzone": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  },
});

const DialogDropzoneAssessor = ({
  ratioSampleSize,
  open,
  onClose,
  handleOpenAlert,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: employeesList } = useSelector((state) => state.employees);
  const { result: potentialAppraisers } = useSelector(
    (state) => state.potentialAppraiser
  );
  const [disable, setDisable] = useState(false);
  const [files, setFiles] = useState(null);
  const [fileSelected, setFileSelected] = useState(null);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles[0]);
      setFileSelected({
        name: acceptedFiles[0].name,
        size: acceptedFiles[0].size,
      });
    },
  });

  const handleClickDownloadTemplate = async () => {
    const roleOptions = [
      "boss",
      "peer",
      "Project",
      "subordinate",
      "customer",
      "crossfunction",
    ];

    const header = [
      "employeeID",
      "FullName(PersonBeingAssessed)",
      "JobLevel",
      "employeeID 1",
      "Assessor_1",
      "SampleSize_1",
      "employeeID 2",
      "Assessor_2",
      "SampleSize_2",
      "employeeID 3",
      "Assessor_3",
      "SampleSize_3",
    ];

    // สร้าง Workbook และ Worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(t("Potential"));

    // ตั้งค่าความกว้างของคอลัมน์
    worksheet.columns = [
      { width: 20 },
      { width: 40 },
      { width: 15 },
      { width: 20 },
      { width: 40 },
      { width: 15 },
      { width: 20 },
      { width: 40 },
      { width: 15 },
      { width: 20 },
      { width: 40 },
      { width: 15 },
    ];

    // เพิ่ม Header
    const headerRow = worksheet.addRow(header);

    // สไตล์สำหรับ Header
    const headerStyle = {
      font: {
        bold: true,
        size: 18,
        name: "TH SarabunPSK",
        color: { argb: "FFFFFF" },
      },
      alignment: { horizontal: "center", vertical: "middle" },
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "081F5C" } },
      border: {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      },
    };

    // ตั้งค่ารูปแบบ Header
    headerRow.eachCell((cell) => {
      cell.font = headerStyle.font;
      cell.alignment = headerStyle.alignment;
      cell.fill = headerStyle.fill;
      cell.border = headerStyle.border;
    });

    worksheet.getRow(1).height = 50;

    // เพิ่ม Dropdown Validation ให้คอลัมน์ SampleSize
    [6, 10, 14].forEach((colIndex) => {
      for (let rowIndex = 2; rowIndex <= 100; rowIndex++) {
        const cellAddress = `${
          worksheet.getColumn(colIndex).letter
        }${rowIndex}`;
        worksheet.getCell(cellAddress).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: [`"${roleOptions.join(",")}"`], // สร้าง Dropdown
        };
      }
    });

    // เพิ่ม Numeric Validation ให้คอลัมน์ Ratio
    [5, 8, 11].forEach((colIndex) => {
      for (let rowIndex = 2; rowIndex <= 100; rowIndex++) {
        const cellAddress = `${
          worksheet.getColumn(colIndex).letter
        }${rowIndex}`;
        worksheet.getCell(cellAddress).dataValidation = {
          type: "whole",
          operator: "between",
          formulae: [1, 100],
          allowBlank: true,
        };
      }
    });

    // เขียนไฟล์ Excel และดาวน์โหลด
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.download = `template.xlsx`;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleRemoveFile = () => {
    setFiles(null);
    setFileSelected(null);
  };

  useEffect(() => {
    if (open) {
      console.log(ratioSampleSize);
    }
  }, []);

  const handleSubmit = async (event) => {
    setDisable(true);
    // event.preventDefault();  // Prevent default form behavior
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        const processedData = jsonData.map((row) => {
          if (!row["employeeID"]) {
            throw new Error("employeeID of Appraiser can't be null");
          }

          // สร้าง object เริ่มต้น
          const result = {
            employeeID_Appraiser: row["employeeID"],
          };

          // ดึง key ที่เริ่มต้นด้วย "employeeID " สำหรับ Appraisee
          Object.keys(row).forEach((key) => {
            if (key.startsWith("employeeID ")) {
              const dynamicKey = key.replace(
                "employeeID ",
                "employeeID_Appraisee_"
              );
              result[dynamicKey] = row[key];
            }
          });

          // ดึง SampleSize_X
          Object.keys(row).forEach((key) => {
            if (key.startsWith("SampleSize_")) {
              result[key] = row[key];
            }
          });

          return result;
        });
        let roleOptions = new Set();
        ratioSampleSize.ratio.forEach((e) => {
          if (e.ratio > 0) {
            roleOptions.add(e.role);
          }
        });
        processedData.forEach((e) => {
          const appraisers = []; // เก็บข้อมูล appraisers แบบ dynamic
          let selectedRole = new Set();
          // ดึง key ของ Appraisee และ SampleSize ที่เกี่ยวข้อง
          Object.keys(e).forEach((key) => {
            if (key.startsWith("employeeID_Appraisee_")) {
              const index = key.split("_")[2]; // ดึงหมายเลข index เช่น 1, 2, 3
              const employeeID = e[key];

              // หา employee ที่ตรงกับ employeeID
              const emp = employeesList.find(
                (emp) => emp.idEmployees === employeeID
              );
              if (emp) {
                let ratio =
                  ratioSampleSize.ratio.find(
                    (r) =>
                      r.role.toLowerCase() ===
                      e[`SampleSize_${index}`].toLowerCase()
                  ).ratio || null; // หา ratio ที่ตรงกับ role (หรือ null หากไม่พบ)
                if (ratio && ratio > 0) {
                  appraisers.push({
                    idEmployees: emp.idEmployees,
                    firstname_TH: emp.firstname_TH,
                    lastname_TH: emp.lastname_TH,
                    firstname_EN: emp.firstname_EN,
                    lastname_EN: emp.lastname_EN,
                    role: e[`SampleSize_${index}`], // ดึง SampleSize ที่ตรงกับ index
                    ratio: ratio,
                  });
                  selectedRole.add(e[`SampleSize_${index}`]);
                }
              }
            }
          });

          potentialAppraisers.forEach((j) => {
            if (j.idEmployees === e.employeeID_Appraiser) {
              // เพิ่ม appraisers เข้าไป
              j.appraisers = [...j.appraisers, ...appraisers];

              const allRolesComplete = selectedRole.size === roleOptions.size; 
              const allAppraisersValid = j.appraisers.every((a) => a.idEmployees); 

              j.isComplete = allRolesComplete && allAppraisersValid;
            }
          });
        });
        setFiles(null);
        setFileSelected(null);
        setDisable(false);
      } catch (error) {
        console.log(error);
        dispatch(
          openNotificationAlert({
            type: "error",
            message: "เกิดข้อผิดพลาดบางข้อมูล",
          })
        );
        setDisable(false);
      } finally {
        setDisable(false);
        onClose();
      }
    };
    reader.readAsBinaryString(files);
  };

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle>{t("Upload")}</DialogTitle>
      <DialogContent>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12}>
            <Link
              variant="body2"
              download
              onClick={handleClickDownloadTemplate}
            >
              {t("DownloadTemplate")}
            </Link>
          </Grid>
          <Grid item xs={12}>
            <StyledImportFile>
              <div {...getRootProps({ className: "dropzone-upload-file" })}>
                <input {...getInputProps()} />
                <div className="inner-dropzone">
                  {fileSelected ? (
                    <>
                      <Typography variant="h6">{fileSelected.name}</Typography>
                      <IconButton color="error" onClick={handleRemoveFile}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <img alt="AddIcon" src={AddIcon} width="80" />
                      <Typography
                        style={{
                          marginTop: 8,
                          backgroundColor: "transparent",
                        }}
                        variant="body2"
                        color="text.secondary"
                      >
                        {t("DragDropOrClickSelectFile")}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        (xlsx)
                      </Typography>
                    </>
                  )}
                </div>
              </div>
            </StyledImportFile>
          </Grid>
          <Grid item>
            <Typography
              style={{ color: "#d3302f", margin: "8px 0" }}
              variant="subtitle1"
            >
              * กรุณาอัพโหลดไฟล์ตามแบบฟอร์ม
            </Typography>
          </Grid>
        </Grid>
        <DialogActions>
          <Button type="button" color="error" onClick={onClose}>
            {`${t("Cancel")}`}
          </Button>
          <ButtonBlue
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={fileSelected === null || disable}
          >
            {`${t("Confirm")}`}
          </ButtonBlue>
        </DialogActions>
      </DialogContent>
    </CustomDialog>
  );
};

export default DialogDropzoneAssessor;
