import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Typography,
  Container,
  styled,
  Card,
  Grid,
  InputAdornment,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
  LinearProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getKpiIsSuccessfulManager } from "../../../../../actions/kpi";
import { useHistory } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useTranslation } from "react-i18next";
import { getAllEmployees } from "../../../../../actions/employee";
import DialogDropzone from "../DialogDropzoneKpi";
import AlertResponse from "../../../shared/general/AlertResponse";
import ClearIcon from "@mui/icons-material/Clear";
import dayjs from "dayjs";
import "dayjs/locale/th";
import ExcelJS from 'exceljs';
import EmployeeSearch from "../SearchManager"; 
import { getUserFirstName, getUserFullName,getUserLastName,getUserPosition } from "../../../../../utils/userData";


dayjs.locale("th");

const GridStyled = styled(Grid)({
  backgroundColor: "#ffffff",
  padding: "16px",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "16px",
  marginTop: "-10px",
  display: "flex",
  alignItems: "center",
});

const ProgressBar = styled(LinearProgress)({
  height: 10,
  borderRadius: 5,
  backgroundColor: "#DCFAF8",
  "& .MuiLinearProgress-bar": {
    borderRadius: 5,
    backgroundColor: "#16DBCC",
  },
});

const KpiIsSuccessful = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { result: kpiPlanStore } = useSelector((state) => state.kpiIsSuccessful); 
  
  const [alignment, setAlignment] = useState("plan");
  const [dataKpi, setDataKpi] = useState([]);
  const [openDialog, setDialog] = useState(false);
  const [openAlert, setOpenAlert] = useState({
    open: false,
    alertType: null,
    label: null,
  });
  const [selectedStatus, setSelectedStatus] = useState("all");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    setSelectedStatus("all");
  };

  const handleCloseDialogDropzoneKpi = () => {
    setDialog(false);
  };

  const handleOpenAlert = (alertType, label) => {
    setOpenAlert({
      open: true,
      alertType: alertType,
      label: label,
    });
  };

  const handleCloseAlert = () => {
    setOpenAlert({
      open: false,
      alertType: null,
      label: null,
    });
  };

  const getUniqueValues = (key) => {
    return [...new Set(dataKpi ? dataKpi.map((item) => item[key] || "") : [])];
  };

  useEffect(() => {
    dispatch(getKpiIsSuccessfulManager());
    dispatch(getAllEmployees());
  }, [dispatch]);

  useEffect(() => {
    if (kpiPlanStore) {
      const temp = [...kpiPlanStore].sort((a, b) => b.totalWeight - a.totalWeight)
      setDataKpi(temp);
    }
  }, [kpiPlanStore]);

  const handleSearch = (searchParams) => {
    const filtered = kpiPlanStore.filter((data) => {
      const {
        name,
        company,
        businessUnit,
        division,
        department,
        section,
        position,
        manager,
      } = searchParams;

      const matchesName = !name || (getUserFirstName(data) && getUserFirstName(data).toLowerCase().includes(name.toLowerCase())) || (getUserLastName(data) && getUserLastName(data).toLowerCase().includes(name.toLowerCase()));
      const matchesCompany = !company || company.idCompany === "all" || (data.companyName && data.companyName === company.companyName);
      const matchesBusinessUnit = !businessUnit || businessUnit.idBusinessUnit === "all" || (data.businessUnitName && data.businessUnitName === businessUnit.businessUnitName);
      const matchesDivision = !division || division.idDivision === "all" || (data.divisionName && data.divisionName === division.divisionName);
      const matchesDepartment = !department || department.idDepartment === "all" || (data.departmentName && data.departmentName === department.departmentName);
      const matchesSection = !section || section.idSection === "all" || (data.sectionName && data.sectionName === section.sectionName);
      const matchesPosition = !position || position.idPosition === "all" || (data.positionName && data.positionName === position.positionName);
      const matchesManager =
        !manager ||
        manager.idEmployees === "all" ||
        (data.manager1Firstname && data.manager1Firstname.includes(manager.firstname_TH)) ||
        (data.manager2Firstname && data.manager2Firstname.includes(manager.firstname_TH));
      
      const score =
        alignment === "plan"
          ? data.totalWeight
          : alignment === "progress"
          ? data.percentProgress
          : data.successRate;

      const matchesStatus =
        selectedStatus === "all" ||
        (selectedStatus === 1 && score === 100) || 
        (selectedStatus === 0 && score < 100);

      return (
        matchesName &&
        matchesCompany &&
        matchesBusinessUnit &&
        matchesDivision &&
        matchesDepartment &&
        matchesSection &&
        matchesPosition &&
        matchesManager &&
        matchesStatus
      );
    });

    const sorted = filtered.sort((a, b) =>
      alignment === "plan"
        ? b.totalWeight - a.totalWeight
        : alignment === "progress"
        ? b.percentProgress - a.percentProgress
        : b.successRate - a.successRate
    );

    setDataKpi(filtered);
  };

  const handleExport = async () => {
    const header = [
      t("EmployeeID"), t("FirstName"), t("LastName"), t("Position"), t("Section"), t("KPIName"), 
      t("Weight"), t("Status"), t("Assessor"), t("EmployeeEvaluation"), t("EmployeeScore"), 
      t("ManagerEvaluation"), t("ManagerScore"), t("Duration")
    ];

    const dataToExport = kpiPlanStore.flatMap(employee => 
      employee.kpis.map(kpi => ({
        "รหัสพนักงาน": employee.idEmployees,
        "ชื่อ": getUserFirstName(employee),
        "นามสกุล": getUserLastName(employee),
        "ตำแหน่ง": getUserPosition(employee),
        "แผนก": employee.sectionName,
        "ชื่อ KPI": kpi.kpiName,
        "น้ำหนัก": kpi.weight,
        "สถานะแผนงาน": kpi.isPlan,
        "ผู้ประเมิน": `${kpi.assessorFirstname} ${kpi.assessorLastname}`, 
        "พนักงานประเมิน": kpi.employeeEvaluate === 1 ? t("Success") : kpi.employeeEvaluate === 0 ? t("Failure") : "",
        "คะแนนของพนักงาน": kpi.employeeRaitng,
        "หัวหน้าประเมิน": kpi.managerEvaluate === 1 ? t("Success") : kpi.managerEvaluate === 0 ? t("Failure") : "",
        "คะแนนของหัวหน้า": kpi.managerRating,
        "ระยะเวลา": `${dayjs(kpi.startDate).format('DD/MM/YYYY')} - ${dayjs(kpi.endDate).format('DD/MM/YYYY')}`
      }))
    );

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(t("KPIData"));
  
    const colWidths = [
        { width: 15 }, { width: 30 }, { width: 30 }, { width: 20 }, 
        { width: 20 }, { width: 40 }, { width: 10 }, { width: 15 }, { width: 40 }, 
        { width: 15 }, { width: 20 }, { width: 15 }, { width: 20 }, { width: 30 }
    ];

    worksheet.columns = colWidths.map(col => ({ width: col.width }));

    const headerRow = worksheet.addRow(header);

    const headerStyle = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '081F5C' }
        },
        border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        }
    };

    headerRow.eachCell((cell) => {
        cell.font = headerStyle.font;
        cell.alignment = headerStyle.alignment;
        cell.fill = headerStyle.fill;
        cell.border = headerStyle.border;
    });

    worksheet.getRow(1).height = 50;

    const bodyStyle = {
        font: { size: 18, name: 'TH SarabunPSK' },
        border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        }
    };

    dataToExport.forEach(item => {
        const row = [
            item["รหัสพนักงาน"],
            item["ชื่อ"],
            item["นามสกุล"],
            item["ตำแหน่ง"],
            item["แผนก"],
            item["ชื่อ KPI"],
            item["น้ำหนัก"],
            item["สถานะแผนงาน"],
            item["ผู้ประเมิน"],
            item["พนักงานประเมิน"],
            item["คะแนนของพนักงาน"],
            item["หัวหน้าประเมิน"],
            item["คะแนนของหัวหน้า"],
            item["ระยะเวลา"]
        ];
        const excelRow = worksheet.addRow(row);

        for (let i = 1; i <= header.length; i++) {
            const cell = excelRow.getCell(i);
            cell.font = bodyStyle.font;
            cell.border = bodyStyle.border;

            if (i === 8) { 
                switch (cell.value) {
                    case 0:
                        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF6969' } }; 
                        cell.value = t("NotApproved");
                        break;
                    case 1:
                        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '50B498' } };
                        cell.value = t("Approved");
                        break;
                    case 2:
                        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '83B4FF' } };
                        cell.value = t("PendingApproval");
                        break;
                    case 3:
                        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFDE4D' } };
                        cell.value = t("Unfinished");
                        break;
                    default:
                        cell.value = "";
                }
            }

            if (!cell.value) {  
                cell.value = "";  
            }
        }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.download = `${t("StatusEmployees")}.xlsx`;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
};

  return (
    <div className="page">
      <Container sx={{ paddingTop: "30px" }}>
        <GridStyled container spacing={2}>
          <Grid item xs={6} sm={8}>
            <Box display="flex" alignItems="center" sx={{ flexGrow: 1 }}>
              <Typography variant="h6" className="kpi" sx={{ ml: 1, fontSize: "30px" }}>
                {t("KPIStatusList")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          <ButtonBlue
              className="download"
              variant="outlined"
              aria-label="download"
              onClick={handleExport}  
              startIcon={<FileDownloadIcon />}
            >
              {t("DownloadData")}
            </ButtonBlue>
          </Grid>
          <Grid item xs={12} marginBottom={"5px"} marginTop={"1px"}>
            <EmployeeSearch
              onSearch={handleSearch}
              businessUnits={getUniqueValues("businessUnitName")}
              divisions={getUniqueValues("divisionName")}
              departments={getUniqueValues("departmentName")}
              sections={getUniqueValues("sectionName")}
              positions={getUniqueValues("positionName")}
            />
          </Grid>
          <Grid item xs={12}>
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
            >
              <ToggleButton value="plan">{t("KPIGoalPlan")}</ToggleButton>
              <ToggleButton value="planning">{t("KpiPlanning")}</ToggleButton>
              <ToggleButton value="progress">{t("UpdateProgress")}</ToggleButton>
              <ToggleButton value="assessment">{t("assessment")}</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </GridStyled>
        <Box sx={{ overflowY: 'auto', paddingRight: '16px', marginTop: '30px' , marginBottom: '30px'}}>
          {dataKpi.length > 0 ? (
            <Grid container spacing={2}>
              {dataKpi.map((row) => {
                const score =
                  alignment === "plan"
                    ? row.totalWeight
                    : alignment === "progress"
                    ? row.percentProgress
                    : alignment === "assessment"
                    ? row.successRate
                    : row.totalUpdatePlan;
                return (
                  <Grid item xs={12} sm={6} md={4} key={row.idEmployees}>
                    <Card
                      sx={{
                        backgroundColor: "#ffffff",
                        padding: "16px",
                        borderRadius: "12px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        cursor: "pointer",
                        "&:hover": {
                          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                        },
                      }}
                      onClick={() => history.push({
                        pathname: '/kpi-plan-update',
                        state: { data: row }
                      })}                      
                    >
                      <Box display="flex" alignItems="center" gap="16px">
                        <Avatar
                          sx={{
                            width: 62,
                            height: 62,
                            "& img": { objectFit: "contain" },
                          }}
                          src={row.imageURL}
                        />
                        <Box>
                          <Typography variant="h6">{`${getUserFullName(row)}`}</Typography>
                          <Typography variant="body2" color="textSecondary">{getUserPosition(row)}</Typography>
                          <Typography variant="body2" color="textSecondary">{row.sectionName}</Typography>
                        </Box>
                      </Box>
                      <Grid container spacing={1} marginTop={2}>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              fontSize={14}
                              color={score === 100 ? "success" : "error"}
                              gutterBottom
                            >
                              {score === 100
                                ? t("Completed")
                                : t("InCompleted")}
                            </Typography>
                            <Typography textAlign={"end"} gutterBottom>
                              {`${score}%`}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box sx={{ mb: 1 }}>
                            <ProgressBar
                              variant="determinate"
                              value={score}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Typography variant="h6" align="center" color="text.secondary">
              {t("NoData")}
            </Typography>
          )}
        </Box>

        {openDialog && (
          <DialogDropzone
            open={openDialog}
            onClose={handleCloseDialogDropzoneKpi}
            handleOpenAlert={handleOpenAlert}
          />
        )}
        <AlertResponse
          open={openAlert.open}
          handleClose={handleCloseAlert}
          alertType={openAlert.alertType}
          label={openAlert.label}
        />
      </Container>
    </div>
  );
};

export default KpiIsSuccessful;
