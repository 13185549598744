import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import {
    TextField,
    FormControl,
    Grid,
    Box,
    Typography,
    Popper,
    Stack,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import AlertResponse from "../../../shared/general/AlertResponse";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import {
    deleteCostElement,
    postCostElement,
    putCostElement,
} from "../../../../../actions/costElement";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import DialogConfirmDelete from "../../../shared/general/DialogConfirmDelete";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center",
    },
});

const StyledBoxSearch = styled(Box)({
    marginTop: 4,
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        [`& .${autocompleteClasses.option}`]: {
            "&:hover": {
                backgroundColor: "#f6f7f8",
                margin: "0 8px",
                borderRadius: 8,
                paddingLeft: 8,
            },
        },
    },
});

function Setting(props) {
    const { t, i18n } = useTranslation();
    const { open, typeDialog, handleClose, data, selectedCompany, costElementList } = props;
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [doDelete, setDoDelete] = useState(false);

    useEffect(() => {
        if (typeDialog === "Add") {
            setFormData({
                idPayrollType: data.idPayrollType || null,
                costElementName: "",
                isActive: 1,
                idCompany: selectedCompany,
            });
        } else if (typeDialog === "Edit") {
            setFormData({
                idCostElement: data.idCostElement,
                costElementName: data.costElementName,
                isActive: data.isActive,
                idPayrollType: data.idPayrollType,
                idCompany: data.idCompany,
            });
        }
    }, [typeDialog, data, selectedCompany]);

    const handleSubmit = async () => {
        if (!formData || !formData.costElementName || !formData.idPayrollType) {
            setOpenAlert(true);
            setAlertType(t("PleaseFillInRequiredInformations"));
            return;
        }

        try {
            const action =
                typeDialog === "Add"
                    ? postCostElement(formData)
                    : putCostElement(formData.idCostElement, formData);
            const result = await dispatch(action);
            if (result.status === 200) {
                handleClose();
            } else {
                setOpenAlert(true);
                setAlertType(t("AnErrorOccurred"));
            }
        } catch (error) {
            setOpenAlert(true);
            setAlertType("error");
        }
    };

    const handleDelete = async () => {
        try {
            const response = await deleteCostElement(data.idCostElement);
            if (response.status === 200) {
                handleClose();
            } else {
                setOpenAlert(true);
                setAlertType(t("Unable to delete"));
            }
        } catch (error) {
            setOpenAlert(true);
            setAlertType(t("Error deleting data"));
        }
    };

    const handlePayrollTypeChange = (event, newValue) => {
        setFormData((prev) => ({
            ...prev,
            idPayrollType: newValue ? newValue.idPayrollType : null,
        }));
    };

    const RenderTitle = () => {
        return `${typeDialog === "Edit" ? t("Edit") : t("Add")} ${t("Cost Element")}`;
    };

    return (
        <DrawerCustom title={RenderTitle()} anchor="right" open={open} onClose={handleClose}>
            <StyledRoot>
                {formData && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography fontSize="14px"
                                fontWeight="600"
                                marginBottom="8px"
                            >
                                {i18n.resolvedLanguage === "th"
                                    ? data.name_TH || "-"
                                    : data.name_EN || "-"}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{t("CostElement")}</Typography>
                            <TextField
                                value={formData.costElementName || ""}
                                onChange={(e) =>
                                    setFormData((prev) => ({
                                        ...prev,
                                        costElementName: e.target.value,
                                    }))
                                }
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{t("Status")}</Typography>
                            <RadioGroup
                                row
                                value={formData.isActive}
                                onChange={(e) =>
                                    setFormData((prev) => ({
                                        ...prev,
                                        isActive: parseInt(e.target.value, 10),
                                    }))
                                }
                            >
                                <FormControlLabel value={1} control={<Radio />} label={t("Active")} />
                                <FormControlLabel value={0} control={<Radio />} label={t("Inactive")} />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                )}

                <Box>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" justifyContent="space-between">
                            <ButtonBlue variant="contained" onClick={handleSubmit}>
                                {t("Save")}
                            </ButtonBlue>
                            <ButtonBlue className="cancel" onClick={handleClose}>
                                {t("Cancel")}
                            </ButtonBlue>
                        </Stack>
                        {typeDialog === "Edit" && (
                            <ButtonBlue onClick={() => setDoDelete(true)} color="error">
                                {t("Delete")}
                            </ButtonBlue>
                        )}
                    </Stack>
                </Box>
            </StyledRoot>

            <AlertResponse open={openAlert} handleClose={() => setOpenAlert(false)} alertType={alertType} />
            {doDelete && (
                <DialogConfirmDelete
                    open={doDelete}
                    handleClose={() => setDoDelete(false)}
                    handleDelete={handleDelete}
                />
            )}
        </DrawerCustom>
    );
}

export default Setting;
