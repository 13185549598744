import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Radar } from "react-chartjs-2";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import {
  Grid,
  Avatar,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Fab,
  Box,
  LinearProgress,
  IconButton,
  Divider,
  Stack
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GradeIcon from "@mui/icons-material/Grade";
import GroupsIcon from "@mui/icons-material/Groups";
import GroupIcon from "@mui/icons-material/Group";
import WorkIcon from "@mui/icons-material/Work";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import ClassIcon from "@mui/icons-material/Class";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import SearchIcon from "@mui/icons-material/Search";
import CircleIcon from "@mui/icons-material/Circle";
import ApexCharts from "apexcharts";

import { getAllWork } from "../../../../actions/work";
import CardStyle from "../../shared/general/Card";
import { getEmployeeProfileList } from "../../../../actions/employee";
import { useTranslation } from "react-i18next";
import { personalityDISCTypes } from "../../user/personalityCharacterTest/DISC1/type";
import { personalityBusinessTypes } from "../../user/personalityCharacterTest/DISC2/type";
import dayjs from "dayjs";
import utils from "../../../../utils";
import { CareerPlanCard } from "./CareerPlanCard";
import { getUserCompany, getUserDepartment, getUserDivision, getUserFullName, getUserPosition, getUserSection } from "../../../../utils/userData";

const useStyles = makeStyles(() => ({
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  bigAvatar: {
    width: 200,
    height: 200,
  },
  text: {
    fontSize: 17,
  },
  textLine: {
    display: "flex",
    alignItems: "center",
    padding: 10,
  },
  textMainData: {
    fontSize: 18,
    fontWeight: "bold",
  },
  textName: {
    fontSize: 25,
    fontWeight: "bold",
    marginTop: 10,
    color: "#007afc",
  },
  space: {
    marginTop: 10,
    marginBottom: 10,
  },
  nameAttribute: {
    fontSize: 23,
    fontWeight: "bold",
    marginLeft: 10,
  },
  attribute: {
    fontSize: 18,
    marginTop: 50,
    marginBottom: 10,
  },
  tableText: {
    fontSize: 18,
  },
  learningName: {
    fontSize: 20,
    fontWeight: "bold",
  },
  avatarCer: {
    minWidth: 700,
    minHeight: 500,
    marginBottom: 20,
    marginTop: 20,
  },
  // textPerform: {
  //   textAlign: "center",
  //   padding: 60,
  //   margin: 20,
  //   fontSize: 75,
  //   fontWeight: "bold",
  //   color: "#007afc",
  // },
  textPerform: {
    textAlign: "center",
    padding: 40,
    margin: 20,
    fontSize: 25,
    fontWeight: "bold",
    color: "#007afc",
  },
  iconBeforeText: {
    marginRight: 7,
    fontSize: 22,
  },
  topicInJob: {
    fontSize: 18,
    fontWeight: "bold",
  },
  accordion: {
    marginBottom: 15,
    borderRadius: 10,
    "&.MuiAccordion-root:before": {
      backgroundColor: "transparent",
    },
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          sx={{
            backgroundColor: "gray",
            "& .MuiLinearProgress-barColorPrimary": {
              backgroundColor: "#DB4178",
            },
            borderRadius: "10px",
            height: 10,
          }}
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const OpenCertificate = ({ open, close, data }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  return (
    <Dialog onClose={close} open={open} maxWidth="false" style={{ margin: 20 }}>
      <DialogTitle
        style={{
          textAlign: "center",
          fontSize: 30,
          fontWeight: "bold",
          color: "#DB4178",
        }}
      >
        {t("certificate")}
      </DialogTitle>
      <DialogContent>
        <div>
          <Avatar
            alt="Remy Sharp"
            src={data}
            variant={"square"}
            className={classes.avatarCer}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ margin: 20, marginTop: 0, backgroundColor: "#DB4178" }}
          onClick={close}
          variant="contained"
        >
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const NormalData = ({ name, value }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <Typography
          className={classes.text}
          style={{ marginTop: 10, color: "#757575" }}
        >
          {name}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Typography className={classes.textMainData}>{value}</Typography>
      </Grid>
    </Grid>
  );
};

const DataInJob = ({ name, value, icon }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <Typography
          className={classes.text}
          style={{
            marginTop: 20,
            color: "#757575",
            display: "flex",
            alignItems: "center",
          }}
        >
          {name}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Typography className={classes.textMainData}>{value}</Typography>
      </Grid>
    </Grid>
  );
};

const ShowInformation = ({ id, idCompany }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [certificate, setCertificate] = useState(null);

  const personalityTypes1 = personalityDISCTypes;
  const personalityTypes2 = personalityBusinessTypes;

  const presentDay = new Date();
  const CURRENT_YEAR_DISPLAY = dayjs().get("month") >= 11 ?
    dayjs().get("year") :
    dayjs().subtract(1, "year").get("year");

  useEffect(() => {
    dispatch(getAllWork());
    setTimeout(() => {
      renderCharts();
    }, 100);
  }, []);

  const renderCharts = () => {
    const optionsCultureFlt = {
      chart: {
        height: 350,
        type: "radialBar",
      },
      series: [70],
      labels: ["Culture Flt"],
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              color: "#DB4178",
              fontSize: "30px",
              offsetY: 10,
            },
          },
        },
      },
      colors: ["#DB4178"],
    };

    const optionsLeadership = {
      chart: {
        height: 350,
        type: "radialBar",
      },
      series: [87],
      labels: ["Leadership"],
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              color: "#DB4178",
              fontSize: "30px",
              offsetY: 10,
            },
          },
        },
      },
      colors: ["#DB4178"],
    };

    const optionsTalentScore = {
      chart: {
        height: 350,
        type: "radialBar",
      },
      series: [47],
      labels: ["Talent Score"],
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              color: "#DB4178",
              fontSize: "30px",
              offsetY: 10,
            },
          },
        },
      },
      colors: ["#DB4178"],
    };

    setTimeout(() => {
      renderChart("#chartCultureFlt", optionsCultureFlt);
      renderChart("#chartLeadership", optionsLeadership);
      renderChart("#chartTalentScore", optionsTalentScore);
    }, 0);
  };

  const renderChart = (selector, options) => {
    const element = document.querySelector(selector);
    if (!element) {
      console.error(`Element with selector '${selector}' not found.`);
      return;
    }

    const chart = new ApexCharts(element, options);
    chart.render();
  };

  const chartDataCompetency = (data) => {
    return {
      labels: data.map(item => item.competencyName),
      datasets: [
        {
          label: 'Rate Level',
          data: data.map(item => item.approveLevel * 20),
          backgroundColor: "rgba(219, 65, 120, 0.5)",
          borderColor: "#DB4178",
          borderWidth: 1,
        },
      ],
    }
  };

  const plugin = {
    beforeDraw: (chart) => {
      const { ctx, scales, config } = chart;
      const { xCenter, yCenter, drawingArea: radius } = scales.r;
      ctx.save();
      ctx.arc(
        Math.abs(xCenter),
        Math.abs(yCenter),
        Math.abs(radius),
        0,
        Math.abs(Math.PI * 2)
      );
      ctx.fillStyle = "rgba(238, 238, 238,0.1)";
      ctx.fill();
      ctx.restore();
    },
  };

  const option = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        suggestedMin: 1,
        suggestedMax: 20,
        pointLabels: {
          font: { size: 16 },
        },
        ticks: {
          maxTicksLimit: 4,
          display: false,
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
    },
  };

  const fetchedData = async (idCompany, idEmp) => {
    try {
      const response = await getEmployeeProfileList(idCompany, idEmp);
      if (response && response.data && response.data.length > 0) {
        setProfileData(response.data[0]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (idCompany && id) {
      fetchedData(idCompany, id);
    }
  }, [idCompany, id]);

  const formatSalary = (salary) => {
    if (salary === undefined || salary === null) return "-";
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(salary);
  };

  return (
    <div>
      <Typography
        variant="h4"
        style={{ fontWeight: 500, marginBottom: "20px" }}
        gutterBottom
      >
        {t("EmployeeProfile")}
      </Typography>
      {profileData &&
        <div>
          <CardStyle
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/Information/BG.svg)`,
            }}
          >
            <div style={{ padding: "40px" }}>
              <Grid container spacing={4}>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={12}
                  xs={12}
                  style={{
                    padding: 20,
                    textAlign: "-webkit-center",
                  }}
                >
                  <Avatar
                    alt="each person"
                    src={profileData.imageProfile}
                    className={classes.bigAvatar}
                  />
                </Grid>
                <Grid
                  item
                  xl={8}
                  lg={8}
                  md={11}
                  xs={12}
                  style={{ padding: 20, marginTop: "60px" }}
                >
                  <Typography style={{ fontSize: "30px", fontWeight: 600 }}>
                    {getUserFullName(profileData)}
                  </Typography>
                  <Typography className={classes.text}>
                    {getUserPosition(profileData)}
                  </Typography>
                </Grid>

                <Grid container style={{ marginLeft: "100px" }}>
                  <Grid item xs={12}>
                    <Divider
                      style={{
                        backgroundColor: "white",
                        height: "2px",
                        marginBottom: "20px",
                      }}
                    />
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} xs={12}>
                    <NormalData name={t("EmployeeID")} value={profileData.employeeID || "-"} />
                    <NormalData name={t("Division")} value={getUserDivision(profileData) || "-"} />
                    <NormalData name={t("JobGroup")} value={profileData.job && profileData.job
                      .filter(date =>
                        dayjs(date.start).isSameOrBefore(presentDay, 'day') &&
                        (dayjs(date.end).isSameOrAfter(presentDay, 'day') || !date.end)
                      )
                      .map(item => item.jobGroupName)} />
                    <NormalData name={t("personalLevel")} value={profileData.levelName} />
                    <NormalData name={t("ESY")} value={profileData.hiringDate
                      ? `${utils.getAgeMonthYear(t, dayjs(profileData.hiringDate).format("DD/MM/YYYY"), i18n.resolvedLanguage)}`
                      : "-"} />
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} xs={12}>
                    <NormalData name={t("WorkingType")} value={profileData.workingType || "-"} />
                    <NormalData name={t("Department")} value={getUserDepartment(profileData) || "-"} />
                    <NormalData name={t("Position")} value={getUserPosition(profileData) || "-"} />
                    <NormalData name={t("TIG")} value={
                      profileData.employeePersonnelLevelStart
                        ? `${utils.getAgeMonthYear(t, dayjs(profileData.employeePersonnelLevelStart).format("DD/MM/YYYY"), i18n.resolvedLanguage)}`
                        : "-"}
                    />

                    <NormalData name={t("ServiceYear")} value={profileData.hiringDate
                      ? `${utils.getAgeMonthYear(t, dayjs(profileData.hiringDate).format("DD/MM/YYYY"), i18n.resolvedLanguage)}`
                      : "-"}
                    />
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} xs={12}>
                    <NormalData name={t("Company")} value={getUserCompany(profileData) || "-"} />
                    <NormalData name={t("Section")} value={getUserSection(profileData) || "-"} />
                    <NormalData name={t("reportTo")} value={profileData.report || "-"} />
                    <NormalData name={t("TIP")} value={profileData.employeePositionStart
                      ? `${utils.getAgeMonthYear(t, dayjs(profileData.employeePositionStart).format("DD/MM/YYYY"), i18n.resolvedLanguage)}`
                      : "-"} />
                    <NormalData name={t("Age")} value={profileData.employeePositionStart
                      ? `${utils.getAgeMonthYear(t, dayjs(profileData.birthday).format("DD/MM/YYYY"), i18n.resolvedLanguage)}`
                      : "-"} />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </CardStyle>
          <br />

          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.nameAttribute}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/background/Information/Job.svg`}
                    style={{ width: "30%", marginRight: "10px" }}
                  />
                  <Typography fontSize={20} fontWeight={"bold"}>{t("certificate")}</Typography>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ marginLeft: 20, paddingBottom: 50 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Divider
                    style={{
                      backgroundColor: "#F4E3EC",
                      borderColor: "#F4E3EC",
                      height: "2px",
                      marginBottom: "20px",
                    }}
                  />
                </Grid>

                {profileData && profileData.job && profileData.job
                  .filter(
                    date => dayjs(date.start).format("YYYY-MM-DD") <= dayjs(presentDay).format("YYYY-MM-DD") &&
                      (dayjs(date.end).format("YYYY-MM-DD") >= dayjs(presentDay).format("YYYY-MM-DD") ||
                        !date.end)
                  )
                  .map((job, index) => (
                    <Grid container rowSpacing={2} sx={{ ml: 10, p: 1, mt: index > 0 ? 1 : 0 }}>
                      <Grid item xs={6}>
                        <Stack direction={"row"} spacing={1}>
                          <Typography fontWeight={600}>{t("JobPosition")}:</Typography>
                          <Typography> {getUserPosition(job) || "-"}</Typography>
                        </Stack>
                      </Grid>

                      <Grid item xs={6}>
                        <Stack direction={"row"} spacing={1}>
                          <Typography fontWeight={600}>{t("JobGroup")}:</Typography>
                          <Typography>{job.jobGroupName || "-"}</Typography>
                        </Stack>
                      </Grid>

                      <Grid item xs={6}>
                        <Stack direction={"row"} spacing={1}>
                          <Typography fontWeight={600}>{t("JobLevel")}:</Typography>
                          <Typography> {job.jobLevelName || "-"}</Typography>
                        </Stack>
                      </Grid>

                      <Grid item xs={6}>
                        <Stack direction={"row"} spacing={1}>
                          <Typography fontWeight={600}>{t("Salary")}:</Typography>
                          <Typography> {formatSalary(job.salary)}</Typography>
                        </Stack>
                      </Grid>

                      <Grid item xs={6}>
                        <Stack direction={"row"} spacing={1}>
                          <Typography fontWeight={600}>{t("minimumSalary") + " - " + t("maximumSalary")}:</Typography>
                          <Typography>{job.min_salary && job.max_salary ? formatSalary(job.min_salary) + " - " + formatSalary(job.max_salary) : t("NoData")}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.nameAttribute}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/background/Information/Education.svg`}
                    style={{ width: "18%", marginRight: "10px" }}
                  />
                  <Typography fontSize={20} fontWeight={"bold"}>{t("Education")}</Typography>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ marginLeft: 20, paddingBottom: 50 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Divider
                    style={{
                      backgroundColor: "#F4E3EC",
                      borderColor: "#F4E3EC",
                      height: "2px",
                      marginBottom: "20px",
                    }}
                  />
                </Grid>
                {profileData.education.map((edu, index) => (
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={12}
                    xs={12}
                    key={`${edu.degree} ${edu.major}`}
                  >
                    <Grid container>
                      <Grid item xl={8} lg={8} md={6} xs={6}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <CircleIcon
                            style={{ fontSize: "small", color: "#DB4178" }}
                          />
                          <Typography
                            style={{
                              fontSize: 19,
                              fontWeight: "bold",
                              color: "#DB4178",
                              marginLeft: "5px",
                            }}
                          >
                            {edu.degree}
                          </Typography>
                        </Box>

                        <Typography
                          className={classes.text}
                          style={{
                            fontSize: 17,
                            fontWeight: 600,
                            marginLeft: "15px",
                          }}
                        >
                          {edu.fromYear} - {edu.endYear}
                        </Typography>

                        <Typography
                          className={classes.text}
                          style={{
                            fontSize: 16,
                            marginLeft: "15px",
                            fontWeight: 600,
                          }}
                        >
                          {edu.major} | {edu.faculty}
                        </Typography>
                        <Typography
                          className={classes.text}
                          style={{ fontSize: 16, marginLeft: "15px" }}
                        >
                          {edu.university}
                          {/* , {profileData.country} */}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.nameAttribute}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/background/Information/ExperienceProfile.svg`}
                    style={{ width: "12%", marginRight: "10px" }}
                  />
                  <Typography fontSize={20} fontWeight={"bold"}>{t("experienceProfile")}</Typography>
                </div>
              </Typography>
            </AccordionSummary >
            <AccordionDetails style={{ marginLeft: 20, paddingBottom: 50 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Divider
                    style={{
                      backgroundColor: "#F4E3EC",
                      borderColor: "#F4E3EC",
                      height: "2px",
                      marginBottom: "20px",
                    }}
                  />
                </Grid>
                {profileData.experience.map((exp, index) => (
                  <Grid item xs={12} key={index}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <CircleIcon
                        style={{ fontSize: "small", color: "#DB4178" }}
                      />
                      <Typography
                        style={{
                          fontSize: 19,
                          fontWeight: "bold",
                          color: "#DB4178",
                          marginLeft: "5px",
                        }}
                      >
                        {exp.date}
                      </Typography>
                    </Box>

                    <Typography
                      className={classes.text}
                      style={{
                        fontSize: 17,
                        fontWeight: 600,
                        marginLeft: "15px",
                      }}
                    >
                      {getUserPosition(exp)}
                      {/* | {exp.departmentName} */}
                    </Typography>

                    <Typography
                      className={classes.text}
                      style={{
                        fontSize: 17,
                        marginLeft: "15px",
                      }}
                    >
                      {getUserCompany(exp)}
                    </Typography>
                    <Typography
                      className={classes.text}
                      style={{
                        fontSize: 17,
                        marginLeft: "15px",
                      }}
                    >
                      {exp.province}
                      {/* , {exp.country} */}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion >

          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.nameAttribute}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/background/Information/ProjectProfileExperience.svg`}
                    style={{ width: "8%", marginRight: "10px" }}
                  />
                  <Typography fontSize={20} fontWeight={"bold"}>{t("projectProfile")}</Typography>
                </div>
              </Typography>
            </AccordionSummary >
            <AccordionDetails style={{ marginLeft: 20, paddingBottom: 50 }}>
              <TableContainer component={Paper}>
                <Table style={{ padding: 20 }}>
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor: "rgba(231, 129, 165, 0.5)",
                      }}
                    >
                      <TableCell>
                        <Typography style={{ fontWeight: "bold" }}>
                          {t("ProjectName")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography style={{ fontWeight: "bold" }}>
                          {t("StartDate")} - {t("EndDate")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography style={{ fontWeight: "bold" }}>
                          {t("Value")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography style={{ fontWeight: "bold" }}>
                          {t("Role")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography style={{ fontWeight: "bold" }}>
                          {t("country")}
                        </Typography>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {profileData.project && profileData.project.length > 0 &&
                      profileData.project.map((profileData, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.tableText}
                            >
                              {profileData.projectName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.tableText}
                            >
                              {profileData.date}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.tableText}
                            >
                              {profileData.use}K
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.tableText}
                            >
                              {profileData.role}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.tableText}
                            >
                              {profileData.country}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Link
                              to={`/project/showDetail/${profileData.idEmployeeProject}`}
                              style={{ textDecoration: "none" }}
                            >
                              <IconButton
                                sx={{
                                  color: "#424242",
                                }}
                                size="small"
                              >
                                <SearchIcon />
                              </IconButton>
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion >

          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.nameAttribute}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/background/Information/LearningProfile.svg`}
                    style={{ width: "12%", marginRight: "10px" }}
                  />
                  <Typography fontSize={20} fontWeight={"bold"}>{t("learningProfile")}</Typography>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ marginLeft: 20, paddingBottom: 50 }}>
              <Divider
                style={{
                  backgroundColor: "#F4E3EC",
                  borderColor: "#F4E3EC",
                  height: "2px",
                  marginBottom: "20px",
                }}
              />
              {profileData && profileData.course && (
                <>
                  <Stack spacing={2}>
                    <Typography fontWeight={"bold"} fontSize={20}>
                      {t("onSite")}
                    </Typography>
                    {profileData.course.classroom && profileData.course.classroom.map(classroom => (
                      <Grid container style={{ alignItems: "center" }}>
                        <Grid item xl={4} lg={4} md={4} xs={12}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CircleIcon
                              style={{ fontSize: "small", color: "#DB4178" }}
                            />
                            <Typography
                              className={classes.learningName}
                              style={{
                                fontSize: 18,
                                fontWeight: "bold",
                                color: "#DB4178",
                                marginLeft: "5px",
                              }}
                            >
                              {classroom.title}
                            </Typography>
                          </Box>

                          <Typography
                            className={classes.text}
                            style={{ marginLeft: "18px" }}
                          >
                            {classroom.category1 ? classroom.category1 : "-"}
                          </Typography>

                        </Grid>
                        <Grid item xl={3} lg={3} md={3} xs={12}>
                          <Typography fontWeight={500} textAlign={"center"}>{t("StartDate")} - {t("EndDate")}</Typography>
                          <Typography textAlign={"center"}>
                            {dayjs(classroom.startDate).format("YYYY/MM/DD") + " - " + dayjs(classroom.endDate).format("YYYY/MM/DD")}
                          </Typography>
                        </Grid>
                        <Grid item xl={3} lg={3} md={2} xs={12}>
                          <Typography fontWeight={500} textAlign={"center"}>{t("Institution")}</Typography>
                          <Typography fontSize={18} textAlign={"center"}>
                            {classroom.institution ? classroom.institution : "-"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xl={2}
                          lg={2}
                          md={3}
                          xs={12}
                          style={{ textAlign: "center" }}
                        >
                          <Fab
                            variant="extended"
                            onClick={(e) => {
                              setOpen(true);
                              setCertificate(classroom.imgurl);
                            }}
                            disabled={!classroom.imgurl}
                            style={{
                              fontSize: 15,
                              fontWeight: "bold",
                              backgroundColor: classroom.imgurl ? "#DB4178" : "gray",
                              color: "white",
                            }}
                          >
                            <VisibilityIcon
                              style={{ marginRight: 10, fontSize: 19 }}
                            />
                            {t("certificate")}
                          </Fab>
                          {open && (
                            <OpenCertificate
                              open={open}
                              close={() => setOpen(false)}
                              data={certificate}
                            />
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Stack>

                  <Stack spacing={2} sx={{ mt: 5 }}>
                    <Typography fontWeight={"bold"} fontSize={20}>
                      {t("online")}
                    </Typography>
                    {profileData.course.online && profileData.course.online.map(online => (
                      <Grid container style={{ alignItems: "center" }} justifyContent={"space-between"}>
                        <Grid item xl={4} lg={4} md={4} xs={12}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CircleIcon
                              style={{ fontSize: "small", color: "#DB4178" }}
                            />
                            <Typography
                              className={classes.learningName}
                              style={{
                                fontSize: 18,
                                fontWeight: "bold",
                                color: "#DB4178",
                                marginLeft: "5px",
                              }}
                            >
                              {online.title}
                            </Typography>
                          </Box>
                          <Typography
                            className={classes.text}
                            style={{ marginLeft: "18px" }}
                          >
                            {online.category1 ? online.category1 : "-"}
                          </Typography>
                        </Grid>
                        <Grid item xl={3} lg={3} md={2} xs={12}>
                          <Typography fontWeight={500} textAlign={"center"}>{t("Institution")}</Typography>
                          <Typography fontSize={18} textAlign={"center"}>
                            {online.institution ? online.institution : "-"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xl={2}
                          lg={2}
                          md={3}
                          xs={12}
                          style={{ textAlign: "center" }}
                        >
                          <Fab
                            variant="extended"
                            onClick={(e) => {
                              setOpen(true);
                              setCertificate(online.imgurl);
                            }}
                            disabled={!online.imgurl}
                            style={{
                              fontSize: 15,
                              fontWeight: "bold",
                              backgroundColor: online.imgurl ? "#DB4178" : "gray",
                              color: "white",
                            }}
                          >
                            <VisibilityIcon
                              style={{ marginRight: 10, fontSize: 19 }}
                            />
                            {t("certificate")}
                          </Fab>
                          {open && (
                            <OpenCertificate
                              open={open}
                              close={() => setOpen(false)}
                              data={certificate}
                            />
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Stack>

                </>
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.nameAttribute}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/background/Information/PerformancePotentail.svg`}
                    style={{ width: "12%", marginRight: "10px" }}
                  />
                  <Typography fontSize={20} fontWeight={"bold"}>{t("performancePotential")}</Typography>
                </div>
              </Typography>
            </AccordionSummary >
            <AccordionDetails style={{ marginLeft: 20, paddingBottom: 50 }}>
              <Grid container>
                {Array.from({ length: 3 }, (_, i) => {
                  const year = CURRENT_YEAR_DISPLAY - i;
                  const performanceForYear = profileData.performance &&
                    profileData.performance.find(
                      (performance) => performance.yearPerformance === year
                    );
                  return (
                    <Grid item xl={3} lg={3} md={3} xs={12}>
                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        {t("Performance")} {year}
                        <GradeIcon style={{ paddingBottom: 5 }} />
                      </Typography>
                      <Paper
                        className={classes.textPerform}
                        style={{
                          borderRadius: "20px",
                          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/Information/BG-pinkgradientblue.svg)`,
                          color: "black",
                        }}
                      >
                        {performanceForYear ? performanceForYear.name : `${t("NoData")}`}
                      </Paper>
                    </Grid>
                  )
                })}
              </Grid>
              <Box p={2}>
                <Divider
                  style={{
                    backgroundColor: "#F4E3EC",
                    borderColor: "#F4E3EC",
                    height: "2px",
                    marginBottom: "20px",
                  }}
                />
              </Box>
              <Grid container>
                {Array.from({ length: 3 }, (_, i) => {
                  const year = CURRENT_YEAR_DISPLAY - i;
                  const potentialForYear = profileData.potential &&
                    profileData.potential.find(
                      (performance) => performance.year === year
                    );
                  return (
                    <Grid item xl={3} lg={3} md={3} xs={12}>
                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        {t("potential")} {year}
                        <GradeIcon style={{ paddingBottom: 5 }} />
                      </Typography>
                      <Paper
                        className={classes.textPerform}
                        style={{
                          borderRadius: "20px",
                          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/Information/BG-pinkgradientblue.svg)`,
                          color: "black",
                        }}
                      >
                        {potentialForYear ? potentialForYear.Level : `${t("NoData")}`}
                      </Paper>
                    </Grid>
                  )
                })}
              </Grid>
            </AccordionDetails>
          </Accordion >

          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.nameAttribute}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/background/Information/Skill.svg`}
                    style={{ width: "30%", marginRight: "10px" }}
                  />
                  <Typography fontSize={20} fontWeight={"bold"}>{t("skills")}</Typography>
                </div>
              </Typography>
            </AccordionSummary >
            <AccordionDetails style={{ marginLeft: 20, paddingBottom: 50 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Divider
                    style={{
                      backgroundColor: "#F4E3EC",
                      borderColor: "#F4E3EC",
                      height: "2px",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    {profileData && profileData.skills && profileData.skills.length > 0 &&
                      profileData.skills.map((skill, index) => (
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12} key={index}>
                          <Typography className={classes.attribute}>
                            <CircleIcon
                              style={{ fontSize: "small", color: "#DB4178" }}
                            />{" "}
                            {skill.skillName + " " + "(" + (skill.level || "-") + ")"}
                          </Typography>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion >

          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.nameAttribute}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/background/Information/Competency.svg`}
                    style={{ width: "12%", marginRight: "10px" }}
                  />
                  <Typography fontSize={20} fontWeight={"bold"}>{t("competency")}</Typography>
                </div>
              </Typography>
            </AccordionSummary >
            <AccordionDetails>
              {profileData && profileData.competency && profileData.competency.length > 0 && (
                <Grid container justifyContent="center">
                  <Paper
                    style={{
                      width: "70vw",
                      height: "50vh",
                      borderRadius: "20px",
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/Information/BG.svg)`,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "20px"
                    }}
                  >
                    <Grid
                      item
                      container
                      justifyContent="center"
                      alignItems="center"
                      style={{ maxWidth: "600px", maxHeight: "600px" }}
                      sx={{ p: 4 }}
                    >
                      <Radar
                        data={chartDataCompetency(profileData.competency)}
                        options={option}
                        plugins={[plugin]}
                      />
                    </Grid>
                  </Paper>

                </Grid>
              )}
            </AccordionDetails>
          </Accordion >

          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.nameAttribute}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/background/Information/Character.svg`}
                    style={{ width: "20%", marginRight: "10px" }}
                  />
                  <Typography fontSize={20} fontWeight={"bold"}>{t("character")}</Typography>
                </div>
              </Typography>
            </AccordionSummary >
            <AccordionDetails style={{ marginLeft: 20, paddingBottom: 50 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Divider
                    style={{
                      backgroundColor: "#F4E3EC",
                      borderColor: "#F4E3EC",
                      height: "2px",
                    }}
                  />
                </Grid>

                {profileData.character && (
                  <Grid container>
                    {profileData.character && profileData.character[0] && (
                      <>
                        <Stack sx={{ mt: 2 }}>
                          <Typography
                            color="#DB4178"
                            fontSize={17}
                            fontWeight={"bold"}
                          >
                            <CircleIcon style={{ fontSize: "small", color: "#DB4178" }} />
                            {" "}
                            {personalityTypes1(t).find(character => character.id === (profileData.character && profileData.character[0].result)).title}
                          </Typography>
                          <Typography
                            style={{ marginLeft: "10px", fontSize: 15 }}
                          >
                            {t("character")} 1
                          </Typography>
                        </Stack>

                        <Grid container spacing={2} columns={16}>
                          <Grid item xs={8}>
                            <img
                              src={`${personalityTypes1(t).find(character => character.id === (profileData.character && profileData.character[0].result)).imagePath}`}
                              style={{
                                width: "50%",
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginTop: "10px",
                                marginBottom: "auto",
                              }}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <Stack style={{ padding: 20, }} spacing={2}>
                              <Typography style={{ fontSize: 15 }}>
                                {personalityTypes1(t).find(character => character.id === (profileData.character && profileData.character[0].result)).content.shortText}
                              </Typography>

                              <div>
                                <Typography style={{ fontSize: 15, marginBottom: 5 }} fontWeight={"bold"}>
                                  {t("goodThing")}
                                </Typography>
                                <Typography style={{ fontSize: 15 }}>
                                  {personalityTypes1(t).find(character => character.id === (profileData.character && profileData.character[0].result)).content.Advantages}
                                </Typography>
                              </div>

                              <div>
                                <Typography style={{ fontSize: 15, marginBottom: 5 }} fontWeight={"bold"}>
                                  {t("weaknessesToImprove")}
                                </Typography>
                                <Typography style={{ fontSize: 15 }}>
                                  {personalityTypes1(t).find(character => character.id === (profileData.character && profileData.character[0].result)).content.Disadvantages}
                                </Typography>
                              </div>

                              {/* <div>
                                <Typography style={{ fontSize: 15, marginBottom: 5 }} fontWeight={"bold"}>
                                  สรุปจุดเด่น
                                </Typography>
                                {personalityTypes1.find(character => character.id === (profileData.character && profileData.character[0].result)).content.prominentPoints.map((prominent, index) => (
                                  <Typography key={index} style={{ fontSize: 15 }}>
                                    - {prominent}
                                  </Typography>
                                ))}
                              </div> */}
                            </Stack>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {profileData.character && profileData.character[1] && (
                      <>
                        <Stack>
                          <Typography
                            color="#DB4178"
                            fontSize={17}
                            fontWeight={"bold"}
                          >
                            <CircleIcon style={{ fontSize: "small", color: "#DB4178" }} />
                            {" "}
                            {personalityTypes2(t).find(character => character.id === (profileData.character && profileData.character[1].result)).title}
                          </Typography>
                          <Typography
                            style={{ marginLeft: "10px", fontSize: 15 }}
                          >
                            {t("character")} 2
                          </Typography>
                        </Stack>

                        <Grid container spacing={2} columns={16}>
                          <Grid item xs={8}>
                            <img
                              src={`${personalityTypes2(t).find(character => character.id === (profileData.character && profileData.character[1].result)).imagePath}`}
                              style={{
                                width: "50%",
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginTop: "10px",
                                marginBottom: "auto",
                              }}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <div style={{ padding: 20 }}>
                              <Typography style={{ fontSize: 15 }}>
                                {personalityTypes2(t).find(character => character.id === (profileData.character && profileData.character[0].result)).content.longText}
                              </Typography>
                            </div>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion >

          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.nameAttribute}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/background/Information/LeadershipScore.svg`}
                    style={{ width: "12%", marginRight: "10px" }}
                  />
                  <Typography fontSize={20} fontWeight={"bold"}>{t("leadershipScore")}</Typography>
                </div>
              </Typography>
            </AccordionSummary >
            <AccordionDetails style={{ marginLeft: 20, paddingBottom: 50 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Divider
                    style={{
                      backgroundColor: "#F4E3EC",
                      borderColor: "#F4E3EC",
                      height: "2px",
                      marginBottom: "20px",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div id="chartLeadership"></div>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion >

          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.nameAttribute}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/background/Information/TalentAssessment.svg`}
                    style={{ width: "12%", marginRight: "10px" }}
                  />
                  <Typography fontSize={20} fontWeight={"bold"}>{t("talentAssessment")}</Typography>
                </div>
              </Typography>
            </AccordionSummary >
            <AccordionDetails style={{ marginLeft: 20, paddingBottom: 50 }}>
              <Grid item xs={12}>
                <Divider
                  style={{
                    backgroundColor: "#F4E3EC",
                    borderColor: "#F4E3EC",
                    height: "2px",
                  }}
                />
              </Grid>
              <Grid container>
                {profileData.talent && profileData.talent.length > 0 && profileData.talent.map((pt => (
                  <Grid item xl={3} lg={3} md={3} xs={12}>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontSize: 18,
                        fontWeight: "bold",
                        marginTop: 20
                      }}
                    >
                      {t("Talent")} {pt.year}
                    </Typography>
                    <Paper
                      className={classes.textPerform}
                      style={{
                        borderRadius: "20px",
                        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/Information/BG-pinkgradientblue.svg)`,
                        color: "black",
                      }}
                    >
                      {pt.talentName}
                    </Paper>
                  </Grid>
                )))}
              </Grid>
            </AccordionDetails>
          </Accordion >

          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.nameAttribute}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/background/Information/Successor.svg`}
                    style={{ width: "12%", marginRight: "10px" }}
                  />
                  <Typography fontSize={20} fontWeight={"bold"}>{t("AppMenu.Successor")}</Typography>
                </div>
              </Typography>
            </AccordionSummary >
            <AccordionDetails style={{ marginLeft: 20, paddingBottom: 50 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Divider
                    style={{
                      backgroundColor: "#F4E3EC",
                      borderColor: "#F4E3EC",
                      height: "2px",
                      marginBottom: "20px",
                    }}
                  />
                </Grid>
                {/* {profileData.education.map((profileData, index) => ( */}
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={12}
                  xs={12}
                  key={`${profileData.degree} ${profileData.major}`}
                >
                  <Grid container>
                    {profileData.successor && profileData.successor.length > 0 ? (
                      <>
                        {profileData.successor.map((success, i) => (
                          <Grid item xl={8} lg={8} md={6} xs={6}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CircleIcon
                                style={{ fontSize: "small", color: "#DB4178" }}
                              />
                              <Typography
                                style={{
                                  fontSize: 19,
                                  fontWeight: "bold",
                                  color: "#DB4178",
                                  marginLeft: "5px",
                                }}
                              >
                                {getUserFullName(success)}
                              </Typography>
                            </Box>

                            <Typography
                              className={classes.text}
                              style={{ fontSize: 16, marginLeft: "15px" }}
                            >
                              {t("AppMenu.Successor")} {i + 1}
                            </Typography>
                          </Grid>
                        ))}
                      </>
                    ) : null}

                  </Grid>
                </Grid>
                {/* ))} */}
              </Grid>
            </AccordionDetails>
          </Accordion >

          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.nameAttribute}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/background/Information/CareerAspiration.svg`}
                    style={{ width: "12%", marginRight: "10px" }}
                  />
                  <Typography fontSize={20} fontWeight={"bold"}>{t("careerAspiration")}</Typography>
                </div>
              </Typography>
            </AccordionSummary >
            <AccordionDetails style={{ marginLeft: 20, paddingBottom: 50 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Divider
                    style={{
                      backgroundColor: "#F4E3EC",
                      borderColor: "#F4E3EC",
                      height: "2px",
                      marginBottom: "20px",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={12}
                  xs={12}
                  key={`${profileData.degree} ${profileData.major}`}
                >
                  <Grid item xs={12} spacing={2}>
                    {profileData && profileData.careerPlan && profileData.careerPlan.map((plan) => (
                      <Box sx={{ p: 2, bgcolor: "#F4E3EC", mt: 2, borderRadius: 2 }} >
                        <Typography fontSize={20} fontWeight={600}>
                          {t("ManagerSuggestion")}
                        </Typography>
                        <Stack spacing={2} sx={{ mt: 2 }}>
                          <Typography fontSize={18} fontWeight={500}>
                            {plan.managerSugJobPos1 || "-"}
                          </Typography>
                          <Typography fontSize={18} fontWeight={500}>
                            {plan.managerSugJobPos2 || "-"}
                          </Typography>
                          <Typography fontSize={18} fontWeight={500}>
                            {plan.managerSugJobPos3 || "-"}
                          </Typography>
                        </Stack>
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion >

          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.nameAttribute}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/background/Information/CareerAspiration.svg`}
                    style={{ width: "12%", marginRight: "10px" }}
                  />
                  <Typography fontSize={20} fontWeight={"bold"}>{t("Strength")}</Typography>
                </div>
              </Typography>
            </AccordionSummary >

            <AccordionDetails style={{ marginLeft: 20, paddingBottom: 50 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Divider
                    style={{
                      backgroundColor: "#F4E3EC",
                      borderColor: "#F4E3EC",
                      height: "2px",
                      marginBottom: "20px",
                    }}
                  />
                </Grid>
                {profileData && profileData.careerPlan && profileData.careerPlan.length > 0 && (
                  <CareerPlanCard
                    idEmployees={id}
                    title={"Strength"}
                    manager1={profileData && profileData.careerPlan && profileData.careerPlan[0] && profileData.careerPlan[0].managerStrength1}
                    manager2={profileData && profileData.careerPlan && profileData.careerPlan[0] && profileData.careerPlan[0].managerStrength2}
                    manager3={profileData && profileData.careerPlan && profileData.careerPlan[0] && profileData.careerPlan[0].managerStrength3}
                    company1={profileData && profileData.careerPlan && profileData.careerPlan[0] && profileData.careerPlan[0].companyStrength1}
                  />
                )}
              </Grid>
            </AccordionDetails>
          </Accordion >

          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.nameAttribute}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/background/Information/CareerAspiration.svg`}
                    style={{ width: "12%", marginRight: "10px" }}
                  />
                  <Typography fontSize={20} fontWeight={"bold"}>{t("Weakness")}</Typography>
                </div>
              </Typography>
            </AccordionSummary >
            <AccordionDetails style={{ marginLeft: 20, paddingBottom: 50 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Divider
                    style={{
                      backgroundColor: "#F4E3EC",
                      borderColor: "#F4E3EC",
                      height: "2px",
                      marginBottom: "20px",
                    }}
                  />
                </Grid>
                {profileData && profileData.careerPlan && profileData.careerPlan.length > 0 && (
                  <CareerPlanCard
                    idEmployees={id}
                    title={t("Weakness")}
                    manager1={profileData && profileData.careerPlan && profileData.careerPlan[0].managerWeakness1}
                    manager2={profileData && profileData.careerPlan && profileData.careerPlan[0].managerWeakness2}
                    manager3={profileData && profileData.careerPlan && profileData.careerPlan[0].managerWeakness3}
                    company1={profileData && profileData.careerPlan && profileData.careerPlan[0].companyWeakness1}
                  />
                )}
              </Grid>
            </AccordionDetails>
          </Accordion >

          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.nameAttribute}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/background/Information/CareerAspiration.svg`}
                    style={{ width: "12%", marginRight: "10px" }}
                  />
                  <Typography fontSize={20} fontWeight={"bold"}>{t("thingsToImprove")}</Typography>
                </div>
              </Typography>
            </AccordionSummary >
            <AccordionDetails style={{ marginLeft: 20, paddingBottom: 50 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Divider
                    style={{
                      backgroundColor: "#F4E3EC",
                      borderColor: "#F4E3EC",
                      height: "2px",
                      marginBottom: "20px",
                    }}
                  />
                </Grid>
                {profileData && profileData.careerPlan && profileData.careerPlan.length > 0 && (
                  <CareerPlanCard
                    idEmployees={id}
                    title={"MoreDev"}
                    manager1={profileData && profileData.careerPlan && profileData.careerPlan[0].managerMoreDev1}
                    manager2={profileData && profileData.careerPlan && profileData.careerPlan[0].managerMoreDev12}
                    manager3={profileData && profileData.careerPlan && profileData.careerPlan[0].managerMoreDev13}
                    company1={profileData && profileData.careerPlan && profileData.careerPlan[0].companyCompanyMoreDev1}
                  />
                )}
              </Grid>
            </AccordionDetails>
          </Accordion >
        </div >
      }
    </div >
  );
};

export default ShowInformation;
