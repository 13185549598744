import React, { Fragment, useState } from "react";
import { ArrowForwardIosSharp } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import dayjs from "dayjs";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import utils from "../../../../../utils";
import { useTranslation } from "react-i18next";

const AccordionStyled = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummaryStyled = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderRadius: 8,
  marginBottom: 8,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .wrap": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const AccordionRow = (props) => {
  const { data, setDrawerConfig, setDeleteConfig } = props;
  const { t, i18n } = useTranslation();
  const [isExpand, setIsExpand] = useState(false);

  const renderAfter = (item) => {
    if (item.canRequestAfter === 0 || item.canRequestAfter === null) {
      return t("FirstDayOfWork");
    } else if (item.canRequestAfter < 365) {
      return `${t("YearOfService")} ${item.canRequestAfter} ${t("Unit.Days")}`;
    } else {
      return `${t("YearOfService")} ${item.canRequestAfter / 365} ${t("Year")}`;
    }
  };

  const renderGender = (item) => {
    if (item.gender === null) {
      return t("AllGender");
    } else if (item.gender === "Male") {
      return t("Male");
    } else if (item.gender === "Female") {
      return t("Female");
    }
  };

  const renderProRateRound = (value) => {
    if (value === 1) {
      return t("ThereIsNoRounding");
    } else if (value === 0.5) {
      return `${t("RoundWhenGreaterThan")} 0.5`;
    } else if (value === 0) {
      return t("RoundUpInAllCases");
    } else {
      return "-";
    }
  };

  const renderMinLeave = (item) => {
    if (item.minLeave === 100) {
      return t("Full_day");
    } else if (item.minLeave === 50) {
      return t("Half_day");
    } else if (item.minLeave === 10) {
      return `1 ${t("Unit.Hours")}`;
    } else if (item.minLeave === 5) {
      return t("Half_an_hour");
    } else {
      return t("NoMinimum");
    }
  };

  const getEmploymentTypeName = (list) => {
    const groupEmploymentTypeName = new Map();
    list.map((item) => {
      groupEmploymentTypeName.set(
        item.idEmploymentType,
        item.employmentTypeName || t("All")
      );
    });
    return Array.from(groupEmploymentTypeName, ([_, value]) => value).join(
      ", "
    );
  };

  const getGroupLevelName = (list) => {
    const groupLevelMap = new Map();
    list.map((item) => {
      groupLevelMap.set(item.idGroupLevel, item.groupLevelName || t("All"));
    });
    return Array.from(groupLevelMap, ([_, value]) => value).join(", ");
  };

  return (
    <AccordionStyled
      expanded={isExpand}
      onChange={() => {
        setIsExpand((prev) => !prev);
      }}
    >
      <AccordionSummaryStyled>
        <Box flexGrow="1" display="flex" alignItems="center">
          <Typography flexGrow="1">
            {
              i18n.resolvedLanguage === "th" ? data.leaveGroupName : data.leaveGroupName_EN ? data.leaveGroupName_EN : data.leaveGroupName
            }
          </Typography>

          <Box>
            <IconButton
              aria-label="edit"
              onClick={(e) => {
                e.stopPropagation();
                setDrawerConfig((prev) => ({
                  ...prev,
                  isOpen: true,
                  isEdit: true,
                  data: {
                    idLeaveGroup: data.idLeaveGroup,
                    leaveGroupName: data.leaveGroupName,
                    leaveGroupName_EN: data.leaveGroupName_EN,
                    leaveTypeList: data.leaveTypeList,
                  },
                }));
              }}
            >
              <EditNoteRoundedIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={(e) => {
                e.stopPropagation();
                setDeleteConfig((prev) => ({
                  ...prev,
                  isOpen: true,
                  data: {
                    idLeaveGroup: data.idLeaveGroup,
                    leaveGroupName: data.leaveGroupName,
                  },
                }));
              }}
            >
              <DeleteOutlineRoundedIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
      </AccordionSummaryStyled>
      <AccordionDetails>
        <Grid container spacing={2}>
          {data.leaveTypeList &&
            data.leaveTypeList.map((item, index) => (
              <Grid item container spacing={2}>
                <Grid key={`leaveType_${item.idLeaveType}`} item xs={12}>
                  {/* <Box 
                  sx={{
                    
                  }}
                ></Box> */}
                  <Typography
                    sx={{
                      paddingLeft: "8px",
                      borderLeft: "8px solid #46cbe2",
                      borderTopLeftRadius: "4px",
                      borderBottomLeftRadius: "4px",
                    }}
                    fontSize="20px"
                    fontWeight="500"
                  >
                    {`${t("GroupEmpType")} ${index + 1}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    color="text.secondary"
                  >
                    {t("EmployeeType")}
                  </Typography>
                  <Typography>
                    {getEmploymentTypeName(item.leaveTypeEmploymentTypeList)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    color="text.secondary"
                  >
                    {t("EmployeePersonalLevel")}
                  </Typography>
                  <Typography>
                    {getGroupLevelName(item.leaveTypeEmploymentTypeList)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    color="text.secondary"
                  >
                    {t("TotalLeaveDays")}
                  </Typography>
                  <Typography>
                    {item.isLeaveStep
                      ? t("OnCondition")
                      : item.leaveValue === null
                      ? t("Unlimited")
                      : `${item.leaveValue} ${t("Unit.Days")}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    color="text.secondary"
                  >
                    {t("AccumulateNextRoundNotMoreThan")}
                  </Typography>
                  <Typography>
                    {item.isLeaveStep
                      ? t("OnCondition")
                      : item.carryValue === null
                      ? t("Unlimited")
                      : `${item.carryValue} ${t("Unit.Days")}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    color="text.secondary"
                  >
                    {t("Pay")}
                  </Typography>
                  <Typography>{item.isPaid ? t("Yes") : t("No")}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    color="text.secondary"
                  >
                    {t("CanRequestLeaveAfter")}
                  </Typography>
                  <Typography>{renderAfter(item)}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    color="text.secondary"
                  >
                    {t("Gender")}
                  </Typography>
                  <Typography>{renderGender(item)}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    color="text.secondary"
                  >
                    {t("MinimumLeaveAllowed")}
                  </Typography>
                  <Typography>{renderMinLeave(item)}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    color="text.secondary"
                  >
                    ลาได้ครั้งเดียว
                  </Typography>
                  <Typography>
                    {item.canBeUseOnce? `ใช่ (${item.canBeUseOnceType === "AllTime"? "ตลอดการทำงาน": "ต่อรอบวันตัด"})`: "ไม่ใช่"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    color="text.secondary"
                  >
                    นับวันหยุดเป็นวันลา
                  </Typography>
                  <Typography>{item.isCountDayOff? "ใช่": "ไม่ใช่"}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        fontSize="14px"
                        fontWeight="500"
                        color="text.secondary"
                      >
                        ลาประเภทวันเกิด
                      </Typography>
                      <Typography>{item.isLeaveBirth? `ใช่ (${item.leaveBirthType === "InMonth"? "ภายในเดือนเกิด": `ก่อน ${item.birthPeriodStartDay} หลัง ${item.birthPeriodEndDay}`})`: "ไม่ใช่"}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    color="text.secondary"
                  >
                    Prorate
                  </Typography>
                  <Typography>{item.isProRate ? t("Yes") : t("No")}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    color="text.secondary"
                  >{`${t("Rounding")} Prorate`}</Typography>
                  <Typography>{renderProRateRound(item.proRateRound)}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    color="text.secondary"
                  >
                    Prorate Resign
                  </Typography>
                  <Typography>{item.isProRateResign ? t("Yes") : t("No")}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    color="text.secondary"
                  >{`${t("Rounding")} Prorate Resign`}</Typography>
                  <Typography>{renderProRateRound(item.proRateRoundResign)}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    color="text.secondary"
                  >คำนวณสิทธิ์กรณี Prorate</Typography>
                  <Typography>{item.calculatingProrateBy === "HIRING_DATE"
                    ? "วันที่เริ่มงาน"
                    : item.calculatingProrateBy === "PROBATION_DATE"
                      ? "วันที่ผ่านทดลองงาน"
                      : "-"
                  }</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    color="text.secondary"
                  >
                    {t("LeaveIn")}
                  </Typography>
                  <Typography>
                    {item.daysInAdvance
                      ? `${item.daysInAdvance} ${t("Unit.Days")}`
                      : t("NotSpecified")}
                  </Typography>
                </Grid>
                {item.isLeaveStep === 1 && item.leaveStepList.length > 0 && (
                  <Grid item xs={12}>
                    <Typography
                      fontSize="18px"
                      fontWeight="500"
                      marginBottom="8px"
                    >
                      {t("Condition")}
                    </Typography>
                    <Grid container spacing={2}>
                      {item.leaveStepList.map((step) => (
                        <Fragment>
                          <Grid item xs={6} md={4}>
                            <Typography
                              fontSize="14px"
                              fontWeight="500"
                              color="text.secondary"
                            >
                              {t("YearOfService")}
                            </Typography>
                            <Typography>{`${step.lessThanYear}${
                              step.moreThanYears === 99
                                ? ` ${t("YearsAbove")}`
                                : ` - ${step.moreThanYears} ${t("Year")}`
                            }`}</Typography>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <Typography
                              fontSize="14px"
                              fontWeight="500"
                              color="text.secondary"
                            >
                              {t("TotalLeaveDays")}
                            </Typography>
                            <Typography>{step.leaveValue}</Typography>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Typography
                              fontSize="14px"
                              fontWeight="500"
                              color="text.secondary"
                            >
                              {t("AccumulateNextRoundNotMoreThan")}
                            </Typography>
                            <Typography>{step.carryValue}</Typography>
                          </Grid>
                        </Fragment>
                      ))}
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            ))}
        </Grid>
      </AccordionDetails>
    </AccordionStyled>
  );
};

export default AccordionRow;
