export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const STANDARD_MENU_FETCHING = "STANDARD_MENU_FETCHING";
export const STANDARD_MENU_FAILED = "STANDARD_MENU_FAILED";
export const STANDARD_MENU_SUCCESS = "STANDARD_MENU_SUCCESS";

export const USERS_FETCHING = "USERS_FETCHING";
export const USERS_FAILED = "USERS_FAILED";
export const USERS_SUCCESS = "USERS_SUCCESS";

export const USERS_MOCK_FETCHING = "USERS_MOCK_FETCHING";
export const USERS_MOCK_FAILED = "USERS_MOCK_FAILED";
export const USERS_MOCK_SUCCESS = "USERS_MOCK_SUCCESS";

export const USERPROFILE_FETCHING = "USERPROFILE_FETCHING";
export const USERPROFILE_FAILED = "USERPROFILE_FAILED";
export const USERPROFILE_SUCCESS = "USERPROFILE_SUCCESS";

export const COURSE_FETCHING = "COURSE_FETCHING";
export const COURSE_FAILED = "COURSE_FAILED";
export const COURSE_SUCCESS = "COURSE_SUCCESS";

export const DEPARTMENT_FETCHING = "DEPARTMENT_FETCHING";
export const DEPARTMENT_FAILED = "DEPARTMENT_FAILED";
export const DEPARTMENT_SUCCESS = "DEPARTMENT_SUCCESS";

export const COURSEITEM_FETCHING = "COURSEITEM_FETCHING";
export const COURSEITEM_FAILED = "COURSEITEM_FAILED";
export const COURSEITEM_SUCCESS = "COURSEITEM_SUCCESS";

export const EMPLOYEE_FETCHING = "EMPLOYEE_FETCHING";
export const EMPLOYEE_FAILED = "EMPLOYEE_FAILED";
export const EMPLOYEE_SUCCESS = "EMPLOYEE_SUCCESS";

export const EMPLOYEE_FETCHING_PROJECT_HOUR = "EMPLOYEE_FETCHING_PROJECT_HOUR";
export const EMPLOYEE_FAILED_PROJECT_HOUR = "EMPLOYEE_FAILED_PROJECT_HOUR";
export const EMPLOYEE_SUCCESS_PROJECT_HOUR = "EMPLOYEE_SUCCESS_PROJECT_HOUR";

export const EMPLOYEE_SUCCESS_WEEK_WORKFORCE =
  "EMPLOYEE_SUCCESS_WEEK_WORKFORCE";
export const EMPLOYEE_FAILED_WEEK_WORKFORCE = "EMPLOYEE_FAILED_WEEK_WORKFORCE";
export const EMPLOYEE_FETCHING_WEEK_WORKFORCE =
  "EMPLOYEE_FETCHING_WEEK_WORKFORCE";

export const GET_BUDGET_USER_WORKFORCE_SUCCESS =
  "GET_BUDGET_USER_WORKFORCE_SUCCESS";
export const GET_BUDGET_USER_WORKFORCE_FETCHING =
  "GET_BUDGET_USER_WORKFORCE_FETCHING";
export const GET_BUDGET_USER_WORKFORCE_FAILED =
  "GET_BUDGET_USER_WORKFORCE_FAILED";

export const GET_SUM_USER_TIME_IN_PROJECT_SUCCESS =
  "GET_SUM_USER_TIME_IN_PROJECT_SUCCESS";
export const GET_SUM_USER_TIME_IN_PROJECT_FAILED =
  "GET_SUM_USER_TIME_IN_PROJECT_FAILED";
export const GET_SUM_USER_TIME_IN_PROJECT_FETCHING =
  "GET_SUM_USER_TIME_IN_PROJECT_FETCHING";

export const ADMIN_DETAIL_WORKFORCE_ALL_SUCCESS =
  "ADMIN_DETAIL_WORKFORCE_ALL_SUCCESS";
export const ADMIN_DETAIL_WORKFORCE_ALL_FAILED =
  "ADMIN_DETAIL_WORKFORCE_ALL_FAILED";
export const ADMIN_DETAIL_WORKFORCE_ALL_FETCHING =
  "ADMIN_DETAIL_WORKFORCE_ALL_FETCHING";

export const SUM_HOUR_MONTHID_WORKFORCE_ALL_SUCCESS =
  "SUM_HOUR_MONTHID_WORKFORCE_ALL_SUCCESS";
export const SUM_HOUR_MONTHID_WORKFORCE_ALL_FAILED =
  "SUM_HOUR_MONTHID_WORKFORCE_ALL_FAILED";
export const SUM_HOUR_MONTHID_WORKFORCE_ALL_FETCHING =
  "SUM_HOUR_MONTHID_WORKFORCE_ALL_FETCHING";

export const DASHBOARD_WORKFORCE_MONTH_FAILED =
  "DASHBOARD_WORKFORCE_MONTH_FAILED";
export const DASHBOARD_WORKFORCE_MONTH_FETCHING =
  "DASHBOARD_WORKFORCE_MONTH_FETCHING";
export const DASHBOARD_WORKFORCE_MONTH_SUCCESS =
  "DASHBOARD_WORKFORCE_MONTH_SUCCESS";

export const DASHBOARD_WORKFORCE_ALL_FAILED = "DASHBOARD_WORKFORCE_ALL_FAILED";
export const DASHBOARD_WORKFORCE_ALL_SUCCESS =
  "DASHBOARD_WORKFORCE_ALL_SUCCESS";
export const DASHBOARD_WORKFORCE_ALL_FETCHING =
  "DASHBOARD_WORKFORCE_ALL_FETCHING";

export const USER_WORKFORCE_HOUR_TABLE_SUCCESS =
  "USER_WORKFORCE_HOUR_TABLE_SUCCESS";
export const USER_WORKFORCE_HOUR_TABLE_FETCHING =
  "USER_WORKFORCE_HOUR_TABLE_FETCHING";
export const USER_WORKFORCE_HOUR_TABLE_FAILED =
  "USER_WORKFORCE_HOUR_TABLE_FAILED";

export const DASHBOARD_WORKFORCE_PIE_SUCCESS =
  "DASHBOARD_WORKFORCE_PIE_SUCCESS";
export const DASHBOARD_WORKFORCE_PIE_FETCHING =
  "DASHBOARD_WORKFORCE_PIE_FETCHING";
export const DASHBOARD_WORKFORCE_PIE_FAILED = "DASHBOARD_WORKFORCE_PIE_FAILED";

export const EMPLOYEE_FETCHING_PROJECT_WORKFORCE =
  "EMPLOYEE_FETCHING_PROJECT_WORKFORCE";
export const EMPLOYEE_FAILED_PROJECT_WORKFORCE =
  "EMPLOYEE_FAILED_PROJECT_WORKFORCE";
export const EMPLOYEE_SUCCESS_PROJECT_WORKFORCE =
  "EMPLOYEE_SUCCESS_PROJECT_WORKFORCE";

export const EMPLOYEEPROFILE_FETCHING = "EMPLOYEEPROFILE_FETCHING";
export const EMPLOYEEPROFILE_FAILED = "EMPLOYEEPROFILE_FAILED";
export const EMPLOYEEPROFILE_SUCCESS = "EMPLOYEEPROFILE_SUCCESS";

export const REQUESTTIME_WITHDRAW_FETCHING = "REQUESTTIME_WITHDRAW_FETCHING";
export const REQUESTTIME_WITHDRAW_SUCCESS = "REQUESTTIME_WITHDRAW_SUCCESS";
export const REQUESTTIME_WITHDRAW_FAILED = "REQUESTTIME_WITHDRAW_FAILED";

export const EDUCATION_FETCHING = "EDUCATION_FETCHING";
export const EDUCATION_FAILED = "EDUCATION_FAILED";
export const EDUCATION_SUCCESS = "EDUCATION_SUCCESS";

export const POSITIONS_FETCHING = "POSITIONS_FETCHING";
export const POSITIONS_FAILED = "POSITIONS_FAILED";
export const POSITIONS_SUCCESS = "POSITIONS_SUCCESS";

export const COMPANY_ORGANIZATION_FETCHING = "COMPANY_ORGANIZATION_FETCHING";
export const COMPANY_ORGANIZATION_SUCCESS = "COMPANY_ORGANIZATION_SUCCESS";
export const COMPANY_ORGANIZATION_FAILED = "COMPANY_ORGANIZATION_FAILED";

export const COMPANYPROFILE_FETCHING = "COMPANYPROFILE_FETCHING";
export const COMPANYPROFILE_FAILED = "COMPANYPROFILE_FAILED";
export const COMPANYPROFILE_SUCCESS = "COMPANYPROFILE_SUCCESS";

export const BANK_FETCHING = "BANK_FETCHING";
export const BANK_FAILED = "BANK_FAILED";
export const BANK_SUCCESS = "BANK_SUCCESS";

export const BANK_DETAIL_FETCHING = "BANK_DETAIL_FETCHING";
export const BANK_DETAIL_FAILED = "BANK_DETAIL_FAILED";
export const BANK_DETAIL_SUCCESS = "BANK_DETAIL_SUCCESS";

export const EMPLOYEE_ADDITION_DEDUCTION_FETCHING =
  "EMPLOYEE_ADDITION_DEDUCTION_FETCHING";
export const EMPLOYEE_ADDITION_DEDUCTION_FAILED =
  "EMPLOYEE_ADDITION_DEDUCTION_FAILED";
export const EMPLOYEE_ADDITION_DEDUCTION_SUCCESS =
  "EMPLOYEE_ADDITION_DEDUCTION_SUCCESS";

export const ORGCHART_FETCHING = "ORGCHART_FETCHING";
export const ORGCHART_FAILED = "ORGCHART_FAILED";
export const ORGCHART_SUCCESS = "ORGCHART_SUCCESS";

export const HOLIDAY_FETCHING = "HOLIDAY_FETCHING";
export const HOLIDAY_FAILED = "HOLIDAY_FAILED";
export const HOLIDAY_SUCCESS = "HOLIDAY_SUCCESS";

export const HOLIDAY_PATTERN_FETCHING = "HOLIDAY_PATTERN_FETCHING";
export const HOLIDAY_PATTERN_FAILED = "HOLIDAY_PATTERN_FAILED";
export const HOLIDAY_PATTERN_SUCCESS = "HOLIDAY_PATTERN_SUCCESS";

export const EMPLOYMENT_TYPE_FETCHING = "EMPLOYMENT_TYPE_FETCHING";
export const EMPLOYMENT_TYPE_FAILED = "EMPLOYMENT_TYPE_FAILED";
export const EMPLOYMENT_TYPE_SUCCESS = "EMPLOYMENT_TYPE_SUCCESS";

export const LEVELS_FETCHING = "LEVELS_FETCHING";
export const LEVELS_FAILED = "LEVELS_FAILED";
export const LEVELS_SUCCESS = "LEVELS_SUCCESS";

export const PERSONNEL_LEVELS_FETCHING = "PERSONNEL_LEVELS_FETCHING";
export const PERSONNEL_LEVELS_FAILED = "PERSONNEL_LEVELS_FAILED";
export const PERSONNEL_LEVELS_SUCCESS = "PERSONNEL_LEVELS_SUCCESS";

export const LEVELADDITION_FETCHING = "LEVELADDITION_FETCHING";
export const LEVELADDITION_FAILED = "LEVELADDITION_FAILED";
export const LEVELADDITION_SUCCESS = "LEVELADDITION_SUCCESS";

export const PROJECT_FETCHING = "PROJECT_FETCHING";
export const PROJECT_FAILED = "PROJECT_FAILED";
export const PROJECT_SUCCESS = "PROJECT_SUCCESS";

export const EMPLOYEE_REWARD_FETCHING = "EMPLOYEE_REWARD_FETCHING";
export const EMPLOYEE_REWARD_FAILED = "EMPLOYEE_REWARD_FAILED";
export const EMPLOYEE_REWARD_SUCCESS = "EMPLOYEE_REWARD_SUCCESS";

export const PROJECTASSIGN_FETCHING = "PROJECTASSIGN_FETCHING";
export const PROJECTASSIGN_FAILED = "PROJECTASSIGN_FAILED";
export const PROJECTASSIGN_SUCCESS = "PROJECTASSIGN_SUCCESS";

export const SALARYSLIP_FETCHING = "SALARYSLIP_FETCHING";
export const SALARYSLIP_FAILED = "SALARYSLIP_FAILED";
export const SALARYSLIP_SUCCESS = "SALARYSLIP_SUCCESS";

export const ADDITIONS_FETCHING = "ADDITIONS_FETCHING";
export const ADDITIONS_FAILED = "ADDITIONS_FAILED";
export const ADDITIONS_SUCCESS = "ADDITIONS_SUCCESS";

export const ADDITIONS_SPECIAL_1_FETCHING = "ADDITIONS_SPECIAL_1_FETCHING";
export const ADDITIONS_SPECIAL_1_FAILED = "ADDITIONS_SPECIAL_1_FAILED";
export const ADDITIONS_SPECIAL_1_SUCCESS = "ADDITIONS_SPECIAL_1_SUCCESS";

export const ADDITIONS_SPECIAL_2_FETCHING = "ADDITIONS_SPECIAL_2_FETCHING";
export const ADDITIONS_SPECIAL_2_FAILED = "ADDITIONS_SPECIAL_2_FAILED";
export const ADDITIONS_SPECIAL_2_SUCCESS = "ADDITIONS_SPECIAL_2_SUCCESS";

export const ADDITIONS_SPECIAL_100_FETCHING = "ADDITIONS_SPECIAL_100_FETCHING";
export const ADDITIONS_SPECIAL_100_FAILED = "ADDITIONS_SPECIAL_100_FAILED";
export const ADDITIONS_SPECIAL_100_SUCCESS = "ADDITIONS_SPECIAL_100_SUCCESS";

export const ADDITIONS_PERSONAL_FETCHING = "ADDITIONS_PERSONAL_FETCHING";
export const ADDITIONS_PERSONAL_FAILED = "ADDITIONS_PERSONAL_FAILED";
export const ADDITIONS_PERSONAL_SUCCESS = "ADDITIONS_PERSONAL_SUCCESS";

export const EMPLOYEE_ADDITIONS_PERSONAL_FETCHING =
  "EMPLOYEE_ADDITIONS_PERSONAL_FETCHING";
export const EMPLOYEE_ADDITIONS_PERSONAL_FAILED =
  "EMPLOYEE_ADDITIONS_PERSONAL_FAILED";
export const EMPLOYEE_ADDITIONS_PERSONAL_SUCCESS =
  "EMPLOYEE_ADDITIONS_PERSONAL_SUCCESS";

export const LOCATION_FETCHING = "LOCATION_FETCHING";
export const LOCATION_FAILED = "LOCATION_FAILED";
export const LOCATION_SUCCESS = "LOCATION_SUCCESS";

export const DAYOFFAPPROVAL_FETCHING = "DAYOFFAPPROVAL_FETCHING";
export const DAYOFFAPPROVAL_FAILED = "DAYOFFAPPROVAL_FAILED";
export const DAYOFFAPPROVAL_SUCCESS = "DAYOFFAPPROVAL_SUCCESS";

export const PAYTYPES_FETCHING = "PAYTYPES_FETCHING";
export const PAYTYPES_FAILED = "PAYTYPES_FAILED";
export const PAYTYPES_SUCCESS = "PAYTYPES_SUCCESS";

export const PAYROLL_SETTING_FETCHING = "PAYROLL_SETTING_FETCHING";
export const PAYROLL_SETTING_FAILED = "PAYROLL_SETTING_FAILED";
export const PAYROLL_SETTING_SUCCESS = "PAYROLL_SETTING_SUCCESS";

export const PAYSLIP_FETCHING = "PAYSLIP_FETCHING";
export const PAYSLIP_SUCCESS = "PAYSLIP_SUCCESS";
export const PAYSLIP_FAILED = "PAYSLIP_FAILED";

export const PAYSLIP_DETAIL_FETCHING = "PAYSLIP_DETAIL_FETCHING";
export const PAYSLIP_DETAIL_SUCCESS = "PAYSLIP_DETAIL_SUCCESS";
export const PAYSLIP_DETAIL_FAILED = "PAYSLIP_DETAIL_FAILED";

export const PAYRUNS_FETCHING = "PAYRUNS_FETCHING";
export const PAYRUNS_FAILED = "PAYRUNS_FAILED";
export const PAYRUNS_SUCCESS = "PAYRUNS_SUCCESS";

export const PAYRUNS_DETAIL_FETCHING = "PAYRUNS_DETAIL_FETCHING";
export const PAYRUNS_DETAIL_FAILED = "PAYRUNS_DETAIL_FAILED";
export const PAYRUNS_DETAIL_SUCCESS = "PAYRUNS_DETAIL_SUCCESS";

export const PAYRUNS_DETAIL_EMPLOYEES_FETCHING =
  "PAYRUNS_DETAIL_EMPLOYEES_FETCHING";
export const PAYRUNS_DETAIL_EMPLOYEES_FAILED =
  "PAYRUNS_DETAIL_EMPLOYEES_FAILED";
export const PAYRUNS_DETAIL_EMPLOYEES_SUCCESS =
  "PAYRUNS_DETAIL_EMPLOYEES_SUCCESS";

export const PAYRUNS_SUMMARY_YEAR_FETCHING = "PAYRUNS_SUMMARY_YEAR_FETCHING";
export const PAYRUNS_SUMMARY_YEAR_FAILED = "PAYRUNS_SUMMARY_YEAR_FAILED";
export const PAYRUNS_SUMMARY_YEAR_SUCCESS = "PAYRUNS_SUMMARY_YEAR_SUCCESS";

export const SHIFT_PATTERN_FETCHING = "SHIFT_PATTERN_FETCHING";
export const SHIFT_PATTERN_FAILED = "SHIFT_PATTERN_FAILED";
export const SHIFT_PATTERN_SUCCESS = "SHIFT_PATTERN_SUCCESS";

export const SHIFT_FETCHING = "SHIFT_FETCHING";
export const SHIFT_FAILED = "SHIFT_FAILED";
export const SHIFT_SUCCESS = "SHIFT_SUCCESS";

export const SHIFT_GROUP_FETCHING = "SHIFT_GROUP_FETCHING";
export const SHIFT_GROUP_FAILED = "SHIFT_GROUP_FAILED";
export const SHIFT_GROUP_SUCCESS = "SHIFT_GROUP_SUCCESS";

export const EMPLOYEE_SHIFT_FETCHING = "EMPLOYEE_SHIFT_FETCHING";
export const EMPLOYEE_SHIFT_FAILED = "EMPLOYEE_SHIFT_FAILED";
export const EMPLOYEE_SHIFT_SUCCESS = "EMPLOYEE_SHIFT_SUCCESS";

export const EMPLOYEE_NOT_SHIFT_FETCHING = "EMPLOYEE_NOT_SHIFT_FETCHING";
export const EMPLOYEE_NOT_SHIFT_SUCCESS = "EMPLOYEE_NOT_SHIFT_SUCCESS";
export const EMPLOYEE_NOT_SHIFT_FAILED = "EMPLOYEE_NOT_SHIFT_FAILED";

export const EMPLOYEES_SHIFTHISTORY_FETCHING =
  "EMPLOYEES_SHIFTHISTORY_FETCHING";
export const EMPLOYEES_SHIFTHISTORY_SUCCESS = "EMPLOYEES_SHIFTHISTORY_SUCCESS";
export const EMPLOYEES_SHIFTHISTORY_FAILED = "EMPLOYEES_SHIFTHISTORY_FAILED";

export const EMPLOYEE_CHANGESHIFT_FETCHING = "EMPLOYEE_CHANGESHIFT_FETCHING";
export const EMPLOYEE_CHANGESHIFT_SUCCESS = "EMPLOYEE_CHANGESHIFT_SUCCESS";
export const EMPLOYEE_CHANGESHIFT_FAILED = "EMPLOYEE_CHANGESHIFT_FAILED";

export const EMPLOYEE_CHANGEPOSITION_FETCHING =
  "EMPLOYEE_CHANGEPOSITION_FETCHING";
export const EMPLOYEE_CHANGEPOSITION_SUCCESS =
  "EMPLOYEE_CHANGEPOSITION_SUCCESS";
export const EMPLOYEE_CHANGEPOSITION_FAILED = "EMPLOYEE_CHANGEPOSITION_FAILED";

export const EMPLOYEE_CHANGE_FETCHING = "EMPLOYEE_CHANGE_FETCHING";
export const EMPLOYEE_CHANGE_SUCCESS = "EMPLOYEE_CHANGE_SUCCESS";
export const EMPLOYEE_CHANGE_FAILED = "EMPLOYEE_CHANGE_FAILED";

export const WORKTYPES_FETCHING = "WORKTYPES_FETCHING";
export const WORKTYPES_FAILED = "WORKTYPES_FAILED";
export const WORKTYPES_SUCCESS = "WORKTYPES_SUCCESS";

export const MANAGER_FETCHING = "MANAGER_FETCHING";
export const MANAGER_SUCCESS = "MANAGER_SUCCESS";
export const MANAGER_FAILED = "MANAGER_FAILED";

export const LEAVEEMPLOYEES_FETCHING = "LEAVEEMPLOYEES_FETCHING";
export const LEAVEEMPLOYEES_FAILED = "LEAVEEMPLOYEES_FAILED";
export const LEAVEEMPLOYEES_SUCCESS = "LEAVEEMPLOYEES_SUCCESS";

export const LEAVEEMPLOYEES_WITHDRAW_FETCHING =
  "LEAVEEMPLOYEES_WITHDRAW_FETCHING";
export const LEAVEEMPLOYEES_WITHDRAW_SUCCESS =
  "LEAVEEMPLOYEES_WITHDRAW_SUCCESS";
export const LEAVEEMPLOYEES_WITHDRAW_FAILED = "LEAVEEMPLOYEES_WITHDRAW_FAILED";

export const LEAVEWORKS_DETAIL_FETCHING = "LEAVEWORKS_DETAIL_FETCHING";
export const LEAVEWORKS_DETAIL_FAILED = "LEAVEWORKS_DETAIL_FAILED";
export const LEAVEWORKS_DETAIL_SUCCESS = "LEAVEWORKS_DETAIL_SUCCESS";

export const JOBFAMILY_DETAIL_FETCHING = "JOBFAMILY_DETAIL_FETCHING";
export const JOBFAMILY_DETAIL_FAILED = "JOBFAMILY_DETAIL_FAILED";
export const JOBFAMILY_DETAIL_SUCCESS = "JOBFAMILY_DETAIL_SUCCESS";

export const INSTITUTE_DETAIL_FETCHING = "INSTITUTE_DETAIL_FETCHING";
export const INSTITUTE_DETAIL_FAILED = "INSTITUTE_DETAIL_FAILED";
export const INSTITUTE_DETAIL_SUCCESS = "INSTITUTE_DETAIL_SUCCESS";

export const LEAVEAPPROVES_DETAIL_FETCHING = "LEAVEWORKS_DETAIL_FETCHING";
export const LEAVEAPPROVES_DETAIL_FAILED = "LEAVEAPPROVES_DETAIL_FAILED";
export const LEAVEAPPROVES_DETAIL_SUCCESS = "LEAVEAPPROVES_DETAIL_SUCCESS";

export const USER_LEAVE_FETCHING = "USER_LEAVE_FETCHING";
export const USER_LEAVE_FAILED = "USER_LEAVE_FAILED";
export const USER_LEAVE_SUCCESS = "USER_LEAVE_SUCCESS";

export const LEAVE_TYPE_FETCHING = "LEAVE_TYPE_FETCHING";
export const LEAVE_TYPE_FAILED = "LEAVE_TYPE_FAILED";
export const LEAVE_TYPE_SUCCESS = "LEAVE_TYPE_SUCCESS";

export const REQUESTTIME_FETCHING = "REQUESTTIME_FETCHING";
export const REQUESTTIME_FAILED = "REQUESTTIME_FAILED";
export const REQUESTTIME_SUCCESS = "REQUESTTIME_SUCCESS";

export const WORKTIMEREQUEST_DETAIL_FETCHING =
  "WORKTIMEREQUEST_DETAIL_FETCHING";
export const WORKTIMEREQUEST_DETAIL_FAILED = "WORKTIMEREQUEST_DETAIL_FAILED";
export const WORKTIMEREQUEST_DETAIL_SUCCESS = "WORKTIMEREQUEST_DETAIL_SUCCESS";

export const LOGRECORD_DETAIL_FETCHING = "LOGRECORD_DETAIL_FETCHING";
export const LOGRECORD_DETAIL_FAILED = "LOGRECORD_DETAIL_FAILED";
export const LOGRECORD_DETAIL_SUCCESS = "LOGRECORD_DETAIL_SUCCESS";

export const GROUP_LOCATIONS_FETCHING = "GROUP_LOCATIONS_FETCHING";
export const GROUP_LOCATIONS_FAILED = "GROUP_LOCATIONS_FAILED";
export const GROUP_LOCATIONS_SUCCESS = "GROUP_LOCATIONS_SUCCESS";

export const LOCATIONS_FETCHING = "LOCATIONS_FETCHING";
export const LOCATIONS_FAILED = "LOCATIONS_FAILED";
export const LOCATIONS_SUCCESS = "LOCATIONS_SUCCESS";

export const ATTENDANCE_FETCHING = "ATTENDANCE_FETCHING";
export const ATTENDANCE_FAILED = "ATTENDANCE_FAILED";
export const ATTENDANCE_SUCCESS = "ATTENDANCE_SUCCESS";

export const ATTENDANCE_UPLOAD_FETCHING = "ATTENDANCE_UPLOAD_FETCHING";
export const ATTENDANCE_UPLOAD_FAILED = "ATTENDANCE_UPLOAD_FAILED";
export const ATTENDANCE_UPLOAD_SUCCESS = "ATTENDANCE_UPLOAD_SUCCESS";

export const JOB_FETCHING = "JOB_FETCHING";
export const JOB_FAILED = "JOB_FAILED";
export const JOB_SUCCESS = "JOB_SUCCESS";

export const JOBPOSITION_FETCHING = "JOBPOSITION_FETCHING";
export const JOBPOSITION_FAILED = "JOBPOSITION_FAILED";
export const JOBPOSITION_SUCCESS = "JOBPOSITION_SUCCESS";

export const WORK_FETCHING = "WORK_FETCHING";
export const WORK_FAILED = "WORK_FAILED";
export const WORK_SUCCESS = "WORK_SUCCESS";

export const TASK_FETCHING = "TASK_FETCHING";
export const TASK_FAILED = "TASK_FAILED";
export const TASK_SUCCESS = "TASK_SUCCESS";

export const CATEGORY_FETCHING = "CATEGORY_FETCHING";
export const CATEGORY_FAILED = "CATEGORY_FAILED";
export const CATEGORY_SUCCESS = "CATEGORY_SUCCESS";

export const DOCUMENT_FETCHING = "DOCUMENT_FETCHING";
export const DOCUMENT_FAILED = "DOCUMENT_FAILED";
export const DOCUMENT_SUCCESS = "DOCUMENT_SUCCESS";

export const DOCUMENT_ITEM_FETCHING = "DOCUMENT_ITEM_FETCHING";
export const DOCUMENT_ITEM_FAILED = "DOCUMENT_ITEM_FAILED";
export const DOCUMENT_ITEM_SUCCESS = "DOCUMENT_ITEM_SUCCESS";

export const DOCUMENT_GROUP_FAILED = "DOCUMENT_GROUP_FAILED";
export const DOCUMENT_GROUP_SUCCESS = "DOCUMENT_GROUP_SUCCESS";
export const DOCUMENT_GROUP_FETCHING = "DOCUMENT_GROUP_FETCHING";

export const DIVISION_FETCHING = "DIVISION_FETCHING";
export const DIVISION_FAILED = "DIVISION_FAILED";
export const DIVISION_SUCCESS = "DIVISION_SUCCESS";

export const BUSINESS_UNIT_FETCHING = "BUSINESS_UNIT_FETCHING";
export const BUSINESS_UNIT_FAILED = "BUSINESS_UNIT_FAILED";
export const BUSINESS_UNIT_SUCCESS = "BUSINESS_UNIT_SUCCESS";

export const MEMBER_FETCHING = "MEMBER_FETCHING";
export const MEMBER_FAILED = "MEMBER_FAILED";
export const MEMBER_SUCCESS = "MEMBER_SUCCESS";

export const DEMAND_FETCHING = "DEMAND_FETCHING";
export const DEMAND_FAILED = "DEMAND_FAILED";
export const DEMAND_SUCCESS = "DEMAND_SUCCESS";

export const INSURANCE_FETCHING = "INSURANCE_FETCHING";
export const INSURANCE_FAILED = "INSURANCE_FAILED";
export const INSURANCE_SUCCESS = "INSURANCE_SUCCESS";

export const INSURANCECATEGORY_FETCHING = "INSURANCECATEGORY_FETCHING";
export const INSURANCECATEGORY_FAILED = "INSURANCECATEGORY_FAILED";
export const INSURANCECATEGORY_SUCCESS = "INSURANCECATEGORY_SUCCESS";

export const HOSPITAL_FETCHING = "HOSPITAL_FETCHING";
export const HOSPITAL_FAILED = "HOSPITAL_FAILED";
export const HOSPITAL_SUCCESS = "HOSPITAL_SUCCESS";

export const PACKAGE_FETCHING = "PACKAGE_FETCHING";
export const PACKAGE_FAILED = "PACKAGE_FAILED";
export const PACKAGE_SUCCESS = "PACKAGE_SUCCESS";

export const MAINCATEGORY_FETCHING = "MAINCATEGORY_FETCHING";
export const MAINCATEGORY_FAILED = "MAINCATEGORY_FAILED";
export const MAINCATEGORY_SUCCESS = "MAINCATEGORY_SUCCESS";

export const CART_SUCCESS = "CART_SUCCESS";
export const CART_FAIL = "CART_FAIL";

export const ITEM_FETCHING = "ITEM_FETCHING";
export const ITEM_FAILED = "ITEM_FAILED";
export const ITEM_SUCCESS = "ITEM_SUCCESS";

export const SHOPCATEGORY_FETCHING = "SHOPCATEGORY_FETCHING";
export const SHOPCATEGORY_FAILED = "SHOPCATEGORY_FAILED";
export const SHOPCATEGORY_SUCCESS = "SHOPCATEGORY_SUCCESS";

export const TRAVELCATEGORY_FETCHING = "TRAVELCATEGORY_FETCHING";
export const TRAVELCATEGORY_FAILED = "TRAVELCATEGORY_FAILED";
export const TRAVELCATEGORY_SUCCESS = "TRAVELCATEGORY_SUCCESS";

export const TRAVEL_FETCHING = "TRAVEL_FETCHING";
export const TRAVEL_FAILED = "TRAVEL_FAILED";
export const TRAVEL_SUCCESS = "TRAVEL_SUCCESS";

export const BILL_FETCHING = "BILL_FETCHING";
export const BILL_FAILED = "BILL_FAILED";
export const BILL_SUCCESS = "BILL_SUCCESS";

export const TREATMENTCATEGORY_FETCHING = "TREATMENTCATEGORY_FETCHING";
export const TREATMENTCATEGORY_FAILED = "TREATMENTCATEGORY_FAILED";
export const TREATMENTCATEGORY_SUCCESS = "TREATMENTCATEGORY_SUCCESS";

export const HEALTHCHECK_FETCHING = "HEALTHCHECK_FETCHING";
export const HEALTHCHECK_FAILED = "HEALTHCHECK_FAILED";
export const HEALTHCHECK_SUCCESS = "HEALTHCHECK_SUCCESS";

export const HEALTHCHECKCATEGORY_FETCHING = "HEALTHCHECKCATEGORY_FETCHING";
export const HEALTHCHECKCATEGORY_FAILED = "HEALTHCHECKCATEGORY_FAILED";
export const HEALTHCHECKCATEGORY_SUCCESS = "HEALTHCHECKCATEGORY_SUCCESS";

export const NOTIFICATION_FETCHING = "NOTIFICATION_FETCHING";
export const NOTIFICATION_FAILED = "NOTIFICATION_FAILED";
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";

export const JOBPOSTING_FETCHING = "JOBPOSTING_FETCHING";
export const JOBPOSTING_FAILED = "JOBPOSTING_FAILED";
export const JOBPOSTING_SUCCESS = "JOBPOSTING_SUCCESS";

export const JOBPOSTINGAPPLY_FETCHING = "JOBPOSTINGAPPLY_FETCHING";
export const JOBPOSTINGAPPLY_FAILED = "JOBPOSTINGAPPLY_FAILED";
export const JOBPOSTINGAPPLY_SUCCESS = "JOBPOSTINGAPPLY_SUCCESS";

export const DELIVERY_FETCHING = "DELIVERY_FETCHING";
export const DELIVERY_FAILED = "DELIVERY_FAILED";
export const DELIVERY_SUCCESS = "DELIVERY_SUCCESS";

export const FACILITIES_FETCHING = "FACILITIES_FETCHING";
export const FACILITIES_FAILED = "FACILITIES_FAILED";
export const FACILITIES_SUCCESS = "FACILITIES_SUCCESS";

export const HEALTH_FETCHING = "HEALTH_FETCHING";
export const HEALTH_FAILED = "HEALTH_FAILED";
export const HEALTH_SUCCESS = "HEALTH_SUCCESS";

export const RIGHTTREATMENT_FETCHING = "RIGHTTREATMENT_FETCHING";
export const RIGHTTREATMENT_FAILED = "RIGHTTREATMENT_FAILED";
export const RIGHTTREATMENT_SUCCESS = "RIGHTTREATMENT_SUCCESS";

export const BOOKINGHEALTHCHECK_FETCHING = "BOOKINGHEALTHCHECK_FETCHING";
export const BOOKINGHEALTHCHECK_FAILED = "BOOKINGHEALTHCHECK_FAILED";
export const BOOKINGHEALTHCHECK_SUCCESS = "BOOKINGHEALTHCHECK_SUCCESS";

export const HISTORY_FETCHING = "HISTORY_FETCHING";
export const HISTORY_FAILED = "HISTORY_FAILED";
export const HISTORY_SUCCESS = "HISTORY_SUCCESS";

export const CAREERPLAN_FETCHING = "CAREERPLAN_FETCHING";
export const CAREERPLAN_FAILED = "CAREERPLAN_FAILED";
export const CAREERPLAN_SUCCESS = "CAREERPLAN_SUCCESS";

export const JOBSTRUCTURE_FETCHING = "JOBSTRUCTURE_FETCHING";
export const JOBSTRUCTURE_FAILED = "JOBSTRUCTURE_FAILED";
export const JOBSTRUCTURE_SUCCESS = "JOBSTRUCTURE_SUCCESS";

export const SUBJOBSTRUCTURE_FETCHING = "SUBJOBSTRUCTURE_FETCHING";
export const SUBJOBSTRUCTURE_FAILED = "SUBJOBSTRUCTURE_FAILED";
export const SUBJOBSTRUCTURE_SUCCESS = "SUBJOBSTRUCTURE_SUCCESS";

export const JOBPOSITIONS_FETCHING = "JOBPOSITIONS_FETCHING";
export const JOBPOSITIONS_FAILED = "JOBPOSITIONS_FAILED";
export const JOBPOSITIONS_SUCCESS = "JOBPOSITIONS_SUCCESS";

export const ORGPLANS_FETCHING = "ORGPLANS_FETCHING";
export const ORGPLANS_FAILED = "ORGPLANS_FAILED";
export const ORGPLANS_SUCCESS = "ORGPLANS_SUCCESS";

export const TEAMPLANS_FETCHING = "TEAMPLANS_FETCHING";
export const TEAMPLANS_FAILED = "TEAMPLANS_FAILED";
export const TEAMPLANS_SUCCESS = "TEAMPLANS_SUCCESS";

export const IDVPLANS_FETCHING = "IDVPLANS_FETCHING";
export const IDVPLANS_FAILED = "IDVPLANS_FAILED";
export const IDVPLANS_SUCCESS = "IDVPLANS_SUCCESS";

export const FAILUREHISTORY_FETCHING = "FAILUREHISTORY_FETCHING";
export const FAILUREHISTORY_FAILED = "FAILUREHISTORY_FAILED";
export const FAILUREHISTORY_SUCCESS = "FAILUREHISTORY_SUCCESS";

export const PROGRESSORGPLAN_FETCHING = "PROGRESSORGPLAN_FETCHING";
export const PROGRESSORGPLAN_FAILED = "PROGRESSORGPLAN_FAILED";
export const PROGRESSORGPLAN_SUCCESS = "PROGRESSORGPLAN_SUCCESS";

export const PROGRESSTEAMPLAN_FETCHING = "PROGRESSTEAMPLAN_FETCHING";
export const PROGRESSTEAMPLAN_FAILED = "PROGRESSTEAMPLAN_FAILED";
export const PROGRESSTEAMPLAN_SUCCESS = "PROGRESSTEAMPLAN_SUCCESS";

export const PROGRESSIDVPLAN_FETCHING = "PROGRESSIDVPLAN_FETCHING";
export const PROGRESSIDVPLAN_FAILED = "PROGRESSIDVPLAN_FAILED";
export const PROGRESSIDVPLAN_SUCCESS = "PROGRESSIDVPLAN_SUCCESS";

export const IMPACT_FETCHING = "IMPACT_FETCHING";
export const IMPACT_FAILED = "IMPACT_FAILED";
export const IMPACT_SUCCESS = "IMPACT_SUCCESS";

export const IMPACTOTHER_FETCHING = "IMPACTOTHER_FETCHING";
export const IMPACTOTHER_FAILED = "IMPACTOTHER_FAILED";
export const IMPACTOTHER_SUCCESS = "IMPACTOTHER_SUCCESS";

export const DIALOGUERECORD_FETCHING = "DIALOGUERECORD_FETCHING";
export const DIALOGUERECORD_FAILED = "DIALOGUERECORD_FAILED";
export const DIALOGUERECORD_SUCCESS = "DIALOGUERECORD_SUCCESS";

export const JOBTASKLIST_FETCHING = "JOBTASKLIST_FETCHING";
export const JOBTASKLIST_FAILED = "JOBTASKLIST_FAILED";
export const JOBTASKLIST_SUCCESS = "JOBTASKLIST_SUCCESS";

export const PERSONALTASKLIST_FETCHING = "PERSONALTASKLIST_FETCHING";
export const PERSONALTASKLIST_FAILED = "PERSONALTASKLIST_FAILED";
export const PERSONALTASKLIST_SUCCESS = "PERSONALTASKLIST_SUCCESS";

export const PERSONALTASKUSED_FETCHING = "PERSONALTASKUSED_FETCHING";
export const PERSONALTASKUSED_FAILED = "PERSONALTASKUSED_FAILED";
export const PERSONALTASKUSED_SUCCESS = "PERSONALTASKUSED_SUCCESS";

export const ATTENDANCE_ONCE_FETCHING = "ATTENDANCE_ONCE_FETCHING";
export const ATTENDANCE_ONCE_FAILED = "ATTENDANCE_ONCE_FAILED";
export const ATTENDANCE_ONCE_SUCCESS = "ATTENDANCE_ONCE_SUCCESS";

export const USER_BENEFICIARYS_FETCHING = "USER_BENEFICIARYS_FETCHING";
export const USER_BENEFICIARYS_FAILED = "USER_BENEFICIARYS_FAILED";
export const USER_BENEFICIARYS_SUCCESS = "USER_BENEFICIARYS_SUCCESS";

export const USER_PROVIDENTFUNDS_FETCHING = "USER_PROVIDENTFUNDS_FETCHING";
export const USER_PROVIDENTFUNDS_FAILED = "USER_PROVIDENTFUNDS_FAILED";
export const USER_PROVIDENTFUNDS_SUCCESS = "USER_PROVIDENTFUNDS_SUCCESS";

export const ADMIN_SETTINGPF_FETCHING = "ADMIN_SETTINGPF_FETCHING";
export const ADMIN_SETTINGPF_FAILED = "ADMIN_SETTINGPF_FAILED";
export const ADMIN_SETTINGPF_SUCCESS = "ADMIN_SETTINGPF_SUCCESS";

export const DASHBOARD_OVERVIEW_FETCHING = "DASHBOARD_OVERVIEW_FETCHING";
export const DASHBOARD_OVERVIEW_FAILED = "DASHBOARD_OVERVIEW_FAILED";
export const DASHBOARD_OVERVIEW_SUCCESS = "DASHBOARD_OVERVIEW_SUCCESS";

export const DASHBOARD_OVERTIME_FETCHING = "DASHBOARD_OVERTIME_FETCHING";
export const DASHBOARD_OVERTIME_FAILED = "DASHBOARD_OVERTIME_FAILED";
export const DASHBOARD_OVERTIME_SUCCESS = "DASHBOARD_OVERTIME_SUCCESS";

export const DASHBOARD_COST_FETCHING = "DASHBOARD_COST_FETCHING";
export const DASHBOARD_COST_FAILED = "DASHBOARD_COST_FAILED";
export const DASHBOARD_COST_SUCCESS = "DASHBOARD_COST_SUCCESS";

export const DASHBOARD_WORKINGTIME_FETCHING = "DASHBOARD_WORKINGTIME_FETCHING";
export const DASHBOARD_WORKINGTIME_FAILED = "DASHBOARD_WORKINGTIME_FAILED";
export const DASHBOARD_WORKINGTIME_SUCCESS = "DASHBOARD_WORKINGTIME_SUCCESS";

export const TAX_DEDUCTION_FETCHING = "TAX_DEDUCTION_FETCHING";
export const TAX_DEDUCTION_FAILED = "TAX_DEDUCTION_FAILED";
export const TAX_DEDUCTION_SUCCESS = "TAX_DEDUCTION_SUCCESS";

export const SUMMARY_TIME_FETCHING = "SUMMARY_TIME_FETCHING";
export const SUMMARY_TIME_FAILED = "SUMMARY_TIME_FAILED";
export const SUMMARY_TIME_SUCCESS = "SUMMARY_TIME_SUCCESS";

export const SUMMARY_TIME_INDIVIDUAL_FETCHING =
  "SUMMARY_TIME_INDIVIDUAL_FETCHING";
export const SUMMARY_TIME_INDIVIDUAL_FAILED = "SUMMARY_TIME_INDIVIDUAL_FAILED";
export const SUMMARY_TIME_INDIVIDUAL_SUCCESS =
  "SUMMARY_TIME_INDIVIDUAL_SUCCESS";

export const EMPLOYEE_PROVIDENTFUNDS_FETCHING =
  "EMPLOYEE_PROVIDENTFUNDS_FETCHING";
export const EMPLOYEE_PROVIDENTFUNDS_FAILED = "EMPLOYEE_PROVIDENTFUNDS_FAILED";
export const EMPLOYEE_PROVIDENTFUNDS_SUCCESS =
  "EMPLOYEE_PROVIDENTFUNDS_SUCCESS";

export const EMPLOYEE_BENEFICIARYS_FETCHING = "EMPLOYEE_BENEFICIARYS_FETCHING";
export const EMPLOYEE_BENEFICIARYS_FAILED = "EMPLOYEE_BENEFICIARYS_FAILED";
export const EMPLOYEE_BENEFICIARYS_SUCCESS = "EMPLOYEE_BENEFICIARYS_SUCCESS";

export const TAX_DEDUCTION_SCHEDULE_FETCHING =
  "TAX_DEDUCTION_SCHEDULE_FETCHING";
export const TAX_DEDUCTION_SCHEDULE_FAILED = "TAX_DEDUCTION_SCHEDULE_FAILED";
export const TAX_DEDUCTION_SCHEDULE_SUCCESS = "TAX_DEDUCTION_SCHEDULE_SUCCESS";

export const INDIVIDUAL_OBJECTIVE_FETCHING = "INDIVIDUAL_OBJECTIVE_FETCHING";
export const INDIVIDUAL_OBJECTIVE_SUCCESS = "INDIVIDUAL_OBJECTIVE_SUCCESS";
export const INDIVIDUAL_OBJECTIVE_FAILED = "INDIVIDUAL_OBJECTIVE_FAILED";

export const ORGANIZATION_OBJECTIVE_FETCHING =
  "ORGANIZATION_OBJECTIVE_FETCHING";
export const ORGANIZATION_OBJECTIVE_SUCCESS = "ORGANIZATION_OBJECTIVE_SUCCESS";
export const ORGANIZATION_OBJECTIVE_FAILED = "ORGANIZATION_OBJECTIVE_FAILED";

export const TEAM_OBJECTIVE_FETCHING = "TEAM_OBJECTIVE_FETCHING";
export const TEAM_OBJECTIVE_SUCCESS = "TEAM_OBJECTIVE_SUCCESS";
export const TEAM_OBJECTIVE_FAILED = "TEAM_OBJECTIVE_FAILED";

export const OKR_SCOREBOARD_FETCHING = "OKR_SCOREBOARD_FETCHING";
export const OKR_SCOREBOARD_FAILED = "OKR_SCOREBOARD_FAILED";
export const OKR_SCOREBOARD_SUCCESS = "OKR_SCOREBOARD_SUCCESS";

export const LEAVE_3MONTH_FETCHING = "LEAVE_3MONTH_FETCHING";
export const LEAVE_3MONTH_FAILED = "LEAVE_3MONTH_FAILED";
export const LEAVE_3MONTH_SUCCESS = "LEAVE_3MONTH_SUCCESS";

export const LEAVE_YEAR_FETCHING = "LEAVE_YEAR_FETCHING";
export const LEAVE_YEAR_FAILED = "LEAVE_YEAR_FAILED";
export const LEAVE_YEAR_SUCCESS = "LEAVE_YEAR_SUCCESS";

export const EMPLOYMENT_FETCHING = "EMPLOYMENT_FETCHING";
export const EMPLOYMENT_FAILED = "EMPLOYMENT_FAILED";
export const EMPLOYMENT_SUCCESS = "EMPLOYMENT_SUCCESS";

export const COMPANY_FETCHING = "COMPANY_FETCHING";
export const COMPANY_FAILED = "COMPANY_FAILED";
export const COMPANY_SUCCESS = "COMPANY_SUCCESS";

export const MANAGER_SETTING_FETCHING = "MANAGER_SETTING_FETCHING";
export const MANAGER_SETTING_FAILED = "MANAGER_SETTING_FAILED";
export const MANAGER_SETTING_SUCCESS = "MANAGER_SETTING_SUCCESS";

export const MANAGER_GROUP_FETCHING = "MANAGER_GROUP_FETCHING";
export const MANAGER_GROUP_FAILED = "MANAGER_GROUP_FAILED";
export const MANAGER_GROUP_SUCCESS = "MANAGER_GROUP_SUCCESS";

export const ORGANIZATION_CHART_FETCHING = "ORGANIZATION_CHART_FETCHING";
export const ORGANIZATION_CHART_FAILED = "ORGANIZATION_CHART_FAILED";
export const ORGANIZATION_CHART_SUCCESS = "ORGANIZATION_CHART_SUCCESS";

export const JOBGROUP_FETCHING = "JOBGROUP_FETCHING";
export const JOBGROUP_FAILED = "JOBGROUP_FAILED";
export const JOBGROUP_SUCCESS = "JOBGROUP_SUCCESS";

export const ANNOUNCEMENT_FETCHING = "ANNOUNCEMENT_FETCHING";
export const ANNOUNCEMENT_FAILED = "ANNOUNCEMENT_FAILED";
export const ANNOUNCEMENT_SUCCESS = "ANNOUNCEMENT_SUCCESS";

export const ANNOUNCEMENT_ACTIVE_FETCHING = "ANNOUNCEMENT_ACTIVE_FETCHING";
export const ANNOUNCEMENT_ACTIVE_FAILED = "ANNOUNCEMENT_ACTIVE_FAILED";
export const ANNOUNCEMENT_ACTIVE_SUCCESS = "ANNOUNCEMENT_ACTIVE_SUCCESS";

export const ORGANIZATION_STRUCTURE_FETCHING =
  "ORGANIZATION_STRUCTURE_FETCHING";
export const ORGANIZATION_STRUCTURE_FAILED = "ORGANIZATION_STRUCTURE_FAILED";
export const ORGANIZATION_STRUCTURE_SUCCESS = "ORGANIZATION_STRUCTURE_SUCCESS";

export const COST_ELEMENT_LIST_FETCHING = "COST_ELEMENT_LIST_FETCHING";
export const COST_ELEMENT_LIST_FAILED = "COST_ELEMENT_LIST_FAILED";
export const COST_ELEMENT_LIST_SUCCESS = "COST_ELEMENT_LIST_SUCCESS";

export const COST_ELEMENT_FETCHING = "COST_ELEMENT_FETCHING";
export const COST_ELEMENT_FAILED = "COST_ELEMENT_FAILED";
export const COST_ELEMENT_SUCCESS = "COST_ELEMENT_SUCCESS";

export const EXPENSE_FETCHING = "EXPENSE_FETCHING";
export const EXPENSE_FAILED = "EXPENSE_FAILED";
export const EXPENSE_SUCCESS = "EXPENSE_SUCCESS";

export const EXPENSE_DETAIL_FETCHING = "EXPENSE_DETAIL_FETCHING";
export const EXPENSE_DETAIL_FAILED = "EXPENSE_DETAIL_FAILED";
export const EXPENSE_DETAIL_SUCCESS = "EXPENSE_DETAIL_SUCCESS";

export const LEVEL_GROUP_FETCHING = "LEVEL_GROUP_FETCHING";
export const LEVEL_GROUP_FAILED = "LEVEL_GROUP_FAILED";
export const LEVEL_GROUP_SUCCESS = "LEVEL_GROUP_SUCCESS";

export const ALL_RIGHTS_FETCHING = "ALL_RIGHTS_FETCHING";
export const ALL_RIGHTS_FAILED = "ALL_RIGHTS_FAILED";
export const ALL_RIGHTS_SUCCESS = "ALL_RIGHTS_SUCCESS";

export const FAMILY_FETCHING = "FAMILY_FETCHING";
export const FAMILY_FAILED = "FAMILY_FAILED";
export const FAMILY_SUCCESS = "FAMILY_SUCCESS";

export const WELFARE_RIGHTS_FETCHING = "WELFARE_RIGHTS_FETCHING";
export const WELFARE_RIGHTS_FAILED = "WELFARE_RIGHTS_FAILED";
export const WELFARE_RIGHTS_SUCCESS = "WELFARE_RIGHTS_SUCCESS";

export const WELFARE_REMAININGRIGHT_FETCHING =
  "WELFARE_REMAININGRIGHT_FETCHING";
export const WELFARE_REMAININGRIGHT_FAILED = "WELFARE_REMAININGRIGHT_FAILED";
export const WELFARE_REMAININGRIGHT_SUCCESS = "WELFARE_REMAININGRIGHT_SUCCESS";

export const WELFARE_BILLING_FETCHING = "WELFARE_BILLING_FETCHING";
export const WELFARE_BILLING_FAILED = "WELFARE_BILLING_FAILED";
export const WELFARE_BILLING_SUCCESS = "WELFARE_BILLING_SUCCESS";

export const WELFARE_BILLING_DETAIL_FETCHING =
  "WELFARE_BILLING_DETAIL_FETCHING";
export const WELFARE_BILLING_DETAIL_FAILED = "WELFARE_BILLING_DETAIL_FAILED";
export const WELFARE_BILLING_DETAIL_SUCCESS = "WELFARE_BILLING_DETAIL_SUCCESS";

export const FLEXCOIN_FETCHING = "FLEXCOIN_FETCHING";
export const FLEXCOIN_FAILED = "FLEXCOIN_FAILED";
export const FLEXCOIN_SUCCESS = "FLEXCOIN_SUCCESS";

export const ADMINS_FETCHING = "ADMINS_FETCHING";
export const ADMINS_FAILED = "ADMINS_FAILED";
export const ADMINS_SUCCESS = "ADMINS_SUCCESS";

export const EXPENSE_EXPORT_FETCHING = "EXPENSE_EXPORT_FETCHING";
export const EXPENSE_EXPORT_FAILED = "EXPENSE_EXPORT_FAILED";
export const EXPENSE_EXPORT_SUCCESS = "EXPENSE_EXPORT_SUCCESS";

export const AFFILIATE_FETCHING = "AFFILIATE_FETCHING";
export const AFFILIATE_FAILED = "AFFILIATE_FAILED";
export const AFFILIATE_SUCCESS = "AFFILIATE_SUCCESS";

export const AFFILIATE_ORGANIZATION_FETCHING =
  "AFFILIATE_ORGANIZATION_FETCHING";
export const AFFILIATE_ORGANIZATION_FAILED = "AFFILIATE_ORGANIZATION_FAILED";
export const AFFILIATE_ORGANIZATION_SUCCESS = "AFFILIATE_ORGANIZATION_SUCCESS";

export const ADMIN_FETCHING = "ADMIN_FETCHING";
export const ADMIN_FAILED = "ADMIN_FAILED";
export const ADMIN_SUCCESS = "ADMIN_SUCCESS";

export const JOBLEVELS_FETCHING = "JOBLEVELS_FETCHING";
export const JOBLEVELS_FAILED = "JOBLEVELS_FAILED";
export const JOBLEVELS_SUCCESS = "JOBLEVELS_SUCCESS";

export const JOBS_MANAGER_FETCHING = "JOBS_MANAGER_FETCHING";
export const JOBS_MANAGER_FAILED = "JOBS_MANAGER_FAILED";
export const JOBS_MANAGER_SUCCESS = "JOBS_MANAGER_SUCCESS";

export const JOB_RECRUIT_FORM_FETCHING = "JOB_RECRUIT_FORM_FETCHING";
export const JOB_RECRUIT_FORM_FAILED = "JOB_RECRUIT_FORM_FAILED";
export const JOB_RECRUIT_FORM_SUCCESS = "JOB_RECRUIT_FORM_SUCCESS";

export const JOB_RECRUIT_GROUP_FETCHING = "JOB_RECRUIT_GROUP_FETCHING";
export const JOB_RECRUIT_GROUP_FAILED = "JOB_RECRUIT_GROUP_FAILED";
export const JOB_RECRUIT_GROUP_SUCCESS = "JOB_RECRUIT_GROUP_SUCCESS";

export const JOB_RECRUIT_DETAIL_FETCHING = "JOB_RECRUIT_DETAIL_FETCHING";
export const JOB_RECRUIT_DETAIL_FAILED = "JOB_RECRUIT_DETAIL_FAILED";
export const JOB_RECRUIT_DETAIL_SUCCESS = "JOB_RECRUIT_DETAIL_SUCCESS";

export const JOBS_RECRUIT_FETCHING = "JOBS_RECRUIT_FETCHING";
export const JOBS_RECRUIT_FAILED = "JOBS_RECRUIT_FAILED";
export const JOBS_RECRUIT_SUCCESS = "JOBS_RECRUIT_SUCCESS";

export const JOB_RECRUIT_EDIT_FETCHING = "JOB_RECRUIT_EDIT_FETCHING";
export const JOB_RECRUIT_EDIT_FAILED = "JOB_RECRUIT_EDIT_FAILED";
export const JOB_RECRUIT_EDIT_SUCCESS = "JOB_RECRUIT_EDIT_SUCCESS";

export const JOBGROUP_INTERVIEWER_FETCHING = "JOBGROUP_INTERVIEWER_FETCHING";
export const JOBGROUP_INTERVIEWER_FAILED = "JOBGROUP_INTERVIEWER_FAILED";
export const JOBGROUP_INTERVIEWER_SUCCESS = "JOBGROUP_INTERVIEWER_SUCCESS";

export const HOMEPAGE_RECRUIT_FETCHING = "HOMEPAGE_RECRUIT_FETCHING";
export const HOMEPAGE_RECRUIT_FAILED = "HOMEPAGE_RECRUIT_FAILED";
export const HOMEPAGE_RECRUIT_SUCCESS = "HOMEPAGE_RECRUIT_SUCCESS";

export const DASHBOARD_RECRUIT_FETCHING = "DASHBOARD_RECRUIT_FETCHING";
export const DASHBOARD_RECRUIT_FAILED = "DASHBOARD_RECRUIT_FAILED";
export const DASHBOARD_RECRUIT_SUCCESS = "DASHBOARD_RECRUIT_SUCCESS";

export const MANPOWER_PROFILE_FETCHING = "MANPOWER_PROFILE_FETCHING";
export const MANPOWER_PROFILE_FAILED = "MANPOWER_PROFILE_FAILED";
export const MANPOWER_PROFILE_SUCCESS = "MANPOWER_PROFILE_SUCCESS";

export const MANPOWER_CERTIFICATE_FETCHING = "MANPOWER_CERTIFICATE_FETCHING";
export const MANPOWER_CERTIFICATE_FAILED = "MANPOWER_CERTIFICATE_FAILED";
export const MANPOWER_CERTIFICATE_SUCCESS = "MANPOWER_CERTIFICATE_SUCCESS";

export const PROBATION_QUESTION_FETCHING = "PROBATION_QUESTION_FETCHING";
export const PROBATION_QUESTION_FAILED = "PROBATION_QUESTION_FAILED";
export const PROBATION_QUESTION_SUCCESS = "PROBATION_QUESTION_SUCCESS";

export const RESULT_PROBATION_FETCHING = "RESULT_PROBATION_FETCHING";
export const RESULT_PROBATION_FAILED = "RESULT_PROBATION_FAILED";
export const RESULT_PROBATION_SUCCESS = "RESULT_PROBATION_SUCCESS";

export const PROBATION_EMPLOYEES_FETCHING = "PROBATION_EMPLOYEES_FETCHING";
export const PROBATION_EMPLOYEES_FAILED = "PROBATION_EMPLOYEES_FAILED";
export const PROBATION_EMPLOYEES_SUCCESS = "PROBATION_EMPLOYEES_SUCCESS";

export const PROBATION_EMPLOYEES_ID_FETCHING =
  "PROBATION_EMPLOYEES_ID_FETCHING";
export const PROBATION_EMPLOYEES_ID_FAILED = "PROBATION_EMPLOYEES_ID_FAILED";
export const PROBATION_EMPLOYEES_ID_SUCCESS = "PROBATION_EMPLOYEES_ID_SUCCESS";

export const PROBATION_EMPLOYEES_STATUS_FETCHING =
  "PROBATION_EMPLOYEES_STATUS_FETCHING";
export const PROBATION_EMPLOYEES_STATUS_FAILED =
  "PROBATION_EMPLOYEES_STATUS_FAILED";
export const PROBATION_EMPLOYEES_STATUS_SUCCESS =
  "PROBATION_EMPLOYEES_STATUS_SUCCESS";

export const EMPLOYEES_ROLE_ONE_TWO_FETCHING =
  "EMPLOYEES_ROLE_ONE_TWO_FETCHING";
export const EMPLOYEES_ROLE_ONE_TWO_FAILED = "EMPLOYEES_ROLE_ONE_TWO_FAILED";
export const EMPLOYEES_ROLE_ONE_TWO_SUCCESS = "EMPLOYEES_ROLE_ONE_TWO_SUCCESS";

export const ONBOARDING_LIST_FETCHING = "ONBOARDING_LIST_FETCHING";
export const ONBOARDING_LIST_FAILED = "ONBOARDING_LIST_FAILED";
export const ONBOARDING_LIST_SUCCESS = "ONBOARDING_LIST_SUCCESS";

export const ONBOARDING_REVIEWER_FETCHING = "ONBOARDING_REVIEWER_FETCHING";
export const ONBOARDING_REVIEWER_FAILED = "ONBOARDING_REVIEWER_FAILED";
export const ONBOARDING_REVIEWER_SUCCESS = "ONBOARDING_REVIEWER_SUCCESS";

export const ONBOARDING_EMPLOYEE_FETCHING = "ONBOARDING_EMPLOYEE_FETCHING";
export const ONBOARDING_EMPLOYEE_FAILED = "ONBOARDING_EMPLOYEE_FAILED";
export const ONBOARDING_EMPLOYEE_SUCCESS = "ONBOARDING_EMPLOYEE_SUCCESS";

export const ONBOARDING_EMPLOYEE_BYID_FETCHING =
  "ONBOARDING_EMPLOYEE_BYID_FETCHING";
export const ONBOARDING_EMPLOYEE_BYID_FAILED =
  "ONBOARDING_EMPLOYEE_BYID_FAILED";
export const ONBOARDING_EMPLOYEE_BYID_SUCCESS =
  "ONBOARDING_EMPLOYEE_BYID_SUCCESS";

export const TERMINATE_REVIEWER_DELETE_FAILED =
  "TERMINATE_REVIEWER_DELETE_FAILED";
export const TERMINATE_REVIEWER_DELETE_SUCCESS =
  "TERMINATE_REVIEWER_DELETE_SUCCESS";

export const ONBOARDING_REVIEWER_DELETE_FAILED =
  "ONBOARDING_REVIEWER_DELETE_FAILED";
export const ONBOARDING_REVIEWER_DELETE_SUCCESS =
  "ONBOARDING_REVIEWER_DELETE_SUCCESS";

export const TERMINATE_LIST_FETCHING = "TERMINATE_LIST_FETCHING";
export const TERMINATE_LIST_FAILED = "TERMINATE_LIST_FAILED";
export const TERMINATE_LIST_SUCCESS = "TERMINATE_LIST_SUCCESS";

export const TERMINATE_REVIEWER_FETCHING = "TERMINATE_REVIEWER_FETCHING";
export const TERMINATE_REVIEWER_FAILED = "TERMINATE_REVIEWER_FAILED";
export const TERMINATE_REVIEWER_SUCCESS = "TERMINATE_REVIEWER_SUCCESS";

export const TERMINATE_EMPLOYEE_FETCHING = "TERMINATE_EMPLOYEE_FETCHING";
export const TERMINATE_EMPLOYEE_FAILED = "TERMINATE_EMPLOYEE_FAILED";
export const TERMINATE_EMPLOYEE_SUCCESS = "TERMINATE_EMPLOYEE_SUCCESS";

export const TERMINATE_EMPLOYEE_BYID_FETCHING =
  "TERMINATE_EMPLOYEE_BYID_FETCHING";
export const TERMINATE_EMPLOYEE_BYID_FAILED = "TERMINATE_EMPLOYEE_BYID_FAILED";
export const TERMINATE_EMPLOYEE_BYID_SUCCESS =
  "TERMINATE_EMPLOYEE_BYID_SUCCESS";

export const PERFORMANCE_RESULT_FETCHING = "PERFORMANCE_RESULT_FETCHING";
export const PERFORMANCE_RESULT_FAILED = "PERFORMANCE_RESULT_FAILED";
export const PERFORMANCE_RESULT_SUCCESS = "PERFORMANCE_RESULT_SUCCESS";

export const EXIT_INTERVIEW_FETCHING = "EXIT_INTERVIEW_FETCHING";
export const EXIT_INTERVIEW_FAILED = "EXIT_INTERVIEW_FAILED";
export const EXIT_INTERVIEW_SUCCESS = "EXIT_INTERVIEW_SUCCESS";

export const RESULT_EXIT_INTERVIEW_FETCHING = "RESULT_EXIT_INTERVIEW_FETCHING";
export const RESULT_EXIT_INTERVIEW_FAILED = "RESULT_EXIT_INTERVIEW_FAILED";
export const RESULT_EXIT_INTERVIEW_SUCCESS = "RESULT_EXIT_INTERVIEW_SUCCESS";

export const REASON_EXIT_INTERVIEW_FETCHING = "REASON_EXIT_INTERVIEW_FETCHING";
export const REASON_EXIT_INTERVIEW_FAILED = "REASON_EXIT_INTERVIEW_FAILED";
export const REASON_EXIT_INTERVIEW_SUCCESS = "REASON_EXIT_INTERVIEW_SUCCESS";

export const EXIT_INTERVIEW_DISCARD_FETCHING =
  "EXIT_INTERVIEW_DISCARD_FETCHING";
export const EXIT_INTERVIEW_DISCARD_FAILED = "EXIT_INTERVIEW_DISCARD_FAILED";
export const EXIT_INTERVIEW_DISCARD_SUCCESS = "EXIT_INTERVIEW_DISCARD_SUCCESS";

export const EXIT_INTERVIEW_EMPLOYEES_FETCHING =
  "EXIT_INTERVIEW_EMPLOYEES_FETCHING";
export const EXIT_INTERVIEW_EMPLOYEES_FAILED =
  "EXIT_INTERVIEW_EMPLOYEES_FAILED";
export const EXIT_INTERVIEW_EMPLOYEES_SUCCESS =
  "EXIT_INTERVIEW_EMPLOYEES_SUCCESS";

export const EXIT_INTERVIEW_EMPLOYEES_STATUS_FETCHING =
  "EXIT_INTERVIEW_EMPLOYEES_STATUS_FETCHING";
export const EXIT_INTERVIEW_EMPLOYEES_STATUS_FAILED =
  "EXIT_INTERVIEW_EMPLOYEES_STATUS_FAILED";
export const EXIT_INTERVIEW_EMPLOYEES_STATUS_SUCCESS =
  "EXIT_INTERVIEW_EMPLOYEES_STATUS_SUCCESS";

export const PERFORMANCE_YEAR_FETCHING = "PERFORMANCE_YEAR_FETCHING";
export const PERFORMANCE_YEAR_FAILED = "PERFORMANCE_YEAR_FAILED";
export const PERFORMANCE_YEAR_SUCCESS = "PERFORMANCE_YEAR_SUCCESS";

export const PERFORMANCE_QUESTION_FETCHING = "PERFORMANCE_QUESTION_FETCHING";
export const PERFORMANCE_QUESTION_FAILED = "PERFORMANCE_QUESTION_FAILED";
export const PERFORMANCE_QUESTION_SUCCESS = "PERFORMANCE_QUESTION_SUCCESS";

export const ESTIMATE_PERFORMANCE_EMPLOYEES_FETCHING =
  "ESTIMATE_PERFORMANCE_EMPLOYEES_FETCHING";
export const ESTIMATE_PERFORMANCE_EMPLOYEES_FAILED =
  "ESTIMATE_PERFORMANCE_EMPLOYEES_FAILED";
export const ESTIMATE_PERFORMANCE_EMPLOYEES_SUCCESS =
  "ESTIMATE_PERFORMANCE_EMPLOYEES_SUCCESS";

export const ESTIMATE_PERFORMANCE_EMPLOYEES_STATUS_FETCHING =
  "ESTIMATE_PERFORMANCE_EMPLOYEES_STATUS_FETCHING";
export const ESTIMATE_PERFORMANCE_EMPLOYEES_STATUS_FAILED =
  "ESTIMATE_PERFORMANCE_EMPLOYEES_STATUS_FAILED";
export const ESTIMATE_PERFORMANCE_EMPLOYEES_STATUS_SUCCESS =
  "ESTIMATE_PERFORMANCE_EMPLOYEES_STATUS_SUCCESS";

export const ESTIMATE_LEVEL_FETCHING = "ESTIMATE_LEVEL_FETCHING";
export const ESTIMATE_LEVEL_FAILED = "ESTIMATE_LEVEL_FAILED";
export const ESTIMATE_LEVEL_SUCCESS = "ESTIMATE_LEVEL_SUCCESS";

export const USERS_VENDOR_FREE_FETCHING = "USERS_VENDOR_FREE_FETCHING";
export const USERS_VENDOR_FREE_FAILED = "USERS_VENDOR_FREE_FAILED";
export const USERS_VENDOR_FREE_SUCCESS = "USERS_VENDOR_FREE_SUCCESS";

export const NEW_CANDIDATES_FETCHING = "NEW_CANDIDATES_FETCHING";
export const NEW_CANDIDATES_FAILED = "NEW_CANDIDATES_FAILED";
export const NEW_CANDIDATES_SUCCESS = "NEW_CANDIDATES_SUCCESS";

export const INTERVIEWED_CANDIDATE_FETCHING = "INTERVIEWED_CANDIDATE_FETCHING";
export const INTERVIEWED_CANDIDATE_FAILED = "INTERVIEWED_CANDIDATE_FAILED";
export const INTERVIEWED_CANDIDATE_SUCCESS = "INTERVIEWED_CANDIDATE_SUCCESS";

export const SCHEDULEANDCANDIDATES_FETCHING = "SCHEDULEANDCANDIDATES_FETCHING";
export const SCHEDULEANDCANDIDATES_FAILED = "SCHEDULEANDCANDIDATES_FAILED";
export const SCHEDULEANDCANDIDATES_SUCCESS = "SCHEDULEANDCANDIDATES_SUCCESS";

export const CANDIDATES_ALREADY_SENT_FETCHING =
  "CANDIDATES_ALREADY_SENT_FETCHING";
export const CANDIDATES_ALREADY_SENT_FAILED = "CANDIDATES_ALREADY_SENT_FAILED";
export const CANDIDATES_ALREADY_SENT_SUCCESS =
  "CANDIDATES_ALREADY_SENT_SUCCESS";

export const PASSED_CANDIDATES_COMPANY_FETCHING =
  "PASSED_CANDIDATES_COMPANY_FETCHING";
export const PASSED_CANDIDATES_COMPANY_FAILED =
  "PASSED_CANDIDATES_COMPANY_FAILED";
export const PASSED_CANDIDATES_COMPANY_SUCCESS =
  "PASSED_CANDIDATES_COMPANY_SUCCESS";

export const HISTORY_CANDIDATES_JOBS_MATCHING_FETCHING =
  "HISTORY_CANDIDATES_JOBS_MATCHING_FETCHING";
export const HISTORY_CANDIDATES_JOBS_MATCHING_FAILED =
  "HISTORY_CANDIDATES_JOBS_MATCHING_FAILED";
export const HISTORY_CANDIDATES_JOBS_MATCHING_SUCCESS =
  "HISTORY_CANDIDATES_JOBS_MATCHING_SUCCESS";

export const HISTORY_MANPOWERS_SENDING_FETCHING =
  "HISTORY_MANPOWERS_SENDING_FETCHING";
export const HISTORY_MANPOWERS_SENDING_FAILED =
  "HISTORY_MANPOWERS_SENDING_FAILED";
export const HISTORY_MANPOWERS_SENDING_SUCCESS =
  "HISTORY_MANPOWERS_SENDING_SUCCESS";

export const WARNING_LETTER_FETCHING = "WARNING_LETTER_FETCHING";
export const WARNING_LETTER_FAILED = "WARNING_LETTER_FAILED";
export const WARNING_LETTER_SUCCESS = "WARNING_LETTER_SUCCESS";

export const WARNING_LEVEL_FETCHING = "WARNING_LEVEL_FETCHING";
export const WARNING_LEVEL_FAILED = "WARNING_LEVEL_FAILED";
export const WARNING_LEVEL_SUCCESS = "WARNING_LEVEL_SUCCESS";

export const WARNING_LETTER_TEMPLATE_FETCHING =
  "WARNING_LETTER_TEMPLATE_FETCHING";
export const WARNING_LETTER_TEMPLATE_FAILED = "WARNING_LETTER_TEMPLATE_FAILED";
export const WARNING_LETTER_TEMPLATE_SUCCESS =
  "WARNING_LETTER_TEMPLATE_SUCCESS";

export const ASSET_MANAGEMENT_FETCHING = "ASSET_MANAGEMENT_FETCHING";
export const ASSET_MANAGEMENT_FAILED = "ASSET_MANAGEMENT_FAILED";
export const ASSET_MANAGEMENT_SUCCESS = "ASSET_MANAGEMENT_SUCCESS";

export const POLICY_FETCHING = "POLICY_FETCHING";
export const POLICY_FAILED = "POLICY_FAILED";
export const POLICY_SUCCESS = "POLICY_SUCCESS";

export const POLICY_ACTIVE_FETCHING = "POLICY_ACTIVE_FETCHING";
export const POLICY_ACTIVE_FAILED = "POLICY_ACTIVE_FAILED";
export const POLICY_ACTIVE_SUCCESS = "POLICY_ACTIVE_SUCCESS";

export const TIMELINE_FETCHING = "TIMELINE_FETCHING";
export const TIMELINE_FAILED = "TIMELINE_FAILED";
export const TIMELINE_SUCCESS = "TIMELINE_SUCCESS";

export const TIMELINE_MONTH_FETCHING = "TIMELINE_MONTH_FETCHING";
export const TIMELINE_MONTH_FAILED = "TIMELINE_MONTH_FAILED";
export const TIMELINE_MONTH_SUCCESS = "TIMELINE_MONTH_SUCCESS";

export const NOTIFICATION_ALERT_OPEN = "NOTIFICATION_ALERT_OPEN";
export const NOTIFICATION_ALERT_CLOSE = "NOTIFICATION_ALERT_CLOSE";

export const MANPOWER_PLANNING_FETCHING = "MANPOWER_PLANNING_FETCHING";
export const MANPOWER_PLANNING_FAILED = "MANPOWER_PLANNING_FAILED";
export const MANPOWER_PLANNING_SUCCESS = "MANPOWER_PLANNING_SUCCESS";

export const CONTRACT_FETCHING = "CONTRACT_FETCHING";
export const CONTRACT_FAILED = "CONTRACT_FAILED";
export const CONTRACT_SUCCESS = "CONTRACT_SUCCESS";

export const REPORT_CONTRACT_TERMINATE_EMPLOYEES_FETCHING =
  "REPORT_CONTRACT_TERMINATE_EMPLOYEES_FETCHING";
export const REPORT_CONTRACT_TERMINATE_EMPLOYEES_FAILED =
  "REPORT_CONTRACT_TERMINATE_EMPLOYEES_FAILED";
export const REPORT_CONTRACT_TERMINATE_EMPLOYEES_SUCCESS =
  "REPORT_CONTRACT_TERMINATE_EMPLOYEES_SUCCESS";

export const REPORT_NEW_EMPLOYEES_FETCHING = "REPORT_NEW_EMPLOYEES_FETCHING";
export const REPORT_NEW_EMPLOYEES_FAILED = "REPORT_NEW_EMPLOYEES_FAILED";
export const REPORT_NEW_EMPLOYEES_SUCCESS = "REPORT_NEW_EMPLOYEES_SUCCESS";

export const REPORT_RESIGN_EMPLOYEES_FETCHING =
  "REPORT_RESIGN_EMPLOYEES_FETCHING";
export const REPORT_RESIGN_EMPLOYEES_FAILED = "REPORT_RESIGN_EMPLOYEES_FAILED";
export const REPORT_RESIGN_EMPLOYEES_SUCCESS =
  "REPORT_RESIGN_EMPLOYEES_SUCCESS";

export const REPORT_PROBATION_EMPLOYEES_FETCHING =
  "REPORT_PROBATION_EMPLOYEES_FETCHING";
export const REPORT_PROBATION_EMPLOYEES_FAILED =
  "REPORT_PROBATION_EMPLOYEES_FAILED";
export const REPORT_PROBATION_EMPLOYEES_SUCCESS =
  "REPORT_PROBATION_EMPLOYEES_SUCCESS";

export const EMAIL_FETCHING = "EMAIL_FETCHING";
export const EMAIL_FAILED = "EMAIL_FAILED";
export const EMAIL_SUCCESS = "EMAIL_SUCCESS";

export const ATTENDANCEWEEKLY_FETCHING = "ATTENDANCEWEEKLY_FETCHING";
export const ATTENDANCEWEEKLY_FAILED = "ATTENDANCEWEEKLY_FAILED";
export const ATTENDANCEWEEKLY_SUCCESS = "ATTENDANCEWEEKLY_SUCCESS";

export const LEAVE_SETTING_FETCHING = "LEAVE_SETTING_FETCHING";
export const LEAVE_SETTING_FAILED = "LEAVE_SETTING_FAILED";
export const LEAVE_SETTING_SUCCESS = "LEAVE_SETTING_SUCCESS";

export const COMPANY_SHIFT_FETCHING = "COMPANY_SHIFT_FETCHING";
export const COMPANY_SHIFT_FAILED = "COMPANY_SHIFT_FAILED";
export const COMPANY_SHIFT_SUCCESS = "COMPANY_SHIFT_SUCCESS";

export const EXPENSE_EXPORT_WELFARE_FETCHING =
  "EXPENSE_EXPORT_WELFARE_FETCHING";
export const EXPENSE_EXPORT_WELFARE_FAILED = "EXPENSE_EXPORT_WELFARE_FAILED";
export const EXPENSE_EXPORT_WELFARE_SUCCESS = "EXPENSE_EXPORT_WELFARE_SUCCESS";

export const SALARY_REASONS_FETCHING = "SALARY_REASONS_FETCHING";
export const SALARY_REASONS_FAILED = "SALARY_REASONS_FAILED";
export const SALARY_REASONS_SUCCESS = "SALARY_REASONS_SUCCESS";

export const ORGANIZATION_LIST_FETCHING = "ORGANIZATION_LIST_FETCHING";
export const ORGANIZATION_LIST_FAILED = "ORGANIZATION_LIST_FAILED";
export const ORGANIZATION_LIST_SUCCESS = "ORGANIZATION_LIST_SUCCESS";

export const LEAVE_AVAILABLE_FETCHING = "LEAVE_AVAILABLE_FETCHING";
export const LEAVE_AVAILABLE_FAILED = "LEAVE_AVAILABLE_FAILED";
export const LEAVE_AVAILABLE_SUCCESS = "LEAVE_AVAILABLE_SUCCESS";

export const USERS_INFORMATIONS_FETCHING = "USERS_INFORMATIONS_FETCHING";
export const USERS_INFORMATION_FAILED = "USERS_INFORMATION_FAILED";
export const USERS_INFORMATION_SUCCESS = "USERS_INFORMATION_SUCCESS";

export const PDPAAPPROVE_FETCHING = "PDPAAPPROVE_FETCHING";
export const PDPAAPPROVE_FAILED = "PDPAAPPROVE_FAILED";
export const PDPAAPPROVE_SUCCESS = "PDPAAPPROVE_SUCCESS";

export const PDPAAPPROVELIST_FETCHING = " PDPAAPPROVELIST_FETCHING";
export const PDPAAPPROVELIST_FAILED = "PDPAAPPROVELIST_FAILED";
export const PDPAAPPROVELIST_SUCCESS = "PDPAAPPROVELIST_SUCCESS";

export const EMPLOYEE_SEARCH_FETCHING = "EMPLOYEE_SEARCH_FETCHING";
export const EMPLOYEE_SEARCH_FAILED = "EMPLOYEE_SEARCH_FAILED";
export const EMPLOYEE_SEARCH_SUCCESS = "EMPLOYEE_SEARCH_SUCCESS";

export const REPORT_SUMMARY_OVERTIME_SHIFT_FETCHING =
  "REPORT_SUMMARY_OVERTIME_SHIFT_FETCHING";
export const REPORT_SUMMARY_OVERTIME_SHIFT_FAILED =
  "REPORT_SUMMARY_OVERTIME_SHIFT_FAILED";
export const REPORT_SUMMARY_OVERTIME_SHIFT_SUCCESS =
  "REPORT_SUMMARY_OVERTIME_SHIFT_SUCCESS";

export const ALL_PROJECT_EMPLOYEES_FAILED = "ALL_PROJECT_EMPLOYEES_FAILED";
export const ALL_PROJECT_EMPLOYEES_SUCCESS = "ALL_PROJECT_EMPLOYEES_SUCCESS";

export const ALL_PROJECT_USER_SUCCESS_ById = "ALL_PROJECT_USER_SUCCESS_ById";
export const ALL_PROJECT_USER_FAILED_ById = "ALL_PROJECT_USER_FAILED_ById";

export const ALL_PROJECT_USER_SUCCESS = "ALL_PROJECT_USER_SUCCESS";
export const ALL_PROJECT_USER_FAILED = "ALL_PROJECT_USER_FAILED";

export const GET_USERSTIME_FOR_APPROVE_FAILED =
  "GET_USERSTIME_FOR_APPROVE_FAILED";
export const GET_USERSTIME_FOR_APPROVE_SUCCESS =
  "GET_USERSTIME_FOR_APPROVE_SUCCESS";

export const REPORT_SUMMARY_OVERTIME_SHIFT_INDIVIDUAL_FETCHING =
  "REPORT_SUMMARY_OVERTIME_SHIFT_INDIVIDUAL_FETCHING";
export const REPORT_SUMMARY_OVERTIME_SHIFT_INDIVIDUAL_FAILED =
  "REPORT_SUMMARY_OVERTIME_SHIFT_INDIVIDUAL_FAILED";
export const REPORT_SUMMARY_OVERTIME_SHIFT_INDIVIDUAL_SUCCESS =
  "REPORT_SUMMARY_OVERTIME_SHIFT_INDIVIDUAL_SUCCESS";

export const REPORT_LEAVE_ABSENT_LATE_FETCHING =
  "REPORT_LEAVE_ABSENT_LATE_FETCHING";
export const REPORT_LEAVE_ABSENT_LATE_FAILED =
  "REPORT_LEAVE_ABSENT_LATE_FAILED";
export const REPORT_LEAVE_ABSENT_LATE_SUCCESS =
  "REPORT_LEAVE_ABSENT_LATE_SUCCESS";

export const REPORT_LEAVE_ABSENT_LATE_PAYROLL_FETCHING =
  "REPORT_LEAVE_ABSENT_LATE_PAYROLL_FETCHING";
export const REPORT_LEAVE_ABSENT_LATE_PAYROLL_FAILED =
  "REPORT_LEAVE_ABSENT_LATE_PAYROLL_FAILED";
export const REPORT_LEAVE_ABSENT_LATE_PAYROLL_SUCCESS =
  "REPORT_LEAVE_ABSENT_LATE_PAYROLL_SUCCESS";

export const LOGDOWNLOADPDF_FETCHING = "LOGDOWNLOADPDF_FETCHING";
export const LOGDOWNLOADPDF_FAILED = "LOGDOWNLOADPDF_FAILED";
export const LOGDOWNLOADPDF_SUCCESS = "LOGDOWNLOADPDF_SUCCESS";

export const LEAVELIST_FETCHING = "LEAVELIST_FETCHING";
export const LEAVELIST_FAILED = "LEAVELIST_FAILED";
export const LEAVELIST_SUCCESS = "LEAVELIST_SUCCESS";

export const SALARYADJUSTEMPLOYEE_FETCHING = "SALARYADJUSTEMPLOYEE_FETCHING";
export const SALARYADJUSTEMPLOYEE_FAILED = "SALARYADJUSTEMPLOYEE_FAILED";
export const SALARYADJUSTEMPLOYEE_SUCCESS = "SALARYADJUSTEMPLOYEE_SUCCESS";

export const DASHBOARDEMPLOYEE_FETCHING = "DASHBOARDEMPLOYEE_FETCHING";
export const DASHBOARDEMPLOYEE_FAILED = "DASHBOARDEMPLOYEE_FAILED";
export const DASHBOARDEMPLOYEE_SUCCESS = "DASHBOARDEMPLOYEE_SUCCESS";

export const MANAGER_APPROVE_SETTING_FETCHING =
  "MANAGER_APPROVE_SETTING_FETCHING";
export const MANAGER_APPROVE_SETTING_FAILED = "MANAGER_APPROVE_SETTING_FAILED";
export const MANAGER_APPROVE_SETTING_SUCCESS =
  "MANAGER_APPROVE_SETTING_SUCCESS";

export const WORKINGTIME_FETCHING = "WORKINGTIME_FETCHING";
export const WORKINGTIME_FAILED = "WORKINGTIME_FAILED";
export const WORKINGTIME_SUCCESS = "WORKINGTIME_SUCCESS";

export const TIMEATTENDANCE_FETCHING = "TIMEATTENDANCE_FETCHING";
export const TIMEATTENDANCE_FAILED = "TIMEATTENDANCE_FAILED";
export const TIMEATTENDANCE_SUCCESS = "TIMEATTENDANCE_SUCCESS";

export const EMPLOYEE_SHIFT_CHANGE_HISTORY_FETCHING =
  "EMPLOYEE_SHIFT_CHANGE_HISTORY_FETCHING";
export const EMPLOYEE_SHIFT_CHANGE_HISTORY_FAILED =
  "EMPLOYEE_SHIFT_CHANGE_HISTORY_FAILED";
export const EMPLOYEE_SHIFT_CHANGE_HISTORY_SUCCESS =
  "EMPLOYEE_SHIFT_CHANGE_HISTORY_SUCCESS";

export const AFFILIATE_SHIFT_GROUP_FETCHING = "AFFILIATE_SHIFT_GROUP_FETCHING";
export const AFFILIATE_SHIFT_GROUP_FAILED = "AFFILIATE_SHIFT_GROUP_FAILED";
export const AFFILIATE_SHIFT_GROUP_SUCCESS = "AFFILIATE_SHIFT_GROUP_SUCCESS";

export const KPI_FETCHING = "KPI_FETCHING";
export const KPI_FAILED = "KPI_FAILED";
export const KPI_SUCCESS = "KPI_SUCCESS";

export const KPI_ORGANIZATION_FETCHING = "KPI_ORGANIZATION_FETCHING";
export const KPI_ORGANIZATION_FAILED = "KPI_ORGANIZATION_FAILED";
export const KPI_ORGANIZATION_SUCCESS = "KPI_ORGANIZATION_SUCCESS";

export const KPI_TEAM_FETCHING = "KPI_TEAM_FETCHING";
export const KPI_TEAM_FAILED = "KPI_TEAM_FAILED";
export const KPI_TEAM_SUCCESS = "KPI_TEAM_SUCCESS";

export const KPI_DATE_FETCHING = "KPI_DATE_FETCHING";
export const KPI_DATE_FAILED = "KPI_DATE_FAILED";
export const KPI_DATE_SUCCESS = "KPI_DATE_SUCCESS";

export const POST_KPI_SUCCESS = "POST_KPI_SUCCESS";
export const POST_KPI_FAILED = "POST_KPI_FAILED";

export const GET_KPI_ID_SUCCESS = "GET_KPI_ID_SUCCESS";
export const GET_KPI_ID_FETCHING = "GET_KPI_ID_FETCHING";
export const GET_KPI_ID_FAILED = "GET_KPI_ID_FAILED";

export const KPI_APPROVAL_SUCCESS = "KPI_APPROVAL_SUCCESS";
export const KPI_APPROVAL_FETCHING = "KPI_APPROVAL_FETCHING";
export const KPI_APPROVAL_FAILED = "KPI_APPROVAL_FAILED";

export const GET_KPI_UPDATE_SUCCESS = "GET_KPI_UPDATE_SUCCESS";
export const GET_KPI_UPDATE_FETCHING = "GET_KPI_UPDATE_FETCHING";
export const GET_KPI_UPDATE_FAILED = "GET_KPI_UPDATE_FAILED";

export const GET_KPI_UPDATE_ID_SUCCESS = "GET_KPI_UPDATE_ID_SUCCESS";
export const GET_KPI_UPDATE_ID_FETCHING = "GET_KPI_UPDATE_ID_FETCHING";
export const GET_KPI_UPDATE_ID_FAILED = "GET_KPI_UPDATE_ID_FAILED";

export const KPI_APPROVAL_RESULT_SUCCESS = "KPI_APPROVAL_RESULT_SUCCESS";
export const KPI_APPROVAL_RESULT_FETCHING = "KPI_APPROVAL_RESULT_FETCHING";
export const KPI_APPROVAL_RESULT_FAILED = "KPI_APPROVAL_RESULT_FAILED";

export const KPI_EVALUATION_SUCCESS = "KPI_EVALUATION_SUCCESS";
export const KPI_EVALUATION_FETCHING = "KPI_EVALUATION_FETCHING";
export const KPI_EVALUATION_FAILED = "KPI_EVALUATION_FAILED";

export const KPI_CARLIBRATE_SUCCESS = "KPI_CARLIBRATE_SUCCESS";
export const KPI_CARLIBRATE_FETCHING = "KPI_CARLIBRATE_FETCHING";
export const KPI_CARLIBRATE_FAILED = "KPI_CARLIBRATE_FAILED";

export const KPI_DEPARTMENTCARLIBRATE_SUCCESS =
  "KPI_DEPARTMENTCARLIBRATE_SUCCESS";
export const KPI_DEPARTMENTCARLIBRATE_FETCHING =
  "KPI_DEPARTMENTCARLIBRATE_FETCHING";
export const KPI_DEPARTMENTCARLIBRATE_FAILED =
  "KPI_DEPARTMENTCARLIBRATE_FAILED";

export const KPI_IsSuccessful_SUCCESS = "KPI_IsSuccessful_SUCCESS";
export const KPI_IsSuccessful_FETCHING = "KPI_IsSuccessful_FETCHING";
export const KPI_IsSuccessful_FAILED = "KPI_IsSuccessful_FAILED";

export const KPI_UpdateAllAchievement_ID_SUCCESS =
  "KPI_UpdateAllAchievement_ID_SUCCESS";
export const KPI_UpdateAllAchievement_ID_FETCHING =
  "KPI_UpdateAllAchievement_ID_FETCHING";
export const KPI_UpdateAllAchievement_ID_FAILED =
  "KPI_UpdateAllAchievement_ID_FAILED";

export const KPI_All_SUCCESS = "KPI_All_SUCCESS";
export const KPI_All_FETCHING = "KPI_All_FETCHING";
export const KPI_All_FAILED = "KPI_All_FAILED";

export const ORGANIZATION_MANAGE_SUCCESS = "ORGANIZATION_MANAGE_SUCCESS";
export const ORGANIZATION_MANAGE_FETCHING = "ORGANIZATION_MANAGE_FETCHING";
export const ORGANIZATION_MANAGE_FAILED = "ORGANIZATION_MANAGE_FAILED";

export const OVERTIME_36HOURS_FETCHING = "OVERTIME_36HOURS_FETCHING";
export const OVERTIME_36HOURS_SUCCESS = "OVERTIME_36HOURS_SUCCESS";
export const OVERTIME_36HOURS_FAILED = "OVERTIME_36HOURS_FAILED";

export const OVERTIME_36HOURS_DETAIL_FETCHING =
  "OVERTIME_36HOURS_DETAIL_FETCHING";
export const OVERTIME_36HOURS_DETAIL_SUCCESS =
  "OVERTIME_36HOURS_DETAIL_SUCCESS";
export const OVERTIME_36HOURS_DETAIL_FAILED = "OVERTIME_36HOURS_DETAIL_FAILED";

export const INTERVIEW_PANEL_FETCHING = "INTERVIEW_PANEL_FETCHING";
export const INTERVIEW_PANEL_FAILED = "INTERVIEW_PANEL_FAILED";
export const INTERVIEW_PANEL_SUCCESS = "INTERVIEW_PANEL_SUCCESS";

export const PERSONAL_TAX_FETCHING = "PERSONAL_TAX_FETCHING";
export const PERSONAL_TAX_FAILED = "PERSONAL_TAX_FAILED";
export const PERSONAL_TAX_SUCCESS = "PERSONAL_TAX_SUCCESS";

export const TAX_SUMMARY_FETCHING = "TAX_SUMMARY_FETCHING";
export const TAX_SUMMARY_FAILED = "TAX_SUMMARY_FAILED";
export const TAX_SUMMARY_SUCCESS = "TAX_SUMMARY_SUCCESS";

export const LEAVE_REQUEST_LIST_FETCHING = "LEAVE_REQUEST_LIST_FETCHING";
export const LEAVE_REQUEST_LIST_FAILED = "LEAVE_REQUEST_LIST_FAILED";
export const LEAVE_REQUEST_LIST_SUCCESS = "LEAVE_REQUEST_LIST_SUCCESS";

export const DILIGENCE_ALLOWANCE_REPORT_FETCHING =
  "DILIGENCE_ALLOWANCE_REPORT_FETCHING";
export const DILIGENCE_ALLOWANCE_REPORT_FAILED =
  "DILIGENCE_ALLOWANCE_REPORT_FAILED";
export const DILIGENCE_ALLOWANCE_REPORT_SUCCESS =
  "DILIGENCE_ALLOWANCE_REPORT_SUCCESS";

export const ADDITION_SPECIAL_DILIGENCE_ALLOWANCE_SETTING_FETCHING =
  "ADDITION_SPECIAL_DILIGENCE_ALLOWANCE_SETTING_FETCHING";
export const ADDITION_SPECIAL_DILIGENCE_ALLOWANCE_SETTING_FAILED =
  "ADDITION_SPECIAL_DILIGENCE_ALLOWANCE_SETTING_FAILED";
export const ADDITION_SPECIAL_DILIGENCE_ALLOWANCE_SETTING_SUCCESS =
  "ADDITION_SPECIAL_DILIGENCE_ALLOWANCE_SETTING_SUCCESS";

export const DOCUMENT_COMAPANY_FETCHING = "DOCUMENT_COMAPANY_FETCHING ";
export const DOCUMENT_COMAPANY_FAILED = "DOCUMENT_COMAPANY_FAILED";
export const DOCUMENT_COMAPANY_SUCCESS = "DOCUMENT_COMAPANY_SUCCESS";

export const EMPLOYEE_DOCUMENTS_FETCHING = "EMPLOYEE_DOCUMENTS_FETCHING";
export const EMPLOYEE_DOCUMENTS_FAILED = "EMPLOYEE_DOCUMENTS_FAILED";
export const EMPLOYEE_DOCUMENTS_SUCCESS = "EMPLOYEE_DOCUMENTS_SUCCESS";

export const GET_ALL_DOCUMENT_TYPE_NAME_FETCHING = "DOCUMENT_ITEM_FETCHING";
export const GET_ALL_DOCUMENT_TYPE_NAME_FAILURE =
  "GET_ALL_DOCUMENT_TYPE_NAME_FAILURE";
export const GET_ALL_DOCUMENT_TYPE_NAME_SUCCESS =
  "GET_ALL_DOCUMENT_TYPE_NAME_SUCCESS";

export const GET_ALL_DOCUMENT_TYPE_FETCHING = "GET_ALL_DOCUMENT_TYPE_FETCHING";
export const GET_ALL_DOCUMENT_TYPE_FAILURE = "GET_ALL_DOCUMENT_TYPE_FAILURE";
export const GET_ALL_DOCUMENT_TYPE_SUCCESS = "GET_ALL_DOCUMENT_TYPE_SUCCESS";

export const GET_ALL_DOCUMENT_GROUP_NAME_FETCHING =
  "GET_ALL_DOCUMENT_GROUP_NAME_FETCHING";
export const GET_ALL_DOCUMENT_GROUP_NAME_FAILURE =
  "GET_ALL_DOCUMENT_GROUP_NAME_FAILURE";
export const GET_ALL_DOCUMENT_GROUP_NAME_SUCCESS =
  "GET_ALL_DOCUMENT_GROUP_NAME_SUCCESS";

export const COMPANY_DOCUMENTS_FAILED = "COMPANY_DOCUMENTS_FAILED";
export const COMPANY_DOCUMENTS_FETCHING = "COMPANY_DOCUMENTS_FETCHING";
export const COMPANY_DOCUMENTS_SUCCESS = "COMPANY_DOCUMENTS_SUCCESS";

export const GET_UNION_DOCUMENT_GROUP_TYPE_FETCHING =
  " GET_UNION_DOCUMENT_GROUP_TYPE_FETCHING";
export const GET_UNION_DOCUMENT_GROUP_TYP_SUCCESS =
  "GET_UNION_DOCUMENT_GROUP_TYP_SUCCESS";
export const GET_UNION_DOCUMENT_GROUP_TYPE_FAILURE =
  "GET_UNION_DOCUMENT_GROUP_TYPE_FAILURE";

export const ADDITION_SPECIAL_CONDITION_TIME_SETTING_FETCHING =
  "ADDITION_SPECIAL_CONDITION_TIME_SETTING_FETCHING";
export const ADDITION_SPECIAL_CONDITION_TIME_SETTING_FAILED =
  "ADDITION_SPECIAL_CONDITION_TIME_SETTING_FAILED";
export const ADDITION_SPECIAL_CONDITION_TIME_SETTING_SUCCESS =
  "ADDITION_SPECIAL_CONDITION_TIME_SETTING_SUCCESS";

export const ADDITION_SPECIAL_CONDITION_TIME_REPORT_FETCHING =
  "ADDITION_SPECIAL_CONDITION_TIME_REPORT_FETCHING";
export const ADDITION_SPECIAL_CONDITION_TIME_REPORT_FAILED =
  "ADDITION_SPECIAL_CONDITION_TIME_REPORT_FAILED";
export const ADDITION_SPECIAL_CONDITION_TIME_REPORT_SUCCESS =
  "ADDITION_SPECIAL_CONDITION_TIME_REPORT_SUCCESS";

export const ADDITION_SPECIAL_CONDITION_TIME_PERSONAL_SETTING_FETCHING =
  "ADDITION_SPECIAL_CONDITION_TIME_PERSONAL_SETTING_FETCHING";
export const ADDITION_SPECIAL_CONDITION_TIME_PERSONAL_SETTING_FAILED =
  "ADDITION_SPECIAL_CONDITION_TIME_PERSONAL_SETTING_FAILED";
export const ADDITION_SPECIAL_CONDITION_TIME_PERSONAL_SETTING_SUCCESS =
  "ADDITION_SPECIAL_CONDITION_TIME_PERSONAL_SETTING_SUCCESS";

export const MANAGER_PENDING_REQUEST_LEAVE_FETCHING =
  "MANAGER_PENDING_REQUEST_LEAVE_FETCHING";
export const MANAGER_PENDING_REQUEST_LEAVE_FAILED =
  "MANAGER_PENDING_REQUEST_LEAVE_FAILED";
export const MANAGER_PENDING_REQUEST_LEAVE_SUCCESS =
  "MANAGER_PENDING_REQUEST_LEAVE_SUCCESS";

export const ADMIN_PENDING_REQUEST_LEAVE_FETCHING =
  "ADMIN_PENDING_REQUEST_LEAVE_FETCHING";
export const ADMIN_PENDING_REQUEST_LEAVE_FAILED =
  "ADMIN_PENDING_REQUEST_LEAVE_FAILED";
export const ADMIN_PENDING_REQUEST_LEAVE_SUCCESS =
  "ADMIN_PENDING_REQUEST_LEAVE_SUCCESS";

export const EMEMO_ALL_FETCHING = "EMEMO_ALL_FETCHING";
export const EMEMO_ALL_SUCCESS = "EMEMO_ALL_SUCCESS";
export const EMEMO_ALL_FAILED = "EMEMO_ALL_FAILED";

export const EMEMO_UPDATE_STATUS_FETCHING = "EMEMO_UPDATE_STATUS_FETCHING";
export const EMEMO_UPDATE_STATUS_SUCCESS = "EMEMO_UPDATE_STATUS_SUCCESS";
export const EMEMO_UPDATE_STATUS_FAILED = "EMEMO_UPDATE_STATUS_FAILED";

export const DEDUCTIONS_FETCHING = "DEDUCTIONS_FETCHING";
export const DEDUCTIONS_SUCCESS = "DEDUCTIONS_SUCCESS";
export const DEDUCTIONS_FAILED = "DEDUCTIONS_FAILED";

export const MOBILE_TOKEN_FETCHING = "MOBILE_TOKEN_FETCHING";
export const MOBILE_TOKEN_SUCCESS = "MOBILE_TOKEN_SUCCESS";
export const MOBILE_TOKEN_FAILED = "MOBILE_TOKEN_FAILED";

export const POTENTIAL_FETCHING = "POTENTIAL_FETCHING";
export const POTENTIAL_SUCCESS = "POTENTIAL_SUCCESS";
export const POTENTIAL_FAILED = "POTENTIAL_FAILED";

export const GET_SMART_DOCUMENTS_FETCHING = "GET_SMART_DOCUMENTS_FETCHING";
export const GET_SMART_DOCUMENT_FAILED = "GET_SMART_DOCUMENT_FAILED";
export const GET_SMART_DOCUMENT_SUCCESS = "GET_SMART_DOCUMENT_SUCCESS";

export const SMART_DOCUMENT_FETCHING = "SMART_COMAPANY_FETCHING ";
export const SMART_DOCUMENT_FAILED = "SMART__COMAPANY_FAILED";
export const SMART_DOCUMENT_SUCCESS = "SMART__COMAPANY_SUCCESS";

export const EXPENSE_PAYMENT_TYPES_FETCHING = "EXPENSE_PAYMENT_TYPES_FETCHING ";
export const EXPENSE_PAYMENT_TYPES_FAILED = "EXPENSE_PAYMENT_TYPES_FAILED";
export const EXPENSE_PAYMENT_TYPES_SUCCESS = "EXPENSE_PAYMENT_TYPES_SUCCESS";

export const EXPENSE_RETURNADVANCE_TYPES_FETCHING =
  "EXPENSE_RETURNADVANCE_TYPES_FETCHING";
export const EXPENSE_RETURNADVANCE_TYPES_FAILED =
  "EXPENSE_RETURNADVANCE_TYPES_FAILED";
export const EXPENSE_RETURNADVANCE_TYPES_SUCCESS =
  "EXPENSE_RETURNADVANCE_TYPES_SUCCESS";

export const CHATBOT_DOCUMENT_FETCHING = "CHATBOT_DOCUMENT_FETCHING";
export const CHATBOT_DOCUMENT_FAILED = "CHATBOT_DOCUMENT_FAILED";
export const CHATBOT_DOCUMENT_SUCCESS = "CHATBOT_DOCUMENT_SUCCESS";

export const GET_TIMELINE_KEYRESULT_FETCHING =
  "GET_TIMELINE_KEYRESULT_FETCHING";
export const GET_TIMELINE_KEYRESULT_SUCCESS = "GET_TIMELINE_KEYRESULT_SUCCESS";
export const GET_TIMELINE_KEYRESULT_FAILURE = "GET_TIMELINE_KEYRESULT_FAILURE";

export const EMPLOYEE_DILIGENCE_INFO_FETCHING =
  "EMPLOYEE_DILIGENCE_INFO_FETCHING";
export const EMPLOYEE_DILIGENCE_INFO_SUCCESS =
  "EMPLOYEE_DILIGENCE_INFO_SUCCESS";
export const EMPLOYEE_DILIGENCE_INFO_FAILED = "EMPLOYEE_DILIGENCE_INFO_FAILED";

export const SMART_DOCUMENT_FETCHING_FILE = "SMART_COMAPANY_FETCHING_FILE ";
export const SMART_DOCUMENT_FAILED_FILE = "SMART__COMAPANY_FAILED_FILE";
export const SMART_DOCUMENT_SUCCESS_FILE = "SMART__COMAPANY_SUCCESS_FILE";

export const OVERVIEW_USER_LEAVE_FETCHING = "OVERVIEW_USER_LEAVE_FETCHING";
export const OVERVIEW_USER_LEAVE_SUCCESS = "OVERVIEW_USER_LEAVE_SUCCESS";
export const OVERVIEW_USER_LEAVE_FAILED = "OVERVIEW_USER_LEAVE_FAILED";

export const OVERVIEW_USER_ABSENT_LATE_FETCHING =
  "OVERVIEW_USER_ABSENT_LATE_FETCHING";
export const OVERVIEW_USER_ABSENT_LATE_SUCCESS =
  "OVERVIEW_USER_ABSENT_LATE_SUCCESS";
export const OVERVIEW_USER_ABSENT_LATE_FAILED =
  "OVERVIEW_USER_ABSENT_LATE_FAILED";

export const OVERVIEW_USER_OT_FETCHING = "OVERVIEW_USER_OT_FETCHING";
export const OVERVIEW_USER_OT_SUCCESS = "OVERVIEW_USER_OT_SUCCESS";
export const OVERVIEW_USER_OT_FAILED = "OVERVIEW_USE_OT_FAILED";

export const OVERVIEW_USER_NOTIFICATION_FETCHING =
  "OVERVIEW_USER_NOTIFICATION_FETCHING";
export const OVERVIEW_USER_NOTIFICATION_SUCCESS =
  "OVERVIEW_USER_NOTIFICATION_SUCCESS";
export const OVERVIEW_USER_NOTIFICATION_FAILED =
  "OVERVIEW_USE_NOTIFICATION_FAILED";

export const COST_CENTER_LIST_FETCHING = "COST_CENTER_LIST_FETCHING";
export const COST_CENTER_LIST_FAILED = "COST_CENTER_LIST_FAILED";
export const COST_CENTER_LIST_SUCCESS = "COST_CENTER_LIST_SUCCESS";
export const EXPENSE_TYPES_FETCHING = "EXPENSE_TYPES_FETCHING";
export const EXPENSE_TYPES_FAILED = "EXPENSE_TYPES_FAILED";
export const EXPENSE_TYPES_SUCCESS = "EXPENSE_TYPES_SUCCESS";

export const EXPENSE_ADVANCES_FETCHING = "EXPENSE_ADVANCES_FETCHING";
export const EXPENSE_ADVANCES_FAILED = "EXPENSE_ADVANCES_FAILED";
export const EXPENSE_ADVANCES_SUCCESS = "EXPENSE_ADVANCES_SUCCESS";

export const EXPENSE_SETTING_FETCHING = "EXPENSE_SETTING_FETCHING";
export const EXPENSE_SETTING_FAILED = "EXPENSE_SETTING_FAILED";
export const EXPENSE_SETTING_SUCCESS = "EXPENSE_SETTING_SUCCESS";

export const EXPENSE_SETTINGS_FETCHING = "EXPENSE_SETTINGS_FETCHING";
export const EXPENSE_SETTINGS_FAILED = "EXPENSE_SETTINGS_FAILED";
export const EXPENSE_SETTINGS_SUCCESS = "EXPENSE_SETTINGS_SUCCESS";

export const EXPENSE_PRORATE_TYPES_FETCHING = "EXPENSE_PRORATE_TYPES_FETCHING";
export const EXPENSE_PRORATE_TYPES_FAILED = "EXPENSE_PRORATE_TYPES_FAILED";
export const EXPENSE_PRORATE_TYPES_SUCCESS = "EXPENSE_PRORATE_TYPES_SUCCESS";

export const EXPENSE_ALLOWANCE_TYPES_FETCHING =
  "EXPENSE_ALLOWANCE_TYPES_FETCHING";
export const EXPENSE_ALLOWANCE_TYPES_FAILED = "EXPENSE_ALLOWANCE_TYPES_FAILED";
export const EXPENSE_ALLOWANCE_TYPES_SUCCESS =
  "EXPENSE_ALLOWANCE_TYPES_SUCCESS";

export const EXPENSE_SETTINGS_MILEAGE_FETCHING =
  "EXPENSE_SETTINGS_MILEAGE_FETCHING";
export const EXPENSE_SETTINGS_MILEAGE_FAILED =
  "EXPENSE_SETTINGS_MILEAGE_FAILED";
export const EXPENSE_SETTINGS_MILEAGE_SUCCESS =
  "EXPENSE_SETTINGS_MILEAGE_SUCCESS";

export const EXPENSE_SETTINGS_ALLOWANCE_FETCHING =
  "EXPENSE_SETTINGS_ALLOWANCE_FETCHING";
export const EXPENSE_SETTINGS_ALLOWANCE_FAILED =
  "EXPENSE_SETTINGS_ALLOWANCE_FAILED";
export const EXPENSE_SETTINGS_ALLOWANCE_SUCCESS =
  "EXPENSE_SETTINGS_ALLOWANCE_SUCCESS";

export const EXPENSE_SETTING_MILEAGE_FETCHING =
  "EXPENSE_SETTING_MILEAGE_FETCHING";
export const EXPENSE_SETTING_MILEAGE_FAILED = "EXPENSE_SETTING_MILEAGE_FAILED";
export const EXPENSE_SETTING_MILEAGE_SUCCESS =
  "EXPENSE_SETTING_MILEAGE_SUCCESS";

export const EXPENSE_SETTING_ALLOWANCE_FETCHING =
  "EXPENSE_SETTING_ALLOWANCE_FETCHING";
export const EXPENSE_SETTING_ALLOWANCE_FAILED =
  "EXPENSE_SETTING_ALLOWANCE_FAILED";
export const EXPENSE_SETTING_ALLOWANCE_SUCCESS =
  "EXPENSE_SETTING_ALLOWANCE_SUCCESS";

export const EXPENSE_SETTINGS_COSTELEMENT_FETCHING =
  "EXPENSE_SETTINGS_COSTELEMENT_FETCHING";
export const EXPENSE_SETTINGS_COSTELEMENT_FAILED =
  "EXPENSE_SETTINGS_COSTELEMENT_FAILED";
export const EXPENSE_SETTINGS_COSTELEMENT_SUCCESS =
  "EXPENSE_SETTINGS_COSTELEMENT_SUCCESS";

export const JOBDESCRIPTION_FETCHING = "JOBDESCRIPTION_FETCHING";
export const JOBDESCRIPTION_FAILED = "JOBDESCRIPTION_FAILED";
export const JOBDESCRIPTION_SUCCESS = "JOBDESCRIPTION_SUCCESS";
export const POTENTIAL_UNI_QUESTION_SUCCESS = "POTENTIAL_UNI_QUESTION_SUCCESS";
export const POTENTIAL_UNI_QUESTION_FETCHING =
  "POTENTIAL_UNI_QUESTION_FETCHING";
export const POTENTIAL_UNI_QUESTION_FAILED = "POTENTIAL_UNI_QUESTION_FAILED";

export const POTENTIAL_APPRAISER_SUCCESS = "POTENTIAL_APPRAISER_SUCCESS";
export const POTENTIAL_APPRAISER_FETCHING = "POTENTIAL_APPRAISER_FETCHING";
export const POTENTIAL_APPRAISER_FAILED = "POTENTIAL_APPRAISER_SFAILED";
export const POTENTIAL_APPRAISER_UPDATE = "POTENTIAL_APPRAISER_UPDATE";

export const POTENTIAL_ASSESSMENT_SUCCESS = "POTENTIAL_ASSESSMENT_SUCCESS";
export const POTENTIAL_ASSESSMENT_FAILED = "POTENTIAL_ASSESSMENT_FAILED";
export const POTENTIAL_ASSESSMENT_FETCHING = "POTENTIAL_ASSESSMENT_FETCHING";

export const POTENTIAL_QUESTION_LIST_SUCCESS =
  "POTENTIAL_QUESTION_LIST_SUCCESS";
export const POTENTIAL_QUESTION_LIST_FAILED = "POTENTIAL_QUESTION_LIST_FAILED";
export const POTENTIAL_QUESTION_LIST_FETCHING =
  "POTENTIAL_QUESTION_LIST_FETCHING";

export const POTENTIAL_OVERVIEW_APPRAISER_SUCCESS =
  "POTENTIAL_OVERVIEW_APPRAISER_SUCCESS";
export const POTENTIAL_OVERVIEW_APPRAISER_FAILED =
  "POTENTIAL_OVERVIEW_APPRAISER_FAILED";
export const POTENTIAL_OVERVIEW_APPRAISER_FETCHING =
  "POTENTIAL_OVERVIEW_APPRAISER_FETCHING";

export const POTENTIAL_DETAIL_OVERVIEW_APPRAISER_SUCCESS = "POTENTIAL_DETAIL_OVERVIEW_APPRAISER_SUCCESS";
export const POTENTIAL_DETAIL_OVERVIEW_APPRAISER_FAILED = "POTENTIAL_DETAIL_OVERVIEW_APPRAISER_FAILED";
export const POTENTIAL_DETAIL_OVERVIEW_APPRAISER_FETCHING = "POTENTIAL_DETAIL_OVERVIEW_APPRAISER_FETCHING";

export const RETIREMENT_SETTING_SUCCESS = "RETIREMENT_SETTING_SUCCESS";
export const RETIREMENT_SETTING_FAILED = "RETIREMENT_SETTING_FAILED";
export const RETIREMENT_SETTING_FETCHING = "RETIREMENT_SETTING_FETCHING";