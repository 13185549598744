import React from "react";
import { Box, styled, Typography } from "@mui/material";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { useTranslation } from "react-i18next";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const FileIcon = (props) => {
  const { fileURL } = props;
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="wrap-head">
        <StyledHeadLabel variant="h6">{t("File")}</StyledHeadLabel>
      </div>
      {fileURL ? (
        <Box
          style={{
            position: "relative",
            textAlign: "center",
          }}
        >
          <div
            style={{
              padding: 15,
              backgroundColor: "#f9f9fb",
              display: "flex",
              borderRadius: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              fileURL && window.open(`${fileURL.URL}`, "_blank");
            }}
          >
            <FilePresentIcon
              height="24"
              style={{
                marginRight: 10,
              }}
            ></FilePresentIcon>
            <Typography
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
              }}
            >
              {fileURL.path}
            </Typography>
          </div>
        </Box>
      ) : (
        <Box>
          <div
            style={{
              padding: 24,
              backgroundColor: "#f9f9fb",
              display: "flex",
              justifyContent: "center",
              borderRadius: 15,
            }}
          >
            <Typography color="error">{t("NoAttachedFile")}</Typography>
          </div>
        </Box>
      )}
    </div>
  );
};

export default FileIcon;
