import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Rating,
  Stack,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getKpiPendingEvaluation,
  updateKPIEvaluationManager,
} from "../../../../../actions/kpi";
import {
  getUserFullName,
  getUserPosition,
} from "../../../../../utils/userData";
import TableCustom from "../../../shared/tableCustom";
import Loading from "../../../shared/loading";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import dayjs from "dayjs";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  borderRadius: "30px",
  overflow: "hidden",
  border: "1px solid #EEEEEE",
  display: "flex",
  justifyContent: "center",
  width: "fit-content",
});

const StyledToggleButton = styled(ToggleButton)(({ selectedColor }) => ({
  flex: 1,
  borderRadius: "30px",
  "&.Mui-selected": {
    backgroundColor: selectedColor,
    color: "#FFFFFF",
  },
  "&.MuiToggleButtonGroup-grouped": {
    border: "none",
    "&:not(:first-of-type)": {
      borderLeft: "1px solid #EEEEEE",
    },
  },
  "&:hover": {
    backgroundColor: `${selectedColor} !important`,
    color: "#FFFFFF",
  },
  backgroundColor: "#EEEEEE",
  color: "#000000",
  textAlign: "center",
  minWidth: "100px",
  maxWidth: "200px",
}));

const KpisIndividualPlanView = ({ data, open, handleClose, idCompany }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [ratingValue, setRatingValue] = useState(0);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      managerRating: 0,
      managerFeedback: "",
      managerEvaluate: "1",
    },
  });

  const handleEvaluation = async (formData) => {
    formData.managerEvaluate = formData.managerEvaluate === "1" ? 1 : 0;
    formData.managerEvaluateAt = dayjs().format("YYYY-MM-DD HH:mm:ss");
    formData.managerRating = formData.managerRating || 0;

    const res = await dispatch(
      updateKPIEvaluationManager(data.idKPI, formData)
    );
    if (res && res.status === 200) {
      dispatch(
        openNotificationAlert({
          type: "success",
          message: t("EvaluationSuccess"),
        })
      );
      dispatch(getKpiPendingEvaluation({ idCompany }));
    } else {
      dispatch(
        openNotificationAlert({
          type: "error",
          message: t("AnErrorOccurred"),
        })
      );
    }
    handleClose();
  };

  const renderEmployeeEvaluate = (evaluate) => {
    if (evaluate === 1) {
      return (
        <Typography
          whiteSpace="pre-line"
          style={{ color: "#50B498", fontSize: "26px", fontWeight: 500 }}
        >
          {`${t("Success")}`}
        </Typography>
      );
    } else if (evaluate === 0) {
      return (
        <Typography
          whiteSpace="pre-line"
          style={{ color: "#FF6969", fontSize: "26px", fontWeight: 500 }}
        >
          {`${t("Unsuccess")}`}
        </Typography>
      );
    }
    return null;
  };

  const renderEmployeeRating = (rating) => {
    if (rating !== null) {
      return (
        <Box display="flex" alignItems="center" gap="4px">
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: 500,
              color: "#FFC403",
              marginLeft: "4px",
            }}
          >
            {rating}
          </Typography>
          {Array.from({ length: rating }, (_, index) => (
            <StarIcon key={index} sx={{ fontSize: "26px", color: "#FFC403" }} />
          ))}
        </Box>
      );
    }
    return null;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{ style: { borderRadius: "15px" } }}
    >
      <div
        style={{
          background: "rgb(79, 101, 223)",
          padding: "16px",
        }}
      >
        <Typography variant="h5" fontWeight={500} color="#ffffff">
          {t("KPIEvaluateDetails")}
        </Typography>
      </div>
      <form onSubmit={handleSubmit(handleEvaluation)}>
        <DialogContent>
          <Stack spacing={2}>
            <Typography fontSize="22px">{data.kpiName}</Typography>
            <Stack gap={1}>
              <Box
                display="flex"
                alignItems="center"
                gap={"8px"}
                marginBottom="8px"
              >
                <AccessTimeIcon fontSize="14px" style={{ color: "#919eab" }} />
                <Typography fontSize="14px" lineHeight="1" color="text.third">
                  {`${dayjs(data.startDate).format(
                    i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                  )} - ${dayjs(data.endDate).format(
                    i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                  )}`}
                </Typography>
              </Box>
              <Typography>
                <span style={{ color: "#919eab", paddingRight: "8px" }}>
                  {t("Year")}
                </span>
                {i18n.resolvedLanguage === "th" ? data.year + 543 : data.year}
              </Typography>
              <Typography>
                <span style={{ color: "#919eab", paddingRight: "8px" }}>
                  {`${t("Scope")}`}
                </span>
                {data.scope}
              </Typography>
              <Typography>
                <span style={{ color: "#919eab", paddingRight: "8px" }}>
                  {`${t("Target")}`}
                </span>
                {data.target}
              </Typography>
              <Typography>
                <span style={{ color: "#919eab", paddingRight: "8px" }}>
                  {`${t("TypeMeasurement")}`}
                </span>
                {data.measureType}
              </Typography>
              <Typography>
                <span style={{ color: "#919eab", paddingRight: "8px" }}>
                  {t("Evidence")}
                </span>
                {data.evidence}
              </Typography>
              <Typography>
                <span style={{ color: "#919eab", paddingRight: "8px" }}>
                  {`${t("Descriptions")}`}
                </span>
                {data.description}
              </Typography>
              <Typography>
                <span style={{ color: "#919eab", paddingRight: "8px" }}>
                  {`${t("Weight")}`}
                </span>
                {`${data.weight}%`}
              </Typography>
              {data.employeeEvaluate !== null && (
                <>
                  <Typography
                    fontSize="18px"
                    fontWeight={500}
                    paddingTop="16px"
                    gutterBottom
                  >
                    <span style={{ color: "#919eab", paddingRight: "8px" }}>
                      {`${t("SelfAssessmentScore")}`}
                    </span>
                    {data.employeeRating}
                  </Typography>
                  <Typography
                    whiteSpace="pre-line"
                    style={{ marginTop: "8px" }}
                  >
                    <span style={{ color: "#919eab", paddingRight: "8px" }}>
                      {`${t("employeeEvaluate")}`}
                    </span>
                  </Typography>
                  <Box display="flex" alignItems="center" gap="36px">
                    {renderEmployeeEvaluate(data.employeeEvaluate)}
                    {renderEmployeeRating(data.employeeRaitng)}
                  </Box>
                  {data.employeeFeedback && (
                    <Typography whiteSpace="pre-line">
                      <span style={{ color: "#919eab", paddingRight: "8px" }}>
                        {`${t("EmployeeFeedback")}`}
                      </span>
                      {data.employeeFeedback}
                    </Typography>
                  )}
                </>
              )}
            </Stack>
            <Divider />
            <Grid container gap={2}>
              <Grid
                item
                xs={12}
                display="flex"
                gap={"32px"}
                alignItems="center"
              >
                <Box>
                  <Typography fontSize={16} fontWeight={500} gutterBottom>
                    {`${t("Evaluate")}`}
                  </Typography>
                  <Controller
                    name="managerEvaluate"
                    control={control}
                    render={({ field }) => (
                      <StyledToggleButtonGroup
                        {...field}
                        exclusive
                        value={field.value}
                      >
                        <StyledToggleButton
                          value="1"
                          selectedColor="#50B498"
                        >{`${t("Success")}`}</StyledToggleButton>
                        <StyledToggleButton
                          value="0"
                          selectedColor="#FF4C4C"
                        >{`${t("Failure")}`}</StyledToggleButton>
                      </StyledToggleButtonGroup>
                    )}
                  />
                </Box>
                <Box>
                  <Typography fontSize={16} fontWeight={500} gutterBottom>
                    {`${t("Rate")} ${ratingValue ? ratingValue : ""}`}
                  </Typography>
                  <Controller
                    name="managerRating"
                    control={control}
                    render={({ field }) => (
                      <Box display="flex" gap="8px">
                        <Rating
                          {...field}
                          name="managerRating"
                          min={1}
                          max={5}
                          precision={1}
                          value={ratingValue}
                          icon={
                            <StarIcon
                              sx={{
                                fontSize: "50px",
                                color: "#FFC403",
                              }}
                            />
                          }
                          emptyIcon={
                            <StarBorderIcon
                              sx={{
                                fontSize: "50px",
                                color: "#ddd",
                              }}
                            />
                          }
                          onChange={(_, newValue) => {
                            field.onChange(newValue);
                            setRatingValue(newValue);
                          }}
                        />
                      </Box>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="managerFeedback"
                  control={control}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      label={t("Feedback")}
                      multiline
                      rows={2}
                      inputProps={{ maxLength: 200 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions>
          <ButtonBlue onClick={handleClose}>{t("Close")}</ButtonBlue>
          <ButtonBlue
            type={"submit"}
            variant="contained"
            disabled={isSubmitting}
          >
            {t("Confirm")}
          </ButtonBlue>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const KpiAdminEvaluation = ({ selectedCompany }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: kpiApproval, isFetching } = useSelector(
    (state) => state.kpiApproval
  );

  const [open, setOpen] = useState(false);
  const [selectedKpi, setSelectedKpi] = useState(null);

  const handleOpen = (kpi) => {
    setOpen(true);
    setSelectedKpi(kpi);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedKpi(null);
  };

  const columns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>{getUserFullName(row)}</Typography>
            <Typography color="text.third" fontSize="14px">
              {getUserPosition(row)}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("KPIName")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Typography textAlign={"center"}>{row.kpiName}</Typography>
      ),
    },
    {
      name: `${t("Year")}`,
      headerTextAlign: "center",
      minWidth: "80px",
      width: "80px",
      cellRender: (row) => (
        <Typography textAlign={"center"}>
          {i18n.resolvedLanguage === "th" ? row.year + 543 : row.year}
        </Typography>
      ),
    },
    {
      name: `${t("Approver")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Typography textAlign={"center"}>
          {`${dayjs(row.startDate).format(
            i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
          )} - ${dayjs(row.endDate).format(
            i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
          )}`}
        </Typography>
      ),
    },
    {
      name: `${t("Evaluate")}`,
      headerTextAlign: "center",
      minWidth: "120px",
      width: "120px",
      cellRender: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            align: "center",
          }}
        >
          <ButtonBlue variant="contained" onClick={() => handleOpen(row)}>
            {t("Evaluate")}
          </ButtonBlue>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (selectedCompany) {
      dispatch(
        getKpiPendingEvaluation({ idCompany: selectedCompany.idCompany })
      );
    }
  }, [selectedCompany]);

  return (
    <>
      {isFetching ? (
        <Loading />
      ) : (
        <TableCustom columns={columns} rows={kpiApproval ? kpiApproval : []} />
      )}
      {open && (
        <KpisIndividualPlanView
          open={open}
          handleClose={handleClose}
          data={selectedKpi}
          idCompany={selectedCompany.idCompany}
        />
      )}
    </>
  );
};

export default KpiAdminEvaluation;
