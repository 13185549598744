import dayjs from "dayjs";
import { httpClient } from "./httpClient";

const getAllEmployeeProject = (query) => {
  return httpClient.get(`all-employee-project`, { params: query });
}

const getProjectByEmp = (query) => {
  return httpClient.get(`employee-project`, { params: query });
}

const getEmployeeProject = (idEmployeeProject) => {
  return httpClient.get(`employee-project/${idEmployeeProject}`);
}
;

const getManagerProjectToApprove = (start, end) => {
  // Validate start and end dates
  const isValidStartDate = dayjs(start).isValid();
  const isValidEndDate = dayjs(end).isValid();

  const startDate = isValidStartDate ? dayjs(start).format("YYYY-MM-DD") : '';
  const endDate = isValidEndDate ? dayjs(end).format("YYYY-MM-DD") : '';

  const url = startDate && endDate ?
    `manager-approve-project?dateStart=${startDate}&dateEnd=${endDate}` :
    'manager-approve-project';

  return httpClient.get(url);
};

const updateStatusProject = (query) => {
  return httpClient.put(`approve-project`, query);
}

const addProject = (formData) => {
  return httpClient.post(`employee-project`, formData);
}

const deleteProject = (id) => {
  return httpClient.delete(`employee-project/${id}`);
}

const updateProject = (id, formData) => {
  return httpClient.put(`employee-project/${id}`, formData);
}

const getAdminProjectApprove = (query) => {
  return httpClient.get(`admin-approve-project`, { params: query });
}

const updateAdminProjectApprove = (formData) => {
  return httpClient.put(`admin-approve-project`, formData);
}

const getAllEmployeeReward = (query) => {
  return httpClient.get(`all-employee-reward`, { params: query });
}

const getEmployeeRewardByEmp = (query) => {
  return httpClient.get(`employee-reward`, { params: query });
}

const getEmployeeReward = (idEmployeeProject) => {
  return httpClient.get(`employee-reward/${idEmployeeProject}`);
}

const addEmployeeReward = (formData) => {
  return httpClient.post(`employee-reward`, formData);
}

const updateEmployeeReward = (id, formData) => {
  return httpClient.put(`employee-reward/${id}`, formData);
}

const deleteEmployeeReward = (id) => {
  return httpClient.delete(`employee-reward/${id}`);
}

const getManagerRewardApprove = () => {
  return httpClient.get(`manager-approve-reward`);
}

const updateManagerRewardApprove = (formData) => {
  return httpClient.put(`manager-approve-reward`, formData);
}

const getAdminRewardApprove = (query) => {
  return httpClient.get(`admin-approve-reward`, { params: query });
}

const updateAdminRewardApprove = (formData) => {
  return httpClient.put(`admin-approve-reward`, formData);
}

export default {
  addProject,
  updateProject,
  deleteProject,
  getAllEmployeeProject,
  getProjectByEmp,
  getEmployeeProject,
  updateStatusProject,
  getManagerProjectToApprove,
  getAdminProjectApprove,
  updateAdminProjectApprove,

  getAllEmployeeReward,
  getEmployeeRewardByEmp,
  getEmployeeReward,
  addEmployeeReward,
  updateEmployeeReward,
  deleteEmployeeReward,
  getManagerRewardApprove,
  updateManagerRewardApprove,
  getAdminRewardApprove,
  updateAdminRewardApprove,
};
