import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory, Link } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Avatar,
  Grid,
  RadioGroup,
  Radio,
  Divider,
  Paper,
  FormControl,
  FormControlLabel,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { useTranslation } from "react-i18next";
import CardStyle from "../../shared/general/Card";
import { getQuestionListByIdPotentialAssessment } from "../../../../actions/potential";
import { getUserFullName, getUserPosition } from "../../../../utils/userData";
import LoadingIcon from "../../assets/social-media.gif";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import FeelingIcon from "./components/FeelingIcon";
import AddIcon from "@mui/icons-material/Add";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { openNotificationAlert } from "../../../../actions/notificationAlert";
import { postAnswerPotentialAssessment } from "../../../../actions/potential";
const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: "5%",
  "& .form-radio-label-type": {
    ["@media only screen and (max-width: 600px)"]: {
      flexBasis: "25%",
      width: "fit-content",
      margin: 0,
    },
    "& .label-radio": {
      ["@media only screen and (max-width: 600px)"]: {
        fontSize: 14,
      },
    },
  },
});

const QuestionPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    state: { potentialAssessment },
  } = useLocation();
  const { isFetching: questionLoading, result: questions } = useSelector(
    (state) => state.PotentialQuestionList
  );

  const answerScale = [
    {
      key: "verybad",
      label: "Very Bad",
      value: "0",
    },
    {
      key: "bad",
      label: "Bad",
      value: "20",
    },
    {
      key: "littlebad",
      label: "Little Bad",
      value: "40",
    },
    {
      key: "littlehappy",
      label: "Little Happy",
      value: "60",
    },
    {
      key: "happy",
      label: "Happy",
      value: "80",
    },
    {
      key: "veryhappy",
      label: "Very Happy",
      value: "100",
    },
  ];
  const validationSchema = yup.object({
    answers: yup.array().of(
      yup.object().shape({
        answer: yup
          .string()
          .max(100, `${t("NotMoreThan")} 100 ${t("Characters")}`)
          .required("กรุณาตอบคำถาม"),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      answers: [
        {
          idQuestion: null,
          answer: "",
          questionType: null,
          feedback: null,
        },
      ],
    },
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "answers",
  });

  const onSubmit = async (data) => {
    let formData = {};
    const idAppraiser = potentialAssessment.idAppraiser;
    const idPotentialAssessmentQuestionSet =
      potentialAssessment.idPotentialAssessmentQuestionSet;
    formData = {
      idAppraiser: idAppraiser,
      idPotentialAssessmentQuestionSet: idPotentialAssessmentQuestionSet,
      answers: data.answers,
      idAppraisee:potentialAssessment.idAppraisee
    };
    console.log(formData);
    const result = await dispatch(postAnswerPotentialAssessment(formData));
    if (result && result.status === 200) {
      dispatch(
        openNotificationAlert({
          type: "success",
          message: t("DataSaveSuccessful"),
        })
      );
      history.replace("/user-potential");
    } else {
      dispatch(
        openNotificationAlert({
          type: "error",
          message: t("AnErrorOccurred"),
        })
      );
    }
  };

  useEffect(() => {
    console.log(potentialAssessment);
    if (!potentialAssessment.idPotentialAssessmentQuestionSet) {
      console.log("no location");
      history.goBack();
    } else {
      console.log(potentialAssessment);
      dispatch(
        getQuestionListByIdPotentialAssessment(
          potentialAssessment.idPotentialAssessmentQuestionSet
        )
      );
    }
  }, []);

  useEffect(() => {
    if (questions != null) {
      const temp = questions.map((e) => ({
        idQuestion: e.idQuestion,
        answer: "",
        questionType: e.questionType,
        feedback: null,
      }));

      reset({
        answers: temp,
      });
      console.log(fields);
    }
  }, [questions]);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <CardStyle>
          <Grid container spacing={2} padding={"24px"}>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{
                  marginRight: "15px",
                  width: 120,
                  height: 120,
                  "& img": { objectFit: "contain" },
                }}
                src={potentialAssessment.imageURL}
              />
              <div
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ fontWeight: "600", fontSize: "18px" }}
                  gutterBottom
                >
                  {getUserFullName(potentialAssessment)}
                </Typography>
                <Typography color="text.secondary">
                  {getUserPosition(potentialAssessment)}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={1}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item xs={7}>
              <Typography
                variant="body2"
                color="#EC5B6A"
                justifyContent="flex-end"
              >
                ชื่อชุดคำถาม
              </Typography>
              <Typography variant="h5" fontWeight={"600"} marginBottom={"10px"}>
                {potentialAssessment.questionSetName}
              </Typography>
              <Typography variant="body2" color="#EC5B6A">
                คำอธิบาย
              </Typography>
              <Typography
                sx={{ fontSize: "14px" }}
                color="text.secondary"
                gutterBottom
              >
                {potentialAssessment.description}
              </Typography>
              <Typography
                variant="body2"
                color="#EC5B6A"
                justifyContent="flex-end"
              >
                จำนวนคำถาม
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                {`${potentialAssessment.questionLength} ข้อ`}
              </Typography>
            </Grid>
          </Grid>
        </CardStyle>
        {questionLoading && (
          <Box
            sx={{
              minHeight: 400,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={LoadingIcon} style={{ height: 80, width: 80 }} />
          </Box>
        )}

        {questions && questions.length === 0 && (
          <Box
            style={{
              minHeight: 400,
              position: "sticky",
              left: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>{t("NoData")}</Typography>
          </Box>
        )}

        {questions && questions.length >= 1 && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              spacing={6}
              sx={{
                marginTop: "10px",
              }}
            >
              {questions.map((question, index) => (
                <Grid item xs={12}>
                  <Paper
                    variant="outlined"
                    key={index}
                    style={{
                      padding: 16,
                      borderRadius: "16px",
                      borderLeft: "10px solid #EC5B6A",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "15px",
                      }}
                    >
                      <Avatar
                        sx={{ backgroundColor: "#EC5B6A", marginRight: "10px" }}
                      >
                        {index + 1}
                      </Avatar>
                      <Typography
                        style={{ fontSize: 20, marginBottom: "20px" }}
                        variant="h6"
                      >
                        {`${question.questionName}?`}
                      </Typography>
                    </div>
                    <Grid container justifyContent={"center"} spacing={2}>
                      {question.questionType === "choice" ? (
                        <Grid container item xs={12} >
                          <Controller
                            name={`answers.${index}.answer`}
                            control={control}
                            render={({ field, fieldState }) => (
                              <FormControl fullWidth>
                                <RadioGroup
                                  {...field}
                                  row
                                  value={field.value || ""}
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  sx={{ justifyContent: "space-evenly" }}
                                >
                                  {answerScale.map((item) => (
                                    <Grid item xs={2}>
                                      <FormControlLabel
                                        control={
                                          <Radio
                                            checkedIcon={
                                              <FeelingIcon
                                                feeling={item.key}
                                                unSelected={true}
                                              />
                                            }
                                            icon={
                                              <FeelingIcon
                                                feeling={item.key}
                                                unSelected={false}
                                              />
                                            }
                                          />
                                        }
                                        value={item.value}
                                        label={item.label}
                                        labelPlacement="bottom"
                                      />
                                    </Grid>
                                  ))}
                                </RadioGroup>
                                {Boolean(fieldState.error) && (
                                  <Typography
                                    color={"error"}
                                    sx={{
                                      textAlign: "center",
                                    }}
                                  >
                                    กรุณาเลือกคำตอบ
                                  </Typography>
                                )}
                              </FormControl>
                            )}
                          />
                          <Grid item xs={12} sx={{
                            marginTop:"15px"
                          }}>
                            <Controller
                              name={`answers.${index}.feedback`}
                              control={control}
                              render={({field,fieldState})=>(
                                <TextFieldTheme
                                  {...field}
                                  multiline
                                  label="แสดงความคิดเห็น"
                                  rows={1}
                                  error={Boolean(fieldState.error)}
                                  helperText={
                                    fieldState.error && fieldState.error.message
                                  }
                                
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <Controller
                            name={`answers.${index}.answer`}
                            control={control}
                            render={({ field, fieldState }) => {
                              return (
                                <TextFieldTheme
                                  {...field}
                                  multiline
                                  rows={4}
                                  error={Boolean(fieldState.error)}
                                  helperText={
                                    fieldState.error && fieldState.error.message
                                  }
                                />
                              );
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            {questions && questions.length >= 1 && (
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"flex-end"}
                marginTop={"15px"}
              >
                <ButtonBlue
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  ส่งคำตอบ
                </ButtonBlue>
              </Grid>
            )}
          </form>
        )}
      </Container>
    </StyledRoot>
  );
};

export default QuestionPage;
