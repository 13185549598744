import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import "dayjs/locale/th";

import {
  Typography,
  CardContent,
  Grid,
  Box,
  Divider,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  TableContainer,
} from "@mui/material";

import FilePresentIcon from "@mui/icons-material/FilePresent";

import StyledCard from "../../../../shared/general/Card";
import Backdrop from "../../../../shared/general/Backdrop";

import Comments from "../../comments";
import Actions from "../../actions";
import utils from "../../../../../../utils";

import {
  getExpenseAdvanceById,
  getExpenseClearAdvanceById,
} from "../../../../../../actions/expense";
import { getUserFullName } from "../../../../../../utils/userData";
import DetailDiv from "../detail-div";
import RowItemExpense from "./rowClearAdvance";
import FileIcon from "../../file-icon";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledGrid = styled(Grid)({
  borderRight: "2px solid rgba(145, 158, 171, 0.32)",
});

const StyledTableCell = styled(TableCell)({
  border: "none",
  padding: 0,
  "& .MuiTypography-root": {
    marginBottom: 16,
  },
  "&.description": {
    width: 200,
  },
  "&.amount": {
    width: 104,
  },
  "&.delete": {
    width: 32,
  },
});

const ViewExpenseClearAdvancePage = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: expenseDetail } = useSelector((state) => state.expenseDetail);
  const { idExpense } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const rowHeader = [
    {
      caption: t("Descriptions"),
      className: "description",
    },
    {
      caption: t("Amount"),
      className: "amount",
    },
  ];

  useEffect(() => {
    if (userProfile) {
      dispatch(getExpenseClearAdvanceById(idExpense));
    }
  }, [userProfile]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <div>
      {expenseDetail ? (
        <Box>
          <div>
            <div className="wrap-head">
              <StyledHeadLabel variant="h6">
                {`${t("GeneralInformation")} (${t("ClearAdvance")})`}
              </StyledHeadLabel>
            </div>
            <DetailDiv title="DocumentNumber" data={expenseDetail.documentId} />
            <DetailDiv title="TitleName" data={expenseDetail.nameExpense} />
            <DetailDiv title="Unit.Items" data={expenseDetail.description} />
            <DetailDiv
              title="advanceExpenseRequest"
              data={expenseDetail.advanceName}
            />
            <DetailDiv title="Currency" data={expenseDetail.currency} />
            <StyledDivider />
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">{t("List")}</StyledHeadLabel>
              </div>

              <TableContainer>
                <Table
                  style={{
                    minWidth: 650,
                    borderSpacing: "0 4px",
                    borderCollapse: "separate",
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      {rowHeader.map((item) => (
                        <StyledTableCell className={item.className}>
                          <StyledHeadLabel
                            variant="body2"
                            color="text.third"
                            gutterBottom
                            sx={{ textAlign: "center" }}
                          >
                            {t(item.caption)}
                          </StyledHeadLabel>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {expenseDetail.listExpense &&
                      expenseDetail.listExpense.length &&
                      expenseDetail.listExpense.length > 0 &&
                      expenseDetail.listExpense
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item, index) => (
                          <RowItemExpense
                            key={index}
                            count={
                              expenseDetail.listExpense &&
                              expenseDetail.listExpense.length
                                ? expenseDetail.listExpense.length
                                : 0
                            }
                            data={expenseDetail.listExpense[index]}
                            index={index}
                            open={open}
                          />
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[
                  10,
                  20,
                  30,
                  40,
                  50,
                  { label: t("All"), value: -1 },
                ]}
                component="div"
                count={
                  expenseDetail.listExpense && expenseDetail.listExpense.length
                    ? expenseDetail.listExpense.length
                    : 0
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={`${t("RowsPerPage")}`}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} ${t("OutOf")} ${
                    count !== -1 ? count : `${t("MoreThan")} ${to}`
                  }`
                }
              />
            </div>
          </div>

          <StyledDivider />
          <div>
            <div
              style={{
                marginTop: 24,
              }}
            >
              <div className="wrap-row-sum">
                <Typography color="text.third">
                  {t("TotalAdvance")}&nbsp;&nbsp;
                </Typography>
                <Typography color="text.third" variant="h6">
                  {utils.numberWithCommas(
                    parseFloat(expenseDetail.totalAdvance)
                  )}
                </Typography>
                <Typography color="text.third">
                  &nbsp;&nbsp;{t(`CurrencyUnit.${expenseDetail.currency}`)}
                </Typography>
              </div>
              <div className="wrap-row-sum">
                <Typography color="text.third">
                  {t("TotaldifferentAmount")}&nbsp;&nbsp;
                </Typography>
                <Typography color="text.third" variant="h6">
                  {utils.numberWithCommas(
                    parseFloat(expenseDetail.differentAmount)
                  )}
                </Typography>
                <Typography color="text.third">
                  &nbsp;&nbsp;{t(`CurrencyUnit.${expenseDetail.currency}`)}
                </Typography>
              </div>
              <div className="wrap-row-sum">
                <Typography variant="h5">
                  {t("TotalValue")}&nbsp;&nbsp;
                </Typography>
                <Typography variant="h4">
                  {utils.numberWithCommas(parseFloat(expenseDetail.net))}
                </Typography>
                <Typography variant="h5">
                  &nbsp;&nbsp;{t(`CurrencyUnit.${expenseDetail.currency}`)}
                </Typography>
              </div>
            </div>
          </div>
          <StyledDivider />
          {expenseDetail.remark && (
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">{t("Note")}</StyledHeadLabel>
              </div>
              <Typography
                component="p"
                variant="subtitle1"
                sx={{
                  paddingLeft: 4,
                  marginBottom: 4,
                }}
              >
                &nbsp;&nbsp;{expenseDetail.remark}
              </Typography>
              <StyledDivider />
            </div>
          )}
          {expenseDetail.fileURL && (
            <FileIcon fileURL={expenseDetail.fileURL} />
          )}
          <div>
            <StyledDivider />
            <div className="wrap-head">
              <StyledHeadLabel variant="h6">
                {t("attachPaymentSlipFile")}
              </StyledHeadLabel>
            </div>
            {expenseDetail.filePaymentSlipURL ? (
              <Box
                style={{
                  position: "relative",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    padding: 15,
                    backgroundColor: "#f9f9fb",
                    display: "flex",
                    borderRadius: 15,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    expenseDetail.fileURL &&
                      window.open(
                        `${expenseDetail.filePaymentSlipURL.URL}`,
                        "_blank"
                      );
                  }}
                >
                  <FilePresentIcon
                    height="24"
                    style={{
                      marginRight: 10,
                    }}
                  ></FilePresentIcon>
                  <Typography>
                    {expenseDetail.filePaymentSlipURL.path}
                  </Typography>
                </div>
              </Box>
            ) : (
              <Box>
                <div
                  style={{
                    padding: 24,
                    backgroundColor: "#f9f9fb",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: 15,
                  }}
                >
                  <Typography color="error">{t("NoAttachedFile")}</Typography>
                </div>
              </Box>
            )}
          </div>
          {expenseDetail.comments &&
          expenseDetail.comments.length &&
          expenseDetail.comments.length > 0 ? (
            <div>
              <StyledDivider />
              <Comments comments={expenseDetail.comments} />
            </div>
          ) : (
            <span></span>
          )}
          {expenseDetail.actions &&
          expenseDetail.actions.length &&
          expenseDetail.actions.length > 0 ? (
            <div>
              <StyledDivider />
              <Actions actions={expenseDetail.actions} />
            </div>
          ) : (
            <span></span>
          )}
        </Box>
      ) : (
        <Backdrop open={true} />
      )}
    </div>
  );
};

export default ViewExpenseClearAdvancePage;
