import { combineReducers } from "redux";
import auth from "./auth";
import standardMenu from "./standard-menu";
import userProfile from "./user-profile";
import companyProfile from "./company-profile";
import attendanceOnce from "./attendance-once";
import attendanceUpload from "./attendance-upload";
import users from "./user";
import userInformation from "./user-information";
import manager from "./manager";
import department from "./department";
import paytypes from "./paytypes";
import payRun from "./pay-run";
import payslip from "./payslip";
import payslipDetail from "./payslip-detail";
import payRunDetail from "./pay-run-detail";
import payRunSummaryYear from "./pay-run-summary-year";
import payrollSetting from "./payroll-setting";
import payRunDetailEmployees from "./pay-run-detail-employee";
import employees from "./employee";
import employeeProfile from "./employee-profile";
import employeeChange from "./employee-change";
import navigation from "./navigation";
import orgChart from "./orgChart";
import dataProject from "./project";
import projectAssign from "./projectAssign";
import employeeReward from "./employee-reward";
import locations from "./locations";
import leaveWorks from "./leaveWorks";
import jobFamily from "./jobFamily";
import institute from "./institute";
import requestTime from "./requestTime";
import requestTimeWithDraw from "./requestTime-withdraw";
import leaveApproves from "./leaveApproves";
import workTimeRequest from "./workTimeRequest";
import logRecord from "./logRecord";
import groupLocations from "./group-locations";
import attendance from "./attendance";
import jobPosition from "./jobPosition";
import dataWork from "./work-data";
import workTypes from "./work-types";
import dataJob from "./job-data";
import dataTask from "./task-data";
import document from "./document";
import documentItem from "./document-item";
import documentGroup from "./document-group";
import category from "./category";
import dataDivision from "./division-data";
import dataMember from "./member-data";
import demandData from "./demand-data";
import userMock from "./user-mock";
import insurance from "./insurance";
import hospital from "./hospital";
import insuranceCategory from "./insuranceCategory";
import package_ from "./package";
import mainCategory from "./mainCategory";
import cart from "./cart";
import travelCategory from "./travelCategory";
import travel from "./travel";
import shopCategory from "./shopCategory";
import item from "./item";
import notification from "./notification";
import salarySlip from "./salarySlip";
import summaryTime from "./summary-time";
import summaryTimeIndividual from "./summary-time-individaul";

import jobPosting from "./jobPosting";
import jobPostingApply from "./jobPostingApply";
import careerPlan from "./careerPlan";

import delivery from "./delivery";
import facilities from "./facilities";
import treatmentCategory from "./treatmentCategory";
import bill from "./bill";
import health from "./health";
import rightTreatment from "./rightTreatment";
import healthCheck from "./healthCheck";
import bookingHealthCheck from "./bookingHealthCheck";
import history from "./history";

import jobStructures from "./jobStructures";
import subJobStructures from "./subJobStructures";
import jobPositions from "./jobPosition";

import OrgPlans from "./OrganizationPlans";
import TeamPlans from "./TeamPlans";
import IdvPlans from "./IndividualPlans";
import FailureHistory from "./FailureHistory";
import ProgressOrgPlans from "./ProgressOrganizationPlans";
import ProgressTeamPlans from "./ProgressTeamPlans";
import ProgressIdvPlans from "./ProgressIndividualPlans";
import Impacts from "./Impacts";
import ImpactOthers from "./ImpactOthers";
import DialogueRecords from "./DialogueRecords";
import jobTaskLists from "./jobTaskList";
import personalTaskLists from "./personalTaskList";
import personalTaskUsed from "./personalTaskUsed";
import holiday from "./holiday";
import holidayPattern from "./holidayPattern";
import leaveType from "./leave-type";
import positions from "./positions";
import additions from "./additions";
import levelAddition from "./levelAddition";
import level from "./level";
import employmentType from "./employment-type";
import leaveEmployees from "./leaveEmployees";
import leaveEmployeesWithDraw from "./leaveEmployeesWithdraw";
import bank from "./bank";
import bankDetail from "./bank-detail";
import shiftPattern from "./shift-pattern";
import shift from "./shift";
import shiftGroup from "./shift-group";
import companyOrganization from "./company-organization";
import employeeShift from "./employee-shift";
import employeeShiftHistory from "./employee-shift-history";
import employeeChangeShift from "./employee-changeShift";
import userBeneficiarys from "./user-beneficiarys";
import userProvidentFund from "./user-providentFund";
import adminSettingPF from "./adminSettingPF";
import dashboardOverview from "./dashboard-overview";
import taxDeduction from "./tax-deduction";
import dashboardOvertime from "./dashboard-overtime";
import dashboardCost from "./dashboard-cost";
import dashboardWorkingtime from "./dashboard-workingtime";
import employeeAdditionDeduction from "./employee-addition-deduction";
import employeeProvidentFunds from "./employee-providentFund";
import employeeBeneficiarys from "./employee-beneficiary";
import taxDeductionSchedule from "./tax-deduction-schedule";
import individualObjective from "./individual-objective";
import organizationObjective from "./organization-objective";
import teamObjective from "./team-objective";
import okrScoreboard from "./okr-scoreboard";
import company from "./company";
import leave3month from "./leave3month";
import leaveYear from "./leaveyear";
import managerSetting from "./managerSetting";
import organizationChart from "./organizationChart";
import announcement from "./announcement";
import announcementActive from "./announcementActive";
import jobGroup from "./jobGroup";
import organizationStructure from "./organizationStructure";
import costElement from "./costElement";
import costElementList from "./costElementList";
import managerGroup from "./managerGroup";
import admin from "./admin";
import affiliate from "./affiliate";
import affiliateOrganization from "./affiliate-organization";

import probationQuestion from "./probation-question";

import terminateList from "./terminatelist";
import terminateReviewer from "./terminateReviewer";
import terminateEmployee from "./terminateEmployee";
import terminateEmployeeByID from "./terminateEmployeeByID";
import onBoardingList from "./onBoardingList";
import onBoardingEmployee from "./onBoardingEmployee";
import onBoardingEmployeeByID from "./onBoardingEmployeeByID";
import onBoardingReviewer from "./onBoardingReviewer";

import employeesProbation from "./employees-probation";
import employeesProbationStatus from "./employees-probation-status";
import employeesRoleOneTwo from "./employees-role-one-two";
import probationAnswer from "./probation-answer";
import employeeProbationId from "./employee-probation-id";

import performanceYear from "./performanceYear";
import estimateLevel from "./estimateLevel";
import performanceQuestion from "./estimatePerformance";
import employeesEstimatePerformanceStatus from "./employees-estimatePerformance-status";
import estimatePerformanceAnswer from "./estimatePerformance-answer";
import employeesEstimatePerformance from "./employees-estimatePerformance";

import getExitInterview from "./exitInterview";
import employeesExitInterviewStatus from "./employees-exitInterview-status";
import exitInterviewAnswer from "./exitInterview-answer";
import exitInterviewDiscard from "./exitInterview-discard";
import exitInterviewReason from "./exitInterview-reason";
import employeesExitInterview from "./employees-exitInterview";

import jobRecruitDetail from "./job-recruit-detail";
import jobRecruitEdit from "./job-recruit-edit";
import jobRecruitForm from "./job-recruit-form";
import jobRecruitGroup from "./job-recruit-group";
import jobsRecruit from "./jobs-recruit";
import jobGroupInterviewer from "./job-group-interviewer";

import manpowerProfile from "./manpower-profile";
import manpowerCertificate from "./manpower-certificate";
import candidateAlreadySent from "./candidate-already-sent";
import passedCandidatesCompany from "./passed-candidates-company";
import newCandidate from "./new-candidate";
import scheduleCandidates from "./schedule-candidates";
import interviewedCandidate from "./interviewedCandidate";
import historyManpowersSending from "./history-manpowers-sending";
import userVendorFree from "./user-vendor-free";

import jobRecruitManager from "./job-recruit-manager";
import homepageHunter from "./homepage-hunter";
import dashboardRecruit from "./dashboard-recruit";

import warningLetter from "./warningLetter";
import warningLevel from "./warningLevel";
import warningLetterTemplate from "./warningLetterTemplate";

import assetManagement from "./assetManagement";
import policy from "./policy";
import policyActive from "./policyActive";

import timeline from "./timeline";
import timelineMonth from "./timelineMonth";

import notificationAlert from "./notificationAlert";
import manpowerPlanning from "./manpower-planning";

import email from "./email";
import attendanceWeekly from "./attendanceWeekly";
import leaveSetting from "./leave-setting";
import companyShift from "./company-shift";

import expense from "./expense";
import expenseDetail from "./expense-detail";
import expenseExport from "./expense-export";
import expenseExportWelfare from "./expense-export-welfare";
import expenseTypes from "./expense-types";
import expenseAdvances from "./expense-advances";

import levelGroup from "./level-group";
import allRights from "./all-rights";
import family from "./family";

import welfareRights from "./welfare-rights";
import welfareRemainingRight from "./welfare-remaining-right";
import welfareBilling from "./welfare-billing";
import welfareBillingDetail from "./welfare-billing-detail";
import flexCoin from "./flexCoin";

import admins from "./admins";
import salaryReasons from "./salary-reasons";

import organizationList from "./organization-list";
import leaveAvailable from "./leave-available";
import employeePdpaApprove from "./pdpaApprove";
import pdpaApproveList from "./pdpaApproveList";
import employeeSearch from "./employee-search";
import dashboardWorkforceMonth from "./dashboardWorkforceMonth";
import dashboardWorkforceAll from "./dashboardWorkforceAll";
import userWorkforceShowHour from "./userWorkforceShowHour";
import dashboardWorkforcePieChart from "./dashboardWorkforcePieChart";

//here
import projectEmployees from "./projectEmployees";
import projectGetUsers from "./projectGetUsers";
import projectGetUserTimeforApprove from "./projectGetUserTimeforApprove";
import projectUserInsertTime from "./projectUserInsertTime";
import getSumHourWorkforceHourUserByIdMonth from "./getSumHourWorkforceHourUserByIdMonth";
import getAdminWorkforceProjectDetail from "./getAdminWorkforceProjectDetail";
import getSumTimeUserInProject from "./getSumTimeUserInProject";

import reportSummaryOvertimeShift from "./report-summary-overtime-shift";
import reportSummaryOvertimeShiftIndividual from "./report-summary-overtime-shift-individual";
import reportLeaveAbsentLate from "./report-leave-absent-late";
import reportLeaveAbsentLatePayroll from "./report-leave-absent-late-payroll";
import reportNewEmployees from "./report-new-employees";
import reportResignEmployees from "./report-resign-employees";
import reportContractTerminateEmployees from "./report-contract-terminate-employees";
import reportProbationEmployees from "./report-probation-employees";

import logDownloadPDF from "./logDownloadPDF";
import leaveList from "./leavelist";
import salaryAdjustList from "./salaryAdjustList";
import dashboardEmployee from "./dashboard-Employee";
import additionSpecial1 from "./additionSpecial1";
import additionSpecial2 from "./additionSpecial2";
import additionSpecial100 from "./additionSpecial100";
import additionPersonal from "./additionPersonal";
import employeeAdditionPersonal from "./additionPersonalEmployee";
import tableHour from "./tableHour";
import empInWorkforce from "./empInWorkforce";
import empWorkforceWeek from "./empWorkforceWeek";
import getBudgetUserWorkforce from "./getBudgetUserWorkforce";

import managerApproveSetting from "./manager-approve-setting";
import workingTime from "./workingTime";
import timeAttendance from "./timeAttendance";

import employeeShiftChangeHistory from "./employee-shift-change-history";
import affiliateShiftGroup from "./affiliate-shift-group";

import kpi from "./kpi";
import kpiByid from "./kpi-by-id";
import kpiApproval from "./kpiApproval";
import kpiUpdate from "./kpiUpdate";
import kpiUpdateId from "./kpiUpdateId";
import kpiResultApprove from "./kpiResultApprove";
import kpiEvaluation from "./kpiEvaluation";
import kpiCarlibrate from "./kpiCarlibrate";
import kpiDepartmentCalibrate from "./kpiDepartmentCalibrate";
import kpiIsSuccessful from "./kpiIsSuccessful";
import kpiUpdateAllAchievement from "./kpiUpdateAllAchievement";
import kpiAll from "./kpiAll";
import kpiOrganization from "./kpiOrganization";
import kpiTeam from "./kpiTeam";
import kpiDate from "./kpiDate";

import organizationManage from "./organizationManage";
import overtime36Hours from "./overtime-36-hours";
import overtime36HoursDetail from "./overtime-36-hours-detail";
import jobRecruitInterviewPanel from "./job-recruit-interviewpanel";

import personalTax from "./personal-tax";
import taxSummary from "./tax-summary";
import diligenceAllowanceReport from "./diligence-allowance-report";
import additionSpecialDiligenceAllowanceSetting from "./addition-special-diligence-allowance-setting";
import additionSpecialConditionTimeSetting from "./addition-special-condition-time-setting";
import additionSpecialConditionTimePersonalSetting from "./addition-special-condition-time-personal-setting";
import additionSpecialConditionTimeReport from "./addition-special-condition-time-report";

import allCompanyDocuments from "./all-company-document";
import alldocumentItem from "./all-documentitem";
import allCompanyDocumentType from "./all-document-Typename";
import allUnionDocumentGroupTypeName from "./all-union-document-grouptypename";
import companyDocumentGroup from "./company-document-group";
import companyDocumentDowload from "./company-document-dowload";
import employeeDocument from "./employee-document";
import managerPendingRequestLeave from "./manager-pending-request-leave";
import adminPendingRequestLeave from "./admin-pending-request-leave";
import eMemos from "./ememo";
import deductions from "./deductions";
import mobileToken from "./mobile-token";
import expensePaymentTypes from "./expense-payment-types";
import expenseReturnAdvanceTypes from "./expense-return-advance-types";
import chatbotDocument from "./chatbot-document";
import individualObjectivesReducer from "./okr-objective";
import employeeDiligenceInfo from "./employee-diligence-info";
import allSmartDocument from "./all-smart-document";
import smartDocumentFile from "./smart-document-file";
import overviewUserAbsentLate from "./overview-user-absent-late";
import overviewUserLeave from "./overview-user-leave";
import overviewUserOt from "./overview-user-ot";
import overviewUserNotification from "./overview-user-notification";
import costCenterList from "./cost-center-list";
import expenseSetting from "./expense-setting";
import expenseSettings from "./expense-settings";
import expenseProrateTypes from "./expense-prorate-types";
import expenseAllowanceTypes from "./expense-allowance-types";
import expenseSettingMileage from "./expense-setting-mileage";
import expenseSettingAllowance from "./expense-setting-allowance";
import expenseSettingsMileage from "./expense-settings-mileage";
import expenseSettingsAllowance from "./expense-settings-allowance";
import expenseSettingsCostElement from "./expense-settings-cost-element";

import potentialAppraiser from "./potentialAppraiser";
import PotentialQuestionList from "./PotentialQuestionList";
import potentialUniQuestion from "./potentialUniQuestion";
import potentialOverviewAppraiser from "./potentialOverviewAppraiser";
import potentialDetailOverviewAppraiser from "./potentialDetailOverviewAppraiser";
import jobDescription from "./jobDescription";
import potentialAssessment from "./potentialAssessment";
import retirementSetting from "./retirement-setting";

const appReducer = combineReducers({
  auth,
  standardMenu,
  users,
  userProfile,
  attendanceOnce,
  attendanceUpload,
  companyProfile,
  department,
  manager,
  payrollSetting,
  paytypes,
  payRun,
  payslip,
  payslipDetail,
  payRunDetail,
  navigation,
  employees,
  tableHour,
  employeeProfile,
  employeeChange,
  orgChart,
  dataProject,
  projectAssign,
  employeeReward,
  locations,
  leaveWorks,
  jobFamily,
  institute,
  leaveApproves,
  workTimeRequest,
  logRecord,
  groupLocations,
  attendance,
  jobPosition,
  dataWork,
  workTypes,
  dataJob,
  dataTask,
  document,
  documentItem,
  documentGroup,
  category,
  dataDivision,
  dataMember,
  demandData,
  userMock,
  insurance,
  hospital,
  insuranceCategory,
  package_,
  mainCategory,
  cart,
  travelCategory,
  travel,
  shopCategory,
  item,
  notification,
  jobPosting,
  jobPostingApply,
  delivery,
  facilities,
  treatmentCategory,
  bill,
  health,
  rightTreatment,
  healthCheck,
  bookingHealthCheck,
  history,
  careerPlan,
  jobStructures,
  subJobStructures,
  jobPositions,
  OrgPlans,
  TeamPlans,
  IdvPlans,
  FailureHistory,
  ProgressOrgPlans,
  ProgressTeamPlans,
  ProgressIdvPlans,
  Impacts,
  ImpactOthers,
  DialogueRecords,
  jobTaskLists,
  personalTaskLists,
  personalTaskUsed,
  companyOrganization,
  holiday,
  holidayPattern,
  leaveType,
  positions,
  additions,
  levelAddition,
  level,
  employmentType,
  leaveEmployees,
  bank,
  bankDetail,
  shiftPattern,
  shift,
  shiftGroup,
  employeeShift,
  employeeShiftHistory,
  employeeChangeShift,
  requestTime,
  requestTimeWithDraw,
  leaveEmployeesWithDraw,
  salarySlip,
  userBeneficiarys,
  userProvidentFund,
  adminSettingPF,
  dashboardOverview,
  taxDeduction,
  dashboardOvertime,
  dashboardCost,
  dashboardWorkingtime,
  summaryTime,
  summaryTimeIndividual,
  employeeAdditionDeduction,
  employeeProvidentFunds,
  employeeBeneficiarys,
  taxDeductionSchedule,
  individualObjective,
  organizationObjective,
  teamObjective,
  okrScoreboard,
  company,
  leave3month,
  leaveYear,
  managerSetting,
  organizationChart,
  payRunDetailEmployees,
  payRunSummaryYear,
  jobGroup,
  announcement,
  announcementActive,
  organizationStructure,
  costElement,
  costElementList,
  managerGroup,
  admin,
  affiliate,
  affiliateOrganization,
  email,
  probationQuestion,
  terminateList,
  terminateReviewer,
  terminateEmployee,
  terminateEmployeeByID,
  onBoardingList,
  onBoardingEmployee,
  onBoardingEmployeeByID,
  onBoardingReviewer,
  employeesProbation,
  employeesProbationStatus,
  employeesRoleOneTwo,
  probationAnswer,
  employeeProbationId,
  estimateLevel,
  performanceYear,
  performanceQuestion,
  employeesEstimatePerformanceStatus,
  estimatePerformanceAnswer,
  employeesEstimatePerformance,
  getExitInterview,
  employeesExitInterviewStatus,
  exitInterviewAnswer,
  exitInterviewDiscard,
  exitInterviewReason,
  employeesExitInterview,
  jobRecruitDetail,
  jobRecruitEdit,
  jobRecruitForm,
  jobRecruitGroup,
  jobsRecruit,
  jobGroupInterviewer,
  manpowerProfile,
  manpowerCertificate,
  candidateAlreadySent,
  passedCandidatesCompany,
  newCandidate,
  scheduleCandidates,
  interviewedCandidate,
  historyManpowersSending,
  userVendorFree,
  homepageHunter,
  jobRecruitManager,
  dashboardRecruit,
  warningLetter,
  warningLetterTemplate,
  warningLevel,
  assetManagement,
  policy,
  policyActive,
  timeline,
  timelineMonth,
  notificationAlert,
  manpowerPlanning,
  attendanceWeekly,
  leaveSetting,
  companyShift,
  expense,
  expenseDetail,
  levelGroup,
  allRights,
  family,
  welfareRights,
  welfareBilling,
  welfareBillingDetail,
  welfareRemainingRight,
  flexCoin,
  admins,
  expenseExport,
  expenseExportWelfare,
  salaryReasons,
  organizationList,
  leaveAvailable,
  userInformation,
  employeePdpaApprove,
  pdpaApproveList,
  employeeSearch,
  projectEmployees,
  projectGetUsers,
  projectGetUserTimeforApprove,
  projectUserInsertTime,
  reportNewEmployees,
  reportResignEmployees,
  reportContractTerminateEmployees,
  reportProbationEmployees,
  reportSummaryOvertimeShift,
  reportSummaryOvertimeShiftIndividual,
  reportLeaveAbsentLate,
  reportLeaveAbsentLatePayroll,
  logDownloadPDF,
  leaveList,
  salaryAdjustList,
  dashboardEmployee,
  additionSpecial1,
  additionSpecial2,
  additionSpecial100,
  additionPersonal,
  employeeAdditionPersonal,
  managerApproveSetting,
  workingTime,
  empInWorkforce,
  timeAttendance,
  empWorkforceWeek,
  employeeShiftChangeHistory,
  affiliateShiftGroup,
  kpi,
  kpiByid,
  kpiApproval,
  kpiUpdate,
  kpiUpdateId,
  kpiResultApprove,
  kpiEvaluation,
  kpiCarlibrate,
  kpiDepartmentCalibrate,
  kpiIsSuccessful,
  kpiUpdateAllAchievement,
  kpiAll,
  kpiOrganization,
  kpiTeam,
  kpiDate,
  dashboardWorkforceMonth,
  dashboardWorkforceAll,
  userWorkforceShowHour,
  dashboardWorkforcePieChart,
  getSumHourWorkforceHourUserByIdMonth,
  getAdminWorkforceProjectDetail,
  getSumTimeUserInProject,
  getBudgetUserWorkforce,
  organizationManage,
  overtime36Hours,
  overtime36HoursDetail,
  jobRecruitInterviewPanel,
  personalTax,
  taxSummary,
  diligenceAllowanceReport,
  additionSpecialDiligenceAllowanceSetting,
  allCompanyDocuments,
  alldocumentItem,
  allCompanyDocumentType,
  allUnionDocumentGroupTypeName,
  companyDocumentGroup,
  companyDocumentDowload,
  employeeDocument,
  additionSpecialConditionTimeSetting,
  additionSpecialConditionTimePersonalSetting,
  additionSpecialConditionTimeReport,
  managerPendingRequestLeave,
  adminPendingRequestLeave,
  eMemos,
  deductions,
  mobileToken,
  expensePaymentTypes,
  expenseReturnAdvanceTypes,
  chatbotDocument,
  individualObjectivesReducer,
  employeeDiligenceInfo,
  smartDocumentFile,
  allSmartDocument,
  overviewUserLeave,
  overviewUserAbsentLate,
  overviewUserOt,
  overviewUserNotification,
  costCenterList,
  expenseTypes,
  expenseAdvances,
  expenseSetting,
  expenseSettings,
  expenseProrateTypes,
  expenseAllowanceTypes,
  expenseSettingMileage,
  expenseSettingAllowance,
  expenseSettingsMileage,
  expenseSettingsAllowance,
  expenseSettingsCostElement,
  potentialAppraiser,
  PotentialQuestionList,
  potentialUniQuestion,
  potentialOverviewAppraiser,
  potentialDetailOverviewAppraiser,
  jobDescription,
  potentialAssessment,
  retirementSetting,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
