import React, { useState } from 'react';
import { Container, Grid, Typography, Button, FormControl, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import { NavLink } from 'react-router-dom';
import { makeStyles } from "@mui/styles"; 

import { useTranslation } from 'react-i18next';

import FacebookFooterIcon from "../landing/image/FooterIcon/FacebookIcon.png";
import LineIcon from "../landing/image/FooterIcon/LineIcon.png";
import LinkedinIcon from "../landing/image/FooterIcon/LinkedinIcon.png";

import Logo from "../assets/logo/unihr_logo.png";


const useStyles = makeStyles({
  container: {
    paddingTop: "30px",
  },
  detailWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    margin: "50px 0px",
    height: "auto",
    "@media (max-width: 800px)": {
      alignItems: "flex-start",
      marginBottom: "10px",
      marginTop: "0",
      justifyContent: "flex-start",
      marginLeft: 0,
      textAlign: "left",  

    },
  },
  title: {
    fontSize: "48px",
    fontWeight: 400,
    color: "#531881 !important",
    marginBottom: "10px",
    whiteSpace: "nowrap",
    "@media (max-width: 800px)": {
      fontSize: "42px",
      whiteSpace: "normal",
      
    },
  },
  contactItem: {
    marginBottom: 24,
    "@media (max-width: 800px)": {
      marginTop: "25px",
      marginBottom: 0,
      marginLeft: 0,
    },
  },
  containerQuestion: {
    marginTop: "120px",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    "@media (max-width: 800px)": {
      marginTop: "100px",
    },
  },
  titleQuestion: {
    fontSize: "32px",
    fontWeight: 400,
    color: "#531881 !important",
    "@media (max-width: 800px)": {
      fontSize: "24px",
    },
  },
  detailQuestion: {
    fontSize: "24px",
    fontWeight: "300",
    "@media (max-width: 800px)": {
      fontSize: "18px",
    },
  },
  button: {
    borderRadius: "30px",
    height: "50px",
    padding: "0 25px",
    width: "auto",
    fontSize: "24px",
    fontWeight: "400",
    backgroundColor: "#531881",
    color: "#ffffff",
    margin: "20px 0px",
    "&:hover": {
      backgroundColor: "#ffffff",
      color: "#531881",
      border: "3px solid #531881",
    },
    "@media (max-width: 800px)": {
      height: "40px",
      width: "auto",
      fontSize: "20px",
      margin: "10px 0px",
    },
  },
  socialIcons: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "-10px",
    gap: "15px",
    "@media (max-width: 800px)": {
      marginTop: "10px",
    },
    "& img": {
      width: "50px",
      padding: "5px",
      "@media (max-width: 800px)": {
        width: "30px",
      },
    },
  },
  labelText: {
    display: "flex",
    marginBottom: 8,
    "@media (max-width: 800px)": {
      flexDirection: "row",
      alignItems: "center",
      marginLeft: 0,
      marginBottom: 0,
    },
  },
  contactText: {
    color: "#531881 !important",
    marginLeft: "8px",
  },
  contactInfo: {
    fontSize: "26px",
    color: "#000", 
    fontWeight: 400,
    "@media (max-width: 800px)": {
      fontSize: "22px", 
    },
  },
  customIcon: {
    color: "#531881 !important",
  },
});

export default function Footer() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h2"
            align="left"
            className={classes.title}
          >
            {t("registerNowTitle")}
          </Typography>

        <FormControl variant="outlined">
            <OutlinedInput
              id="outlined-adornment-password"
              type={"text"}
              sx={{ 
                border: '2px solid #C2B0E5', 
                backgroundColor: '#ffffff', 
                borderRadius: '30px', 
                height: '66px', 
                width: '540px', 
                '&.Mui-focused': {
                  borderColor: '#531881',
                  boxShadow: 'none',
                },
                "@media (max-width: 800px)": {
                  height: "55px",
                  width: "100%",
                },
              }}
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 10,
                      height: 50,
                      width: "auto",
                      padding: "0px 20px",
                      fontSize: 24,
                      backgroundColor: "#531881",
                      "&:hover": {
                        backgroundColor: "#ffffff",
                        color: "#531881",
                        border: "3px solid #531881",
                      },
                      "@media (max-width: 800px)": {
                        height: "40px",
                        width: "auto",
                        fontSize: "16px",
                      },
                    }}
                  >
                    {t("Register")}
                  </Button>
                </InputAdornment>
              }
            />
          </FormControl>
  
          <div className={classes.detailWrapper}>
            <div className={classes.contactItem}>
            <div className={classes.labelText}>
              <MailOutlineRoundedIcon className={classes.customIcon}/>
              <Typography className={classes.contactText}>Email</Typography>
            </div>
              <Typography variant="h5" className={classes.contactInfo}>
                puttinun@anthr.co
              </Typography>
            </div>
            <div className={classes.contactItem}>
            <div className={classes.labelText}>
              <PhoneIphoneOutlinedIcon className={classes.customIcon}/>
              <Typography className={classes.contactText}>Phone</Typography>
            </div>
              <Typography variant="h5" className={classes.contactInfo}>
                082-889-4498
              </Typography>
  
            </div>
            <div className={classes.contactItem}>
            <div className={classes.labelText}>
              <FmdGoodOutlinedIcon className={classes.customIcon}/>
              <Typography className={classes.contactText}>Address</Typography>
            </div>
              <Typography variant="h5" className={classes.contactInfo}>
                Bangkok, Thailand
              </Typography>
              </div>
          </div>
        </Grid>
  
        <Grid item xs={12} sm={6}>
          <div style={{ textAlign: "right" }}>
            <img src={Logo} alt="Logo" width="167" />
            <div className={classes.containerQuestion}>
              <Typography
                variant="h3"
                align="left"
                className={classes.titleQuestion}
              >
                {t("needMoreInfo")}
              </Typography>
              <Typography
                variant="body1"
                align="left"
                className={classes.detailQuestion}
              >
                {t("shareDetailsForAnswer")}
              </Typography>
  
              <Button
                component={NavLink}
                to="/contact-us"
                className={classes.button}
              >
                {t("ContactUs")}
              </Button>
            </div>
  
            <div className={classes.socialIcons}>
              <IconButton aria-label="facebook">
                <img src={FacebookFooterIcon} />
              </IconButton>
              <IconButton aria-label="line">
                <img src={LineIcon} sx={{ width: "62px", height: "62px", "@media (max-width: 800px)": { width: "25px", height: "25px" },}} />
              </IconButton>
              <IconButton aria-label="linkedin">
                <img src={LinkedinIcon} />
              </IconButton>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}  